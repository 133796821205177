import { DatePicker } from '@/components/DatePicker'
import { LoadingBlocker } from '@/components/Loader/LoadingBlocker.jsx'
import { userSelectors } from '@/entity'
import { contractsSelectors } from '@/entity/contracts'
import { useDigitalSign, useElectronicTermination } from '@/shared'
import { Button as AppButton, Checkbox, SelectSearch } from '@/ui'
import { Add, Trash } from '@app/icons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import cn from 'classnames'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractTerminationApplicationSchema, contractTerminationSchema } from './contractTermination.schema'
import { Decline, Revoke, Sign } from './modals'
import styles from './TerminateElectronic.module.scss'
import { request } from '@/shared/api/request'
import { SignStatus } from '@features/contracts/badges'
import CloseButton from '@/components/CloseButton/CloseButton'
import { message } from 'antd'
import { Field as TextField } from '@/ui'
import { Terminate } from '@features/contracts/actions/terminateElectronic/modals/Terminate'
import { useHistory } from 'react-router-dom'
import { HarmfulCheckboxBlock } from '@features/contracts/harmfulCheckboxBlock'
import { isHarmfulWorkingConditions } from '@/utils'
const getFormatDate = (value) => {
	const dateArr = value?.split('.')
	if (dateArr) {
		return `${dateArr?.[1]}.${dateArr?.[0]}.${dateArr?.[2]}`
	} else {
		return false
	}
}

const AdditonalReasonSelect = ({ setAdditionalReasons, index, value, removeReason, terminationReasons }) => {
	const { t } = useTranslation()

	const handleReasonSelect = (data) => {
		setAdditionalReasons((value) => {
			const newVal = [...value]
			newVal[index] = data
			return newVal
		})
	}

	const handleReasonRemove = () => {
		removeReason(index)
	}

	return (
		<div className={styles.select__wrapper}>
			<SelectSearch value={value} onChange={handleReasonSelect} className="flex-1" options={terminationReasons} />
			<AppButton transparent onClick={handleReasonRemove} buttonClassName={styles.buttonRed}>
				<Trash className={styles.iconRed} />
				{t('remove')}
			</AppButton>
		</div>
	)
}

export const TerminateElectronic = ({
	onToggleAction,
	onUpdate,
	onClose,
	isCreation = true,
	selectedContract,
	createTerminationApp = null,
	setActiveTab,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const selectorContract = useSelector(contractsSelectors.contract)
	const history = useHistory()
	const contract = selectedContract ?? selectorContract
	const {
		stateCode,
		dcontractState,
		id,
		bin,
		iin,
		externalId,
		initEmployer,
		fullName,
		registerDate,
		contractNumber,
		termDate,
		termReasonsList,
		note,
		statement,
		contractId,
		employerName,
		birthDate,
		dworkingConditionsCode,
	} = contract

	const [additionalReasons, setAdditionalReasons] = useState([])
	const activeRole = useSelector(userSelectors.activeRole)

	const [openRevoke, setOpenRevoke] = useState(false)
	const [isState] = useState(stateCode ?? dcontractState)
	const [openDecline, setOpenDecline] = useState(false)
	const [openSign, setOpenSign] = useState(false)
	const [openTerminate, setOpenTerminate] = useState(false)
	const { isLoading, terminationReasons, handleSign, handleSignWithoutEmployee } = useElectronicTermination(
		onUpdate,
		onClose
	)
	const [data, setData] = useState({})

	const { successPayload, onClearPayload, onSign, onSignMultiple } = useDigitalSign()
	const [annuity, setAnnuity] = useState(false)
	const onSubmit = async (data) => {
		const toSubmit = {
			terminationDate: data.terminationDate,
			terminationReasons: [data.terminationReason, ...additionalReasons],
			employeeRequest: data.employeeRequest,
			employeeConsent: data.employeeConsent,
			employerApprove: data.employerApprove,
			employeeAccountNumber: data.employeeAccountNumber,
			hrManagerPhone: data.hrManagerPhone,
			employeePhone: data.employeePhone,
		}
		setData(toSubmit)

		if (activeRole === 'ROLE_EMPLOYEE') {
			try {
				const res = await request({
					url: `/contract/eContractTerm/employee/xmlOnCreate`,
					method: 'POST',
					data: {
						...data,
						externalId,
					},
				})
				onSign(res.xmlData)
			} catch (error) {
				const err = error?.response?.data?.message || t('unknown_error')
				message.error(err ? err : t('unknown_error'), 5)
			} finally {
				// setTemplatesLoading(false)
			}
		}

		if (activeRole === 'ROLE_EMPLOYER') {
			try {
				const res = await request({
					url: `/contract/eContractTerm/send`,
					method: 'POST',
					data: {
						...toSubmit,
						externalId,
						signedXml: null,
						id: null,
					},
				})
				onClearPayload()
				message.success(t('success_alert'))
				onClose()
			} catch (error) {
				const err = error?.response?.data?.message || t('unknown_error')
				message.error(err ? err : t('unknown_error'), 5)
			}
		}
	}

	const senSignedEtd = async (xml) => {
		try {
			await request({
				url: `/contract/eContractTerm/employee/signAndCreate`,
				method: 'POST',
				data: {
					xmlData: xml,
				},
			})
			onClearPayload()
			message.success(t('success_alert'))
			setOpenTerminate(true)
		} catch (error) {
			const err = error?.response?.data?.message || t('unknown_error')
			message.error(err ? err : t('unknown_error'), 5)
		}
	}

	useEffect(() => {
		if (successPayload) {
			senSignedEtd(successPayload)
		}
	}, [successPayload])

	const openSignWindow = (data) => {
		onSignClick(data)
		//setOpenSign(true)
	}

	const onSignClick = (data) => {
		if (activeRole == 'ROLE_EMPLOYER' && isCreation) {
			const toSubmit = {
				terminationDate: data.terminationDate,
				terminationReasons: [data.terminationReason, ...additionalReasons],
				employeeRequest: data.employeeRequest,
				employeeConsent: data.employeeConsent,
				employerApprove: data.employerApprove,
				employeeAccountNumber: data.employeeAccountNumber,
				hrManagerPhone: data.hrManagerPhone,
				employeePhone: data.employeePhone,
			}

			//setData(null);

			handleSignWithoutEmployee(toSubmit, data.externalId)
		} else {
			handleSign({ termExternalId: selectedContract.termExternalId })
		}
	}

	const handleAddReasonClick = (value) => {
		setAdditionalReasons((value) => [...value, null])
	}

	const handleRemoveReasonClick = (index) => {
		setAdditionalReasons((value) => {
			const newArr = [...value]
			newArr.splice(index, 1)
			return newArr
		})
	}

	const [disableContinue, setDisableContinue] = useState(false)
	const validateDate = (value) => {
		const valueFormat = getFormatDate(value)
		if (!valueFormat) setDisableContinue(false)

		if (!(valueFormat && getFormatDate(contract?.dateFrom))) return
		const dateFrom = new Date(getFormatDate(contract?.dateFrom))
		const valueDate = new Date(valueFormat)
		//setDisableContinue(valueDate < dateFrom || valueDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))
		setDisableContinue(valueDate < dateFrom)
		setAnnuity(
			!!(
				isHarmfulWorkingConditions(dworkingConditionsCode) &&
				new Date(...value.split('.').reverse()).getFullYear() >= 2024 &&
				birthDate &&
				new Date().getFullYear() - new Date(...birthDate.split('.').reverse()).getFullYear() >= 55
			)
		)
	}

	const lastTerminationReasons = useMemo(() => terminationReasons?.filter(({ last }) => !!last), [terminationReasons])
	const employeeOrEmployer = () => {
		if (isCreation) return activeRole === 'ROLE_EMPLOYER'
		return initEmployer
	}
	const handleOpenAdditional = () => {
		history.push(`/contracts`, { refValue: contractId, local: false })
		setActiveTab(0)
		onClose()
	}
	return (
		<>
			{isLoading && <LoadingBlocker />}
			<Dialog scroll={'paper'} classes={{ paper: styles.dialog__template }} onClose={onClose} open={true}>
				<DialogTitle>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Button
							startIcon={<ArrowBackIosIcon />}
							classes={{ root: cn(styles.button_etd, styles.back__button) }}
							variant="text"
							onClick={onClose}
						>
							{t('back')}
						</Button>
						<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Box className={styles.header}>
						<div className={styles.etd__detailInformation}>
							<div className={styles.etd__title}>
								{t('electronic_contract_number', { contractNumber: contractNumber, registerDate: registerDate })}
							</div>
							<div>
								<SignStatus item={isState ? isState : { code: '1', nameRu: '' }} />
							</div>
						</div>
						<div className={styles.id__wrapper}>ID {externalId}</div>
						<div className={styles.date__wrapper}>
							{t('esutd_reg_date')}: {registerDate}
						</div>
					</Box>

					<div className={styles.etd__title}>{t('parties_requisites')}</div>

					<Grid classes={{ root: styles.requisites__container }} container spacing={2}>
						<Grid item xs={7}>
							<div className={styles.requisites__col__title}>{t('employer')}:</div>
							<Grid container spacing={2}>
								<Grid classes={{ root: styles.requisites__col__key }} item xs={3}>
									{t('iin_bin')}:
								</Grid>
								<Grid item xs={5}>
									{bin ?? t('not_indicated')}
								</Grid>
							</Grid>

							<Grid container spacing={2}>
								<Grid classes={{ root: styles.requisites__col__key }} item xs={3}>
									{t('name')}
								</Grid>
								<Grid item xs={7}>
									{employerName}
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={5}>
							<div className={styles.requisites__col__title}>{t('employee')}:</div>
							<Grid container spacing={2}>
								<Grid classes={{ root: styles.requisites__col__key }} item xs={3}>
									{t('iin_bin')}:
								</Grid>
								<Grid item xs={5}>
									{iin ?? t('not_indicated')}
								</Grid>
							</Grid>

							<Grid container spacing={2}>
								<Grid classes={{ root: styles.requisites__col__key }} item xs={3}>
									{t('fio') + ':'}
								</Grid>
								<Grid item xs={8}>
									{fullName}
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<div className={styles.etd__title}>
						{createTerminationApp ? t('terminate_application') : t('e_term_contract')}
					</div>

					<Formik
						initialValues={
							activeRole === 'ROLE_EMPLOYEE'
								? {
										terminationDate: null,
										statement:
											statement ??
											(language === 'rus'
												? 'Прошу Вас уволить меня по собственному желанию'
												: 'Сізден мені өз қалауыңыз бойынша жұмыстан шығаруыңызды сұраймын'),
										...contract,
								  }
								: {
										terminationDate: null,
										terminationReasons: null,
										statement: statement,
										...contract,
								  }
						}
						onSubmit={onSubmit}
						validationSchema={
							activeRole == 'ROLE_EMPLOYEE' ? contractTerminationApplicationSchema : contractTerminationSchema
						}
					>
						{({ isValid, values, setFieldValue }) => {
							validateDate(values.terminationDate)
							return (
								<Form>
									<Grid classes={{ root: styles.requisites__container }} container spacing={2}>
										<Grid item xs={5}>
											<label className={styles.label}>{t('terminate_date')}*</label>
											<Field name="terminationDate">
												{({ field }) => (
													<DatePicker
														minDate={new Date()}
														{...field}
														showIcon
														// maxDate={new Date()}
														disabled={!isCreation}
														value={termDate?.split('-').reverse().join('') || null}
													/>
												)}
											</Field>
										</Grid>
										{employeeOrEmployer() ? (
											<Grid item xs={7}>
												<label className={styles.label}>{t('contract_termination_reason')}</label>
												<div className={styles.selectCol}>
													{additionalReasons.map((item, index) => {
														return (
															<div key={`additonal-resaons-${index}`}>
																<AdditonalReasonSelect
																	index={index}
																	setAdditionalReasons={setAdditionalReasons}
																	value={item}
																	terminationReasons={terminationReasons}
																	removeReason={handleRemoveReasonClick}
																/>
															</div>
														)
													})}
													{isCreation && (
														<div className="flex">
															<Field name="terminationReason">
																{({ field }) => (
																	<SelectSearch
																		disabled={!isCreation}
																		{...field}
																		className="flex-1"
																		options={lastTerminationReasons}
																	/>
																)}
															</Field>
															<AppButton
																disabled={!isCreation}
																transparent
																onClick={handleAddReasonClick}
																buttonClassName={styles.button}
															>
																<Add className={styles.iconGreen} />
																{t('add')}
															</AppButton>
														</div>
													)}

													{!isCreation &&
														termReasonsList &&
														termReasonsList.map((reason) => {
															return (
																<div className="flex">
																	<Field key={reason?.id} name="terminationReason">
																		{({ field }) => (
																			<SelectSearch
																				disabled={!isCreation}
																				{...field}
																				value={reason?.nameRu}
																				className="flex-1"
																				options={lastTerminationReasons}
																			/>
																		)}
																	</Field>
																</div>
															)
														})}
												</div>
											</Grid>
										) : (
											<Grid item xs={12}>
												<Field name="statement">
													{({ field, meta: { touched, error } }) => (
														<TextField
															type="text"
															fieldType={'textarea'}
															isRequired
															label={t('military.comment')}
															showCount
															disabled={!isCreation}
															maxLength={3500}
															rows={6}
															{...field}
														/>
													)}
												</Field>
											</Grid>
										)}

										{/* <Grid item xs={1}>
								Добавить
							</Grid> */}
									</Grid>
									{(values.terminationDate || termDate) && (annuity || !isCreation) && (
										<HarmfulCheckboxBlock isEdit={!isCreation} annuity={annuity} />
									)}
									{!isCreation && note && (
										<>
											<div className={styles.etd__title}>{t('corruption.cause')} </div>
											<div className={styles.cause__text}>
												{note}
												{/* //! reason - object or string? */}
											</div>
										</>
									)}

									{activeRole == 'ROLE_EMPLOYER' && (
										<div className="flex-1 mt-16">
											<Field name="terminationWithoutEmployeesConsent">
												{({ field }) => (
													<Checkbox
														{...field}
														label={t('contract_termination_without_employees_consent')}
														disabled={!isCreation}
														onChange={() => setFieldValue(field.name, !field.value)}
														value={field.value}
													/>
												)}
											</Field>
										</div>
									)}

									{activeRole == 'ROLE_EMPLOYEE' && isCreation && (
										<div className={styles.buttonBlock}>
											<AppButton transparent onClick={onClose}>
												{t('cancel')}
											</AppButton>
											<AppButton disabled={!isValid} type="submit">
												{t('sign_to_sign_employer')}
											</AppButton>
										</div>
									)}

									{activeRole == 'ROLE_EMPLOYER' && isCreation && !values.terminationWithoutEmployeesConsent && (
										<div className={styles.buttonBlock}>
											<AppButton transparent onClick={onClose}>
												{t('cancel')}
											</AppButton>
											<AppButton disabled={!isValid || disableContinue} type="submit">
												{t('send_to_sign_worker')}
											</AppButton>
										</div>
									)}
									{activeRole == 'ROLE_EMPLOYER' && isCreation && values.terminationWithoutEmployeesConsent && (
										<div className={styles.buttonBlock}>
											<AppButton disabled={!isValid || disableContinue} onClick={() => openSignWindow(values)}>
												{t('actions.sign')}
											</AppButton>
										</div>
									)}

									{!isCreation && activeRole === 'ROLE_EMPLOYER' && (
										<div className={styles.buttonBlock}>
											{(isState.code === '4' || isState.code === '15') && (
												<span className={styles.button__wrapper}>
													<AppButton onClick={setOpenRevoke.bind(null, true)}>{t('actions.revoke')}</AppButton>
												</span>
											)}
											{!['2', '3', '4', '7'].includes(isState.code) && (
												<span className={styles.button__wrapper}>
													<AppButton onClick={handleOpenAdditional}>{t('notification_open_contract')}</AppButton>
												</span>
											)}
											{isState.code === '7' && (
												<>
													<span className={styles.button__wrapper}>
														<AppButton onClick={setOpenDecline.bind(null, true)}>{t('actions.reject')}</AppButton>
													</span>
													<span className={styles.button__wrapper}>
														<AppButton onClick={setOpenSign.bind(null, true)}>{t('actions.sign')}</AppButton>
													</span>
												</>
											)}
										</div>
									)}

									{!isCreation && activeRole === 'ROLE_EMPLOYEE' && (
										<div className={styles.buttonBlock}>
											{(isState.code === '7' || isState.code === '15') && (
												<span className={styles.button__wrapper}>
													<AppButton onClick={setOpenRevoke.bind(null, true)}>{t('actions.revoke')}</AppButton>
												</span>
											)}
											{!['2', '3', '4', '7', '15'].includes(isState.code) && (
												<span className={styles.button__wrapper}>
													<AppButton onClick={handleOpenAdditional}>{t('notification_open_contract')}</AppButton>
												</span>
											)}
											{isState.code === '4' && (
												<>
													<span className={styles.button__wrapper}>
														<AppButton onClick={setOpenDecline.bind(null, true)}>{t('actions.reject')}</AppButton>
													</span>
													<span className={styles.button__wrapper}>
														<AppButton onClick={setOpenSign.bind(null, true)}>{t('actions.sign')}</AppButton>
													</span>
												</>
											)}
										</div>
									)}
								</Form>
							)
						}}
					</Formik>
				</DialogContent>

				{openRevoke && (
					<Revoke selectedContract={selectedContract} onUpdate={onUpdate} onClose={setOpenRevoke.bind(null, false)} />
				)}
				{openDecline && (
					<Decline selectedContract={selectedContract} onUpdate={onUpdate} onClose={setOpenDecline.bind(null, false)} />
				)}
				{openSign && (
					<Sign
						selectedContract={selectedContract}
						onUpdate={onUpdate}
						//onSignClick={onSignClick}
						onClose={setOpenSign.bind(null, false)}
					/>
				)}
				{openTerminate && <Terminate onClose={onClose} />}
			</Dialog>
		</>
	)
}
