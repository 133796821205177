import styles from './DigitizationModal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title, Button } from '@/ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DigitizationTable from './table/DigitizationTable'
import DigitizationFormModal from './form/DigitizationFormModal'
import { OpvModal } from './opv/OpvModal'
import { useDigitalSign } from '@/shared'
import { useDispatch, useSelector } from 'react-redux'
import { laborRecordsActions, laborRecordsSelectors, laborRecordsThunks } from '@/entity'
import { Question } from '@/components/question/Question'

export const DigitizationModal = ({ singed = false, items = [], id = '', onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isOpenForm, setOpenForm] = useState(false)
	const [isOpenOpv, setOpenOpv] = useState(false)
	const [isNew, setStatus] = useState(false)
	const [list, setList] = useState(Object.assign([], items))
	const [edit, setEdit] = useState(null)
	const [index, setEditIndex] = useState(null)
	const create = !items.length
	const { onSign, successPayload, onClearPayload } = useDigitalSign()
	const isSuccess = useSelector(laborRecordsSelectors.isAdjustmentXmlSuccess)
	const isLoading = useSelector(laborRecordsSelectors.isAdjustmentXmlLoading)
	const xml = useSelector(laborRecordsSelectors.adjustmentXml)
	const isRefreshContracts = useSelector(laborRecordsSelectors.isRefreshContracts)
	const [sign, setSign] = useState(false)
	const [disableSave, setDisableSave] = useState(false)
	const [deletedIds, setDeletedIds] = useState([])
	const handleProcess = () => {
		setSign(true)
	}
	const closeProcess = () => {
		setSign(false)
		dispatch(laborRecordsActions.resetAdjustmentXml())
	}
	const fullClear = () => {
		dispatch(laborRecordsActions.resetAdjustmentXml())
		onClearPayload()
	}

	useEffect(() => {
		list.length ? setDisableSave(false) : setDisableSave(true)
	}, [list])

	useEffect(() => {
		sign && xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [sign, xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])

	useEffect(() => {
		if (successPayload) {
			dispatch(
				laborRecordsThunks.sendContractDigit({
					signedXml: successPayload,
					isRefreshContracts: isRefreshContracts,
					onClose: onClose,
				})
			).then(closeProcess())
		}
	}, [successPayload])

	const openForm = () => {
		setEdit(null)
		setStatus(list.length ? false : true)
		setOpenForm(true)
	}

	const getAction = (mode, row, index) => {
		if (mode === 'edit') {
			setEditIndex(index)
			setEdit(row)
			setStatus(row.laborType.code === '1' ? true : false)
			setOpenForm(true)
		} else if (mode === 'remove') {
			if (row.id) {
				setList((list) => list.filter((item) => item.id !== row.id))
				setDeletedIds((deletedIds) => [...deletedIds, row.id])
			} else {
				setList((list) => list.filter((item, i) => i !== index))
			}
		}
	}
	const getLastPosition = () => {
		const item = list.length ? list.reduce((a, b) => (a.eventDate > b.eventDate ? a : b)) : ''
		return item.establishedPost
	}

	const getEventDates = () => {
		const first = list.find((a) => a.laborType.code === '1')
		const secondAndThirdFiltered = list.filter((a) => a.laborType.code === '2' || a.laborType.code === '3')
		const secondAndThird = secondAndThirdFiltered.length
			? secondAndThirdFiltered.reduce((a, b) => (a.eventDate > b.eventDate ? a : b))
			: ''
		const secondAndThirdMin = secondAndThirdFiltered.length
			? secondAndThirdFiltered.reduce((a, b) => (a.eventDate < b.eventDate ? a : b))
			: ''
		const four = list.find((a) => a.laborType.code === '4')
		const dates = {
			one: first ? first.eventDate : null,
			two: secondAndThird ? secondAndThird.eventDate : null,
			twoMin: secondAndThirdMin ? secondAndThirdMin.eventDate : null,
			four: four ? four.eventDate : null,
		}
		return dates
	}

	const addItem = (item) => {
		setList([...list, item])
		setOpenForm(false)
	}
	const saveItem = (item) => {
		list[index] = item
		setList(list)
		setOpenForm(false)
	}
	const saveInLocal = useCallback(
		(data) => !isLoading && dispatch(laborRecordsThunks.saveContractLocalDigit(data, isRefreshContracts, onClose)),
		[dispatch]
	)
	const singAndSend = useCallback((data) => !isLoading && dispatch(laborRecordsThunks.getContractDigitXml(data)), [])
	const singAndSendWithOutID = useCallback(
		(data) => !isLoading && dispatch(laborRecordsThunks.getContractDigitXmlWithOutID(data)),
		[]
	)
	const save = () => {
		saveInLocal(getItems())
	}
	const send = () => {
		id ? singAndSend(getItems()) : singAndSendWithOutID(getItems())
	}
	const getItems = () => {
		let items = {
			laborDigitalizationRecordList: list.map((e) => {
				return {
					...e,
					laborTypeCode: e.laborType.code,
				}
			}),
		}
		if (id) {
			items = { ...items, id: id }
		}
		if (deletedIds) {
			items = { ...items, detailDeletedIds: deletedIds }
		}
		return items
	}

	const questionText = {
		title: t('digitization.confirm.sign_contract.title'),
		data: t('digitization.confirm.sign_contract.data'),
	}

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>
							{create ? t('digitization.modal_title.create') : t('digitization.modal_title.edit')}
						</Title>
					</div>
					<DigitizationTable items={list} onClose={onClose} getAction={getAction} />
					<Button
						border
						borderColor={'default'}
						textColor={'default'}
						backgroundColor={'white'}
						onClick={() => openForm()}
					>
						{t('labor_records.add_record')}
					</Button>
					<div className={styles.footer}>
						<Button buttonClassName={styles.button} type="bare" onClick={onClose}>
							{t('cancel')}
						</Button>
						{!singed && (
							<Button
								border
								borderColor={'default'}
								textColor={'default'}
								backgroundColor={'white'}
								type={'button'}
								onClick={save}
								disabled={disableSave}
							>
								{t('save')}
							</Button>
						)}

						<Button type="button" onClick={send} disabled={disableSave}>
							{t('save_and_sign')}
						</Button>
					</div>
				</div>
			</Modal>
			{isOpenForm && (
				<DigitizationFormModal
					position={getLastPosition()}
					eventDates={getEventDates()}
					edit={edit}
					addItem={(item) => addItem(item)}
					saveItem={(item) => saveItem(item)}
					onOpenOpv={() => setOpenOpv(true)}
					onClose={() => setOpenForm(false)}
					isNew={isNew}
				/>
			)}
			{isOpenOpv && <OpvModal onClose={() => setOpenOpv(false)} />}
			{xml ? <Question data={questionText} handleProcess={handleProcess} handleCancel={closeProcess}></Question> : null}
		</>
	)
}
