export const headCells = [
	{
		id: 'name',
		numeric: true,
		label: 'contracts_tab.number',
	},
	{
		id: 'bin',
		numeric: true,
		label: 'col_contract.table.bin',
	},
	{
		id: 'name',
		numeric: false,
		label: 'col_contract.table.name',
	},
	{
		id: 'union_name',
		numeric: false,
		label: 'col_contract.table.union_name',
	},
	{
		id: 'date_from',
		numeric: false,
		label: 'col_contract.table.date_from',
	},
	{
		id: 'date_to',
		numeric: false,
		label: 'col_contract.table.date_to',
	},
	{
		id: 'state',
		numeric: true,
		label: 'contracts_tab.state',
	},
	{
		id: 'isElectronic',
		numeric: true,
		label: 'contracts_tab.type',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'col_contract.table.actions',
	},
]
