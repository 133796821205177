export const colors = {
	high: '#EB5757',
	medium: '#FFD500',
	low: '#5792EB',
	no: '#17B67C',
}
export const colorsTitle = {
	high: 'red',
	medium: 'yellow',
	low: 'blue',
	no: 'green',
}
