import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setEvaluations: (payload) => ({
		type: types.SET_EVALUATIONS,
		payload,
	}),
	setCalculation: (payload) => ({
		type: types.SET_CALCULATION,
		payload,
	}),
	setGeneratedPdf: (payload) => ({
		type: types.SET_GENERATED_PDF,
		payload,
	}),
	setFormInitialData: (payload) => ({
		type: types.SET_FORM_INITIAL_DATA,
		payload,
	}),
}
