export const defaultRoles = [
	{
		title: 'Работник',
		path: '/worker',
		label: 'ROLE_EMPLOYEE',
	},
	{
		title: 'Работодатель',
		path: '/employer',
		label: 'ROLE_EMPLOYER',
	},
]
