export const headCells = [
	{
		id: 'dateCreate',
		numeric: false,
		label: 'date_created',
	},
	{
		id: 'typeCode',
		numeric: true,
		label: 'profUnions.unionType',
	},
	{
		id: 'unionName',
		numeric: false,
		label: 'col_contract.union_name',
	},
	{
		id: 'statusCode',
		numeric: false,
		label: 'profUnions.unionStatus',
	},
	{
		id: 'managers',
		numeric: true,
		label: 'profUnions.count_manager',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'col_contract.table.actions',
	},
]
