import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RecoverModal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { Title } from '@/ui'
import { Formik } from 'formik'
import { contractsThunks, contractsActions } from '@/entity/contracts'
import { useDigitalSign } from '@/shared'
import { LoadingBlocker } from '@/components/Loader'

import * as Yup from 'yup'
import { Form } from './Form'
import { message } from 'antd'

const validationSchema = Yup.object().shape({
	restoreDate: Yup.string().required('required'),
	drestoreOrgTypeCode: Yup.string().required('required'),
	restoreOrder: Yup.string().when('drestoreOrgTypeCode', {
		is: (value) => ['1', '2'].includes(value),
		then: Yup.string().required('required'),
	}),
	dcourtCode: Yup.string().when('drestoreOrgTypeCode', {
		is: '1',
		then: Yup.string().required('required'),
	}),
	restoreNote: Yup.string().when('drestoreOrgTypeCode', {
		is: '3',
		then: Yup.string().required('required'),
	}),
})

export const Recover = ({ onClose }) => {
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { successPayload, onSign, failedPayload } = useDigitalSign()

	const contract = useSelector(contractActionsSelectors.contractView())
	const { restoreOrganizationTypes, recoverXML, restoreOrganizationCourts } = useSelector((state) => ({
		restoreOrganizationTypes: state.handbook.restoreOrganizationTypes,
		restoreOrganizationCourts: state.handbook.restoreOrganizationCourts,
		recoverXML: state.contracts.recoverXML,
	}))

	useEffect(() => {
		if (successPayload) {
			dispatch(
				contractsThunks.recover({
					signedXml: successPayload,
					entityId: contract?.id,
					externalId: contract?.externalId,
				})
			)
		}
	}, [successPayload])

	useEffect(() => {
		if (failedPayload) {
			setIsLoading(false)
		}
	}, [failedPayload])

	useEffect(() => {
		if (recoverXML?.data) {
			onSign(recoverXML.data)
		}

		if (recoverXML.response) {
			setIsLoading(false)
			dispatch(
				contractsActions.updateContractAfterRecover({
					status: { id: 1, code: '1', nameRu: 'Действующий', nameKz: 'Қолданыстағы' },
					externalId: contract.externalId,
				})
			)
			message.success(t('success_alert'))
			dispatch(contractsActions.setRecoverXML({ response: null, error: null, data: null }))
			onClose()
		}

		if (recoverXML.error) {
			setIsLoading(false)
			message.error(recoverXML.error)
			dispatch(contractsActions.setRecoverXML({ response: null, error: null, data: null }))
		}
	}, [onSign, recoverXML])

	const onSubmit = (data) => {
		setIsLoading(true)
		dispatch(contractsThunks.generateRecoverXML(data))
	}

	const initialValues = {
		bin: contract?.bin,
		contractId: contract?.id,
		drestoreOrgTypeCode: '',
		externalId: contract?.externalId,
		restoreDate: '',
		restoreOrder: '',
		dcourtCode: '',
		restoreNote: '',
		terminationDate: contract?.terminationDate,
	}

	if (!contract) return <LoadingBlocker />

	return (
		<>
			{isLoading && <LoadingBlocker />}
			<Modal show={true} windowClassName={styles.modal} onClose={onClose}>
				<ModalBackButton onClick={onClose} />
				<Title color="black">
					{t('contracts_tab.contract_recover_information', {
						contractNumber: contract?.contractNumber || ' ',
						registerDate: contract?.registerDate || ' ',
					})}
				</Title>
				<div className={styles.worker__info__wrapper}>
					<div className={styles.worker__info__item}>
						<div className={styles.worker__info__item__title}>{t('contracts_tab.recover.worker_iin')}</div>
						<div className={styles.worker__info__item__title}>{t('contracts_tab.recover.worker_fio')}</div>
					</div>

					<div>
						<div className={styles.worker__info__item__value}>{contract?.iin}</div>
						<div className={styles.worker__info__item__value}>{contract?.fullName}</div>
					</div>
				</div>

				<div className={styles.contarct__info}>
					<div className={styles.contarct__info__title}>{t('contracts_tab.recover.contract_info')}</div>

					<div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							enableReinitialize
							onSubmit={onSubmit}
						>
							<Form
								restoreOrganizationTypes={restoreOrganizationTypes}
								restoreOrganizationCourts={restoreOrganizationCourts}
								contract={contract}
								onClose={onClose}
							/>
						</Formik>
					</div>
				</div>
			</Modal>
		</>
	)
}
