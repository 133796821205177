import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from '@/pages'
import { AuthorisePassport, LocalStorageProvider, NotificationWrapper } from '@/processes'
import './styles/main.scss'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'
import ScrollToTop from '@/components/entrance/ScrollToTop' // theme css file
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const App = () => {
	const {
		t,
		i18n: { language, changeLanguage },
	} = useTranslation()
	const storedLanguage = localStorage.getItem('lang')
	useEffect(() => {
		if (storedLanguage && storedLanguage !== language) {
			changeLanguage(storedLanguage)
		}
	}, [])
	document.title = t('portal_employment_source')
	return (
		<LocalStorageProvider>
			<AuthorisePassport>
				<NotificationWrapper>
					<ScrollToTop />
					<Switch>
						{routes.map(({ path, exact, Component }) => (
							<Route key={path} exact={exact} path={path} render={() => <Component />} />
						))}
						<Redirect to={'/'} />
					</Switch>
				</NotificationWrapper>
			</AuthorisePassport>
		</LocalStorageProvider>
	)
}
