import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared'
import { format, isAfter, subYears } from 'date-fns'
import { formates } from '@app/config'

/*ФЛК
Легенда:
+ - реализовано в виде подсказок при вводе yup
- - реализовано в виде сообщений в модальном окне при подписании
Для всех типов СО:

Не работал с:
-не должно быть раньше даты заключения контракта +
-не должно быть позднее текущей даты +
-не должно быть позднее значения поля "Не работал по" +

--------

Тип СО - В связи с беременностью и рождением ребенка

Не работал по:
-не должно быть ранее 50 лет от текущей даты +
-не должно быть позднее текущей даты +
-не должно быть раньше значения поля "Не работал с" -
-не должно быть позднее значения поля "Дата первого рабочего дня" +
-не должно быть более 184 дня от поля "Не работал с" -

Дата первого рабочего дня:
-не должно быть раньше даты заключения контракта +
-не должно быть позднее текущей даты +
-не должно быть ранее 50 лет от текущей даты +

--------

Тип СО - Без сохр ЗП по уходу за ребенком до достижения им возраста 3 лет

Не работал по:
-не должно быть ранее 50 лет от текущей даты +
-не должно быть позднее текущей даты +
-не должно быть раньше значения поля "Не работал с" -

Выходные дни: - числа целые и положительные +*/

yup.addMethod(yup.date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, value: format(dateBefore, formates.date, new Date()) } })
			: true
	})
})
export const RequestSocialSchema = (contractDate) =>
	yup.object().shape({
		contractNumber: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('contractNumberIs', {
				is: (contractNumberIs) => !!contractNumberIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		dpositionCode: yup.string().when('dpositionCodeIs', {
			is: (dpositionCodeIs) => !!dpositionCodeIs === true,
			then: yup.string().required({ key: 'required' }),
		}),
		establishedPost: yup.string().when('establishedPositionIs', {
			is: (establishedPositionIs) => !!establishedPositionIs === true,
			then: yup.string().required({ key: 'required' }),
		}),
		terminationDate: transformStringDateFormatToDate().when(
			['contractDate', 'terminationDateIs'],
			(contractDate, terminationDateIs) => {
				if (contractDate && !!terminationDateIs)
					return transformStringDateFormatToDate()
						.min(contractDate, { key: 'date.end_before_start' })
						.max(new Date(), { key: 'date.can_not_be_after_now' })
						.required({ key: 'required' })
			}
		),
		terminationReason: yup.string().when('terminationReasonIs', {
			is: (terminationReasonIs) => !!terminationReasonIs === true,
			then: yup.string().required('required'),
		}),
		phone: yup.string().required('required'),
		email: yup.string().email('invalid_email').required('required'),
		comment: yup.string().required('required'),
		additionalContracts: yup
			.array()
			.of(
				yup.object().shape({
					subsidiaryContractNumber: yup
						.number()
						.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
						.when('subsidiaryContractNumberIs', {
							is: (subsidiaryContractNumberIs) => !!subsidiaryContractNumberIs === true,
							then: yup.number().required({ key: 'required' }),
						}),
					dposition: yup.object().when('dpositionIs', {
						is: (dpositionIs) => !!dpositionIs === true,
						then: yup.object().required({ key: 'required' }),
					}),
					establishedPost: yup.string().when('establishedPositionIs', {
						is: (establishedPositionIs) => !!establishedPositionIs === true,
						then: yup.string().required({ key: 'required' }),
					}),
				})
			)
			.nullable(),
		socialLeaves: yup
			.array()
			.of(
				yup.object().shape({
					beginDate: transformStringDateFormatToDate()
						.when('beginDateIs', {
							is: (beginDateIs) => !!beginDateIs === true,
							then: transformStringDateFormatToDate()
								.min(contractDate, { key: 'labor.date.min_contract' })
								.max(new Date(), { key: 'labor.date.max' })
								.required({ key: 'required' }),
						})
						.when(['beginDateIs', 'endDate'], (beginDateIs, endDate) => {
							if (endDate && beginDateIs)
								if (new Date() > new Date(endDate))
									return transformStringDateFormatToDate()
										.max(endDate, { key: 'labor.date.max_contract' })
										.required({ key: 'required' })
						}),
					endDate: transformStringDateFormatToDate().when(['endDateIs', 'workDate'], (endDateIs, workDate) => {
						if (endDateIs === true) {
							if (workDate != null)
								return transformStringDateFormatToDate()
									.max(new Date(workDate), { key: 'labor.date.max_work_date' })
									.minDateBefore({ yearsBefore: 50, error: 'labor.date.min' })
									.required({ key: 'required' })
							else
								return transformStringDateFormatToDate()
									.max(new Date(), { key: 'labor.date.max' })
									.minDateBefore({ yearsBefore: 50, error: 'labor.date.min' })
									.required({ key: 'required' })
						}
					}),
					workDate: transformStringDateFormatToDate().when('workDateIs', {
						is: (workDateIs) => !!workDateIs === true,
						then: transformStringDateFormatToDate()
							.max(new Date(), { key: 'labor.date.max' })
							.min(contractDate, { key: 'labor.date.min_contract' })
							.minDateBefore({ yearsBefore: 50, error: 'labor.date.min' })
							.required({ key: 'required' }),
					}),
					daysOffCount: yup
						.number({ key: 'labor.number.weekend' })
						.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
						.when('daysOffCountIs', {
							is: (daysOffCountIs) => daysOffCountIs === true,
							then: yup.number().required({ key: 'required' }).min(1, { key: 'labor.number.weekend' }),
						}),
				})
			)
			.nullable(),
	})
