import { actions } from './actions'
import { api } from '@/shared'

export const searchSectionForms = (payload, captcha) => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.getSections(payload, captcha)
			dispatch(actions.themesLoading(response))
		} catch (error) {}
	}
}

export const searchQuestions = (payload) => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.getQuestions(payload)
			dispatch(actions.questionsLoading(response))
		} catch (error) {}
	}
}

export const setActiveTheme = (code, label) => {
	return async (dispatch) => {
		try {
			if (code != null) {
				dispatch(actions.activeTheme(code))
				dispatch(actions.activeThemeLabel(label))
			}
		} catch (error) {
			dispatch(actions.activeTheme(null))
		}
	}
}

export const setStatusCheck = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.statusCheck(payload))
		} catch (error) {
			dispatch(actions.statusCheck(false))
		}
	}
}

export const setStatusSelect = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.statusSelect(payload))
		} catch (error) {
			dispatch(actions.statusSelect(false))
		}
	}
}

export const sendSelfTest = (payload, captcha) => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.returnResult(payload, captcha)
			dispatch(actions.selfTestResultResponse(response))
		} catch (error) {}
	}
}

export const sendAuthSelfTest = (payload) => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.returnResultAuth(payload)
			dispatch(actions.selfTestResultResponse(response))
		} catch (error) {}
	}
}

export const getPdf = (payload, lang) => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.getPdfResult(payload, lang)
			const file = window.URL.createObjectURL(response)
			const a = document.createElement('a')
			a.href = file
			a.download = response.name || 'detailPDF'
			document.body.appendChild(a)
			a.click()
			dispatch(actions.answerSelfTestLoading(response))
		} catch (error) {}
	}
}

export const getChecks = (sectionCode, page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.selftest.getChecks({ sectionCode }, page, size)
			dispatch(actions.setChecks(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getChecksAllResult = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.selftest.getChecksAll(params, { page, size })
			dispatch(actions.checksAll(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getCheckForCompany = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.selftest.getCheckInfo(id)
			dispatch(actions.setSelectedCheck({ ...response, id }))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getSectionForms = () => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.getSectionAuth()
			dispatch(actions.themesLoading(response))
		} catch (error) {}
	}
}

export const getResultTypes = () => {
	return async (dispatch) => {
		try {
			const response = await api.selftest.getDicResultTypes()
			dispatch(actions.resultTypes(response))
		} catch (error) {}
	}
}
