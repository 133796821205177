import { clearXml } from '@/app/store/GlobalStoreUtils'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

let webSocket = new WebSocket('wss://127.0.0.1:13579/')
const socketUrl = 'wss://127.0.0.1:13579/'

export function blockScreen() {
	const overlay = document.createElement('div')
	overlay.setAttribute('id', 'blockUIOverlay')
	document.body.appendChild(overlay)
	overlay.innerHTML = `<img src="lib/loading.gif" style={{width: '30%' height: '30%'}}/><br/>${'Подождите, выполняется операция в NCALayer...'}`
	overlay.style.display = 'block'
	overlay.style.position = 'fixed'
	overlay.style.top = '0'
	overlay.style.left = '0'
	overlay.style.width = '100%'
	overlay.style.height = '100%'
	overlay.style.background = 'rgba(0, 0, 0, 0.5)'
	overlay.style.color = '#fff'
	overlay.style.textAlign = 'center'
	overlay.style.paddingTop = '20%'
	overlay.style.zIndex = '5000'
}
export function unblockScreen() {
	const overlay = document.getElementById('blockUIOverlay')
	if (overlay) {
		overlay.remove()
	}
}

export const useDigitalSign = () => {
	const { t } = useTranslation()
	const [successPayload, setSuccessPayload] = useState(null)
	const [ecpInfo, setEcpInfo] = useState(null)
	const [failedPayload, setFailedPayload] = useState(null)
	const [actionType, setActionType] = useState(null)

	const dispatch = useDispatch()

	const startSocket = function (callback) {
		callback = callback || function () {}

		if (!webSocket || webSocket.readyState !== webSocket.OPEN) {
			//веб сокет успешно запущен
			webSocket = new WebSocket(socketUrl)

			webSocket.onopen = function (event) {
				callback(event)
			}
		}

		//веб сокет сломался
		webSocket.onclose = function (event) {
			if (!event.wasClean) {
				setFailedPayload(event.timeStamp)
				alert(t('ncalayer_info'))
			}
		}
	}

	useEffect(() => {
		webSocket.onmessage = function (event) {
			const result = JSON.parse(event.data)
			if (result) {
				const data = {
					code: result['code'],
					message: result['message'],
					responseObject: result['responseObject'],
					getResult: function () {
						return this.result
					},
					getMessage: function () {
						return this.message
					},
					getResponseObject: function () {
						return this.responseObject
					},
					getCode: function () {
						return this.code
					},
				}

				unblockScreen()
				if (data['code'] === '500') {
					setFailedPayload({ message: data['message'] })
				} else if (data['code'] === '200') {
					if (actionType === 'info') {
						setEcpInfo(data['responseObject'])
					} else if (actionType === 'sign') {
						setSuccessPayload(data['responseObject'])
					}
				}
			}
		}
	}, [actionType])

	const onSign = useCallback((dataToSign) => {
		if (!webSocket || webSocket.readyState !== webSocket.OPEN) {
			startSocket(function () {
				onSign(dataToSign)
			})
			return
		}
		blockScreen()
		const signXml = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'signXml',
			args: ['PKCS12', 'SIGNATURE', dataToSign, '', ''],
		}
		setActionType('sign')
		webSocket.send(JSON.stringify(signXml))
	}, [])

	const onSignMultiple = useCallback((dataToSign, callback) => {
		if (!webSocket || webSocket.readyState !== webSocket.OPEN) {
			startSocket(function () {
				onSignMultiple(dataToSign, callback)
			})
			return
		}
		blockScreen()
		const signXmls = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'signXmls',
			args: ['PKCS12', 'SIGNATURE', dataToSign, '', ''],
		}
		setActionType('sign')
		webSocket.send(JSON.stringify(signXmls))
	}, [])

	const getKeyInfoCall = useCallback((callback) => {
		if (!webSocket || webSocket.readyState !== webSocket.OPEN) {
			startSocket(function () {
				getKeyInfoCall(callback)
			})
			return
		}
		blockScreen()
		let getKeyInfo = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'getKeyInfo',
			args: ['PKCS12'],
		}
		setActionType('info')
		webSocket.send(JSON.stringify(getKeyInfo))
	}, [])

	const onClearPayload = useCallback(() => {
		setSuccessPayload(null)
		setEcpInfo(null)
		setActionType(null)
	}, [])

	useEffect(() => {
		return () => dispatch(clearXml())
	}, [successPayload])

	return {
		successPayload,
		failedPayload,
		onSign,
		onClearPayload,
		getKeyInfoCall,
		ecpInfo,
		onSignMultiple,
	}
}
