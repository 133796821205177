export const SET_NOTIFICATIONS = 'notifications/SET_NOTIFICATIONS' //список уведомлений в разделе
export const SET_NOTIFICATIONS_BADGE = 'notifications/SET_NOTIFICATIONS_BADGE' //список уведомлений в заголовке
export const RESET_NOTIFICATIONS_BADGE = 'notifications/RESET_NOTIFICATIONS_BADGE' //сбросить список уведомлений в заголовке
export const SET_NOTIFICATION_READ = 'notifications/SET_NOTIFICATION_READ' //пометить как прочитано
export const SET_NOTIFICATION_READ_FRON_UNREAD_PANEL = 'notifications/SET_NOTIFICATION_READ_FRON_UNREAD_PANEL' //пометить как прочитано с панели непрочитанных уведомлений
export const SET_NOTIFICATION_UNREAD = 'notifications/SET_NOTIFICATION_UNREAD' //пометить как неПрочитано
export const SET_ERROR = 'notifications/SET_ERROR'
export const SET_QUERY_PARAMS = 'notifications/SET_QUERY_PARAMS'
export const SET_TAGS = 'notifications/SET_TAGS'
export const SET_ALL = 'notifications/SET_ALL' // новые уведомления, все
export const TOGGLE_LOADING = 'notifications/TOGGLE_LOADING' //загрузка
export const TOGGLE_SUCCESS = 'notifications/TOGGLE_SUCCESS' //успешно завершенно
