import styles from './RequestPersonalAffairForm.module.scss'
import { Button, Field, Title } from '@/ui'
import { Formik, Field as FormikField, Form as FormikForm } from 'formik'
import { formFieldNames } from '@/entity'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IINValidationScheme } from '@/shared'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { getFLData } from '@/shared/api/rest/contracts'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { message } from 'antd'

export const RequestPersonalAffairForm = ({ onClose, onSubmit }) => {
	const { t } = useTranslation()
	const [isFormDirty] = useState(false)
	const [codes, setCodes] = useState([])
	const { bin } = useSelector(employerProfileSelectors.activeCompany)

	const initialValues = {
		[formFieldNames.iin]: '',
		[formFieldNames.employeeInitials]: '',
	}

	const selectAll = () =>
		setCodes((prev) =>
			prev.length === 10 ? [] : ['001', '002', '004', '005', '006', '007', '009', '010', '014', '017',
				// todo раскомментить 31.10.2024 branch: reviews
				// '018'
			]
		)
	const toggleCheckbox = (code) =>
		setCodes((prev) => (prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code]))

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={IINValidationScheme}
			onSubmit={(data) => onSubmit({ ...data, bin, methods: codes })}
		>
			{({ setFieldValue, values, ...form }) => {
				const getNameByIin = async () => {
					try {
						const response = await getFLData({ iin: values.iin })
						setFieldValue(formFieldNames.employeeInitials, response.fullNamePerson || '')
					} catch (error) {
						const err = error?.response?.data?.message || 'Неизвестная ошибка'
						message.error(err ? err : 'Неизвестная ошибка', 5)
					}
				}

				return (
					<FormikForm>
						<section>
							<div className={styles.item}>
								<Title>{t('employee_data')}</Title>
								<div className={styles.fields}>
									<FormikField name={formFieldNames.iin}>
										{({ field, meta: { touched, error } }) => (
											<div className={styles.fieldAndButton}>
												<Field
													hideNumberArrows
													isRequired
													label={t('employee_yin')}
													placeholder={t('enter_count_digitals', { count: 12 })}
													error={(touched || isFormDirty) && error}
													{...field}
													maxLength={12}
													pattern="[0-9]{12}"
													onInput={(e) => {
														e.target.value = e.target.value.replace(/[^0-9]/g, '')
													}}
												/>
												<div>
													<Button
														type="button"
														onClick={getNameByIin}
														disabled={!!(touched && error) || values.iin === ''}
													>
														{t('find')}
													</Button>
												</div>
											</div>
										)}
									</FormikField>
									<FormikField name={formFieldNames.employeeInitials}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												isRequired
												disabled
												label={t('employee_initials')}
												placeholder={t('filled_automate')}
												{...field}
												error={(touched || isFormDirty) && error}
											/>
										)}
									</FormikField>
									<div className={styles.fieldAndButton}></div>
								</div>
							</div>
						</section>
						<section>
							<div className={styles.item}>
								<Title>{t('personal_affairs.request.form.choose_data_label')}</Title>
								<div className={styles.col}>
									<WrappedCheckbox onChange={selectAll} value={codes.length === 10} label={t('select_all')} />
									<WrappedCheckbox
										onChange={() => toggleCheckbox('001')}
										value={codes.includes('001')}
										label={t('personal_affairs.request.form.id_label')}
									/>
									<WrappedCheckbox
										onChange={() => toggleCheckbox('010')}
										value={codes.includes('010')}
										label={t('personal_affairs.request.form.photo_label')}
									/>
									<WrappedCheckbox
										onChange={() => toggleCheckbox('002')}
										value={codes.includes('002')}
										label={t('personal_affairs.request.form.education_label')}
									/>
									<WrappedCheckbox
										onChange={() => toggleCheckbox('007')}
										value={codes.includes('007')}
										label={t('personal_affairs.request.form.employment_label')}
									/>
									<WrappedCheckbox
										onChange={() => toggleCheckbox('009')}
										value={codes.includes('009')}
										label={t('personal_affairs.request.form.medicine_label')}
									/>
									<WrappedCheckbox
										onChange={() => {
											toggleCheckbox('004')
											toggleCheckbox('005')
											toggleCheckbox('006')
										}}
										value={codes.includes('004') && codes.includes('005') && codes.includes('006')}
										label={t('personal_affairs.request.form.medicine_drug_label')}
									/>
									<WrappedCheckbox
										onChange={() => toggleCheckbox('014')}
										value={codes.includes('014')}
										label={t('personal_affairs.request.form.status_candas')}
									/>
									{/*<WrappedCheckbox*/}
									{/*	onChange={() => toggleCheckbox('016')}*/}
									{/*	value={codes.includes('016')}*/}
									{/*	label={t('personal_affairs.request.form.committing_corruption_crime')}*/}
									{/*/>*/}
									<WrappedCheckbox
										onChange={() => toggleCheckbox('017')}
										value={codes.includes('017')}
										label={t('personal_affairs.request.form.military_service')}
									/>
									{/*todo раскомментить 31.10.2024 branch: reviews*/}
									{/*<WrappedCheckbox*/}
									{/*	onChange={() => toggleCheckbox('018')}*/}
									{/*	value={codes.includes('018')}*/}
									{/*	label={t('personal_affairs.request.form.reviews')}*/}
									{/*/>*/}
								</div>
							</div>
						</section>
						<div className={styles.buttonsRow}>
							<Button type="bare" onClick={onClose}>
								{t('cancel')}
							</Button>
							<Button disabled={codes.length == 0 || !values.fullName} type="submit">
								{t('sign_and_send')}
							</Button>
						</div>
					</FormikForm>
				)
			}}
		</Formik>
	)
}
