export const StateCode = {
	SIGNED: '1',
	TERMINATED: '2',
	IN_DEVELOP: '3',
	ON_SIGN_EMPLOYEE: '4',
	IN_PROGRESS: '5',
	REJECT_EMPLOYEE: '6',
	ON_SIGN_EMPLOYER: '7',
	REJECT_EMPLOYER: '8',
	EXPIRED: '9',
}
export const inProcess = (code) => [StateCode.IN_DEVELOP, StateCode.IN_PROGRESS].includes(code)
