import styles from '@/entity/grading/positionEvaluation/ui/form/PositionEvaluationForm.module.scss'
import * as React from 'react'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import classnames from 'classnames'

export const Functions = ({ data, removeFunction }) => {
	const currentLanguage = getCurrentLanguage()
	const lang = useMemo(() => (currentLanguage === 'rus' ? 'nameRu' : 'nameKz'), [currentLanguage])
	const { t } = useTranslation()
	return (
		<div className={classnames('scroll', styles.functions_container)}>
			{data.length > 0 ? (
				data.map((item, index) => (
					<div key={index} className={styles.functions_container_item}>
						<div className={styles.functions_container_item_content}>
							<p>Функция: {item.fn[lang]}</p>
							<p>
								Фактор: {item.criteria[lang]}. {item.factor[lang]}. {item.functionalDuty[lang]}
							</p>
						</div>
						{removeFunction && (
							<button
								onClick={(e) => {
									e.preventDefault()
									removeFunction(index)
								}}
							>
								×
							</button>
						)}
					</div>
				))
			) : (
				<div className={styles.functions_container_item}>{t('position_evaluation_page.form.selectFunction')}</div>
			)}
		</div>
	)
}
