import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import Header from '@Root/components/Header/index'
import { Title } from '@Root/components/Typography'
import Tabs, { TabPane } from 'rc-tabs'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionDetails, createTheme, ThemeProvider } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Footer from '@/components/home/components/Footer'
import { getCurrentLanguage } from '@/i18next'
import PreHeader from '@/components/home/components/PreHeader'

export const ReferenceInformationPage = () => {
	const [expanded, setExpanded] = useState(false)
	const [panel, setPanel] = useState('panel1')
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { key } = useParams()
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
		setPanel(panel)
	}

	//TODO переписать стили для компонена accordion
	let theme = createTheme({
		components: {
			MuiAccordion: {
				styleOverrides: {
					root: {
						marginBottom: '16px',
						boxShadow: 'none',
						'&:before': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: {
						backgroundColor: 'none',
						border: '2px solid #AAB8D1',
						borderRadius: '10px',
					},
				},
			},
		},
	})
	let theme1 = theme
	let theme2 = theme
	let theme3 = theme
	let theme4 = theme
	let theme5 = theme
	let theme6 = theme
	let theme7 = theme
	let theme8 = theme
	let theme9 = theme
	let theme10 = theme
	let theme11 = theme
	let theme12 = theme
	let theme13 = theme
	let theme14 = theme
	let theme15 = theme
	let theme16 = theme
	let theme17 = theme
	let theme18 = theme
	let theme19 = theme
	let theme20 = theme
	let theme21 = theme
	let theme22 = theme
	let theme23 = theme
	let theme24 = theme
	let theme25 = theme
	let theme26 = theme
	let theme27 = theme
	let theme28 = theme
	let theme29 = theme
	let theme30 = theme
	let theme31 = theme
	let theme32 = theme
	let theme33 = theme
	let theme34 = theme
	let theme35 = theme
	let theme36 = theme
	let theme37 = theme
	let theme38 = theme
	let theme39 = theme
	let theme40 = theme
	let theme41 = theme
	let theme42 = theme
	let theme43 = theme
	let theme44 = theme
	let theme45 = theme
	let theme46 = theme
	let theme47 = theme
	let theme48 = theme
	let theme49 = theme
	let theme50 = theme
	let theme51 = theme
	let theme52 = theme
	let theme53 = theme
	let theme54 = theme
	let theme55 = theme
	let theme56 = theme
	let theme57 = theme
	let theme58 = theme

	if (expanded) {
		let newTheme = createTheme({
			components: {
				MuiAccordion: {
					styleOverrides: {
						root: {
							borderLeft: '1px solid #AAB8D1',
							borderRight: '1px solid #AAB8D1',
							borderBottom: '1px solid #AAB8D1',
							borderRadius: '10px',
							'&:first-of-type': {
								borderLeft: '1px solid #AAB8D1',
								borderRight: '1px solid #AAB8D1',
								borderBottom: '1px solid #AAB8D1',
								borderRadius: '10px',
							},
						},
					},
				},
				MuiAccordionSummary: {
					styleOverrides: {
						root: {
							border: '2px solid #17B67C',
							borderRadius: '10px',
						},
					},
				},
				MuiAccordionDetails: {
					styleOverrides: {
						root: {
							boxShadow: 'none',
						},
					},
				},
				MuiSvgIcon: {
					styleOverrides: {
						root: {
							color: '#17B67C',
						},
					},
				},
			},
		})
		switch (panel) {
			case 'panel1':
				theme = newTheme
				break
			case 'panel':
				theme1 = newTheme
				break
			case 'panel2':
				theme2 = newTheme
				break
			case 'panel3':
				theme3 = newTheme
				break
			case 'panel4':
				theme4 = newTheme
				break
			case 'panel5':
				theme5 = newTheme
				break
			case 'panel6':
				theme6 = newTheme
				break
			case 'panel7':
				theme7 = newTheme
				break
			case 'panel8':
				theme8 = newTheme
				break
			case 'panel9':
				theme9 = newTheme
				break
			case 'panel10':
				theme10 = newTheme
				break
			case 'panel11':
				theme11 = newTheme
				break
			case 'panel12':
				theme12 = newTheme
				break
			case 'panel13':
				theme13 = newTheme
				break
			case 'panel14':
				theme14 = newTheme
				break
			case 'panel15':
				theme15 = newTheme
				break
			case 'panel16':
				theme16 = newTheme
				break
			case 'panel17':
				theme17 = newTheme
				break
			case 'panel18':
				theme18 = newTheme
				break
			case 'panel19':
				theme19 = newTheme
				break
			case 'panel20':
				theme20 = newTheme
				break
			case 'panel21':
				theme21 = newTheme
				break
			case 'panel22':
				theme22 = newTheme
				break
			case 'panel23':
				theme23 = newTheme
				break
			case 'panel24':
				theme24 = newTheme
				break
			case 'panel25':
				theme25 = newTheme
				break
			case 'panel26':
				theme26 = newTheme
				break
			case 'panel27':
				theme27 = newTheme
				break
			case 'panel28':
				theme28 = newTheme
				break
			case 'panel29':
				theme29 = newTheme
				break
			case 'panel30':
				theme30 = newTheme
				break
			case 'panel31':
				theme31 = newTheme
				break
			case 'panel32':
				theme32 = newTheme
				break
			case 'panel33':
				theme33 = newTheme
				break
			case 'panel34':
				theme34 = newTheme
				break
			case 'panel35':
				theme35 = newTheme
				break
			case 'panel36':
				theme36 = newTheme
				break
			case 'panel37':
				theme37 = newTheme
				break
			case 'panel38':
				theme38 = newTheme
				break
			case 'panel39':
				theme39 = newTheme
				break
			case 'panel40':
				theme40 = newTheme
				break
			case 'panel41':
				theme41 = newTheme
				break
			case 'panel42':
				theme42 = newTheme
				break
			case 'panel43':
				theme43 = newTheme
				break
			case 'panel44':
				theme44 = newTheme
				break
			case 'panel45':
				theme45 = newTheme
				break
			case 'panel46':
				theme46 = newTheme
				break
			case 'panel47':
				theme47 = newTheme
				break
			case 'panel48':
				theme48 = newTheme
				break
			case 'panel49':
				theme49 = newTheme
				break
			case 'panel50':
				theme50 = newTheme
				break
			case 'panel51':
				theme51 = newTheme
				break
			case 'panel53':
				theme53 = newTheme
				break
			case 'panel54':
				theme54 = newTheme
				break
			case 'panel55':
				theme55 = newTheme
				break
			case 'panel57':
				theme57 = newTheme
				break
			case 'panel58':
				theme58 = newTheme
				break
			default:
				theme = newTheme
				break
		}
	}

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<Header active="reference_information" dark />
			<div className={`${styles.page} container`}>
				<Title level={1}>{t('reference_information')}</Title>
				<Tabs defaultActiveKey={key} className="mt-64">
					<TabPane className={styles.tabPane} tab={t('helpTab')} key="1" activeKey={key}>
						<ThemeProvider theme={theme}>
							<Accordion expanded={'panel1' === expanded} onChange={handleChange('panel1')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('rules_submitting')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('description_help')}
									</Title>
									<p>{t('order_minister')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_to_rules')}
									</Title>
									<a
										href="https://adilet.zan.kz/rus/docs/V2000021173"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://adilet.zan.kz/rus/docs/V2000021173
									</a>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/rule-rus.pdf' : '../../../files/rule-kz.pdf'} download>
										{t('download_rules')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme55}>
							<Accordion expanded={'panel55' === expanded} onChange={handleChange('panel55')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('rules_harmful')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('description_help')}
									</Title>
									<p>{t('order_minister_2')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_to_rules')}
									</Title>
									<a
										href="https://adilet.zan.kz/rus/docs/V2300033811"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://adilet.zan.kz/rus/docs/V2300033811
									</a>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/rulesHarmful-kz.pdf' : '../../../files/rulesHarmful-kz.pdf'}
										download
									>
										{t('download_rules_harmful')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme57}>
							<Accordion expanded={'panel57' === expanded} onChange={handleChange('panel57')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('methodology')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={'../../../files/methodology-rus.docx'} download>
										{t('download_methodology')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
					</TabPane>
					<TabPane className={styles.tabPane} tab={t('instructions')} key="4" activeKey={key}>
						<ThemeProvider theme={theme53}>
							<Accordion expanded={'panel53' === expanded} onChange={handleChange('panel53')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_15')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr15-rus.pdf' : '../../../files/instr15-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme54}>
							<Accordion expanded={'panel54' === expanded} onChange={handleChange('panel54')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_16')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr16-rus.pdf' : '../../../files/instr16-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme51}>
							<Accordion expanded={'panel51' === expanded} onChange={handleChange('panel51')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_13')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr13-rus.pdf' : '../../../files/instr13-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_13}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme52}>
							<Accordion expanded={'theme52' === expanded} onChange={handleChange('theme52')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_14')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr14.pdf' : '../../../files/instr14-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_16}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme56}>
							<Accordion expanded={'theme56' === expanded} onChange={handleChange('theme56')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_17')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr17.pdf' : '../../../files/instr17-kz.pdf'} download>
										{t('download_instruct')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme1}>
							<Accordion expanded={'panel' === expanded} onChange={handleChange('panel')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_1')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr1-rus.pdf' : '../../../files/instr1-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_1}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme36}>
							<Accordion expanded={'panel36' === expanded} onChange={handleChange('panel36')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_2')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr2-rus.pdf' : '../../../files/instr2-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_2}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme37}>
							<Accordion expanded={'panel37' === expanded} onChange={handleChange('panel37')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_3')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr3-rus.pdf' : '../../../files/instr3-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_3}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme38}>
							<Accordion expanded={'panel38' === expanded} onChange={handleChange('panel38')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_4')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr4-rus.pdf' : '../../../files/instr4-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_4}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme39}>
							<Accordion expanded={'panel39' === expanded} onChange={handleChange('panel39')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_5')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr5-rus.pdf' : '../../../files/instr5-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_5}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme40}>
							<Accordion expanded={'panel40' === expanded} onChange={handleChange('panel40')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_6')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr6-rus.pdf' : '../../../files/instr6-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_6}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme41}>
							<Accordion expanded={'panel41' === expanded} onChange={handleChange('panel41')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_7')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr7-rus.pdf' : '../../../files/instr7-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_7}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme42}>
							<Accordion expanded={'panel42' === expanded} onChange={handleChange('panel42')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_8')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr8-rus.pdf' : '../../../files/instr8-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_8}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme43}>
							<Accordion expanded={'panel43' === expanded} onChange={handleChange('panel43')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_9')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a href={lang === 'rus' ? '../../../files/instr9-rus.pdf' : '../../../files/instr9-kz.pdf'} download>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_9}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme44}>
							<Accordion expanded={'panel44' === expanded} onChange={handleChange('panel44')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_10')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr10-rus.pdf' : '../../../files/instr10-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_10}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme45}>
							<Accordion expanded={'panel45' === expanded} onChange={handleChange('panel45')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_11')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr11-rus.pdf' : '../../../files/instr11-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_11}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme47}>
							<Accordion expanded={'panel47' === expanded} onChange={handleChange('panel47')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_12')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr12-rus.pdf' : '../../../files/instr12-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
									<Title className={styles.pTop} level={3}>
										{t('video_instruction')}
									</Title>
									<iframe
										src={process.env.REACT_APP_SUPPORT_REF_12}
										frameBorder={0}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>{' '}
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme58}>
							<Accordion expanded={'panel58' === expanded} onChange={handleChange('panel58')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1-header">
									<Typography>{t('instr.ref_18')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('file')}
									</Title>
									<a
										href={lang === 'rus' ? '../../../files/instr18-rus.pdf' : '../../../files/instr18-kz.pdf'}
										download
									>
										{t('download_instruct')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
					</TabPane>
					<TabPane className={styles.tabPane} tab={t('servicesTabs')} key="2" activeKey={key}>
						<ThemeProvider theme={theme2}>
							<Accordion expanded={'panel2' === expanded} onChange={handleChange('panel2')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2-header">
									<Typography>{t('integration_unified_system')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('service_assignment')}
									</Title>
									<p>{t('the_service_is_intended_registration')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_service_smartbridge')}
									</Title>
									<a
										href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-0356"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-0356
									</a>
									<Title className={styles.pTop} level={3}>
										{t('service_description')}
									</Title>
									<a href="../../../files/info-service.rar" download>
										{t('download_archive_service')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme3}>
							<Accordion expanded={'panel3' === expanded} onChange={handleChange('panel3')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3-header">
									<Typography>{t('integration_unified_system_recording')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('service_assignment')}
									</Title>
									<p>{t('the_service_is_designed_batch_loading')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_service_smartbridge')}
									</Title>
									<a
										href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3301"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3301
									</a>
									<Title className={styles.pTop} level={3}>
										{t('service_description')}
									</Title>
									<a href="../../../files/info-service-package.rar" download>
										{t('download_archive_service')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme4}>
							<Accordion expanded={'panel4' === expanded} onChange={handleChange('panel4')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4-header">
									<Typography>{t('integration_unified_system_recording_fastening')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('service_assignment')}
									</Title>
									<p>{t('the_service_is_designed_to_synchronize')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_service_smartbridge')}
									</Title>
									<a
										href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3023"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3023
									</a>
									<Title className={styles.pTop} level={3}>
										{t('service_description')}
									</Title>
									<a href="../../../files/info-service-synch.rar" download>
										{t('download_archive_service')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme49}>
							<Accordion expanded={'panel49' === expanded} onChange={handleChange('panel49')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel49bh-content" id="panel49-header">
									<Typography>{t('worker_profile_details')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('service_assignment')}
									</Title>
									<p>{t('the_service_is_designed_to_send_employees_contracts')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_service_smartbridge')}
									</Title>
									<a
										href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3263"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3263
									</a>
									<Title className={styles.pTop} level={3}>
										{t('service_description')}
									</Title>
									<a href="../../../files/info-service-employee.rar" download>
										{t('download_archive_service')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme50}>
							<Accordion expanded={'panel50' === expanded} onChange={handleChange('panel50')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel50bh-content" id="panel50-header">
									<Typography>{t('employee_personal_affairs')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Title className={styles.pTop} level={3}>
										{t('service_assignment')}
									</Title>
									<p>{t('service_employer_package_employee')}</p>
									<Title className={styles.pTop} level={3}>
										{t('link_service_smartbridge')}
									</Title>
									<a
										href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-A-3318"
										target="_blank"
										rel="noopener noreferrer"
										style={{ wordWrap: 'break-word' }}
									>
										https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-A-3318
									</a>
									<Title className={styles.pTop} level={3}>
										{t('service_description')}
									</Title>
									<a href="../../../files/info-service-affairs.rar" download>
										{t('download_archive_service')}
									</a>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
					</TabPane>
					<TabPane className={styles.tabPane} tab={t('asked_questions')} key="3" activeKey={key}>
						<ThemeProvider theme={theme46}>
							<Accordion expanded={'panel46' === expanded} onChange={handleChange('panel46')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5-header">
									<Typography>{t('question46')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer46')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme48}>
							<Accordion expanded={'panel48' === expanded} onChange={handleChange('panel48')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5-header">
									<Typography>{t('question48')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer48')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme5}>
							<Accordion expanded={'panel5' === expanded} onChange={handleChange('panel5')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5-header">
									<Typography>{t('question1')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer1')}</p>
									<p>{t('answer1_1')}</p>
									<p>{t('answer1_2')}</p>
									<p>{t('answer1_3')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme6}>
							<Accordion expanded={'panel6' === expanded} onChange={handleChange('panel6')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6-header">
									<Typography>{t('question2')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer2')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme7}>
							<Accordion expanded={'panel7' === expanded} onChange={handleChange('panel7')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7-header">
									<Typography>{t('question3')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer3')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme8}>
							<Accordion expanded={'panel8' === expanded} onChange={handleChange('panel8')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8-header">
									<Typography>{t('question8')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer8')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme9}>
							<Accordion expanded={'panel9' === expanded} onChange={handleChange('panel9')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9-header">
									<Typography>{t('question4')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer4')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme10}>
							<Accordion expanded={'panel10' === expanded} onChange={handleChange('panel10')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10bh-content" id="panel10-header">
									<Typography>{t('question5')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer5')}</p>
									<p>{t('answer5_1')}</p>
									<p>{t('answer5_2')}</p>
									<p>{t('answer5_3')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme11}>
							<Accordion expanded={'panel11' === expanded} onChange={handleChange('panel11')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11bh-content" id="panel11-header">
									<Typography>{t('question6')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer6')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme12}>
							<Accordion expanded={'panel12' === expanded} onChange={handleChange('panel12')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel12bh-content" id="panel12-header">
									<Typography>{t('question7')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer7')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme13}>
							<Accordion expanded={'panel13' === expanded} onChange={handleChange('panel13')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel13bh-content" id="panel13-header">
									<Typography>{t('question8')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer8')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme14}>
							<Accordion expanded={'panel14' === expanded} onChange={handleChange('panel14')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel14bh-content" id="panel14-header">
									<Typography>{t('question9')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer9')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme15}>
							<Accordion expanded={'panel15' === expanded} onChange={handleChange('panel15')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel15bh-content" id="panel15-header">
									<Typography>{t('question10')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer10')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme16}>
							<Accordion expanded={'panel16' === expanded} onChange={handleChange('panel16')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel16bh-content" id="panel16-header">
									<Typography>{t('question11')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer11')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme17}>
							<Accordion expanded={'panel17' === expanded} onChange={handleChange('panel17')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel17bh-content" id="panel17-header">
									<Typography>{t('question12')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer12')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme18}>
							<Accordion expanded={'panel18' === expanded} onChange={handleChange('panel18')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel18bh-content" id="panel18-header">
									<Typography>{t('question13')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer13')}</p>
									<p>{t('answer13_1')}</p>
									<p>{t('answer13_2')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme19}>
							<Accordion expanded={'panel19' === expanded} onChange={handleChange('panel19')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel19bh-content" id="panel19-header">
									<Typography>{t('question14')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer14')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme20}>
							<Accordion expanded={'panel20' === expanded} onChange={handleChange('panel20')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel20bh-content" id="panel20-header">
									<Typography>{t('question15')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer15')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme21}>
							<Accordion expanded={'panel21' === expanded} onChange={handleChange('panel21')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel21bh-content" id="panel21-header">
									<Typography>{t('question16')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer16')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme22}>
							<Accordion expanded={'panel22' === expanded} onChange={handleChange('panel22')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel22bh-content" id="panel22-header">
									<Typography>{t('question17')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer17')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme23}>
							<Accordion expanded={'panel23' === expanded} onChange={handleChange('panel23')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel23bh-content" id="panel23-header">
									<Typography>{t('question18')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer18')}</p>
									<p>{t('answer18_1')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme24}>
							<Accordion expanded={'panel24' === expanded} onChange={handleChange('panel24')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel24bh-content" id="panel24-header">
									<Typography>{t('question19')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer19')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme25}>
							<Accordion expanded={'panel25' === expanded} onChange={handleChange('panel25')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel25bh-content" id="panel25-header">
									<Typography>{t('question20')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer20')}</p>
									<p>{t('answer20_1')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme26}>
							<Accordion expanded={'panel26' === expanded} onChange={handleChange('panel26')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel26bh-content" id="panel26-header">
									<Typography>{t('question21')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer21')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme27}>
							<Accordion expanded={'panel27' === expanded} onChange={handleChange('panel27')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel27bh-content" id="panel27-header">
									<Typography>{t('question22')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer22')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme28}>
							<Accordion expanded={'panel28' === expanded} onChange={handleChange('panel28')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel28bh-content" id="panel28-header">
									<Typography>{t('question23')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer23')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme29}>
							<Accordion expanded={'panel29' === expanded} onChange={handleChange('panel29')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel29bh-content" id="panel29-header">
									<Typography>{t('question24')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer24')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme30}>
							<Accordion expanded={'panel30' === expanded} onChange={handleChange('panel30')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel30bh-content" id="panel30-header">
									<Typography>{t('question25')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer25')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme31}>
							<Accordion expanded={'panel31' === expanded} onChange={handleChange('panel31')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel31bh-content" id="panel31-header">
									<Typography>{t('question26')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer26')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme32}>
							<Accordion expanded={'panel32' === expanded} onChange={handleChange('panel32')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel32bh-content" id="panel32-header">
									<Typography>{t('question27')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer27')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme33}>
							<Accordion expanded={'panel33' === expanded} onChange={handleChange('panel33')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel33bh-content" id="panel33-header">
									<Typography>{t('question28')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer28')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme34}>
							<Accordion expanded={'panel34' === expanded} onChange={handleChange('panel34')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel34bh-content" id="panel34-header">
									<Typography>{t('question29')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer29')}</p>
									<p>{t('answer29_1')}</p>
									<p>{t('answer29_2')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
						<ThemeProvider theme={theme35}>
							<Accordion expanded={'panel35' === expanded} onChange={handleChange('panel35')}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel35bh-content" id="panel35-header">
									<Typography>{t('question30')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<p>{t('answer30')}</p>
								</AccordionDetails>
							</Accordion>
						</ThemeProvider>
					</TabPane>
				</Tabs>
			</div>
			<Footer />
		</>
	)
}
