import React, { useCallback, useEffect, useState } from 'react'
import { Table, TableColumn } from '@/components/Table'
import { HrPagination } from '@/components/Pagination'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import styles from './CompanyInsurance.module.scss'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { defaultInsurance } from '@pages/CompanyPassport/dfault'
import { LoadingBlocker } from '@/components/Loader'
import { Modal, ModalBackButton } from '@/components/Modal'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const CalendarSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
			<rect width="40" height="40" fill="#F0F9F4" rx="20" />
			<g clipPath="url(#a)">
				<g stroke="#17B67C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#b)">
					<path d="M27 15.3v-3.5c0-1.3-1-2.3-2.3-2.3H11.8c-1.3 0-2.3 1-2.3 2.3v16.4c0 1.3 1 2.3 2.3 2.3h3.5" />
					<path d="M14.8 25.8a1.8 1.8 0 1 1 0-3.5 1.8 1.8 0 0 1 0 3.5Zm15.7 3.1v-1c0-.6-.3-1-.8-1.2l-2.7-.9v-2.3l.8-.5a1.6 1.6 0 0 0-.9-3h-3.3a1.6 1.6 0 0 0-1 3l.9.5v2.3l-2.7 1c-.5 0-.8.5-.8 1v1c0 .4.1.7.3.9l.5.5c.2.2.5.3.8.3H29c.3 0 .6-.1.8-.3l.5-.5c.2-.2.3-.5.3-.8ZM13 14.2h9.3M13 18.3h4.7" />
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
				<clipPath id="b">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const UserSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none">
			<rect width="40" height="40" x=".3" fill="#F0F9F4" rx="20" />
			<g stroke="#17B67C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)">
				<path d="M11 29.3c0-2.9 2.4-5.2 5.3-5.2h3m7.4 6.4s3-1.4 3-3.5v-2.4l-2.2-.8a2 2 0 0 0-1.5 0l-2.2.8V27c0 2.1 3 3.5 3 3.5Zm-2.9-18.4a5 5 0 1 1-7 7 5 5 0 0 1 7-7m4.7 12.6L25 28.2" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6.3 6h28v28h-28z" />
				</clipPath>
			</defs>
		</svg>
	)
}

export const Indicator = ({ icon, value, subValue, title, subTitle }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.indicatorContainer}>
			<div className={styles.indicatorHeader}>
				{icon}
				<div>
					<Typography variant={'subtitle_xxl'}>{value}</Typography>
					&nbsp;
					<Typography variant={'subtitle_xl'}>{subValue}</Typography>
				</div>
			</div>
			<div className={styles.indicatorBody}>
				<Typography variant={'text'}>{t(title)}</Typography>
				<Typography variant={'text'}>{t(subTitle)}</Typography>
			</div>
		</div>
	)
}

const Row = ({ title, value }) => {
	return (
		<>
			<div>
				<Typography variant={'subtitle'}>{title}</Typography>
			</div>
			<div>
				<Typography variant={'text'}>{value}</Typography>
			</div>
		</>
	)
}

const MoadlContent = ({ onClose, tableData, commonInfo }) => {
	const { t } = useTranslation()

	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	return (
		<Modal windowClassName={styles.modalContainer}>
			<ModalBackButton noMargin onClick={onClose} />

			<div className={styles.modalTitleContainer}>
				<Typography variant={'title_24_700'}>
					{lang === 'rus'
						? `${t('ckp_OSRNS')} № ${tableData.contractNumber} ${t('ckp_from')} ${tableData.contractDate}`
						: `${tableData.contractDate} № ${tableData.contractNumber} ЖОҚМС шарты`}
				</Typography>
				<Typography
					variant={
						tableData[`status${langPrefix}`]?.toLowerCase().trim() === t('ckp_active').toLowerCase()
							? 'status_done_400_18_white_green'
							: 'status_done_400_18_white_red'
					}
				>
					{t(tableData[`status${langPrefix}`])}
				</Typography>
			</div>

			<div className={styles.tableInfo}>
				<Row
					title={t('ckp_validity_period')}
					value={
						langPrefix === 'Ru'
							? `c ${tableData.contractBeginDate} по ${tableData.contractEndDate}`
							: `${tableData.contractBeginDate} бастап ${tableData.contractEndDate} дейін`
					}
				></Row>
				<Row title={t('ckp_insurer_BIN_IIN')} value={tableData.systemDelimiterBin}></Row>
				<Row title={t('ckp_insurance_company_name')} value={tableData.systemDelimiterRu}></Row>
				<Row title={t('ckp_branch_BIN')} value={tableData.branchBinList.join(' ')}></Row>
			</div>

			<Table tableName="social-leaves" data={tableData.contractVersionDTOList} emptyPlaceholder="-">
				<TableColumn dataKey="versionNumber" heading={t('ckp_version_number')} />
				<TableColumn dataKey="insuranceVersionBeginDate" heading={t('ckp_version_date')} />
				<TableColumn dataKey="calculatedAmount" heading={t('ckp_total_annual_payroll')} />
				<TableColumn dataKey="premium" heading={t('ckp_insurance_premium')} />
				<TableColumn dataKey="insuredEmployers" heading={t('ckp_number_of_workers')} />
				<TableColumn dataKey="countEmployees" heading={t('ckp_total_employees')} />
			</Table>
		</Modal>
	)
}

export const CompanyInsurance = ({ bin }) => {
	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	const [totalPages, setTotalPages] = useState(0)

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [modalData, setModalData] = useState(null)

	const fetchFunction = useCallback(
		async (reqestedBin) => {
			return safetyService.getAccidentInsurance(currentPage, rowsPerPage, reqestedBin)
		},
		[currentPage, rowsPerPage]
	)

	const { data, isEmpty, loading } = useFetchData({
		bin: bin,
		fetchFunction,
		defaultValue: defaultInsurance,
		defaultChecks: true,
	})

	useEffect(() => {
		if (data?.totalInsuranceContractCount) {
			setTotalPages(Math.ceil(data.totalInsuranceContractCount / rowsPerPage))
		}
	}, [data])

	if (loading) {
		return <LoadingBlocker />
	}
	return (
		<div className={styles.insuranceContainer}>
			<div className={styles.insuranceHeader}>
				{isOpenModal && <MoadlContent tableData={modalData} onClose={() => setIsOpenModal(false)}></MoadlContent>}
				<Indicator
					value={data?.totalInsuranceContractCount || 0}
					subValue={`(${data?.activeContractCount || 0}/${data?.quitedContractCount || 0})`}
					title={t('ckp_total_OSRNS_contracts')}
					subTitle={t('ckp_active_completed_osrns')}
					icon={<CalendarSvg />}
				></Indicator>
				<Indicator
					title={t('ckp_total_employees')}
					subTitle={t('ckp_total_employees_insured_uninsured')}
					value={data?.totalEmployeesCount || 0}
					subValue={`(${data?.insuredEmployeeCount || 0}/${data?.notInsuredEmployeeCount || 0})`}
					icon={<UserSvg />}
				></Indicator>
			</div>
			{data?.osrnsContracts && (
				<Table
					tableName="social-leaves"
					data={data.osrnsContracts}
					emptyPlaceholder="-"
					onRowClick={(_, data) => {
						setModalData(data)
						setIsOpenModal(true)
					}}
				>
					<TableColumn dataKey="contractNumber" heading={t('ckp_contract_number_series')} />
					<TableColumn dataKey="contractDate" heading={t('ckp_conclusion_date')} />
					<TableColumn dataKey="contractBeginDate" heading={t('ckp_start_date')} />
					<TableColumn dataKey="contractEndDate" heading={t('ckp_end_date')} />
					<TableColumn dataKey={`systemDelimiter${langPrefix}`} heading={t('ckp_insurance_company_name')} />
					<TableColumn dataKey="insuredEmployees" heading={t('ckp_insured_employees_count')} />
					<TableColumn
						dataKey={`status${langPrefix}`}
						renderCell={(item) => {
							return (
								<Typography
									variant={
										item?.toLowerCase() === t('ckp_active').toLowerCase() ? 'text_button_green_s' : 'text_button_red_s'
									}
								>
									{t(item)}
								</Typography>
							)
						}}
						heading={t('ckp_status')}
					/>
				</Table>
			)}
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
				]}
				rowsPerPage={rowsPerPage}
				numberOfElements={data?.osrnsContracts?.length || 0}
				totalElements={data?.totalInsuranceContractCount || 0}
				totalPages={totalPages}
				handleChangePage={(_, page) => {
					setCurrentPage(page - 1)
				}}
				handleChangeRowsPerPage={(rows) => {
					setRowsPerPage(rows)
					setCurrentPage(0)
				}}
				page={currentPage}
			/>
		</div>
	)
}
