import React from 'react'
import { Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Field as FormikField } from 'formik'

export const CustomField = ({ name, label, placeholder, fieldType, maxDate, isFormDirty }) => {
	const { t } = useTranslation()
	const isRequired =
		label.includes('additional_contract_number') ||
		label.includes('reg_date_additional_contract') ||
		label.includes('start_date_additional_contract')

	return (
		<FormikField name={name}>
			{({ field, meta: { touched, error } }) => (
				<Field
					fieldType={fieldType}
					isRequired={isRequired}
					label={t(label)}
					placeholder={t(placeholder)}
					maxDate={maxDate ? new Date() : undefined}
					error={(touched || isFormDirty) && error}
					{...field}
				/>
			)}
		</FormikField>
	)
}
