import React, { useCallback, useMemo } from 'react'
import styles from './Table.module.scss'
import { headCells } from './table.headings'
import { useTranslation } from 'react-i18next'

const Table = ({ data, setContract, close }) => {
	const { t } = useTranslation()
	const selectRow = (row) => {
		setContract(row)
		close()
	}
	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => selectRow(row)}>
				<td>{row.iin}</td>
				<td>{row.fullName}</td>
				<td>{row.contractNumber}</td>
				<td>{row.destablishedPost}</td>
			</tr>
		),
		[selectRow]
	)
	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : null}</tbody>
			</table>
		</>
	)
}

export default Table
