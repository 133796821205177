import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contractsActions, contractsSelectors, contractsThunks, userSelectors } from '@/entity'
import { request } from '@/shared/api/request'
import { useDigitalSign } from '@/shared'
import { message } from 'antd'
import { Button as DialogButton } from '@/ui'
import styles from './AdditionalContractStyles.module.scss'
import { Dialog, Box, DialogTitle, DialogContent, Button } from '@mui/material'
import { LoadingBlocker } from '@/components/Loader'
import CloseButton from '@/components/CloseButton/CloseButton'
import Timer from '@features/contracts/actions/view/components/Timer/Timer'
import cn from 'classnames'

export const SignAdditionalAgreementModal = ({
	onCancel,
	additionalContract,
	contract,
	paramMap,
	setParamMap,
	filters,
	page,
	pageSize,
}) => {
	const dispatch = useDispatch()
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const xml = useSelector(contractsSelectors.additionalContractXml)
	const isThunkLoading = useSelector(contractsSelectors.additionalContractIsLoading)
	const isSuccess = useSelector(contractsSelectors.additionalContractSubmitted)
	const isSuccessEtd = useSelector(contractsSelectors.isSuccessEtd)
	const errorEtd = useSelector(contractsSelectors.errorEtd)
	const image = useSelector(contractsSelectors.imageEtd)
	const imageKey = useSelector(contractsSelectors.imageKey)
	const isLoading = useSelector(contractsSelectors.isLoadingEtd)
	const { successPayload, onSign, onClearPayload, failedPayload, onSignMultiple } = useDigitalSign()
	const [statusSign, setStatusSign] = useState(null)
	const activeRole = useSelector(userSelectors.activeRole)
	const [templatesLoading, setTemplatesLoading] = useState(false)
	const [qr, setQr] = useState(false)
	const [qrTimer, setQrTimer] = useState(null)
	const [qrStatusInterval, setQrStatusInterval] = useState(null)

	const onSignLocal = async () => {
		try {
			setTemplatesLoading(true)
			if (activeRole === 'ROLE_EMPLOYEE' && contract.econtractFound) {
				const res = await request({
					url: '/contract/eAdContract/employee/xml',
					method: 'GET',
					params: { externalId: additionalContract.externalId },
				})
				onSignMultiple(res)
				return
			}
			if (activeRole === 'ROLE_EMPLOYER' && contract.econtractFound) {
				const res = await request({
					url: '/contract/eAdContract/employer/xml',
					method: 'GET',
					params: { externalId: additionalContract.externalId },
				})
				onSignMultiple(res)
				return
			}
			dispatch(contractsThunks.saveAdditionalContract(additionalContract))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setTemplatesLoading(false)
		}
	}
	useEffect(() => xml && onSign(xml), [xml]) /** подписание xml */
	useEffect(() => {
		return () => {
			//удаляется, прерываем интервала
			if (!qr) clearInterval(qrStatusInterval)
		}
	}, [qrStatusInterval, qr])
	const resetDataQr = () => {
		setQr(false)
		dispatch(contractsActions.resetEtdQR())
	}
	useEffect(() => {
		let intervalId
		if (qr && imageKey) {
			intervalId = setInterval(() => {
				dispatch(contractsThunks.getStatusQrSign(imageKey))
			}, 10000)
			setQrStatusInterval(intervalId)
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}
	}, [qr, imageKey])
	/** очистка и выход при удачной операции */
	useEffect(() => {
		if (isSuccess) {
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()
			onCancel()
		}
	}, [isSuccess])

	/** очистка и выход при удачной операции */
	useEffect(() => {
		if (isSuccessEtd) {
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()
			resetDataQr()
			dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
			onCancel()
		}
	}, [isSuccessEtd])
	const updateData = () => {
		dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
		dispatch(contractsActions.setAdditionalContractSubmitted())
		dispatch(contractsThunks.getContracts(filters, page, pageSize))
	}
	const sendSignedEDSEmployee = async (signXmlList) => {
		try {
			const response = await request({
				url: '/contract/eAdContract/employee/saveSign',
				method: 'POST',
				data: signXmlList,
			})
			if (response.success) {
				updateData()
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}
	const sendSignedEDSEmployer = async (signXmlList) => {
		try {
			const response = await request({
				url: '/contract/eAdContract/employer/saveSign',
				method: 'POST',
				data: signXmlList,
			})
			if (response.success) {
				updateData()
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			alert(err ? err : 'Неизвестная ошибка')
		}
	}
	const success = () => {
		message.success(t('success_alert'), 5)
	}
	/** отправка подписанного XML файла */
	useEffect(() => {
		if (successPayload && !isThunkLoading) {
			if (activeRole === 'ROLE_EMPLOYEE' && contract?.econtractFound) {
				sendSignedEDSEmployee(successPayload).then(success)
				return
			}
			if (activeRole === 'ROLE_EMPLOYER' && contract?.econtractFound) {
				sendSignedEDSEmployer(successPayload).then(success)
				return
			}
			dispatch(contractsThunks.sendAdditionalContract(successPayload, paramMap, page, pageSize, filters))
			setParamMap({})
		}
	}, [successPayload])
	/** сброс redux параметров при ошибке NCALayer */
	useEffect(() => {
		if (failedPayload) {
			dispatch(contractsActions.setAdditionalContractXml(null))
			onCancel()
		}
	}, [failedPayload])
	const handleSignQr = async (qrTimer) => {
		try {
			setTemplatesLoading(true)
			setStatusSign(null)
			dispatch(
				contractsThunks.getImageQr(
					additionalContract.externalId,
					activeRole === 'ROLE_EMPLOYER' ? 'eadc_employer' : 'eadc_employee'
				)
			)
			setQr(true)
			// Если таймер уже существует, отменяем его
			if (qrTimer) {
				clearTimeout(qrTimer)
			}
			// Устанавливаем новый таймер для сброса состояния qr через 1 минуту (60000 миллисекунд)
			const newQrTimer = setTimeout(() => {
				setQr(false)
				setStatusSign(t('time_out'))
			}, 60000) // 60000 миллисекунд = 1 минута
			setQrTimer(newQrTimer) // Сохраняем идентификатор нового таймера в состоянии
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setTemplatesLoading(false)
		}
	}
	return (
		<Dialog open={true} classes={{ paper: styles.sign }} onClose={onCancel}>
			{templatesLoading || isThunkLoading ? <LoadingBlocker /> : null}
			<Box>{onCancel ? <CloseButton onClose={onCancel}></CloseButton> : null} </Box>
			<DialogTitle classes={{ root: styles.sign__title }}>
				{qr ? (
					<>
						{t('text_qr_sign_employee1')} <br />
						{t('text_qr_sign_employee2')} <br />
						{t('text_qr_sign_employee3')}
					</>
				) : (
					<>{activeRole === 'ROLE_EMPLOYER' ? t('sure_sign') : t('sure_sign_employee')}</>
				)}
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					{!qr ? (
						<div className={styles.flex_button}>
							<div className={styles.margin_button}>
								<DialogButton type={'button'} onClick={onSignLocal}>
									{t('sign_button_ecp')}
								</DialogButton>
							</div>
							<div className={styles.margin_button}>
								<DialogButton type={'button'} onClick={handleSignQr.bind(null, qrTimer)}>
									{t('sign_button_qr')}
								</DialogButton>
							</div>
						</div>
					) : null}
					{qr && additionalContract?.externalId && !isLoading ? (
						<>
							{errorEtd ? (
								<div className={styles.error__qr}>
									{errorEtd === 'was_signed' ? t('error_was_signed') : t('error_qr')}
								</div>
							) : (
								<>
									<Timer qr={qr}></Timer>
									<img src={image} alt={'QR Code'} />
								</>
							)}
						</>
					) : (
						<div className={styles.error__qr}>{statusSign}</div>
					)}
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button) }}
						onClick={onCancel}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
