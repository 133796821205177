import React from 'react'

export const Delete = ({ fill="#EB5757" }) => (
	<svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.1709 1.75586H7.1084V1.38086C7.1084 0.760539 6.60372 0.255859 5.9834 0.255859H4.4834C3.86308 0.255859 3.3584 0.760539 3.3584 1.38086V1.75586H1.2959C0.778961 1.75586 0.358398 2.17642 0.358398 2.69336V4.00586C0.358398 4.21295 0.526305 4.38086 0.733398 4.38086H0.938336L1.26231 11.1844C1.29093 11.7852 1.78452 12.2559 2.38602 12.2559H8.08077C8.6823 12.2559 9.17589 11.7852 9.20448 11.1844L9.52846 4.38086H9.7334C9.94049 4.38086 10.1084 4.21295 10.1084 4.00586V2.69336C10.1084 2.17642 9.68784 1.75586 9.1709 1.75586ZM4.1084 1.38086C4.1084 1.17409 4.27663 1.00586 4.4834 1.00586H5.9834C6.19016 1.00586 6.3584 1.17409 6.3584 1.38086V1.75586H4.1084V1.38086ZM1.1084 2.69336C1.1084 2.58998 1.19252 2.50586 1.2959 2.50586H9.1709C9.27428 2.50586 9.3584 2.58998 9.3584 2.69336V3.63086C9.24283 3.63086 1.5873 3.63086 1.1084 3.63086V2.69336ZM8.45533 11.1487C8.44579 11.349 8.28126 11.5059 8.08077 11.5059H2.38602C2.18552 11.5059 2.02098 11.349 2.01147 11.1487L1.68918 4.38086H8.77762L8.45533 11.1487Z"
			fill={fill}
		/>
		<path
			d="M5.2334 10.7559C5.44049 10.7559 5.6084 10.588 5.6084 10.3809V5.50586C5.6084 5.29877 5.44049 5.13086 5.2334 5.13086C5.0263 5.13086 4.8584 5.29877 4.8584 5.50586V10.3809C4.8584 10.588 5.02628 10.7559 5.2334 10.7559Z"
			fill={fill}
		/>
		<path
			d="M7.1084 10.7559C7.31549 10.7559 7.4834 10.588 7.4834 10.3809V5.50586C7.4834 5.29877 7.31549 5.13086 7.1084 5.13086C6.9013 5.13086 6.7334 5.29877 6.7334 5.50586V10.3809C6.7334 10.588 6.90128 10.7559 7.1084 10.7559Z"
			fill={fill}
		/>
		<path
			d="M3.3584 10.7559C3.56549 10.7559 3.7334 10.588 3.7334 10.3809V5.50586C3.7334 5.29877 3.56549 5.13086 3.3584 5.13086C3.1513 5.13086 2.9834 5.29877 2.9834 5.50586V10.3809C2.9834 10.588 3.15128 10.7559 3.3584 10.7559Z"
			fill={fill}
		/>
	</svg>
)
