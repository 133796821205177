import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsThunks } from '@/entity/notifications/index'
import { cookies } from '@/shared'
import { userSelectors } from '@/entity'
import { companySelectors } from '@/entity/company'

export const NotificationWrapper = ({ children }) => {
	const dispatch = useDispatch()
	const companies = useSelector(companySelectors.companies)
	const user = useSelector(userSelectors.user)
	const activeRole = useSelector(userSelectors.activeRole)

	useEffect(() => {
		const intervalId = setInterval(() => {
			const SSO_PASSPORT = process.env.REACT_APP_PASSPORT
			const cookie = cookies.get(SSO_PASSPORT, true)
			if (cookie.key)
				if (
					(companies.length && activeRole === 'ROLE_EMPLOYER') ||
					(user?.iin?.length && activeRole === 'ROLE_EMPLOYEE')
				) {
					dispatch(notificationsThunks.getNotificationsBadge(activeRole === 'ROLE_EMPLOYER'))
				}
		}, 60000)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	return children
}
