import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field, connect } from 'formik'
import { Title, Button, Checkbox } from '@/ui'
import { Add } from '@/app/icons'
import styles from './ConditionsPicker.module.scss'
import { useSelector } from 'react-redux'
import { contractsSelectors } from '@/entity'

const WrappedCheckbox = ({ onChange, value, ...props }) => {
	const handleCheckboxChange = useCallback(() => {
		onChange(!value)
	}, [value, onChange])

	return <Checkbox onChange={handleCheckboxChange} value={value} {...props} />
}

const SelectAll = connect(({ formik: { setValues, values }, label }) => {
	const checkboxValue = useMemo(() => {
		return !Object.values(values).includes(false)
	}, [values])

	const handleSelectAll = useCallback(() => {
		let toSet = false
		if (!checkboxValue) {
			toSet = true
		}
		setValues({
			contractTerm: toSet,
			contractPosition: toSet,
			contractWorkingHours: toSet,
			contractJobLocation: toSet,
			contractBin: toSet,
			contractJobType: toSet,
			contractOther: toSet,
		})
	}, [checkboxValue, setValues])

	return <Checkbox onChange={handleSelectAll} value={checkboxValue} label={label} />
})

const SubmitButton = connect(({ label, formik: { values = {} }, isTransfer }) => {
	const isSelected = useMemo(() => {
		if (isTransfer) {
			return Object.values(values).includes(true) && values.contractBin
		} else {
			return Object.values(values).includes(true)
		}
	}, [values, isTransfer])

	return (
		<Button buttonClassName={styles.button} type="submit" disabled={!isSelected}>
			{label}
		</Button>
	)
})

export const ConditionsPicker = ({ onSaveChoice, onCancelEditing, isEdit = false, isTransfer, setShowCheckbox }) => {
	const { t } = useTranslation()
	const [show, setShow] = useState(isEdit)
	const {
		dcontractDateCode,
		contractEndDate,
		dworkingHoursCode,
		tariffRate,
		establishedPost,
		destablishedPostCode,
		dpartTimeCode,
		dremoteWorkCode,
		dcountryCode,
		ddistrictCode,
		dregionCode,
		dlocalityCode,
		workingPlace,
		otherConditions,
	} = useSelector(contractsSelectors.additionalContract) || {}

	const toggleShow = useCallback(() => {
		setShow((value) => !value)
		setShowCheckbox((value) => !value)
	}, [])

	return (
		<Formik
			initialValues={{
				selectAllConditions: !!dcontractDateCode || !!contractEndDate,
				contractTerm: !!dcontractDateCode || !!contractEndDate,
				contractPosition: !!establishedPost || !!destablishedPostCode,
				contractJobType: !!dpartTimeCode,
				contractWorkingHours: !!dworkingHoursCode || !!tariffRate,
				contractJobLocation:
					!!dremoteWorkCode || !!dcountryCode || !!ddistrictCode || !!dregionCode || !!dlocalityCode || !!workingPlace,
				contractBin: true,
				contractOther: !!otherConditions,
			}}
			onSubmit={onSaveChoice}
		>
			<Form>
				<Title type="h3" className={styles.title}>
					{t('choose_conditions_to_change')}
				</Title>
				<Button buttonClassName={styles.button} onClick={toggleShow}>
					<Add className={styles.iconWhite} />
					{t('select')}
				</Button>
				{show && (
					<div className={styles.col}>
						<SelectAll label={t('select_all')} />
						<Field name="contractTerm">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_contract_term')} />}
						</Field>
						<Field name="contractPosition">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_contract_position')} />}
						</Field>
						<Field name="contractWorkingHours">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_working_hours')} />}
						</Field>
						<Field name="contractJobType">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_work_type')} />}
						</Field>
						<Field name="contractJobLocation">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_work_country')} />}
						</Field>
						<Field name="contractOther">
							{({ field }) => <WrappedCheckbox {...field} label={t('change_contract_other')} />}
						</Field>
						{isTransfer && (
							<div className={styles.checkbox__wrapper}>
								<Checkbox label={t('change_iin_bin')} value={true} disabled />
							</div>
						)}
						<div className={styles.footer}>
							<Button buttonClassName={styles.button} type="bare" onClick={onCancelEditing}>
								{t('cancel')}
							</Button>
							<SubmitButton isTransfer={isTransfer} label={t('continue')} />
						</div>
					</div>
				)}
			</Form>
		</Formik>
	)
}
