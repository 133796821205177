import { Button } from '@/ui'
import { EBook } from '@app/images'
import { useToggle } from '@/shared'
import { useTranslation } from 'react-i18next'
import { Form } from '@features/eCollectiveContracts/registration/Form/Form'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eColContractsActions, eColContractsSelectors, eColContractsThunks } from '@/entity'
import { initialEColContract } from '@/entity/eCollectiveContracts/model/reducer'
import { contractActionsSelectors } from '@features/contracts/model'
import { Project } from '@features/eCollectiveContracts/registration/Form/Project'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { NavLink } from 'react-router-dom'
import styles from './Registration.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const Registration = ({ fetchData, isTradeUnionNotEmpty, tradeUnion, isTradeUnion, isEmployee }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isCreate, toggleCreate] = useToggle(false)
	const eHtml = useSelector(eColContractsSelectors.eHtml)
	const eHtmlSuccess = useSelector(eColContractsSelectors.eHtmlSuccess)
	const isLoadingContract = useSelector(eColContractsSelectors.isLoadingContract)
	const isSuccessContract = useSelector(eColContractsSelectors.isSuccessContract)
	const options = useSelector(contractActionsSelectors.options)
	const [openEForm, setOpenEForm] = useState(false)
	const [colContractData, setColContractData] = useState(null)
	const [tempTransformedColContract, setTempTransformedColContract] = useState(null)
	const lang = getCurrentLanguage()

	const onClear = () => {
		dispatch(eColContractsActions.setEColContractSuccess(false))
	}

	useEffect(() => {
		if (eHtmlSuccess) {
			setColContractData(tempTransformedColContract)
			setTimeout(() => setTempTransformedColContract(null))
		}
	}, [eHtmlSuccess])

	const Close = () => {
		toggleCreate(false)
	}

	const onCancel = () => {
		setOpenEForm(false)
	}

	let eColContract
	if (isTradeUnionNotEmpty && isTradeUnion) {
		let localUnion = tradeUnion.typeCode === 'local'
		eColContract = {
			...initialEColContract,
			bin: tradeUnion.employers[0].employerBin,
			enterpriseName: tradeUnion.employers[0].employerName,
			enterpriseNameKaz: tradeUnion.employers[0].employerName,
			unions: [
				{
					...initialEColContract.unions[0],
					unionTypeCode: localUnion ? '2' : '1',
					unionBin: localUnion ? tradeUnion.bin : '',
					unionName: tradeUnion.unionName,
					unionNameKaz: tradeUnion.unionName,
					representativeDtos: [
						{
							...initialEColContract.unions[0].representativeDtos[0],
							representativeFIO: tradeUnion.chiefFio,
							representativeIin: tradeUnion.chiefIin,
						},
					],
				},
			],
			externalId: null,
		}
	}

	const onSubmit = (data) => {
		let transformedColContract = {
			clang: data.language,
			contractDTO: {
				bin: data.bin,
				collectiveContractDetails: {
					eighthGradeMax: data.eighthGradeMax,
					eighthGradeMin: data.eighthGradeMin,
					fifthGradeMax: data.fifthGradeMax,
					fifthGradeMin: data.fifthGradeMin,
					firstGradeMax: data.firstGradeMax,
					firstGradeMin: data.firstGradeMin,
					fourthGradeMax: data.fourthGradeMax,
					fourthGradeMin: data.fourthGradeMin,
					fundingAmount: data.fundingAmount,
					rateInfo: {
						firstCategoryRate: data.firstCategoryRate,
						industryAgreementCoefficient: data.industryAgreementCoefficient,
						maxCoefficient: data.maxCoefficient,
						minCoefficient: data.minCoefficient,
					},
					relaxTime: data.relaxTime,
					secondGradeMax: data.secondGradeMax,
					secondGradeMin: data.secondGradeMin,
					securityFundingVolume: data.securityFundingVolume,
					seventhGradeMax: data.seventhGradeMax,
					seventhGradeMin: data.seventhGradeMin,
					sixthGradeMax: data.sixthGradeMax,
					sixthGradeMin: data.sixthGradeMin,
					thirdGradeMax: data.thirdGradeMax,
					thirdGradeMin: data.thirdGradeMin,
					workTime: data.workTime,
					dcolContractEnterpriseTypeCode: data.dcolContractEnterpriseTypeCode,
					dcolContractOwnershipTypeCode: data.dcolContractOwnershipTypeCode,
				},
				collectiveContractNumber: data.collectiveContractNumber,
				companyName: data.enterpriseName,
				companyNameKaz: data.enterpriseNameKaz,
				dateFrom: data.dateFrom,
				dateTo: data.dateTo,
				electronic: true,
				unions: data.unions,
				extraGuarantee: data.extraGuarantee,
			},
			externalId: data.externalId,
		}
		// Добавляем overtimePay только если overtimePayIs равно true
		if (data.overtimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.overtimePay = data.overtimePay
		}
		if (data.holidayPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.holidayPay = data.holidayPay
		}
		if (data.nightTimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.nightTimePay = data.nightTimePay
		}
		if (data.combinationPositionPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.combinationPositionPay = data.combinationPositionPay
		}
		if (data.termCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.termCompensationPay = data.termCompensationPay
		}
		if (data.wayCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wayCompensationPay = data.wayCompensationPay
		}
		if (data.wageIndexProcedureIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wageIndexProcedure = data.wageIndexProcedure
		}
		dispatch(eColContractsThunks.getHtml(transformedColContract, isEmployee))
		setTempTransformedColContract(transformedColContract)
		setOpenEForm(true)
	}

	const InfoLink =
		lang === 'rus' ? (
			<p>
				{t('col_contract.info_for_create')}
				<NavLink to="/prof" className={styles.item} activeClassName={styles.navLinkActive}>
					{t('profUnions.generalInformation')}
				</NavLink>
			</p>
		) : (
			<p>
				{t('col_contract.info_for_create')}
				<NavLink to="/prof" className={styles.item} activeClassName={styles.navLinkActive}>
					{t('profUnions.generalInformation')}
				</NavLink>
				{t('col_contract.info_for_create_2')}
			</p>
		)

	useEffect(() => {
		if (isSuccessContract) {
			fetchData()
			setOpenEForm(false)
			Close()
			setTimeout(() => onClear())
		}
	}, [isSuccessContract])

	const handleSendToEmployer = (parsedData, isSendToEmployer) => {
		// Очистка поля required из parsedDate
		const cleanedParsedDate = parsedData.map(({ required, ...rest }) => rest)

		// Формирование нового объекта с добавлением valueList
		const combinedData = {
			...colContractData,
			valueList: cleanedParsedDate,
		}

		// Использование нового объекта combinedData вместо parsedDate
		dispatch(eColContractsThunks.save(combinedData, isSendToEmployer, isEmployee))
	}

	return (
		<div>
			{isTradeUnion ? (
				<Button icon={EBook} iconSize={'big'} disabled={!isTradeUnionNotEmpty} onClick={toggleCreate}>
					{t('contracts_tab.collective_agreement_conclude')}
				</Button>
			) : null}
			{!isTradeUnionNotEmpty ? <InfoDevelopment width48={true} child={InfoLink} /> : null}
			{isCreate && isTradeUnionNotEmpty ? (
				<Form initialValues={eColContract} onCancel={Close} options={options} onSubmit={onSubmit}></Form>
			) : null}
			{!isLoadingContract && openEForm && !!eHtml ? (
				<Project
					eHtml={eHtml}
					onCancel={onCancel}
					handleSendToEmployer={handleSendToEmployer}
					isTradeUnion={isTradeUnion}
				></Project>
			) : null}
		</div>
	)
}
