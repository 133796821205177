import React, { useCallback } from 'react'
import { Checkbox } from '@/ui'

export const WrappedCheckbox = ({ onChange, value, ...props }) => {
	const handleCheckboxChange = useCallback(() => {
		onChange(!value)
	}, [value, onChange])

	return <Checkbox onChange={handleCheckboxChange} value={value} {...props} />
}
