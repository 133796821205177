import { Table, Terminations } from '@features/laborRecords/frame/Activities/Details/components'

export const Details = ({ details }) => {
	return (
		<>
			{details.map((detail, index) => (
				<Terminations key={index} detail={detail}></Terminations>
			))}
			<Table data={details}></Table>
		</>
	)
}
