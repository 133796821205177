import { useTranslation } from 'react-i18next'
import { ProfileTemplate } from '@/widgets'
import { Button } from '@/ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { ProfUnions } from '@/features'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { TradeUnionsTable } from '@features/profUnions/table/TradeUnionsTable'
import { HrPagination } from '@/components/Pagination'
import { View } from '@features/profUnions/View'
import { initialQueryState } from '@/entity/profUnions/model/reducer'

export const TradeUnionsPage = ({ buttonClassName = '' }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.user)
	const profUnionsList = useSelector(profUnionsSelectors.profUnionsList)
	const profUnions = useSelector(profUnionsSelectors.profUnions)
	const tradeUnion = useSelector(profUnionsSelectors.tradeUnion)
	let isTradeUnionNotEmpty = tradeUnion?.externalId?.length > 0
	const isEmployee = useSelector(userSelectors.isEmployee)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const isMobile = window.innerWidth < 768
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
		approve: false,
	})

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(profUnionsThunks.getProfUnionsList(page, pageSize))
		},
		[queryParams]
	)
	useEffect(() => {
		dispatch(profUnionsThunks.getOptions())
		dispatch(profUnionsThunks.getTradeUnion(isEmployee))
	}, [])
	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const Close = () => {
		onToggleAction('create', false)
		onToggleAction('edit', false)
		onToggleAction('view', false)
	}

	useEffect(() => {
		isTradeUnionNotEmpty = tradeUnion?.bin?.length > 0
	}, [tradeUnion])

	return (
		<ProfileTemplate title={'profUnions.name'}>
			<Button
				onClick={() => {
					onToggleAction('create', true)
				}}
				buttonClassName={buttonClassName}
				disabled={user?.manager || !isTradeUnionNotEmpty}
			>
				{t('profUnions.addProfUnion')}
			</Button>
			{profUnions.totalElements > 0 ? (
				<>
					<TradeUnionsTable
						onToggleAction={onToggleAction}
						profUnionsList={profUnionsList}
						isEmployee={isEmployee}
					></TradeUnionsTable>
					<HrPagination
						id="hr-pagination"
						rowsPerPageitems={
							isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
						}
						rowsPerPage={queryParams.pageSize}
						numberOfElements={profUnions.numberOfElements}
						totalElements={profUnions.totalElements}
						totalPages={profUnions.totalPages}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						page={queryParams.page}
					/>
				</>
			) : (
				<InfoDevelopment
					title={isEmployee ? t('profUnions.employee_info_list') : t('profUnions.infoList')}
					width48={true}
				/>
			)}
			{actions.create || actions.edit ? (
				<ProfUnions
					onCancel={Close}
					actions={actions}
					activeCompany={activeCompany}
					cabinetTradeUnion={isEmployee}
					tradeUnion={tradeUnion}
				></ProfUnions>
			) : null}
			{actions.view && isTradeUnionNotEmpty ? (
				<View onClose={Close} onToggleAction={onToggleAction} tradeUnion={tradeUnion}></View>
			) : null}
		</ProfileTemplate>
	)
}
