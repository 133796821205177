import styles from './Experience.module.scss'
import { Title, Button, Field, SelectSearch, DatePicker } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field as FormikField } from 'formik'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Info } from '@app/icons'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { pensionSelectors } from '@/entity'
import * as Yup from 'yup'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'

export const Experience = ({ birthDate, data, addItem, onClose }) => {
	const { t } = useTranslation()
	const maxDate = new Date()
	const pensionPrefExp = useSelector(pensionSelectors.pensionPrefExp)
	const pensionExp = useSelector(pensionSelectors.pensionExp)
	const dateFromMax = (dateTo) => {
		return dateTo ? new Date(convert(dateTo)) : new Date()
	}
	const dateToMin = (dateFrom) => {
		return dateFrom ? new Date(convert(dateFrom)) : new Date()
	}
	const convert = (date) => {
		const prev = date.split('.')
		return new Date(prev[2], prev[1] - 1, prev[0])
	}
	const ValidationSchema = Yup.object().shape({
		expTypeCode: Yup.string().required(t('required')).nullable(),
		dateFrom: Yup.string().required(t('required')),
		dateTo: Yup.string().required(t('required')),
		expName: Yup.string().required(t('required')),
	})
	const dateByBirthDate = () => {
		const date = new Date(convert(birthDate))
		return new Date(date.setFullYear(date.getFullYear() + 14))
	}
	return (
		<ModalWS show={true} windowClassName={styles.modal}>
			<ModalWSBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<div className={styles.header}>
					<Title className={styles.title}>{t('pension.payments.form.exp_form.adding_seniority')}</Title>
				</div>
				<Formik
					initialValues={data}
					validationSchema={ValidationSchema}
					onSubmit={(data) => {
						const expType = pensionExp.find((a) => a.code === data.expTypeCode)
						data.expType = { code: expType.code, nameRu: expType.rus, nameKz: expType.kz }
						if (data.prefExpTypeCode) {
							const prefExpType = pensionPrefExp.find((a) => a.code === data.prefExpTypeCode)
							data.prefExpType = { code: prefExpType.code, nameRu: prefExpType.rus, nameKz: prefExpType.kz }
						}
						addItem(data)
					}}
				>
					{({ values }) => (
						<Form>
							<Box className={styles.mb20}>
								<FormikField name="expTypeCode">
									{({ field, meta: { touched, error } }) => (
										<Box className={styles.mb20}>
											<Tooltip
												placement="right"
												color="white"
												overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
												title={t('pension.payments.hints.type_of_experience')}
											>
												<label>
													{t('pension.payments.form.exp_form.type_of_experience')}
													<Info className={styles.hintIcon} />
												</label>
											</Tooltip>
											<SelectSearch
												options={pensionExp}
												fieldtype="select"
												{...field}
												placeholder={t('pension.payments.form.exp_form.choose_type_of_experience')}
												error={error ? t(error) : ''}
											/>
											{error && <div className={styles.errorColor}>{t(error)}</div>}
										</Box>
									)}
								</FormikField>
							</Box>
							<div className={'flex-1'}>
								<FormikField name="prefExpTypeCode">
									{({ field, meta: { touched, error } }) => (
										<Box className={styles.mb20}>
											<Tooltip
												placement="right"
												color="white"
												overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
												title={t('pension.payments.hints.benefit_type')}
											>
												<label>
													{t('pension.payments.form.exp_form.benefit_type')}
													<Info className={styles.hintIcon} />
												</label>
											</Tooltip>
											<SelectSearch
												options={pensionPrefExp}
												fieldtype="select"
												{...field}
												placeholder={t('pension.payments.form.exp_form.select_benefit_type')}
												error={touched && error ? t(error) : ''}
											/>
										</Box>
									)}
								</FormikField>
							</div>
							<Box display="flex" alignItems="flex-end" className={styles.information}>
								<FormikField name="dateFrom">
									{({ field, meta: { touched, error } }) => (
										<Box className={styles.mb20}>
											<Tooltip
												placement="right"
												color="white"
												overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
												title={t('pension.payments.hints.period_start_date')}
											>
												<label>
													{t('pension.payments.form.exp_form.start_date')}
													<Info className={styles.hintIcon} />
												</label>
											</Tooltip>
											<DatePicker
												{...field}
												minDate={dateByBirthDate()}
												maxDate={dateFromMax(values.dateTo)}
												showIcon
												placeholder={t('pension.payments.form.exp_form.date_format')}
											/>
											{touched && error && <div className={styles.errorColor}>{t(error)}</div>}
										</Box>
									)}
								</FormikField>
								<FormikField name="dateTo">
									{({ field, meta: { touched, error } }) => (
										<Box className={styles.mb20}>
											<Tooltip
												placement="right"
												color="white"
												overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
												title={t('pension.payments.hints.period_end_date')}
											>
												<label>
													{t('pension.payments.form.exp_form.end_date')}
													<Info className={styles.hintIcon} />
												</label>
											</Tooltip>
											<DatePicker
												{...field}
												disabled={!values.dateFrom}
												minDate={dateToMin(values.dateFrom)}
												maxDate={maxDate}
												showIcon
												placeholder={t('pension.payments.form.exp_form.date_format')}
											/>
											{touched && error && <div className={styles.errorColor}>{t(error)}</div>}
										</Box>
									)}
								</FormikField>
							</Box>
							<FormikField name="expName">
								{({ field, meta: { touched, error } }) => (
									<Box className={styles.mb20}>
										<Tooltip
											placement="right"
											color="white"
											overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
											title={t('pension.payments.hints.name_of_experience')}
										>
											<label>
												{t('pension.payments.form.exp_form.name_of_experience')}
												<Info className={styles.hintIcon} />
											</label>
										</Tooltip>
										<Field
											placeholder={t('pension.payments.form.exp_form.enter_name')}
											type="text"
											{...field}
											error={touched && error ? t(error) : ''}
										/>
									</Box>
								)}
							</FormikField>
							<div className={styles.footer}>
								<Button buttonClassName={styles.button} type="bare" onClick={onClose}>
									{t('cancel')}
								</Button>
								<Button type="submit">{t('save')}</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</ModalWS>
	)
}
