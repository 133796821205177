export const isLoading = (state) => state.user?.isLoading
export const isSuccess = (state) => state.user?.isSuccess
export const user = (state) => state.user?.user
export const activeRole = (state) => state.user?.activeRole
export const isEmployer = (state) => state.user?.activeRole === 'ROLE_EMPLOYER'
export const isEmployee = (state) => state.user?.activeRole === 'ROLE_EMPLOYEE'
export const role = (state) => (isEmployer(state) ? 'employer' : 'employee')
export const userByIin = (state) => state.user?.userByIin
export const employerCompanies = (state) => state.user?.employerCompanies
export const currentFlEmployer = (state) => state.user?.user?.currentFlEmployer
