import { Remove as RemoveIcon, Sign as SignIcon, View as ViewIcon } from '@app/images'

export const headCells = [
	{
		id: 'contractNumber',
		numeric: false,
		disablePadding: true,
		label: 'Инициатор',
	},
	{
		id: 'establishedPost',
		numeric: true,
		disablePadding: false,
		label: 'ИИН',
	},
	{
		id: 'companyName',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'registerDate',
		numeric: true,
		disablePadding: false,
		label: 'Дата запроса',
	},
	{
		id: 'dateFrom',
		numeric: true,
		disablePadding: false,
		label: 'Статус запроса',
	},
	{
		id: 'action',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.action',
	},
]

export const getActions = (t, code = '1', role, initEmployer) => {
	const view = { icon: ViewIcon, title: t('actions.view'), action: 'view' }
	const sign = { icon: SignIcon, title: t('actions.sign'), action: 'sign' }
	const decline = { icon: RemoveIcon, title: t('actions.decline'), action: 'decline' }
	const revoke = { icon: RemoveIcon, title: t('actions.revoke'), action: 'revoke' }

	const actionsStatuses =
		role == 'ROLE_EMPLOYER'
			? {
					1: [view],
					2: [view, sign, decline],
					3: [view, sign],
					4: [view, revoke],
					6: [view],
					7: [view, sign, decline],
					8: [view],
					9: [view],
					10: [view],
					11: [view],
					12: [view],
					13: [view],
					14: [view],
					15: [view],
			  }
			: {
					1: [view],
					2: [view, sign, decline],
					3: [view, sign],
					4: [view, sign, decline],
					6: [view],
					7: initEmployer ? [view] : [view, revoke],
					8: [view],
					9: [view],
					10: [view],
					11: [view],
					12: [view],
					13: [view],
					14: [view],
					15: [view, revoke],
			  }
	return actionsStatuses[code] ?? actionsStatuses[2]
}
