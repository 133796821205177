export const headCells = [
	{
		id: 'number',
		numeric: true,
		disablePadding: false,
		label: 'personal_affairs.table.field_number',
	},
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'personal_affairs.card.table.headers.name',
	},
	{
		id: 'requestStatus',
		numeric: false,
		disablePadding: true,
		label: 'personal_affairs.table.request_status',
	},
]
