import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui/atoms/dataGridTable/dataGridTable'
import styles from './InfoTable.module.scss'
import { renderWithHTML } from '@/utils'

export const InformationAboutCompany = ({ company }) => {
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const contactInformation = [
		{ title: t('kind_activity'), data: company?.dokedCode?.[currentLanguage] },
		{ title: t('type_ownership'), data: company?.downershipTypeCode?.[currentLanguage] },
		{ title: t('region'), data: company?.ddistrictCode?.[currentLanguage] },
		{ title: t('district'), data: company?.dregionCode?.[currentLanguage] },
		{ title: t('locality'), data: company?.dlocalityCode?.[currentLanguage] },
		{
			title: t('address'),
			data: `${
				company?.house
					? `${company?.street ? `${company.street},` : ''}`
					: `${company?.street ? `${company.street}` : ''}`
			}
			${
				company?.office
					? `${company?.house ? `${t('house').toLowerCase()} ${company.house},` : ''}`
					: `${company?.house ? `${t('house').toLowerCase()} ${company.house}` : ''}`
			}
			${company?.office ? `${t('office').toLowerCase()} ${company.office}` : ''}`,
		},
		{ title: t('web_address'), data: company?.website },
		{ title: t('employee_number'), data: company?.employeeCnt },
		{ title: t('details_about_company'), data: renderWithHTML(company?.about) },
	]
	return (
		<div>
			<DataGridTable
				hideSubTitle
				title={'information_about_company'}
				information={contactInformation}
				titleClass={styles.tableTitleCell}
			/>
		</div>
	)
}
