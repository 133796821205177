import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	dictVersion: null,
}
export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_DICT_VERSION:
			return {
				...state,
				dictVersion: payload,
			}
		default:
			return state
	}
}
