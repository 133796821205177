import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { SignStatus } from '../badges'
import { contractsSelectors, contractsThunks, userSelectors } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AdditionalContracts.module.scss'
import { Unsign } from '@app/icons'
import Tooltip from '@mui/material/Tooltip'
import { handbookSelectors } from '@/entity/handbook'
import onContractActionMenuClick from '@features/contracts/info-table/actionMenu/onContractActionMenuClick'

export const AdditionalContracts = ({ contracts, onViewButtonClick }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeRole = useSelector(userSelectors.activeRole)
	const positionsByKnz = useSelector(handbookSelectors.positionsByKnz)
	const parentContract = useSelector(contractsSelectors.contract)
	const [actionMenuItems, setActionMenuItems] = useState([])
	const onSelect = (agreement) => dispatch(contractsThunks.setAdditionalContract(agreement))

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>{t('contracts_tab.number')}</th>
						<th>{t('contracts_tab.conclusion_date')}</th>
						<th>{t('contracts_tab.begin_date')}</th>
						<th>{t('contracts_tab.expired_date')}</th>
						<th>{t('contracts_tab.registration_date')}</th>
						<th>{t('contracts_tab.state')}</th>
						<th>{t('contracts_tab.action')}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{contracts.map((contract) => (
						<tr key={contract.externalId}>
							<td>{contract.contractNum || '-'}</td>
							<td>{contract.registerDate || '-'}</td>
							<td>{contract.dateFrom || '-'}</td>
							<td>{contract.dateTo || '-'}</td>
							<td>{contract.entryDate || '-'}</td>
							<td>{<SignStatus item={contract.dcontractState} />}</td>
							<td
								onClick={(e) =>
									onContractActionMenuClick(
										contract,
										e,
										setActionMenuItems,
										t,
										activeRole,
										parentContract,
										positionsByKnz
									)
								}
							>
								<DropDownMenu
									title={t('select_pls')}
									items={actionMenuItems}
									onItemClick={(mode) => onViewButtonClick(mode)}
									onButtonClick={() => onSelect(contract)}
								/>
							</td>
							<td>
								{activeRole !== 'ROLE_EMPLOYEE' && contract.dsendStatusCode && !contract.desutdRegErrorCode && (
									<Tooltip
										placement="top-start"
										title={t('package_loading.warning_text_additional')}
										classes={{
											popper: styles.tooltip__popper,
											tooltip: styles.tooltip__root,
										}}
									>
										<span className={styles.signature__icon__wrapper}>
											<Unsign fill="#FFBC34" />
										</span>
									</Tooltip>
								)}

								{activeRole !== 'ROLE_EMPLOYEE' && contract.desutdRegErrorCode && (
									<Tooltip
										placement="top-start"
										title={t('package_loading.warning_text_additional')}
										classes={{
											popper: styles.tooltip__popper,
											tooltip: styles.tooltip__root,
										}}
									>
										<span className={styles.signature__icon__wrapper}>
											<Unsign fill="#E65332" />
										</span>
									</Tooltip>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}
