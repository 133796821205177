import Box from '@mui/material/Box'
import { Clock, Success, CrossRounded, Processing } from '@app/icons'
import styles from './StatusSent.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'

const statuses = {
	UNSENT: {
		icon: <Clock className={styles.status__icon__unsent} />,
		wrapperClassName: styles.status__label__unsent,
	},
	SENT: {
		icon: <Success className={styles.status__icon__success} />,
		wrapperClassName: styles.status__label__success,
	},
	ERROR: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	PROCESSING: {
		icon: <Processing className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
}

export const StatusSent = ({ status, errorCode }) => {
	const { staffEmpStatus } = useSelector(contractActionsSelectors.options)
	const esutdRegErrors = useSelector((state) => state.handbook.esutdRegErrors)
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const statusElement = statuses[status] ?? statuses['UNSENT']
	const statusName = staffEmpStatus.find(({ code }) => code === status)
	const titleError = esutdRegErrors?.find((item) => item.code === errorCode)

	return (
		<Box display="flex" alignItems="center" title={(titleError && titleError[language]) || ''}>
			<Box>{statusElement.icon ?? statusElement.icon}</Box>
			<Box className={statusElement.wrapperClassName}>{statusName[language]}</Box>
		</Box>
	)
}
