export const TOGGLE_LOADING = 'laborRecords/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'laborRecords/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'laborRecords/TOGGLE_ERROR'
export const SET_LABOR_RECORDS = 'laborRecords/SET_LABOR_RECORDS'
export const TOGGLE_ADJUSTMENT_LOADING = 'laborRecords/TOGGLE_ADJUSTMENT_LOADING'
export const TOGGLE_ADJUSTMENT_SUCCESS = 'laborRecords/TOGGLE_ADJUSTMENT_SUCCESS'
export const SET_REQUESTS_ADJUSTMENT = 'laborRecords/SET_REQUESTS_ADJUSTMENT'
export const TOGGLE_ADJUSTMENT_REFRESH = 'laborRecords/TOGGLE_ADJUSTMENT_REFRESH'
export const TOGGLE_ADJUSTMENT_ERROR = 'laborRecords/TOGGLE_ADJUSTMENT_ERROR'
export const TOGGLE_REQUEST_LOADING = 'laborRecords/TOGGLE_REQUEST_LOADING'
export const TOGGLE_REQUEST_SUCCESS = 'laborRecords/TOGGLE_REQUEST_SUCCESS'
export const TOGGLE_REQUEST_ERROR = 'laborRecords/TOGGLE_REQUEST_ERROR'
export const SET_REQUEST_DETAIL = 'laborRecords/SET_REQUEST_DETAIL'
export const SET_SHORT_CONTRACT_DATA = 'laborRecords/SET_SHORT_CONTRACT_DATA'
export const SET_ADJUSTMENT_XML = 'laborRecords/SET_ADJUSTMENT_XML'
export const TOGGLE_ADJUSTMENT_XML_LOADING = 'laborRecords/TOGGLE_ADJUSTMENT_XML_LOADING'
export const TOGGLE_ADJUSTMENT_XML_SUCCESS = 'laborRecords/TOGGLE_ADJUSTMENT_XML_SUCCESS'
export const RESET_ADJUSTMENT = 'laborRecords/RESET_ADJUSTMENT'
export const SET_CANCEL_ADJUSTMENT_XML = 'laborRecords/SET_CANCEL_ADJUSTMENT_XML'
export const TOGGLE_CANCEL_ADJUSTMENT_XML_LOADING = 'laborRecords/TOGGLE_CANCEL_ADJUSTMENT_XML_LOADING'
export const TOGGLE_CANCEL_ADJUSTMENT_XML_SUCCESS = 'laborRecords/TOGGLE_CANCEL_ADJUSTMENT_XML_SUCCESS'
export const DIGIT_CONTRACT_LOCAL = 'laborRecords/DIGIT_CONTRACT_LOCAL'
export const REFRESH_CONTRACTS = 'laborRecords/REFRESH_CONTRACTS'
export const TOGGLE_LOCAL_DIGIT_SUCCESS = 'laborRecords/TOGGLE_LOCAL_DIGIT_SUCCESS'