import { useTranslation } from 'react-i18next'
import { Title } from '@/ui'
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import styles from './Composition.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Tabs } from '@/widgets'
import { initialQueryState } from '@/entity/profUnions/model/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { HrPagination } from '@/components/Pagination'
import { InfoDevelopment } from '@/components/infoDevelopment'

const headCells = [
	{
		id: 'iin',
		numeric: true,
		label: 'iin',
	},
	{
		id: 'fio',
		numeric: false,
		label: 'fio',
	},
	{
		id: 'position',
		numeric: true,
		label: 'contract.info.position',
	},
]

const personsHeadCells = [
	{
		id: 'iin',
		numeric: true,
		label: 'iin',
	},
	{
		id: 'fio',
		numeric: false,
		label: 'fio',
	},
	{
		id: 'date_in',
		numeric: true,
		label: 'profUnions.date_in',
	},
]

export const Composition = ({ tradeUnion, isTradeUnionsItem }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { managers } = tradeUnion
	const dispatch = useDispatch()
	const personsData = useSelector(profUnionsSelectors.personsData)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const isMobile = window.innerWidth < 768

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(profUnionsThunks.getPersonsList(page, pageSize, tradeUnion.externalId))
		},
		[queryParams, tradeUnion]
	)

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize, tradeUnion])

	// Создаем новый массив из managers, исключая тех, у кого iin = ""
	const filteredManagers = managers ? managers.filter((manager) => manager.iin !== '') : []
	filteredManagers.unshift({
		iin: tradeUnion.chiefIin,
		fio: tradeUnion.chiefFio,
		position: t('profUnions.chairman_trade_union'),
	})
	const tabControlRef = useRef(null)

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.iin || '-'}</td>
				<td>{row.fio}</td>
				<td>{row.position}</td>
			</tr>
		),
		[lang]
	)

	const getPersonTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.iin || '-'}</td>
				<td>{row.fio}</td>
				<td>{row.dateIn}</td>
			</tr>
		),
		[lang]
	)

	const tableBody = useMemo(
		() => filteredManagers?.map((row, index) => getTableRow(row, index)),
		[filteredManagers, getTableRow]
	)

	const personsTableBody = useCallback(
		(data) => data?.map((row, index) => getPersonTableRow(row, index)),
		[personsData?.persons, getPersonTableRow]
	)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const personsHeader = useMemo(
		() => (
			<>
				{personsHeadCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const governingList = (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>{!!filteredManagers?.length > 0 ? tableBody : null}</tbody>
		</table>
	)

	const managersList = (
		<table className={styles.personsTable}>
			<thead>
				<tr>{personsHeader}</tr>
			</thead>
			<tbody>{(personsData?.persons && personsData?.persons?.length > 0) ? personsTableBody(personsData?.persons) : null}</tbody>
		</table>
	)

	const tabs = ["profUnions.titleGoverning", "profUnions.managersList"]

	const data = {
		"profUnions.titleGoverning": (
			<div>
				{governingList}
			</div>
		),
		"profUnions.managersList": (
			<div>
				{personsData && personsData?.persons && personsData.persons.length > 0 ? (
					<>
						{managersList}
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={
								isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
							}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={personsData.numberOfElements}
							totalElements={personsData.totalElements}
							totalPages={personsData.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</>
				) : (
					<InfoDevelopment
						title={t('profUnions.personsEmptyInfo')}
						width48={true}
					/>
				)}
			</div>
		)
	}

	return (
		<section>
			{
				isTradeUnionsItem ?
					<Tabs tabs={tabs} data={data} ref={tabControlRef} /> :
					<>
						<div className={styles.docs}>
							<Title>{t('profUnions.titleGoverning')}</Title>
						</div>
						{governingList}
					</>
			}
		</section>
	)
}
