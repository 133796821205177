import { getCurrentLanguage } from '@/i18next'
import styles from './DocumentState.module.scss'

const colors = {
	1: styles.active,
	2: styles.terminated,
	3: styles.expired,
	4: styles.transfered,
	5: styles.notregistered,
	6: styles.notregistered,
	7: styles.notregistered,
	8: styles.notregistered,
}

export const DocumentState = ({ item }) => {
	const lang = getCurrentLanguage()

	return (
		<span className={`${styles.item} ${colors[item.code]}`}>
			{lang === 'rus' ? item.nameRu || item.rus : item.nameKz || item.kz}
		</span>
	)
}
