import { useTranslation } from 'react-i18next'
import { ProfileTemplate } from '@/widgets'
import { Button } from '@/ui'
import { TradeUnionsTable } from '@features/profUnions/table/TradeUnionsTable'
import { HrPagination } from '@/components/Pagination'
import { InfoDevelopment } from '@/components/infoDevelopment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { AddMembers } from '@/features'
import { initialQueryState } from '@/entity/profUnions/model/reducer'
import { MembersTable } from '@features/profUnions/table/MembersTable'
import TradeMembersSearch from '@features/profUnions/search/tradeMembersSearch'

export const MembersTradeUnionPage = ({ buttonClassName = '' }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.user)
	const tradeUnion = useSelector(profUnionsSelectors.tradeUnion)
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const members = useSelector(profUnionsSelectors.members)
	const isMobile = window.innerWidth < 768
	let isTradeUnionNotEmpty = tradeUnion?.externalId?.length > 0
	let isApproved = !!user?.iin && !!tradeUnion?.chiefIin ? user?.iin === tradeUnion?.chiefIin : false
	let isMembersNotEmpty = members?.members?.length > 0
	const [actions, setActions] = useState({
		add: false,
		exclude: false,
		approve: false,
		decline: false,
	})
	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}
	const Close = () => {
		onToggleAction('add', false)
		onToggleAction('exclude', false)
		onToggleAction('approve', false)
		onToggleAction('decline', false)
	}
	const { externalId } = tradeUnion

	useEffect(() => {
		dispatch(profUnionsThunks.getOptions())
		dispatch(profUnionsThunks.getTradeUnion(isEmployee))
	}, [isEmployee])

	const [searchIin, setSearchIin] = useState("")

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			if (externalId) {
				dispatch(profUnionsThunks.getTradeUnionMembers(externalId, searchIin,page, pageSize))
			}
		},
		[queryParams, externalId, searchIin]
	)
	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize, externalId])

	useEffect(() => {
		isTradeUnionNotEmpty = tradeUnion?.bin?.length > 0
	}, [tradeUnion])

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	return (
		<ProfileTemplate title={'profUnions.name'}>
			{' '}
			<Button
				onClick={() => {
					onToggleAction('add', true)
				}}
				buttonClassName={buttonClassName}
				disabled={user?.manager || !isTradeUnionNotEmpty}
			>
				{t('profUnions.add_prof_union_member')}
			</Button>
			{isTradeUnionNotEmpty && isMembersNotEmpty ? (
				<>
					<TradeMembersSearch fetchData={fetchData} searchIin={searchIin} setSearchIin={setSearchIin} />
					<MembersTable
						onToggleAction={onToggleAction}
						members={members.members}
						tradeUnion={tradeUnion}
						isEmployee={isEmployee}
						page={queryParams.page}
						pageSize={queryParams.pageSize}
						isApproved={isApproved}
					></MembersTable>
					<HrPagination
						id="hr-pagination"
						rowsPerPageitems={
							isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
						}
						rowsPerPage={queryParams.pageSize}
						numberOfElements={members.numberOfElements}
						totalElements={members.totalElements}
						totalPages={members.totalPages}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						page={queryParams.page}
					/>
				</>
			) : (
				<InfoDevelopment title={t('profUnions.info_members')} width48={true} />
			)}
			{actions.add ? <AddMembers onClose={Close} externalId={externalId}></AddMembers> : null}
		</ProfileTemplate>
	)
}
