import { actions } from '@/entity/contracts/model/actions'
import { contractsSelectors, userSelectors } from '@/entity'
import { api } from '@/shared'
import { eContractsActions } from '@/entity/eContracts'
import { message } from 'antd'

export const initTermEmployer = (submitData) => {
	return async (dispatch, getState) => {
		try {
			dispatch(eContractsActions.setTerminationLoading(true))
			const state = getState()
			const { externalId } = contractsSelectors.contractData(state)
			const payload = {
				...submitData,
				id: null,
				signedXml: null,
				externalId,
			}

			const { xmlData } = await api.econtracts.send(payload)
			alert('Операция выполнена успешно!')
			// setToLocalStorage(storageKeys.XML_DATA, xmlData)
			// dispatch(actions.setTerminateXML(xmlData))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			dispatch(eContractsActions.setTerminationLoading(false))
		}
	}
}
export const initTermEmployee = (submitData) => {
	return async (dispatch, getState) => {
		try {
			dispatch(eContractsActions.setTerminationLoading(true))
			const state = getState()
			const { externalId } = contractsSelectors.contractData(state)
			const payload = {
				...submitData,
				id: null,
				externalId,
			}

			const { xmlData } = await api.econtracts.xmlOnCreate(payload)
			const data = {
				...payload,
				signedXml: xmlData,
			}
			await api.econtracts.signAndCreate(data)
			// setToLocalStorage(storageKeys.XML_DATA, xmlData)
			// dispatch(actions.setTerminateXML(xmlData))
			alert('Операция выполнена успешно!')
		} catch (e) {
		} finally {
			dispatch(eContractsActions.setTerminationLoading(false))
		}
	}
}

export const signEmployeeTermination = (submitData, signedXml) => {
	return async (dispatch, getState) => {
		try {
			const payload = {
				...submitData,
				signedXml,
			}
			const response = await api.econtracts.signAndCreate(payload)
		} catch (e) {
		} finally {
		}
	}
}

export const saveAndGetXml = (submitData, externalId) => {
	return async (dispatch, getState) => {
		try {
			dispatch(eContractsActions.setTerminationLoading(true))
			const payload = {
				...submitData,
				externalId,
			}

			const res = await api.econtracts.saveAndGetXml(payload)
			dispatch(eContractsActions.setTerminateXML(res));

		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			dispatch(eContractsActions.setTerminationLoading(false))
		}
	}
}

export const getXml = (payload) => {
	return async (dispatch, getState) => {
		try {
			dispatch(eContractsActions.setTerminationLoading(true))
			const state = getState()
			const role = userSelectors.role(state)
			const isEmployer = userSelectors.isEmployer(state)

			const res = await api.econtracts.getXml(role, payload)
			dispatch(eContractsActions.setTerminateXML(isEmployer ? res : res.xmlData));

		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			dispatch(eContractsActions.setTerminationLoading(false))
		}
	}
}

export const saveSign = (signedXml) => {
	return async (dispatch, getState) => {
		try {
			dispatch(eContractsActions.setTerminationLoading(true))
			const state = getState()
			const isEmployer = userSelectors.isEmployer(state)
			const role = userSelectors.role(state)

			const payload = isEmployer
				? signedXml
				: {
						xmlData: signedXml,
				  }
			const response = await api.econtracts.saveSign(role, payload)
			dispatch(eContractsActions.setTerminationSuccess(true))
		} catch (error) {
			message.error('Ошибка сохранения ЭТД: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(eContractsActions.setTerminationSuccess(false))
		} finally {
			dispatch(eContractsActions.setTerminationLoading(false))
		}
	}
}
export const getTerms = (filter = {}, params) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setContractLoading(true))
			const state = getState()
			const role = userSelectors.role(state)
			const response = await api.econtracts.getPage(role, filter, params)
			dispatch(eContractsActions.setTerms(response))
			dispatch(actions.setContractSuccess(true))
		} catch (error) {
			message.error('Ошибка получения ЭТД: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}
