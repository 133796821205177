import { useSelector } from 'react-redux'
import { laborRecordsSelectors } from '@/entity'
import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './Edit.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import { EditAdjustment } from './EditAdjustment'
import { Loading } from '@/ui/atoms/loading'
import React from 'react'

export const Edit = ({ requestType, onClose }) => {
	const isLoading = useSelector(laborRecordsSelectors.isRequestLoading)
	const isSuccess = useSelector(laborRecordsSelectors.isRequestSuccess)
	const isLoadingAdjustment = useSelector(laborRecordsSelectors.isAdjustmentLoading)
	return (
		<>
			<Modal windowClassName={styles.modal} onClick={onClose}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					{isLoading && <Loading isLoading={isLoading} isSuccess={isSuccess}></Loading>}
					{!isLoading && isSuccess ? (
						<EditAdjustment requestType={requestType} onClose={onClose}></EditAdjustment>
					) : null}
				</div>
			</Modal>
			{isLoadingAdjustment && <LoadingBlocker />}
		</>
	)
}
