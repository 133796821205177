import React from 'react'
import { DataGridTable } from '@/ui'
import styles from './GuaranteeData.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const GuaranteeData = ({ extraGuarantee }) => {
	const lang = getCurrentLanguage()

	const extraGuaranteeData = extraGuarantee.map((item) => ({
		title: lang === 'rus' ? item.nameRu : item.nameKz,
		data: lang === 'rus' ? item.valueRu : item.valueKz,
	}))

	return (
		<div>
			<DataGridTable
				hideTitle
				hideSubTitle
				titleClass={styles.tableTitleCell}
				information={extraGuaranteeData}
			></DataGridTable>
		</div>
	)
}