import { request } from '@/shared/api/request'

export const getEtd = (payload) => {
	return request({ url: '/contract/econtract/get', method: 'GET', params: payload })
}

export const getEtdEdit = (payload) => {
	return request({ url: '/contract/econtract/edithtml', method: 'GET', params: payload })
}

export const sendtoEmployee = (payload) => {
	return request({ url: '/contract/econtract/send', method: 'POST', params: payload })
}

export const declineEmployee = (payload) => {
	return request({ url: '/contract/econtract/employee/reject', method: 'POST', params: payload })
}

export const sendForImprovementEmployee = (payload) => {
	return request({ url: '/contract/econtract/employee/sendForImprovement', method: 'POST', params: payload })
}

export const declineEmployer = (payload) => {
	return request({ url: '/contract/econtract/employer/reject', method: 'POST', params: payload })
}

export const revertEmployer = (payload) => {
	return request({ url: '/contract/econtract/employer/revert', method: 'POST', params: payload })
}

export const deleteEtd = (payload) => {
	return request({ url: '/contract/econtract/delete', method: 'DELETE', params: payload })
}

export const downloadPDF = (externalId) => {
	return request({ url: `/contract/econtract/pdf/?externalId=${externalId}`, method: 'GET', responseType: 'blob' })
}
export const getOptions = () => {
	return request({ url: '/contract/econtract/dict/options', method: 'GET' })
}
export const saveProject = (payload, params) => {
	return request({ url: 'contract/econtract/save', method: 'POST', data: payload, params: params })
}
