import { ConfirmModal, Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './Table/Table'
import { Search } from '@pages/PersonalAffairs/components/Search/Search'
import styles from './EmployeeRequestsTab.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import PersonalAffairCardRequest from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/RequestCard'
import { api, useDigitalSign } from '@/shared'
import { message } from 'antd'
import { LoadingBlocker } from '@/components/Loader'
import { Buffer } from 'buffer'
import { margin } from '@mui/system'

const EmployeeRequestsTab = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { totalElements } = useSelector(affairsSelectors.employeeRequests)
	const [selectedAction, setSelectedAction] = useState(null)
	const [selectedRequest, setSelectedRequest] = useState(null)
	const { successPayload, onClearPayload, isLoading, failedPayload, onSign } = useDigitalSign()

	const [loading, setLoading] = useState(false)

	const getXml = async (isApprove) => {
		if (!selectedRequest) return
		const { bin, orgNameRu, id } = selectedRequest
		try {
			onSign(await api.personalAffairs.getXml(bin, orgNameRu, id, isApprove))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		}
	}

	const sendXml = async () => {
		if (!selectedRequest) return
		const { bin, orgNameRu, id } = selectedRequest
		setLoading(true)
		try {
			await api.personalAffairs.sendXml({
				bin,
				orgName: orgNameRu,
				reqId: id,
				xml: Buffer.from(successPayload).toString('base64'),
			})
			await getRequests()
			setSelectedAction(null)
			message.success('Операция была успешна выполнена!')
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			setLoading(false)
		}
	}

	const sendReject = async () => {
		if (!selectedRequest) return
		const { bin, orgNameRu, id } = selectedRequest
		try {
			await api.personalAffairs.sendReject({ bin, orgName: orgNameRu, reqId: id })
			await getRequests()
			setSelectedAction(null)
			message.success('Операция была успешна выполнена!')
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		}
	}

	const getRequests = (srcText = '', page = 0, size = 10) =>
		dispatch(affairsThunks.getEmployeeRequests(srcText, page, size))

	useEffect(() => getRequests(), [])
	useEffect(() => {
		successPayload && sendXml()
		return () => onClearPayload()
	}, [successPayload])
	useEffect(() => failedPayload && onClearPayload(), [failedPayload])

	return (
		<div className={styles.wrapper}>
			{(isLoading || loading) && <LoadingBlocker localStyles={{ zIndex: '1003' }} />}
			{selectedAction === 'reject' && (
				<ConfirmModal
					onProcess={sendReject}
					onCancel={() => setSelectedAction(null)}
					cancelButtonTitle={'no_cancel'}
					processButtonTitle={'Все равно отклонить'}
					hideBackButton
					priority={2}
				>
					<div className={styles.title}>
						<Title>
							В случае отклонения согласия на сбор и обработку персональных данных, получатель согласия не сможет
							осуществлять запросы по Вашему ИИН
						</Title>
					</div>
				</ConfirmModal>
			)}
			{selectedAction === 'sign' && (
				<ConfirmModal
					onProcess={() => getXml(true)}
					onCancel={() => setSelectedAction(null)}
					cancelButtonTitle={'no_cancel'}
					processButtonTitle={t('sign_button')}
					hideBackButton
					priority={2}
				>
					<div className={styles.title}>
						<Title>Вы даете единоразовое согласие Работодателю на сбор и обработку персональных данных</Title>
					</div>
				</ConfirmModal>
			)}
			{selectedAction === 'view' && selectedRequest?.id && (
				<PersonalAffairCardRequest
					data={selectedRequest}
					onClose={() => setSelectedAction(null)}
					onSign={() => getXml(true)}
					onReject={sendReject}
				/>
			)}
			<Search style={{ marginTop: '25px' }} count={totalElements} isRequests fetchData={getRequests} />
			<Table
				setSelectedRequest={setSelectedRequest}
				setSelectedAction={setSelectedAction}
				getData={getRequests}
				selectedRequest={selectedRequest}
			/>
		</div>
	)
}
export default EmployeeRequestsTab
