import { headCells } from '@features/accidents/table/accidentsTable.headings'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './accidentsTable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { accidentSelectors, accidentThunks } from '@/entity'
import { AccidentState } from '@features/accidents/table/accidentState/AccidentState'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import { getDeclension } from '@/utils'
import { ViewAccident } from '@features/accidents/viewAccident'

export const AccidentsTable = ({ accidents }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const accident = useSelector(accidentSelectors.accident)
	const [actions, setActions] = useState({
		view: false,
	})

	const onClose = useCallback(() => {
		setActions({ view: false })
	}, [])

	const { isLoading, error, isSuccess } = useSelector((state) => ({
		isLoading: accidentSelectors.isLoading(state),
		error: accidentSelectors.error(state),
		isSuccess: accidentSelectors.isSuccess(state),
	}))

	const getRequest = useCallback((externalId) => dispatch(accidentThunks.getAccident(externalId)), [dispatch])

	const onToggleAction = (action, value, row) => {
		getRequest(row.externalId)
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}

	const getTableRow = (row, index) => {
		const injuredFullName = row.isMoreOne
			? t('accidentsPage.groupAccident')
			: `${row.injureds[0].surname} ${row.injureds[0].firstname} ${row.injureds[0].secondname}`

		return (
			<tr key={index + '-table-row'} onClick={() => fastView(row)}>
				<td className={styles.pixelGamingDate}>{new Date(row.accidentDateTime).toLocaleDateString('ru-RU') || '-'}</td>
				<td className={styles.pixelGamingFullName}>{injuredFullName}</td>
				<td className={styles.pixelGamingSeverity}>
					{row.severity ? row.severity[lang === 'rus' ? 'nameRu' : 'nameKz'] : '-'}
				</td>
				<td className={styles.pixelGamingState}>{<AccidentState accidentState={row.status} lang={lang} /> || '-'}</td>
				<td className={styles.pixelGamingPeriod}>
					{!!row.statusRemainingDays ? row.statusRemainingDays + ' ' + t(getDeclension(row.statusRemainingDays)) : '-'}
				</td>
			</tr>
		)
	}

	const tableBody = useMemo(
		() => accidents.content.map((row, index) => getTableRow(row, index)),
		[accidents, getTableRow]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{(isLoading || (!error && !isLoading && !isSuccess)) && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('labor_records.loading')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && accidents.totalElements === 0 && (
						<div className={styles.tableLoading}>{t('accidentsPage.noAccidents')}</div>
					)}
					{error && !isLoading && !isSuccess && <div className={styles.tableLoading}>{t('has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, accidents, error, t]
	)

	return (
		<div className={styles.tableRow}>
			<table className={styles.table}>
				<thead>
					<tr>
						{headCells.map((head, index) => (
							<th key={index + 'thead-th'}>{t(head.label)}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: 'relative' }}>
					{isLoading || error || accidents.totalElements === 0 ? tableLoading : tableBody}
				</tbody>
			</table>
			{actions.view && accident ? <ViewAccident onClose={onClose} accident={accident}></ViewAccident> : null}
		</div>
	)
}
