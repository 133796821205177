import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Logo } from '@/ui'
import { userSelectors } from '@/entity/user'
import { Logout } from '@features/auth'
import styles from './Sidebar.module.scss'
import { formsActions } from '@/entity/forms'
import { companySelectors } from '@/entity/company'
import { authSelectors } from '@app/store/auth'
import { Loader } from '@/components/Loader'
import { employerProfileSelectors } from '@features/employerProfile/model'
import React from 'react'
import { CollapsibleMenu } from '@/widgets/sidebar/CollapsibleMenu'
import {
	EmploymentContractIcon,
	LaborProtectionIcon,
	LaborRecordsIcon,
	LogoutIcon,
	NotificationsIcon,
	OnlineConsultantIcon,
	PersonalAffairsIcon,
	PersonalRecordsIcon,
	GradingIcon,
	ProfileIcon,
	ProfUnions,
	StaffingIcon, ReviewsIcon,
} from '@/widgets/sidebar/Sidebar.icons'

export const Sidebar = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const activeRole = useSelector(userSelectors.activeRole)
	const companies = useSelector(companySelectors.companies)
	const { staff } = useSelector(employerProfileSelectors.activeCompany)
	const user = useSelector(userSelectors.user)
	const isLoading = useSelector(authSelectors.isLoading)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { flEmployer } = activeCompany
	const verifyPerson = user?.iin
	const isEmployee = activeRole === 'ROLE_EMPLOYEE'
	const isEmployer = activeRole === 'ROLE_EMPLOYER'

	const clearForm = () => {
		dispatch(formsActions.themesLoading([]))
		dispatch(formsActions.questionsLoading([]))
		dispatch(formsActions.selfTestResultResponse([]))
		dispatch(formsActions.answerSelfTestLoading([]))
	}

	return (
		<div className={styles.sidebar}>
			<NavLink to="/">
				<span className={styles.logo}>
					<Logo />
				</span>
			</NavLink>

			{isLoading && (
				<div className={styles.loading}>
					<Loader />
				</div>
			)}

			{!user && !isLoading && (
				<nav className={styles.navigation}>
					<NavLink
						onClick={clearForm}
						to={'/forms'}
						className={`${styles.item}`}
						activeClassName={styles.navLinkActive}
					>
						<OnlineConsultantIcon />
						<p>{t('online_consultant')}</p>
					</NavLink>
				</nav>
			)}
			{user && !isLoading && (
				<nav className={styles.navigation}>
					<NavLink
						to={isEmployee ? '/worker' : '/employer'}
						className={`${styles.item}`}
						activeClassName={styles.navLinkActive}
					>
						<div className={`${styles.item__title}`}>
							<ProfileIcon />
							<p>{t('profile')}</p>
						</div>
					</NavLink>
					<NavLink
						to="/contracts"
						className={`${styles.item} ${
							(!companies.length && isEmployer) || (isEmployee && !verifyPerson) ? styles.item__disabled : ''
						}`}
						activeClassName={styles.navLinkActive}
					>
						<EmploymentContractIcon />
						<p>{t('employment_contract')}</p>
					</NavLink>
					{isEmployer && staff ? (
						<NavLink
							to="/staffing"
							className={`${styles.item} ${
								(!companies.length && isEmployer) || flEmployer ? styles.item__disabled : ''
							}`}
							activeClassName={styles.navLinkActive}
						>
							<StaffingIcon />
							<p>{t('staffing')}</p>
						</NavLink>
					) : null}
					{isEmployee ? (
						<>
							<NavLink
								to="/labor-records"
								className={`${styles.item} ${!user.iin ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<LaborRecordsIcon />
								<p>{t('labor_records.title')}</p>
							</NavLink>
							<NavLink
								to="/employees-personal-files"
								className={`${styles.item} ${!verifyPerson ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<PersonalRecordsIcon />
								<p>{t('personal_records')}</p>
							</NavLink>
						</>
					) : (
						<NavLink
							to="/personal-affairs"
							className={`${styles.item} ${
								(!companies.length && isEmployer) || (isEmployee && !verifyPerson) || flEmployer
									? styles.item__disabled
									: ''
							}`}
							activeClassName={styles.navLinkActive}
						>
							<PersonalAffairsIcon />
							<p>{t('personal_affairs.worker')}</p>
						</NavLink>
					)}

					{/*todo раскомментить 31.10.2024 branch: reviews*/}
					{/*<NavLink*/}
					{/*	to="/reviews"*/}
					{/*	className={`${styles.item} ${!verifyPerson ? styles.item__disabled : ''}`}*/}
					{/*	activeClassName={styles.navLinkActive}*/}
					{/*>*/}
					{/*	<ReviewsIcon />*/}
					{/*	<p>{t('reviews.title')}</p>*/}
					{/*</NavLink>*/}

					{!flEmployer ? (
						<CollapsibleMenu className={styles.item} title="profUnions.name" icon={<ProfUnions />}>
							<NavLink
								to="/prof"
								className={`${styles.item} ${
									(isEmployee && !verifyPerson) || (!companies.length && isEmployer) ? styles.item__disabled : ''
								}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('profUnions.generalInformation')}</p>
							</NavLink>
							<NavLink
								to="/trade-members"
								className={`${styles.item} ${
									(!companies.length && isEmployer) || (isEmployee && !verifyPerson) ? styles.item__disabled : ''
								}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('profUnions.trade_members')}</p>
							</NavLink>
							{isEmployer ? (
								<>
									<NavLink
										to="/trade-unions"
										className={`${styles.item} ${!companies.length && isEmployer ? styles.item__disabled : ''}`}
										activeClassName={styles.navLinkActive}
									>
										<p>{t('profUnions.tradeUnions')}</p>
									</NavLink>
								</>
							) : (
								<NavLink
									to="/my-unions"
									className={`${styles.item} ${
										(isEmployee && !verifyPerson) || (!companies.length && isEmployer) ? styles.item__disabled : ''
									}`}
									activeClassName={styles.navLinkActive}
								>
									<p>{t('profUnions.employee_trade_unions')}</p>
								</NavLink>
							)}
							<NavLink
								to="/col-contracts"
								className={`${styles.item} ${
									(isEmployee && !verifyPerson) || (!companies.length && isEmployer) ? styles.item__disabled : ''
								}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('col_contract.contracts')}</p>
							</NavLink>
							{isEmployer ? <NavLink to="/industry-agreement" className={styles.item} activeClassName={styles.navLinkActive}>
								<p>{t('industryAgreement.title')}</p>
							</NavLink> : null}
						</CollapsibleMenu>
					) : null}
					{isEmployer ? (
						<CollapsibleMenu className={styles.item} title="laborProtection" icon={<LaborProtectionIcon />}>
							<NavLink
								to="/company-passport"
								className={`${styles.item} ${!companies.length ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('ckp_enterprise_passport')}</p>
							</NavLink>
							<NavLink
								to="/employer-passport"
								className={`${styles.item} ${!companies.length ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('ckp_workers_portrait')}</p>
							</NavLink>
							<NavLink
								to="/accidents"
								className={`${styles.item} ${!companies.length ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('accidents')}</p>
							</NavLink>
							<NavLink
								to="/checks"
								className={`${styles.item} ${!companies.length ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('ckp_checks')}</p>
							</NavLink>
							<NavLink
								to="/violations"
								className={`${styles.item} ${!companies.length ? styles.item__disabled : ''}`}
								activeClassName={styles.navLinkActive}
							>
								<p>{t('ckp_violations_monitoring')}</p>
							</NavLink>
						</CollapsibleMenu>
					) : null}
					{/*	//TODO временно комментируется 12.09.2024
						<NavLink to="/grading" className={styles.item} activeClassName={styles.navLinkActive}>
						<GradingIcon />
						<p>{t('grading')}</p>
					</NavLink>*/}
					<NavLink
						to="/notifications"
						className={`${styles.item} ${
							(!companies.length && isEmployer) || (isEmployee && !verifyPerson) ? styles.item__disabled : ''
						}`}
						activeClassName={styles.navLinkActive}
					>
						<NotificationsIcon />
						<p>{t('notifications')}</p>
					</NavLink>

					{isEmployer ? (
						<NavLink
							onClick={clearForm}
							to={'/forms'}
							className={`${styles.item}`}
							activeClassName={styles.navLinkActive}
						>
							<OnlineConsultantIcon />
							<p>{t('online_consultant')}</p>
						</NavLink>
					) : null}
					<Logout>
						<span className={styles.spanLogout}>
							<LogoutIcon />
							<p>{t('exit')}</p>
						</span>
					</Logout>
				</nav>
			)}
		</div>
	)
}
