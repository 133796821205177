import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import doc from '@/assets/home/doc.svg'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
import styles from './ViewConfirmDocument.module.scss'
import { downloadFileUni, downloadFileUniAdditional } from '@/shared/api/rest/contracts'

export const ViewConfirmDocument = ({ data, additionalContract = false, local = false }) => {
	const { t } = useTranslation()

	const downloadFile = async (row) => {
		const response =
			row?.local || local
				? additionalContract
					? await downloadFileUniAdditional(row.id)
					: await downloadFileByUid(row.id)
				: additionalContract
				? await downloadFileUniAdditional(row.id)
				: await downloadFileUni(row.id)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = row.fileName || 'file'
		document.body.appendChild(a)
		a.click()
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} className={styles.file}>
				<td>
					<img src={doc} />
					<label>{row.fileName}</label>
					<label>
						{row.fileSize > 1e6
							? '(' + (row.fileSize / 1e6).toFixed(1) + ' МБ)'
							: '(' + (row.fileSize / 1000).toFixed() + ' КБ)'}
					</label>
					<span onClick={() => downloadFile(row)}>{t('labor_records.download')}</span>
				</td>
			</tr>
		),
		[t]
	)

	const filesSize = useMemo(() => (data.reduce((acc, file) => file.size + acc, 0) / 1e6).toFixed(1), [data])

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	return (
		<div>
			<table>
				<tbody>{tableBody}</tbody>
			</table>
			{filesSize > 0.0 ? (
				<div className={styles.size}>{t('labor_records.full_size') + ' (' + filesSize + ' МБ)'}</div>
			) : null}
		</div>
	)
}
