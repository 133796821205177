import React, { useMemo, useRef, useCallback, useState } from 'react'
import { useToggle, useClickOutside } from '@/shared'
import { Options, Input, Button } from '@/ui/atoms'
import { Arrow, Cross } from '@app/icons'
import { getCurrentLanguage } from '@/i18next'
import styles from './MultipleSelect.module.scss'

const ValueBadge = ({ onDelete, text, code }) => {
	const handleRemoveButtonClick = useCallback(() => {
		onDelete(code)
	}, [code, onDelete])

	return (
		<div className={styles.badge}>
			<div>{text}</div>
			<Button onClick={handleRemoveButtonClick} type="bare">
				<Cross className={styles.closeIcon} />
			</Button>
		</div>
	)
}

export const MultipleSelect = ({ options = [], placeholder, value = [], onChange, onBlur, disabled = false }) => {
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const [searchValue, setSearchValue] = useState('')
	const selectRef = useRef(null)
	const currentLang = getCurrentLanguage()
	const onSelect = useCallback(
		(code) => {
			const val = value ? value : []
			if (val.includes(code)) {
				onChange(val.filter((item) => item !== code))
			} else {
				onChange([...val, code])
			}
		},
		[onChange, value]
	)

	const filteredOptions = useMemo(() => {
		const optionLang = currentLang === 'rus' ? 'nameRu' : 'nameKz'
		return options.filter((option) =>
			(option[optionLang] || option[currentLang]).toLowerCase().includes(searchValue.toLowerCase())
		)
	}, [currentLang, options, searchValue])

	const onClickOutside = useCallback(
		(event) => {
			event.target.blur()
			toggleOpenedOptions()
		},
		[toggleOpenedOptions]
	)

	const values = useMemo(() => {
		const optionLang = currentLang === 'rus' ? 'nameRu' : 'nameKz'
		return options
			?.filter(({ code }) => value.includes(code))
			?.map((option) => ({ text: option[currentLang] || option[optionLang], code: option.code }))
	}, [value, options, currentLang])

	useClickOutside(selectRef, isOpenedOptions, onClickOutside)

	return (
		<div className={styles.select} ref={selectRef}>
			<div aria-disabled={disabled} className={styles.valuesFrame} onClick={toggleOpenedOptions}>
				<div className={styles.values}>
					{value?.length === 0 && placeholder && <div className={styles.placeholder}>{placeholder}</div>}
					{values?.map(({ code, text }) => (
						<ValueBadge onDelete={onSelect} code={code} text={text} key={code} />
					))}
				</div>
				<Button type="bare">
					<Arrow
						styles={{
							fill: '#AAB8D1',
							transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`,
							width: '16px',
							height: '16px',
						}}
					/>
				</Button>
			</div>
			{isOpenedOptions && !disabled && (
				<>
					<Input onChange={setSearchValue} value={searchValue} placeholder="Поиск" />
					<Options
						options={filteredOptions}
						showItems={5}
						onSelect={onSelect}
						actives={value}
						multiSelect
						multiple
						onBlur={onBlur}
					/>
				</>
			)}
		</div>
	)
}
