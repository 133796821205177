import { useTranslation } from 'react-i18next'
import { Field, Button, SelectSearch, Title, DataGridTable } from '@/ui'
import { Field as FormikField, Form, Formik } from 'formik'
import styles from './RequestTerminateForm.module.scss'
import { Add, Info } from '@app/icons'
import React, { useCallback, useMemo, useState } from 'react'
import { AdditonalReasonSelect } from '@/components/AdditionalReasonSelect'
import { requestTerminateSchema } from './RequestTerminate.schema'
import { ConfirmDocument } from '@features/laborRecords/components/document/ConfirmDocument'
import { ViewConfirmDocument } from '@features/laborRecords/components/document/ViewConfirmDocument'
import { Tooltip } from 'antd'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { AdditionalInfoField } from './fields'
export const RequestTerminateForm = ({
	shortContractData,
	onSubmit,
	terminationReasons = [],
	isView,
	onClose,
	onToggleContractAction,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const activeRole = useSelector(userSelectors.activeRole)
	const optionLang = useMemo(() => (language === 'rus' ? 'nameRu' : 'nameKz'), [language])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const { contractId, terminationContract, phone, email, comment, note, docList, contractDate, requestStatus } =
		shortContractData
	const [additionalReasons, setAdditionalReasons] = useState([])
	const [documentList, setDocList] = useState([])
	const getDocument = (docList) => {
		setDocList(docList)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}
	const lastTerminationReasons = useMemo(() => terminationReasons.filter(({ last }) => !!last), [terminationReasons])

	const handleAddReasonClick = useCallback(() => {
		setAdditionalReasons((value) => [...value, null])
	}, [])

	const handleRemoveReasonClick = useCallback((index) => {
		setAdditionalReasons((value) => {
			const newArr = [...value]
			newArr.splice(index, 1)
			return newArr
		})
	}, [])

	const dateArr = shortContractData?.contractDate?.split('.')

	const handleDataProcess = (data) => {
		const toSubmit = {
			comment: data.comment,
			contractId: contractId,
			docList: documentList,
			email: data.email,
			phone: data.phone,
			terminationContract: {
				terminationDate: data.terminationDate,
				terminationReasonCodes: [data.terminationReason, ...additionalReasons],
			},
		}
		onSubmit(toSubmit)
	}

	const contractTerminationInfo = useMemo(() => {
		const data = [{ title: t('terminate_date'), data: terminationContract?.terminationDate }]
		data.splice(1, 0, {
			title: t('contract_termination_reason'),
			data: terminationContract?.terminationReasons.map((item) => item[optionLang]).join(',') || '-',
		})
		return data
	}, [optionLang, t, terminationContract])

	const hint = useMemo(() => t('labor_records.specify_reason'), [t])

	const actionConfirm = () => {
		onToggleContractAction('confirm', true)
	}
	const actionReject = () => {
		onToggleContractAction('reject', true)
	}
	return (
		<div className={styles.wrapper}>
			<Title>{t('labor_records.contract_terminate_info')}</Title>
			<Formik
				initialValues={{
					contractDate: contractDate,
					terminationDate: isView ? terminationContract?.terminationDate : null,
					terminationReason: isView ? terminationContract?.terminationReasons : null,
					phone: isView ? phone : '',
					email: isView ? email : '',
					comment: isView ? comment : '',
					note: isView ? note : '',
				}}
				onSubmit={handleDataProcess}
				validationSchema={requestTerminateSchema}
				enableReinitialize
			>
				{({ isValid, values }) => {
					return (
						<Form>
							<div className={styles.col}>
								<div className={styles.row}>
									{isView ? (
										<DataGridTable
											hideSubTitle
											titleClass={styles.tableTitleCell}
											information={contractTerminationInfo}
										/>
									) : (
										<>
											<div className={styles.datePicker}>
												<FormikField name={formFieldNames.terminationDate}>
													{({ field, meta: { touched, error } }) => (
														<Field
															fieldType="datePicker"
															label={t('terminate_date')}
															isRequired
															minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
															showIcon
															maxDate={new Date()}
															error={touched && error}
															disabled={isView}
															{...field}
														/>
													)}
												</FormikField>
											</div>
											<div className="flex-2">
												<Tooltip
													placement="top"
													color="white"
													overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
													title={hint}
												>
													<label className={styles.label}>
														{t('contract_termination_reason')}* <Info className={styles.hintIcon} />
													</label>
												</Tooltip>
												<div className={styles.selectCol}>
													<div className="flex">
														<FormikField name={formFieldNames.terminationReason}>
															{({ field, meta: { touched, error } }) => (
																<SelectSearch
																	options={lastTerminationReasons}
																	fieldType="select"
																	isRequired
																	{...field}
																	className="flex-1"
																	error={touched && error ? t(error) : ''}
																/>
															)}
														</FormikField>
														<Button transparent onClick={handleAddReasonClick} buttonClassName={styles.button}>
															<Add className={styles.iconGreen} />
															{t('add')}
														</Button>
													</div>
													{additionalReasons.map((item, index) => (
														<div key={`additonal-resaons-${index}`} className="flex">
															<AdditonalReasonSelect
																index={index}
																setAdditionalReasons={setAdditionalReasons}
																value={item}
																terminationReasons={terminationReasons}
																removeReason={handleRemoveReasonClick}
															/>
														</div>
													))}
												</div>
											</div>
										</>
									)}
								</div>
							</div>
							<div className={styles.wrapper}>
								<Title>{t('labor_records.confirming_document')}</Title>
							</div>
							{isView ? (
								<ViewConfirmDocument data={docList}></ViewConfirmDocument>
							) : (
								<ConfirmDocument getDocument={getDocument} getErrorSize={getErrorSize}></ConfirmDocument>
							)}
							<div className={styles.wrapper}>
								<Title>{t('labor_records.additional_information')}</Title>
								<AdditionalInfoField isView={isView}></AdditionalInfoField>
								{isView ? (
									<>
										{requestStatus?.code === '1' ? null : (
											<div className={styles.answer}>
												<Title>{t('labor_records.note')}</Title>
												<FormikField name={formFieldNames.note}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType={'textarea'}
															isRequired
															label={t('labor_records.comment')}
															error={touched && error ? t(error) : ''}
															{...field}
															disabled
															labelClassName={styles.field}
														/>
													)}
												</FormikField>
											</div>
										)}
										<div className={styles.cancel__view}>
											{activeRole !== 'ROLE_EMPLOYEE' ? (
												<>
													<Button onClick={actionConfirm} disabled={!(requestStatus?.code === '1')}>
														{t('labor_records.confirm')}
													</Button>
													<Button onClick={actionReject} disabled={!(requestStatus?.code === '1')}>
														{t('labor_records.reject')}
													</Button>
												</>
											) : null}
											<Button onClick={onClose}>{t('close')}</Button>
										</div>
									</>
								) : (
									<div className={styles.actions}>
										<div className={styles.cancel__button} onClick={onClose}>
											{t('labor_records.cancel')}
										</div>
										<Button
											disabled={
												disableErrorSize ||
												!isValid ||
												documentList.length < 1 ||
												values.comment === '' ||
												values.phone === '' ||
												values.email === ''
											}
											type="submit"
										>
											{t('sign_and_send')}
										</Button>
									</div>
								)}
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}
