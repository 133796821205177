import { api } from '@/shared'
import { actions } from './actions'
import { getDictionaries } from '@/shared/api/rest/dict/getDictionaries'

export const getNotifications = (page, pageSize, unread, typeCode, searchText, employer, tags) => {
	return async (dispatch) => {
		try {
			let queryParams = `?page=${page ?? ''}&size=${pageSize || ''}&unread=${unread}&typeCode=${
				typeCode || 'NOTIF'
			}&employer=${employer}`
			const { content, pageable, totalPages, totalElements, last, number, sort, size, first, numberOfElements, empty } =
				await api.notification.getNotifications(queryParams, tags)

			dispatch(
				actions.setNotifications({
					content,
					pageable,
					totalPages,
					totalElements,
					last,
					number,
					sort,
					size,
					first,
					numberOfElements,
					empty,
				})
			)
			dispatch(actions.setError(''))
		} catch (error) {
			const message = error?.response?.data?.message
			dispatch(actions.setError(message || ''))
		}
	}
}
export const getTags = () => {
	return async (dispatch) => {
		try {
			const data = await getDictionaries('tags', {}, null, `/notification/get/tags`)
			dispatch(actions.setTags(data))
		} catch (error) {
			const message = error?.response?.data?.message
			dispatch(actions.setError(message || ''))
		}
	}
}
export const setRead = (notifyId, employer) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setNotificationRead(notifyId))
			await api.notification.setRead(notifyId, employer)
			dispatch(actions.setError(''))
		} catch (error) {
			dispatch(actions.setNotificationUnread(notifyId))
			const message = error?.response?.data?.message
			dispatch(actions.setError(message || ''))
		}
	}
}
export const setReadTermination = (termExternalId, employer) => {
	return async (dispatch) => {
		if (employer) await api.notification.setReadTermination(termExternalId)
	}
}
export const getNotificationsBadge = (employer) => {
	return async (dispatch) => {
		try {
			let queryParams = `?page=0&size=4&unread=true&employer=${employer}&typeCode=NOTIF`
			const { content, totalElements } = await api.notification.getNotifications(queryParams)
			dispatch(actions.setNotificationsBadge({ content, totalElements }))
			dispatch(actions.setError(''))
		} catch (error) {
			const message = error?.response?.data?.message
			dispatch(actions.setError(message || ''))
		}
	}
}

export const getAll = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const result = await api.notification.getAll()
			dispatch(actions.setAll(result))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
