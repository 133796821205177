import { ProfileTemplate, Tabs } from '@/widgets'
import { ChangeProfile, UserProfile, EditUserProfile } from '@/features'
import { RedirectUnAuthUser } from '@/processes'
import styles from './style.module.scss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsThunks, userActions, userSelectors } from '@/entity'
import { getCurrentLanguage } from '@/i18next'
import PreHeader from '@/components/home/components/PreHeader'
import { employerProfileSelectors } from '@features/employerProfile/model'

//Data array must consist of key of tabs and as value any renderable content
export const WorkerProfilePage = () => {
	const tabControlRef = useRef(null)
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.user)
	const { bin } = useSelector(employerProfileSelectors.activeCompany)
	const [urlAuth, setUrlAuth] = useState()
	const lang = getCurrentLanguage()
	useMemo(
		() =>
			lang === 'rus'
				? setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_WORKER)
				: setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_WORKER),
		[lang]
	)
	const hasProfile = useMemo(() => user?.iin, [user])
	const tabs = hasProfile
		? ['worker_data', 'edit', { label: 'account_settings', url: urlAuth }]
		: ['worker_data', { label: 'account_settings', url: urlAuth }]
	const data = {
		worker_data: (
			<div className={styles.wrapper}>
				<UserProfile changeProfile={<ChangeProfile />} />
			</div>
		),
		edit: (
			<div className={styles.wrapper}>
				<EditUserProfile changeProfile={<ChangeProfile />} tabControlRef={tabControlRef} />
			</div>
		),
		account_settings: <div />,
	}

	useEffect(() => {
		dispatch(userActions.setActiveRole('ROLE_EMPLOYEE'))
	}, [])

	useEffect(() => {
		if (bin) {
			dispatch(profUnionsThunks.getTradeUnion(false))
		}
	}, [bin])

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<div>
					<ProfileTemplate title={'my_profile'}>
						<Tabs tabs={tabs} data={data} ref={tabControlRef} />
					</ProfileTemplate>
				</div>
			</RedirectUnAuthUser>
		</>
	)
}
