import React, { useEffect, useRef, useState } from 'react'
import { ContractForm, formFieldNames } from '@/entity/contracts'
import { ModalTemplate } from '@/ui'
import styles from './style.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import { Documents } from '@/entity/contracts/ui/form/Documents/Documents'
import { EmploymentContractFile } from '@/entity/contracts/ui/form/employmentСontractFile/employmentСontractFile'

export const Form = ({
	initialValues,
	options = {},
	workerData,
	onSubmit,
	onFindWorker,
	onGetHandBookOptions,
	onCancel,
	setIsEsutd,
	isEsutd,
	isElectronic,
	CloseAndUpdateFilter,
	fetchData,
	filters,
	page,
	pageSize,
}) => {
	const formRef = useRef(null)
	const [isAttachProjectFile, setAttachProjectFile] = useState(false)
	const [attachProjectFileData, setAttachProjectFileData] = useState({})
	const [isPdfView, setPdfView] = useState(false)
	const [document, setDoc] = useState([])
	useEffect(() => {
		if (workerData) {
			const form = formRef.current
			if (form) {
				form.setFieldValue(formFieldNames.employeeInitials, workerData)
			}
		}
	}, [workerData])

	const validateOnFindWorker = async (form) => {
		const { iin } = await form.setFieldTouched(formFieldNames.iin)
		if (!iin) {
			const { iin } = form.values
			onFindWorker(iin)
		}
	}

	const attachProjectFile = (values) => {
		setAttachProjectFileData(values)
		setAttachProjectFile(true)
	}

	const attachFileData = (values) => {
		setAttachProjectFileData(values)
		setPdfView(true)
	}

	return (
		<>
			<ModalTemplate onClose={onCancel}>
				<div className={`scroll ${styles.wrapper}`}>
					{options?.positionsByKnz.length > 0 && options?.positions.length > 0 ? (
						<ContractForm
							options={options}
							initialValues={initialValues}
							advanced
							onSubmit={onSubmit}
							validateOnFindWorker={validateOnFindWorker}
							onGetHandBookOptions={onGetHandBookOptions}
							onCancel={onCancel}
							formRef={formRef}
							isTitle
							setIsEsutd={setIsEsutd}
							isEsutd={isEsutd}
							isElectronicButton={isElectronic}
							fetchData={fetchData}
							attachProjectFile={attachProjectFile}
							CloseAndUpdateFilter={CloseAndUpdateFilter}
						/>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
			</ModalTemplate>
			{isAttachProjectFile && (
				<Documents
					onClose={() => setAttachProjectFile(false)}
					document={document}
					setDoc={setDoc}
					nextPage={attachFileData}
					attachProjectFileData={attachProjectFileData}
				/>
			)}
			{isPdfView && (
				<EmploymentContractFile
					onClose={() => setPdfView(false)}
					documentData={document}
					attachProjectFileData={attachProjectFileData}
					CloseAndUpdateFilter={CloseAndUpdateFilter}
					filters={filters}
					page={page}
					pageSize={pageSize}
					saveUrl={'/contract/econtract/save'}
				/>
			)}
		</>
	)
}
