import React, { useEffect } from 'react'
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/Tabs'
import TabsListUnstyled from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanelUnstyled from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import Staffing from '@features/staffing/Staffing'
import CreateStaffing from '@features/staffing/CreateStaffng/CreateStaffing'
import { staffThunks } from '@/entity/staffingProject'
import { useDispatch, useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { staffingThunks } from '@/entity/staffing'
import History from '@features/staffing/history/History'

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	max-width: 90vw;
	margin: 32px 34px 0 0;
	font-family: 'Inter', sans-serif;
	font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
	width: fit-content;
	max-width: 90vw;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	height: 41px;
`

const StaffingPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = React.useState(0)
	const { staff } = useSelector(employerProfileSelectors.activeCompany)

	useEffect(() => {
		dispatch(staffingThunks.getActualStaff())
	}, [])

	useEffect(() => {
		dispatch(staffThunks.getOptions())
	}, [])

	const handleChangeTab = (e, newValue) => setActiveTab(newValue)
	return (
		<RedirectUnAuthUser>
			{staff ? (
				<ProfileTemplate title={'staffing'}>
					<TabsUnstyled defaultValue={0} value={activeTab} onChange={handleChangeTab}>
						<TabsList>
							<Tab value={0}>{t('staffing')}</Tab>
							<Tab value={1}>{t('staffing_table.project')}</Tab>
							<Tab value={2}>{t('staffing_table.history')}</Tab>
							<Tab value={3}>{t('staffing_table.admin')}</Tab>
						</TabsList>
						<TabPanel value={0}>
							<Staffing />
						</TabPanel>
						<TabPanel value={1}>
							<CreateStaffing />
						</TabPanel>
						<TabPanel value={2}>
							{/*<History />*/}
							<InfoDevelopment title={'payment.sectionUnderDevelopment'} />
						</TabPanel>
						<TabPanel value={3}>
							<InfoDevelopment title={'payment.sectionUnderDevelopment'} />
						</TabPanel>
					</TabsUnstyled>
				</ProfileTemplate>
			) : null}
		</RedirectUnAuthUser>
	)
}

export default StaffingPage
