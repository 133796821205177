import { BINValidationScheme } from '@/shared/validations/shemes/iin'
import * as Yup from 'yup'

const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

const FormValidationSchema = Yup.object().shape({
	companyName: Yup.string().required({ key: 'required' }).nullable(),
	companyNameKaz: Yup.string().required({ key: 'required' }).nullable(),
	certificateExpiresAt: Yup.string().required({ key: 'required' }).nullable(),
	dokedCode: Yup.string().required({ key: 'required' }).nullable(),
	downershipTypeCode: Yup.string().required({ key: 'required' }).nullable(),
	lastname: Yup.string().required({ key: 'required' }).nullable(),
	employeeCnt: Yup.string().required({ key: 'required' }).nullable(),
	email: Yup.string().email({ key: 'invalid_email' }).required({ key: 'required' }),
	street: Yup.string().required({ key: 'required' }).nullable(),
	ddistrictCode: Yup.string().required({ key: 'required' }).nullable(),
	dregionCode: Yup.string().required({ key: 'required' }).nullable(),
	dlocalityCode: Yup.string()
		.nullable()
		.when('ddistrictCode', {
			is: (code) => citiesInRegionsCodes.includes(code),
			otherwise: (schema) => schema.required({ key: 'required' }),
		}),
	officePhone: Yup.string().required({ key: 'required' }).nullable(),
})

export const ValidationSchema = FormValidationSchema.concat(BINValidationScheme)
