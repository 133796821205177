import React, { useEffect } from 'react'
import { ContractsTable } from './table/ContractsTable'
import { RegistrationEmployerContract } from '@features/contracts/registrationEmployerContract'
import ContractSearch from '@features/contracts/search/contractsSearch'
import { contractsThunks } from '@/entity/contracts'
import { useDispatch } from 'react-redux'

export const EmploymentContracts = ({ filters, setFilters, fetchData, resetFilter, page, pageSize, setActiveTab }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(contractsThunks.getOptions())
	}, [])

	return (
		<>
			<RegistrationEmployerContract
				fetchData={fetchData}
				setFilters={setFilters}
				filters={filters}
				page={page}
				pageSize={pageSize}
			/>
			<ContractSearch
				filters={filters}
				setFilters={setFilters}
				fetchData={fetchData}
				resetFilter={resetFilter}
				page={page}
				pageSize={pageSize}
			/>
			<ContractsTable
				page={page}
				pageSize={pageSize}
				filters={filters}
				setFilters={setFilters}
				fetchData={fetchData}
				setActiveTab={setActiveTab}
			/>
		</>
	)
}
