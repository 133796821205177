import React, { useCallback } from 'react'
import { UploadImagePlaceholder } from '@app/images/index'
import styles from './ProfilePopup.module.scss'

export const ProfileCard = ({ onSelect, companyName = '', imgSrc = '', id }) => {
	const handleCardClick = useCallback(() => {
		onSelect(id)
	}, [])

	return (
		<div className={styles.selectProfile} onClick={handleCardClick}>
			<img alt="logo" src={imgSrc ? imgSrc : UploadImagePlaceholder} className={styles.logo} />
			<p>{companyName}</p>
		</div>
	)
}
