export const formFieldNames = {
	iin: 'iin',
	employeeInitials: 'fullName',
	contractEmployerNumber: 'contractNumber',
	workplaceCode: 'workplaceCode',
	tariffRate: 'tariffRate',
	salarySupplement: 'salarySupplement',
	cause: 'reasonForAbsence',
	startContractDate: 'beginDate',
	endContractDate: 'endDate',
	freelancer: 'freelancer',
	factSalary: 'factSalary',
	substitutionExternalId: 'substitutionExternalId',
	substitutionIin: 'substitutionIin',
	substitutionName: 'substitutionName',
	workPlaceId: 'workPlaceId',
}
