import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	setError: (payload) => ({
		type: types.SET_ERROR,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	setCompany: (payload) => ({
		type: types.SET_COMPANY,
		payload,
	}),
	setCompanies: (payload) => ({
		type: types.SET_COMPANIES,
		payload,
	}),
	setInitialCompany: (payload) => ({
		type: types.SET_INITIAL_COMPANY,
		payload,
	}),
	setCompaniesFields: (payload) => ({
		type: types.SET_COMPANY_FIELDS,
		payload,
	}),
	setCreationXml: (payload) => ({
		type: types.SET_CREATION_XML,
		payload,
	}),
	resetCreationXml: (payload) => ({
		type: types.RESET_CREATION_XML,
		payload,
	}),
}
