import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { employerProfileSelectors, employerProfileThunks } from '@features/employerProfile/model/index'
import { companySelectors } from '@/entity/company/model/index'
import Button from '@/components/Button/index'
import { UploadImagePlaceholder } from '@app/images/index'
import { ProfileCard } from './ProfileCard'
import styles from './ProfilePopup.module.scss'

export const EmployerProfile = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isSelecting, setIsSelecting] = useState(false)
	const company = useSelector(employerProfileSelectors.activeCompany)
	const companies = useSelector(companySelectors.companies)

	const handleProfileChangeButtonClick = useCallback(() => {
		setIsSelecting(true)
	}, [])

	const handleCompanySelect = useCallback((id) => {
		dispatch(employerProfileThunks.getCompany({ id }))
		setIsSelecting(false)
	}, [])

	return isSelecting ? (
		<div className={`scroll ${styles.scrollArea}`}>
			{companies?.map((item) => (
				<ProfileCard
					key={`company-${item?.id}`}
					onSelect={handleCompanySelect}
					companyName={item?.companyName}
					id={item?.id}
					imgSrc={item?.photoId ? `${process.env.REACT_APP_API_URL}/file/notype/${item.photoId}` : ''}
				/>
			))}
		</div>
	) : (
		<div className={styles.profile}>
			<img
				alt="logo"
				src={
					company?.photoId ? `${process.env.REACT_APP_API_URL}/file/notype/${company.photoId}` : UploadImagePlaceholder
				}
				className={styles.logo}
			/>
			<div>
				<p className={styles.companyName}>{company?.companyName}</p>
				<Button borderless green className={styles.changeCompanyButton} onClick={handleProfileChangeButtonClick}>
					{t('edit_btn')}
				</Button>
			</div>
		</div>
	)
}
