import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from './NotificationError.module.scss'
import { Warning as WarningIcon } from '@app/icons'

export const NotificationError = ({ handleProcess, handleCancel, errorsType }) => {
	const { t } = useTranslation()
	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			processButtonTitle={'modal_info_employer.cancel_text'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<div className={styles.title}>
					<WarningIcon />
				</div>
				{errorsType.availability ? <p>{t('labor_records.info.availability_fields')}</p> : null}
				{errorsType.minEndDate ? <p>{t('validation.labor.date.min_end_date')}</p> : null}
				{errorsType.maxEndDate ? <p>{t('labor_records.info.max_end_date')}</p> : null}
			</div>
		</ConfirmModal>
	)
}
