import React, { useRef } from 'react'
import { CorrectionRequests, LaborActivities, UnsignedRecords } from '@features/laborRecords'
import { ProfileTemplate, Tabs } from '@/widgets'
import { RedirectExternalUnAuthUser } from '@/processes'
import { RedirectToRole } from '@/processes/RedirectToRole'
import PreHeader from '@/components/home/components/PreHeader'

export const LaborRecordsPage = () => {
	const tabControlRef = useRef(null)
	const tabs = ['labor_activities_tab', 'correction_requests_tab']
	//Комментируется оцифровка 15.05.2023
	// const tabs = ['labor_activities_tab', 'correction_requests_tab', 'unsigned_records_tab']

	const data = {
		labor_activities_tab: <LaborActivities tabControlRef={tabControlRef} />,
		correction_requests_tab: <CorrectionRequests />,
		// Комментируется оцифровка 15.05.2023
		// unsigned_records_tab: <UnsignedRecords />,
	}
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectExternalUnAuthUser>
				{/*<RedirectToWorkerProfile>*/}
				<RedirectToRole>
					<ProfileTemplate title={'labor_records.titlePage'}>
						<Tabs tabs={tabs} data={data} ref={tabControlRef} />
					</ProfileTemplate>
				</RedirectToRole>
				{/*</RedirectToWorkerProfile>*/}
			</RedirectExternalUnAuthUser>
		</>
	)
}
