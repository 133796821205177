export const headCells = [
	{
		id: 'eventDate',
		numeric: false,
		label: 'labor_records.date',
	},
	{
		id: 'note',
		numeric: false,
		label: 'industryAgreement.table.note',
	},
	{
		id: 'copy',
		numeric: true,
		label: 'industryAgreement.table.copy',
	},
]
