import classes from './SearchPanel.module.scss'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { Close8, Preference } from '@app/images'
import { Button, Select } from '@/ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const SearchPanel = ({ searchPlaceholder, searchValue, onSearchValueChange, fetchData, resetFilter, children }) => {
	const { t } = useTranslation()
	const [advancedSearch, setAdvancedSearch] = useState(false)

	return (
		<>
			<div className={classes.searchRow}>
				<div className={classes.searchField}>
					<div className={classes.searchField__left}>
						<IconButton type="submit" aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							fullWidth
							placeholder={searchPlaceholder}
							inputProps={{
								'aria-label': 'search',
							}}
							value={searchValue}
							onChange={(e) => onSearchValueChange(e?.target?.value || (typeof e === 'string' && e) || '')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault()
									fetchData()
								}
							}}
						/>
					</div>
					<span className={classes.searchField__button} onClick={() => fetchData()}>
						{t('find')}
					</span>
				</div>

				<div className={classes.advancedSearch__button} onClick={() => setAdvancedSearch(!advancedSearch)}>
					{advancedSearch ? (
						<>
							<img src={Close8} className={classes.advancedSearch__icon} />
							{t('contracts_tab.advancedSearchHide')}
						</>
					) : (
						<>
							<img src={Preference} className={classes.advancedSearch__icon} />
							{t('contracts_tab.advancedSearch')}
						</>
					)}
				</div>
			</div>
			{advancedSearch && (
				<>
					<div className={classes.advancedSearch__row}>

						{children}

						<div className={classes.advancedSearch__searchButtonsRow}>
							<Button type="bare" buttonClassName={classes.advancedSearch__reset_button} onClick={resetFilter}>
								{t('reset_all')}
							</Button>
							<Button type="submit" buttonClassName={classes.advancedSearch__search_button} onClick={() => fetchData()}>
								{t('search')}
							</Button>
						</div>

					</div>


				</>
			)}
		</>
	)
}
