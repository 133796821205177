import React, { useEffect, useState } from 'react'
import styles from './Timer.module.scss'

const Timer = ({ qr, second = 60 }) => {
	const [secondsRemaining, setSecondsRemaining] = useState(second)

	const startTimer = () => {
		setSecondsRemaining(second)
		return setInterval(() => {
			setSecondsRemaining((prevSeconds) => Math.max(prevSeconds - 1, 0))
		}, 1000)
	}

	const clearTimer = (timerId) => clearInterval(timerId)

	useEffect(() => {
		let timerId
		if (qr) {
			timerId = startTimer()
		} else if (timerId) {
			clearTimer(timerId)
		}
		return () => clearTimer(timerId)
	}, [qr])

	return <>{secondsRemaining < second ? <div className={styles.timer}>{secondsRemaining}</div> : null}</>
}
export default Timer
