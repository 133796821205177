import React from 'react'

export const InfoGreen = ({ className = '' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			className={className}
		>
			<path
				d="M12 1.59863C6.20156 1.59863 1.5 6.3002 1.5 12.0986C1.5 17.8971 6.20156 22.5986 12 22.5986C17.7984 22.5986 22.5 17.8971 22.5 12.0986C22.5 6.3002 17.7984 1.59863 12 1.59863ZM12 20.8174C7.18594 20.8174 3.28125 16.9127 3.28125 12.0986C3.28125 7.28457 7.18594 3.37988 12 3.37988C16.8141 3.37988 20.7188 7.28457 20.7188 12.0986C20.7188 16.9127 16.8141 20.8174 12 20.8174Z"
				fill="#17B67C"
			/>
			<path
				d="M10.875 7.97363C10.875 8.272 10.9935 8.55815 11.2045 8.76913C11.4155 8.98011 11.7016 9.09863 12 9.09863C12.2984 9.09863 12.5845 8.98011 12.7955 8.76913C13.0065 8.55815 13.125 8.272 13.125 7.97363C13.125 7.67526 13.0065 7.38912 12.7955 7.17814C12.5845 6.96716 12.2984 6.84863 12 6.84863C11.7016 6.84863 11.4155 6.96716 11.2045 7.17814C10.9935 7.38912 10.875 7.67526 10.875 7.97363ZM12.5625 10.5986H11.4375C11.3344 10.5986 11.25 10.683 11.25 10.7861V17.1611C11.25 17.2643 11.3344 17.3486 11.4375 17.3486H12.5625C12.6656 17.3486 12.75 17.2643 12.75 17.1611V10.7861C12.75 10.683 12.6656 10.5986 12.5625 10.5986Z"
				fill="#17B67C"
			/>
		</svg>
	)
}
