import React, { useCallback, useMemo } from 'react'
import styles from './Table.module.scss'
import { useTranslation } from 'react-i18next'
import { HrPagination } from '@/components/Pagination'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useSelector } from 'react-redux'
import { affairsSelectors } from '@/entity/personalAffairs'
import { headCells } from './utils'
import renderIcon from '@/pages/PersonalAffairs/components/Icons'
import { getCurrentLanguage } from '@/i18next'
import { colors } from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/utils'
import { formatDate } from '@/utils/formatDate'

export const Table = ({ page, setPage, size, setSize, openModal, getData }) => {
	const { t } = useTranslation()
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)
	const { content: data, totalPages, totalElements, numberOfElements } = useSelector(affairsSelectors.employerRequests)
	const lang = getCurrentLanguage()
	const handleChangePage = (e, _page) => {
		setPage(_page - 1)
		if (_page - 1 < 0) {
			return getData('', 0, size)
		}
		getData('', _page - 1 || 0, size)
	}

	const handleChangeRowsPerPage = (_size) => {
		setSize(_size)
		if (page - 1 < 0) {
			return getData('', 0, _size)
		}
		getData('', page - 1 || 0, _size)
	}

	const getTableRow = useCallback(
		(row, index) => {
			const { ord, iin, employeeFio, requestDate, consentDate, orgNameRu, orgNameKz } = row

			return (
				<tr key={index + '-table-row'} onClick={() => openModal(row)}>
					<td>{ord || '-'}</td>
					<td>{lang === 'rus' ? orgNameRu : orgNameKz || '-'}</td>
					<td>{iin || '-'}</td>
					<td>{employeeFio || '-'}</td>
					<td>{requestDate ? formatDate(requestDate) : '-'}</td>
					<td>{consentDate ? formatDate(consentDate) : '-'}</td>
					<td>
						<p className={styles.textStatusWrapper}>
							{renderIcon(row.requestStatus?.code?.toLowerCase())}
							<span style={{ color: colors[row.requestStatus?.code?.toLowerCase()] }} className={styles.textStatus}>
								{row.requestStatus?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
							</span>
						</p>
					</td>
				</tr>
			)
		},
		[t]
	)

	const tableBody = useMemo(() => !!data && data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th
						key={index + 'thead-th'}
						className={styles.thText}
						style={{ maxWidth: head.id === 'consentDate' ? '140px' : '100%' }}
					>
						{t(head.label)}
					</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 30, label: '30' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
					// { size: null, label: 'pagination.show_all' },
				]}
				rowsPerPage={size}
				numberOfElements={numberOfElements}
				totalElements={totalElements}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={page}
			/>
		</>
	)
}
