import React, { useState, useEffect } from 'react'
import { List, Col, Row, Divider, Button } from 'antd'
import './style.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NotificatonDetails } from '@features/notifications/modal'
import { notificationsSelectors, notificationsThunks } from '@/entity/notifications/index'
import { useHistory, useLocation } from 'react-router-dom'
import { actions } from '@/entity/notifications/actions'
import { userSelectors } from '@/entity'
import { companySelectors } from '@/entity/company'

const ReadStateIcon = ({ readDate }) => {
	if (readDate) {
		return <svg width="16" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" />
	}
	return (
		<svg width="16" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<circle cx="8" cy="12" r="8" fill="#17B67C" />
		</svg>
	)
}

export const InfiniteList = ({ content, isVisible, onClose }) => {
	const dispatch = useDispatch()
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const history = useHistory()
	const isSuccess = useSelector(notificationsSelectors.isSuccess)
	const companies = useSelector(companySelectors.companies)
	const user = useSelector(userSelectors.user)
	const activeRole = useSelector(userSelectors.activeRole)
	const location = useLocation()
	const refValue = location.state?.refValue // Получение значения из объекта состояния маршрута
	const [modalShow, setModalShow] = useState(false)
	const [modalData, setModaldata] = useState(null)
	const [notifyId, setNotifyId] = useState(refValue?.externalId)

	useEffect(() => {
		if (isSuccess && refValue) {
			setModaldata(refValue)
			setModalShow(true)
			dispatch(notificationsThunks.setRead(notifyId, activeRole === 'ROLE_EMPLOYER'))
			dispatch(actions.toggleSuccess(false))
			setNotifyId(null)
		}
	}, [])
	const handleClickListItem = (item) => {
		if (window.document.body.clientWidth < 1001) {
			history.push(`/notifications/${item.externalId}`)
			dispatch(notificationsThunks.setRead(item.externalId, activeRole === 'ROLE_EMPLOYER'))
		} else {
			setModaldata(item)
			setModalShow(true)
			dispatch(notificationsThunks.setRead(item.externalId, activeRole === 'ROLE_EMPLOYER')).then(
				dispatch(notificationsThunks.getNotificationsBadge(activeRole === 'ROLE_EMPLOYER'))
			)
		}
	}

	const handleClose = () => {
		setModalShow(false)
	}

	return (
		<div>
			<NotificatonDetails visible={modalShow} onClose={handleClose} data={modalData} />
			<List
				size="small"
				locale={{ emptyText: t('notification_no_notifications') }}
				dataSource={content}
				renderItem={(item) => (
					<List.Item>
						<List.Item.Meta
							avatar={<ReadStateIcon readDate={item.readDate} />}
							title={
								<>
									<Row>
										<Col span={14}>
											<span id="n_text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
												{(language === 'rus' ? item?.notifType.nameRu : item?.notifType.nameKz) || ''}
											</span>
										</Col>
										<Col span={10}>
											<span
												style={{
													fontSize: '14px',
													fontWeight: 'regular',
													color: '#AAB8D1',
													marginLeft: '20px',
													float: 'right',
												}}
											>
												{item?.eventDate.substring(0, 10) || ''} {item?.eventDate.substring(11, 16) || ''}
											</span>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<Button id="open" type="text" size="small" onClick={() => handleClickListItem(item)}>
												{t('notification_open')}
											</Button>
										</Col>
									</Row>
								</>
							}
						/>
					</List.Item>
				)}
			/>
			<>
				<Divider />
				<Button
					id="expand"
					disabled={
						(!companies.length && activeRole === 'ROLE_EMPLOYER') || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
					}
					type="text"
					block
					onClick={() => {
						onClose()
						history.push('/notifications')
					}}
				>
					{t('notification_open_all')}
				</Button>
			</>
		</div>
	)
}
