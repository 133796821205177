import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select } from '@/ui'
import { cookies, useToggle } from '@/shared'
import { AddCompany } from './form'
import styles from './ProfileActions.module.scss'
import { Info, RefreshIcon } from '@app/icons'
import { Tooltip, message } from 'antd'
import { authActions, authSelectors, authThunks } from '@app/store/auth'
import { userActions, userSelectors } from '@/entity/user'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '@/components/Loader'
import { Warningcompany } from './Warningcompany'
import { AddEmployerFl } from './addEmployerFl/AddEmployerFl'

export const ProfileActions = ({ onChangeCompany, options, value, manager }) => {
	const { t } = useTranslation()
	const [isOpenedAddFlEmployer, toggleOpenAddFlEmployer] = useToggle(false)
	const dispatch = useDispatch()
	const isLoading = useSelector(authSelectors.isLoading)
	const errorCompanies = useSelector((state) => state.user?.user?.errorCompanies)
	const error = useSelector(authSelectors.error)
	const currentFlEmployer = useSelector(userSelectors.currentFlEmployer)
	const [isOpenWarningcompany, setIsOpenWarningcompany] = useState(false)

	const onManagersClick = useCallback(() => (window.location = process.env.REACT_APP_REDIRECT_ADD_MANAGER), [])
	const updateCompaniesList = () => {
		const { key: name, value } = cookies.get(process.env.REACT_APP_PASSPORT, true)
		if (!name) return
		dispatch(authThunks.getCurrentUser({ data: { name, value } }, { forceRefresh: true }))
	}

	const onClear = () => {
		dispatch(authActions.setError(null))
		dispatch(userActions.clearErrorCompanies())
	}

	useEffect(() => {
		if (errorCompanies) {
			setIsOpenWarningcompany(true)
		}
	}, [errorCompanies])

	useEffect(() => {
		if (error) {
			message.error(t('error'))
			onClear()
		}
		return () => onClear()
	}, [error])

	const onCloseOpenWarningcompany = () => {
		setIsOpenWarningcompany(false)
		dispatch(userActions.clearErrorCompanies())
	}

	const onClickOpenModalFlEmployer = () => {
		toggleOpenAddFlEmployer(true)
	}

	const onCloseSuccess = () => {
		toggleOpenAddFlEmployer(false)
	}

	return (
		<div>
			<div className={styles.label}>
				<label className={styles.fieldLabel}>{t('choose_company')}</label>
				{!isLoading ? (
					<div className={styles.updateButton} onClick={updateCompaniesList}>
						<RefreshIcon className={styles.refreshIcon} />
						<span>{t('update_companies_list')}</span>
						<Tooltip
							placement="rightTop"
							color="white"
							overlayInnerStyle={{ color: '#5B6880' }}
							title={t('should_update_companies_list')}
						>
							<span>
								<Info className={styles.hintIcon} />
							</span>
						</Tooltip>
					</div>
				) : (
					<Loader />
				)}
			</div>
			<Select required={true} name={'company'} onChange={onChangeCompany} value={value} options={options} />
			<div className={styles.actions}>
				<AddCompany buttonClassName={styles.actionsWrapper} border={false} disabled={manager} transparent={false} />
				<Button disabled={manager} buttonClassName={styles.actionsWrapper} onClick={onManagersClick}>
					{t('managers')}
				</Button>
				{isOpenedAddFlEmployer ? (
					<AddEmployerFl onClose={() => toggleOpenAddFlEmployer(false)} onCloseSuccess={onCloseSuccess}></AddEmployerFl>
				) : null}
				<Button
					disabled={manager || currentFlEmployer}
					buttonClassName={styles.actionsWrapper}
					onClick={onClickOpenModalFlEmployer}
				>
					{t('add_fl_employer')}
				</Button>
			</div>

			<Warningcompany
				errorCompanies={errorCompanies || []}
				open={isOpenWarningcompany}
				handleClose={onCloseOpenWarningcompany}
			/>
		</div>
	)
}
