import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Timer from '@features/contracts/actions/view/components/Timer/Timer'
import styles from './VerificationQR.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { verificationSelector, verificationThunks } from '@/entity/verification'
import { cookies } from '@/shared'
import { authThunks } from '@app/store/auth'
import { Button } from '@/ui'
import { RefreshIcon } from '@app/icons'
export const VerificationQR = () => {
	const dispatch = useDispatch()
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const qr = useSelector(verificationSelector.qr)
	const qr_key = useSelector(verificationSelector.qr_key)
	const status = useSelector(verificationSelector.qr_status)
	const [startTimer, setStartTimer] = useState(true)
	const [statusSign, setStatusSign] = useState('')

	useEffect(() => {
		if (status && status?.code !== 'not_found') {
			const { key: name, value } = cookies.get(process.env.REACT_APP_PASSPORT, true)
			if (!name) return
			dispatch(authThunks.getCurrentUser({ data: { name, value } }, { forceRefresh: true }))
		}
	}, [status])

	useEffect(() => {
		let interval
		if (qr_key) {
			interval = setInterval(() => {
				dispatch(verificationThunks.getStatus(qr_key))
			}, 10000)
		}
		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [qr_key])

	useEffect(() => {
		dispatch(verificationThunks.getVerificationQR())
		const timeout = setTimeout(() => {
			setStartTimer(false)
			setStatusSign(t('time_out'))
		}, 60000)
		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}, [])

	const refreshQR = () => {
		dispatch(verificationThunks.getVerificationQR())
		setStartTimer(true)
	}

	return (
		<div className={styles.width}>
			<div className={styles.text}>
				{t('text_qr_sign_employee1')} <br />
				{t('text_qr_sign_employee2')} <br />
				{t('text_qr_sign_employee3')}
			</div>
			<div>
				<center>
					{startTimer && qr ? (
						<>
							<div>
								<Timer qr={startTimer}></Timer>
							</div>
							<img src={qr} alt={'QR Code'} />
						</>
					) : (
						<>
							<div className={styles.error__qr}>{statusSign}</div>
							<Button type="button" onClick={() => refreshQR()}>
								<RefreshIcon className={styles.refreshIcon} />
								{t('update')}
							</Button>
						</>
					)}
				</center>
			</div>
		</div>
	)
}
