import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Checkbox, Field } from '@/ui'
import { formFieldNames } from '../../../lib/constants/formFieldNames'
import styles from '../ECollectiveForm.module.scss'

export const PayFields = connect(({ formik: { setFieldValue, values } }) => {
	const { t } = useTranslation()
	return (
		<>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.overtimePayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.overtimePay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.overtimePayIs}
							placeholder={t('col_contract.placeholder_amount')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.holidayPayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.holiday_pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.holiday_pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.holidayPay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.holidayPayIs}
							placeholder={t('col_contract.placeholder_amount')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.nightTimePayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.night_time_pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.night_time_pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.nightTimePay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.nightTimePayIs}
							placeholder={t('col_contract.placeholder_amount')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.combinationPositionPayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.combination_position_pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.combination_position_pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.combinationPositionPay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.combinationPositionPayIs}
							placeholder={t('col_contract.placeholder_amount')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.termCompensationPayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.term_compensation_pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.term_compensation_pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.termCompensationPay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.termCompensationPayIs}
							placeholder={t('col_contract.placeholder_amount')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.wayCompensationPayIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.way_compensation_pay')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.way_compensation_pay')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.wayCompensationPay}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.wayCompensationPayIs}
							placeholder={t('col_contract.placeholder_percent')}
						/>
					)}
				</FormikField>
			</div>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.wageIndexProcedureIs}>
					{({ field }) => (
						<Checkbox
							{...field}
							label={t('col_contract.wage_index_procedure')}
							onChange={() => setFieldValue(field.name, !field.value)}
							hint={t('col_contract.hint.wage_index_procedure')}
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.wageIndexProcedure}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							error={touched && error}
							{...field}
							disabled={!values.wageIndexProcedureIs}
							placeholder={t('col_contract.placeholder_percent')}
						/>
					)}
				</FormikField>
			</div>
		</>
	)
})
