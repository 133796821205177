export const formFieldNames = {
	bin: 'bin',
	dateCreate: 'dateCreate',
	unionName: 'unionName',
	chiefIin: 'chiefIin',
	chiefFio: 'chiefFio',
	unionBin: 'unionBin',
	unionNote: 'unionNote',
	typeCode: 'typeCode',
	industryCode: 'industryCode',
	parentBin: 'parentBin',
	parentName: 'parentName',
	employerBin: 'employerBin',
	employerName: 'employerName',
}
