import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './ChecksTab.module.scss'
import { SelfTest } from '@/components/SelfTest'
import { formsActions, formsSelectors, formsThunks } from '@/entity/forms'
import { useDispatch, useSelector } from 'react-redux'
import SelectButton from '@/components/entrance/SelectButton'
import RadioAnswer from '@/components/RadioAnswer/RadioAnswer'
import { Link } from 'react-router-dom'
import { getCurrentLanguage } from '@/i18next'
import Captcha from '@/components/Captcha/Captcha'
import { QuestionModal } from '@pages/Forms/Tabs/Checks/component'
import { HistoryCheckTheme } from '@pages/Forms/Tabs/Checks/component'
import themeBackground from 'assets/pages/forms/themeBackground.png'
import ellipse from 'assets/pages/forms/ellipse.svg'
import questionBackground from 'assets/pages/forms/questionBackground.svg'
import { downloadPdfById } from '@/shared/api/rest/selftest'

const ChecksTab = ({
	themes,
	cntEmployee,
	categoryCompany,
	questionnaires,
	activeRole,
	activeCompany,
	isAuth,
	activeSection,
}) => {
	const tabControlRef = useRef(null)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const selectQuestion = useSelector(formsSelectors.questions)
	const selectTestResult = useSelector(formsSelectors.testResult)
	const statusCheckQuestion = useSelector(formsSelectors.statusCheck)
	const [sectionTitle, setSectionTitle] = useState()
	const [sectionCode, setSectionCode] = useState()
	const [result, setResult] = useState({})
	const [indexKey, setIndexKey] = useState(0)
	const [nameQuestion, setNameQuestion] = useState({})
	const [answerPossible, setAnswerPossible] = useState([])
	const [answer, setAnswer] = useState([])
	const [check, setCheck] = useState()
	const [complete, setComplete] = useState(false)
	const [numberChapter, setNumberChapter] = useState()
	const [chapter, setChapter] = useState({})
	const [breach, setBreach] = useState([])
	const [recom, setRecom] = useState([])
	const [colorTitle, setColorTitle] = useState()
	const [disable, setDisable] = useState(true)
	const currDate = new Date().toLocaleDateString()
	const [startTimeAnswer, setStartTimeAnswer] = useState(null)
	const [timeAnswer, setTimeAnswer] = useState('')
	const [disableDownload, setDisableDownload] = useState(false)
	const [data, setData] = useState({})
	const [screenShow, setScreenShow] = useState('online_consultant')
	let backArrow = '\u003c'
	let currentLanguage = getCurrentLanguage()
	const [captchaValue, setCaptchaValue] = useState('[empty]')
	const [lastAnswer, setLastAnswer] = useState('[empty]')
	const [isActive, setIsActive] = useState(false)
	const [activeHistoryCheck, setActiveHistoryCheck] = useState(false)
	const [activateQuestion, setActivateQuestion] = useState(false)

	const trimLocal = (currentLanguage) => {
		if (currentLanguage === undefined || currentLanguage === null) return 'rus'
		return currentLanguage.replace(/[^a-zA-ZА-Яа-яЁё]/gi, '').replace(/\s+/gi, ', ')
	}
	currentLanguage = trimLocal(currentLanguage)

	const createSecondTitle = (createTitle) => {
		setIndexKey(0)
		setAnswer([])
		setComplete(false)
		setSectionTitle(createTitle)
		setData({})
		dispatch(formsActions.selfTestResultResponse([]))
	}

	const createSectionCode = (sectionCode) => {
		setSectionCode(sectionCode)
	}

	const getCaptchaValue = (value) => {
		setCaptchaValue(value)
	}

	useEffect(() => {
		if (activeRole === 'ROLE_EMPLOYER' && activeCompany) setActiveHistoryCheck(true)
	}, [activeCompany, activeRole, sectionTitle])

	useEffect(() => {
		if (Object.keys(selectQuestion).length > 0) {
			constructorQuestion()
		}
	}, [selectQuestion])

	useEffect(() => {
		dispatch(formsActions.selfTestResultResponse([]))
		setIndexKey(0)
		setAnswer([])
		setLastAnswer('[empty]')
	}, [sectionTitle])

	useEffect(() => {
		if (Object.keys(selectTestResult).length > 0) {
			dispatch(formsActions.activeTheme(null))
			setSectionCode(null)
			setIndexKey(0)
			setNameQuestion({})
			setAnswerPossible([])
			setAnswer([])
			setCheck(null)
			setComplete(false)
			setColorTitle(null)
			setCaptchaValue('[empty]')
			setLastAnswer('[empty]')
			initResult()
		}
	}, [selectTestResult])

	useEffect(() => {
		if (lastAnswer === 'ok') {
			setDisable(true)
			if (captchaValue === '[empty]') {
				setDisable(true)
			} else {
				setDisable(false)
			}
		}
	}, [captchaValue])

	const initResult = () => {
		setData(data)
		setTimeAnswer(selectTestResult.duration)
		setResult({ label: { rus: selectTestResult.result.nameRu, kz: selectTestResult.result.nameKz } })
		switch (selectTestResult.result.code) {
			case 'high':
				setColorTitle('red')
				break
			case 'medium':
				setColorTitle('yellow')
				break
			case 'low':
				setColorTitle('blue')
				break
			case 'no':
				setColorTitle('green')
				break
			default:
				setColorTitle('default')
		}
		setNumberChapter(selectTestResult.section.code.replace(/\D+/g, '') + '. ')
		setChapter({ label: { rus: selectTestResult.section.nameRu, kz: selectTestResult.section.nameKz } })
		setBreach(
			selectTestResult.breachList.map(({ code, nameRu, nameKz }) => ({
				code: code,
				label: { rus: nameRu, kz: nameKz },
			}))
		)
		setRecom(
			selectTestResult.recomList.map(({ code, nameRu, nameKz }) => ({
				code: code,
				label: { rus: nameRu, kz: nameKz },
			}))
		)
		setScreenShow('result')
	}

	function secondsToHms(d) {
		d = Number(d)
		let h = Math.floor(d / 3600)
		let m = Math.floor((d % 3600) / 60)
		let s = Math.floor((d % 3600) % 60)

		let hDisplay = h > 0 ? h + (h === 1 ? ' ч., ' : ' ч., ') : ''
		let mDisplay = m > 0 ? m + (m === 1 ? ' мин., ' : ' мин., ') : ''
		let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' с.') : ''
		return hDisplay + mDisplay + sDisplay
	}

	const onSubmitSelfTestForms = (e) => {
		e.preventDefault()
		let selfTest
		let end = new Date().getTime()
		let sec = (end - startTimeAnswer) / 1000
		let tm = secondsToHms(sec)
		if (isAuth) {
			selfTest = {
				answersList: answer,
				questionnaireList: questionnaires,
				sectionCode: sectionCode,
				duration: tm,
			}
			dispatch(formsThunks.sendAuthSelfTest(selfTest))
		} else {
			selfTest = {
				amountWorkers: cntEmployee,
				answersList: answer,
				categoryCode: categoryCompany,
				questionnaireList: questionnaires,
				sectionCode: sectionCode,
				duration: tm,
			}
			dispatch(formsThunks.sendSelfTest(selfTest, captchaValue))
		}
		setData(selfTest)
	}

	//TODO
	const constructorQuestion = () => {
		setActivateQuestion(true)
		setDisable(true)
		setScreenShow('section')
		let countQuestion = Object.keys(selectQuestion).length
		let question = selectQuestion[indexKey]
		setNameQuestion({ label: { rus: question.question.nameRu, kz: question.question.nameKz } })
		const newAnswerPossible = question.answers.map(({ code, nameRu, nameKz }) => ({
			code: code,
			label: { rus: nameRu, kz: nameKz },
		}))
		newAnswerPossible.forEach((item) => {
			item.checked = false
		})
		newAnswerPossible.forEach((item) => {
			item.qCode = question.question.code
		})
		newAnswerPossible.forEach((item) => {
			item.cntQ = countQuestion
		})
		newAnswerPossible.forEach((item) => {
			item.numberQ = indexKey
		})
		setAnswerPossible(newAnswerPossible)
		if (selectQuestion.length - 1 === indexKey) {
			setComplete(true)
			setIndexKey(0)
		}
		if (indexKey === 0) {
			setStartTimeAnswer(new Date().getTime())
		}
		if (indexKey > 0) {
			setAnswer([...answer, check])
		}
		setIndexKey(indexKey + 1)
	}

	const downloadResultPdf = (e) => {
		e.preventDefault()
		setDisableDownload(true)
		setTimeout(() => {
			setDisableDownload(false)
		}, 5000)
		let objJsonStr = JSON.stringify(data)
		const encodedString = Buffer.from(objJsonStr).toString('base64')
		let lang = currentLanguage === 'rus' ? 'ru' : 'kk'
		dispatch(formsThunks.getPdf(encodedString, lang))
	}

	const downloadAuthPDF = async () => {
		let lng = currentLanguage === 'rus' ? 'ru' : 'kz'
		const res = await downloadPdfById(lng, selectTestResult.id)
		const file = new Blob([res], { type: 'application/pdf' })
		const fileURL = URL.createObjectURL(file)
		window.open(fileURL)
	}

	const handleChange = (e) => {
		dispatch(formsThunks.setStatusCheck(true))
		const { value } = e.target
		let arrForUpdt = answerPossible
		setDisable(false)
		arrForUpdt.forEach((item) => {
			if (item.code === value) item.checked = true
			else item.checked = false
		})
		setAnswerPossible(arrForUpdt)
		const checkedAnswer = arrForUpdt.filter((i) => i.checked === true)
		const responseFormat = { code: checkedAnswer[0].qCode, value: checkedAnswer[0].code }
		if (checkedAnswer[0].cntQ === checkedAnswer[0].numberQ + 1) {
			if (answer.length === checkedAnswer[0].cntQ) {
				answer.splice(-1)
				setAnswer([...answer, responseFormat])
				setLastAnswer('ok')
			} else {
				setAnswer([...answer, responseFormat])
				setLastAnswer('ok')
			}
		} else {
			setCheck(responseFormat)
		}
		if (complete) {
			setDisable(true)
			if (captchaValue === '[empty]' && !isAuth) {
				setDisable(true)
			} else {
				setDisable(false)
			}
		}
	}

	const moveBack = () => {
		switch (screenShow) {
			case 'online_consultant':
				dispatch(formsActions.themesLoading([]))
				dispatch(formsActions.questionsLoading([]))
				dispatch(formsActions.selfTestResultResponse([]))
				dispatch(formsActions.answerSelfTestLoading([]))
				break
			case 'section':
				statusCheckQuestion ? setIsActive(true) : handleModalOnClick()
				break
			case 'result':
				dispatch(formsActions.questionsLoading([]))
				dispatch(formsActions.selfTestResultResponse([]))
				dispatch(formsActions.answerSelfTestLoading([]))
				setScreenShow('online_consultant')
				break
			default:
				dispatch(formsActions.themesLoading([]))
				dispatch(formsActions.questionsLoading([]))
				dispatch(formsActions.selfTestResultResponse([]))
				dispatch(formsActions.answerSelfTestLoading([]))
				setScreenShow('online_consultant')
				break
		}
	}

	const handleModalOnClick = () => {
		dispatch(formsActions.questionsLoading([]))
		dispatch(formsActions.answerSelfTestLoading([]))
		dispatch(formsThunks.setStatusCheck(false))
		setAnswerPossible([])
		setIsActive(false)
		setScreenShow('online_consultant')
	}

	const activateQuestionModal = () => {
		setActiveHistoryCheck(false)
		setStartTimeAnswer(new Date().getTime())
	}

	const handleModalCancelClick = useCallback(() => setIsActive(false), [])

	return (
		<div className={styles.section_wrapper}>
			<div>
				{activateQuestion ? (
					<></>
				) : (
					<Link onClick={moveBack} className={styles.back} to={'/'}>
						<Title color={'green'} type={'h3'}>
							{backArrow} {t('back')}
						</Title>
					</Link>
				)}
				<Title>{t('choose_topic_self_test')}</Title>
				<SelfTest
					themes={themes}
					createTitle={createSecondTitle}
					sectionCode={createSectionCode}
					ref={tabControlRef}
					activeSection={activeSection}
				/>
			</div>
			{Object.keys(selectTestResult).length > 0 ? (
				<div className={styles.section_block}>
					<div className={styles.section_empty}>
						<div onClick={moveBack} className={styles.back}>
							<Title color={'green'} type={'h3'}>
								{backArrow} {t('back')}
							</Title>
						</div>
						<Title ground={'default'}>{sectionTitle?.[currentLanguage === 'rus' ? 'nameRu' : 'nameKz' || '-']}</Title>
						<div className={styles.empty_topic}>
							<div className={styles.empty_circle_right_result}>
								<img src={ellipse} />
							</div>
							<div className={styles.mrg}>
								<div className={styles.title_result}>
									<div className={styles.result}>
										<Title ground={'notcolor'} color={colorTitle}>
											{t('result')}
										</Title>
									</div>
									<Title color={'white'} ground={colorTitle}>
										{result.label?.[currentLanguage]}
									</Title>
								</div>
								<p align={'justify'}>{t('service_online_labor_consultant_check')}</p>
								<p>
									{t('history_check.business_name')}: {selectTestResult?.orgName || '-'}
								</p>
								<p align={'justify'}>
									{t('self_test_date')} {currDate} {t('year')}
								</p>
								<p align={'justify'}>
									{t('self_test_duration')} {timeAnswer}
								</p>
								<p align={'justify'}>
									{t('chapter')}
									{numberChapter}
									{chapter.label?.[currentLanguage]}
								</p>
								<>
									{Object.keys(breach).length > 0 ? (
										<>
											<Title ground={'notcolor'} color={colorTitle}>
												{t('following_violations_identified')}
											</Title>
											{breach.map((value, index) => {
												return (
													<p key={value} align={'justify'}>
														{index + 1 + '. '}
														{value.label?.[currentLanguage]}
													</p>
												)
											})}
										</>
									) : (
										<>
											<Title ground={'notcolor'} type={'h3'} color={colorTitle}>
												{t('no_violations_found')}
											</Title>
										</>
									)}
									{Object.keys(recom).length > 0 ? (
										<>
											<Title ground={'notcolor'}>{t('recommendationsTest')}</Title>
											{recom.map((value, index) => {
												return (
													<p key={value} align={'justify'}>
														{index + 1 + '. '}
														{value.label?.[currentLanguage]}
													</p>
												)
											})}
										</>
									) : (
										<></>
									)}
									<div className={styles.button_result}>
										<SelectButton onClick={isAuth ? downloadAuthPDF : downloadResultPdf} disabled={disableDownload}>
											{t('download_result')}
										</SelectButton>
									</div>
								</>
							</div>
							<div className={styles.empty_circle_result}></div>
							<div className={styles.theme_empty}>
								<img src={questionBackground} />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.section_block}>
					{activateQuestion ? (
						<div className={styles.section_empty}>
							{isActive && (
								<QuestionModal
									cancelButtonTitle={'cancel'}
									handleProcess={handleModalOnClick}
									onCancel={handleModalCancelClick}
								></QuestionModal>
							)}
							<a onClick={moveBack} className={styles.back}>
								<Title color={'green'} type={'h3'}>
									{backArrow} {t('back')}
								</Title>
							</a>
							<Title ground={'default'}>{sectionTitle?.[currentLanguage === 'rus' ? 'nameRu' : 'nameKz' || '-']}</Title>
							<div className={styles.empty_topic}>
								<div className={styles.mrg}>
									{activeHistoryCheck ? (
										<HistoryCheckTheme activateQuestionModal={activateQuestionModal}></HistoryCheckTheme>
									) : (
										<>
											<Title type={'h2'} ground={'notcolor'} color={'green'}>
												{t('question_number')} {indexKey === 0 ? 1 : indexKey}
											</Title>
											<Title type={'h4'} ground={'notcolor'}>
												{nameQuestion.label?.[currentLanguage]}
											</Title>
											{answerPossible &&
												answerPossible.map((value) => {
													return (
														<RadioAnswer
															key={value.code}
															id={value.code}
															checked={value.checked}
															name={value.label?.[currentLanguage]}
															handleChange={handleChange}
														/>
													)
												})}
											{complete && complete ? (
												<div className={styles.identCaptcha}>
													{!isAuth && <Captcha recaptcha={getCaptchaValue} />}
													<div className={styles.identCaptcha}>
														<SelectButton onClick={onSubmitSelfTestForms} disabled={disable}>
															{t('complete_self_test_button')}
														</SelectButton>
													</div>
												</div>
											) : (
												<SelectButton onClick={constructorQuestion} disabled={disable}>
													{t('next_question')}
												</SelectButton>
											)}
										</>
									)}
								</div>
								<div className={styles.empty_circle}></div>
								<div className={styles.empty_circle_right}>
									<img src={ellipse} />
								</div>
								<div className={styles.theme_empty}>
									<img src={questionBackground} />
								</div>
							</div>
						</div>
					) : (
						<div className={styles.section_empty}>
							<Title>{t('topic_not_selected')}</Title>
							<div className={styles.empty_topic}>
								<div className={styles.empty_topic_title}>
									<p>{t('service')}</p>
									<p>{t('online_labor')}</p>
								</div>
								<div className={styles.empty_topic_text}>
									<p>{t('start_self_test')}</p>
									<p>{t('must_select_topic')}</p>
								</div>
								<div className={styles.empty_circle_left}>
									<img src={ellipse} />
								</div>
								<div className={styles.theme_empty}>
									<img src={themeBackground} />
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default ChecksTab
