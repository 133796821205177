import React, { useCallback } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTranslation } from 'react-i18next'
import { renderWithHTML } from '@/utils'

const NotificationListItem = ({ item, handleClickItem }) => {
	const handleListItemClick = useCallback(() => {
		handleClickItem(item)
	}, [handleClickItem, item])
	const {
		i18n: { language },
	} = useTranslation()
	const emaketArray = ['emaket_22', 'emaket_21', 'emaket_4', 'emaket_3', 'emaket_20']
	return (
		<ListItem
			onClick={handleListItemClick}
			alignItems="flex-start"
			sx={{
				backgroundColor: item.readDate == null ? '#F9FBFF' : '#FFFFFF',
				padding: '0 16px 0 0',
				height: '106px',
				border: '1px solid #D3DFF0',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				maxWidth: '100%',
				cursor: 'pointer',
				transition: 'background 0.3s ease',
				...(emaketArray.includes(item.notifType.code) &&
					item.readDate == null && {
						backgroundColor: '#C4D9F8',
						'&:hover': {
							backgroundColor: '#5792EB',
						},
					}),
			}}
		>
			<div
				style={{
					position: 'relative',
					width: '8px',
					minWidth: '8px',
					height: '106px',
					backgroundColor: item.readDate == null ? 'black' : 'transparent',
				}}
			></div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					padding: '16px',
					gap: '6px',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
				}}
			>
				<div
					style={{
						color: '#1D293F',
						fontFamily: 'Inter',
						fontSize: '18px',
						lineHeight: '110%',
						fontWeight: 'normal',
						textOverflow: 'ellipsis',
					}}
				>
					{(language === 'rus' ? item?.notifType.nameRu.substring(0, 120) : item?.notifType.nameKz.substring(0, 120)) ||
						''}
				</div>
				<div
					style={{
						color: '#5B6880',
						fontFamily: 'Inter',
						fontSize: '16px',
						lineHeight: '140%',
						fontWeight: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						maxWidth: '70vw',
					}}
				>
					{(language === 'rus'
						? renderWithHTML(item?.notifRu.substring(0, 120))
						: renderWithHTML(item?.notifKz.substring(0, 120))) || ''}
				</div>
				<div
					style={{
						color: '#AAB8D1',
						fontFamily: 'Inter',
						fontSize: '16px',
						lineHeight: '140%',
						fontWeight: 'normal',
						textOverflow: 'ellipsis',
					}}
				>
					{item?.eventDate.substring(0, 10) || ''} {item?.eventDate.substring(11, 16) || ''}
				</div>
			</div>
		</ListItem>
	)
}

export function NotificationsTabPanelList({ data, handleClickItem, nodata }) {
	const { t } = useTranslation()
	return (
		<List
			sx={{
				bgcolor: '#F9FBFF',
				borderRadius: '8px 8px 0 0',
				padding: '0 0 0 0',
				border: 'none',
				maxWidth: 'inherit',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			}}
		>
			{data &&
				data?.content.map((item, index) => (
					<NotificationListItem key={`notification-item-${index}`} item={item} handleClickItem={handleClickItem} />
				))}
			{data && data.error == '' && data?.content.length == 0 && <span>{nodata}</span>}
			{data && data.error?.length > 0 && data?.content.length == 0 && <span>{t('nodata')}</span>}
		</List>
	)
}
