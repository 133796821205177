import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './PositionInformation.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { formatNumber } from '@/utils'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'

export const PositionInformation = ({ position }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const langPositionName = lang === 'rus' ? 'rus' : 'kz'
	const { positionsByKnz } = useSelector(contractActionsSelectors.options)
	let positionName = positionsByKnz?.find((item) => item.code === position.positionCode)

	const isFreelance = position.freelance

	let errors = position.errors
	const errorInformation = () => {
		const errorDescriptions = errors.map((error) => error.code + ': ' + error[optionLang])
		return [
			{
				title: t('staff.errors'),
				data: errorDescriptions.join(',\n'),
			},
		]
	}

	const positionInformation = () => {
		return [
			{ title: t('staffing_table.workplace_code_label'), data: position.code },
			{ title: t('staff_modal.position_kz'), data: position.establishedPost.nameKz },
			{ title: t('staff_modal.position_ru'), data: position.establishedPost.nameRu },
			{ title: t('contract.info.nkz_position'), data: positionName?.[langPositionName] || '' },
			{ title: t('staff_modal.freelancer'), data: isFreelance ? t('yes') : t('no') },
			{ title: t('workplace.tariff_rate'), data: position.staffUnitsCount },
			{ title: t('staffing_table.salary'), data: formatNumber(position.grossSalary || '') },
			{ title: t('staffing_table.supplement_surcharge'), data: formatNumber(position.salarySupplement || '') },
		]
	}
	return (
		<>
			<DataGridTable
				hideSubTitle
				titleClass={styles.tableTitleCell}
				title={t('staff_modal.info_position')}
				information={positionInformation()}
			/>
			{position.errors?.length > 0 && (
				<DataGridTable
					hideSubTitle
					titleClass={styles.tableTitleCell}
					dataClass={styles.dataClassCell}
					information={errorInformation()}
				/>
			)}
		</>
	)
}
