import { Button as DialogButton, Field } from '@/ui'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../actions/view/ContractCard.module.scss'
import CloseButton from '@/components/CloseButton/CloseButton'
import { request } from '@/shared/api/request'
import { message } from 'antd'
import { contractsActions, contractsThunks } from '@/entity'
import { useDispatch } from 'react-redux'

export const AdditionalDecline = ({ title = 'sure_decline', onClose, isOpen, additionalContract, contract }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [note, setNote] = useState('')

	const onRejectDefault = async (callback, note) => {
		const result = {
			externalId: additionalContract.externalId,
			note,
		}
		await callback(result)
	}

	const updateData = () => {
		dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
		dispatch(contractsActions.setAdditionalContractSubmitted())
		message.success(t('success_alert'), 5)
		onClose()
	}
	const sendReject = async (title, rejectData) => {
		try {
			const res = await request({
				url: `/contract/eAdContract/${title}/reject`,
				method: 'POST',
				params: rejectData,
			})
			if (res.success) {
				updateData()
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}
	const sendRejectEmployee = async (rejectData) => await sendReject('employee', rejectData)
	const sendRejectEmployer = async (rejectData) => await sendReject('employer', rejectData)

	const onRejectEmployee = async (note) => await onRejectDefault(sendRejectEmployee, note)
	const onRejectEmployer = async (note) => await onRejectDefault(sendRejectEmployer, note)

	//? Если статус "На подписи работодателя"
	const IS_EMPLOYER_SIGN = additionalContract?.dcontractState?.code === '7'
	const onDecline = IS_EMPLOYER_SIGN ? onRejectEmployer : onRejectEmployee

	return (
		<Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={isOpen}>
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: styles.sign__title }}>{t(title)}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					<Field
						value={note}
						onChange={(e) => {
							setNote(e)
						}}
						wrapperClassName={styles.textarea__wrapper}
						placeholder={t('el_cause_short')}
						rows={8}
						fieldType="textarea"
					/>
					<div className={styles.reset__button__wrapper}>
						<DialogButton type="button" onClick={onDecline.bind(null, note)} disabled={!note}>
							{t('decline')}
						</DialogButton>
					</div>
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button, styles.reset__button) }}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
