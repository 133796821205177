import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './Textarea.module.scss'

export const Textarea = ({ onChange, maxLength = 3500, rows = 3, showCount = false, autosize = false, ...props }) => {
	const [count, setCount] = useState('')
	const [currentValue, setCurrentValue] = useState('')
	const textareaRef = useRef(null)

	const onChangeValue = useCallback(
		(e) => {
			let value = e.currentTarget.value
			const caretPosition = textareaRef.current.selectionStart
			setCount(value.length + ' / ' + maxLength)

			if (autosize) {
				setCurrentValue(value)
			}

			onChange(value)

			// Восстанавливаем позицию курсора
			window.requestAnimationFrame(() => {
				textareaRef.current.selectionStart = caretPosition
				textareaRef.current.selectionEnd = caretPosition
			})
		},
		[onChange, autosize, maxLength]
	)

	useEffect(() => {
		const lineHeight = parseFloat(getComputedStyle(textareaRef.current).lineHeight)
		const minHeight = rows * lineHeight

		textareaRef.current.style.height = 'auto'
		const scrollHeight = textareaRef.current.scrollHeight
		textareaRef.current.style.height = Math.max(scrollHeight, minHeight) + 'px'
	}, [currentValue, rows])

	return (
		<>
			<textarea
				ref={textareaRef}
				className={`scroll ${styles.textarea}`}
				onChange={onChangeValue}
				maxLength={maxLength}
				rows={rows}
				value={currentValue}
				{...props}
			/>
			{showCount && (
				<div className={styles.otherContract}>
					<p align="right">{count}</p>
				</div>
			)}
		</>
	)
}
