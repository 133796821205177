export const headCells = [
	{
		id: 'id',
		numeric: true,
		label: '№',
	},
	{
		id: 'name',
		numeric: false,
		label: 'personal_affairs.card.table.headers.name',
	},
	{
		id: 'request_data',
		numeric: false,
		label: 'personal_affairs.card.table.headers.request_data',
	},
	{
		id: 'sign_data',
		numeric: false,
		label: 'personal_affairs.card.table.headers.sign_data',
	},
	{
		id: null,
		numeric: false,
		label: 'personal_affairs.card.table.headers.result',
	},
	{
		id: null,
		numeric: false,
		label: '',
	},
]
