import styles from './Form.module.scss'
import classNames from 'classnames'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, DateTimePicker, Error, Field, Label } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { filterKato } from '@/entity/handbook/thunks'
import { Victim } from '@/entity/accidents/ui/form/components/Victim'
import { Plus } from '@app/images'
import { SelectPerson } from '@/components/SelectPerson/SelectPerson'
import { getCurrentLanguage } from '@/i18next'

export const Form = ({ onClose, isFormDirty, setFormDirty, districts, positions, positionsNkz, genders }) => {
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const { setFieldValue, values, validateForm } = useFormikContext()
	const [regions, setRegions] = useState([])

	const loadRegions = useCallback(async (code) => {
		if (code) {
			const response = await filterKato(code)
			setRegions(response)
		} else {
			setRegions([])
		}
	}, [])

	const handleEstablishedPositionChange = useCallback(
		(value) => {
			// Find a position by code
			let position = positions?.find((item) => item.code === value)
			if (position) {
				// Value is from select options
				setFieldValue('senderEstablisedPost', { code: position.code, nameRu: position.rus, nameKz: position.kz })
			} else {
				// Value is from manual input
				const newValue =
					currentLanguage === 'rus'
						? { code: null, nameRu: value, nameKz: null }
						: { code: null, nameRu: null, nameKz: value }
				setFieldValue('senderEstablisedPost', newValue)
			}
		},
		[positions, currentLanguage]
	)

	const establishedPosition = useMemo(() => {
		return currentLanguage === 'rus' ? values.senderEstablisedPost?.nameRu : values.senderEstablisedPost?.nameKz
	}, [values.senderEstablisedPost])

	return (
		<FormikForm className={styles.wrapper}>
			<div className={styles.chapter}>
				<div className={styles.row}>
					<section className={classNames(styles.fields, styles.fields_threeFields)}>
						<FormikField name="accidentDateTime">
							{({ meta: { error } }) => (
								<div className={styles.dateTimeField}>
									<Label name="accidentDate">{t('accidentsPage.register.accidentDate') + ' *'}</Label>
									<DateTimePicker
										onChange={(value, dateString) => {
											setFieldValue('accidentDateTime', dateString)
										}}
										required
									/>
									{error && (
										<Error>
											{typeof error === 'string' ? error : t(`validation.${error.key}`, { value: error.value })}
										</Error>
									)}
								</div>
							)}
						</FormikField>
						<FormikField name="region">
							{({ field, meta: { touched, error } }) => (
								<Field
									options={districts}
									type="text"
									fieldType="select"
									label={t('region')}
									placeholder={t('accidentsPage.register.selectRegion')}
									isRequired
									{...field}
									disabled={false}
									error={(touched || isFormDirty) && error}
									onChange={(code) => {
										setFieldValue('region', code)
										setFieldValue('district', null)
										loadRegions(code)
									}}
								/>
							)}
						</FormikField>
						<FormikField name="district">
							{({ field, meta: { touched, error } }) => (
								<Field
									options={regions}
									type="text"
									fieldType="select"
									label={t('district')}
									isRequired
									placeholder={t('accidentsPage.register.selectDistrict')}
									{...field}
									disabled={false}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name="location">
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType="textarea"
									label={t('accidentsPage.register.incidentScene')}
									isRequired
									rows={4}
									placeholder={t('accidentsPage.register.incidentScene')}
									error={(touched || isFormDirty) && error}
									{...field}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name="circumstance">
							{({ field, meta: { touched, error } }) => (
								<Field
									labelClassName={styles.formLabel}
									type="text"
									isRequired
									fieldType="textarea"
									label={t('accidentsPage.register.circumstances')}
									placeholder={t('specification')}
									rows={4}
									disabled={false}
									error={(touched || isFormDirty) && error}
									autosize
									{...field}
								/>
							)}
						</FormikField>
					</section>
				</div>
				<div className={styles.row}>
					{values.injureds.map((item, index) => (
						<Victim
							key={index}
							isFormDirty={isFormDirty}
							name={'injureds'}
							index={index}
							showIndex={values.injureds.length > 1}
							positions={positions}
							positionsNkz={positionsNkz}
							genders={genders}
							currentLanguage={currentLanguage}
						></Victim>
					))}
					<section>
						<Button
							textColor="green"
							borderColor="green"
							transparent
							border
							type="button"
							icon={Plus}
							onClick={() => setFieldValue('injureds', [...values.injureds, { isResident: true }])}
						>
							{t('accidentsPage.register.addVictim')}
						</Button>
					</section>
				</div>
				<div className={styles.row}>
					<h5>{t('accidentsPage.register.applicant')}</h5>
					<SelectPerson
						isFormDirty={isFormDirty}
						wrapperClassName={styles.senderSelectPersonWrapper}
						iinLabel={t('accidentsPage.senderIin')}
						iinFieldName={'senderIin'}
						fullNameFieldName={'senderFio'}
					/>
					<section>
						<Field
							name={'senderEstablisedPost'}
							options={positions}
							type="text"
							fieldType="selectAndInput"
							isRequired
							placeholder={t('position_placeholder')}
							onChange={handleEstablishedPositionChange}
							value={establishedPosition}
							label={t('contract.info.position')}
							error={isFormDirty && !establishedPosition && { key: 'required' }}
						/>
					</section>
				</div>
			</div>

			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button
					type="submit"
					onClick={() => {
						validateForm()
						setFormDirty(true)
					}}
				>
					{t('sign_and_send')}
				</Button>
			</div>
		</FormikForm>
	)
}
