import React, { useMemo, useState } from 'react'
import Slider from 'react-slick'
import foto1 from '../../../assets/home/foto1.png'
import foto2 from '../../../assets/home/foto2.png'
import foto3 from '../../../assets/home/foto3.png'
import foto4 from '../../../assets/home/foto4.png'
import foto5 from '../../../assets/home/foto5.png'
import foto8 from '../../../assets/home/foto8.png'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

const Info = () => {
	const activeRole = useSelector(userSelectors.activeRole)
	const companies = useSelector(userSelectors.employerCompanies)
	const user = useSelector(userSelectors.user)
	const lang = getCurrentLanguage()
	const { t } = useTranslation()
	const [urlAuth, setUrlAuth] = useState()
	const [refEnbekVac, setRefEnbekVac] = useState()
	const [refEnbekRes, setRefEnbekRes] = useState()
	const [refContract, setRefContract] = useState('/contracts')

	useMemo(
		() =>
			lang === 'rus'
				? [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbekVac(process.env.REACT_APP_REDIRECT_ENBEK + 'ru' + process.env.REACT_APP_VAC),
						setRefEnbekRes(process.env.REACT_APP_REDIRECT_ENBEK + 'ru' + process.env.REACT_APP_RES),
				  ]
				: [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbekVac(process.env.REACT_APP_REDIRECT_ENBEK + 'kk' + process.env.REACT_APP_VAC),
						setRefEnbekRes(process.env.REACT_APP_REDIRECT_ENBEK + 'kk' + process.env.REACT_APP_RES),
				  ],
		[lang]
	)

	useMemo(
		() =>
			(activeRole === 'ROLE_EMPLOYER' && !companies.length) || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
				? setRefContract('/reference-information/3')
				: setRefContract('/contracts'),
		[activeRole, companies.length]
	)

	const array = useMemo(
		() => [
			{
				title: 'register_employment_contracts_online',
				ref: !user ? urlAuth : refContract,
				link: foto4,
			},
			{
				title: 'learn_about_integration_unified_accounting_system_employment_contracts',
				ref: '/reference-information/2',
				link: foto5,
			},
			{
				title: 'pass_self_assessment_compliance_labor_law_requirements',
				ref: '/forms',
				link: foto2,
			},
			{
				title: 'frequently_asked_questions',
				ref: '/reference-information/3',
				link: foto8,
			},
			{
				title: 'find_job_title',
				ref: refEnbekVac,
				link: foto3,
			},
			{
				title: 'find_employee',
				ref: refEnbekRes,
				link: foto1,
			},
		],
		[refEnbekRes, refEnbekVac, urlAuth, user]
	)

	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		swipeToSlide: true,
		// autoplay: true,
		responsive: [
			{
				breakpoint: 1900,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 571,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}
	return (
		<div className="info">
			<div className="infoSlider-wrapper">
				<Slider {...settings}>
					{array.map((arr, index) => {
						return (
							<div key={arr.title} className="infoSlider-block">
								<div className="infoSlider-item">
									<p className={styles.pMediumInter}>{t(arr.title)}</p>
									<a className={'link'} href={arr.ref}>
										{' '}
										{t('more_details')}
									</a>
									<img src={arr.link} />
								</div>
							</div>
						)
					})}
				</Slider>
			</div>
		</div>
	)
}

export default Info
