import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	oppv: null,
	error: null,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.OPPV:
			return { ...state, oppv: payload }
		case types.ERROR:
			return { ...state, error: payload }
		case types.RESET:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				oppv: null,
				error: null,
			}
		default:
			return state
	}
}
