import React, { useEffect } from 'react'
import { ModalTemplate, Title } from '@/ui'
import styles from './StaffingFirstStructure.module.scss'
import { Formik } from 'formik'
import { Form } from './Form'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { ValidationStructSchema } from './validationStructSchema'
import { LoadingBlocker } from '@/components/Loader'

function filterStructByExternalId(staffProject, externalId) {
	if (staffProject.externalId === externalId) return staffProject

	if (Array.isArray(staffProject.childs)) {
		for (let child of staffProject.childs) {
			let res = filterStructByExternalId(child, externalId)
			if (res) return res
		}
	}

	return null
}
export const StaffingStructure = ({
	initialValues,
	staffType,
	rowSelectMenu,
	setRowSelectMenu,
	onClose,
	actions,
	staffProject,
}) => {
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const units = [rowSelectMenu]
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const isLoading = useSelector(staffSelectors.isLoading)

	useEffect(() => {
		if (isSuccess) {
			if (actions.edit) {
				let structure = filterStructByExternalId(staffProject, rowSelectMenu.externalId)
				setRowSelectMenu(structure)
			}
			onClose()
		}
	}, [isSuccess])

	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	const deptTypeName = showDeptType(rowSelectMenu.deptTypeCode)

	const onSubmit = (data) => {
		if (actions.edit) {
			let transformedStructure = {
				orgExternalId: staffProject.externalId,
				row: {
					binBranch: data.units[0].binBranch,
					dept: {
						code: data.units[0].dept.code,
						nameKz: data.units[0].dept.nameKz,
						nameRu: data.units[0].dept.nameRu,
					},
					deptTypeCode: data.units[0].deptTypeCode,
					externalId: rowSelectMenu.externalId,
				},
			}
			dispatch(staffThunks.editStructure(transformedStructure))
		} else {
			let transformedStructure = {
				list: [],
				orgExternalId: staffProject.externalId,
				parentExternalId: rowSelectMenu.externalId,
			}
			transformedStructure.list = data.units.map((unit) => {
				return {
					binBranch: unit.binBranch,
					dept: {
						code: unit.dept.code,
						nameKz: unit.dept.nameKz,
						nameRu: unit.dept.nameRu,
					},
					deptTypeCode: unit.deptTypeCode,
				}
			})
			dispatch(staffThunks.addStructure(transformedStructure))
		}
	}
	return (
		<div>
			{isLoading ? <LoadingBlocker /> : null}
			<ModalTemplate onClose={onClose} priority={actions.edit ? 2 : 1}>
				<div className={`scroll ${styles.wrapper}`}>
					<Title color={'black'}>{actions.edit ? `Редактирование: ${deptTypeName}` : deptTypeName}</Title>
					<Formik
						validationSchema={ValidationStructSchema}
						initialValues={actions.edit ? { units } : initialValues}
						staffType={staffType}
						rowSelectMenu={rowSelectMenu}
						onSubmit={onSubmit}
					>
						<Form onClose={onClose} staffType={staffType} actions={actions}></Form>
					</Formik>
				</div>
			</ModalTemplate>
		</div>
	)
}
