export const staffing = (state) => state.staffing.staffing.data
export const isLoading = (state) => state.staffing.isLoading
export const isSuccess = (state) => state.staffing.isSuccess
export const error = (state) => state.staffing.error
export const esutdInfo = (state) => state.staffing.staffing.data.esutdInfo
export const staffXML = (state) => state.staffing.staffXML
export const wplaceEmployeeList = (state) => state.staffing.wplaceEmployeeList
export const employeeListXML = (state) => state.staffing.employeeListXML
export const employeeeDelXML = (state) => state.staffing.employeeeDelXML
export const employeeEditXML = (state) => state.staffing.employeeEditXML
