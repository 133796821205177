import { handbookThunks } from '@/entity/handbook'
import { staffActions } from '@/entity/staffingProject'
import { api } from '@/shared'
import { message } from 'antd'

function updateChilds(childs, targetId, newData) {
	return childs.map((child) => {
		if (child.externalId === targetId) {
			return { ...child, ...newData } // возвращаем обновленный объект
		} else if (child.childs) {
			return { ...child, childs: updateChilds(child.childs, targetId, newData) } // обновляем вложенные childs
		} else {
			return child // возвращаем оригинальный объект для всех остальных элементов
		}
	})
}

// функция для обновления workPlaces
function updateChildWorkPlaces(childs, structureExternalId, newWorkPlace) {
	for (let i = 0; i < childs.length; i++) {
		if (childs[i].externalId === structureExternalId) {
			const workPlacesUpdated = [...(childs[i].workPlaces || []), ...(newWorkPlace || [])]
			return [...childs.slice(0, i), { ...childs[i], workPlaces: workPlacesUpdated }, ...childs.slice(i + 1)]
		}
		if (childs[i].childs) {
			const updatedChilds = updateChildWorkPlaces(childs[i].childs, structureExternalId, newWorkPlace)
			if (updatedChilds) {
				return [...childs.slice(0, i), { ...childs[i], childs: updatedChilds }, ...childs.slice(i + 1)]
			}
		}
	}
}

function updateEditChildWorkPlaces(childs, structureExternalId, newWorkPlace) {
	let updatedChilds = [...childs] // Создаем копию массива, чтобы работать с ней, не изменяя оригинальный массив.

	for (let i = 0; i < updatedChilds.length; i++) {
		let currentChild = updatedChilds[i]

		// Ищем соответствующего ребенка (child).
		if (currentChild.externalId === structureExternalId) {
			let index = currentChild.workPlaces.findIndex((workPlace) => workPlace.externalId === newWorkPlace.externalId)

			if (index !== -1) {
				// Соответствующее рабочее место (workplace) найдено.
				currentChild.workPlaces[index] = newWorkPlace // Обновляем его.
				return updatedChilds // Возвращаем результат, так как обновление уже завершено.
			}
		}

		// Если у нашего дочернего элемента (child) есть свои дочерние элементы (childs), то ищем в них рекурсивно.
		if (currentChild.childs) {
			let result = updateEditChildWorkPlaces(currentChild.childs, structureExternalId, newWorkPlace)
			if (result !== null) {
				// Обновление проведено в дочернем элементе.
				return updatedChilds // Возвращаем результат, так как обновление уже завершено.
			}
		}
	}

	// Если выполнение дошло до этого момента, это значит, что рабочее место (workplace) не было обновлено.
	// Возвращаем null, чтобы указать это.
	return null
}

function deleteFromChildWorkPlaces(childs, structureExternalId, deleteWorkPlaceExternalId) {
	let updatedChilds = [...childs]

	for (let i = 0; i < updatedChilds.length; i++) {
		let currentChild = updatedChilds[i]

		if (currentChild.externalId === structureExternalId) {
			let index = currentChild.workPlaces.findIndex((workPlace) => workPlace.externalId === deleteWorkPlaceExternalId)

			if (index !== -1) {
				currentChild.workPlaces.splice(index, 1) // Удаляем соответствующее рабочее место.
				return updatedChilds // Возвращаем результат, так как удаление уже завершено.
			}
		}

		if (currentChild.childs) {
			let result = deleteFromChildWorkPlaces(currentChild.childs, structureExternalId, deleteWorkPlaceExternalId)
			if (result !== null) {
				return updatedChilds // Возвращаем результат, так как удаление уже завершено.
			}
		}
	}

	return null
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const { staffType, staffStatus, staffEmpStatus, esutdRegErrors, positions, positionsByKnz } = getState().handbook
		if (!staffType.length > 0) dispatch(handbookThunks.getStaffDeptType())
		if (!staffStatus.length > 0) dispatch(handbookThunks.getStaffStatus())
		if (!staffEmpStatus.length > 0) dispatch(handbookThunks.getStaffEmpStatus())
		if (!esutdRegErrors.length > 0) dispatch(handbookThunks.esutdRegErrors())
		if (!positions.length > 0) dispatch(handbookThunks.getPositions())
		if (!positionsByKnz.length > 0) dispatch(handbookThunks.getPositionsByKnz())
	}
}

export const getProject = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const staffProject = await api.staff.getProject()
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getStatusProject = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const statusProject = await api.staff.getStatusProject()
			dispatch(staffActions.setStatus(statusProject.statusCode))
			if (statusProject.statusCode === '3') {
				dispatch(staffActions.setStaffProject(statusProject.staffOrgDto))
			}
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения статуса:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const createProject = (project) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const createdProject = await api.staff.createProject(project)
			dispatch(staffActions.setStaffProject(createdProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно создан!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка сохранения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editProject = (project) => {
	return async (dispatch, getState) => {
		try {
			dispatch(staffActions.toggleLoading(true))

			const response = await api.staff.editProject(project)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				note: project.note,
				orderNumber: project.orderNumber,
				orderDate: project.orderDate,
				// Заменяем объект dept из project.dept в первом элементе childs
				childs:
					staff.staff.data.childs.length > 0
						? [
								{
									...staff.staff.data.childs[0],
									dept: project.dept,
								},
								...staff.staff.data.childs.slice(1),
						  ]
						: [],
			}

			// Используем updatedProject для обновления состояния Redux
			dispatch(staffActions.setStaffProject(updatedProject))

			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно изменён!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delProject = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const deletedProject = await api.staff.removeProject()
			dispatch(staffActions.setStaffProject(deletedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно удален!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const addStructure = (structure) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const addedStructure = await api.staff.addStructure(structure)
			dispatch(staffActions.setStaffProject(addedStructure))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editStructure = (structure) => {
	return async (dispatch, getState) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const editStructureResponse = await api.staff.editStructure(structure)
			// Проверка на ошибку в editStructure
			if (Object.keys(editStructureResponse).length !== 0) {
				throw new Error(editStructureResponse)
			}
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: staff.staff.data.childs.map((child) => ({
					...child,
					childs: updateChilds(child.childs, structure.row.externalId, structure.row),
				})),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delStructure = (deleteStructure) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const staffProject = await api.staff.removeStructure(deleteStructure)
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const addWorkPlace = (workplace, multiCreate) => {
	return async (dispatch, getState) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const addedWorkplace = await api.staff.addWorkPlace(workplace, multiCreate)
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateChildWorkPlaces(staff.staff.data.childs, workplace.structureExternalId, addedWorkplace),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editWorkPlace = (workplace) => {
	return async (dispatch, getState) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const editWorkplaceResponse = await api.staff.editWorkPlace(workplace)
			// Проверка на ошибку в editWorkPlace
			if (Object.keys(editWorkplaceResponse).length !== 0) {
				throw new Error(editWorkplaceResponse)
			}
			const { staff } = getState()

			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateEditChildWorkPlaces(staff.staff.data.childs, workplace.structureExternalId, workplace.row),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delWorkPlace = (deleteWorkPlace) => {
	return async (dispatch, getState) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const response = await api.staff.removeWorkPlace(deleteWorkPlace)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				// Здесь мы используем нашу функцию удаления
				childs: deleteFromChildWorkPlaces(
					staff.staff.data.childs,
					deleteWorkPlace.structureExternalId,
					deleteWorkPlace.delExternalIds[0]
				),
			}

			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getXML = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const xmlData = await api.staff.getXml()
			dispatch(staffActions.setStaffXml(xmlData))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const sendSignedStaff = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			const signedStaff = await api.staff.sendSignedStaff({ data })
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Подписанное штатное расписания успешно отправлено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
			dispatch(staffActions.setStaffXml(null))
		}
	}
}
