import React from 'react'
import styles from './Button.module.scss'
export const Button = ({ children, green, img, chevronUp, ...props }) => {
	return (
		<div className={`${styles.Button} ${green ? styles.Button__green : styles.Button__red}`} {...props}>
			<div className={`${img ? styles.Button__left_button : null}`}>
				{children}
				<span></span>
				{img ? <img src={img} /> : null}
			</div>
		</div>
	)
}
