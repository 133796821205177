import * as types from './types'

export const initialEColContract = {
	bin: '',
	enterpriseName: '',
	enterpriseNameKaz: '',
	collectiveContractNumber: '',
	dateFrom: '',
	dateTo: '',
	electronic: true,
	overtimePay: '',
	holidayPay: '',
	nightTimePay: '',
	combinationPositionPay: '',
	termCompensationPay: '',
	wayCompensationPay: '',
	wageIndexProcedure: '',
	firstCategoryRate: '',
	industryAgreementCoefficient: '',
	minCoefficient: '',
	maxCoefficient: '',
	firstGradeMin: '',
	firstGradeMax: '',
	secondGradeMin: '',
	secondGradeMax: '',
	thirdGradeMin: '',
	thirdGradeMax: '',
	fourthGradeMin: '',
	fourthGradeMax: '',
	fifthGradeMin: '',
	fifthGradeMax: '',
	sixthGradeMin: '',
	sixthGradeMax: '',
	seventhGradeMin: '',
	seventhGradeMax: '',
	eighthGradeMin: '',
	eighthGradeMax: '',
	workTime: [],
	relaxTime: [],
	securityFundingVolume: '',
	fundingAmount: '',
	dcolContractEnterpriseTypeCode: '',
	dcolContractOwnershipTypeCode: '',
	unions: [
		{
			representativeDtos: [
				{
					representativeFIO: '',
					representativeIin: '',
				},
			],
			unionBin: '',
			unionName: '',
			unionNameKaz: '',
			unionTypeCode: '',
		},
	],
	extraGuarantee: [
		{
			code: '',
			value: '',
		},
	],
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	eColContract: {
		data: initialEColContract,
		isLoading: false,
		isSuccess: false,
		error: null,
		eHtml: null,
		eHtmlSuccess: null,
		currentEcd: null,
		currentEcdEdit: null,
		isSendSuccess: false,
		eColXml: null,
	},
	eColContracts: [],
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
}

export const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_E_COL_CONTRACT:
			return { ...state, eColContract: { ...state.eColContract, data: payload } }
		case types.SET_E_COL_CONTRACT_LOADING:
			return { ...state, eColContract: { ...state.eColContract, isLoading: payload } }
		case types.SET_E_COL_CONTRACT_SUCCESS:
			return { ...state, eColContract: { ...state.eColContract, isSuccess: payload } }
		case types.SET_E_COL_CONTRACT_ERROR:
			return { ...state, eColContract: { ...state.eColContract, error: payload } }
		case types.SET_E_COL_CONTRACTS:
			return {
				...state,
				eColContracts: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_INITIAL_E_COL_CONTRACT:
			return { ...state, eColContract: initialEColContract }
		case types.SET_E_COL_XML:
			return { ...state, eColContract: { ...state.eColContract, eColXml: payload } }
		case types.SET_E_HTML:
			return { ...state, eColContract: { ...state.eColContract, eHtml: payload } }
		case types.SET_E_HTML_SUCCESS:
			return { ...state, eColContract: { ...state.eColContract, eHtmlSuccess: payload } }
		case types.RESET_E_COL_CONTRACT:
			return { ...state, eColContract: { ...state.eColContract, data: null } }
		case types.SET_CURRENT_ECD:
			return { ...state, eColContract: { ...state.eColContract, currentEcd: payload } }
		case types.SET_CURRENT_ECD_EDIT:
			return { ...state, eColContract: { ...state.eColContract, currentEcdEdit: payload } }
		case types.RESET_CURRENT_ECD:
			return { ...state, eColContract: { ...state.eColContract, currentEcd: null, currentEcdEdit: null } }
		case types.SET_E_COL_CONTRACT_SEND_SUCCESS:
			return { ...state, eColContract: { ...state.eColContract, isSendSuccess: payload } }
		default:
			return state
	}
}
