import React from 'react'
import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { notificationsSelectors } from '@/entity/notifications/index'
import { useHistory, useParams } from 'react-router-dom'
import './mobile.css'
import PreHeader from '@/components/home/components/PreHeader'

export const NotificationsMobileDetailsPage = () => {
	const { id } = useParams()
	const history = useHistory()
	const notifyData = useSelector(notificationsSelectors.notifications)

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={'notifications'}>
					<div id="return_button" style={{ marginTop: '17px' }} onClick={() => history.goBack()}>
						{'< '}НАЗАД
					</div>
					<div style={{ marginTop: '17px', fontSize: '24px', fontWeight: 'bold', color: '#5B6880' }}>Уведомление</div>
					<div style={{ marginTop: '8px', fontSize: '18px', fontWeight: 'bold', color: '#1D293F' }}>
						{notifyData?.content.find((x) => x.id == id)?.notifType?.nameRu}
					</div>
					<div style={{ marginTop: '17px', fontSize: '16px', fontWeight: 'bold', color: '#AAB8D1' }}>
						{notifyData?.content.find((x) => x.id == id)?.createDate.substring(0, 10)}{' '}
						{notifyData?.content.find((x) => x.id == id)?.createDate.substring(11, 16)}
					</div>
					<div
						style={{
							marginTop: '17px',
							marginRight: '32px',
							fontSize: '16px',
							fontWeight: 'bold',
							color: '#5B6880',
							textAlign: 'justify',
						}}
					>
						{notifyData?.content.find((x) => x.id == id)?.notifRu}
					</div>
					<div style={{ marginTop: '28px' }}>
						<Button size="large" block style={{ height: '40px' }}>
							Открыть доп соглашение
						</Button>
						<Button size="large" block style={{ marginTop: '17px', height: '40px' }} onClick={() => history.goBack()}>
							Закрыть
						</Button>
					</div>
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}
