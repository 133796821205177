import React, { useCallback, useRef } from 'react'
import styles from './Notice.module.scss'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { employerProfileThunks } from '@features/employerProfile/model'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions } from '@/entity/notifications/actions'
import { setToLocalStorage } from '@/shared'
import { notificationsSelectors, userActions } from '@/entity'
import { Title } from '@/ui'
import { LoadingBlocker } from '@/components/Loader'

const Notice = ({ notify, setIsOpenWarning, profile, employer }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const optionsLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const refValue = useRef(null)
	const isLoading = useSelector(notificationsSelectors.isLoading)

	const handleCompanySelect = useCallback((item) => {
		setToLocalStorage('activeRole', employer ? 'ROLE_EMPLOYER' : 'ROLE_EMPLOYEE')
		dispatch(userActions.setActiveRole(employer ? 'ROLE_EMPLOYER' : 'ROLE_EMPLOYEE'))
		if (employer) {
			if (!profile.email) {
				return setIsOpenWarning(true)
			}
			setIsOpenWarning(false)
			dispatch(employerProfileThunks.getCompanyForBin(item.bin))
		}
		if (window.document.body.clientWidth < 1001) {
			history.push(`/notifications/${item.externalId}`, { refValue: item })
		} else {
			dispatch(actions.toggleSuccess(true))
			history.push('/notifications', { refValue: item })
		}
	}, [])
	const blueArray = ['emaket_22', 'emaket_21', 'emaket_4', 'emaket_3', 'emaket_20']
	return (
		<div className={styles.contains}>
			<p>{t('unread_notifications')}</p>
			{!isLoading ? (
				<div className={styles.pseudoTable}>
					{notify?.length > 0 ? (
						<>
							{notify?.map((item) => (
								<div
									key={item.externalId}
									className={`${blueArray.includes(item.notifType.code) ? styles.harm : ''} ${styles.pseudoTh}`}
									ref={refValue}
									onClick={() => handleCompanySelect(item)}
								>
									{employer ? (
										<p>
											{item?.[optionsLang]} ({t('bin_iin')} {' ' + item.bin})
										</p>
									) : (
										<p>{profile.lastname + ' ' + (profile.firstname || '') + ' ' + (profile.middlename || '')}</p>
									)}
									<span>
										{item?.eventDate.substring(0, 10) || ''} {item?.eventDate.substring(11, 16) || ''}
										<p>{item.notifType?.[optionsLang]}</p>
									</span>
								</div>
							))}
						</>
					) : (
						<div className={styles.centered}>
							<Title>{t('absence_notifications')}</Title>
						</div>
					)}
				</div>
			) : (
				<LoadingBlocker />
			)}
		</div>
	)
}

export default Notice
