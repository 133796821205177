import { request } from '@/shared/api/request'

export const positionEvaluation = {
	//deprecated. Do not use
	list: (data, params) => {
		return request({ url: 'staff/evaluation/getPage', method: 'GET', params: params, withCredentials: true })
	},
	initialData: () => {
		return request({ url: 'staff/evaluation/getInfoForEvaluationForm', method: 'GET', withCredentials: true })
	},
	calculate: (payload) => {
		return request({ url: 'staff/evaluation/calculate', data: payload, method: 'POST', withCredentials: true })
	},
	generatePdf: (payload) => {
		return request({ url: 'staff/evaluation/generatePDF', data: payload, method: 'POST', withCredentials: true, responseType: 'blob' })
	},
}
