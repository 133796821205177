export const dashBoardInformation = (state) => state.main.dashBoardInformation

export const isSuccess = (state) => state.main.isSuccess

export const isSuccessSend = (state) => state.main.isSuccessSend

export const isSuccessPhone = (state) => state.main.isSuccessPhone

export const regionalPowerContact = (state) => state.main.regionalPowerContact

export const userByBin = (state) => state.main.userByBin

export const documentTypes = (state) => state.main.documentTypes
