import { useTranslation } from 'react-i18next'
import { Field, Button } from '@/ui'
import styles from './SecurityModal.module.scss'
import { Formik, Form, Field as FormikField } from 'formik'
import * as Yup from 'yup'
import { Box } from '@mui/system'
import { Modal } from '@/components/Modal'
import { Cross } from '@app/images'
import React from 'react'

const ValidationSchema = Yup.object().shape({
	iin: Yup.string()
		.required('required')
		.matches(/^[0-9]+$/, 'not_correct_iin')
		.min(12, 'min_12_length')
		.max(12, 'max_12_length'),
})

export const SecurityModal = ({ onClose, onSubmit, flData, onSignAndSend }) => {
	const { t } = useTranslation()

	return (
		<Modal onClose={onClose}>
			<div className={styles.modal__wrapper}>
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<span onClick={onClose}>
						<div className={styles.cross}>
							<img src={Cross} alt="cross" />
						</div>
					</span>
				</Box>
				<div className={styles.content}>
					<div className={styles.title}>{t('update_iin')}</div>
					<Formik
						validationSchema={ValidationSchema}
						initialValues={{ iin: '', fio: '' }}
						onSubmit={onSubmit}
						enableReinitialize
					>
						{({ isValid }) => (
							<Form>
								<Box
									display="flex"
									alignItems="flex-end"
									justifyContent="space-between"
									className={styles.iin__field__wrapper}
								>
									<FormikField name="iin">
										{({ field, meta: { touched, error } }) => (
											<Field
												placeholder={t('enter_12_digits')}
												type="text"
												{...field}
												error={touched && error ? t(error) : ''}
												label={t('new_epmloyee_iin')}
											/>
										)}
									</FormikField>
									<Button disabled={!isValid} type="submit">
										{t('find')}
									</Button>
								</Box>
								<Field
									placeholder={t('auto_filled')}
									type="text"
									onChange={() => {}}
									disabled
									label={t('employee_fio')}
									value={flData?.fullNamePerson || ''}
								/>
							</Form>
						)}
					</Formik>

					<Box className={styles.buttons__wrapper} display="flex" alignItems="center" justifyContent="space-between">
						<div className={styles.cancel__button} onClick={onClose}>
							{t('cancel')}
						</div>
						<Button onClick={onSignAndSend} disabled={!flData}>
							{t('sign_and_send')}
						</Button>
					</Box>
				</div>
			</div>
		</Modal>
	)
}
