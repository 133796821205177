import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import paper from '@/assets/home/PaperClip.svg'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import styles from './ConfirmDocument.module.scss'
import { getBase64 } from './utils'
import { useError } from '@/shared'
import { Info } from '@app/icons'
import { Tooltip } from 'antd'

export const ConfirmDocument = ({ getDocument, maxSize = 10485760, getErrorSize }) => {
	const { t } = useTranslation()
	const { getInputProps, acceptedFiles, fileRejections } = useDropzone({
		noClick: true,
		maxSize: maxSize,
		accept: ['.jpg', '.jpeg', '.png', '.pdf'],
	})
	const [files, setFiles] = useState([])
	const [docList, setDocList] = useState([])
	const [errorSize, setErrorSize] = useState(false)
	const { errors, setError } = useError()
	useEffect(() => {
		setFiles([...files, ...acceptedFiles])
		if (fileRejections?.length > 0) {
			const error = fileRejections[0]?.errors?.filter(({ code }) => code === 'file-invalid-type').length
				? t('wrong_type_photo_error')
				: t('labor_records.max_size_photo_error')
			setError('image', error)
		}
	}, [acceptedFiles, fileRejections])

	useEffect(() => {
		if (files.length > 0) {
			getBase64(files).then(function (arr) {
				setDocList(
					arr.map((file) => ({
						docData: file.base64StringFile,
						fileName: file.fileName,
						extension: file.fileName.substring(file.fileName.lastIndexOf('.') + 1),
					}))
				)
			})
		} else {
			setDocList([])
		}
		const filesSizeByte = files.reduce((acc, file) => file.size + acc, 0)
		if (filesSizeByte >= maxSize) {
			setErrorSize(true)
			getErrorSize(true)
		} else {
			setErrorSize(false)
			getErrorSize(false)
		}
	}, [files])

	useEffect(() => {
		getDocument(docList)
	}, [docList, getDocument])

	const filesSize = useMemo(() => (files.reduce((acc, file) => file.size + acc, 0) / 1e6).toFixed(1), [files])

	const hint = useMemo(() => t('labor_records.max_size_all'), [t])

	return (
		<div className={styles.wrapper}>
			<ul className={styles.file}>
				{files.map(({ name }, idx) => (
					<li key={name}>
						<div>
							<img src={doc} />
							<span>{name}</span>
						</div>
						<div
							className={styles.file__delete}
							onClick={() => {
								const newFiles = [...files]
								newFiles.splice(idx, 1)
								setFiles(newFiles)
							}}
						>
							<img src={trash} />
							<span>{t('remove')}</span>
						</div>
					</li>
				))}
			</ul>
			{errors.image && <p className={styles.error}>{errors.image}</p>}
			{filesSize > 0.0 ? (
				<div className={styles.size}>
					{' '}
					<Tooltip
						placement="right"
						color="white"
						overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
						title={hint}
					>
						{t('labor_records.full_size') + ' (' + filesSize + ' МБ) '}
						<Info className={styles.hintIcon} />
					</Tooltip>
				</div>
			) : null}
			{errorSize ? (
				<p className={styles.error}>{t('labor_records.error_size')}</p>
			) : (
				<div className={styles.formBtn}>
					<div className="greenBtn">
						<label className="input-file">
							<input type="file" {...getInputProps()} accept="image/jpeg,image/png,application/pdf" />
							<div className="custom-file">
								<img src={paper} />
								<span>{t('attache_file')}</span>
							</div>
						</label>
					</div>
				</div>
			)}
		</div>
	)
}
