export const getDeclension = (count) => {
	const absoluteCount = Math.abs(count)
	const lastDigit = absoluteCount % 10
	const lastTwoDigits = absoluteCount % 100
	if (lastTwoDigits > 10 && lastTwoDigits < 20) {
		return 'day1'
	}
	if (lastDigit === 1) {
		return 'day'
	}
	if (lastDigit >= 2 && lastDigit <= 4) {
		return 'day2'
	}
	return 'day1'
}
