import React from 'react'
import styles from './style.module.scss'

const RadioStaff = ({ label, id, name, checked, onChange, ...props }) => {
	return (
		<>
			<input
				className={styles.radio}
				type="radio"
				id={id}
				name={name}
				onChange={onChange}
				value={id}
				checked={checked}
				{...props}
			/>
			<label htmlFor={id}>{label}</label>
		</>
	)
}

export default RadioStaff
