import { DialogTitle, DialogContent, Dialog, Box } from '@mui/material'
import styles from '../TerminateElectronic.module.scss'
import { useTranslation } from 'react-i18next'
import { Button as AppButton } from '@/ui'
import CloseButton from '@/components/CloseButton/CloseButton'

export const Terminate = ({ onClose }) => {
	const { t } = useTranslation()
	return (
		<Dialog scroll={'paper'} classes={{ paper: styles.modal__dialog }} onClose={onClose} open={true}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box className={styles.modal__text}>{t('terminate_application_sended')}</Box>

				<form onSubmit={onClose}>
					<Box display="flex" alignItems="center" justifyContent="center">
						<AppButton type="submit">Ок</AppButton>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	)
}
