import { ProfileTemplate } from '@/widgets'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { profUnionsSelectors, profUnionsThunks } from '@/entity'
import { MyUnionsTable } from '@features/profUnions/table/MyUnionsTable'
import { View } from '@/features'

export const MainUnionsPage = ({ buttonClassName = '' }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const myUnions = useSelector(profUnionsSelectors.myUnions)
	const tradeUnion = useSelector(profUnionsSelectors.tradeUnion)
	const [myTradeUnion, setMyTradeUnion] = useState()
	let isTradeUnionNotEmpty = tradeUnion?.externalId?.length > 0
	const [actions, setActions] = useState({
		view: false,
		join: false,
	})

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		if(action === "view" && value) {
			setMyTradeUnion(row)
		}
		if(action === "view" && !value) {
			setMyTradeUnion(undefined)
		} 
	}

	useEffect(() => {
		dispatch(profUnionsThunks.getOptions())
		dispatch(profUnionsThunks.getByOrgContracts())
	}, [])

	const Close = () => {
		onToggleAction('view', false)
		onToggleAction('join', false)
	}

	return (
		<ProfileTemplate title={'profUnions.name'}>
			<Title>{t('profUnions.tradeUnions')}</Title>
			<MyUnionsTable myUnions={myUnions} onToggleAction={onToggleAction}></MyUnionsTable>
			{actions.view && isTradeUnionNotEmpty ? (
				<View myTradeUnion={myTradeUnion} onClose={Close} onToggleAction={onToggleAction} tradeUnion={tradeUnion} myUnion={true}></View>
			) : null}
		</ProfileTemplate>
	)
}
