function plural(word, num) {
	const forms = word.split('_')
	return num % 10 === 1 && num % 100 !== 11
		? forms[0]
		: num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
		? forms[1]
		: forms[2]
}
export const relativeTimeWithPlural = (number, key) => {
	const format = {
		m: 'месяц_месяца_месяцев',
		y: 'год_года_лет',
	}
	return number + ' ' + plural(format[key], +number)
}
