import { Sign as SignIcon, View as ViewIcon } from '@app/images'
import CloseIcon from '@/assets/pages/personalAffairs/close.svg'

export const headCells = [
	{
		id: 'number',
		numeric: true,
		label: 'personal_affairs.table.field_number',
	},
	{
		id: 'employerName',
		numeric: false,
		label: 'personal_affairs.table.employer_name',
	},
	{
		id: 'requestDate',
		numeric: false,
		label: 'personal_affairs.table.request_date',
	},
	{
		id: 'consentDate',
		numeric: false,
		label: 'personal_affairs.table.consent_date',
	},
	{
		id: 'requestStatus',
		numeric: false,
		label: 'personal_affairs.table.request_status',
	},
	{
		id: null,
		numeric: false,
		label: 'contracts_tab.action',
	},
]

export const actions = [
	{ icon: ViewIcon, title: 'actions.view', action: 'view' },
	{ icon: SignIcon, title: 'actions.sign', action: 'sign' },
	{ icon: CloseIcon, title: 'actions.reject', action: 'reject' },
]
