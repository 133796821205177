import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from '@features/contracts/actions/recoverWarning/RecoverWarningModal.module.scss'
import { Warning } from '@app/icons'

export const RecoverReject = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			processButtonTitle={'close'}
			cancelButtonTitle={''}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Warning />
				<p>{t('contracts_tab.recover.reject_text')}</p>
			</div>
		</ConfirmModal>
	)
}
