import { useTranslation } from 'react-i18next'
import styles from './Table.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formsSelectors, formsThunks } from '@/entity/forms'
import { colors } from '@pages/Forms/Tabs/utils'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import { CheckList } from '@pages/Forms/Tabs/component/Checklist/Checklist'
import { headCells } from './table.headings'
import { HrPagination } from '@/components/Pagination'

export const Table = ({ getData }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isLoading = useSelector(formsSelectors.isLoading)
	const isSuccess = useSelector(formsSelectors.isSuccess)
	const { content: checks, totalPages, totalElements, numberOfElements } = useSelector(formsSelectors.checks)
	const selectedCheck = useSelector(formsSelectors.selectedCheck)
	const lang = getCurrentLanguage()

	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)

	const getChecksInfo = useCallback((id) => id && dispatch(formsThunks.getCheckForCompany(id)), [dispatch])

	const handleChangePage = (e, _page) => {
		setPage(_page - 1)
		if (_page - 1 < 0) {
			return getData(0, size)
		}
		getData(_page - 1 || 0, size)
	}

	const handleChangeRowsPerPage = (_size) => {
		setSize(_size)
		if (page - 1 < 0) {
			return getData(0, _size)
		}
		getData(page - 1 || 0, _size)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'}>
				<td>{row.createDate ? row.createDate : '-'}</td>
				<td>
					<span style={{ color: colors[row.result?.code?.toLowerCase()] }}>
						{row.result?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
					</span>
				</td>
				<td onClick={() => getChecksInfo(row.id)}>{t('history_check.watch_action')}</td>
			</tr>
		),
		[getChecksInfo, lang, t]
	)

	const tableBody = useMemo(
		() => !!checks && checks.map((row, index) => getTableRow(row, index)),
		[checks, getTableRow]
	)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !checks?.length && (
						<div className={styles.tableLoading}>{t('history_check.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('history_check.has_error')}</div>}
				</td>
			</tr>
		),
		[checks?.length, isLoading, isSuccess, t]
	)

	return (
		<>
			<table className={styles.scroll}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!checks?.length ? tableBody : tableLoading}</tbody>
			</table>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 30, label: '30' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
				]}
				rowsPerPage={size}
				numberOfElements={numberOfElements}
				totalElements={totalElements}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={page}
			/>
			{selectedCheck?.id && <CheckList />}
		</>
	)
}
