import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { api, useDigitalSign } from '@/shared'
import { ConfirmModal, Title } from '@/ui'
import { LoadingBlocker } from '@/components/Loader'
import styles from './AddEmployerFl.module.scss'
import { userSelectors, userThunks } from '@/entity'

export const AddEmployerFl = ({ onClose, onCloseSuccess }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { successPayload, onSign, onClearPayload } = useDigitalSign()
	const isLoading = useSelector(userSelectors.isLoading)

	useEffect(() => {
		if (successPayload) {
			const base64 = Buffer.from(successPayload).toString('base64')
			dispatch(userThunks.verification({ xmlData: base64 }, true))
			onClearPayload()
			onCloseSuccess()
		}
	}, [successPayload])

	const getXmlEmployerFl = async () => {
		onSign(await api.profile.getXML())
	}

	return isLoading ? (
		<LoadingBlocker />
	) : (
		<ConfirmModal
			onProcess={getXmlEmployerFl}
			onCancel={onClose}
			cancelButtonTitle={'no_cancel'}
			processButtonTitle={'yes_sign'}
			hideBackButton
			priority={2}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<div className={styles.content__title}>
					<Title>{t('add_fl_employer')}</Title>
				</div>
				<div className={styles.content__body}>{t('add_fl_employer_text')}</div>
			</div>
		</ConfirmModal>
	)
}
