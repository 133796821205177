import { Link } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { mainThunks } from '@/app/store/main'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Logo } from '@/ui/atoms/logo'
import styles from './style.module.scss'
import { getDashboardInformation } from '@/shared/api/rest'
import LangFooter from '@/components/LangFooter'
import HeaderButton from '@/components/HeaderButton/HeaderButton'
import { getCurrentLanguage } from '@/i18next'
import { EcoSystemEnbek } from '@app/icons/ecoSystemEnbek'
import { SystemEnbekPopup } from '@features/systemEnbekPopup/SystemEnbekPopup'
import { getFromLocalStorage, useClickOutside } from '@/shared'
import { NotificationHome } from './NotificationHome/NotificationHome'

const Header = (props) => {
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const [contractCount, setContractCount] = useState()
	const [organizationCount, setOrganizationCount] = useState()
	const [employeeCount, setEmployeeCount] = useState()
	const { t } = useTranslation()
	const [showPopup, setShowPopup] = useState(false)
	const ref = useRef(null)
	//Модалка
	const [enableModal, setEnableModal] = useState()
	useMemo(() => setEnableModal(process.env.REACT_APP_MODAL_INFO_HOME), [])
	const [isOpenInfoHome, setIsOpenInfoHome] = useState(false)
	const isHomeCheck = getFromLocalStorage('isHomeCheck')
	useEffect(() => {
		if (!isHomeCheck && enableModal === '1') {
			setIsOpenInfoHome(true)
		} else {
			setIsOpenInfoHome(false)
		}
	}, [enableModal])

	const handlePopupToggle = useCallback(() => {
		setShowPopup((show) => !show)
	}, [])

	useEffect(() => {
		;(async function () {
			try {
				const response = await getDashboardInformation()
				if (response?.contractCount.toString().length > 6) {
					setContractCount(
						(response?.contractCount / 1000000).toLocaleString('ru-RU', { maximumSignificantDigits: 2 }) + ' млн'
					)
				} else {
					setContractCount(response?.contractCount.toLocaleString())
				}
				if (response?.organizationCount.toString().length > 6) {
					setOrganizationCount(
						(response?.organizationCount / 1000000).toLocaleString('ru-RU', { maximumSignificantDigits: 2 }) + ' млн'
					)
				} else {
					setOrganizationCount(response?.organizationCount.toLocaleString())
				}
				if (response?.employeeCount.toString().length > 6) {
					setEmployeeCount(
						(response?.employeeCount / 1000000).toLocaleString('ru-RU', { maximumSignificantDigits: 2 }) + ' млн'
					)
				} else {
					setEmployeeCount(response?.employeeCount.toLocaleString())
				}
			} catch (error) {
				if (!error.response) {
					// Сетевая ошибка
					console.error('Error: Network Error')
				} else {
					// Http ошибка (бэд статус)
					console.error(`Error: ${error.response.status}`)
				}
			}
		})()
	}, [])

	useEffect(() => {
		dispatch(mainThunks.getDashboardInformation())
	}, [dispatch])

	useClickOutside(ref, showPopup, () => setShowPopup(false))

	return (
		<>
			{isOpenInfoHome && (
				<NotificationHome
					handleProcess={setIsOpenInfoHome.bind(null, false)}
					handleCancel={setIsOpenInfoHome.bind(null, false)}
				></NotificationHome>
			)}
			<div className="container header-wrapper absolute-header">
				{props.appButton ? <div className="marginTop"></div> : null}
				<div
					onClick={() => {
						props.setSidebar(true)
					}}
					className="gamburger mobile fixed"
				>
					<span />
					<span />
					<span />
				</div>
				<Link to={'/'}>
					<span className={styles.logo}>
						<Logo />
					</span>
				</Link>
				<div className={styles.enbekSystem} ref={ref}>
					<div className={styles.popupToggle} onClick={handlePopupToggle}>
						<EcoSystemEnbek />
					</div>
					{showPopup ? <SystemEnbekPopup /> : null}
				</div>
				<ul className="menu">
					<li>
						<Link to={'/about-project'} className={'link'}>
							{t('about_project')}
						</Link>
					</li>
					<li>
						<Link to={'/for-employers'} className={'link'}>
							{t('for_employer')}
						</Link>
					</li>
					<li>
						<Link to={'/for-workers'} className={'link'}>
							{t('for_worker')}
						</Link>
					</li>
					<li>
						<Link to={'reference-information/1'} className={'link'}>
							{t('reference_information')}
						</Link>
					</li>
					<li>
						<LangFooter header />
					</li>
				</ul>
				<HeaderButton lang={lang} />
			</div>
			<div className="header slider-1">
				<div className="container">
					<div className="header-offer">
						<h1 className={styles.slide}>{t('intro_sub_title')}</h1>
					</div>
				</div>
			</div>
			<div className="header-absolute__block">
				<div className="header-absolute__block-item">
					<p className={styles.pDashboard}>{contractCount}</p>
					<span>{t('count_employment_contracts')}</span>
				</div>
				<div className="header-absolute__block-item">
					<p className={styles.pDashboard}>{organizationCount}</p>
					<span>{t('count_employers')}</span>
				</div>
				<div className="header-absolute__block-item">
					<p className={styles.pDashboard}>{employeeCount}</p>
					<span>{t('count_workers')}</span>
				</div>
			</div>
		</>
	)
}

export default Header
