import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setColContract: (payload) => ({
		type: types.SET_COL_CONTRACT,
		payload,
	}),
	setColContractLoading: (payload) => ({
		type: types.SET_COL_CONTRACT_LOADING,
		payload,
	}),
	setColContractSuccess: (payload) => ({
		type: types.SET_COL_CONTRACT_SUCCESS,
		payload,
	}),
	setColContracts: (payload) => ({
		type: types.SET_COL_CONTRACTS,
		payload,
	}),
	setColXML: (payload) => ({
		type: types.SET_COL_XML,
		payload,
	}),
	resetColXml: () => ({
		type: types.RESET_COL_XML,
	}),
}
