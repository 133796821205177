import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	toggleLoadingBin: (payload) => ({
		type: types.TOGGLE_LOADING_BIN,
		payload,
	}),
	toggleSuccessBin: (payload) => ({
		type: types.TOGGLE_SUCCESS_BIN,
		payload,
	}),
	toggleLoadingChiefFio: (payload) => ({
		type: types.TOGGLE_LOADING_CHIEF_FIO,
		payload,
	}),
	toggleSuccessChiefFio: (payload) => ({
		type: types.TOGGLE_SUCCESS_CHIEF_FIO,
		payload,
	}),
	toggleLoadingBinEmployer: (payload) => ({
		type: types.TOGGLE_LOADING_BIN_EMPLOYER,
		payload,
	}),
	toggleSuccessBinEmployer: (payload) => ({
		type: types.TOGGLE_SUCCESS_BIN_EMPLOYER,
		payload,
	}),
	toggleLoadingBinParent: (payload) => ({
		type: types.TOGGLE_LOADING_BIN_PARENT,
		payload,
	}),
	toggleSuccessBinParent: (payload) => ({
		type: types.TOGGLE_SUCCESS_BIN_PARENT,
		payload,
	}),
	setProfUnions: (payload) => ({
		type: types.SET_PROF_UNIONS,
		payload,
	}),
	setChairmanName: (payload) => ({
		type: types.SET_CHAIRMAN_NAME,
		payload,
	}),
	setProfUnionsXml: (payload) => ({
		type: types.SET_PROF_UNIONS_XML,
		payload,
	}),
	resetXml: () => ({
		type: types.RESET_XML,
	}),
	setProfUnionsList: (payload) => ({
		type: types.SET_PROF_UNIONS_LIST,
		payload,
	}),
	setPersonsList: (payload) => ({
		type: types.SET_PERSONS,
		payload,
	}),
	setPersonsLoading: (payload) => ({
		type: types.SET_PERSONS_LOADING,
		payload,
	}),
	setSearchChiefFio: (payload) => ({
		type: types.SET_SEARCH_CHIEF_FIO,
		payload,
	}),
	setSearchName: (payload) => ({
		type: types.SET_SEARCH_NAME,
		payload,
	}),
	setSearchParentName: (payload) => ({
		type: types.SET_SEARCH_PARENT_NAME,
		payload,
	}),
	setMembersProfUnions: (payload) => ({
		type: types.SET_PROF_MEMBERS,
		payload,
	}),
	setNewMembersProfUnion: (payload) => ({
		type: types.SET_NEW_MEMBERS,
		payload,
	}),
	setMemberStatus: (iin, status) => ({
		type: types.SET_MEMBER_STATUS,
		payload: { iin, status },
	}),
	setSearchEmployerName: (payload) => ({
		type: types.SET_SEARCH_EMPLOYER_NAME,
		payload,
	}),
	reset: () => ({
		type: types.RESET,
	}),
	setMyUnions: (payload) => ({
		type: types.SET_MY_UNIONS,
		payload,
	}),
}
