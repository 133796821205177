import styles from './Table.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { formatDate } from '@/utils'

export const Table = ({ headCells, data }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [tableState, setTableState] = useState({})
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const toggleTableRow = (index) => setTableState((prev) => ({ ...prev, [index]: !prev[index] }))

	const getTableRow = useCallback(
		(row, index) => {
			const lastChild = row.contractPeriodData ? row.contractPeriodData[0] : null

			return (
				<>
					<tr key={index + '-table-row'} onClick={() => toggleTableRow(index)}>
						<td>
							<KeyboardArrowDownIcon className={tableState[index] && styles.arrowIcon_active} />
						</td>
						<td>{row.name || '-'}</td>
						<td>{row.bin || '-'}</td>
						<td>{formatDate(row.beginDate)}</td>
						<td>{formatDate(row.endDate)}</td>
						<td>{lastChild?.dposition[optionLang] || '-'}</td>
						<td>{lastChild?.establishedPost || '-'}</td>
					</tr>
					{tableState[index] &&
						row.contractPeriodData?.map((item) => (
							<tr key={index + '-child-row'}>
								<td />
								<td>{language === 'rus' ? item.nameRu : item.nameKz}</td>
								<td>{row.bin}</td>
								<td>{formatDate(item.beginDate)}</td>
								<td>{formatDate(item.endDate)}</td>
								<td>{item.dposition[optionLang] || '-'}</td>
								<td>{item.establishedPost || '-'}</td>
							</tr>
						))}
					{lastChild && tableState[index] && row?.termReasions && (
						<tr>
							<td />
							<td colSpan={6}>
								{t('personal_affairs.terminal_date')}
								{formatDate(row.endDate)}
							</td>
						</tr>
					)}
					{lastChild &&
						tableState[index] &&
						row?.termReasions &&
						row?.termReasions.map((item) => (
							<tr key={index + '-child-row'}>
								<td />
								<td colSpan={6}>
									{t('personal_affairs.termination_reason')}
									{language === 'rus' ? item.nameRu : item.nameKz}
								</td>
							</tr>
						))}
				</>
			)
		},
		[t, tableState]
	)

	const tableBody = useMemo(() => !!data && data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				<th />
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</>
	)
}
