import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, connect } from 'formik'
import { Title, Field } from '@/ui'
import { getKatoChildren } from '@/shared/api/rest/shared'
import styles from './Conditions.module.scss'
import { formFieldNames } from '@/entity'
import { filterKato } from '@/entity/handbook/thunks'

const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

export const ContractJobLocation = connect(
	({ countries = [], districts = [], remoteWorkTypes = [], formik: { setFieldValue, values }, isFormDirty }) => {
		const { t } = useTranslation()
		const [regions, setRegions] = useState([])
		const [localities, setLocalities] = useState([])
		const { dremoteWorkCode, dcountryCode, ddistrictCode, dregionCode } = values

		const getRegions = async (code) => {
			const response = await filterKato(code)
			setRegions(response)
			setLocalities([])
		}

		const getLocalities = async (code, onlyLeaves) => {
			const response = await getKatoChildren({ parentCode: code, onlyLeaves })
			setLocalities(response?.content)
		}

		const onDistrictChange = useCallback(
			async (code) => {
				setFieldValue('ddistrictCode', code)
				setFieldValue('dregionCode', null)
				setFieldValue('dlocalityCode', null)
				await getRegions(code)
			},
			[setFieldValue]
		)

		const onRegionChange = useCallback(
			async (code) => {
				setFieldValue('dregionCode', code)
				setFieldValue('dlocalityCode', null)
				await getLocalities(code, true)
			},
			[setFieldValue]
		)

		const onCountryChange = useCallback(
			(code) => {
				setFieldValue(formFieldNames.countryWork, code)
				setFieldValue(formFieldNames.districtWork, null)
				setFieldValue(formFieldNames.regionWork, null)
				setFieldValue(formFieldNames.locality, null)
			},
			[setFieldValue]
		)

		useEffect(() => {
			ddistrictCode && getRegions(ddistrictCode)
			dregionCode && getLocalities(dregionCode)
		}, [])

		return (
			<div className={styles.conditionSection}>
				<Title className={styles.title}>{t('change_work_country')}</Title>
				<div className={styles.col}>
					<div className={styles.row}>
						<div className="flex-1">
							<FormikField name="dremoteWorkCode">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="select"
										label={t('contract.info.remote_job')}
										placeholder={t('choose_from_directory')}
										options={remoteWorkTypes}
										error={(touched || isFormDirty) && error}
										isRequired
										{...field}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1">
							<FormikField name="dcountryCode">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="selectSearch"
										label={t('contract.info.work_country')}
										placeholder={t('choose_from_directory')}
										options={countries}
										error={(touched || isFormDirty) && error}
										isRequired={dremoteWorkCode !== '1'}
										{...field}
										onChange={onCountryChange}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1">
							<FormikField name="ddistrictCode">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="select"
										label={t('contract_work_district')}
										placeholder={t('choose_from_directory')}
										options={districts}
										error={(touched || isFormDirty) && error}
										disabled={dcountryCode !== '398'} // Не активно, если выбрана страна не Казахстан
										isRequired={dcountryCode === '398'} // Обязательное, если страна Казахстан
										{...field}
										onChange={onDistrictChange}
									/>
								)}
							</FormikField>
						</div>
					</div>
					<div className={styles.row}>
						<div className="flex-1">
							<FormikField name="dregionCode">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="select"
										label={t('contract_work_region')}
										placeholder={t('choose_from_directory')}
										options={regions}
										error={(touched || isFormDirty) && error}
										disabled={dcountryCode !== '398'} // Не активно, если выбрана страна не Казахстан
										isRequired={dcountryCode === '398'} // Обязательное, если страна Казахстан
										{...field}
										onChange={onRegionChange}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1">
							<FormikField name="dlocalityCode">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="select"
										label={t('contract_work_locality')}
										placeholder={t('choose_from_directory')}
										options={localities}
										error={(touched || isFormDirty) && error}
										isRequired={dcountryCode === '398' && !citiesInRegionsCodes.includes(ddistrictCode)}
										disabled={
											dcountryCode !== '398' || !localities?.length || citiesInRegionsCodes.includes(ddistrictCode)
										}
										{...field}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1">
							<FormikField name="workingPlace">
								{({ field, meta: { touched, error } }) => (
									<Field
										label={t('contract.info.work_address')}
										placeholder={t('work_address_placeholder')}
										error={(touched || isFormDirty) && error}
										isRequired={dremoteWorkCode !== '1' || !!dcountryCode}
										{...field}
									/>
								)}
							</FormikField>
						</div>
					</div>
				</div>
			</div>
		)
	}
)
