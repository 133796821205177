export const removeFromLocalStorage = (name) => {
	localStorage.removeItem(name)
}

export const removeItemByKeyPartial = (partialKey) => {
	for (let key in localStorage) {
		if (key.includes(partialKey)) {
			localStorage.removeItem(key)
		}
	}
}
