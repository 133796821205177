import { formatDate } from '@/utils'

export const getMilitaryColumns = (t, data, lang) => {
	const {
		nameRuMovu,
		nameKzMovu,
		statusNameRu,
		statusNameKz,
		commentRu,
		commentKz,
		codeMovu,
		nameMovuRu,
		nameMovuKz,
		rankCode,
		rankNameRu,
		rankNameKz,
		isAssignedToLegalEntity,
		bookingNumberLegalEntity,
		militarySpecialtyCode,
		militarySpecialtyNameRu,
		militarySpecialtyNameKz,
		period,
	} = data || {}

	const periodEntries = period
		? period
				.map(({ periodfrom, periodto }) => [
					{ title: t('military.begin'), data: formatDate(periodfrom) },
					{ title: t('military.end'), data: formatDate(periodto) },
				])
				.flat()
		: []

	return [
		{ title: t('military.status'), data: lang === 'rus' ? statusNameRu : statusNameKz },
		{ title: t('military.comment'), data: lang === 'rus' ? commentRu : commentKz },
		{
			title: t('military.local_authority'),
			data: nameMovuRu ? (lang === 'rus' ? nameMovuRu : nameMovuKz) : lang === 'rus' ? nameRuMovu : nameKzMovu,
		},
		{ title: t('military.code_movu'), data: codeMovu },
		{ title: t('military.rank'), data: lang === 'rus' ? rankNameRu : rankNameKz },
		{ title: t('military.rank_code'), data: rankCode },
		{ title: t('military.is_assigned_bin'), data: isAssignedToLegalEntity ? t('yes') : t('no') },
		{ title: t('military.booking_number_bin'), data: bookingNumberLegalEntity },
		{ title: t('military.specialty'), data: lang === 'rus' ? militarySpecialtyNameRu : militarySpecialtyNameKz },
		{ title: t('military.specialty_code'), data: militarySpecialtyCode },
		{ fullWidth: 'military.period' },
		...periodEntries,
	].map((item) => (!!item.data ? item : { ...item, data: '-' }))
}
