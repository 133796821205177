import { useEffect } from 'react'

export const useClickOutside = (ref, isOpen, onAction = () => {}) => {
	useEffect(() => {
		const handleClickOutside = (event) =>
			isOpen && ref.current && !ref.current.contains(event.target) && onAction(event)
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [ref, isOpen, onAction])
}
