import { connect, Field as FormikField } from 'formik'
import { getCurrentLanguage } from '@/i18next'
import { Checkbox, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

export const AdditionalContractPositionField = connect(
	({ index, positions = [], positionsByKnz = [], isView, formik: { setFieldValue, values }, isFormDirty }) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()
		const establishedPosition = useMemo(
			() =>
				values.additionalContracts[index].destablishedPost
					? values.additionalContracts[index].destablishedPost
					: values.additionalContracts[index].establishedPost,
			[index, values.additionalContracts]
		)
		const handleEstablishedPositionChange = useCallback(
			(code) => {
				const pos = positions?.find((item) => item.code === code)
				if (pos) {
					/** Если значение из справочника, то заполняем destablishedPost */
					setFieldValue(`additionalContracts.${index}.destablishedPost`, code)
					setFieldValue(
						`additionalContracts.${index}.establishedPost`,
						lang === 'rus' ? pos[lang || 'nameRu'] : pos[lang || 'nameKz']
					)
				} else {
					/** Если ручной ввод, то заполняем значение establishedPost */
					setFieldValue(`additionalContracts.${index}.destablishedPost`, null)
					setFieldValue(`additionalContracts.${index}.establishedPost`, code)
				}
			},
			[index, lang, positions, setFieldValue]
		)
		return (
			<>
				<div className={'flex-1'} style={{ marginTop: 32 }}>
					{isView ? null : (
						<FormikField name={`additionalContracts.${index}.dpositionIs`}>
							{({ field }) => (
								<Checkbox
									{...field}
									label={t('labor_records.position_by_nkz')}
									hint={t('hint_position_knz')}
									onChange={() => setFieldValue(field.name, !field.value)}
								/>
							)}
						</FormikField>
					)}
					<FormikField name={`additionalContracts.${index}.dposition.code`}>
						{({ field, meta: { touched, error } }) => {
							return (
								<Field
									fieldType="selectSearch"
									placeholder={t('choose_from_directory')}
									options={positionsByKnz}
									error={touched || isFormDirty ? error : ''}
									hideNotFoundError
									{...field}
									label={isView ? t('labor_records.position_by_nkz') : null}
									disabled={!values.additionalContracts[index].dpositionIs || isView}
								/>
							)
						}}
					</FormikField>
				</div>
				<div className={'flex-1'} style={{ marginTop: 32 }}>
					{isView ? null : (
						<FormikField name={`additionalContracts.${index}.establishedPositionIs`}>
							{({ field }) => (
								<Checkbox
									{...field}
									label={t('contract.info.position')}
									hint={t('hint_position')}
									onChange={() => setFieldValue(field.name, !field.value)}
								/>
							)}
						</FormikField>
					)}
					<FormikField
						name={
							values.additionalContracts[index].destablishedPost
								? lang === 'rus'
									? `additionalContracts.${index}.destablishedPost.nameRu`
									: `additionalContracts.${index}.destablishedPost.nameKz`
								: `additionalContracts.${index}.establishedPost`
						}
					>
						{({ field, meta: { touched } }) => {
							return (
								<Field
									fieldType="selectAndInput"
									value={establishedPosition}
									onChange={handleEstablishedPositionChange}
									placeholder={t('position_placeholder')}
									options={positions}
									error={!isView ? (touched || isFormDirty) && !establishedPosition && { key: 'required' } : null}
									{...field}
									label={isView ? t('contract.info.position') : null}
									disabled={!values.additionalContracts[index].establishedPositionIs || isView}
								/>
							)
						}}
					</FormikField>
				</div>
			</>
		)
	}
)
