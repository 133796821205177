import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import paper from '@/assets/home/PaperClip.svg'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import styles from './ConfirmDocument.module.scss'
import { useError } from '@/shared'
import { Tooltip } from 'antd'
import { uploadFile } from '@/shared/api/rest/pension'
import { getSha256 } from '@/utils'

export const ConfirmDocument = ({ getDocument, fileList, maxSize = 13631488 }) => {
	const { t } = useTranslation()
	const { getInputProps, acceptedFiles, fileRejections } = useDropzone({
		noClick: true,
		maxSize: maxSize,
		accept: ['.jpg', '.jpeg', '.png', '.pdf'],
	})
	const [files, setFiles] = useState([])
	const { errors, setError } = useError()
	useEffect(() => {
		if (acceptedFiles.length > 0) onUploadFile(acceptedFiles[0])
		if (fileRejections?.length > 0) {
			const error = fileRejections[0]?.errors?.filter(({ code }) => code === 'file-invalid-type').length
				? t('wrong_type_photo_error')
				: t('pension.payments.form.doc_form.max_file_size_error')
			setError('file', error)
		}
	}, [acceptedFiles, fileRejections])

	const onUploadFile = useCallback(
		async (file) => {
			try {
				const formData = new FormData()
				getSha256(file).then(async (arr) => {
					const isFound = [...fileList, ...files].some((element) => {
						return element.sha256hex === arr
					})
					if (isFound) {
						setError('file', t('pension.payments.form.doc_form.file_added'))
					} else {
						formData.set('file', file)
						const data = await uploadFile(formData)
						setFiles([...files, data])
					}
				})
			} catch (error) {
				console.error('[error uploading file] ', error)
			}
		},
		[files]
	)

	useEffect(() => {
		getDocument(files)
	}, [files, getDocument])
	return (
		<div>
			<ul className={styles.file}>
				{files.map(({ fileName }, idx) => (
					<li key={fileName}>
						<div>
							<img src={doc} />
							<span>{fileName}</span>
						</div>
						<div
							className={styles.file__delete}
							onClick={() => {
								const newFiles = [...files]
								newFiles.splice(idx, 1)
								setFiles(newFiles)
							}}
						>
							<img src={trash} />
							<span>{t('remove')}</span>
						</div>
					</li>
				))}
			</ul>
			{errors.file && <p className={styles.error}>{errors.file}</p>}
			<div className={styles.formBtn}>
				<Tooltip
					placement="right"
					color="white"
					overlayInnerStyle={{ color: '#5B6880', width: '420px' }}
					title={t('pension.payments.hints.attach_file')}
				>
					<div className="greenBtn">
						<label className="input-file">
							<input type="file" {...getInputProps()} accept="image/jpeg,image/png,application/pdf" />
							<div className="custom-file">
								<img src={paper} />
								<span>{t('attache_file')}</span>
							</div>
						</label>
					</div>
				</Tooltip>
			</div>
		</div>
	)
}
