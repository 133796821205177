import React, { useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, Button, Box } from '@mui/material'
import { Button as DialogButton } from '@/ui'
import styles from './EmlpoyerSignModal.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDigitalSign } from '@/shared'
import { LoadingBlocker } from '@/components/Loader'
import CloseButton from '@/components/CloseButton/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { eColContractsSelectors, eColContractsActions, eColContractsThunks } from '@/entity'

export const TradeUnionSignModal = ({ onClose, isOpen, externalId, isEmployee }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()
	const eColXml = useSelector(eColContractsSelectors.eColXml)
	const isLoadingContract = useSelector(eColContractsSelectors.isLoadingContract)
	const { successPayload, onSignMultiple, failedPayload, onClearPayload } = useDigitalSign()

	const onClear = () => {
		onClearPayload()
		dispatch(eColContractsActions.setEColXML(null))
		onClose()
	}

	const handleSign = () => {
		dispatch(eColContractsThunks.generateTradeUnionXML(externalId, isEmployee))
	}

	useEffect(() => eColXml && onSignMultiple(eColXml), [eColXml])

	useEffect(() => {
		if (successPayload) {
			dispatch(eColContractsThunks.sendSignedColContract(isEmployee, successPayload))
			setTimeout(() => onClear())
		}
	}, [successPayload])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	return (
		<Dialog classes={{ paper: styles.sign }} onClose={onClose} open={isOpen}>
			{isLoadingContract ? <LoadingBlocker /> : null}
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: styles.sign__title }}>{t('col_contract.text_ecd_sign_trade_union')}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					<div className={styles.flex_button}>
						<div className={styles.margin_button}>
							<DialogButton type="button" onClick={handleSign}>
								{t('sign_button_ecp')}
							</DialogButton>
						</div>
					</div>
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button) }}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
