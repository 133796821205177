import { headCells } from './table.headings'
import styles from './Table.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formsSelectors, formsThunks } from '@/entity/forms'
import React, { useCallback, useMemo, useState } from 'react'
import { colors } from '@pages/Forms/Tabs/utils'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { CheckList } from '@pages/Forms/Tabs/component'
import onHistoryCheckActionMenuClick from '@pages/Forms/Tabs/HistoryCheck/component/Table/actionMenu/onHistoryCheckActionMenuClick'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'

export const Table = ({ data, tabControlRef, activeSection }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isLoading = useSelector(formsSelectors.isLoading)
	const isSuccess = useSelector(formsSelectors.isSuccess)
	const selectedCheck = useSelector(formsSelectors.selectedCheck)
	const [actionMenuItems, setActionMenuItems] = useState([])
	const lang = getCurrentLanguage()

	const onToggleAction = (action, value, check) => {
		if (action === 'view') {
			getChecksInfo(check.id)
		} else {
			tabControlRef?.current?.onChangeActiveTab('checksTab')
			activeSection(check.section)
		}
		return { [action]: value }
	}

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}

	const getChecksInfo = useCallback((id) => dispatch(formsThunks.getCheckForCompany(id)), [dispatch])

	const onActionMenuChoose = (mode, checks) => {
		onToggleAction(mode, true, checks)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'} onClick={() => fastView(row)}>
				<td>{row.createDate ? row.createDate : '-'}</td>
				<td>{row.section?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}</td>
				<td>
					<span style={{ color: colors[row.result?.code?.toLowerCase()] }}>
						{row.result?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
					</span>
				</td>
				<td onClick={(e) => onHistoryCheckActionMenuClick(row, e, setActionMenuItems, getChecksInfo, t)}>
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[actionMenuItems, getChecksInfo, lang, onActionMenuChoose, t]
	)

	const tableBody = useMemo(() => !!data && data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('history_check.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('history_check.has_error')}</div>}
				</td>
			</tr>
		),
		[data?.length, isLoading, isSuccess, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
			{selectedCheck?.id && <CheckList />}
		</>
	)
}
