import * as types from './types'

export const actions = {
	setNotifications: (payload) => ({
		type: types.SET_NOTIFICATIONS,
		payload,
	}),
	setTags: (payload) => ({
		type: types.SET_TAGS,
		payload,
	}),
	setNotificationsBadge: (payload) => ({
		type: types.SET_NOTIFICATIONS_BADGE,
		payload,
	}),
	resetNotificationsBadge: () => ({
		type: types.RESET_NOTIFICATIONS_BADGE,
	}),
	setNotificationRead: (payload) => ({
		type: types.SET_NOTIFICATION_READ,
		payload,
	}),
	setNotificationUnread: (payload) => ({
		type: types.SET_NOTIFICATION_UNREAD,
		payload,
	}),
	setError: (payload) => ({
		type: types.SET_ERROR,
		payload,
	}),
	setQueryParams: (payload) => ({
		type: types.SET_QUERY_PARAMS,
		payload,
	}),
	setAll: (payload) => ({
		type: types.SET_ALL,
		payload,
	}),
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
}
