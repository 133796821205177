import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import styles from './InfoTable.module.scss'

export const ExperienceInfo = ({ oppv }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const outRes = oppv?.outRes

	/**
	 * Formats a number by inserting a space delimiter every three digits.
	 *
	 * @param {string} value - The number to be formatted.
	 * @returns {string} The formatted number, or '-' if the input value is falsy.
	 */
	const formatNumber = (value) => value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '-'

	/**
	 * Represents information about ENPF (Employer's National Pension Fund).
	 * @typedef {Object} EnpfInformation
	 * @property {string} title - The title of the information.
	 * @property {string} data - The formatted number representing the data.
	 * @property {string} [tooltip] - The tooltip for the information.
	 */
	const enpfInformation = [
		...(oppv.outCntuniq015per
			? [
					{
						title: t('oppv_count_unikey_period'),
						data: formatNumber(oppv.outCntuniq015per),
						tooltip: t('oppv_hint2'),
					},
			  ]
			: []),
		...(oppv.outSumrb
			? [
					{
						title: t('oppv_spectial'),
						data: formatNumber(oppv.outSumrb),
					},
			  ]
			: []),
		...(oppv.outSumkszh ? [{ title: t('oppv_money'), data: formatNumber(oppv.outSumkszh) }] : []),
		...(oppv.outSumemployer ? [{ title: t('oppv_money_employer'), data: formatNumber(oppv.outSumemployer) }] : []),
		...(oppv.outSumenpf
			? [
					{
						title: t('oppv_money_enpf'),
						data: formatNumber(oppv.outSumenpf),
						tooltip: t('oppv_hint3'),
					},
			  ]
			: []),
		...(oppv.outSumall ? [{ title: t('oppv_all'), data: formatNumber(oppv.outSumall) }] : []),
	]
	return (
		<>
			{outRes?.code === '1' ? (
				<>
					<DataGridTable
						hideSubTitle
						information={enpfInformation}
						titleClass={styles.tableTitleCellMedium}
						dataClass={styles.experienceInfo}
					/>
					<p className={styles.comment}>{t('oppv_comment')}</p>
				</>
			) : (
				<div className={styles.info}>{outRes ? outRes[optionLang] : null}</div>
			)}
			<div className={styles.comment}>
				{'* '}
				{lang === 'rus' ? (
					<>
						<a className={styles.greenLink} href={'../../../files/methodology-rus.docx'} download>
							{t('important_comment_2')}
						</a>
						{t('important_comment_3')}
					</>
				) : (
					<>
						{t('important_comment_2')}
						<a className={styles.greenLink} href={'../../../files/methodology-rus.docx'} download>
							{t('important_comment_3')}
						</a>
					</>
				)}
			</div>
		</>
	)
}
