import styles from './RequestPersonalAffair.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestPersonalAffairForm } from './form/RequestPersonalAffairForm'
import { getXmlForRequest } from '@/shared/api/rest/personalAffairs'
import { useDigitalSign } from '@/shared'
import { sendRequest } from '@/shared/api/rest/personalAffairs/sendRequest'
import { formFieldNames } from '@/entity'
import { Buffer } from 'buffer'
import { message } from 'antd'
import { affairsThunks } from '@/entity/personalAffairs'
import { useDispatch } from 'react-redux'
import { LoadingBlocker } from '@/components/Loader'

export const RequestPersonalAffairModal = ({ page = 0, size = 10, onClose }) => {
	const { t } = useTranslation()
	const [xml, setXml] = useState(null)
	const { successPayload, onSign, isLoading, failedPayload, onClearPayload } = useDigitalSign()
	const [fio, setFio] = useState('')
	const dispatch = useDispatch()
	const [sendingLoading, setSendingLoading] = useState(false)

	const getXml = async (data) => {
		setFio(data[formFieldNames.employeeInitials])
		setXml(await getXmlForRequest(data))
	}

	const onClear = () => {
		onClearPayload()
		setXml(null)
	}

	const sendRequestAffair = async () => {
		try {
			setSendingLoading(true)
			await sendRequest({ employeeFio: fio, xml: Buffer.from(successPayload).toString('base64') })
			onClear()
			dispatch(affairsThunks.getEmployerAffairs())
			dispatch(affairsThunks.getEmployerRequests('', page, size))
			onClose()
			message.success('Запрос был успешно отправлен!')
		} catch (e) {
			message.error(e?.response?.data?.message)
		} finally {
			setSendingLoading(false)
		}
	}

	useEffect(() => xml && onSign(xml), [xml])
	useEffect(() => successPayload && sendRequestAffair(), [successPayload])
	useEffect(() => {
		if (failedPayload) onClear()
		return () => onClear()
	}, [failedPayload])

	return (
		<>
			{(isLoading || sendingLoading) && <LoadingBlocker />}

			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>{t('personal_affairs.request.title')}</Title>
					</div>
					<RequestPersonalAffairForm onClose={onClose} onSubmit={getXml} />
				</div>
			</Modal>
		</>
	)
}
