import styles from './SelectPerson.module.scss'
import { Button, Field } from '@/ui'
import { Field as FormikField } from 'formik'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { useTranslation } from 'react-i18next'
import { getFLData } from '@/shared/api/rest/contracts'

export const SelectPerson = connect(
	({
		formik: { setFieldValue, values, errors },
		wrapperClassName,
		iinFieldName,
		fullNameFieldName,
		iinLabel,
		isFormDirty,
		onSelected = () => {},
	}) => {
		const { t } = useTranslation()

		const [loading, setLoading] = useState(false)
		const [isIinValid, setIinValidity] = useState(false)

		const getValueByPath = (obj, path) => {
			// Разбиваем строку пути на части, учитывая индексы массивов
			const parts = path.split(/[\.\[\]]/).filter((part) => part !== '')

			// Используем reduce для последовательного обращения к объекту по частям пути
			return parts.reduce((acc, part) => {
				if (acc && acc[part] !== undefined) {
					return acc[part]
				} else if (acc && !isNaN(part)) {
					// Проверяем, является ли part числом (индекс массива)
					return acc[parseInt(part, 10)]
				} else {
					return undefined
				}
			}, obj)
		}

		const handleClick = () => {
			if (!isIinValid) return
			setLoading(true)
			getFLData({ iin: getValueByPath(values, iinFieldName) })
				.then((result) => {
					if (result.fullNamePerson !== null) {
						setFieldValue(fullNameFieldName, result.fullNamePerson || '')
					} else {
						setFieldValue(fullNameFieldName, '')
						message.error(t('person_not_found'), 10)
					}
					// Объединяем result с isResident: true и передаем в onSelected
					onSelected({ ...result, isResident: true })
				})
				.finally(() => setLoading(false))
		}

		useEffect(() => {
			setIinValidity(!getValueByPath(errors, iinFieldName))
		}, [getValueByPath(errors, iinFieldName)])

		return (
			<div className={wrapperClassName ?? styles.fields}>
				<FormikField name={iinFieldName}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							hideNumberArrows
							isRequired
							label={iinLabel}
							placeholder={t('enter_count_digitals', { count: 12 })}
							error={(touched || isFormDirty) && error}
							{...field}
							maxLength={12}
							disabled={loading}
						/>
					)}
				</FormikField>
				<Button
					type="button"
					buttonClassName={styles.selectButton}
					onClick={handleClick}
					disabled={loading || !isIinValid}
				>
					{t('find')}
				</Button>
				<FormikField name={fullNameFieldName}>
					{({ field, meta: { touched, error } }) => (
						<Field
							classes={loading ? styles.progressbar : ''}
							type="text"
							isRequired
							disabled
							label={t('employee_initials')}
							placeholder={loading ? 'Загрузка...' : t('filled_automate')}
							{...field}
							error={(touched || isFormDirty) && error}
							onChange
						/>
					)}
				</FormikField>
			</div>
		)
	}
)
