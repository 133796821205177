import { Clock, Dots, Success } from '@app/icons'
import styles from '@features/contracts/table/contractsTable.module.scss'
import React from 'react'

export const AccidentState = ({ accidentState, lang }) => {
	const icons = {
		registered: <Success className={styles.iconSuccess} />,
		investigation_awaiting: <Clock className={styles.iconClock} />,
		commission_awaiting: <Clock className={styles.iconClock} />,
		medical_report_awaiting: <Success className={styles.iconSuccess} />,
		act_n1_awaiting: <Clock className={styles.iconClock} />,
		// 6: <CrossRounded className={styles.iconClock} />,
		// 7: <Dots className={styles.iconClock} />,
		// 8: <CrossRounded className={styles.iconClock} />,
		// 9: <Clock className={styles.iconClock} />,
	}

	const classes = {
		registered: styles.textSuccess,
		investigation_awaiting: styles.textClock,
		commission_awaiting: styles.textClock,
		medical_report_awaiting: styles.textSuccess,
		act_n1_awaiting: styles.textClock,
		// 6: styles.textRed,
		// 7: styles.textClock,
		// 8: styles.textRed,
		// 9: styles.textClock,
	}

	return (
		<div className={styles.contractState}>
			<div>{icons[accidentState.code] || <Dots className={styles.iconClock} />}</div>
			{
				<span className={classes[accidentState.code] || styles.textClock}>
					{accidentState[lang === 'rus' ? 'nameRu' : 'nameKz']}
				</span>
			}
		</div>
	)
}
