import React, { useCallback, useEffect, useState } from 'react'
import styles from './employmentСontractFile.module.scss'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Box } from '@mui/system'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'
import IconSave from '@features/contracts/actions/view/IconSave'
import cn from 'classnames'
import PdfViewer from './PdfViewer/PdfViewer'
import { format } from 'date-fns'
import { formates } from '@app/config'
import { notypeFileByUid } from '@/shared/api/rest/pension/notypeFileByUid'
import { request } from '@/shared/api/request'
import { contractsActions, contractsSelectors, contractsThunks, userSelectors } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { LoadingBlocker } from '@/components/Loader'
import { message } from 'antd'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
import { ConfirmDocument } from '@/entity/contracts/ui/form/Documents/ConfirmDocument/ConfirmDocument'

export const EmploymentContractFile = ({
	onClose,
	documentData,
	attachProjectFileData,
	CloseAndUpdateFilter,
	filters,
	page,
	pageSize,
	saveUrl,
	title,
	changeFile = false,
	downloadFile = false,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const xml = useSelector(contractsSelectors.additionalContractXml)
	const isThunkLoading = useSelector(contractsSelectors.additionalContractIsLoading)
	const isSuccess = useSelector(contractsSelectors.additionalContractSubmitted)
	const [isPdfFile, setPdfFile] = useState(null)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const activeRole = useSelector(userSelectors.activeRole)
	const [disableDownload, setDisableDownload] = useState(false)
	const [isState] = useState(attachProjectFileData.additionalContractDTO?.dcontractState?.code ?? null)
	const [sendToWorkerActive, setSendToWorkerActive] = useState(false)
	useEffect(async () => {
		const response = await notypeFileByUid(documentData.id)
		setPdfFile(response)
	}, [documentData])

	const onSignLocal = async () => {
		let response
		if (isState) {
			response = await request({
				url: '/contract/eAdContract/send',
				method: 'POST',
				params: {
					externalId: attachProjectFileData.additionalContractDTO?.externalId,
				},
			})
		} else {
			response = await request({
				url: saveUrl,
				method: 'POST',
				data: attachProjectFileData,
				params: {
					isSend: true,
				},
			})
		}
		if (response.success) CloseAndUpdateFilter()
	}

	const [paramMap, setParamMap] = useState({})
	useEffect(() => xml && onSign(xml), [xml]) /** подписание xml */

	/** очистка и выход при удачной операции */
	useEffect(() => {
		if (isSuccess) {
			onClose()
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()
		}
	}, [isSuccess])

	const sendSignedEDSEmployee = async (signXmlList) => {
		try {
			await request({
				url: '/contract/eAdContract/employee/saveSign',
				method: 'POST',
				data: signXmlList,
			})
			onClose()
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()

			dispatch(
				contractsThunks.getContract(
					attachProjectFileData.contractDTO.externalId,
					attachProjectFileData.contractDTO.isLocalContract
				)
			)
			dispatch(contractsActions.setAdditionalContractSubmitted())
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}

	const sendSignedEDSEmployer = async (signXmlList) => {
		try {
			await request({
				url: '/contract/eAdContract/employer/saveSign',
				method: 'POST',
				data: signXmlList,
			})
			onClose()
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()
			dispatch(
				contractsThunks.getContract(
					attachProjectFileData.contractDTO.externalId,
					attachProjectFileData.contractDTO.isLocalContract
				)
			)
			dispatch(contractsActions.setAdditionalContractSubmitted())
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			alert(err ? err : 'Неизвестная ошибка')
		}
	}

	const success = () => {
		message.success(t('success_alert'), 5)
	}

	/** отправка подписанного XML файла */
	useEffect(() => {
		if (successPayload && !isThunkLoading) {
			if (activeRole === 'ROLE_EMPLOYEE' && attachProjectFileData.contractDTO?.isElectronic) {
				sendSignedEDSEmployee(successPayload).then(success)
				return
			}
			if (activeRole === 'ROLE_EMPLOYER' && attachProjectFileData.contractDTO?.isElectronic) {
				sendSignedEDSEmployer(successPayload).then(success)
				return
			}
			dispatch(contractsThunks.sendAdditionalContract(successPayload, paramMap, page, pageSize, filters))
			setParamMap({})
		}
	}, [successPayload])

	/** сброс redux параметров при ошибке NCALayer */
	useEffect(() => {
		if (failedPayload) {
			dispatch(contractsActions.setAdditionalContractXml(null))
			// mode === 'sign' && onClose()
		}
	}, [failedPayload])

	const downloadContractPDF = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDisableDownload(true)
		setTimeout(() => {
			setDisableDownload(false)
		}, 5000)
		const response = await downloadFileByUid(attachProjectFileData.downloadFile.id)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = attachProjectFileData.downloadFile.fileName
		document.body.appendChild(a)
		a.click()
	}

	const getDocument = useCallback(async (doc) => {
		if (doc) {
			const response = await notypeFileByUid(doc.id)
			setPdfFile(response)
			setSendToWorkerActive(true)
			attachProjectFileData.fileId = doc.id
		}
	}, [])

	return (
		<>
			{!isPdfFile ? <LoadingBlocker /> : null}
			<ModalWS show={true} windowClassName={styles.modal}>
				<ModalWSBackButton onClick={onClose} />
				<div className={`scroll`}>
					<div className={styles.header}>
						<Box className={styles.header} display="flex" alignItems="center" justifyContent="space-between">
							<Box>
								<div className={styles.etd__title}>{title ?? t('electronic_contract.open_contract_project_title')}</div>
								<div className={styles.id__wrapper}>ID {documentData.id}</div>
								<div className={styles.date__wrapper}>
									{t('date_created')}: {format(new Date(), formates.date)}
								</div>
							</Box>
						</Box>
					</div>
					{downloadFile && ['1'].includes(isState) && (
						<Button
							buttonClassName={`mt-3 mb-3 ${styles.button}`}
							onClick={(e) => downloadContractPDF(e)}
							disabled={disableDownload}
						>
							{t('download_pdf_eds')}
						</Button>
					)}
					{changeFile && ['2', '3', '5', '6', '8', '9'].includes(isState) && (
						<div className={'mt-3 mb-3'}>
							<ConfirmDocument getDocument={getDocument} buttonText={t('replace_eds_file')}></ConfirmDocument>
						</div>
					)}
					<Formik
						initialValues={{ type: '' }}
						enableReinitialize
						onSubmit={async () => {
							const response = await request({
								url: saveUrl,
								method: 'POST',
								data: attachProjectFileData,
								params: {
									isSend: false,
								},
							})
							if (response.success) CloseAndUpdateFilter()
						}}
					>
						<Form>
							<div className={styles.option__block}>
								<PdfViewer isPdfFile={isPdfFile} />
							</div>
							{activeRole === 'ROLE_EMPLOYER' &&
								isState !== '1' &&
								['3', '5', '6', '4', '7', '8', '9', null].includes(isState) && (
									<div className={styles.footer}>
										<Button
											type={'submit'}
											startIcon={<IconSave />}
											buttonClassName={styles.button__save}
											classes={{ root: cn(styles.button) }}
											disabled={['4', '7'].includes(isState)}
											sx={{ paddingLeft: '20px !important' }}
										>
											<span className={styles.svgWhite}>
												<IconSave />
											</span>{' '}
											{t('save')}
										</Button>
										<Button
											type={'button'}
											onClick={() => onSignLocal()}
											disabled={['4', '7'].includes(isState) || sendToWorkerActive}
										>
											{t('send_to_sign_worker')}
										</Button>
									</div>
								)}
						</Form>
					</Formik>
				</div>
			</ModalWS>
		</>
	)
}
