import React, { useState } from 'react'
import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './CheckingSpecialSocialPaymentModal.module.scss'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import CheckingSpecialSocialPaymentForm from './form/CheckingSpecialSocialPaymentForm'

const CheckingSpecialSocialPaymentModal = ({ onClose }) => {
	const { t } = useTranslation()

	return (
		<Modal onClickDisable={true} windowClassName={styles.modal} onClose={onClose}>
			<ModalBackButton backButtonIsShown={false} onClick={onClose} noMargin={true} />
			<div className={`${styles.wrapper} scroll`}>
				<Title className={styles.title}>{t('personal_affairs.title_checking_social_payments')}</Title>
			</div>
			<CheckingSpecialSocialPaymentForm onClose={onClose} />
		</Modal>
	)
}

export default CheckingSpecialSocialPaymentModal
