import { getCurrentLanguage } from '@/i18next'

export const headCells = [
	{
		id: 'name',
		numeric: true,
		disablePadding: false,
		label: 'employer',
	},
	{
		id: 'bin',
		numeric: false,
		disablePadding: false,
		label: 'bin_iin',
	},
	{
		id: 'beginDate',
		numeric: false,
		disablePadding: false,
		label: 'contracts_tab.conclusion_date',
	},
	{
		id: 'endDate',
		numeric: false,
		disablePadding: false,
		label: 'personal_affairs.end_date',
	},
	{
		id: 'positionByNKZ',
		numeric: false,
		disablePadding: false,
		label: 'personal_affairs.nkz_position',
	},
	{
		id: 'position',
		numeric: false,
		disablePadding: false,
		label: 'contract.info.established_position',
	},
]

export const getTableData = (data) => {
	const lang = getCurrentLanguage()
	const langOption = lang === 'rus' ? 'nameRu' : 'nameKz'
	return data.map(({ contractDate, terminationDate, contractPeriodData, organization, termReasions }) => ({
		beginDate: contractDate,
		endDate: terminationDate,
		contractPeriodData,
		bin: organization.bin,
		name: organization[langOption],
		termReasions,
	}))
}
