import React, { useCallback } from 'react'
import { ModalTemplate } from '@/ui'
import styles from './View.module.scss'
import { PositionCard } from './PositionCard'

export const View = ({ onClose, onToggleAction, positionRowSelectMenu, history = false }) => {
	const onEdit = useCallback(() => onToggleAction('edit', true), [onToggleAction])
	const onRemove = useCallback(() => onToggleAction('remove', true), [onToggleAction])
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper} ${styles.wrapper_view}`}>
				<PositionCard
					onClose={onClose}
					onEdit={onEdit}
					onRemove={onRemove}
					positionRowSelectMenu={positionRowSelectMenu}
					history={history}
				></PositionCard>
			</div>
		</ModalTemplate>
	)
}
