export const isLoading = (state) => state.laborRecords.isLoading
export const isSuccess = (state) => state.laborRecords.isSuccess
export const isError = (state) => state.laborRecords.isError
export const contractList = (state) => state.laborRecords.contractList
export const laborRecords = (state) => state.laborRecords
export const isAdjustmentLoading = (state) => state.laborRecords.isAdjustmentLoading
export const isAdjustmentSuccess = (state) => state.laborRecords.isAdjustmentSuccess
export const requestsAdjustment = (state) => state.laborRecords.requestsAdjustmentList
export const isAdjustmentRefresh = (state) => state.laborRecords.isAdjustmentRefresh
export const isRequestLoading = (state) => state.laborRecords.isRequestLoading
export const isRequestSuccess = (state) => state.laborRecords.isRequestSuccess
export const requestDetail = (state) => state.laborRecords.requestDetail
export const shortContractData = (state) => state.laborRecords.shortContractData
export const adjustmentXml = (state) => state.laborRecords.adjustmentXml
export const isAdjustmentXmlLoading = (state) => state.laborRecords.isAdjustmentXmlLoading
export const isAdjustmentXmlSuccess = (state) => state.laborRecords.isAdjustmentXmlSuccess
export const isLocalDigitContractAdded = (state) => state.laborRecords.isLocalDigitContractAdded
export const cancelAdjustmentXml = (state) => state.laborRecords.cancelAdjustmentXml
export const isCancelAdjustmentXmlLoading = (state) => state.laborRecords.isCancelAdjustmentXmlLoading
export const isCancelAdjustmentXmlSuccess = (state) => state.laborRecords.isCancelAdjustmentXmlSuccess
export const digitContractLocal = (state) => state.laborRecords.digitContractLocal
export const isRefreshContracts = (state) => state.laborRecords.isRefreshContracts
