import { request } from '@/shared/api/request'
export const contractDigitXml = (data) => {
	return request({ url: 'contract/labor/digit/external/xml', method: 'POST', data })
}

export const contractDigitXmlWithOutID = (data) => {
	return request({ url: 'contract/labor/digit/local/saveAndGetXml', method: 'POST', data })
}
export const contractDigit = (payload) => {
	return request({ url: 'contract/labor/digit/send', method: 'POST', data: payload })
}
export const contractLocalDigit = (payload) => {
	return request({ url: 'contract/labor/digit/local/save', method: 'POST', data: payload })
}
export const getContractLocalDigit = () => {
	return request({ url: 'contract/labor/digit/local', method: 'GET'})
}
export const deleteContractLocalDigit = (payload) => {
	return request({ url: 'contract/labor/digit/local/delete', method: 'POST', data: payload })
}
export const deleteContractDigit = (payload) => {
	return request({ url: 'contract/labor/digit/external/del/xml', method: 'POST', data: payload })
}

export const contractLocalDigitXml = (data) => {
	return request({ url: 'contract/labor/digit/local/xmlList', method: 'POST', data })
}