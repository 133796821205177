import React, { useCallback, useEffect, useState } from 'react'
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import styles from '@features/contracts/registrationEmployerContract/RegistrationEmployerContract.module.scss'
import { Button } from '@/ui'
import { Flame } from '@app/images'
import { useTranslation } from 'react-i18next'
import { AccidentsTable } from '@features/accidents/table/AccidentsTable'
import { accidentActions, accidentSelectors, accidentThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '@features/accidents/registrationAccident/form'
import { HrPagination } from '@/components/Pagination'

const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

export const AccidentsPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const accidents = useSelector(accidentSelectors.accidents)
	const isSuccessSend = useSelector(accidentSelectors.isSuccessSend)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const isMobile = window.innerWidth < 768
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))
	const [actions, setActions] = useState({
		create: false,
	})

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(accidentThunks.getAccidents(page, pageSize))
		},
		[queryParams]
	)

	useEffect(() => {
		if (isSuccessSend) {
			fetchData()
			dispatch(accidentActions.toggleSuccessSend(false))
		}
	}, [isSuccessSend])

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])

	const CloseForm = () => {
		onToggleAction('create', false)
	}

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={'accidents'}>
				<div className={styles.actions__wrapper}>
					<Button
						icon={Flame}
						iconSize={'big'}
						onClick={() => {
							onToggleAction('create', true)
						}}
					>
						{t('accidentsPage.registerAccident')}
					</Button>
				</div>
				<AccidentsTable accidents={accidents}></AccidentsTable>
				<HrPagination
					id="hr-pagination"
					rowsPerPageitems={
						isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
					}
					rowsPerPage={queryParams.pageSize}
					numberOfElements={accidents.numberOfElements}
					totalElements={accidents.totalElements}
					totalPages={accidents.totalPages}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					page={queryParams.page}
				/>
				{actions.create ? <Form fetchData={fetchData} onClose={CloseForm}></Form> : null}
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
