import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	contracts: (payload) => ({
		type: types.SET_CONTRACTS,
		payload,
	}),
	resetContracts: () => ({
		type: types.RESET_CONTRACTS,
	}),
}
