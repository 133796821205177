import styles from './Switcher.module.scss'
import classnames from 'classnames'

export const Switcher = ({ values, onChange, activeValue }) => {
	return (
		<div className={styles.switcherContainer}>
			{values.map((value) => (
				<div
					onClick={() => onChange(value)}
					key={value.id}
					className={classnames(styles.switcherItem, value?.id === activeValue?.id ? styles.switcherItemActive : '')}
				>
					{value.name}
				</div>
			))}
		</div>
	)
}
