import { ConfirmModal, Icon, Title } from '@/ui'
import { Warn } from '@app/images'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ContractTerminationConfirmationModal.module.scss'

export const ContractTerminationConfirmationModal = ({ onToggleAction, onConfirm }) => {
	const { t } = useTranslation()

	const handleCancelButtonClick = useCallback(() => {
		onToggleAction('terminate', false)
	}, [onToggleAction])

	return (
		<ConfirmModal
			onProcess={onConfirm}
			onCancel={handleCancelButtonClick}
			cancelButtonTitle={'cancel'}
			processButtonTitle={'contracts_tab.terminate.process_button_title'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Icon src={Warn} />
				<Title>{t('contracts_tab.terminate.warning')}</Title>
			</div>
		</ConfirmModal>
	)
}
