import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Arrow } from '@app/icons'
import styles from './tableExtended.module.scss'
import { useToggle } from '@/shared'
import { headCells } from './tableExtended.headings'
import { Title } from '@/ui'
import onStaffingActionMenuClick from './actionMenu/onStaffingActionMenuClick'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { getCurrentLanguage } from '@/i18next'
import { calculateStaffUnits, formatNumber } from '@/utils'

const TableExtended = ({
	toggleViewExtended,
	staffType,
	structure,
	workPlaces,
	setActions,
	setWorkPlaceRowSelect,
	deactivateStaff,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const optionLang = useMemo(() => (language === 'rus' ? 'nameRu' : 'nameKz'), [language])
	const lang = getCurrentLanguage()
	const [actionMenuItems, setActionMenuItems] = useState([])
	const [totalStaffUnits, setTotalStaffUnits] = useState(0)
	const [totalFreeStaffUnits, setTotalFreeStaffUnits] = useState(0)

	const showFullNameStruct = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] + ': ' + structure.dept[optionLang] : null
	}

	const workPlacesList = {
		workPlaces: workPlaces,
	}

	useEffect(() => {
		if (workPlacesList) {
			const { totalStaffUnits, totalFreeStaffUnits } = calculateStaffUnits(workPlacesList)
			setTotalStaffUnits(totalStaffUnits)
			setTotalFreeStaffUnits(totalFreeStaffUnits)
		}
	}, [])
	const onClose = () => {
		toggleViewExtended(false)
	}
	const [isFreelance, toggleFreelance] = useToggle(false)
	const handleCheckboxChange = ({ value }) => {
		toggleFreelance(value)
	}
	const tableHeader = useMemo(
		() => (
			<>
				{(deactivateStaff ? headCells.slice(0, -1) : headCells).map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		setWorkPlaceRowSelect(row)
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}
	const getTableRow = useCallback((row, index) => (
		<tr key={index} onClick={() => fastView(row)}>
			<td>{index + 1}</td>
			<td title={t('staff.information_workplace_position')}>{row.code}</td>
			<td title={t('staff.information_workplace_position')}>
				{lang === 'rus' ? row.establishedPost.nameRu : row.establishedPost.nameKz}
			</td>
			<td title={t('staff.information_workplace_position')}>
				{row.staffUnitsCount}
				{'/' + row.staffFreeCount}
			</td>
			<td title={t('staff.information_workplace_position')}>{formatNumber(row.grossSalary || '')}</td>
			<td title={t('staff.information_workplace_position')}>{formatNumber(row.salarySupplement || '')}</td>
			{!deactivateStaff ? (
				<td onClick={(e) => onStaffingActionMenuClick(row, e, setActionMenuItems, t)}>
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			) : null}
		</tr>
	))

	// Общий блок кода
	const tableBody = useMemo(() => {
		let filteredWorkPlace = workPlaces

		// Если isFreelance === true, фильтруем workPlaces
		if (isFreelance) {
			filteredWorkPlace = workPlaces?.filter((place) => place.freelance === true)
		}

		// Возвращаем обновленные строки таблицы
		return filteredWorkPlace?.map((row, index) => getTableRow(row, index))
	}, [workPlaces, getTableRow, isFreelance])

	return (
		<div>
			<div className={styles.linePreTable}>
				<div onClick={onClose} className={styles.back}>
					<Arrow styles={{ fill: '#17B67C', width: '5px' }} />
					{t('back')}
				</div>
				<WrappedCheckbox
					onChange={handleCheckboxChange}
					value={isFreelance}
					label={t('staff_modal.freelancer_position')}
				/>
			</div>
			<div className={styles.countStaff}>
				<Title>{showFullNameStruct(structure.deptTypeCode)}</Title>
				<p>
					{t('count_staff_units_1')}
					<span>{totalStaffUnits}</span>
					{t('count_staff_units_2')}
					<span>{totalFreeStaffUnits}</span>
				</p>
			</div>
			<table className={styles.table}>
				<thead>{tableHeader}</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</div>
	)
}

export default TableExtended
