import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Title } from '@/ui'
import { userSelectors } from '@/entity/user'
import { employerProfileSelectors, employerProfileThunks } from '@features/employerProfile/model'
import { UploadImagePlaceholder } from '@app/images/index'
import { ProfileActions } from './ProfileActions'
import { InformationAboutCompany, CompanyAccountData, EmployerInformation } from '@/features'
import styles from './EmployerProfile.module.scss'
import { notificationsThunks } from '@/entity'
import { NotificationEmployer } from '@pages/ChooseRole/NotificationEmployer'
import { getFromLocalStorage } from '@/shared'

export const EmployerProfile = () => {
	const dispatch = useDispatch()
	const companies = useSelector(userSelectors.employerCompanies)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const employer = useSelector(userSelectors.user)
	const { t } = useTranslation()
	const [tmpActiveCompany, setTmpActiveCompany] = useState(null)

	const onChangeCompany = useCallback((id) => id && dispatch(employerProfileThunks.getCompany({ id })), [dispatch])
	// const [enableModal, setEnableModal] = useState()
	// useMemo(() => setEnableModal(process.env.REACT_APP_MODAL_INFO_EMPLOYER), [])
	// const [isOpenInfoEmployer, setIsOpenInfoEmployer] = useState(false)
	// const isCheck = getFromLocalStorage('isCheck_01')
	// useEffect(() => {
	// 	if (!isCheck && enableModal === '1') {
	// 		setIsOpenInfoEmployer(true)
	// 	} else {
	// 		setIsOpenInfoEmployer(false)
	// 	}
	// }, [enableModal])

	useEffect(() => {
		//TODO сделано временно, т.к. activeCompany перезаписывается несколько раз и нельзя на неё ориентироваться
		setTmpActiveCompany(activeCompany.bin)
		if (activeCompany.bin != null && activeCompany.bin !== '' && activeCompany.bin !== tmpActiveCompany) {
			dispatch(notificationsThunks.getNotificationsBadge(true))
		}
	}, [activeCompany])

	return (
		<>
			{/*{isOpenInfoEmployer && (*/}
			{/*	<NotificationEmployer*/}
			{/*		handleProcess={setIsOpenInfoEmployer.bind(null, false)}*/}
			{/*		handleCancel={setIsOpenInfoEmployer.bind(null, false)}*/}
			{/*	></NotificationEmployer>*/}
			{/*)}*/}
			<div className={styles.profile}>
				<div className={styles.mainInformation}>
					<div className={styles.mainInfomationCol}>
						<Title>{t('company')}</Title>
						<ProfileActions
							options={companies}
							onChangeCompany={onChangeCompany}
							value={activeCompany?.code}
							manager={employer?.manager}
						/>
					</div>
					<div className={styles.logoCol}>
						<p className={styles.label}>{t('logo')}:</p>
						<img
							src={
								activeCompany?.photoId
									? `${process.env.REACT_APP_API_URL}/file/notype/${activeCompany.photoId}`
									: UploadImagePlaceholder
							}
							alt="avatar"
							className={styles.logoImage}
						/>
					</div>
				</div>
				<div className={styles.information}>
					<CompanyAccountData company={activeCompany} hideSubTitle />
					<div className={styles.employerInfo}>
						<EmployerInformation company={activeCompany} employer={employer} />
					</div>
					<div className={styles.companyInfo}>
						<InformationAboutCompany company={activeCompany} />
					</div>
				</div>
			</div>
		</>
	)
}
