import React, { useState, useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, Button, Box } from '@mui/material'
import styles from '../style.module.scss'
import cn from 'classnames'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Formik } from 'formik'
import { TemplateForm } from './Form'
import { useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'
import { ValidationSchema, validationSchemaFlImmigrant } from './validationSchema'

export const NewTemplate = ({
	isOpen,
	onClose,
	onSave,
	onOpenProject,
	formData,
	isAdditional = false,
	selectedTemplate,
}) => {
	const company = useSelector((state) => state.company.company)
	const { t } = useTranslation()
	const handleOpenProject = (data) => {
		onOpenProject(data)
	}
	const lang = getCurrentLanguage()
	const languages = { rus: '1.2', kz: '1.1' }
	const dpartTimeCodes = { 2: '2.1', 1: '2.2' }
	const dcontractDateCodes = {
		1: '5.1',
		2: '5.2',
		3: '5.3',
		4: '5.4',
		5: '5.5',
		6: '5.6',
		7: '5.7',
		9: '5.9',
	}
	const [initialValues, setInitialValues] = useState(null)
	const [isLaborImmigrant, setIsLaborImmigrant] = useState(false)

	const getParseOptions = (options) => {
		const languages = { rus: 'textValueRus', kz: 'textValueKaz' }
		const optionsToSearch = isAdditional ? options : options.optionDtos

		const getValue = (findString) => {
			return optionsToSearch.find((item) => item.paramValueCode.split('.')[0] === findString)?.paramValueCode
		}

		const getItem = (findString) => {
			return optionsToSearch.find((item) => item.paramValueCode.split('.')[0] === findString)
		}

		const getBaseValues = () => {
			return {
				employerType: getValue('0'),
				employeeType: getValue('14'),
				language: getValue('1'),
				jobType: getValue('2'),
				term: getValue('5'),
			}
		}

		const getFullValues = () => {
			return {
				...getBaseValues(),
				workCondition: getValue('3'),
				workBeginning: getValue('4'),
				probitionary: getValue('6'),
				workingTime: getValue('7'),
				salary: getValue('8'),
				bonus: getValue('9'),
				mandatory: getValue('10'),
				downtime: getValue('11'),
				overtime: getValue('12'),
				paymentMethod: getValue('13'),
				employeeRights: Boolean(getValue('34')) ? getItem('34')?.[languages[lang]] : '',
				employeeRightsIs: Boolean(getValue('34')),
				employeeResp: Boolean(getValue('35')) ? getItem('35')?.[languages[lang]] : '',
				employeeRespIs: Boolean(getValue('35')),
				employerRights: Boolean(getValue('36')) ? getItem('36')?.[languages[lang]] : '',
				employerRightsIs: Boolean(getValue('36')),
				employerResp: Boolean(getValue('37')) ? getItem('37')?.[languages[lang]] : '',
				employerRespIs: Boolean(getValue('37')),
			}
		}

		if (isLaborImmigrant) {
			setInitialValues(getBaseValues())
		} else {
			setInitialValues(getFullValues())
		}
	}

	const baseInitialValues = () => ({
		employerType: company?.flEmployer ? '0.3' : company?.isIin ? '0.2' : '0.1',
		employeeType: formData?.isResident ? '14.1' : formData?.isForeignLabor ? '14.2' : '14.3',
		language: languages[lang],
		jobType: dpartTimeCodes[formData.dpartTimeCode],
		workBeginning: formData.registerDate === formData.dateFrom ? '4.1' : '4.2',
		term: dcontractDateCodes[formData.dcontractDateCode],
	})

	const fullInitialValues = () => ({
		...baseInitialValues(),
		workCondition: '3.1',
		probitionary: '',
		workingTime: '',
		salary: '',
		bonus: '',
		mandatory: '',
		downtime: false,
		overtime: false,
		paymentMethod: '',
		employeeRights: '',
		employeeRightsIs: false,
		employeeResp: '',
		employeeRespIs: false,
		employerRights: '',
		employerRightsIs: false,
		employerResp: '',
		employerRespIs: false,
	})

	useEffect(() => {
		if (selectedTemplate || isAdditional) {
			getParseOptions(selectedTemplate ? selectedTemplate : formData)
		} else {
			if (isLaborImmigrant) {
				setInitialValues(baseInitialValues())
			} else {
				setInitialValues(fullInitialValues())
			}
		}
	}, [selectedTemplate, isAdditional, isLaborImmigrant])

	useEffect(() => {
		if (formData?.isResident === false && !formData?.isForeignLabor) {
			setIsLaborImmigrant(true)
		}
	}, [])

	return (
		<Dialog scroll={'paper'} classes={{ paper: styles.dialog__new__template }} onClose={onClose} open={isOpen}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Button
						startIcon={<ArrowBackIosIcon />}
						classes={{ root: cn(styles.button, styles.back__button) }}
						variant="text"
						onClick={onClose}
					>
						{t('back')}
					</Button>
					<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Box className={styles.title__new__template}>
					{selectedTemplate ? selectedTemplate.templateName : t('electronic_contract.new_template')}
				</Box>
				<Box className={styles.sub__title}>{t('electronic_contract.new_template_select_options')}</Box>

				{initialValues && (
					<Formik
						validationSchema={isLaborImmigrant ? validationSchemaFlImmigrant(t) : ValidationSchema(t)}
						initialValues={initialValues}
						validateOnChange={false}
						validateOnBlur={false}
					>
						<TemplateForm
							onSaveTemplate={onSave}
							onOpenProject={handleOpenProject}
							isAdditional={isAdditional}
							onClose={onClose}
							isLaborImmigrant={isLaborImmigrant}
						/>
					</Formik>
				)}
			</DialogContent>
		</Dialog>
	)
}
