import React, { useEffect, useState } from 'react'
import { Button } from '@/ui'
import { Modal, ModalBackButton } from '@/components/Modal'
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { request } from '@/shared/api/request'
import { Cross } from '@app/images'
import { LoadingBlocker } from '@/components/Loader'
import moment from 'moment'
import { message } from 'antd'
import TransferForm from './form/TransferForm'
import { AddTransferValidationSchema } from '@features/contracts/transferRequests/components/AddTransferValidationSchema'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { branchSelectors, transferActions, transferSelectors, transferThunks } from '@/entity'
import Table from './table/Table'
const createTransfer = (data) => request({ url: '/contract/branchTransfer/save/createReq', method: 'POST', data })

const contractInitial = {
	contractNumber: '',
	destablishedPost: '',
	externalId: '',
	fullName: '',
	iin: '',
}

const initialValues = {
	bin: '',
	branchNameKz: '',
	branchNameRu: '',
	contracts: [contractInitial],
	date: moment(new Date()).format('DD.MM.YYYY'),
}

export const AddTransferModal = ({ onClose, addNewTransfer }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isLoading = useSelector(branchSelectors.isLoading)
	const isLoadingContracts = useSelector(transferSelectors.isLoading)
	const [response, setResponse] = useState(null)
	const [name, setName] = useState('')
	const isSuccessContracts = useSelector(transferSelectors.isSuccess)
	const contracts = useSelector(transferSelectors.contracts)
	const [activateTable, setActivateTable] = useState(false)
	const [loading, setLoading] = useState(false)
	const [contract, setContract] = useState(null)

	useEffect(() => {
		if (isSuccessContracts && contracts?.length > 1) {
			setActivateTable(true)
		}
	}, [contracts, isSuccessContracts])

	const closeContractTable = () => {
		setActivateTable(false)
		dispatch(transferActions.resetContracts())
	}

	const onSubmit = async (data) => {
		try {
			setLoading(true)
			if (data.contracts.some(({ fullName, iin, contractNumber }) => !fullName || !iin || !contractNumber)) {
				message.error('Заполните все поля!')
				return
			}
			const response = await createTransfer(data)
			setResponse(response)
			addNewTransfer()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{(loading || isLoading || isLoadingContracts) && <LoadingBlocker />}
			<Modal windowClassName={styles.modal} onClose={onClose} onClickDisable={true}>
				<div className={`scroll ${styles.modal__wrapper}`}>
					<ModalBackButton onClick={onClose} />
					<div className={styles.modal__title}>{t('transfer_request_title')}</div>
					<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={AddTransferValidationSchema}>
						<TransferForm
							contractInitial={contractInitial}
							setName={setName}
							addNewTransfer={addNewTransfer}
							selectContract={contract}
						></TransferForm>
					</Formik>
				</div>
			</Modal>
			{!!response ? (
				<Modal windowClassName={styles.modal} onClose={onClose}>
					<div className={styles.success__modal__wrapper}>
						<Box display="flex" justifyContent="flex-end" onClick={onClose}>
							<div className={styles.cross}>
								<img src={Cross} alt="cross" />
							</div>
						</Box>
						<div className={styles.success__modal__title}>{t('transfer_request_sended')}</div>
						<div className={styles.success__modal__subtitle}>
							{t('transfer_wait_for_confirmation')} {name}
						</div>
						<div className={styles.success__modal__text}>{t('transfer_watch_status')}</div>
						<Box display="flex" justifyContent="center">
							<Button onClick={onClose}>{t('transfer_go_to_list')}</Button>
						</Box>
					</div>
				</Modal>
			) : null}
			{activateTable ? (
				<Modal windowClassName={styles.modal} onClose={closeContractTable}>
					<div className={`scroll ${styles.modal__table}`}>
						<ModalBackButton onClick={closeContractTable} />
						<div className={styles.wrapper}>
							<div className={styles.success__modal__title}>{t('choose_employment_contract')}</div>
							{contracts ? <Table data={contracts} setContract={setContract} close={closeContractTable}></Table> : null}
						</div>
					</div>
				</Modal>
			) : null}
		</>
	)
}
