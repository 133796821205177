import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contractsActions, contractsSelectors, contractsThunks } from '@/entity/contracts'
import { Modal, ModalBackButton } from '@/components/Modal'
import { LoadingBlocker } from '@/components/Loader'
import { Title } from '@/ui'
import { useDigitalSign } from '@/shared'
import { ProlongationForm } from './ProlongationForm'
import styles from './ProlongationModal.module.scss'
import { sendProlongation } from '@/entity/contracts/model/thunks'

export const ProlongationModal = ({ onClose }) => {
	const { t } = useTranslation()
	const contract = useSelector(contractsSelectors.contract)
	const isLoading = useSelector(contractsSelectors.prolongationIsLoading)
	const isSuccess = useSelector(contractsSelectors.prolongationIsSuccess)
	const xml = useSelector(contractsSelectors.prolongationXML)
	const { successPayload, onSign, onClearPayload, failedPayload } = useDigitalSign()
	const dispatch = useDispatch()
	const { dcontractDate, endDate, prolongationDate } = contract?.predictAutoProlongationData
	const handleFormSubmit = useCallback(
		(data) =>
			!isLoading &&
			dispatch(
				contractsThunks.createProlongation({
					...data,
					bin: contract?.bin,
					contractId: contract?.id,
					externalId: contract?.externalId,
					prolongationDate: prolongationDate,
				})
			),
		[]
	)

	const fullClear = () => {
		onClose()
		dispatch(contractsActions.resetProlongation())
		onClearPayload()
	}

	useEffect(() => {
		xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])

	useEffect(() => {
		if (successPayload) dispatch(sendProlongation(successPayload))
	}, [successPayload])

	useEffect(() => {
		failedPayload
			? fullClear()
			: dispatch(contractsActions.resetProlongation()) /** сброс redux параметров при ошибке NCALayer */
		return () => dispatch(contractsActions.resetProlongation()) /** сброс redux параметров при закрытии компонента */
	}, [failedPayload])

	return (
		<>
			{isLoading && <LoadingBlocker />}
			<Modal onClose={onClose} windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={styles.description}>
					<Title>
						{t('prolongation_title', {
							contractNumber: contract?.contractNumber || ' ',
							registerDate: contract.registerDate || ' ',
						})}
					</Title>
					<div className={styles.textRow}>
						<p className={styles.titleCol}>{t('worker_iin')}:</p>
						<p>{contract.iin}</p>
					</div>
					<div className={styles.textRow}>
						<p className={styles.titleCol}>{t('worker_fullname')}:</p>
						<p>{contract.fullName}</p>
					</div>
				</div>
				<ProlongationForm
					dcontractDate={dcontractDate}
					endDate={endDate}
					onSubmit={handleFormSubmit}
					onCancel={onClose}
				/>
			</Modal>
		</>
	)
}
