import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styles from './NotificationHome.module.scss'
import { Info as InfoIcon } from '@app/icons'
import { ConfirmModal } from '@/ui'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { setToLocalStorage } from '@/shared'

export const NotificationHome = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()
	const [isHomeCheck, setIsHomeCheck] = useState(false)
	const handleCheckboxChange = (newValue) => {
		setIsHomeCheck(newValue)
		setToLocalStorage('isHomeCheck', newValue) // Обновляем LocalStorage при изменении состояния
	}

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			processButtonTitle={'modal_home.cancel_text'}
			cancelButtonTitle={''}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<InfoIcon />
				<div className={styles.title}>
					<p>{t('modal_home.title')}</p>
				</div>
				<p>{t('modal_home.body')}</p>
				<div>
					<p>{t('modal_home.body_second')}</p>
				</div>
				<div className={styles.checkbox__container}>
					<WrappedCheckbox onChange={handleCheckboxChange} value={isHomeCheck} label={t('modal_home.checkbox_text')} />
				</div>
			</div>
		</ConfirmModal>
	)
}
