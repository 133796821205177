import styles from './Deletion.module.scss'
import { ConfirmModal, Title } from '@/ui'
import React, { useState } from 'react'
import { api } from '@/shared'
import { message } from 'antd'
import warningIcon from 'assets/core/greenWarning.svg'
import { useTranslation } from 'react-i18next'
import { LoadingBlocker } from '@/components/Loader'
import { useDispatch } from 'react-redux'
import { userActions } from '@/entity'
import { verificationActions } from '@/entity/verification'

export const Deletion = ({ onClose }) => {
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onDelete = async () => {
		try {
			setIsLoading(true)
			const response = await api.profile.cleanPersonProfile()
			dispatch(userActions.setUser(response))
			dispatch(verificationActions.resetVerification())
			message.success('Операция успешно выполнена')
			onClose()
		} catch (error) {
			message.error('Ошибка:' + error.response ? error?.response?.data?.message : 'Неизвестная ошибка')
		} finally {
			setIsLoading(false)
		}
	}

	return isLoading ? (
		<LoadingBlocker />
	) : (
		<ConfirmModal
			onProcess={onDelete}
			onCancel={onClose}
			cancelButtonTitle={'cancel'}
			processButtonTitle={'delete_sign'}
			hideBackButton
			priority={2}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<img src={warningIcon} alt="warningIcon" />
				<div className={styles.content__title}>
					<Title>{t('attention')}</Title>
				</div>
				<div className={styles.content__body}>{t('delete_sign_title')}</div>
			</div>
		</ConfirmModal>
	)
}
