import { useTranslation } from 'react-i18next'
import styles from './RequestEditForm.module.scss'
import stylesTerm from './RequestTerminateForm.module.scss'
import { InfoBox } from '@/ui/atoms/infoBox'
import { InfoMedium } from '@app/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { getCurrentLanguage } from '@/i18next'
import {
	AdditionalContractField,
	EditContractDataField,
	SocialLeaveField,
	TerminationField,
	AdditionalInfoField,
} from './fields'
import { Formik, Form, Field as FormikField } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { userSelectors } from '@/entity'
import { Chevron } from '@/ui/atoms/chevron'
import { ViewConfirmDocument } from '@features/laborRecords/components/document/ViewConfirmDocument'
import { ConfirmDocument } from '@features/laborRecords/components/document/ConfirmDocument'
import { Button, Field, Title } from '@/ui'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { RequestSocialSchema } from './RequestEdit.schema'
import { NotificationError } from '@features/laborRecords/components/NotificationError'
export const RequestEditForm = ({
	shortContractData,
	terminationReasons,
	onSubmit,
	isView,
	onClose,
	onToggleContractAction,
}) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const activeRole = useSelector(userSelectors.activeRole)
	const positions = useSelector(handbookSelectors.positions)
	const positionsByKnz = useSelector(handbookSelectors.positionsByKnz)
	const socialLeaveTypes = useSelector(handbookSelectors.socialLeaveTypes)
	useEffect(() => {
		if (!positions.length) dispatch(handbookThunks.getPositions())
		if (!positionsByKnz.length) dispatch(handbookThunks.getPositionsByKnz())
		if (!socialLeaveTypes.length) dispatch(handbookThunks.getSocialLeaveTypes())
	}, [dispatch, positions.length, positionsByKnz.length, socialLeaveTypes.length])
	const body = useMemo(
		() =>
			lang === 'rus'
				? [
						'Открываете необходимую вкладку в которой хотите произвести изменения;',
						'С помощью галочки активируете поле в котором хотите произвести изменения;',
						'Вводите новое значение в выбранном поле;',
						'При необходимости можно выбрать более одного поля;',
						'Прикрепляете подтверждающие документы (скан либо фото);',
						'Обязательно указываете Ваши контактные данные (номер телефона, почту) для связи с Вами в случае возникновения вопросов;',
						'В поле “Комментарий” пишите причину по которой вы хотите произвести изменения в трудовом договоре;',
						'Подписываете запрос с помощью Вашего ЭЦП;',
						'Запрос обрабатывается до 3 рабочих дней. Результат вы сможете увидеть во вкладке “Запросы на корректировку”, либо при открытии самого договора',
				  ]
				: [
						'Өзгерістер жасағыңыз келетін қажетті қойындыны ашыңыз;',
						'Құсбелгіні пайдаланып өзгертулер енгізгіңіз келетін өрісті іске қосыңыз;',
						'Таңдалған өріске жаңа мәнді енгізіңіз;',
						'Қажет болса, бірнеше өрісті таңдауға болады;',
						'Растайтын құжаттарды тіркеңіз (скан немесе фото);',
						'Сұрақтар туындаған жағдайда Сізбен байланысу үшін байланыс деректерін (телефон нөмірі, пошта) көрсетуді ұмытпаңыз;',
						'Түсініктеме" өрісінде еңбек шартына өзгерістер енгізгіңіз келетін себебін жазыңыз;',
						'ЭЦҚ көмегімен сұрауға қол қойыңыз;',
						'Сұрау 3 жұмыс күніне дейін өңделеді. Нәтижені сіз "түзету сұраулары" қойындысында немесе шарттың өзін ашқан кезде көре аласыз',
				  ],
		[lang]
	)
	const {
		contractNumber,
		phone,
		email,
		comment,
		note,
		docList,
		dposition,
		destablishedPost,
		establishedPost,
		terminationDate,
		contractDate,
		editContractData,
		editTerminationData,
		terminationReasons: saveTerminationReasons,
		subsidiaryContractList,
		editSubContractData,
		socialLeaveDataList,
		editSocialLeaveData,
		contractId,
		requestStatus,
	} = shortContractData
	const [destablishedPostCode, setDestablishedPostCode] = useState(null)
	const firstTerminationReason = useMemo(
		() => (saveTerminationReasons != null ? saveTerminationReasons[0].code : null),
		[saveTerminationReasons]
	)
	const viewTerminationReasons = useMemo(
		() => (editTerminationData?.terminationReasons != null ? editTerminationData?.terminationReasons : null),
		[editTerminationData?.terminationReasons]
	)
	const position = useMemo(() => (dposition != null ? dposition.code : null), [dposition])
	const viewPosition = useMemo(
		() => (editContractData?.dposition != null ? editContractData?.dposition.code : null),
		[editContractData?.dposition]
	)
	useEffect(() => {
		if (destablishedPost != null) {
			lang === 'rus'
				? setDestablishedPostCode(destablishedPost.nameRu)
				: setDestablishedPostCode(destablishedPost.nameKz)
		}
	}, [destablishedPost, lang])
	useEffect(() => {
		if (editContractData?.destablishedPost != null) {
			lang === 'rus'
				? setDestablishedPostCode(editContractData.destablishedPost.nameRu)
				: setDestablishedPostCode(editContractData.destablishedPost.nameKz)
		}
	}, [editContractData?.destablishedPost, lang])
	const [chevron, setChevron] = useState(false)
	const [chevronSoc, setChevronSoc] = useState(false)
	const changeChevron = () => {
		chevron ? setChevron(false) : setChevron(true)
	}
	const changeChevronSoc = () => {
		chevronSoc ? setChevronSoc(false) : setChevronSoc(true)
	}
	const title_additional_contract = useMemo(() => t('labor_records.additional_contract'), [t])
	const title_social = useMemo(() => t('labor_records.social_leave'), [t])
	const [documentList, setDocList] = useState([])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [additionalReasons, setAdditionalReasons] = useState([])
	const getDocument = (docList) => {
		setDocList(docList)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}
	const getAdditionalReasons = (additionalReasons) => {
		setAdditionalReasons(additionalReasons)
	}
	const availabilityValidSocialLeaves = (socialLeaves) => {
		let returnArr = null
		let socLeaves = socialLeaves.map((item) => ({
			socialLeaveId:
				!!item.beginDateIs || !!item.daysOffCountIs || !!item.endDateIs || !!item.workDateIs
					? item.socialLeaveId
					: null,
		}))
		const newArr = [...socLeaves]
		for (let i = 0; i < socLeaves.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.socialLeaveId == null)
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length !== 0
	}
	const availabilityAdditionalContract = (additionalContracts) => {
		let returnArr = null
		let addContract = additionalContracts.map((item) => ({
			subContractId:
				!!item.establishedPositionIs ||
				!!item.dpositionCodeIs ||
				!!item.establishedPositionIs ||
				!!item.subsidiaryContractNumberIs
					? item.subContractId
					: null,
		}))
		const newArr = [...addContract]
		for (let i = 0; i < addContract.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.subContractId == null)
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length !== 0
	}
	const createSocialLeavesObj = (socialLeaves) => {
		let returnArr = null
		let socLeaves = socialLeaves.map((item) => ({
			beginDate: item.beginDateIs ? item.beginDate : null,
			daysOffCount: item.daysOffCountIs ? item.daysOffCount : null,
			endDate: item.endDateIs ? item.endDate : null,
			workDate: item.workDateIs ? item.workDate : null,
			socialLeaveId:
				!!item.beginDateIs || !!item.daysOffCountIs || !!item.endDateIs || !!item.workDateIs
					? item.socialLeaveId
					: null,
		}))
		const newArr = [...socLeaves]
		for (let i = 0; i < socLeaves.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.socialLeaveId == null)
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length === 0 ? null : returnArr
	}
	const createAdditionalContractObj = (additionalContracts) => {
		let returnArr = null
		let addContract = additionalContracts.map((item) => ({
			destablishedPostCode: item.establishedPositionIs ? item.destablishedPostCode?.code : null,
			dpositionCode: item.dpositionCodeIs ? item.dpositionCode.code : null,
			establishedPost: item.establishedPositionIs ? item.establishedPost : null,
			subContractNumber: item.subsidiaryContractNumberIs ? item.subsidiaryContractNumber : null,
			subContractId:
				!!item.establishedPositionIs ||
				!!item.dpositionCodeIs ||
				!!item.establishedPositionIs ||
				!!item.subsidiaryContractNumberIs
					? item.subContractId
					: null,
		}))
		const newArr = [...addContract]
		for (let i = 0; i < addContract.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.subContractId == null)
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length === 0 ? null : returnArr
	}

	const initState = {
		availability: false,
		minEndDate: false,
		maxEndDate: false,
	}

	const [errorsType, setErrorsType] = useState(initState)
	const onToggleErrorsType = (type, value) => {
		setErrorsType((prevState) => {
			return { ...prevState, [type]: value }
		})
	}
	const resetErrorsType = () => {
		setErrorsType(initState)
	}
	const getFormatDate = (value) => {
		const dateArr = value?.split('.')
		if (dateArr) {
			return `${dateArr?.[1]}.${dateArr?.[0]}.${dateArr?.[2]}`
		} else {
			return false
		}
	}
	const validationMinEndDate = (socialLeaves) => {
		let returnArr = null
		let socLeaves = socialLeaves.map((item) => ({
			socialLeaveId:
				new Date(getFormatDate(item.beginDate)) < new Date(getFormatDate(item.endDate)) ? item.socialLeaveId : null,
		}))
		const newArr = [...socLeaves]
		for (let i = 0; i < socLeaves.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.socialLeaveId == null)
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length !== 0
	}

	const validationMaxEndDate = (socialLeaves) => {
		let returnArr = null
		let socLeaves = socialLeaves.map((item) => ({
			socialLeaveId:
				new Date(
					new Date(getFormatDate(item.beginDate)).setDate(new Date(getFormatDate(item.beginDate)).getDate() + 184)
				) > new Date(getFormatDate(item.endDate)) && item.dleaveType.code === '1'
					? item.socialLeaveId
					: null,
			dleaveType: item.dleaveType,
		}))
		const newArr = [...socLeaves]
		for (let i = 0; i < socLeaves.length; i++) {
			let deleteIndex = null
			deleteIndex = newArr.findIndex((item) => item.socialLeaveId == null && item.dleaveType.code === '1')
			if (deleteIndex !== -1) newArr.splice(deleteIndex, 1)
			returnArr = newArr
		}
		return returnArr.length !== 0
	}
	const availabilityValid = (data) => {
		let validSoc = false
		let validAddContract = false
		let validContract = false
		if (!(data.socialLeaves == null)) validSoc = availabilityValidSocialLeaves(data.socialLeaves)
		if (!(data.additionalContracts == null)) validAddContract = availabilityAdditionalContract(data.additionalContracts)
		if (
			!!data.contractNumberIs ||
			!!data.establishedPositionIs ||
			!!data.dpositionCodeIs ||
			!!data.terminationDateIs ||
			!!data.terminationReasonsIs
		)
			validContract = true
		if (validSoc || validAddContract || validContract) {
			let validMinEndDate = true
			if (!(data.socialLeaves == null)) validMinEndDate = validationMinEndDate(data.socialLeaves)
			if (validMinEndDate) {
				let validMaxEndDate = true
				if (!(data.socialLeaves == null)) validMaxEndDate = validationMaxEndDate(data.socialLeaves)
				if (validMaxEndDate) handleDataProcess(data)
				else return onToggleErrorsType('maxEndDate', true)
			} else return onToggleErrorsType('minEndDate', true)
		} else return onToggleErrorsType('availability', true)
	}
	const handleDataProcess = (data) => {
		let _editSocialLeaveData = null
		let _editSubContractData = null
		if (!(data.socialLeaves == null)) _editSocialLeaveData = createSocialLeavesObj(data.socialLeaves)
		if (!(data.additionalContracts == null))
			_editSubContractData = createAdditionalContractObj(data.additionalContracts)
		const toSubmit = {
			comment: data.comment,
			contractId: contractId,
			docList: documentList,
			email: data.email,
			phone: data.phone,
			editContractData:
				!!data.contractNumberIs == null && !!data.establishedPositionIs == null && !!data.dpositionCodeIs == null
					? null
					: {
							contractNumber: data.contractNumberIs ? data.contractNumber : null,
							destablishedPostCode:
								data.establishedPositionIs && data.destablishedPostCode ? data.destablishedPostCode : null,
							dpositionCode: data.dpositionCodeIs ? data.dpositionCode : null,
							establishedPost: data.establishedPositionIs && data.establishedPost,
					  },
			editSocialLeaveData: _editSocialLeaveData,
			editSubContractData: _editSubContractData,
			editTerminationData:
				!!data.terminationDateIs || !!data.terminationReasonsIs
					? {
							terminationDate: data.terminationDateIs ? data.terminationDate : terminationDate,
							terminationReasonCodes: [data.terminationReason, ...additionalReasons],
					  }
					: null,
		}
		onSubmit(toSubmit)
	}
	const actionConfirm = () => {
		onToggleContractAction('confirm', true)
	}
	const actionReject = () => {
		onToggleContractAction('reject', true)
	}
	return (
		<>
			<div className={styles.wrapper}>
				<InfoBox>
					<div className={'flex align-center'}>
						<InfoMedium className={styles.hintIconMedium}></InfoMedium>
						<div className={styles.iconText}>{t('labor_records.instruction')}</div>
					</div>
					<div className={styles.inherit}>{t('labor_records.info_box_title')}</div>
					<div className={styles.infoText}>
						<ol>
							{body.map((item) => {
								return <li key={item}>{item}</li>
							})}
						</ol>
					</div>
				</InfoBox>
				<Formik
					initialValues={{
						contractNumber: isView ? editContractData?.contractNumber : contractNumber,
						dpositionCode: isView ? viewPosition : position,
						destablishedPostCode: destablishedPostCode,
						establishedPost: isView ? editContractData?.establishedPost : establishedPost,
						terminationDate: isView ? editTerminationData?.terminationDate : terminationDate,
						contractDate: contractDate,
						terminationReason: firstTerminationReason,
						additionalContracts: isView ? editSubContractData : subsidiaryContractList,
						socialLeaves: isView ? editSocialLeaveData : socialLeaveDataList,
						phone: isView ? phone : '',
						email: isView ? email : '',
						comment: isView ? comment : '',
						note: isView ? note : '',
					}}
					validationSchema={RequestSocialSchema(contractDate)}
					enableReinitialize
					onSubmit={availabilityValid}
				>
					{({ isValid, errors }) => {
						return (
							<Form>
								{isView && editContractData && positions.length > 0 && positionsByKnz.length > 0 ? (
									<EditContractDataField
										positions={positions}
										positionsByKnz={positionsByKnz}
										isView={isView}
									></EditContractDataField>
								) : null}
								{!isView && positions.length > 0 && positionsByKnz.length > 0 ? (
									<EditContractDataField
										positions={positions}
										positionsByKnz={positionsByKnz}
										isView={isView}
									></EditContractDataField>
								) : null}
								{isView && editTerminationData ? (
									<TerminationField
										contractDate={contractDate}
										terminationReasons={terminationReasons}
										saveTerminationReasons={saveTerminationReasons}
										getAdditionalReasons={getAdditionalReasons}
										viewTerminationReasons={viewTerminationReasons}
										isView={isView}
									></TerminationField>
								) : null}
								{!isView ? (
									<TerminationField
										contractDate={contractDate}
										terminationReasons={terminationReasons}
										saveTerminationReasons={saveTerminationReasons}
										getAdditionalReasons={getAdditionalReasons}
										isView={isView}
									></TerminationField>
								) : null}
								{!isView && subsidiaryContractList ? (
									<Chevron chevron={chevron} title={title_additional_contract} changeChevron={changeChevron}></Chevron>
								) : null}
								{!isView && chevron && subsidiaryContractList && positions.length > 0 && positionsByKnz.length > 0 ? (
									<AdditionalContractField
										positions={positions}
										positionsByKnz={positionsByKnz}
										isView={isView}
									></AdditionalContractField>
								) : null}
								{isView && editSubContractData ? (
									<Chevron chevron={chevron} title={title_additional_contract} changeChevron={changeChevron}></Chevron>
								) : null}
								{isView && chevron && editSubContractData && positions.length > 0 && positionsByKnz.length > 0 ? (
									<AdditionalContractField
										positions={positions}
										positionsByKnz={positionsByKnz}
										isView={isView}
									></AdditionalContractField>
								) : null}
								{!isView && socialLeaveDataList ? (
									<div className={styles.wrapper}>
										<Chevron chevron={chevronSoc} title={title_social} changeChevron={changeChevronSoc}></Chevron>
									</div>
								) : null}
								{!isView && chevronSoc && socialLeaveDataList && socialLeaveTypes ? (
									<SocialLeaveField
										contractDate={contractDate}
										socialLeaveTypes={socialLeaveTypes}
										isView={isView}
									></SocialLeaveField>
								) : null}
								{isView && editSocialLeaveData ? (
									<div className={styles.wrapper}>
										<Chevron chevron={chevronSoc} title={title_social} changeChevron={changeChevronSoc}></Chevron>
									</div>
								) : null}
								{isView && chevronSoc && editSocialLeaveData && socialLeaveTypes ? (
									<SocialLeaveField
										contractDate={contractDate}
										socialLeaveTypes={socialLeaveTypes}
										isView={isView}
									></SocialLeaveField>
								) : null}
								{isView ? (
									<div className={styles.wrapper}>
										<Title>{t('labor_records.confirming_document')}</Title>
										<ViewConfirmDocument data={docList}></ViewConfirmDocument>
									</div>
								) : (
									<ConfirmDocument getDocument={getDocument} getErrorSize={getErrorSize}></ConfirmDocument>
								)}
								<div className={styles.wrapper}>
									<Title>{t('labor_records.additional_information')}</Title>
									<AdditionalInfoField isView={isView}></AdditionalInfoField>
									{isView ? (
										<>
											{requestStatus?.code === '1' ? null : (
												<div className={stylesTerm.answer}>
													<Title>{t('labor_records.note')}</Title>
													<FormikField name={formFieldNames.note}>
														{({ field, meta: { touched, error } }) => (
															<Field
																type="text"
																fieldType={'textarea'}
																isRequired
																label={t('labor_records.comment')}
																error={touched && error ? t(error) : ''}
																{...field}
																disabled
																labelClassName={stylesTerm.field}
															/>
														)}
													</FormikField>
												</div>
											)}
											<div className={stylesTerm.cancel__view}>
												{activeRole !== 'ROLE_EMPLOYEE' ? (
													<>
														<Button onClick={actionConfirm} disabled={!(requestStatus?.code === '1')}>
															{t('labor_records.confirm')}
														</Button>
														<Button onClick={actionReject} disabled={!(requestStatus?.code === '1')}>
															{t('labor_records.reject')}
														</Button>
													</>
												) : null}
												<Button onClick={onClose}>{t('close')}</Button>
											</div>
										</>
									) : (
										<div className={stylesTerm.actions}>
											<div className={stylesTerm.cancel__button} onClick={onClose}>
												{t('labor_records.cancel')}
											</div>
											<Button disabled={!isValid || disableErrorSize || documentList.length === 0} type="submit">
												{t('sign_and_send')}
											</Button>
										</div>
									)}
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
			{(errorsType.availability || errorsType.minEndDate || errorsType.maxEndDate) && (
				<NotificationError
					handleProcess={resetErrorsType}
					handleCancel={resetErrorsType}
					errorsType={errorsType}
				></NotificationError>
			)}
		</>
	)
}
