import React, { useEffect, useState } from 'react'
import styles from './RadioPanel.module.scss'
import { useTranslation } from 'react-i18next'
import Radio from '@/components/Radio/Radio'
import { useDispatch } from 'react-redux'
import { formsThunks } from '@/entity/forms'
import SelectButton from '@/components/entrance/SelectButton'
import Captcha from '@/components/Captcha/Captcha'

export const RadioPanel = ({ getEmployee, getCompany, getQuestionnaire }) => {
	const dispatch = useDispatch()
	const [q, setQ] = useState([])
	const [company, setCompany] = useState([])
	const [employee, setEmployee] = useState(0)
	const [questionnaire, setQuestionnaire] = useState([
		{ code: 'Q1', value: false },
		{ code: 'Q2', value: false },
		{ code: 'Q3', value: false },
		{ code: 'Q4', value: false },
		{ code: 'Q5', value: false },
	])
	const [disable, setDisable] = useState(true)
	const [captchaValue, setCaptchaValue] = useState('[empty]')

	const countEmployee = (e) => {
		if (e.target.value < 0) {
			setEmployee(0)
		} else {
			setEmployee(e.target.value)
		}
	}

	const handleSelect = (e) => {
		e.target.select()
	}

	const getCaptchaValue = (value) => {
		setCaptchaValue(value)
	}

	useEffect(() => {
		setDisable(true)
		if (
			captchaValue !== '[empty]' &&
			Object.keys(q).length === 5 &&
			Object.keys(company).length > 0 &&
			Object.keys(employee).length > 0 &&
			employee > 0
		)
			setDisable(false)
	}, [company, q, employee, captchaValue])

	//TODO
	const handleChange = (e) => {
		const { name, value } = e.target
		setQ((prevQValues) => ({
			...prevQValues,
			[name]: value,
		}))
		let newQuestionnaire = questionnaire
		newQuestionnaire.forEach((item) => {
			if (item.code === name) {
				if (name === value) item.value = true
				else item.value = false
			}
		})
		setQuestionnaire(newQuestionnaire)
	}

	const smlHandleChange = (e) => {
		const { name, value } = e.target
		setCompany((prevSelectedValues) => ({
			...prevSelectedValues,
			[name]: value,
		}))
		getCompany(value)
	}

	const onSubmitAnswerForms = (e) => {
		e.preventDefault()
		getEmployee(employee)
		const codeAnswer = Object.values(q)
		getQuestionnaire(questionnaire)
		const postTest = codeAnswer.filter((codeAnswer) => codeAnswer.length < 3)
		dispatch(formsThunks.searchSectionForms(postTest, captchaValue))
	}

	const { t } = useTranslation()
	return (
		<div className={styles.radioPanel}>
			<table>
				<tbody>
					<tr>
						<td className={styles.tdBorder}>
							<p className={styles.bolt}>{t('category_entrepreneurship')}</p>
							<Radio form={company} name={'sml'} label={t('small')} id="S" handleChange={smlHandleChange} />
							<Radio form={company} name={'sml'} label={t('medium')} id="M" handleChange={smlHandleChange} />
							<Radio form={company} name={'sml'} label={t('large')} id="L" handleChange={smlHandleChange} />
							<p className={styles.bolt}>{t('count_employee')}</p>
							<p>
								<input
									id="typeinp"
									className={styles.inputWith}
									type="number"
									onInput={countEmployee}
									placeholder={t('enter_number')}
									value={employee}
									onClick={handleSelect}
								/>
							</p>
							<p className={styles.bolt}>{t('you_employees_disabilities_groups')}</p>
							<Radio form={q} name="Q1" label={t('yes')} id="Q1" handleChange={handleChange} />
							<Radio form={q} name="Q1" label={t('no')} id="no1" handleChange={handleChange} />
							<p className={styles.bolt}>{t('people_under_age_eighteen_work')}</p>
							<Radio form={q} name="Q2" label={t('yes')} id="Q2" handleChange={handleChange} />
							<Radio form={q} name="Q2" label={t('no')} id="no2" handleChange={handleChange} />
						</td>
						<td className={styles.tdBorder}>
							<p className={styles.bolt}>{t('pregnant_women_work_you')}</p>
							<Radio form={q} name="Q3" label={t('yes')} id="Q3" handleChange={handleChange} />
							<Radio form={q} name="Q3" label={t('no')} id="no3" handleChange={handleChange} />
							<p className={styles.bolt}>{t('shift_method_work')}</p>
							<Radio form={q} name="Q4" label={t('yes')} id="Q4" handleChange={handleChange} />
							<Radio form={q} name="Q4" label={t('no')} id="no4" handleChange={handleChange} />
							<p className={styles.boltSpetial}>{t('harmful_dangerous_working_conditions')}</p>
							<Radio form={q} name="Q5" label={t('yes')} id="Q5" handleChange={handleChange} />
							<Radio form={q} name="Q5" label={t('no')} id="no5" handleChange={handleChange} />
							<p className={styles.boltPadding}></p>
						</td>
					</tr>
				</tbody>
			</table>
			<div className={styles.identCaptcha}>
				<Captcha recaptcha={getCaptchaValue} />
			</div>
			<p className={styles.ident}>
				<SelectButton iconsize={'big'} onClick={onSubmitAnswerForms} disabled={disable}>
					{t('pass_self_test')}
				</SelectButton>
			</p>
		</div>
	)
}
