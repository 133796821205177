import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	checks: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	themes: [],
	questions: [],
	theme: null,
	statusCheck: false,
	testResult: [],
	pdf: null,
	selectedCheck: {},
	checksAll: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	isReload: false,
	resultTypes: []
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_CHECKS:
			return { ...state, checks: payload }
		case types.THEMES:
			return { ...state, themes: payload }
		case types.QUESTIONS:
			return { ...state, questions: payload }
		case types.THEME:
			return { ...state, theme: payload }
		case types.STATUSCHECK:
			return { ...state, statusCheck: payload }
		case types.TESTRESULT:
			return { ...state, testResult: payload }
		case types.PDF:
			return { ...state, pdf: payload }
		case types.SET_SELECTED_CHECK:
			return { ...state, selectedCheck: payload }
		case types.CHECKS_ALL:
			return { ...state, checksAll: payload }
		case types.RESULT_TYPES:
			return { ...state, resultTypes: payload }
		default:
			return state
	}
}
