import React from 'react'
import { Loader } from './Loader'
import styles from './Loader.module.scss'

export const LoadingScreen = ({ children }) => {
	return (
		<div className={styles.screen}>
			<Loader />
			{children}
		</div>
	)
}
