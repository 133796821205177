import { LoadingBlocker } from '@/components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { contractsActions, contractsSelectors, contractsThunks } from '@/entity'
import { request } from '@/shared/api/request'
import { CreateEditAdditionalAgreementModal } from './CreateEditAdditionalAgreementModal'
import { RemoveAdditionalAgreementModal } from './RemoveAdditionalAgreementModal'
import React, { useCallback, useEffect, useState } from 'react'
import { AdditionalDecline } from './AdditionalDecline'
import { ViewAdditionalAgreementModal } from './ViewAdditionalAgreementModal'
import { useTranslation } from 'react-i18next'
import { EmployeeRevision } from '../actions/view/components/EmployeeRevision'
import { RevertModal } from '@features/contracts/actions/view/components'
import { message } from 'antd'
import { SignAdditionalAgreementModal } from '@features/contracts/additionalContracts/SignAdditionalAgreementModal'
import { useDigitalSign } from '@/shared'
import { RecoverReject } from '@features/contracts/actions'

export const AdditionalAgreementMain = ({
	onClose,
	mode,
	onToggleAction,
	page,
	pageSize,
	filters,
	setFilters,
	fetchData,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const xml = useSelector(contractsSelectors.additionalContractXml)
	const isThunkLoading = useSelector(contractsSelectors.additionalContractIsLoading)
	const isSuccess = useSelector(contractsSelectors.additionalContractSubmitted)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const additionalContract = useSelector(contractsSelectors.additionalContract)
	const contract = useSelector(contractsSelectors.contract)
	const [isOpenRevert, setIsOpenRevert] = useState(false)
	const [isEmployeeDeclineOpen, setIsEmployeeDeclineOpen] = useState(false)
	const [isEmployeeRevisionOpen, setIsEmployeeRevisionOpen] = useState(false)
	const [paramMap, setParamMap] = useState({})
	useEffect(() => xml && onSign(xml), [xml]) /** подписание xml */
	useEffect(() => {
		if (mode === 'decline') {
			setIsEmployeeDeclineOpen(true)
		}
	}, [mode])
	/** очистка и выход при удачной операции */
	useEffect(() => {
		if (isSuccess) {
			dispatch(contractsActions.resetAdditionalContract())
			onClearPayload()
			onClose()
		}
	}, [isSuccess])
	const updateData = () => {
		dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
		dispatch(contractsActions.setAdditionalContractSubmitted())
		dispatch(contractsThunks.getContracts(filters, page, pageSize))
		message.success(t('success_alert'), 5)
		onClose()
	}
	/** отправка подписанного XML файла */
	useEffect(() => {
		if (successPayload && !isThunkLoading) {
			dispatch(contractsThunks.sendAdditionalContract(successPayload, paramMap, page, pageSize, filters))
			setParamMap({})
		}
	}, [successPayload])
	/** сброс redux параметров при ошибке NCALayer */
	useEffect(() => {
		if (failedPayload) {
			dispatch(contractsActions.setAdditionalContractXml(null))
		}
	}, [failedPayload])

	const onRevertEmployer = async () => {
		try {
			const response = await request({
				url: '/contract/eAdContract/employer/revert',
				method: 'POST',
				params: { externalId: additionalContract?.externalId },
			})
			if (response.success) {
				updateData()
				setIsOpenRevert(false)
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			dispatch(
				contractsActions.declineEmployer({
					data: null,
					loading: false,
					error: err,
				})
			)
		}
	}

	const sendRevision = async (data) => {
		try {
			await request({
				url: 'contract/eAdContract/employee/sendForImprovement',
				method: 'POST',
				params: data,
			})
			updateData()
			setIsEmployeeRevisionOpen(false)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}
	const onOpenRevision = () => setIsEmployeeRevisionOpen(true)
	const onOpenRevert = () => setIsOpenRevert(true)

	const onRevisionDefault = async (callback, note) => {
		setIsEmployeeRevisionOpen(true)
		const result = {
			externalId: additionalContract.externalId,
			note,
		}
		await callback(result)
		setIsEmployeeRevisionOpen(false)
	}
	const onRevision = async (note) => await onRevisionDefault(sendRevision, note)

	const onSignLocal = useCallback(
		() => dispatch(contractsThunks.saveAdditionalContract(additionalContract)),
		[additionalContract, dispatch]
	)
	return (
		<>
			{mode === 'view' ? (
				<ViewAdditionalAgreementModal
					onRevert={onOpenRevert}
					onRevision={onOpenRevision}
					onToggleAction={onToggleAction}
					onClose={onClose}
					filters={filters}
					page={page}
					pageSize={pageSize}
					setFilters={setFilters}
					fetchData={fetchData}
					onSign={onSignLocal}
				/>
			) : null}
			{mode === 'remove' && additionalContract?.employeeRequest && additionalContract?.dcontractState?.code === '1' ? (
				<RecoverReject handleProcess={onClose} handleCancel={onClose} />
			) : null}
			{mode === 'remove' && !additionalContract?.employeeRequest ? (
				<RemoveAdditionalAgreementModal onCancel={onClose} externalId={additionalContract.externalId} />
			) : null}
			{mode === 'create' ? (
				<CreateEditAdditionalAgreementModal
					setParamMap={setParamMap}
					onClose={onClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			) : null}
			{mode === 'edit' ? (
				<CreateEditAdditionalAgreementModal
					setParamMap={setParamMap}
					onClose={onClose}
					isEdit={true}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			) : null}
			{mode === 'sign' ? (
				<SignAdditionalAgreementModal
					onCancel={onClose}
					additionalContract={additionalContract}
					paramMap={paramMap}
					setParamMap={setParamMap}
					contract={contract}
					filters={filters}
					page={page}
					pageSize={pageSize}
				/>
			) : null}
			{isOpenRevert ? (
				<RevertModal
					title={'revoke_eds'}
					onClose={setIsOpenRevert.bind(null, false)}
					onRevert={onRevertEmployer}
					isOpen={isOpenRevert}
				/>
			) : null}
			{mode === 'decline' ? (
				<AdditionalDecline
					title="sure_decline_eds"
					onClose={onClose}
					isOpen={isEmployeeDeclineOpen}
					additionalContract={additionalContract}
					contract={contract}
				/>
			) : null}
			{isEmployeeRevisionOpen ? (
				<EmployeeRevision
					title="el_cause_eds"
					onClose={setIsEmployeeRevisionOpen.bind(null, false)}
					sendForImprovement={onRevision}
					isOpen={isEmployeeRevisionOpen}
				/>
			) : null}
			{!isSuccess && isThunkLoading ? <LoadingBlocker /> : null}
		</>
	)
}
