import { Button as DialogButton, Field } from '@/ui'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../ContractCard.module.scss'
import CloseButton from '@/components/CloseButton/CloseButton'

export const EmployeeDecline = ({ title = 'sure_decline', onClose, onDecline, isOpen }) => {
	const { t } = useTranslation()
	const [note, setNote] = useState('')

	return (
		<Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={isOpen}>
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: styles.sign__title }}>{t(title)}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					<Field
						value={note}
						onChange={(e) => {
							setNote(e)
						}}
						wrapperClassName={styles.textarea__wrapper}
						placeholder={t('el_cause_short')}
						rows={8}
						fieldType="textarea"
					/>
					<div className={styles.reset__button__wrapper}>
						<DialogButton type="button" onClick={onDecline.bind(null, note)} disabled={!note}>
							{t('decline')}
						</DialogButton>
					</div>
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button, styles.reset__button) }}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
