import { Field, useFormikContext } from 'formik'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './Form.module.scss'
import { Radio } from '@/entity/contracts/ui/form/components/Radio'
import { useEffect } from 'react'

export const Form = ({ getContractStatusCode }) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()

	useEffect(() => {
		getContractStatusCode(values.dcontractStatusCode)
	}, [getContractStatusCode, values])

	return (
		<Field name="dcontractStatusCode">
			{({ field, name, children, ...props }) => {
				const fieldName = name || field?.name
				return (
					<RadioGroup classes={{ root: styles.radioGroup }} {...field} {...props} name={fieldName}>
						<FormControlLabel
							control={<Radio />}
							label={t('contracts_tab.current_list_treaties')}
							value="8"
							classes={{ root: styles.label__root }}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t('contracts_tab.all_contracts')}
							value="7"
							classes={{ root: styles.label__root }}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t('contracts_tab.current_contracts')}
							value="1"
							classes={{ root: styles.label__root }}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t('contracts_tab.terminate_contracts')}
							value="2"
							classes={{ root: styles.label__root }}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t('contracts_tab.overdue_contracts')}
							value="3"
							classes={{ root: styles.label__root }}
						/>
					</RadioGroup>
				)
			}}
		</Field>
	)
}
