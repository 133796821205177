export const StateCode = {
	SIGNED: '1',
	TERMINATED: '2',
	IN_DEVELOP: '3',
	ON_SIGN_EMPLOYEE: '4',
	IN_PROGRESS: '5',
	REJECT_UNION: '6',
	ON_SIGN_EMPLOYER: '7',
	EXPIRED: '8',
	REJECT_EMPLOYER: '9',
}
export const StatusId = {
	ETD: 6,
}

export const readyTerminate = (code) => [StateCode.SIGNED].includes(code)
export const inProcess = (code) =>
	[StateCode.IN_DEVELOP, StateCode.IN_PROGRESS, StateCode.EXPIRED, StateCode.REJECT_UNION].includes(code)
