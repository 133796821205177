import React, { useEffect } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { useDispatch, useSelector } from 'react-redux'
import { FirstForm } from './FirstForm'
import { ValidationSchema } from './validationSchema'

export const StaffingFirstStructure = ({ initialValues, actions, onClose, staffProject, setRowSelectMenu }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isSuccess = useSelector(staffSelectors.isSuccess)

	useEffect(() => {
		if (isSuccess) {
			if (actions.edit) {
				let destructStaff = { level: 0, dept: staffProject.childs[0].dept, ...staffProject }
				delete destructStaff.childs
				setRowSelectMenu(destructStaff)
			}
			onClose()
		}
	}, [isSuccess])
	const onSubmit = (data) => {
		if (actions.edit) {
			let editStaffing = {
				dept: {
					nameKz: data.nameKz,
					nameRu: data.nameRu,
				},
				note: data.note,
				orderDate: data.orderDate,
				orderNumber: data.orderNumber,
			}
			dispatch(staffThunks.editProject(editStaffing))
		} else {
			dispatch(staffThunks.createProject(data))
		}
	}
	return (
		<div>
			<Title color={'black'}>{t('staff_modal.title_staffing_first')}</Title>
			<Formik validationSchema={ValidationSchema} initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
				<FirstForm onClose={onClose} isEdit={actions.edit} />
			</Formik>
		</div>
	)
}
