import React, { useState } from 'react'
import styles from './statusProject.module.scss'
import { Clock, Edit, Success, InfoMedium } from '@app/icons'
import { useTranslation } from 'react-i18next'

const statuses = {
	0: {
		icon: <Clock className={styles.iconGrey} />,
		wrapperClassName: styles.labelText,
	},
	1: {
		icon: <Success className={styles.iconGreen} />,
		wrapperClassName: styles.labelText,
	},
	2: {
		icon: <Edit className={styles.iconGreen} />,
		wrapperClassName: styles.labelText,
	},
	3: {
		icon: <InfoMedium className={styles.iconRed} />,
		wrapperClassName: styles.labelError,
	},
}

export const StatusProject = ({ status, staffStatus, toggleIsError }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const statusElement = statuses[status] ?? statuses[3]
	const statusName = staffStatus.find(({ code }) => code === status)
	return (
		<div
			className={styles.information}
			onClick={() => {
				if (status === '3') {
					toggleIsError(true)
				}
			}}
		>
			<>{statusElement.icon ?? statusElement.icon}</>
			<div className={statusElement.wrapperClassName}>{t('staff.status') + statusName[language]}</div>
		</div>
	)
}
