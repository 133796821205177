import styles from './CompanyStatistic.module.scss'
import cn from 'classnames'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const formatNumber = (val) => {
	if (!val) return '-'
	if (val >= 1000000) {
		return (val / 1000000).toFixed(1) + 'M'
	} else if (val >= 1000) {
		return (val / 1000).toFixed(1) + 'K'
	} else {
		return val.toString()
	}
}
const mapRiskTypeToClass = {
	Средний: 'risk_container_medium',
	Высокий: 'risk_container_high',
	Отсутствует: 'risk_container_none',
}

const mapRiskToKZ = {
	Средний: 'Орташа',
	Высокий: 'Жоғары',
	Отсутствует: 'Жоқ',
}

const TopBlock = ({ title, content, component }) => {
	const { t } = useTranslation()
	return (
		<div className={cn(styles.base_block, styles.top_block)}>
			<div>{t(title)}</div>
			{content && <Typography variant={'subtitle_xxl'}>{content}</Typography>}
			{component}
		</div>
	)
}
const BottomBlock = ({ title, subTitle, content, subTitleColor }) => {
	const { t } = useTranslation()
	return (
		<div className={cn(styles.base_block, styles.bottom_block)}>
			<Typography variant={'text'}>{t(title)}</Typography>
			<div className={styles.flex_row}>
				{content && <Typography variant={'subtitle_xxl'}>{content}</Typography>}
				&nbsp;
				{subTitle && (
					<Typography variant={subTitleColor ? `subtitle_xl_${subTitleColor}` : 'subtitle_xl'}>{subTitle}</Typography>
				)}
			</div>
		</div>
	)
}

const Risk = ({ riskType }) => {
	const lang = getCurrentLanguage()
	const risk = lang === 'rus' ? riskType : mapRiskToKZ[riskType]

	return <div className={styles[mapRiskTypeToClass[riskType]]}>{risk}</div>
}

export const CompanyStatistic = ({ passport }) => {
	const { t } = useTranslation()
	const smz = passport.salaryLast12Month - passport.salaryByRegionAndOked
	return (
		<div className={styles.main_container}>
			<div className={styles.row_container}>
				<TopBlock title={t('ckp_risk_level')} component={<Risk riskType={passport.riskType} />}></TopBlock>
				<TopBlock title={t('ckp_average_rating')} content={passport.averageRating}></TopBlock>
				<TopBlock title={t('ckp_employees')} content={formatNumber(passport.employeeCount)}></TopBlock>
				<TopBlock
					title={t('ckp_citizens_foreigners')}
					content={`${formatNumber(passport.citizenCount)} / ${formatNumber(passport.foreignEmployeeCount)}`}
				></TopBlock>
			</div>
			<div className={styles.grid_container}>
				<BottomBlock
					title={t('ckp_salary_for_12_24_months')}
					content={formatNumber(passport.salaryLast12Month)}
					subTitle={formatNumber(passport.salaryLast24Month)}
				></BottomBlock>
				<BottomBlock
					title={t('ckp_salary_by_region_and_OKED')}
					content={formatNumber(passport.salaryByRegionAndOked)}
					subTitle={formatNumber(smz)}
					subTitleColor={smz >= 0 ? 'green' : 'red'}
				></BottomBlock>
				<BottomBlock
					title={t('ckp_median_for_12_24_months')}
					content={formatNumber(passport.medianSalaryLast12Month)}
					subTitle={formatNumber(passport.medianSalaryLast24Month)}
				></BottomBlock>
				<BottomBlock
					title={t('ckp_salary_at_enterprise')}
					content={formatNumber(passport.inEnterpriseSMZ)}
				></BottomBlock>
				<BottomBlock
					title={t('ckp_salary_for_citizens_foreigners')}
					content={formatNumber(passport.smzInRKCitizens)}
					subTitle={formatNumber(passport.smzInForeignCitizens)}
				></BottomBlock>
			</div>
		</div>
	)
}
