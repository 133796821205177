import React, { useMemo } from 'react'
import { StaffChevronDown, StaffChevronUp, View as ViewIcon } from '@app/images'
import styles from './TableTreeView.module.scss'
import { headCells } from './tableTreeView.headings'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { staffingActions, staffingSelectors } from '@/entity/staffing'
import { getCurrentLanguage } from '@/i18next'
import { formatNumber } from '@/utils'

const TableTreeView = ({ node, staffType, toggleViewExtended, activeStructId, setActiveStructId }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const isOpen = useSelector((state) => state.staffing.openedChildsIds.includes(node.externalId))

	/**
	 * Вычисление сумм по рабочим местам
	 *
	 * @param {Object} node - Структурное подразделение
	 * @returns {Object} - Сумма Штатных ед., Оклад, Надбавка/Доплата, Оклад факт
	 */
	const total = useMemo(() => {
		const initialSums = {
			staffUnitsCount: 0,
			grossSalary: 0,
			salarySupplement: 0,
			factSalary: 0,
		}

		return node.workPlaces.reduce((acc, workplace) => {
			acc.staffUnitsCount += workplace.staffUnitsCount ?? 0
			acc.grossSalary += workplace.grossSalary ?? 0
			acc.salarySupplement += workplace.salarySupplement ?? 0
			acc.factSalary += workplace.factSalary ?? 0
			return acc
		}, initialSums)
	}, [node])

	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	const handleToggleClick = (e) => {
		e.stopPropagation()
		const id = node.externalId
		if (node.childs?.length > 0) {
			if (isOpen) {
				dispatch(staffingActions.removeOpenedChildId(id))
			} else {
				dispatch(staffingActions.addOpenedChildId(id))
			}
		}
	}

	const onOpenDetailedView = (e, externalId) => {
		e.stopPropagation()
		setActiveStructId(externalId)
		toggleViewExtended(true)
	}
	return (
		<>
			<tr onClick={handleToggleClick}>
				<td
					style={{
						paddingLeft: `${node.level * 10}px`,
					}}
					className={styles['td-cursor-pointer']}
				>
					{node.childs?.length > 0 ? (
						isOpen ? (
							<img className={styles.treeView} src={StaffChevronUp} alt="minus" />
						) : (
							<img className={styles.treeView} src={StaffChevronDown} alt="plus" />
						)
					) : null}
				</td>
				<td
					style={{
						paddingLeft: `${node.level * 10 + 10}px`, // всегда добавляем дополнительный отступ
					}}
					className={`${styles['td-cursor-pointer']} ${styles['td-padding-left']}`}
				>
					{lang === 'rus' ? node.dept.nameRu : node.dept.nameKz}
				</td>
				<td>{showDeptType(node.deptTypeCode)}</td>
				<td>{node.dept.code}</td>
				<td>{total.staffUnitsCount}</td>
				<td>{formatNumber(total.grossSalary || '')}</td>
				<td>{formatNumber(total.salarySupplement || '')}</td>
				<td>{formatNumber(total.factSalary || '')}</td>
				<td onClick={(e) => onOpenDetailedView(e, node.externalId)}>
					<div className={styles.view__td}>
						<img src={ViewIcon} alt="" className={styles.icon} />
						{t('actions.view')}
					</div>
				</td>
			</tr>
			{isOpen &&
				node.childs?.length > 0 &&
				node.childs.map((child) => (
					<TableTreeView
						key={child.externalId}
						node={{ ...child, level: node.level + 1 }}
						staffType={staffType}
						toggleViewExtended={toggleViewExtended}
						activeStructId={activeStructId}
						setActiveStructId={setActiveStructId}
					/>
				))}
		</>
	)
}

export const TreeView = ({ staffing, staffType, toggleViewExtended, activeStructId, setActiveStructId }) => {
	const { t } = useTranslation()
	const isLoading = useSelector(staffingSelectors.isLoading)
	const error = useSelector(staffingSelectors.error)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>
				{staffing?.currVersion && !isLoading ? (
					staffing.childs.map((child) => (
						<TableTreeView
							key={child.externalId}
							node={{ ...child, level: 0 }}
							staffType={staffType}
							toggleViewExtended={toggleViewExtended}
							setActiveStructId={setActiveStructId}
							activeStructId={activeStructId}
						/>
					))
				) : (
					<tr>
						<td colSpan={8} className={styles.messageCol}>
							{isLoading && (
								<LoadingScreen>
									<Title>
										{t('staff.stop')} <br />
										{t('labor_records.loading')}
									</Title>
								</LoadingScreen>
							)}
							{!isLoading && !staffing && <div className={styles.tableLoading}>{t('staff.info_no_staff')}</div>}
							{!isLoading && error && <div className={styles.error}>{t('has_error')}</div>}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
