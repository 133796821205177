import { formFieldNames } from '@/entity'
import { Field } from '@/ui'
import React, { useEffect } from 'react'
import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'

export const EndDateField = connect(
	({
		formik: { setFieldValue, values },
		isFormDirty,
		hint,
		required = false,
		fieldName = formFieldNames.endContractDate,
		minDate,
		immigrant = false,
		disabled = false,
		...props
	}) => {
		const { dcontractDateCode } = values
		const { t } = useTranslation()
		const isRequired =
			required ||
			values[formFieldNames.validityTerm] === '2' ||
			values[formFieldNames.validityTerm] === '3' ||
			values[formFieldNames.validityTerm] === '6'

		useEffect(
			() =>
				dcontractDateCode &&
				(dcontractDateCode === '1' || dcontractDateCode === '8') &&
				setFieldValue(formFieldNames.endContractDate, null),
			[dcontractDateCode]
		)
		return (
			<FormikField name={fieldName}>
				{({ field, meta: { touched, error } }) => (
					<Field
						hint={hint}
						fieldType="datePicker"
						label={t('contracts_tab.end_contract_date')}
						placeholder={t('default_date_value')}
						isRequired={immigrant ? true : isRequired}
						error={(touched || isFormDirty) && error}
						minDate={minDate}
						disabled={
							!!(values[formFieldNames.validityTerm] === '1' || values[formFieldNames.validityTerm] === '8') || disabled
						} // Не активно, если выбран период на неопределенный срок или По день окончания отпуска по уходу за ребенком
						{...field}
						{...props}
					/>
				)}
			</FormikField>
		)
	}
)
