import { Button } from '@/ui/atoms/button/Button.jsx'
import { Prohibit } from '@app/icons/prohibit/Prohibit.jsx'
import { useTranslation } from 'react-i18next'
import styles from '../view/ContractCard.module.scss'

const TerminateButton = ({ onTermination }) => {
	const { t } = useTranslation()
	return (
		<Button
			onClick={onTermination}
			buttonClassName={styles.button}
			textColor="grey"
			borderColor="grey"
			transparent
			border
		>
			<Prohibit className={styles.iconGrey} />
			{t('terminate_button')}
		</Button>
	)
}
export default TerminateButton
