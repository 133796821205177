import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ProfileTemplate, Tabs } from '@/widgets'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './FormsPage.module.scss'
import { RadioPanel } from '@/components/RadioPanel'
import { useDispatch, useSelector } from 'react-redux'
import { formsActions, formsSelectors, formsThunks } from '@/entity/forms'
import ChecksTab from '@pages/Forms/Tabs/Checks/ChecksTab'
import HistoryChecksTab from '@pages/Forms/Tabs/HistoryCheck/HistoryChecksTab'
import { getFromLocalStorage } from '@/shared'
import SelectButton from '@/components/entrance/SelectButton'
import PreHeader from '@/components/home/components/PreHeader'

const serviceWorks = [
	'find_topic_you_need_using_search_category',
	'choose_topic_start_answering_questions',
	'complete_self_test',
	'system_will_analyze_responses_form_conclusion',
	'violations_are_detected_employer_will_offered_recommendations',
	'after_violations_eliminated_you_can_re_check_yourself',
]

export const FormsPage = () => {
	const tabControlRef = useRef(null)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isAuth, setAuth] = useState(false)
	const activeCompany = useMemo(() => getFromLocalStorage('activeCompany'), [])
	const activeRole = useMemo(() => getFromLocalStorage('activeRole'), [])
	const [activeSection, setActiveSection] = useState(null)

	useEffect(() => {
		const isLogged = getFromLocalStorage('isLogged')
		if (activeCompany && activeRole === 'ROLE_EMPLOYER') setAuth(isLogged)
	}, [activeCompany, activeRole, dispatch])

	useEffect(() => {
		dispatch(formsActions.themesLoading([]))
		dispatch(formsActions.questionsLoading([]))
		dispatch(formsActions.selfTestResultResponse([]))
		dispatch(formsActions.answerSelfTestLoading([]))
	}, [dispatch])

	const selectThemes = useSelector(formsSelectors.themes)
	const [countEmployee, setCountEmployee] = useState(0)
	const [sizeCompany, setSizeCompany] = useState()
	const [questionnaire, setQuestionnaire] = useState()
	const getCountEmployee = (getEmployee) => {
		setCountEmployee(getEmployee)
	}
	const getSizeCompany = (getCompany) => {
		setSizeCompany(getCompany)
	}
	const getQuestionnaireList = (getQuestionnaire) => {
		setQuestionnaire(getQuestionnaire)
	}

	const getActiveSection = (getSection) => {
		setActiveSection(getSection)
	}

	const form = useState(true)
	const tabs = ['checksTab', 'historyChecksTab']
	const data = {
		checksTab: (
			<div className={styles.wrapper}>
				<ChecksTab
					themes={selectThemes}
					cntEmployee={countEmployee}
					categoryCompany={sizeCompany}
					questionnaires={questionnaire}
					activeCompany={activeCompany}
					activeRole={activeRole}
					isAuth={isAuth}
					activeSection={activeSection}
				/>
			</div>
		),
		historyChecksTab: <HistoryChecksTab tabControlRef={tabControlRef} activeSection={getActiveSection} />,
	}
	const onSubmitAnswerForms = (e) => {
		e.preventDefault()
		dispatch(formsThunks.getSectionForms())
	}
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<ProfileTemplate title={'online_consultant'}>
				{(countEmployee > 0 && selectThemes.length > 0) || (isAuth && selectThemes.length > 0) ? (
					<Tabs tabs={tabs} data={data} form={form} ref={tabControlRef} />
				) : (
					<>
						<Title>{t('specification')}</Title>
						<div className={styles.tabulation}>
							<p>
								&#8226; {t('every_employer_is_given_the_opportunity_to')} <b>{t('self_check_yourself')}</b>{' '}
								{t('compliance_requirements_labor_legislation')}
							</p>
							<p>
								&#8226; {t('self_check_performs')} <b>{t('auxiliary')}</b> {t('tool_and_provides')}{' '}
								<b>{t('assistance_for_improvement')}</b> {t('working_conditions_due_introduction_digital_tools')}
							</p>
							{!isAuth && (
								<>
									<p>
										&#8226; {t('the_service_is')} <b>{t('anonymous')}</b>{' '}
										{t('name_organization_personal_data_not_requested_no_pre_registration_required')}
									</p>
									<p>{t('start_self_test_you_need_fill_out_preliminary_questionnaire')}</p>
								</>
							)}
						</div>
						{!isAuth && (
							<>
								<Title>{t('questionnaire')}</Title>
								<RadioPanel
									getEmployee={getCountEmployee}
									getCompany={getSizeCompany}
									getQuestionnaire={getQuestionnaireList}
								/>
							</>
						)}
						<div className={styles.tabulation}>
							<div className={styles.ident}>
								<Title>{t('how_service_works')}</Title>
							</div>
							<ul>
								{serviceWorks.map((value) => {
									return <p key={value}>&#8226; {t(value)}</p>
								})}
							</ul>
							<div className={styles.ident}>
								<Title>{t('how_will_help')}</Title>
							</div>
							<p>
								&#8226; {t('independently')} {t('sort_out')} {t('irequirements_labor_legislation')}
							</p>
							<p>
								&#8226; {t('understand')} {t('and')} {t('estimate')} {t('preliminary_violations')}
							</p>
							<p>
								&#8226; {t('get')} {t('recommendations')} {t('and')} {t('methods')} {t('eliminate_violations')}
							</p>
							<p>
								&#8226; {t('improve')} {t('working_conditions')}
							</p>
							<p>
								&#8226; {t('independentlyUtil')} {t('correct')} {t('violations')}
							</p>
							<p>
								&#8226; {t('avoid')} {t('regulations_fines_real_check')}
							</p>
							{isAuth && isAuth ? (
								<p>
									&#8226; {t('save_money')} {t('time_means_real_verification')}
								</p>
							) : (
								<p className={styles.tabBottom}>
									&#8226; {t('save_money')} {t('time_means_real_verification')}
								</p>
							)}
						</div>
						{isAuth && (
							<div className={styles.flexBottom}>
								<SelectButton iconsize={'big'} onClick={onSubmitAnswerForms}>
									{t('pass_self_test')}
								</SelectButton>
							</div>
						)}
					</>
				)}
			</ProfileTemplate>
		</>
	)
}
