import { accidentActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'

export const getAccidents = (page, size) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleError(null))
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.getAll({ page, size })
			dispatch(accidentActions.setAccidents(result))
			dispatch(accidentActions.toggleSuccess(true))
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			dispatch(accidentActions.toggleError(error))
			dispatch(accidentActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}

export const saveReport = (data) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.saveReport(data)
			dispatch(accidentActions.setReportXml(result))
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}

export const sendSignedReport = (data) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleLoadingSend(true))
			const result = await api.accidents.sendReport(data)
			if (result === '') {
				dispatch(accidentActions.toggleSuccessSend(true))
			}
		} catch (error) {
			message.error(error?.response?.data?.message)
			dispatch(accidentActions.resetReportXml())
			dispatch(accidentActions.toggleLoadingSend(false))
		} finally {
			dispatch(accidentActions.toggleLoadingSend(false))
		}
	}
}

export const getAccident = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.getAccident(externalId)
			dispatch(accidentActions.setAccident(result))
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			dispatch(accidentActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}
