import React, { useCallback, useEffect, useState } from 'react'
import { handbookSelectors } from '@/entity/handbook'
import { useSelector, useDispatch } from 'react-redux'
import { useFormikContext, Field as FormikField } from 'formik'
import { SelectSearch, Button, Input } from '@/ui'
import styles from './DigitizationForm.module.scss'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { userThunks } from '@/entity/user'
import { Field, DatePicker } from '@/ui'
import { opvSelectors, opvThunks } from '@/entity/opv'
import { contractsThunks, contractsSelectors } from '@/entity/contracts'
import { Add } from '@app/icons'
import { AdditonalReasonSelect } from '@/components/AdditionalReasonSelect'
import { BIN_REGEX } from '@/constants/regex'
export const DigitizationForm = ({
	eventDates,
	position,
	laborTypes,
	onOpenOpv,
	additionalReasons,
	setAdditionalReasons,
	isNew,
}) => {
	const lang = getCurrentLanguage()
	const { values, setFieldValue } = useFormikContext()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const activity = useSelector(handbookSelectors.singleOptions)
	const opv = useSelector(opvSelectors.selectedOpv)
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const [minMaxDate, setMinMaxDate] = useState({ min: new Date(1900, 12), max: new Date(2100, 12) })
	useEffect(() => {
		if (opv) {
			setFieldValue('orgName', opv[lang === 'rus' ? 'orgNameRu' : 'orgNameKz'])
			setFieldValue('bin', opv.bin)
			dispatch(opvThunks.setSelectedOpv(null))
		}
	}, [opv])

	useEffect(() => {
		dispatch(contractsThunks.getTerminationReasons())
	}, [dispatch])

	useEffect(() => {
		if (values.laborTypeCode) {
			if (values.laborTypeCode === '4') {
				setFieldValue('establishedPost', position)
			} else {
				setFieldValue('terminationReason', [])
				setAdditionalReasons([])
			}
		}
	}, [values.laborTypeCode])

	const onGetHandBookOptions = useCallback(
		(type, value) => {
			type === 'bin' && dispatch(userThunks.getEmployerActivityByBin({ code: value }))
			type === 'iin' && dispatch(userThunks.getEmployerActivityByIIN({ code: value }))
		},
		[dispatch]
	)

	useEffect(() => {
		if (activity.oked) {
			setFieldValue('orgName', activity.oked.fullNameRu)
			dispatch(userThunks.removeOked())
		}
	}, [activity.oked])
	const handleSearchBin = async () => {
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values.bin)) {
			onGetHandBookOptions(values.bin.charAt(4) >= 4 ? 'bin' : 'iin', values.bin)
		}
	}

	const handleAddReasonClick = useCallback(() => {
		setAdditionalReasons((value) => {
			const newArr = [...value, null]
			return newArr
		})
	}, [])

	const handleRemoveReasonClick = useCallback((index) => {
		setAdditionalReasons((value) => {
			const newArr = [...value]
			newArr.splice(index, 1)
			return newArr
		})
	}, [])

	const isBinPattern = (e) => {
		const regex = /[0-9]/
		if (!regex.test(e.key) || e.target.value.length >= 12) {
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (values.laborTypeCode) {
			const minDate = new Date()
			minDate.setFullYear(minDate.getFullYear() - 100)
			const maxDate = new Date()
			maxDate.setFullYear(maxDate.getFullYear() + 100)

			switch (values.laborTypeCode) {
				case '1': {
					const max = eventDates.twoMin ? convert(eventDates.twoMin) : new Date()
					setMinMaxDate({ min: minDate, max: max })
					break
				}
				case '2':
				case '3': {
					const max = eventDates.four ? convert(eventDates.four) : new Date()
					setMinMaxDate({ min: convert(eventDates.one), max: max })
					break
				}
				case '4': {
					const min = eventDates.two ? convert(eventDates.two) : convert(eventDates.one)
					setMinMaxDate({ min: min, max: new Date() })
					break
				}
			}

			if (
				values.eventDate &&
				minMaxDate.min < convert(values.eventDate) &&
				minMaxDate.max < convert(values.eventDate)
			) {
				setFieldValue('eventDate', '')
			}
		}
	}, [values.laborTypeCode])

	const convert = (date) => {
		const prev = date.split('.')
		return new Date(prev[2], prev[1] - 1, prev[0])
	}
	return (
		<Grid container spacing={4} alignItems="flex-end" classes={{ root: styles.container }}>
			<Grid item xs={12} classes={{ root: styles.item }}>
				<label>{t('digitization.form.laborTypeCode')}*</label>
				<FormikField name="laborTypeCode">
					{({ field, meta: { touched, error } }) => (
						<>
							<SelectSearch
								disabled={isNew}
								value={field.code}
								className="flex-1"
								{...field}
								options={laborTypes.filter((e) => (isNew ? e : e.code !== '1'))}
							/>
							{touched && error && <div className={styles.errorColor}>{t(error)}</div>}
						</>
					)}
				</FormikField>
			</Grid>
			{values.laborTypeCode && (
				<>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						classes={{ root: styles.customBin }}
					>
						<Grid item xs={5} classes={{ root: styles.item }}>
							<Box display="flex" alignItems="flex-end" justifyContent="space-between">
								<div className="flex-1">
									<label className={styles.labelMr}>{t('digitization.form.bin')}</label>
									<span className={styles.span} onClick={onOpenOpv}>
										{t('digitization.form.choose')}
									</span>
									<FormikField name="bin">
										{({ field }) => (
											<Input
												{...field}
												onKeyPress={(e) => isBinPattern(e)}
												type={'text'}
												autoComplete="off"
												placeholder={t('enter_12_digits')}
											></Input>
										)}
									</FormikField>
								</div>
								<div className={styles.button}>
									<Button onClick={handleSearchBin.bind(null)}>{t('find')}</Button>
								</div>
							</Box>
						</Grid>

						<Grid item xs={7} classes={{ root: styles.item }}>
							<label className={styles.button}>{t('digitization.form.orgName')}*</label>
							<FormikField name="orgName">
								{({ field, meta: { error, touched } }) => (
									<Field {...field} placeholder={t('auto_filled')} error={touched && error ? t(error) : ''} />
								)}
							</FormikField>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						classes={{ root: styles.custom }}
					>
						<Grid item xs={4} classes={{ root: styles.item }}>
							<label>{t('digitization.form.eventDate.' + values.laborTypeCode)}*</label>
							<FormikField name="eventDate">
								{({ field, meta: { touched, error } }) => (
									<>
										<DatePicker
											minDate={minMaxDate.min}
											{...field}
											showIcon
											maxDate={minMaxDate.max}
											placeholder={t('default_date_value')}
										/>
										{touched && error && <div className={styles.error}>{t(error)}</div>}
									</>
								)}
							</FormikField>
						</Grid>
						{values.laborTypeCode === '4' ? (
							<Grid item xs={8} classes={{ root: styles.item }}>
								<label>{t('labor_records.contract_termination_reason')}*</label>
								<div className={styles.selectCol}>
									<div className="flex">
										<FormikField name="terminationReason">
											{({ field, meta: { touched, error } }) => (
												<div className="flex-1">
													<SelectSearch value={field.code} {...field} options={terminationReasons} />
													{touched && error && <div className={styles.errorColor}>{t(error)}</div>}
												</div>
											)}
										</FormikField>
										<Button transparent onClick={handleAddReasonClick}>
											<Add className={styles.iconGreen} />
											{t('add')}
										</Button>
									</div>
									{additionalReasons.map((item, index) => (
										<div key={`additonal-resaons-${index}`} className="flex">
											<AdditonalReasonSelect
												index={index}
												setAdditionalReasons={setAdditionalReasons}
												value={item}
												terminationReasons={terminationReasons}
												removeReason={handleRemoveReasonClick}
											/>
										</div>
									))}
								</div>
							</Grid>
						) : (
							<Grid item xs={8} classes={{ root: styles.item }}>
								<label>{t('digitization.form.establishedPost.' + values.laborTypeCode)}*</label>
								<FormikField name={'establishedPost'}>
									{({ field, meta: { touched, error } }) => (
										<Field {...field} error={touched && error ? t(error) : ''} />
									)}
								</FormikField>
							</Grid>
						)}
					</Grid>
				</>
			)}
		</Grid>
	)
}
