import React, { useState } from 'react'
import { DialogTitle, DialogContent, Dialog, Button, Box } from '@mui/material'
import styles from './style.module.scss'
import cn from 'classnames'
import { Delete } from '@/app/icons'
import { DeleteTemplate } from './DeleteTemplate'
import { useTranslation } from 'react-i18next'
import { request } from '@/shared/api/request'
import { LoadingBlocker } from '@/components/Loader'
import CloseButton from '@/components/CloseButton/CloseButton'
import { message } from 'antd'

export const TemplateModal = ({
	isOpen,
	onClose,
	onNewTemplate,
	formData,
	selectTemplate,
	templates = [],
	getTemplates,
	setTemplates,
}) => {
	const [templateToDelete, setTemplateToDelete] = useState(null)
	const [templatesLoading, setTemplatesLoading] = useState(false)

	const onConfirm = async () => {
		try {
			setTemplatesLoading(true)
			await request({
				url: 'contract/econtract/template',
				method: 'DELETE',
				params: { templateId: templateToDelete.id },
			})
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			getTemplates(setTemplates, formData, setTemplatesLoading)
			setTemplatesLoading(false)
			setTemplateToDelete(null)
		}
	}

	const { t } = useTranslation()

	return (
		<Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={isOpen}>
			{templatesLoading && <LoadingBlocker />}
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box></Box>
					<Box className={styles.title}>{t('choose_template')}</Box>
					<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Button onClick={onNewTemplate} classes={{ root: styles.button }} variant="text">
					{t('electronic_contract.new_template')}
				</Button>
				{templates &&
					templates.map((item, index) => (
						<Box
							key={index}
							className={styles.text}
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							onClick={selectTemplate.bind(null, item)}
						>
							<Box className={styles.text__wrapper} display="flex" alignItems="center" justifyContent="space-between">
								<Box className={styles.text__left}>{item.templateName}</Box>
								<Box className={styles.creation__date}>
									{t('electronic_contract.created')} {item.createDate}
								</Box>
							</Box>
							<Button
								startIcon={<Delete />}
								classes={{ root: cn(styles.button, styles.delete__button) }}
								variant="text"
								onClick={(e) => {
									e.stopPropagation()
									setTemplateToDelete(item)
								}}
							>
								{t('remove')}
							</Button>
						</Box>
					))}
			</DialogContent>

			<DeleteTemplate
				onConfirm={onConfirm}
				isOpen={Boolean(templateToDelete)}
				onClose={setTemplateToDelete.bind(null, null)}
			/>
		</Dialog>
	)
}
