import { useTranslation } from 'react-i18next'
import styles from './SubHeader.module.scss'
import { colors } from '@features/laborRecords/utils'
import { getCurrentLanguage } from '@/i18next'

export const SubHeader = ({ id, requestDate, statusRequest }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	return (
		<div className={styles.wrapper}>
			{statusRequest ? (
				<div className={styles.wrapper_size}>
					<p>
						{t('request_id')}: {id}
					</p>
					<p>{t('labor_records.request_data', { requestDate: requestDate })}</p>
					{t('labor_records.request_status')}
					<span style={{ color: colors[statusRequest.code] }}>
						{lang === 'rus' ? statusRequest.nameRu : statusRequest.nameKz}
					</span>
				</div>
			) : (
				<>
					<p>
						{t('contract_id')}: {id}
					</p>
					{t('labor_records.register_data')}
					{requestDate}
				</>
			)}
		</div>
	)
}
