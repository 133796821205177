import React from 'react'
import { DialogTitle, DialogContent, Dialog, Box } from '@mui/material'
import { Button as DialogButton } from '@/ui'
import styles from './ContractCard.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'

const ModalConfirm = ({ onClose, isOpen, message = '' }) => {
	const { t } = useTranslation()

	return (
		<Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={isOpen}>
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: cn(styles.title, styles.reset__title) }}>{message}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					<DialogButton type="button" onClick={onClose}>
						t{'col_contract.return'}
					</DialogButton>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ModalConfirm
