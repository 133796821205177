import { View as ViewIcon, Determinate as Revoke } from '@app/images'

export default function onRequestActionMenuClick(row, e, setActionMenuItems, t) {
	const statusId = row.requestStatus?.code
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (statusId === '1') {
		actions.splice(1, 0, { icon: Revoke, title: t('labor_records.revoke'), action: 'revoke' })
	}
	setActionMenuItems(actions)
}
