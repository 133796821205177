import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField } from 'formik'
import styles from '../CollectiveForm.module.scss'
import { formFieldNames } from '../../../lib/constants/formFieldNames'
import { Field } from '@/ui'
import React from 'react'

export const WorkingHolidaysFields = connect(
	({ workTimeCollectiveContract = [], relaxTimeCollectiveContract = [] }) => {
		const { t } = useTranslation()
		return (
			<>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.workTime}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								fieldType="multipleSelect"
								label={t('col_contract.working_time')}
								placeholder={t('col_contract.placeholder_one_or_more_values')}
								error={touched && error ? t(error) : ''}
								options={workTimeCollectiveContract}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
				</div>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.relaxTime}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								fieldType="multipleSelect"
								label={t('col_contract.relax_time')}
								placeholder={t('col_contract.placeholder_one_or_more_values')}
								error={touched && error ? t(error) : ''}
								options={relaxTimeCollectiveContract}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
				</div>
			</>
		)
	}
)
