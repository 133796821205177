import { Button } from '@/ui'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styles from './ProfileActions.module.scss'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import React from 'react'
import CloseButton from '@/components/CloseButton/CloseButton'

export const Warningcompany = ({ open, handleClose, errorCompanies }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			classes={{
				paper: styles.dialog__paper,
			}}
		>
			<DialogTitle
				classes={{
					root: styles.dialog__title,
				}}
				id="alert-dialog-title"
			>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between">
					<div className={styles.company__warning__title}>{t('warning_company_title')}</div>
					<CloseButton onClose={handleClose}></CloseButton>
				</Box>
			</DialogTitle>
			<DialogContent
				classes={{
					root: styles.dialog__content,
				}}
			>
				{errorCompanies.map((company, index) => (
					<Grid
						container
						spacing={4}
						key={company.extId}
						classes={{
							root: styles.grid__line__root,
						}}
					>
						<Grid item xs={6}>
							<div className={styles.column__title}>
								{t('warning_company_title_left')} {errorCompanies.length > 1 && index + 1}
							</div>
							<Grid container spacing={0}>
								<Grid item xs={6}>
									<div className={styles.column__key}>{t('company_id')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.extId}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('bin')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.bin}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('full_bin_name')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>
										{language == 'rus' ? company.companyName : company.companyNameKaz}
									</div>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={6}>
							<div className={styles.column__title}>{t('warning_company_title_right')}</div>
							<Grid container spacing={0}>
								<Grid item xs={6}>
									<div className={styles.column__key}>{t('lastname')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.lastname}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('firstname')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.firstname}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('middlename')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.middlename}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('electronic_mail')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.email}</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('officePhone')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>
										{company.officePhone} {company.officePhoneInternal ? `(${company.officePhoneInternal})` : ''}
									</div>
								</Grid>

								<Grid item xs={6}>
									<div className={styles.column__key}>{t('mobilePhone')}:</div>
								</Grid>
								<Grid item xs={6}>
									<div className={styles.column__value}>{company.mobilePhone}</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				))}
			</DialogContent>
			<DialogActions
				classes={{
					root: styles.dialog__actions,
				}}
			>
				<Button onClick={handleClose}>{t('close_message')}</Button>
			</DialogActions>
		</Dialog>
	)
}
