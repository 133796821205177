import { Modal, ModalBackButton } from '@/components/Modal'
import { contractsActions, contractsSelectors, contractsThunks, userSelectors } from '@/entity'
import { handbookSelectors } from '@/entity/handbook'
import { getCurrentLanguage } from '@/i18next'
import { Button, DataGridTable, Title } from '@/ui'
import { Button as ButtonMui } from '@mui/material'
import { Edit, Pencil, Trash, Delete } from '@app/icons'
import { parseAdditionalContractData } from '@features/contracts/additionalContracts/utils'
import { SignStatus } from '@features/contracts/badges'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AdditionalContractStyles.module.scss'
import { TableSign } from './TableSign/TableSign'
import { request } from '@/shared/api/request'
import cn from 'classnames'
import { LoadingBlocker } from '@/components/Loader'
import { message } from 'antd'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
import { EmploymentContractFile } from '@/entity/contracts/ui/form/employmentСontractFile/employmentСontractFile'
import { contractActionsSelectors } from '@features/contracts/model'
import { ViewConfirmDocument } from '@/components/ViewDocuments/ViewConfirmDocument'

export const ViewAdditionalAgreementModal = ({
	onRevert,
	onRevision,
	onClose,
	onToggleAction,
	onSign,
	filters = {},
	page,
	pageSize,
	setFilters,
	fetchData,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()
	const contract = useSelector(contractsSelectors.contract)
	const additionalContract = useSelector(contractsSelectors.additionalContract)
	const positionsByKnz = useSelector(handbookSelectors.positionsByKnz)
	const positions = useSelector(handbookSelectors.positions)
	const lang = getCurrentLanguage()
	const activeRole = useSelector(userSelectors.activeRole)
	const remoteWorkTypes = useSelector(handbookSelectors.remoteWorkTypes)
	const jobTypes = useSelector(handbookSelectors.jobTypes)
	const workingTimeModes = useSelector(handbookSelectors.workingTimeModes)
	const [adContractInfo, setAdContractInfo] = useState(null)
	const [eAdContractToSend, setEAdContractToSend] = useState(null)
	const [mode, setMode] = useState(null)
	const [disableDownload, setDisableDownload] = useState(false)
	const [showFile, setShowFile] = useState(false)
	const { workCondition = [], workActivityTypes = [] } = useSelector(contractActionsSelectors.options)
	const data = useMemo(
		() =>
			parseAdditionalContractData(
				t,
				additionalContract,
				positionsByKnz,
				lang,
				positions,
				remoteWorkTypes,
				jobTypes,
				workingTimeModes,
				workCondition,
				workActivityTypes
			),
		[
			t,
			additionalContract,
			positionsByKnz,
			lang,
			positions,
			remoteWorkTypes,
			jobTypes,
			workingTimeModes,
			workCondition,
			workActivityTypes,
		]
	)
	const [disableButtonNkz, setDisableButtonNkz] = useState(false)

	useEffect(() => {
		const result = !Boolean(positionsByKnz.find(({ code }) => code === contract?.dpositionCode))

		setDisableButtonNkz(result)
	}, [positionsByKnz, additionalContract])

	useEffect(async () => {
		if (contract.econtractFound) {
			setMode('loading')
			const res = await request({
				url: '/contract/eAdContract/get',
				method: 'GET',
				params: { externalId: additionalContract.externalId },
			})
			if (res.file)
				setEAdContractToSend({
					additionalContractDTO: additionalContract,
					externalId: additionalContract.externalId,
					fileId: res.file.id,
					optionsList: [],
					valueList: [],
					downloadFile: res.downloadFile,
					file: res.file,
				})
			setAdContractInfo(res)
		}
	}, [])

	useEffect(() => {
		if (adContractInfo) {
			setMode(null)
		}
	}, [adContractInfo])

	const esutdRegErrors = useSelector((state) => state.handbook.esutdRegErrors)

	const errorData = esutdRegErrors.find((item) => item.code === additionalContract?.desutdRegErrorCode)
	const onDelete = () => {
		onToggleAction('removeAdditionalAgreement', true)
		onToggleAction('viewAdditionalAgreement', false)
	}
	const onEdit = () => {
		onToggleAction('editAdditionalAgreement', true)
		onToggleAction('viewAdditionalAgreement', false)
	}
	const onSignAdditionalAgreement = () => {
		onToggleAction('signAdditionalAgreement', true)
		onToggleAction('viewAdditionalAgreement', false)
	}
	const onDecline = () => {
		onToggleAction('declineAdditionalAgreement', true)
		onToggleAction('viewAdditionalAgreement', false)
	}
	const downloadContractPDF = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDisableDownload(true)
		setTimeout(() => {
			setDisableDownload(false)
		}, 5000)
		const response = await downloadFileByUid(adContractInfo.downloadFile.id)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = adContractInfo.downloadFile.fileName
		document.body.appendChild(a)
		a.click()
	}

	const code = additionalContract?.dcontractState?.code
	const status = contract?.dcontractStatus?.code
	const state = contract?.dcontractState?.code
	const isEmployer = activeRole === 'ROLE_EMPLOYER'
	const isEmployee = activeRole === 'ROLE_EMPLOYEE'

	const allowRejectEmployer = isEmployer && code === '7'
	const allowRejectEmployee = isEmployee && code === '4'
	const handleSendToEmployee = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (additionalContract?.externalId)
			try {
				setMode('loading')
				await request({
					url: '/contract/eAdContract/send',
					method: 'POST',
					params: {
						externalId: additionalContract?.externalId,
					},
				})
				dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
				dispatch(contractsActions.setAdditionalContractSubmitted())
				dispatch(contractsThunks.getContracts(filters, page, pageSize))
				message.success(t('success_alert'), 5)
			} catch (error) {
				const err = error?.response?.data?.message || 'Неизвестная ошибка'
				message.error(err ? err : 'Неизвестная ошибка', 5)
			} finally {
				setMode(null)
			}
	}
	const CloseAndUpdateFilter = () => {
		dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
		setFilters({ ...filters, dcontractStatusCode: '6' })
		fetchData({ ...filters, dcontractStatusCode: '6' })
		onClose()
	}
	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title>
							{t('view_add_contr_title', {
								additionalContractNumber: additionalContract?.contractNum || ' ',
								contractNumber: contract?.contractNumber || ' ',
								registerDate: contract?.registerDate || ' ',
							})}
						</Title>
						<div className={styles.headerRight}>
							<SignStatus item={additionalContract?.dcontractState} />
						</div>
					</div>
					<p className={styles.identifier}>ID: {additionalContract?.externalId}</p>
					<div className={styles.subTitle}>
						{!additionalContract?.desutdRegErrorCode && (
							<>
								{t('esutd_reg_date')}: {additionalContract?.entryDate}
							</>
						)}
						{additionalContract?.desutdRegErrorCode && (
							<p className={styles.registrationDate}>
								{t('esutd_reg_date')}:{' '}
								<span className={styles.error}>
									{t('package_loading.contract_error_text', {
										errorText: errorData?.[language],
										errorNumber: errorData?.code,
									})}
								</span>
							</p>
						)}
					</div>
					{!contract.econtractFound && (
						<div className={styles.actions}>
							{activeRole === 'ROLE_EMPLOYER' && state === '1' && code !== '1' && status !== '2' && (
								<Button textColor="green" buttonClassName={styles.button} onClick={onSign} disabled={disableButtonNkz}>
									<Pencil className={styles.iconWhite} />
									{t('sign_button')}
								</Button>
							)}
							{activeRole === 'ROLE_EMPLOYER' && status !== '2' ? (
								<>
									<Button borderColor="green" transparent border buttonClassName={styles.button} onClick={onEdit}>
										<Edit className={styles.iconGreen} />
										{t('edit_button')}
									</Button>
									<Button type="bare" buttonClassName={styles.button} onClick={onDelete}>
										<Trash className={styles.iconGrey} />
										{t('remove')}
									</Button>
								</>
							) : null}
						</div>
					)}
					{!contract.fromFile && contract.econtractFound && ['1', '2'].includes(status) && code === '1' ? (
						<div className={styles.actions}>
							<Button onClick={(e) => downloadContractPDF(e)} disabled={disableDownload}>
								{t('download_pdf_eds')}
							</Button>
						</div>
					) : null}
					{contract.econtractFound && contract.fromFile && (
						<div className={styles.actions}>
							<Button onClick={() => setShowFile(true)} disabled={disableDownload}>
								{t('open_eds')}
							</Button>
						</div>
					)}
					<DataGridTable
						title={t('additional_contract_info')}
						information={data}
						hideSubTitle
						titleClass={styles.tableTitleCell}
					/>
					{additionalContract?.files?.length > 0 ? (
						<div className={styles.mt44}>
							<Title>{t('labor_records.confirming_document')}</Title>
							<ViewConfirmDocument data={additionalContract?.files} additionalContract={true}></ViewConfirmDocument>
						</div>
					) : null}
					{/*Статус ЭДС*/}
					{contract.econtractFound ? (
						<div className={styles.etd__wrapper}>
							<div className={styles.etd__titile}>{t('edit_confirm_contract')}</div>
							<div dangerouslySetInnerHTML={{ __html: adContractInfo?.textHtmlAd }} style={{ marginBottom: 32 }} />
							{adContractInfo?.note && (
								<div className={styles.employee__comment__wrapper}>
									<div className={styles.etd__title}>{t('employee_comment')}</div>
									<div className={styles.employee__comment}>{adContractInfo.note}</div>
								</div>
							)}
							{adContractInfo && code !== '3' ? <TableSign adContract={adContractInfo} /> : null}
						</div>
					) : null}
					{contract.econtractFound &&
					activeRole === 'ROLE_EMPLOYER' &&
					['3', '5', '6', '8', '9'].includes(code) &&
					status === '1' ? (
						<div className={styles.buttons__wrapper}>
							<ButtonMui
								type="button"
								disabled={code === '4'}
								variant="text"
								classes={{ root: cn(styles.button_etd, styles.reset__button), disabled: styles.button__disabled }}
								startIcon={<Delete fill={'#5B6880'} className={styles.edit__icon} />}
								onClick={onDelete}
							>
								{t('remove')}
							</ButtonMui>
							{/*							<ButtonMui
								type="button"
								startIcon={<Edit fill={'#17B67C'} className={styles.edit__icon} />}
								classes={{
									root: cn(styles.button_etd, styles.button__save),
									disabled: styles.button__disabled,
								}}
								sx={{ paddingLeft: '20px !important' }}
								onClick={handleEditEtd}
							>
								{t('edit_button')}
							</ButtonMui>*/}
							<Button type="submit" onClick={handleSendToEmployee} buttonClassName={styles.send__worker}>
								{t('send_to_sign_worker')}
							</Button>
						</div>
					) : null}
					{/*Кнопка отозвать*/}
					{contract.econtractFound && activeRole === 'ROLE_EMPLOYER' && code === '4' ? (
						<div className={styles.decline__button}>
							<Button type="submit" onClick={onRevert}>
								{t('actions.revoke')}
							</Button>
						</div>
					) : null}
					<div className={styles.footer}>
						{contract.econtractFound &&
						adContractInfo &&
						code === '4' &&
						activeRole === 'ROLE_EMPLOYEE' &&
						adContractInfo.signEmployeeDate == null ? (
							<Button buttonClassName={`ms-3 ${styles.button}`} onClick={onRevision} disabled={disableButtonNkz}>
								{t('send_to_rework')}
							</Button>
						) : null}
						{allowRejectEmployer && (
							<Button buttonClassName={`ms-3 ${styles.button}`} onClick={onDecline}>
								{t('decline')}
							</Button>
						)}
						{allowRejectEmployee && (
							<Button buttonClassName={`ms-3 ${styles.button}`} onClick={onDecline}>
								{t('decline')}
							</Button>
						)}
						{contract.econtractFound && adContractInfo && status === '1' ? (
							<>
								{!['3', '4', '5', '6', '8'].includes(code) &&
									activeRole === 'ROLE_EMPLOYER' &&
									adContractInfo.signEmployerDate == null && (
										<Button
											buttonClassName={`ms-3 ${styles.button}`}
											onClick={onSignAdditionalAgreement}
											disabled={disableButtonNkz}
										>
											{t('sign_button')}
										</Button>
									)}
								{!['3', '6', '8'].includes(code) &&
									activeRole === 'ROLE_EMPLOYEE' &&
									adContractInfo.signEmployeeDate == null && (
										<Button
											buttonClassName={`ms-3 ${styles.button}`}
											onClick={onSignAdditionalAgreement}
											disabled={disableButtonNkz}
										>
											{t('sign_button')}
										</Button>
									)}
							</>
						) : null}
					</div>
				</div>
			</Modal>
			{showFile && eAdContractToSend && (
				<EmploymentContractFile
					onClose={() => setShowFile(false)}
					documentData={adContractInfo.file}
					attachProjectFileData={eAdContractToSend}
					CloseAndUpdateFilter={CloseAndUpdateFilter}
					filters={filters}
					page={page}
					pageSize={pageSize}
					saveUrl={'/contract/eAdContract/save'}
					title={t('e_ad_contract')}
					changeFile={true}
					downloadFile={true}
				/>
			)}
			{mode === 'loading' ? <LoadingBlocker /> : null}
		</>
	)
}
