import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './TablePosition.module.scss'
import { headCells } from './tablePosition.headings'
import { useTranslation } from 'react-i18next'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import onPositionActionMenuClick from './actionMenu/onPositionActionMenuClick'
import { getCurrentLanguage } from '@/i18next'
import { checkWorkPlacesErrors, formatNumber } from '@/utils'
import renderIcon from '@pages/PersonalAffairs/components/Icons'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

const TablePosition = ({ setActions, positions, setPositionRowSelectMenu }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [actionMenuItems, setActionMenuItems] = useState([])
	const [isLastRowMenuOpen, setIsLastRowMenuOpen] = useState(false)
	const [isSecondLastRowMenuOpen, setIsSecondLastRowMenuOpen] = useState(false)
	const [hasScroll, setHasScroll] = useState(false)
	const tableRef = useRef()

	useEffect(() => {
		const checkOverflow = () => {
			if (tableRef.current) {
				setHasScroll(tableRef.current.offsetHeight < tableRef.current.scrollHeight)
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [positions])

	useEffect(() => {
		setIsLastRowMenuOpen(false)
		setIsSecondLastRowMenuOpen(false)
	}, [positions])

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		setPositionRowSelectMenu(row)
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head) => (
					<TableCell key={head.id} classes={{ root: styles.table__head__row }}>
						{t(head.label)}
					</TableCell>
				))}
			</>
		),
		[t]
	)

	const getTableRow = useCallback((row, index) => (
		<TableRow key={index} style={{ backgroundColor: index % 2 ? '#f9fbff' : 'white' }} onClick={() => fastView(row)}>
			<TableCell>{index + 1}</TableCell>
			<TableCell
				classes={{ root: styles.table__body_cell }}
				align="left"
				title={t('staff.information_workplace_position')}
			>
				{row.code}
			</TableCell>
			<TableCell
				classes={{ root: styles.table__body_cell }}
				align="left"
				title={t('staff.information_workplace_position')}
			>
				{lang === 'rus' ? row.establishedPost.nameRu : row.establishedPost.nameKz}
			</TableCell>
			<TableCell
				classes={{ root: styles.table__body_cell }}
				align="left"
				title={t('staff.information_workplace_position')}
			>
				{row.staffUnitsCount}
			</TableCell>
			<TableCell
				classes={{ root: styles.table__body_cell }}
				align="left"
				title={t('staff.information_workplace_position')}
			>
				{formatNumber(row.grossSalary || '')}
			</TableCell>
			<TableCell
				classes={{ root: styles.table__body_cell }}
				align="left"
				title={t('staff.information_workplace_position')}
			>
				{formatNumber(row.salarySupplement || '')}
			</TableCell>
			<TableCell
				onClick={(e) => {
					onPositionActionMenuClick(row, e, setActionMenuItems, t)
					if (index === positions.length - 1) {
						setIsLastRowMenuOpen(!isLastRowMenuOpen)
					} else if (index === positions.length - 2) {
						setIsSecondLastRowMenuOpen(!isSecondLastRowMenuOpen)
					} else {
						setIsLastRowMenuOpen(false)
					}
				}}
			>
				<DropDownMenu
					title={t('select_pls')}
					items={actionMenuItems}
					onItemClick={(mode) => onActionMenuChoose(mode, row)}
				/>
			</TableCell>
			<TableCell classes={{ root: styles.table__body_cell }} align="left" title={t('staff.error_info')}>
				{checkWorkPlacesErrors(row) ? renderIcon('error') : null}
			</TableCell>
		</TableRow>
	))

	const tableBody = useMemo(() => positions?.map((row, index) => getTableRow(row, index)), [positions, getTableRow])

	return (
		<TableContainer
			ref={tableRef}
			className={
				hasScroll ? `${styles.table__container} scroll` : `${styles.table__container} ${styles.isLastRowMenuOpen}`
			}
		>
			<Table aria-labelledby="tableTitle" size="medium" stickyHeader>
				<TableHead>
					<TableRow>{tableHeader}</TableRow>
				</TableHead>
				{positions?.length > 0 ? (
					<TableBody>{tableBody}</TableBody>
				) : (
					<tr>
						<td colSpan={8} className={styles.messageCol}>
							<div className={styles.tableLoading}>{t('staffing_table.info_no_positions')}</div>
						</td>
					</tr>
				)}
			</Table>
		</TableContainer>
	)
}

export default TablePosition
