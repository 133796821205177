export const getValueByPath = (obj, path) => {
	// Разбиваем строку пути на части, учитывая индексы массивов
	const parts = path.split(/[\.\[\]]/).filter((part) => part !== '')

	// Используем reduce для последовательного обращения к объекту по частям пути
	return parts.reduce((acc, part) => {
		if (acc && acc[part] !== undefined) {
			return acc[part]
		} else if (acc && !isNaN(part)) {
			// Проверяем, является ли part числом (индекс массива)
			return acc[parseInt(part, 10)]
		} else {
			return undefined
		}
	}, obj)
}
