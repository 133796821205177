import React from 'react'
import styles from './style.module.scss'

const RadioAnswer = ({ id, checked, name, handleChange }) => {
	return (
		<label className={styles.fontLabel} key={id}>
			<input type="radio" name="radioOption" id={id} value={id} defaultChecked={checked} onChange={handleChange} />
			{' ' + name}
		</label>
	)
}

export default RadioAnswer
