import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalTemplate, Title } from '@/ui'
import styles from './PositionForm.module.scss'
import { Formik } from 'formik'
import { Form } from './Form'
import { useDispatch, useSelector } from 'react-redux'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { ValidationSchema } from './validationSchema'
import { contractActionsSelectors } from '@features/contracts/model'

function filterByExternalId(obj, externalId) {
	if (Array.isArray(obj)) {
		for (let child of obj) {
			if (child) {
				// рекурсивный вызов
				let foundItem = filterByExternalId(child, externalId)

				// Если найдено соответствующее workplace, оно сразу возвращается
				if (foundItem !== null) {
					return foundItem
				}
			}
		}
	} else if (typeof obj === 'object' && obj !== null) {
		if (Array.isArray(obj.workPlaces) && obj.workPlaces.length > 0) {
			let foundWorkPlace = obj.workPlaces.find((workPlace) => workPlace.externalId === externalId)

			// Как только найдено соответствующее workplace, оно возвращается
			if (foundWorkPlace) {
				return foundWorkPlace
			}
		}
		if (obj.childs) {
			// рекурсивный вызов
			let foundChild = filterByExternalId(obj.childs, externalId)

			// Если найдено соответствующее workplace, оно сразу возвращается
			if (foundChild !== null) {
				return foundChild
			}
		}
	}

	return null
}

function createWorkPlaceData(data, position) {
	return {
		establishedPost: {
			nameKz: data.isDicPosition === '2' ? data.nameKz : position.kz,
			nameRu: data.isDicPosition === '2' ? data.nameRu : position.rus,
			code: data.isDicPosition === '2' ? null : position.code,
		},
		factSalary: data.factSalary,
		freelance: data.freelance,
		grossSalary: data.grossSalary,
		salarySupplement: data.salarySupplement,
		staffFreeCount: data.staffFreeCount,
		staffUnitsCount: data.staffUnitsCount,
		positionCode: data.positionCode,
	}
}

export const PositionForm = ({
	initialValues,
	onClose,
	actions,
	activeRowId,
	staffProject,
	positionRowSelectMenu,
	setPositionRowSelectMenu,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const { positions } = useSelector(contractActionsSelectors.options)

	let establishedPostNames,
		otherProps,
		editPosition = null
	if (actions.edit && positionRowSelectMenu?.establishedPost) {
		establishedPostNames = { ...positionRowSelectMenu.establishedPost }
		otherProps = { ...positionRowSelectMenu }

		// добавляем поле isDicPosition в объект editPosition
		editPosition = {
			...(establishedPostNames || {}),
			...(otherProps || {}),
			isDicPosition: !establishedPostNames.code ? '2' : '3',
			position: establishedPostNames.code ? establishedPostNames.code : null,
		}
	}

	useEffect(() => {
		if (isSuccess) {
			if (actions.edit) {
				let workPlace = filterByExternalId(staffProject.childs[0], editPosition.externalId)
				setPositionRowSelectMenu(workPlace)
			}
			onClose()
		}
	}, [isSuccess])

	const onSubmit = (data) => {
		let position = positions?.find((item) => item.code === data.position)
		const newWorkPlace = createWorkPlaceData(data, position)
		if (actions.edit) {
			const workPlaceData = {
				orgExternalId: staffProject.externalId,
				row: {
					...newWorkPlace,
					code: data.code,
					externalId: editPosition.externalId,
				},
				structureExternalId: activeRowId,
			}
			dispatch(staffThunks.editWorkPlace(workPlaceData))
		} else {
			const workPlaceData = {
				list: [newWorkPlace],
				orgExternalId: staffProject.externalId,
				structureExternalId: activeRowId,
			}
			dispatch(staffThunks.addWorkPlace(workPlaceData, data.multiCreate !== '1'))
		}
	}

	return (
		<ModalTemplate onClose={onClose} priority={actions.edit || actions.create ? 2 : 1}>
			<div className={`scroll ${styles.wrapper}`}>
				<div className={styles.mt10}>
					<Title color="black">
						{actions.edit ? `${t('staff_modal.edit_position')}` : t('staff_modal.create_position')}
					</Title>
					<Formik
						validationSchema={ValidationSchema}
						initialValues={actions.edit ? editPosition : initialValues}
						onSubmit={onSubmit}
					>
						<Form onClose={onClose} isEdit={actions.edit}></Form>
					</Formik>
				</div>
			</div>
		</ModalTemplate>
	)
}
