import './Button.scss'
import { isValidElement } from 'react'

const Button = ({ children, bordered, borderless, green, gray, secondary, img, className, ...props }) => {
	return (
		<button
			className={`Button ${bordered ? 'Button--bordered' : ''} ${borderless ? 'Button--borderless' : ''} ${green ? 'Button--green' : ''} ${
				secondary ? 'Button--secondary' : ''
			} ${gray ? 'Button--gray' : ''} ${className || ''}`}
			{...props}
		>
			{img && (isValidElement(img) ? img : <img src={img} />)}
			{children}
		</button>
	)
}

Button.defaultProps = {
	type: 'button',
}

export default Button
