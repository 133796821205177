export const headCells = [
	{
		id: 'role',
		numeric: false,
		label: 'electronic_contract.role',
	},
	{
		id: 'iin',
		numeric: true,
		label: 'iin',
	},
	{
		id: 'fio',
		numeric: false,
		label: 'fio',
	},
	{
		id: 'signDate',
		numeric: false,
		label: 'electronic_contract.sign_date',
	},
	{
		id: 'statusSignEec',
		numeric: false,
		label: 'col_contract.status_sign',
	},
]
