import { request } from '@/shared/api/request'

export const sendIndustryAgreement = (payload) => {
	return request({
		url: 'colcontract/industryAgreement/saveIndustryAgreement',
		method: 'POST',
		data: payload,
		headers: { 'Content-Type': 'text/plain' },
	})
}
