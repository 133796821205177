import {
	Add as AddIcon,
	Determinate as DeterminateIcon,
	Edit as EditIcon,
	RefreshGreyIcon,
	Remove as RemoveIcon,
	Sign as SignIcon,
	View as ViewIcon,
} from '@app/images'

import { Download } from '@app/icons'
import { CrossRed } from '@app/icons/crossRed/CrossRed'
import { inProcess, StateCode } from './config.js'

export default function onContractActionMenuClick(row, e, setActionMenuItems, t, activeRole) {
	const statusId = row.dcontractStatus?.id
	const stateCode = row.dcontractState?.code
	const econtractFound = row.econtractFound
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (statusId === 1) {
		actions = [
			...actions,
			{ icon: <Download height="14" width="14" />, title: t('donwload_reference'), action: 'donwload' },
		]
	}
	if (activeRole === 'ROLE_EMPLOYEE') {
		setActionMenuItems(actions)
		return
	}
	// Действующий ТД
	if (statusId === 1 && !econtractFound) {
		// Подписанный
		actions.splice(
			1,
			0,
			{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
			{ icon: AddIcon, title: t('actions.createAdditionalAgreement'), action: 'createAdditionalAgreement' },
			{ icon: AddIcon, title: t('actions.createSocialLeave'), action: 'createSocialLeave' },
			{ icon: DeterminateIcon, title: t('actions.terminate'), action: 'terminate' }
		)
		row.dcontractState?.id !== 1 && actions.splice(2, 0, { icon: SignIcon, title: t('actions.sign'), action: 'sign' }) // Не подписанный
	}
	if (statusId === 1 && econtractFound) {
		// Подписанный ЭТД
		actions.splice(
			1,
			0,
			{ icon: AddIcon, title: t('actions.createAdditionalAgreement'), action: 'createAdditionalAgreement' },
			{ icon: AddIcon, title: t('actions.createSocialLeave'), action: 'createSocialLeave' }
		)
		row.dcontractState?.id !== 1 && actions.splice(2, 0, { icon: SignIcon, title: t('actions.sign'), action: 'sign' }) // Не подписанный
	}
	// Расторгнутый
	statusId === 2 &&
		actions.splice(
			1,
			0,
			{ icon: EditIcon, title: t('actions.edit_termination'), action: 'editTermination' },
			{ icon: RefreshGreyIcon, title: t('actions.recover'), action: 'recover' }
		)
	// Просроченный
	if (statusId === 3) {
		actions.splice(
			1,
			0,
			{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
			{ icon: AddIcon, title: t('actions.createAdditionalAgreement'), action: 'createAdditionalAgreement' },
			{ icon: EditIcon, title: t('actions.prolongation'), action: 'prolongation' }
		)
		if (!econtractFound) actions.splice({ icon: DeterminateIcon, title: t('actions.terminate'), action: 'terminate' })
	}
	// Не зарегистрирован
	statusId === 5 &&
		actions.splice(
			1,
			0,
			// { icon: SignIcon, title: t('actions.sign'), action: 'sign' },
			{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
			{ icon: AddIcon, title: t('actions.createAdditionalAgreement'), action: 'createAdditionalAgreement' },
			{ icon: AddIcon, title: t('actions.createSocialLeave'), action: 'createSocialLeave' },
			{ icon: RemoveIcon, title: t('remove_contract'), action: 'remove' }
		)

	// Проект ЭТД
	if (statusId === 6) {
		// В разработке | На доработке
		if (inProcess(stateCode)) {
			actions = [
				{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
				{ icon: SignIcon, title: t('actions.sendEmployee'), action: 'sendEmployee' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'deleteEtd' },
			]
		}
		// Отказ Работника
		if (stateCode === StateCode.REJECT_EMPLOYEE) {
			actions = [
				{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'deleteEtd' },
			]
		}
		// На подписи Работодателя
		if (stateCode === StateCode.ON_SIGN_EMPLOYER) {
			actions = [
				{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
				/*TODO реализовать
				{ icon: SignIcon, title: t('actions.sign'), action: 'sign' },*/
			]
		}
		// На подписи Работника
		if (stateCode === StateCode.ON_SIGN_EMPLOYEE) {
			actions = [
				{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
				/*TODO реализовать
				{ icon: SignIcon, title: t('actions.sign'), action: 'sign' },
				{ icon: <CrossRed />, title: t('decline'), action: 'decline' },*/
			]
		}
		// Отказ Работодателя | Истекло время ожидания
		if ([StateCode.REJECT_EMPLOYER, StateCode.EXPIRED].includes(stateCode)) {
			actions = [
				{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
				/*TODO реализовать
				{ icon: SignIcon, title: t('actions.sendEmployee'), action: 'sendEmployee' },*/
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'deleteEtd' },
			]
		}
	}
	setActionMenuItems(actions)
}
