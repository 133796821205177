export const headCells = [
	{
		id: 'yin',
		numeric: false,
		label: 'yin',
	},
	{
		id: 'contractor',
		numeric: true,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'certificate_count',
		numeric: true,
		label: 'personal_affairs.table.certificate_count',
	},
	{
		id: null,
		numeric: false,
		label: '',
	},
]
