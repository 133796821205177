import React, { useMemo } from 'react'
import styles from './Tubik.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import { DataGridTable } from '@/ui'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

function Tubik({ data }) {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const filteredData = useMemo(
		() => [
			{
				title: lang === 'rus' ? 'Статус' : 'Мәртебесі',
				data: data.data?.[lang === 'rus' ? 'messageRu' : 'messageKz'],
			},
		],
		[data.data]
	)

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_006')}</p>
					</div>
				)}
				<DataGridTable
					hideSubTitle
					information={filteredData}
					hideTitle
					titleClass={styles.tableTitleCell}
					rowClass={styles.dataRow}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
export default Tubik
