import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Content, Header } from '@/widgets/tabs/ui'

export const Tabs = forwardRef(({ tabs, data }, ref) => {
	const [activeTab, setActiveTab] = useState(tabs[0])
	const onChangeActiveTab = (tab) => {
		setActiveTab(tab)
	}

	useImperativeHandle(ref, () => ({
		onChangeActiveTab,
	}))

	return (
		<>
			<Header tabs={tabs} activeTab={activeTab} onChange={onChangeActiveTab} />
			<Content content={data} activeTab={activeTab} />
		</>
	)
})
