import { request } from '@/shared/api/request'

export const createSocialLeaveXml = (payload) =>
	request({ url: 'contract/socialLeave/registration/xml', method: 'POST', data: payload })

export const createLocalSocialLeave = (payload) =>
	request({ url: 'contract/socialLeave/local/createOrUpdate', method: 'POST', data: payload })

export const sendSignedSocialLeave = (payload) =>
	request({ url: 'contract/socialLeave/sendRequest', method: 'POST', data: payload })

export const deleteSocialLeaveXml = (payload) =>
	request({ url: 'contract/socialLeave/deletion/xml', method: 'DELETE', data: payload })

export const deleteLocalSocialLeave = (externalId) =>
	request({ url: `contract/socialLeave/local/deletion/${externalId}`, method: 'DELETE' })
