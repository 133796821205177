import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react'
import { userSelectors, userThunks } from '@/entity/user'
import { Field as FormikField, Formik, Form } from 'formik'
import { Field, Title, UploadImageWithLabel, Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from '@/shared/api/rest/main/uploadFile'
import { EmployeeAccountInfo } from '@features/userProfile/info-table'
import { EmployeeValidationScheme } from './employee.scheme'
import styles from './EditUserProfile.module.scss'
import { api, useDigitalSign } from '@/shared'
import { Buffer } from 'buffer'

export const EditUserProfile = ({ tabControlRef }) => {
	const dispatch = useDispatch()
	const formRef = useRef(null)
	const user = useSelector(userSelectors.user)
	const { successPayload, onSign, onClearPayload } = useDigitalSign()
	const [formData, setFormData] = useState(null)
	const initialValues = useMemo(
		() => ({
			email: user?.email,
			officePhone: user?.officePhone,
			officePhoneInternal: user?.officePhoneInternal,
			mobilePhone: user?.mobilePhone,
			photoId: user?.photoId,
		}),
		[user]
	)

	const onSubmit = async (data) => {
		setFormData({
			id: user?.personDetailsId,
			iin: user?.iin,
			lastname: user?.lastname,
			firstname: user?.firstname,
			middlename: user?.middlename || null,
			certificateExpiresAt: user?.certificateExpiresAt,
			officePhone: data?.officePhone,
			officePhoneInternal: data?.officePhoneInternal,
			mobilePhone: data?.mobilePhone,
			photoId: data?.photoId || user?.photoId,
		})
		onSign(await api.profile.getXML())
	}

	useEffect(() => {
		if (successPayload) {
			const base64 = Buffer.from(successPayload).toString('base64')
			dispatch(userThunks.createEditUser({ ...formData, xml: base64, id: user?.personDetailsId }))
			onClearPayload()
		}
	}, [successPayload])

	const onChangePhoto = useCallback(async (file) => {
		try {
			const formData = new FormData()
			formData.set('file', file)
			const { id } = await uploadFile(formData)
			const form = formRef.current
			form.setFieldValue('photoId', id)
		} catch (error) {
			console.error('[error uploading file] ', error)
		}
	}, [])

	const onReset = (callback) => {
		callback()
		tabControlRef?.current?.onChangeActiveTab('worker_data')
	}

	const { t } = useTranslation()
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={EmployeeValidationScheme}
			onSubmit={onSubmit}
			innerRef={formRef}
		>
			{({ resetForm }) => (
				<Form>
					<div className={styles.row}>
						<div className={styles.formCol}>
							<EmployeeAccountInfo worker={user} />
							<div className="mt-32">
								<Title type="h3">{t('contact_information')}</Title>
								<div className={styles.fields}>
									<FormikField name="email">
										{({ field, meta: { touched, error } }) => (
											<Field
												labelClassName={styles.formLabel}
												type="text"
												label={t('electronic_mail')}
												readOnly
												isRequired
												error={touched && error ? t(error) : ''}
												{...field}
											/>
										)}
									</FormikField>
									<div className={styles.phoneRow}>
										<FormikField name="officePhone">
											{({ field, meta: { touched, error } }) => (
												<Field
													labelClassName={styles.formLabel}
													type="text"
													label={t('officePhone')}
													error={touched && error ? t(error) : ''}
													{...field}
												/>
											)}
										</FormikField>
										<FormikField name="officePhoneInternal">
											{({ field }) => (
												<Field labelClassName={styles.formLabel} type="text" label={t('internal')} {...field} />
											)}
										</FormikField>
									</div>
									<FormikField name="mobilePhone">
										{({ field, meta: { touched, error } }) => (
											<Field
												labelClassName={styles.formLabel}
												type="text"
												label={t('mobilePhone')}
												error={touched && error ? t(error) : ''}
												{...field}
											/>
										)}
									</FormikField>
								</div>
							</div>
						</div>
						<div className="flex-1">
							<UploadImageWithLabel
								label={`${t('photo')}:`}
								onChange={onChangePhoto}
								initialValue={initialValues.photoId}
								maxSize={512000}
							/>
						</div>
					</div>
					<div className={styles.formButtons}>
						<Button buttonClassName={styles.formButton} type="bare" onClick={() => onReset(resetForm)}>
							{t('cancel')}
						</Button>
						<Button buttonClassName={styles.formButton} type="submit">
							{t('save')}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
