import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: false,
	phone: null,
	qr: null,
	qr_key: null,
	qr_status: {},
	isSuccessSend: false,
	errorSend: false,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_PHONE:
			return { ...state, phone: payload }
		case types.SET_QR:
			return { ...state, qr: payload }
		case types.SET_QR_KEY:
			return { ...state, qr_key: payload }
		case types.GET_QR_STATUS:
			return { ...state, qr_status: payload }
		case types.TOGGLE_SUCCESS_SEND:
			return { ...state, isSuccessSend: payload }
		case types.TOGGLE_ERROR_SEND:
			return { ...state, errorSend: payload }
		case types.RESET_VERIFICATION:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				error: false,
				phone: null,
				isSuccessSend: false,
				errorSend: false,
			}
		default:
			return state
	}
}
