import * as types from './types'

export const actions = {
	setTerms: (payload) => ({ type: types.SET_TERMS, payload }),
	setTerminateXML: (payload) => ({
		type: types.E_SET_TERMINATION_XML,
		payload,
	}),
	setTerminationLoading: (payload) => ({
		type: types.SET_TERMINATION_LOADING,
		payload,
	}),
	setTerminationSuccess: (payload) => ({
		type: types.SET_TERMINATION_SUCCESS,
		payload,
	}),
}
