import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import styles from './GeneralData.module.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'

export const GeneralData = ({ agreement }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const lang = getCurrentLanguage()
	const industryAgreement = useSelector(handbookSelectors.industryAgreement)

	const generateIndustryAgreementString = (items = [industryAgreement], ids = [agreement.industryCodes]) => {
		return ids
			.map((id) => {
				const item = items.find((item) => item.code.toString() === id)
				return item ? item[language] : null
			})
			.filter(Boolean) // Оставляем только ненулевые значения
			.join(', ')
	}

	const industryAgreementString = generateIndustryAgreementString(industryAgreement, agreement.industryCodes)
	const agreementData = [
		{
			title: t('industryAgreement.industryAgreementName'),
			data: lang === 'rus' ? agreement.industryAgreementNameRu : agreement.industryAgreementNameKz,
		},
		{
			title: t('industryAgreement.authStateName'),
			data: lang === 'rus' ? agreement.authStateNameRu : agreement.authStateNameKz,
		},
		{
			title: t('industryAgreement.industryAgreement'),
			data: industryAgreementString,
		},
		{ title: t('industryAgreement.createDate'), data: agreement.createDate },
		{ title: t('industryAgreement.dateFrom'), data: agreement.dateFrom },
		{ title: t('industryAgreement.dateTo'), data: agreement.dateTo },
	]

	return (
		<div>
			<DataGridTable
				hideTitle
				hideSubTitle
				titleClass={styles.tableTitleCell}
				information={agreementData}
			></DataGridTable>
		</div>
	)
}
