import styles from './style.module.scss'

export const Label = ({ name, size, children, className }) => {
	const stylesBySize = {
		default: styles.default,
		small: styles.small,
	}
	return (
		<label htmlFor={name} className={`${stylesBySize[size]} ${className}`}>
			{children}
		</label>
	)
}
