export const headCells = [
	{
		id: 'role',
		numeric: false,
		label: 'electronic_contract.role',
	},
	{
		id: 'partiesRequisites',
		numeric: true,
		label: 'electronic_contract.parties_requisites',
	},
	{
		id: 'nameFio',
		numeric: false,
		label: 'electronic_contract.name_fio',
	},
	{
		id: 'signDate',
		numeric: false,
		label: 'electronic_contract.sign_date',
	},
	{
		id: 'statusSignEec',
		numeric: false,
		label: 'electronic_contract.status_sign_eds',
	},
]
