import React, { useEffect, useState } from 'react'
import styles from './Calendar.module.scss'
import { Arrow } from '@app/icons'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { getSSPData, downloadSSPData } from '@/shared/api/rest/personalAffairs'
import { datePickerlocaleRu, datePickerlocaleKz } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { LoadingBlocker } from '@/components/Loader'
import { Button } from '@/ui'
import { message } from 'antd'
import { InfoDevelopment } from '@/components/infoDevelopment'

export const Calendar = ({ activeCompany, itemsYears }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [openIndex, setOpenIndex] = useState(null)
	const [loading, setLoading] = useState(true)
	const [calendars, setCalendars] = useState([])
	const [lastUpdateDate, setLastUpdateDate] = useState(null)
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

	useEffect(async () => {
		try {
			if (activeCompany.bin) {
				setLoading(true)
				const result = await getSSPData(activeCompany.bin)
				if (result) {
					setCalendars(result.employerInformationDtos)
					setLastUpdateDate(result.lastUpdateDate)
					setLoading(false)
				}
			}
		} catch (error) {
			setCalendars([])
		} finally {
			setLoading(false)
		}
	}, [])

	const toggleItem = (index) => {
		setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
	}

	const handleYearChange = (action) => {
		const yearInt = parseInt(action, 10)
		if (!isNaN(yearInt)) {
			setSelectedYear(yearInt)
		}
	}

	const getDate = (date) => {
		return (
			(language === 'rus' ? datePickerlocaleRu : datePickerlocaleKz).months[parseInt(date.split('.')[1], 10) - 1] +
			' ' +
			date.split('.')[2]
		)
	}

	const downloadFile = async (period) => {
		const response = await downloadSSPData(period, activeCompany.bin)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = getDate(period)
		document.body.appendChild(a)
		a.click()
	}

	const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	const handleSSPData = async () => {
		try {
			if (activeCompany.bin) {
				setLoading(true)
				const result = await getSSPData(activeCompany.bin, false)
				if (result) {
					setCalendars(result.employerInformationDtos)
					setLastUpdateDate(result.lastUpdateDate)
					setLoading(false)
				}
			}
		} catch (error) {
			setLoading(false)
			const err = error?.response?.data?.message || 'Попробуйте позже, технические проблемы на стороне сервиса'
			message.error(err ? err : 'Попробуйте позже, технические проблемы на стороне сервиса', 5)
		}
	}

	const filteredCalendars = calendars.filter((calendar) => {
		const year = new Date(calendar.period.split('.').reverse().join('.')).getFullYear()
		return year === selectedYear
	})

	return (
		<>
			<div className={styles.update}>
				<Button onClick={handleSSPData}>{t('update')}</Button>
				<InfoDevelopment title={'welfare.info'} small={true} child={lastUpdateDate} />
			</div>
			<div className={styles.dropdown}>
				<DropdownMenu title={selectedYear.toString()} items={itemsYears} onItemClick={handleYearChange} />
			</div>
			{filteredCalendars
				.sort((a, b) => {
					const dateA = new Date(a.period.split('.').reverse().join('.'))
					const dateB = new Date(b.period.split('.').reverse().join('.'))
					return dateA - dateB
				})
				.map((calendar, index) => (
					<section key={index}>
						<div className={styles.row}>
							<div className={styles.title}>{getDate(calendar.period)}</div>
							<div className={styles.wrap}>
								<div className={styles.wrapRow}>
									<div className={styles.title}>{calendar.cntAssignedForPayment}</div>
									<div className={styles.gray}>{t('payment.assignedForPayment')}</div>
								</div>
								<div className={styles.wrapRow}>
									<div className={styles.title}>{formatNumber(calendar.expectedPayouts)} тг.</div>
									<div className={styles.gray}>{t('payment.dueToBePaid')}</div>
								</div>
								<div className={styles.wrapRow}>
									<div className={`${styles.title} ${styles.green}`}>
										{formatNumber(calendar.paidToIndividuals)} тг.
									</div>
									<div className={styles.gray}>{t('payment.paidToPersons')}</div>
								</div>
								<div className={styles.wrapRow}>
									<div className={`${styles.title} ${styles.blue}`}>{formatNumber(calendar.refundAmount)} тг.</div>
									<div className={styles.gray}>{t('payment.refund')}</div>
								</div>
								<div className={styles.wrapRow}>
									<div className={`${styles.title} ${styles.red}`}>{formatNumber(calendar.employerDebt)} тг.</div>
									<div className={styles.gray}>{t('payment.employersDebt')}</div>
								</div>
							</div>
							<div>
								{openIndex === index ? (
									<>
										<span className={`${styles.green} ${styles.pointer}`} onClick={() => toggleItem(index)}>
											{t('payment.briefly')} <Arrow className={`${styles.icon} ${styles.transform}`} />
										</span>
										<table className={styles.table}>
											<thead>
												<tr>
													<th>{t('payment.table.assignedForPayment')}</th>
													<th>{t('payment.table.appointmentDate')}</th>
													<th>{t('payment.table.dueToBePaid')}</th>
													<th>{t('payment.table.paidToPerson')}</th>
													<th>{t('payment.table.refund')}</th>
													<th>{t('payment.table.employersDebt')}</th>
												</tr>
											</thead>
											<tbody>
												{calendar.employeeInformations.map((info, key) => (
													<tr key={key}>
														<td>{info.fio}</td>
														<td>{info.assignmentDate}</td>
														<td>{formatNumber(info.expectedPayouts)}</td>
														<td>{formatNumber(info.paidToIndividuals)}</td>
														<td>{formatNumber(info.refundAmount)}</td>
														<td>{formatNumber(info.employerDebt)}</td>
													</tr>
												))}
											</tbody>
										</table>
										<span className={`${styles.green} ${styles.pointer}`} onClick={() => downloadFile(calendar.period)}>
											{t('labor_records.download')}
										</span>
									</>
								) : (
									<span className={`${styles.green} ${styles.pointer}`} onClick={() => toggleItem(index)}>
										{t('payment.details')} <Arrow className={styles.icon} />
									</span>
								)}
							</div>
						</div>
					</section>
				))}
			{loading && <LoadingBlocker />}
		</>
	)
}
