import React from 'react'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity/user/model/index'
import { UploadImagePlaceholder } from '@app/images/index'
import styles from './ProfilePopup.module.scss'

export const EmployeeProfile = () => {
	const user = useSelector(userSelectors.user)

	return (
		<div className={styles.profile}>
			<img
				alt="logo"
				src={user?.photoId ? `${process.env.REACT_APP_API_URL}/file/notype/${user.photoId}` : UploadImagePlaceholder}
				className={styles.logo}
			/>
			<div>
				<p className={styles.nameValue}>{user?.lastname}</p>
				<p className={styles.nameValue}>{user?.firstname}</p>
				<p className={styles.nameValue}>{user?.middlename}</p>
			</div>
		</div>
	)
}
