export const headCells = [
	{
		id: 'iin',
		numeric: true,
		label: 'welfare.tabs.table.iin',
	},
	{
		id: 'fio',
		numeric: false,
		label: 'fio',
	},
	{
		id: 'status',
		numeric: false,
		label: 'welfare.tabs.table.status',
	},
]
