import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import React from 'react'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import styles from './CompanyPassport.module.scss'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import { LoadingBlocker } from '@/components/Loader'
import { defaultPassport } from '@pages/CompanyPassport/dfault'
import { CommonInfo } from '@pages/CompanyPassport/CommonInfo'
import TabPanel from '@mui/base/TabPanel'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { CompanyObjects } from '@pages/CompanyPassport/CompanyObjects'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { CompanyInsurance } from '@pages/CompanyPassport/CompanyInsurance'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

export const CompanyPassport = () => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const {
		loading,
		data: passport,
		isEmpty,
	} = useFetchData({
		bin: activeCompany.bin,
		fetchFunction: safetyService.getBasicInfo,
		defaultValue: defaultPassport,
	})

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_enterprise_passport')}>
				{!!passport.nameRu ? (
					<div className={styles.main_container}>
						<Typography variant={'title'}>{lang === 'rus' ? passport.nameRu : passport.nameKz}</Typography>
					</div>
				) : null}
				{loading ? (
					<LoadingBlocker />
				) : (
					<TabsUnstyled defaultValue={0}>
						<TabsList>
							<Tab value={0} id={'all'}>
								{t('ckp_general_data')}
							</Tab>
							<Tab value={1} id={'objects'}>
								{t('ckp_objects')}
							</Tab>
							<Tab value={2} id={'OSRNS'}>
								{t('ckp_OSRNS')}
							</Tab>
						</TabsList>
						<>
							<TabPanel value={0}>
								{isEmpty ? (
									<InfoDevelopment title={t('ckp_no_info_on_your_enterprise')} small={false} />
								) : (
									<CommonInfo isEmpty={isEmpty} passport={passport} />
								)}
							</TabPanel>
							<TabPanel value={1}>
								<CompanyObjects activeCompany={activeCompany}></CompanyObjects>
							</TabPanel>
							<TabPanel value={2}>
								<CompanyInsurance bin={activeCompany?.bin}></CompanyInsurance>
							</TabPanel>
						</>
					</TabsUnstyled>
				)}
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
