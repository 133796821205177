import { useDispatch, useSelector } from 'react-redux'
import { contractsActions, contractsSelectors, contractsThunks } from '@/entity'
import { useDigitalSign } from '@/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { LoadingBlocker } from '@/components/Loader'
import { ViewSocialLeaveModal } from '@features/contracts/socialLeaves/ViewSocialLeaveModal'
import RemoveSocialLeaveModal from '@features/contracts/socialLeaves/RemoveSocialLeaveModal'
import CreateEditSocialLeaveModal from '@features/contracts/socialLeaves/CreateEditSocialLeaveModal'

const SocialLeaveMain = ({ onClose, socialLeave, mode, onToggleAction, page, pageSize, filters }) => {
	const xml = useSelector(contractsSelectors.socialLeaveXml)
	const isThunkLoading = useSelector(contractsSelectors.socialLeaveIsLoading)
	const isSuccess = useSelector(contractsSelectors.socialLeaveIsSuccess)
	const dispatch = useDispatch()
	const { successPayload, onSign, isLoading, failedPayload, onClearPayload } = useDigitalSign()
	const [paramMap, setParamMap] = useState({})

	const onDelete = useCallback(() => {
		onToggleAction('removeSocialLeave', true)
		onToggleAction('viewSocialLeave', false)
	}, [onToggleAction])

	const onEdit = useCallback(() => {
		onToggleAction('editSocialLeave', true)
		onToggleAction('viewSocialLeave', false)
	}, [onToggleAction])

	const onSignLocal = useCallback(
		() =>
			dispatch(
				contractsThunks.createSocialLeave({
					...socialLeave,
					dleaveTypeCode: socialLeave.dleaveTypeCode.value,
				})
			),
		[socialLeave, dispatch]
	)

	const fullClear = () => {
		onClose()
		dispatch(contractsActions.resetSocialLeave())
		onClearPayload()
		setParamMap({})
	}

	useEffect(() => mode === 'sign' && onSignLocal(), [mode])
	useEffect(() => {
		xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [xml]) /** подписание xml */
	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])
	useEffect(
		() => {
			if (successPayload) {
				dispatch(contractsThunks.sendSignedSocialLeave(successPayload, paramMap, page, pageSize, filters))
				setParamMap({})
			}
		} /** отправка подписанного XML файла */,
		[successPayload]
	)
	useEffect(() => {
		failedPayload && mode === 'sign'
			? fullClear()
			: dispatch(contractsActions.resetSocialLeave()) /** сброс redux параметров при ошибке NCALayer */
		return () => dispatch(contractsActions.resetSocialLeave()) /** сброс redux параметров при закрытии компонента */
	}, [failedPayload])

	return (
		<>
			{mode === 'view' && (
				<ViewSocialLeaveModal
					socialLeave={socialLeave}
					onDelete={onDelete}
					onEdit={onEdit}
					onClose={onClose}
					onSign={onSignLocal}
				/>
			)}
			{mode === 'remove' && (
				<RemoveSocialLeaveModal onClose={onClose} socialLeave={socialLeave} isLoading={isThunkLoading || isLoading} />
			)}
			{mode === 'create' && <CreateEditSocialLeaveModal setParamMap={setParamMap} onClose={onClose} />}
			{mode === 'edit' && (
				<CreateEditSocialLeaveModal socialLeave={socialLeave} setParamMap={setParamMap} onClose={onClose} />
			)}
			{(isLoading || isThunkLoading) && <LoadingBlocker />}
		</>
	)
}
export default SocialLeaveMain
