import React, { useEffect, useRef } from 'react'
import { ChangeContractBinForm, formFieldNames } from '@/entity/contracts'
import { ModalTemplate } from '@/ui'
import styles from './style.module.scss'
import { contractsActions } from '@/entity'
import { useDispatch } from 'react-redux'

export const ChangeBinForm = ({
	initialValues,
	options = {},
	workerData,
	onSubmit,
	onFindWorker,
	onGetHandBookOptions,
	onCancel,
	setIsEsutd,
}) => {
	const formRef = useRef(null)
	const dispatch = useDispatch()

	useEffect(() => {
		if (workerData) {
			const form = formRef.current
			if (form) {
				form.setFieldValue(formFieldNames.employeeInitials, workerData)
			}
		}
	}, [workerData])

	const validateOnFindWorker = async (form) => {
		const { iin } = await form.setFieldTouched(formFieldNames.iin)
		if (!iin) {
			const { iin } = form.values
			onFindWorker(iin)
		}
	}

	const handleClose = () => {
		dispatch(contractsActions.clearDataByBin())
		onCancel()
	}

	return (
		<ModalTemplate onClose={handleClose}>
			<div className={`scroll ${styles.wrapper}`}>
				<ChangeContractBinForm
					options={options}
					initialValues={initialValues}
					advanced
					onSubmit={onSubmit}
					validateOnFindWorker={validateOnFindWorker}
					onGetHandBookOptions={onGetHandBookOptions}
					onClose={handleClose}
					formRef={formRef}
					isTitle
					setIsEsutd={setIsEsutd}
				/>
			</div>
		</ModalTemplate>
	)
}
