import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Successful = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <path d="M8 0.819336C3.58853 0.819336 0 4.40787 0 8.81934C0 13.2308 3.58853 16.8193 8 16.8193C12.4115 16.8193 16 13.2302 16 8.81934C16 4.40849 12.4115 0.819336 8 0.819336ZM8 15.58C4.27266 15.58 1.23934 12.5473 1.23934 8.81934C1.23934 5.09137 4.27266 2.05868 8 2.05868C11.728 2.05868 14.7607 5.09137 14.7607 8.81934C14.7607 12.5473 11.7273 15.58 8 15.58Z" fill="#17B67C"/>
            <path d="M11.6718 6.03663C11.4196 5.80735 11.0273 5.82532 10.7968 6.07876L7.01678 10.2411L5.19059 8.3846C4.94953 8.14045 4.5579 8.13673 4.31437 8.37717C4.07022 8.61698 4.0665 9.00923 4.30693 9.25338L6.59291 11.5772C6.71003 11.6961 6.86866 11.7624 7.03472 11.7624C7.03844 11.7624 7.04278 11.7624 7.0465 11.7631C7.21753 11.7594 7.37866 11.6862 7.49328 11.5598L11.7139 6.91226C11.9438 6.65817 11.9252 6.26654 11.6718 6.03663Z" fill="#17B67C"/>
        </Svg>
    );
};

export default Successful;