import classes from './Search.module.scss'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

export const Search = ({ fetchData, count = 0, isRequests = false }) => {
	const { t } = useTranslation()
	const [searchValue, setSearchValue] = useState('')

	const onChange = (e) => setSearchValue(e.target.value)
	const onEnterKeyPressed = (e) => e.key === 'Enter' && e.preventDefault() && fetchData()

	const activeRole = useSelector(userSelectors.activeRole)

	return (
		<div className={classes.search}>
			<div className={classes.search__field}>
				<IconButton type="submit" aria-label="search" className={classes.search__icon}>
					<SearchIcon />
				</IconButton>
				<InputBase
					fullWidth
					placeholder={
						activeRole == 'ROLE_EMPLOYEE' ? t('enter_bin_or_name') : t('personal_affairs.search_placeholder')
					}
					inputProps={{ 'aria-label': 'search' }}
					value={searchValue}
					onChange={onChange}
					onKeyDown={onEnterKeyPressed}
				/>
				<span className={classes.search__button} onClick={() => fetchData(searchValue)}>
					{t('find')}
				</span>
			</div>
			<span className={classes.search__title}>
				{`${isRequests ? t('personal_affairs.total_count_request') : t('personal_affairs.total_count')}: ${count}`}
			</span>
		</div>
	)
}
