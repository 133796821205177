import styles from './Table.module.scss'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View as ViewIcon } from '@app/images'
import exportIcon from '@/assets/pages/personalAffairs/export.svg'
import { useSelector } from 'react-redux'
import { formatDate } from '@/utils'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'

export const Table = ({ headCells, setSelectedMethod, downloadPDF }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const isLoading = useSelector((state) => state.affairs.isLoading)
	const isSuccess = useSelector((state) => state.affairs.isSuccess)
	const data = useSelector((state) => state.affairs.employeeCard)
	const { methodInfoData } = data || {}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'}>
				<td>{index + 1}</td>
				<td>{language === 'rus' ? row.method.nameRu : row.method.nameKz || '-'}</td>
				<td>{row.requestDate ? formatDate(row.requestDate) : '-'}</td>
				<td>{row.consentDate ? formatDate(row.consentDate) : '-'}</td>
				<td onClick={() => setSelectedMethod(row.method.code)}>
					<div className={styles.view__td}>
						<img src={ViewIcon} alt="" className={styles.icon} />
						{t('actions.view')}
					</div>
				</td>
				<td onClick={() => downloadPDF(row.method.code)}>
					<img src={exportIcon} alt="" className={styles.icon} />
					{t('personal_affairs.card.table.headers.download')}
				</td>
			</tr>
		),
		[t]
	)

	const tableBody = useMemo(
		() => !!methodInfoData && methodInfoData.map((row, index) => getTableRow(row, index)),
		[methodInfoData, getTableRow]
	)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{isLoading || methodInfoData?.length < 1 ? tableLoading : tableBody}</tbody>
			</table>
		</>
	)
}
