import { TRANSLATION_KZ } from './kz/translation'
import { TRANSLATION_RUS } from './rus/translation'

export const resources = {
	kz: {
		translation: TRANSLATION_KZ,
	},
	rus: {
		translation: TRANSLATION_RUS,
	},
}
