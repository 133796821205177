import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null
}

const initialState = {
	reviews: data,
	review: data,

	currentReview: null
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.SET_CURRENT_REVIEW:
			return { ...state, currentReview: payload }


		case types.GET_REVIEW_ACTION:
			return { ...state, reviews: {...data, loading: true} }

		case types.GET_REVIEW_ACTION_DONE:
			return { ...state, reviews: {...data, success: true, data: payload} }

		case types.GET_REVIEW_ACTION_FAIL:
			return { ...state, reviews: {...data, failed: true} }


		case types.REVIEW_ACTION:
			return { ...state, review: {...data, loading: true} }

		case types.REVIEW_ACTION_DONE:
			return { ...state, review: {...data, success: true, data: payload} }

		case types.REVIEW_ACTION_FAIL:
			return { ...state, review: {...data, failed: true} }

		default:
			return state
	}
}
