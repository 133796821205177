import { connect, Field as FormikField } from 'formik'
import { getCurrentLanguage } from '@/i18next'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { Checkbox, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

export const ContractPositionField = connect(
	({ positions = [], positionsByKnz = [], isView, formik: { setFieldValue, values } }) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()
		const establishedPosition = useMemo(
			() => values.destablishedPostCode || values.establishedPost,
			[values.destablishedPostCode, values.establishedPost]
		)
		const handleEstablishedPositionChange = useCallback((code) => {
			const pos = positions?.find((item) => item.code === code)
			if (pos) {
				/** Если значение из справочника, то заполняем destablishedPostCode */
				setFieldValue('destablishedPostCode', code)
				setFieldValue('establishedPost', lang === 'rus' ? pos[lang || 'rus'] : pos[lang || 'kz'])
			} else {
				/** Если ручной ввод, то заполняем значение establishedPost */
				setFieldValue('establishedPost', code)
				setFieldValue('destablishedPostCode', null)
			}
		}, [])

		return (
			<>
				<div className={'flex-1'}>
					{isView ? null : (
						<FormikField name={formFieldNames.dpositionCodeIs}>
							{({ field }) => (
								<Checkbox
									{...field}
									label={t('labor_records.position_by_nkz')}
									hint={t('hint_position_knz')}
									onChange={() => setFieldValue(field.name, !field.value)}
								/>
							)}
						</FormikField>
					)}
					<FormikField name={formFieldNames.dpositionCode}>
						{({ field, meta: { touched, error } }) => {
							return (
								<Field
									fieldType="selectSearch"
									placeholder={t('choose_from_directory')}
									options={positionsByKnz}
									error={touched ? error : ''}
									hideNotFoundError
									{...field}
									label={isView ? t('labor_records.position_by_nkz') : null}
									disabled={!values.dpositionCodeIs || isView}
								/>
							)
						}}
					</FormikField>
				</div>
				<div className={'flex-1'}>
					{isView ? null : (
						<FormikField name={formFieldNames.establishedPositionIs}>
							{({ field }) => (
								<Checkbox
									{...field}
									label={t('contract.info.position')}
									hint={t('hint_position')}
									onChange={() => setFieldValue(field.name, !field.value)}
								/>
							)}
						</FormikField>
					)}
					<Field
						fieldType="selectAndInput"
						label={isView ? t('contract.info.position') : null}
						value={establishedPosition}
						onChange={handleEstablishedPositionChange}
						placeholder={t('position_placeholder')}
						options={positions}
						error={!isView ? !establishedPosition && { key: 'required' } : null}
						disabled={!values.establishedPositionIs || isView}
					/>
				</div>
			</>
		)
	}
)
