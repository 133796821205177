import s from '../../components/entrance/selectButton.module.css'

const SelectButton = (props) => {
	const { showEntranceForm, children, assign, job, communication } = props
	return (
		<button className={s.btn} {...props}>
			{children}
		</button>
	)
}

export default SelectButton
