export const headCells = [
	{
		id: 'check_date',
		numeric: false,
		disablePadding: false,
		label: 'history_check.check_date',
	},
	{
		id: 'theme',
		numeric: false,
		disablePadding: false,
		label: 'history_check.check_subject',
	},
	{
		id: 'result',
		numeric: false,
		disablePadding: false,
		label: 'history_check.result',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: 'history_check.action',
	},
]
