export const headCells = [
	{
		id: 'accidentDate',
		numeric: false,
		disablePadding: true,
		label: 'accidentsPage.accidentDate',
	},
	{
		id: 'victimFullName',
		numeric: false,
		disablePadding: false,
		label: 'accidentsPage.victimFullName',
	},
	{
		id: 'severity',
		numeric: false,
		disablePadding: false,
		label: 'accidentsPage.severity',
	},
	{
		id: 'state',
		numeric: false,
		disablePadding: false,
		label: 'accidentsPage.state',
	},
	{
		id: 'executionPeriod',
		numeric: false,
		disablePadding: false,
		label: 'accidentsPage.executionPeriod',
	},
]
