import React, { useEffect, useState, useCallback } from 'react'
import { ProfileTemplate } from '@/widgets'

import { RedirectUnAuthUser } from '@/processes'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/system'
import TabsUnstyled, { tabsClasses } from '@mui/base/Tabs'
import TabsListUnstyled from '@mui/base/TabsList'
import TabPanelUnstyled from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Tab from '@/ui/atoms/tab'
import { NotificationsTabPanelList, Tags } from '@features/notifications'
import { HrPagination } from '@/components/Pagination/hrPagination'
import { notificationsSelectors, notificationsThunks, notificationsActions } from '@/entity/notifications/index'
import { NotificatonDetails } from '@features/notifications/modal'
import { actions } from '@/entity/notifications/actions'
import { getFromLocalStorage } from '@/shared'
import { companySelectors } from '@/entity/company'
import { userSelectors } from '@/entity'
import PreHeader from '@/components/home/components/PreHeader'

const Tabs = styled(TabsUnstyled)({})

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	max-width: 90vw;
	margin: 20px 34px 0 0;
	font-family: 'Inter', sans-serif;
	font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
	width: fit-content;
	max-width: 90vw;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	height: 41px;
`

export const NotificationsPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const isSuccess = useSelector(notificationsSelectors.isSuccess)
	const companies = useSelector(companySelectors.companies)
	const user = useSelector(userSelectors.user)
	const tags = useSelector(notificationsSelectors.tags)
	const activeRoleFroLS = getFromLocalStorage('activeRole')
	const [activeTab, setActiveTab] = useState(0)
	const [isVisible, setIsVisible] = useState(false)
	const [modalData, setModaldata] = useState(null)
	const location = useLocation()
	const refValue = location.state?.refValue // Получение значения из объекта состояния маршрута
	const [notifyId, setNotifyId] = useState(refValue?.externalId)
	const [chosen, setChosen] = useState(['all'])
	useEffect(() => {
		if (isSuccess && refValue) {
			setModaldata(refValue)
			setIsVisible(true)
			dispatch(notificationsThunks.setRead(notifyId, activeRoleFroLS === 'ROLE_EMPLOYER'))
			dispatch(actions.toggleSuccess(false))
			setNotifyId(null)
		}
		dispatch(notificationsThunks.getTags())
	}, [])

	const notifyData = useSelector(notificationsSelectors.notifications)

	const queryParams = useSelector((state) => state.notifications.queryState)

	const setQueryParams = (params) => dispatch(notificationsActions.setQueryParams(params))

	const handleChangeTab = (e, newValue) => {
		setActiveTab(newValue)
		switch (newValue) {
			case 0:
				setQueryParams({ ...queryParams, page: 0, typeCode: 'NOTIF', searchText: null, unread: false, pageSize: 10 })
				break
			case 1:
				setQueryParams({ ...queryParams, page: 0, typeCode: 'EVENT', searchText: null, unread: false, pageSize: 10 })
				break
			default:
				break
		}
	}

	const handleChangePage = (e, page) => {
		setQueryParams({ ...queryParams, page: page - 1 })
	}

	const handleChangeRowsPerPage = (size) => {
		setQueryParams({ ...queryParams, page: 0, pageSize: size, typeCode: queryParams.typeCode, searchText: null })
	}

	const fetchData = useCallback(() => {
		const { page, pageSize, unread, typeCode, searchText } = queryParams
		const employer = activeRoleFroLS === 'ROLE_EMPLOYER'
		if (
			(companies.length && activeRoleFroLS === 'ROLE_EMPLOYER') ||
			(user?.iin?.length && activeRoleFroLS === 'ROLE_EMPLOYEE')
		) {
			dispatch(
				notificationsThunks.getNotifications(
					page,
					pageSize,
					unread,
					!activeTab ? 'NOTIF' : typeCode,
					searchText,
					employer,
					chosen
				)
			)
		}
	}, [activeTab, dispatch, queryParams, chosen, companies, user])

	useEffect(() => {
		fetchData()
	}, [activeTab, queryParams.page, queryParams.pageSize, chosen, companies, user])

	const handleClickItem = useCallback(
		(item) => {
			if (!item.readDate) {
				if (item.otherId && item.notifType?.code === 'ecterm.send_to_employer')
					dispatch(notificationsThunks.setReadTermination(item.otherId, activeRoleFroLS === 'ROLE_EMPLOYER'))
				dispatch(notificationsThunks.setRead(item.externalId, activeRoleFroLS === 'ROLE_EMPLOYER')).then(
					dispatch(notificationsThunks.getNotificationsBadge(activeRoleFroLS === 'ROLE_EMPLOYER'))
				)
			}
			setModaldata(item)
			setIsVisible(true)
			if (item.notifType?.code !== 'emaket_20') item.readDate = Date.now()
		},
		[dispatch, history, activeTab]
	)

	const handleClose = useCallback(
		(needReload) => {
			if (needReload) {
				fetchData()
			}
			setIsVisible(false)
		},
		[fetchData]
	)

	const pagination = React.useMemo(
		() => (
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={queryParams.rowsPerPage}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={notifyData.numberOfElements}
				totalElements={notifyData.totalElements}
				totalPages={notifyData.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
		),
		[activeTab, queryParams.page, queryParams.pageSize, notifyData]
	)
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={'notifications'}>
					<Tabs defaultValue={0} onChange={handleChangeTab}>
						<NotificatonDetails visible={isVisible} onClose={handleClose} data={modalData} />
						<TabsList>
							<Tab value={0}>{t('notifications')}</Tab>
							<Tab value={1}>{t('events')}</Tab>
						</TabsList>
						<Tags chosen={chosen} setChosen={setChosen} tags={tags ?? []} />
						<TabPanel value={0}>
							<NotificationsTabPanelList data={notifyData} handleClickItem={handleClickItem} nodata={t('nonotif')} />
						</TabPanel>
						<TabPanel value={1}>
							<NotificationsTabPanelList data={notifyData} handleClickItem={handleClickItem} nodata={t('noevent')} />
						</TabPanel>
					</Tabs>
					<div style={{ margin: '36px 34px 0 0' }}>{pagination}</div>
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}
