import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Title } from '@/ui'
import styles from './ChangeContractBinForm.module.scss'
import { contractsThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Form } from './Form'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'
import { format, isAfter, subYears } from 'date-fns'
import { formates } from '@app/config'

Yup.addMethod(Yup.date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, value: format(dateBefore, formates.date, new Date()) } })
			: true
	})
})

const ValidationSchema1 = Yup.object().shape({
	reorganizationDate: transformStringDateFormatToDate()
		// reorganizationDate: Yup.date()
		.nullable()
		.required({ key: 'required' })
		.minDateBefore({ yearsBefore: 50, error: 'labor.date.min' })
		.max(new Date(), { key: 'date.can_not_be_after_now' }),
})

const ValidationSchema2 = Yup.object().shape({
	oldBin: Yup.string()
		.required('required')
		.matches(/^[0-9]+$/, 'contracts_tab.not_correct_bin')
		.min(12, 'contracts_tab.min_12_length')
		.max(12, 'contracts_tab.max_12_length'),
})

export const ChangeContractBinForm = ({ onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [changeSchema, setChangeSchema] = useState(false)
	const [buttonPressed, setButtonPressed] = useState(false)

	const { contractPage } = useSelector((state) => ({
		contractPage: state.contracts.reorganize.checkData?.contractPage,
	}))

	useEffect(() => {
		if (contractPage && contractPage.length > 0) setChangeSchema(true)
	}, [contractPage])

	const handleSubmit = (data) => {
		setButtonPressed(true)
		dispatch(contractsThunks.getDataByBin(data))
	}

	return (
		<>
			<Title color="black">{t('contracts_tab.change_contract_bin')}</Title>
			<Formik
				validationSchema={changeSchema ? ValidationSchema1 : ValidationSchema2}
				className={styles.form}
				initialValues={{ reorganizationDate: '', oldBin: '' }}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				<Form onClose={onClose} className={styles.form} buttonPressed={buttonPressed} />
			</Formik>
		</>
	)
}
