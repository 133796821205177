import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './Table.module.scss'
import { headCells } from './table.headings'
import onRequestActionMenuClick from './actionMenu/onRequestActionMenuClick'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { pensionSelectors, pensionThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { colors, colorsState } from './utils'
import { PensionPayments } from '../PensionPayments/PensionPayments'

const Table = () => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = useMemo(() => (lang === 'rus' ? 'nameRu' : 'nameKz'), [lang])
	const [actionMenuItems, setActionMenuItems] = useState([])
	const [pension, setPension] = useState({})
	const dispatch = useDispatch()
	const isLoading = useSelector(pensionSelectors.isLoading)
	const isSuccess = useSelector(pensionSelectors.isSuccess)
	const pensions = useSelector(pensionSelectors.pensions)
	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head) => (
					<th key={head.id}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)
	const [actions, setActions] = useState({
		view: false,
		edit: false,
	})
	const getRequest = useCallback((id) => dispatch(pensionThunks.getPension(id)), [dispatch])
	const onToggleAction = (action, value, row) => {
		setPension(row)
		getRequest(row.externalId)
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}
	const fastView = (row) => {
		onToggleAction('view', true, row)
	}
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => fastView(row)}>
				<td>{row.sendDate || '-'}</td>
				<td>{row.iin}</td>
				<td style={{ textTransform: 'capitalize' }}>
					{row.surname?.toLowerCase() +
						' ' +
						(row.name !== null ? row.name.toLowerCase() : '') +
						' ' +
						(row.middleName !== null ? row.middleName.toLowerCase() : '')}
				</td>
				<td>{row.birthDate || '-'}</td>
				<td>
					<span style={{ color: colors[row.status.code] }}>{row.status[optionLang]}</span>
				</td>
				<td>
					<span style={{ color: colorsState[row.state.code] }}>{row.state[optionLang]}</span>
				</td>
				<td onClick={(e) => onRequestActionMenuClick(row, e, setActionMenuItems, t)}>
					<DropdownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[actionMenuItems, fastView, onActionMenuChoose, optionLang, t]
	)
	const tableBody = useMemo(() => pensions?.map((row, index) => getTableRow(row, index)), [pensions, getTableRow])
	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('pension.loading')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !pensions?.length && (
						<div className={styles.tableLoading}>{t('pension.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('pension.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, pensions]
	)
	const handlePensionPaymentsClose = useCallback(() => {
		setActions((value) => ({ ...value, view: false, edit: false }))
	}, [])
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!pensions?.length ? tableBody : tableLoading}</tbody>
			</table>
			{actions.view || actions.edit ? (
				<PensionPayments
					onToggleAction={actions}
					pensioner={pension}
					onClose={handlePensionPaymentsClose}
					newRecord={false}
				/>
			) : null}
		</>
	)
}

export default Table
