import { UploadImage } from '@/ui/atoms'
import styles from './style.module.scss'

export const UploadImageWithLabel = ({ label, ...props }) => {
	return (
		<div className={styles.wrapper}>
			<p className={styles.label}>{label}</p>
			<UploadImage {...props} />
		</div>
	)
}
