import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ProlongationTable.module.scss'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useSelector } from 'react-redux'
import { contractsSelectors } from '@/entity'
import { getCurrentLanguage } from '@/i18next'

export const headCells = [
	{
		id: 'number',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.number',
	},
	{
		id: 'endDate',
		numeric: false,
		disablePadding: false,
		label: 'contracts_tab.expired_date',
	},
	{
		id: 'prolongationDate',
		numeric: false,
		disablePadding: false,
		label: 'contracts_tab.registration_date',
	},
	{
		id: 'dcontractDateCode',
		numeric: false,
		disablePadding: false,
		label: 'contracts_tab.contract_term',
	},
]

export const ProlongationTable = ({ data, contractTerms }) => {
	const { t } = useTranslation()
	const isLoading = useSelector(contractsSelectors.isLoading)
	const lang = getCurrentLanguage()
	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head) => (
					<th key={head.label}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{index + 1}</td>
				<td>{row.endDate || '-'}</td>
				<td>{row.regDateProlongation || '-'}</td>
				<td>{contractTerms?.find((item) => item.code === row.dcontractDateCode)?.[lang] || '-'}</td>
			</tr>
		),
		[lang]
	)

	const tableBody = useMemo(() => data?.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableLoading = useMemo(
		() =>
			isLoading ? (
				<LoadingScreen>
					<Title>{t('wait')}</Title>
				</LoadingScreen>
			) : null,
		[]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}
