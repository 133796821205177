import { Checkbox as MaterialCheckbox } from '@mui/material'
import styles from './style.module.scss'


export const Checkbox = (props) => {
	return (
		<MaterialCheckbox
			sx={{
				// color: pink[800],
				borderRadius: '4px',
				'&.Mui-checked': {
					color: '#17B67C',
					borderRadius: '4px',
				},
			}}
			classes={{ root: styles.checkbox__root }}
			{...props}
		/>
	)
}
