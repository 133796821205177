import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getFromLocalStorage } from '@/shared'

export const RedirectUnAuthUser = ({ children }) => {
	const dispatch = useDispatch()
	const [isAuth, setAuth] = useState(true)

	useEffect(() => {
		const isLogged = getFromLocalStorage('isLogged')
		setAuth(isLogged)
	}, [dispatch])

	if (!isAuth) return <Redirect to={'/'} />
	return <>{children}</>
}
