import { connect, Field as FormikField } from 'formik'
import { SwitchBox } from '@/ui/atoms/switchBox'
import { SelectPerson } from '@/components/SelectPerson/SelectPerson'
import styles from '@features/accidents/steps/accidentReport/Form.module.scss'
import { Button, Field } from '@/ui'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { getValueByPath } from '@/utils'

export const Victim = connect(
	({
		formik: { setFieldValue, values },
		name,
		index,
		showIndex,
		isFormDirty,
		positions,
		positionsNkz,
		genders,
		currentLanguage,
	}) => {
		const { t } = useTranslation()

		let preffix = name + '[' + index + '].'

		const handleEstablishedPositionChange = useCallback(
			(value) => {
				// Find a position by code
				let position = positions?.find((item) => item.code === value)
				if (position) {
					// Value is from select options
					setFieldValue(preffix + 'establishedPost', { code: position.code, nameRu: position.rus, nameKz: position.kz })
				} else {
					// Value is from manual input
					const newValue =
						currentLanguage === 'rus'
							? { code: null, nameRu: value, nameKz: null }
							: { code: null, nameRu: null, nameKz: value }
					setFieldValue(preffix + 'establishedPost', newValue)
				}
			},
			[positions, currentLanguage]
		)

		const handlePositionNkzChange = (code) => {
			if (!code) {
				setFieldValue(preffix + 'positionCode', null)
				setFieldValue(preffix + 'positionNameKz', null)
				setFieldValue(preffix + 'positionNameRu', null)
			} else {
				const position = positionsNkz.find((it) => it.code === code)
				setFieldValue(preffix + 'positionCode', position.code)
				setFieldValue(preffix + 'positionNameKz', position.kz)
				setFieldValue(preffix + 'positionNameRu', position.rus)
			}
		}

		const removeCurrentItem = () => {
			let items = values[name]
			if (index > -1 && index < items.length) {
				const newItems = [...items]
				newItems.splice(index, 1)
				setFieldValue(name, newItems)
			}
		}

		const establishedPosition = useMemo(() => {
			const establishedPostField = getValueByPath(values, preffix + 'establishedPost')
			return establishedPostField && typeof establishedPostField === 'object'
				? currentLanguage === 'rus'
					? establishedPostField.nameRu
					: establishedPostField.nameKz
				: establishedPostField
		}, [JSON.stringify(getValueByPath(values, preffix + 'establishedPost')), preffix, currentLanguage])

		return (
			<>
				<section>
					<h5>{t('accidentsPage.register.victim') + (showIndex ? ' ' + (index + 1) : '')} </h5>
					<FormikField name={preffix + 'isResident'}>
						{({ field }) => <SwitchBox disabled={false} {...field} label={t('accidentsPage.register.resident')} />}
					</FormikField>
					{showIndex && (
						<Button buttonClassName={'float-end'} type="bare" onClick={removeCurrentItem}>
							{t('remove')}
						</Button>
					)}
				</section>
				<div className={classNames(styles.row, showIndex && styles.victimSection_padding)}>
					{getValueByPath(values, preffix + 'isResident') ? (
						<section className={styles.victimSection_gender}>
							<SelectPerson
								isFormDirty={isFormDirty}
								wrapperClassName={styles.selectPersonWrapper}
								iinLabel={t('accidentsPage.victimIin')}
								iinFieldName={preffix + 'iin'}
								fullNameFieldName={preffix + 'fio'}
								onSelected={(value) => {
									setFieldValue(preffix + 'surname', value.surname)
									setFieldValue(preffix + 'firstname', value.firstname)
									setFieldValue(preffix + 'secondname', value.patronname)
								}}
							/>
							<div className={styles.ml}>
								<FormikField name={preffix + 'gender'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType="select"
											options={genders}
											isRequired
											label={t('gender')}
											placeholder={t('gender')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</div>
						</section>
					) : (
						<>
							<section className={styles.victimNonResidentDocuments}>
								<FormikField name={preffix + 'surname'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											fieldType="input"
											label={t('accidentsPage.victimSurname')}
											placeholder={t('accidentsPage.placeholderSurname')}
											error={(touched || isFormDirty) && error}
											{...field}
										/>
									)}
								</FormikField>
								<FormikField name={preffix + 'firstname'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											fieldType="input"
											label={t('accidentsPage.victimFirstname')}
											placeholder={t('accidentsPage.placeholderFirstname')}
											error={(touched || isFormDirty) && error}
											{...field}
										/>
									)}
								</FormikField>
								<FormikField name={preffix + 'secondname'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											fieldType="input"
											label={t('accidentsPage.victimSecondName')}
											placeholder={t('accidentsPage.placeholderSecondName')}
											error={(touched || isFormDirty) && error}
											{...field}
										/>
									)}
								</FormikField>
							</section>
							<section className={styles.victimNonResidentDocuments}>
								<FormikField name={preffix + 'birthDate'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											style={{ width: '100%' }}
											fieldType="datePicker"
											label={t('accidentsPage.register.birthday')}
											placeholder={t('default_date_value')}
											isRequired
											{...field}
											maxDate={new Date()}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
								<FormikField name={preffix + 'gender'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType="select"
											options={genders}
											isRequired
											label={t('gender')}
											placeholder={t('gender')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</section>
							<section className={styles.victimNonResidentDocuments}>
								<FormikField name={preffix + 'passportNumber'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											fieldType="input"
											label={t('accidentsPage.register.passport')}
											isRequired
											placeholder={t('accidentsPage.register.number')}
											error={(touched || isFormDirty) && error}
											{...field}
										/>
									)}
								</FormikField>
								<FormikField name={preffix + 'issuredBy'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											fieldType="input"
											label={t('accidentsPage.register.issuer')}
											isRequired
											placeholder={t('accidentsPage.register.issuerName')}
											error={(touched || isFormDirty) && error}
											{...field}
										/>
									)}
								</FormikField>
								<FormikField name={preffix + 'issuredWhen'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											style={{ width: '100%' }}
											fieldType="datePicker"
											label={t('accidentsPage.register.takenDate')}
											placeholder={t('default_date_value')}
											isRequired
											{...field}
											maxDate={new Date()}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</section>
						</>
					)}
					<section>
						<Field
							name={preffix + 'establishedPost'}
							options={positions}
							type="text"
							fieldType="selectAndInput"
							isRequired
							placeholder={t('position_placeholder')}
							onChange={handleEstablishedPositionChange}
							value={establishedPosition}
							label={t('contract.info.position')}
							error={isFormDirty && !establishedPosition && { key: 'required' }}
						/>
					</section>
					<section>
						<FormikField name={preffix + 'positionCode'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="selectSearch"
									isRequired
									label={t('contract.info.nkz_position')}
									placeholder={t('accidentsPage.register.positionNkz')}
									options={positionsNkz}
									error={(touched || isFormDirty) && error}
									{...field}
									onChange={handlePositionNkzChange}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name={preffix + 'diagnosis'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType="input"
									isRequired
									label={t('accidentsPage.register.icd10NameProf')}
									placeholder={t('accidentsPage.register.selectIcd10')}
									{...field}
									disabled={false}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</section>
				</div>
			</>
		)
	}
)
