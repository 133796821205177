import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './CoefData.module.scss'

export const CoefData = ({ agreement }) => {
	const { t } = useTranslation()

	const coefficientData = [
		{ title: t('industryAgreement.industrialCoef'), data: agreement.industrialCoef || '' },
		{ title: t('industryAgreement.baseSalary'), data: agreement.baseSalary || '' },
		{ title: t('industryAgreement.oneToTwoGradeCoef'), data: agreement.oneToTwoGradeCoef || '' },
		{ title: t('industryAgreement.twoToThreeGradeCoef'), data: agreement.twoToThreeGradeCoef || '' },
		{ title: t('industryAgreement.threeToFourGradeCoef'), data: agreement.threeToFourGradeCoef || '' },
		{ title: t('industryAgreement.fourToFiveGradeCoef'), data: agreement.fourToFiveGradeCoef || '' },
		{ title: t('industryAgreement.fiveToSixGradeCoef'), data: agreement.fiveToSixGradeCoef || '' },
		{ title: t('industryAgreement.sixToSevenGradeCoef'), data: agreement.sixToSevenGradeCoef || '' },
		{ title: t('industryAgreement.sevenToEightGradeCoef'), data: agreement.sevenToEightGradeCoef || '' },
	].filter((item) => item.data !== '')

	return (
		<div>
			<DataGridTable
				hideTitle
				hideSubTitle
				titleClass={styles.tableTitleCell}
				information={coefficientData}
			></DataGridTable>
		</div>
	)
}
