import React, { useCallback } from 'react'
import { Title } from '@/ui'
import { contractsSelectors, contractsThunks } from '@/entity'
import { SocialLeaveForm } from '@features/contracts/socialLeaves/form/SocialLeaveForm'
import styles from '../additionalContracts/AdditionalContractStyles.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'

const CreateEditSocialLeaveModal = ({ onClose, socialLeave, setParamMap }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const contract = useSelector(contractsSelectors.contract)

	const handleFormSubmit = useCallback((data, isLocal = false) => {
		let _data = { ...data }
		if (data.dleaveTypeCode == '2') {
			_data = {
				beginDate: data.beginDate,
				dleaveTypeCode: data.dleaveTypeCode,
				endDate: data.endDate,
				paramMap: data.paramMap,
				externalId: data.externalId,
			}
		}
		setParamMap({})
		if (isLocal) {
			dispatch(contractsThunks.createLocalSocialLeave(_data))
		} else {
			dispatch(contractsThunks.createSocialLeave(_data))
		}
		setParamMap(data.paramMap)
		// isLocal
		// 	? dispatch(contractsThunks.createLocalSocialLeave(data))
		// 	: dispatch(contractsThunks.createSocialLeave(data))
	}, [])

	return (
		<ModalWS show={true} windowClassName={styles.modalWS}>
			<ModalWSBackButton onClick={onClose} />
			<div className={`scroll`}>
				<Title className={styles.title}>
					{socialLeave
						? t('social_leave.edit_title', {
								contractNumber: contract?.contractNumber,
								contractDate: contract?.dateFrom,
						  })
						: t('social_leave.registration_title', {
								contractNumber: contract?.contractNumber,
								registerDate: contract?.registerDate,
						  })}
				</Title>
				<SocialLeaveForm socialLeave={socialLeave} onSubmit={handleFormSubmit} onCancel={onClose} />
			</div>
		</ModalWS>
	)
}
export default CreateEditSocialLeaveModal
