import React, { useRef, useMemo } from 'react'
import styles from './Input.module.scss'

export const Input = ({
	hideNumberArrows,
	onChange,
	type,
	min,
	max,
	classes = '',
	value,
	enablecheckfloat = false,
	...props
}) => {
	const inputRef = useRef(null)

	const onChangeValue = (event) => {
		let value = event.currentTarget.value
		let caretPosition = inputRef.current.selectionStart

		if (type === 'number' && enablecheckfloat) {
			value = value
				.replace(',', '.')
				.replace(/[^\d\.]/g, '')
				.replace(/\./, 'x')
				.replace(/\./g, '')
				.replace(/x/, '.')

			if (value.startsWith('.')) {
				const arr = value.split()
				value = ['0', ...arr].join('')
			}
			if (value[0] == 0 && value[1] == 0) {
				value = '0'
			}
			if (value[0] == 0 && value[1] && value[1] !== '.') {
				let arr = value.split('')
				arr.splice(0, 1)
				value = ['0', '.', ...arr].join('')
			}
			const formattedValue = Number(value)
			if (formattedValue < min) {
				onChange(min)
				return
			}
			if (formattedValue > max) {
				onChange(max)
				return
			}
		}

		onChange(value)
		// восстанавливаем позицию курсора после изменения value
		if (type !== 'number') {
			window.requestAnimationFrame(() => {
				inputRef.current.selectionStart = caretPosition
				inputRef.current.selectionEnd = caretPosition
			})
		}
	}

	let inputType = ''
	if (!enablecheckfloat) {
		inputType = type
	} else {
		inputType = type === 'number' ? undefined : type
	}

	const isForSelect = useMemo(() => type === 'select', [type])

	return (
		<input
			ref={inputRef}
			className={`${styles.input} ${hideNumberArrows ? styles.hideNumberArrows : ''} ${
				isForSelect ? styles.select : ''
			} ${classes}`}
			type={inputType}
			onChange={onChangeValue}
			value={value || ''}
			{...props}
		/>
	)
}
