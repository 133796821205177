import React from 'react'

export const Warning = () => (
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3999_27705)">
			<path
				d="M24.0289 17.5663L16.158 2.58981C15.4416 1.22667 14.0399 0.379883 12.5 0.379883C10.96 0.379883 9.55843 1.22667 8.84196 2.58981L0.971172 17.5663C0.302063 18.8396 0.348465 20.395 1.09211 21.6253C1.83559 22.8563 3.19096 23.6211 4.62919 23.6211H20.3709C21.8089 23.6211 23.1643 22.8564 23.9078 21.6256C24.6515 20.395 24.6979 18.8396 24.0289 17.5663ZM21.8499 20.3823C21.5389 20.8971 20.9721 21.2169 20.3708 21.2169H4.62911C4.02764 21.2169 3.46095 20.8971 3.14992 20.3821C2.83897 19.8676 2.81966 19.2173 3.09943 18.6849L10.9703 3.7082C11.2698 3.13822 11.856 2.78407 12.5 2.78407C13.1439 2.78407 13.7302 3.13814 14.0297 3.7082L21.9005 18.6849C22.1803 19.2173 22.161 19.8676 21.8499 20.3823Z"
				fill="#17B67C"
			/>
			<path d="M12.0433 16.1432H12.9563L13.8471 6.99609H11.1523L12.0433 16.1432Z" fill="#17B67C" />
			<path
				d="M12.4997 16.9922C11.78 16.9922 11.1973 17.575 11.1973 18.2947C11.1973 19.0136 11.78 19.5971 12.4997 19.5971C13.2193 19.5971 13.8021 19.0136 13.8021 18.2947C13.8021 17.5751 13.2193 16.9922 12.4997 16.9922Z"
				fill="#17B67C"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3999_27705">
				<rect width="24" height="24" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
)
