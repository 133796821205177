import React from 'react'

export const CrossRounded = ({ className, fill = 'none' }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22.5C6.201 22.5 1.5 17.799 1.5 12C1.5 6.201 6.201 1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12C22.5 17.799 17.799 22.5 12 22.5Z"
				fill="#EB5757"
			/>
			<path
				d="M16.7188 6.2168L12.0013 10.9418L7.28375 6.2168L6.21875 7.2818L10.9438 11.9993L6.21875 16.7168L7.28375 17.7818L12.0013 13.0568L16.7188 17.7818L17.7838 16.7168L13.0588 11.9993L17.7838 7.2818L16.7188 6.2168Z"
				fill="#EB5757"
			/>
		</svg>
	)
}
