import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import block1 from '../../../app/images/employer_ico_1.svg'
import block2 from '../../../app/images/employer_ico_2.svg'
import block3 from '../../../app/images/employer_ico_3.svg'
import block4 from '../../../app/images/employer_ico_4.svg'
import block5 from '../../../app/images/employer_ico_5.svg'
import GreenRectangle from '../../../app/images/employerRectangle.png'
import GreenLine from '../../../app/images/employerLine.svg'
import GreenRectangleRight from '../../../app/images/employerRectangleLeft.svg'

const array = [
	{
		icon: block1,
		title: 'register_employment_contracts_online',
		body: 'search_qualified_workers',
	},
	{
		icon: block2,
		title: 'form_store_personal',
		body: 'manage_entire_package',
	},
	{
		icon: block3,
		title: 'use_template_contracts',
		body: 'types_employment_contracts_agreements',
	},
	{
		icon: block4,
		title: 'access_single_employee_profile',
		body: 'informed_experience_employment_employee',
	},
	{
		icon: block5,
		title: 'work_verified_documents',
		body: 'save_time_processing',
	},
]
const settings = {
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipeToSlide: true,
	responsive: [
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
}
const Employer = () => {
	const { t } = useTranslation()
	return (
		<div className="employer" id="employer">
			<div className="green-block">
				<img src={GreenRectangle} />
			</div>
			<div className="container">
				<div className="home">
					<div className="green-line">
						<img src={GreenLine} />
					</div>
					<div className="home-block_1">
						<h2 className="title">{t('for_employer')}</h2>
						<p>{t('source_portal')}</p>
						<div className="home-block_1-item">
							<img src={block1} />
							<div className="home-block_1-item-frame">
								<p>{t('register_employment_contracts_online')}</p>
								<span>{t('search_qualified_workers')}</span>
							</div>
						</div>
						<div className="home-block_2-item">
							<img src={block4} />
							<div className="home-block_1-item-frame">
								<p>{t('access_single_employee_profile')}</p>
								<span>{t('informed_experience_employment_employee')}</span>
							</div>
						</div>
					</div>
					<div className="home-block_2">
						<div className="home-block_1">
							<div className="home-block_1-item">
								<img src={block2} />
								<div className="home-block_1-item-frame">
									<p>{t('form_store_personal')}</p>
									<span>{t('manage_entire_package')}</span>
								</div>
							</div>
							<div className="home-block_1-item">
								<img src={block3} />
								<div className="home-block_1-item-frame">
									<p>{t('use_template_contracts')}</p>
									<span>{t('types_employment_contracts_agreements')}</span>
								</div>
							</div>
							<div className="home-block_2-item">
								<img src={block5} />
								<div className="home-block_1-item-frame">
									<p>{t('work_verified_documents')}</p>
									<span>{t('save_time_processing')}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="home-block_3">
						<div className="home-block_1">
							<div className="home-block_1-item">
								<img src={block4} />
								<div className="home-block_1-item-frame">
									<p>{t('access_single_employee_profile')}</p>
									<span>{t('informed_experience_employment_employee')}</span>
								</div>
							</div>
							<div className="home-block_1-item">
								<img src={block5} />
								<div className="home-block_1-item-frame">
									<p>{t('work_verified_documents')}</p>
									<span>{t('save_time_processing')}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="home-block_column">
						<h2 className="title">{t('for_employer')}</h2>
						<p>{t('source_portal')}</p>
						<div className="home-slider">
							<Slider {...settings}>
								{array.map((arr, index) => {
									return (
										<div key={arr.title} className="home-slider-block">
											<div className="home-block_1-item">
												<img src={arr.icon} />
												<div className="home-block_1-item-frame">
													<p>{t(arr.title)}</p>
													<span>{t(arr.body)}</span>
												</div>
											</div>
										</div>
									)
								})}
							</Slider>
						</div>
					</div>
				</div>
			</div>
			<div className="green-rectangle-block">
				<img src={GreenRectangleRight} />
			</div>
		</div>
	)
}

export default Employer
