import { getCurrentLanguage } from '@/i18next'
import { formatDate } from '@/utils'

export const getColumns = (t, data) => {
	const { surname, name, patronymic, iin, birthDate, gender, nationality, citizenship } = data || {}
	const lang = getCurrentLanguage()
	const langOption = lang === 'rus' ? 'nameRu' : 'nameKz'
	return [
		{ title: t('fio'), data: `${surname} ${name} ${patronymic}` },
		{
			title: t('yin'),
			data: iin,
		},
		{
			title: t('personal_affairs.card.info.birthday'),
			data: birthDate ? formatDate(birthDate) : '-',
		},
		{
			title: t('personal_affairs.card.info.gender'),
			data: gender?.[langOption],
		},
		{
			title: t('personal_affairs.card.info.nationality'),
			data: nationality?.[langOption],
		},
		{
			title: t('personal_affairs.card.info.citizenship'),
			data: citizenship?.[langOption],
		},
	]
}
