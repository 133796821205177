import React, { useCallback, useEffect, useState } from 'react'
import { RegistrationCollectiveContract } from '@features/contracts/registrationCollectiveContract'
import { useDispatch, useSelector } from 'react-redux'
import { colContractsSelectors, colContractsThunks } from '@/entity/collectiveContracts/model'
import CollectiveContractSearch from '@features/contracts/search/collectiveContractsSearch'
import { HrPagination } from '@/components/Pagination'
import CollectiveContractTable from '@features/contracts/table/CollectiveContractTable'
import { View } from '@features/contracts/registrationCollectiveContract/view'
import { Loader } from '@/components/Loader'

const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const initialFilterState = {
	srcText: '',
}

export const CollectiveContracts = ({ isMobile, activeTab }) => {
	const dispatch = useDispatch()
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [filters, setFilters] = useState(initialFilterState)
	const [isView, openView] = useState(false)
	const [isEdit, openEdit] = useState(false)
	const contractsData = useSelector(colContractsSelectors.colContracts)
	const contract = useSelector(colContractsSelectors.colContract)
	const isLoading = useSelector(colContractsSelectors.isLoading)
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		(_filters = filters, _queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(colContractsThunks.getColContracts(page, pageSize, _filters.srcText)).then(() => {})
		},
		[activeTab, queryParams, filters]
	)
	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])
	useEffect(() => {
		dispatch(colContractsThunks.getOptions())
	}, [])
	const Close = () => {
		openView(false)
	}
	return (
		<>
			<RegistrationCollectiveContract
				fetchData={fetchData}
				isEdit={isEdit}
				openEdit={openEdit}
			></RegistrationCollectiveContract>
			<CollectiveContractSearch filters={filters} setFilters={setFilters} fetchData={fetchData} />
			{contractsData ? (
				<CollectiveContractTable
					data={contractsData?.colContracts}
					view={openView}
					edit={openEdit}
				></CollectiveContractTable>
			) : null}
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={
					isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
				}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={contractsData.numberOfElements}
				totalElements={contractsData.totalElements}
				totalPages={contractsData.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
			{isView ? <View contract={contract} onClose={Close}></View> : null}
			{isLoading ? <Loader /> : null}
		</>
	)
}
