import { Button, ModalTemplate } from '@/ui'
import { UnionsData } from '@features/profUnions/info-table/UnionsData'
import { Docs } from '@features/profUnions/info-table/Docs'
import { Composition } from '@features/profUnions/info-table/Composition'
import React from 'react'
import styles from './View.module.scss'
import { useTranslation } from 'react-i18next'
import { getFromLocalStorage } from '@/shared'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks } from '@/entity'
import { LoadingBlocker } from '@/components/Loader'
import { isNull } from 'lodash'

export const View = ({ onClose, onToggleAction, tradeUnion, myUnion = false, myTradeUnion }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeRole = getFromLocalStorage('activeRole')
	const isLoading = useSelector(profUnionsSelectors.isLoading)

	const addSelfTradeUnion = (externalId) => {
		const today = new Date()
		const day = today.getDate().toString().padStart(2, '0')
		const month = (today.getMonth() + 1).toString().padStart(2, '0')
		const dateIn = `${day}.${month}.${today.getFullYear()}`
		const person = { dateIn: dateIn }
		dispatch(profUnionsThunks.addSelf(externalId, person))
	}

	const approveSelfTradeUnion = (externalId) => {
		const today = new Date()
		const day = today.getDate().toString().padStart(2, '0')
		const month = (today.getMonth() + 1).toString().padStart(2, '0')
		const dateIn = `${day}.${month}.${today.getFullYear()}`
		const person = { dateIn: dateIn }
		dispatch(profUnionsThunks.approveSelf(externalId, person))
	}

	const rejectSelfTradeUnion = (externalId) => {
		const today = new Date()
		const day = today.getDate().toString().padStart(2, '0')
		const month = (today.getMonth() + 1).toString().padStart(2, '0')
		const dateIn = `${day}.${month}.${today.getFullYear()}`
		const person = { dateIn: dateIn }
		dispatch(profUnionsThunks.rejectSelf(externalId, person))
	}

	return (
		<>
			<ModalTemplate onClose={onClose}>
				<div className={`scroll ${styles.wrapper}`}>
					<UnionsData tradeUnion={tradeUnion} onToggleAction={onToggleAction} myUnion={myUnion} isEmployee={'ROLE_EMPLOYEE' === activeRole}></UnionsData>
					<Docs tradeUnion={tradeUnion} onToggleAction={onToggleAction} myUnion={myUnion}></Docs>
					<Composition isTradeUnionsItem tradeUnion={tradeUnion} onToggleAction={onToggleAction} myUnion={myUnion}></Composition>
					<div className={styles.buttonEnd}>
						{myUnion ? (
							<>
								{
									isNull(myTradeUnion.approvedPerson) && isNull(myTradeUnion.initPerson) &&
									<div className={styles.mr}>
										<Button onClick={() => addSelfTradeUnion(tradeUnion.externalId)}>{t('actions.join')}</Button>
									</div>
								}
								{
									(!isNull(myTradeUnion.approvedPerson) && !isNull(myTradeUnion.initPerson) && !myTradeUnion.approvedPerson && !myTradeUnion.initPerson) &&
									<>
										<div className={styles.mr}>
											<Button onClick={() => approveSelfTradeUnion(tradeUnion.externalId)}>{t('actions.approve')}</Button>
										</div>
										<div className={styles.mr}>
											<Button onClick={() => rejectSelfTradeUnion(tradeUnion.externalId)}>{t('actions.reject')}</Button>
										</div>
									</>
								}
							</>
						) : null}
						<Button onClick={onClose}>{t('close')}</Button>
					</div>
				</div>
			</ModalTemplate>
			{isLoading ? <LoadingBlocker /> : null}
		</>
	)
}
