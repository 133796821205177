import React, { useState } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { CollectiveForm } from './CollectiveForm'
import { ColContractValidationSchema } from '@/entity/collectiveContracts'
import { Formik } from 'formik'

export const CollectiveContractForm = ({ isTitle, initialValues, onSubmit, options, onCancel, isEdit }) => {
	const { t } = useTranslation()
	const [filesMiddleWare, setFilesMiddleWare] = useState([])
	const { files } = initialValues

	const onSubmitMiddleWare = (data) => {
		let sendFileArray = []
		if (filesMiddleWare.length > 0) {
			sendFileArray = filesMiddleWare.map((file) => ({
				id: file.id,
			}))
		}
		// Включение массива файлов в объект данных
		let preparedData = { ...data, files: sendFileArray || [] }
		// Вызов onSubmit с подготовленными данными
		onSubmit(preparedData)
	}
	const attachHarmfulConditionsFiles = (values) => {
		setFilesMiddleWare(values)
	}

	return (
		<div>
			{isTitle ? (
				<Title color={'black'}>
					{isEdit
						? t('contracts_tab.edit_collective_contract')
						: t('contracts_tab.registration_new_collective_contract')}
				</Title>
			) : null}
			<Formik
				validationSchema={ColContractValidationSchema}
				initialValues={initialValues}
				onSubmit={onSubmitMiddleWare}
			>
				<CollectiveForm
					options={options}
					onCancel={onCancel}
					attachHarmfulConditionsFiles={attachHarmfulConditionsFiles}
					files={files}
				></CollectiveForm>
			</Formik>
		</div>
	)
}
