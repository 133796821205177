import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './Table/Table'
import styles from './AffairsTab.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { affairsThunks } from '@/entity/personalAffairs'
import { headCells } from './utils'
import DetailedAffair from '@pages/PersonalAffairs/components/DetailedAffair/DetailedAffair'
import { downloadEmployeePdf } from '@/shared/api/rest/personalAffairs'

const AffairsTab = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isDetailedModalOpen, setDetailedModalOpen] = useState(false)
	const [selectedMethod, setSelectedMethod] = useState(null)
	const card = useSelector((state) => state.affairs.employeeCard)
	const data = {
		...card,
		methodInfoData: card?.methodInfoData.filter(({ method }) => method.code === selectedMethod),
	}

	useEffect(() => dispatch(affairsThunks.getEmployeeCard()), [dispatch])

	const downloadPDF = async (method = '') => {
		const res = await downloadEmployeePdf(method)
		const file = new Blob([res], { type: 'application/pdf' })
		const fileURL = URL.createObjectURL(file)
		window.open(fileURL)
	}

	const onCloseDetailed = () => {
		setSelectedMethod(null)
		setDetailedModalOpen(false)
	}

	return (
		<div className={styles.wrapper}>
			{(selectedMethod || isDetailedModalOpen) && (
				<DetailedAffair
					data={selectedMethod ? data : card}
					isAllData={!Boolean(selectedMethod)}
					onClose={onCloseDetailed}
					selectedMethod={isDetailedModalOpen ? null : selectedMethod}
					downloadPDF={downloadPDF}
				/>
			)}
			<Button
				buttonClassName={styles.wrapper__button}
				icon={addWhiteIcon}
				onClick={() => setDetailedModalOpen(true)}
				disabled={!card}
			>
				{t('employee_affairs.tabs.first.action')}
			</Button>
			<Table headCells={headCells} setSelectedMethod={setSelectedMethod} downloadPDF={downloadPDF} />
		</div>
	)
}
export default AffairsTab
