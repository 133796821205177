import { useTranslation } from 'react-i18next'
import styles from './Question.module.scss'
import { ConfirmModal, Title } from '@/ui'

export const QuestionRevoke = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			processButtonTitle={'labor_records.sign_and_send'}
			cancelButtonTitle={'no_cancel'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{t('labor_records.title_send_revoke')}</Title>
				<p>{t('labor_records.body_sign_revoke')}</p>
			</div>
		</ConfirmModal>
	)
}
