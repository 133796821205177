import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field as FormikField, Form as FormikForm, connect } from 'formik'
import { Title, Field, Button, UploadImageWithLabel, Error, ConfirmModal } from '@/ui'
import { cookies, parseECPKeyInformation } from '@/shared'
import { CompanyAccountData } from '@features/employerProfile'
import { uploadFile } from '@/shared/api/rest/main/uploadFile'
import { findOrg } from '@/shared/api/rest/main/findOrg'
import { RefreshIcon } from '@/app/icons'
import { formFieldNames } from './company.fieldnames'
import { ValidationSchema } from './company.schema'
import styles from './CompanyForm.module.scss'
import stylesAddCompany from './AddCompany.module.scss'
import { message } from 'antd'
import { authSelectors, authThunks } from '@app/store/auth'
import { useDispatch, useSelector } from 'react-redux'
import { LocalityField } from '@features/contracts/registrationEmployerContract/form/fields'
import { getCurrentLanguage } from '@/i18next'
import { LoadingBlocker } from '@/components/Loader'
import InputMask from 'react-input-mask'
import Input from '@mui/material/Input'

const kzCodes = [
	'700',
	'701',
	'702',
	'703',
	'704',
	'705',
	'706',
	'707',
	'708',
	'709',
	'747',
	'750',
	'751',
	'760',
	'761',
	'762',
	'763',
	'764',
	'771',
	'775',
	'776',
	'777',
	'778',
]

const validatePhone = (value = '') => {
	const operatorCode = value.slice(4, 7)

	return (
		kzCodes.includes(operatorCode) &&
		value
			.replace(/_/g, '')
			.replace(/-/g, '')
			.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
			.replace(/\s/g, '').length == 11
	)
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const { ...other } = props
	return (
		<InputMask
			{...other}
			mask="+7 (999) 999-99-99"
			definitions={{
				'#': /[0-9]/,
			}}
			inputRef={ref}
		/>
	)
})

const DistrictRegionFields = connect(
	({
		formik: { setFieldValue, values },
		okeds,
		ownerships,
		districts,
		regions,
		onGetHandBookOptions,
		isFormSubmitted,
	}) => {
		const { t } = useTranslation()
		return (
			<div className={`${styles.field} ${styles.location}`}>
				<FormikField name={formFieldNames.dokedCode}>
					{({ field, meta: { touched, error } }) => {
						return (
							<Field
								options={okeds}
								type="text"
								fieldType="selectSearchApi"
								isRequired
								placeholder={t('filled_automate')}
								hint={t('hint_oked_code')}
								label={t('activity_type')}
								apiHandler={() => {
									return { content: okeds ?? [] }
								}}
								labelClassName={styles.formLabel}
								{...field}
								onChange={(data) => {
									setFieldValue(field.name, data)
								}}
								error={(touched || isFormSubmitted) && error}
							/>
						)
					}}
				</FormikField>
				<FormikField name={formFieldNames.downershipTypeCode}>
					{({ field, meta: { touched, error } }) => {
						return (
							<Field
								labelClassName={styles.formLabel}
								type="text"
								fieldType="select"
								options={ownerships}
								label={t('ownership_type')}
								isRequired
								placeholder={t('choose_from_handbook')}
								error={(touched || isFormSubmitted) && error}
								{...field}
								onChange={(data) => {
									setFieldValue(field.name, data)
								}}
							/>
						)
					}}
				</FormikField>
				<FormikField name={formFieldNames.ddistrictCode}>
					{({ field, meta: { touched, error } }) => {
						useEffect(() => onGetHandBookOptions('district', field.value), [field.value])
						return (
							<Field
								labelClassName={styles.formLabel}
								type="text"
								fieldType="select"
								options={districts}
								label={t('region')}
								isRequired
								placeholder={t('choose_from_handbook')}
								{...field}
								onChange={(data) => {
									setFieldValue(field.name, data)
									setFieldValue(formFieldNames.dregionCode, '')
									setFieldValue(formFieldNames.dlocalityCode, '')
								}}
								error={(touched || isFormSubmitted) && error}
							/>
						)
					}}
				</FormikField>
				<FormikField name={formFieldNames.dregionCode}>
					{({ field, meta: { touched, error } }) => {
						useEffect(() => onGetHandBookOptions('region', field.value), [field.value])
						return (
							<Field
								labelClassName={styles.formLabel}
								type="text"
								fieldType="select"
								options={regions}
								label={t('district')}
								isRequired
								placeholder={t('choose_from_handbook')}
								error={(touched || isFormSubmitted) && error}
								{...field}
								onChange={(data) => {
									setFieldValue(field.name, data)
									setFieldValue(formFieldNames.dlocalityCode, '')
								}}
							/>
						)
					}}
				</FormikField>
			</div>
		)
	}
)

export const CompanyForm = ({
	initialValues,
	options = {},
	onSubmit,
	onCancel,
	isTitle,
	onChooseECP,
	ecpInformation,
	isCreateMode,
	onGetHandBookOptions,
}) => {
	const formRef = useRef(null)
	const { t } = useTranslation()
	const [modalData, setModalData] = useState(null)
	const [isFormSubmitted, setFormSubmitted] = useState(false)
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const { ownerships, regions, districts, populatedAreas, okeds } = options
	const [stringAddress, setStringAddress] = useState(null)
	const [okedCodeDic, setOkedCodeDic] = useState(null)
	const [ownershipsCode, setOwnershipsCode] = useState(null)
	const { setFieldValue } = useMemo(() => formRef.current || {}, [formRef?.current])
	const [redirected, setRedirected] = useState(false)
	const isLoading = useSelector(authSelectors.isLoading)

	const onChangePhoto = useCallback(async (file) => {
		try {
			const formData = new FormData()
			formData.set('file', file)
			const { id } = await uploadFile(formData)
			const form = formRef.current
			form.setFieldValue(formFieldNames.photoId, id)
		} catch (error) {
			console.error('[error uploading file] ', error)
		}
	}, [])

	const getInfo = useCallback(async () => {
		if (ecpInformation) {
			const form = formRef.current
			const parsedData = parseECPKeyInformation(ecpInformation)
			if (form) {
				let errorMessage
				const { bin, iin } = parsedData
				let response = {}
				if (iin) response = await findOrg(iin)
				if (bin) response = await findOrg(bin)
				const { resultCode, ulDataDTO } = response
				if (isCreateMode) {
					if (resultCode === 'FOUND_CURRENT_USER') message.error(t('employeeProfile.alreadyRegisteredOnMe'))
					if (resultCode === 'NOT_FOUND') {
						getContractForm(form, parsedData, ulDataDTO)
					}
					if (resultCode === 'FOUND_OTHER_USER' || resultCode === 'FOUND_NO_USERS')
						setModalData({
							title: t('employeeProfile.alreadyRegisteredOnPortal.title'),
							description: t('employeeProfile.alreadyRegisteredOnPortal.description'),
							action: t('employeeProfile.alreadyRegisteredOnPortal.action'),
						})
				} else {
					if (form?.values?.bin === bin || form?.values?.bin === iin) {
						getContractForm(form, parsedData, ulDataDTO)
					} else errorMessage = t('not_equal_bin')
					!errorMessage && message.success(t('success_alert'))
				}
				form.setFieldError('bin', errorMessage)
			}
		}
	}, [ecpInformation, isCreateMode, onGetHandBookOptions, t])

	const getAddressFromString = (ulDataDTO) => {
		if (!ulDataDTO.kato) return
		const address = ulDataDTO[lang === 'rus' ? 'addressNameRu' : 'addressNameKz'].split(',').map((item) => item.trim())
		setStringAddress(address)
		const trimDistrictAdr = address[1].replace(/\s+/g, '')
		const district = districts.find((district) =>
			district[lang === 'rus' ? 'rus' : 'kz']?.toLowerCase().includes(trimDistrictAdr.toLowerCase())
		)
		let devIndex = address.length - 5
		if (devIndex < 0) devIndex = 0
		setFieldValue(formFieldNames.street, address[address.length - (2 + devIndex)])
		setFieldValue(formFieldNames.house, address[address.length - (1 + devIndex)])
		if (!district) return
		setFieldValue(formFieldNames.ddistrictCode, district.code)
	}

	const getContractForm = (form, parsedData, ulDataDTO) => {
		form.setValues({
			...form.values,
			[formFieldNames.bin]: parsedData.bin || parsedData.iin,
			[formFieldNames.regActionDate]: parsedData.regActionDate || '',
			[formFieldNames.companyName]: ulDataDTO.fullNameRu || '',
			[formFieldNames.certificateExpiresAt]: `${parsedData.dateFrom}-${parsedData.dateTo}`,
			[formFieldNames.lastname]: parsedData.surname || '',
			[formFieldNames.firstname]: parsedData.firstname || '',
			[formFieldNames.middlename]: parsedData.middlename || '',
			[formFieldNames.companyNameKaz]: ulDataDTO.fullNameKz || ulDataDTO.fullNameRu,
		})
		getAddressFromString(ulDataDTO)
		onGetHandBookOptions('bin', parsedData.bin || parsedData.iin)
		if (ulDataDTO.opfCode) {
			setOwnershipsCode(ulDataDTO.opfCode)
		}
		if (!ulDataDTO.okedCode) return
		setOkedCodeDic(ulDataDTO.okedCode)
	}

	useEffect(() => {
		if (!ownerships || !ownershipsCode) return
		const ownership = ownerships.find((item) => item['code'] === ownershipsCode)
		if (!ownership) return
		setFieldValue(formFieldNames.downershipTypeCode, ownership.code)
	}, [ownerships, ownershipsCode])

	useEffect(() => {
		if (!okeds || !okedCodeDic) return
		const oked = okeds.find((item) => item['code'] === okedCodeDic)
		if (!oked) return
		setFieldValue(formFieldNames.dokedCode, oked.code)
	}, [lang, okedCodeDic, okeds])

	useEffect(() => {
		if (!regions || !stringAddress) return
		const region = regions.find((region) =>
			region[lang === 'rus' ? 'rus' : 'kz']?.toLowerCase().includes(stringAddress[2].toLowerCase())
		)
		if (!region) return
		setFieldValue(formFieldNames.dregionCode, region.code)
	}, [lang, regions, setFieldValue, stringAddress])

	useEffect(() => {
		if (!populatedAreas || !stringAddress) return
		const area = populatedAreas.find((area) =>
			area[lang === 'rus' ? 'rus' : 'kz']?.toLowerCase().includes(stringAddress[3].toLowerCase())
		)
		if (!area) return
		setFieldValue(formFieldNames.dlocalityCode, area.code)
	}, [populatedAreas])

	const handleModalCancelClick = useCallback(() => setModalData(null), [])
	const onMove = () => {
		window.open(process.env.REACT_APP_REDIRECT_ADD_COMPANY)
		setRedirected(true)
	}
	const updateCompaniesList = () => {
		const { key: name, value } = cookies.get(process.env.REACT_APP_PASSPORT, true)
		if (!name) return
		dispatch(authThunks.getCurrentUser({ data: { name, value } }, { forceRefresh: true }))
	}

	useEffect(() => getInfo(), [ecpInformation])
	useEffect(() => redirected && !isLoading && onCancel(), [isLoading])

	const [mobilePhoneError, setMobilePhone] = useState('')
	const [officePhoneEqualError, setOfficePhoneEqualError] = useState('')

	const submitMiddleWare = (data) => {
		setMobilePhone('')
		setOfficePhoneEqualError('')
		const isValidMobilePhone = data.mobilePhone?.length > 0 ? validatePhone(data.mobilePhone) : true

		const officePhoneMobilePhoneNotEqual = data.officePhone !== data.mobilePhone

		if (isValidMobilePhone && officePhoneMobilePhoneNotEqual) {
			onSubmit(data)
		} else {
			if (!isValidMobilePhone) {
				setMobilePhone(t('phone_error'))
			}
			if (!officePhoneMobilePhoneNotEqual) {
				setOfficePhoneEqualError(t('phone_error_equal'))
			}
		}
	}

	return (
		<>
			{isLoading && <LoadingBlocker />}
			{modalData && (
				<ConfirmModal
					onProcess={redirected ? updateCompaniesList : onMove}
					onCancel={handleModalCancelClick}
					cancelButtonTitle={'no_cancel'}
					processButtonTitle={redirected ? 'update_companies_list' : modalData?.action}
					hideBackButton
					priority={2}
				>
					<Title>{modalData?.title}</Title>
					<p>{modalData?.description}</p>
				</ConfirmModal>
			)}
			<div className={`${isTitle ? styles.wrapper : ''}`}>
				{isTitle && <Title color="black">{t('company_titles.add_new_company')}</Title>}
				<Formik
					className={styles.form}
					initialValues={initialValues}
					validationSchema={ValidationSchema}
					onSubmit={submitMiddleWare}
					innerRef={formRef}
					enableReinitialize
				>
					{({ resetForm, setFieldValue, ...form }) => (
						<FormikForm className={styles.form}>
							<div className={styles.firstRow}>
								<div className="flex-1">
									<div className={styles.accountDetails}>
										<Title className="mb-0">{t('account_details')}</Title>
										<Button onClick={onChooseECP} buttonClassName={styles.ecpButton}>
											{isCreateMode ? (
												t('choose_ecp')
											) : (
												<>
													<RefreshIcon className={styles.refreshIcon} />
													{t('update_ecp')}
												</>
											)}
										</Button>
										<Error>
											{form?.errors?.bin?.key ? t(`validation.${form?.errors?.bin?.key}`) : form?.errors?.bin}
										</Error>
										<CompanyAccountData
											hideTitle
											hideSubTitle
											company={{
												bin: form.values?.bin,
												companyName: form.values?.companyName,
												certificateExpiresAt: form.values?.certificateExpiresAt,
											}}
										/>
									</div>
								</div>

								<div className="flex-1">
									<div className={styles.uploading}>
										<UploadImageWithLabel
											label={`${t('logo')}:`}
											onChange={onChangePhoto}
											initialValue={initialValues?.photoId}
										/>
									</div>
								</div>
							</div>
							<div className={styles.grid}>
								<div className={styles.column}>
									<Title className="mb-0">{t('information_about_company')}</Title>
									<div className={styles.fields}>
										<DistrictRegionFields
											okeds={okeds}
											ownerships={ownerships}
											districts={districts}
											regions={regions}
											onGetHandBookOptions={onGetHandBookOptions}
											isFormSubmitted={isFormSubmitted}
										/>
										<LocalityField options={populatedAreas} isFormDirty={isFormSubmitted} validateCountry={false} />{' '}
										<div className={`${styles.field} ${styles.address}`}>
											<FormikField name={formFieldNames.street}>
												{({ field, meta: { touched, error } }) => (
													<Field
														labelClassName={styles.formLabel}
														type="text"
														label={t('address')}
														isRequired
														placeholder={t('enter_address')}
														{...field}
														error={(touched || isFormSubmitted) && error}
													/>
												)}
											</FormikField>
											<div className={styles.house}>
												<FormikField name={formFieldNames.house}>
													{({ field }) => (
														<Field labelClassName={styles.formLabel} type="text" label={t('house')} {...field} />
													)}
												</FormikField>{' '}
												<FormikField name={formFieldNames.office}>
													{({ field }) => (
														<Field labelClassName={styles.formLabel} type="text" label={t('office')} {...field} />
													)}
												</FormikField>
											</div>
										</div>
										<div className={`${styles.field} ${styles.website}`}>
											<FormikField name={formFieldNames.website}>
												{({ field }) => (
													<Field
														labelClassName={styles.formLabel}
														type="text"
														label={t('website')}
														placeholder={t('enter_website')}
														{...field}
													/>
												)}
											</FormikField>
											<FormikField name={formFieldNames.employeeCnt}>
												{({ field, meta: { touched, error } }) => (
													<Field
														labelClassName={`${styles.formLabel} ${styles.formLabel_noMargin}`}
														type="text"
														label={`${t('worker_count')} *`}
														placeholder={t('worker_count')}
														{...field}
														error={(touched || isFormSubmitted) && error}
													/>
												)}
											</FormikField>
										</div>
									</div>
								</div>
								<div className={styles.column}>
									<Title className="mb-0">{t('contract_information')}</Title>
									<div className={styles.fields}>
										<FormikField name={formFieldNames.lastname}>
											{({ field, meta: { touched, error } }) => (
												<Field
													labelClassName={styles.formLabel}
													type="text"
													label={t('lastname')}
													isRequired
													placeholder={t('lastname')}
													{...field}
													error={(touched || isFormSubmitted) && error}
												/>
											)}
										</FormikField>{' '}
										<FormikField name={formFieldNames.firstname}>
											{({ field }) => (
												<Field
													labelClassName={styles.formLabel}
													type="text"
													label={t('firstname')}
													placeholder={t('firstname')}
													{...field}
												/>
											)}
										</FormikField>{' '}
										<FormikField name={formFieldNames.middlename}>
											{({ field }) => (
												<Field
													labelClassName={styles.formLabel}
													type="text"
													label={t('middlename')}
													placeholder={t('middlename')}
													{...field}
												/>
											)}
										</FormikField>{' '}
										<FormikField name={formFieldNames.email}>
											{({ field, meta: { touched, error } }) => (
												<Field
													labelClassName={styles.formLabel}
													type="text"
													label={t('electronic_mail')}
													readOnly={!isTitle}
													isRequired
													placeholder={t('email')}
													{...field}
													error={(touched || isFormSubmitted) && error}
												/>
											)}
										</FormikField>{' '}
										<div className={`${styles.field} ${styles.phones}`}>
											<div>
												<div className={stylesAddCompany.phone__field__label}>{t('officePhone')} *</div>
												<FormikField name={formFieldNames.officePhone}>
													{({ field, meta: { touched, error } }) => (
														<>
															<Input
																classes={{ root: stylesAddCompany.phone__field }}
																fullWidth
																{...field}
																inputComponent={TextMaskCustom}
																placeholder={t('officePhone')}
															/>
															<div className={stylesAddCompany.error}>
																{(touched && t(error?.key)) || officePhoneEqualError}
															</div>
														</>
													)}
												</FormikField>{' '}
											</div>
											<FormikField name={formFieldNames.officePhoneInternal}>
												{({ field }) => (
													<Field
														labelClassName={styles.formLabel}
														type="text"
														label={t('internal')}
														placeholder={t('internal')}
														{...field}
													/>
												)}
											</FormikField>{' '}
										</div>
										<div>
											<div className={stylesAddCompany.phone__field__label}>{t('mobilePhone')}</div>
											<FormikField name={formFieldNames.mobilePhone}>
												{({ field, meta: { touched, error } }) => {
													return (
														<>
															<Input
																classes={{ root: stylesAddCompany.phone__field }}
																fullWidth
																{...field}
																inputComponent={TextMaskCustom}
																placeholder={t('mobilePhone')}
															/>
															<div className={stylesAddCompany.error}>
																{(touched && t(error?.key)) || officePhoneEqualError || mobilePhoneError}
															</div>
														</>
													)
												}}
											</FormikField>{' '}
										</div>
									</div>
								</div>
								<div className={styles.textarea}>
									<FormikField name={formFieldNames.about}>
										{({ field }) => (
											<Field
												labelClassName={styles.formLabel}
												type="text"
												fieldType="textarea"
												label={t('details_about_company')}
												placeholder={t('write_what_you_want')}
												{...field}
											/>
										)}
									</FormikField>
								</div>
							</div>
							<div className={styles.actions}>
								<Button type="bare" onClick={onCancel}>
									{t('cancel')}
								</Button>
								<Button type="submit" onClick={() => setFormSubmitted(true)}>
									{t('save')}
								</Button>
							</div>
						</FormikForm>
					)}
				</Formik>
			</div>
		</>
	)
}
