import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAid } from '@/shared/api/rest/personalAffairs/getAid'
import RequestList from './Statuses/RequestList'
import UpdateList from './Statuses/UpdateList'
import ReportList from './Statuses/ReportList'
import EmployeesInNeedModal from './EmployeesInNeedModal'
import { LoadingBlocker } from '@/components/Loader'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
const EmployeesInNeed = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const [filters, setFilters] = useState({})
	const [aidData, setAidData] = useState(null)
	const [statusCode, setStatusCode] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const [loading, setLoading] = useState(true)
	useEffect(async () => {
		try {
			const aid = await getAid(0, 10, activeCompany.bin)
			setAidData(aid)
			setStatusCode(aid.statusCode ?? null)
		} catch (error) {
			setAidData(null)
			setStatusCode(null)
		} finally {
			setLoading(false)
		}
	}, [])
	const renderComponent = () => {
		switch (statusCode) {
			case '001':
				return <RequestList setOpenModal={setOpenModal} />
			case '002':
				return <UpdateList setOpenModal={setOpenModal} />
			case '003':
				return (
					<ReportList
						aidData={aidData}
						setAidData={setAidData}
						setOpenModal={setOpenModal}
						filters={filters}
						setFilters={setFilters}
						setLoading={setLoading}
					/>
				)
			default:
				return null
		}
	}
	return (
		<>
			{renderComponent()}
			{openModal && <EmployeesInNeedModal onCancel={() => setOpenModal(false)} />}
			{loading && <LoadingBlocker />}
		</>
	)
}

export default EmployeesInNeed
