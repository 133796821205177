import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui/atoms/dataGridTable'
import styles from './InfoTable.module.scss'

export const EmployeeContactInfo = ({ worker }) => {
	const { t } = useTranslation()

	const contactInformation = [
		{ title: t('electronic_mail'), data: worker?.email },
		{
			title: t('officePhone'),
			data: `${worker?.officePhone || ''} ${worker?.officePhoneInternal ? `(${worker?.officePhoneInternal})` : ''}`,
		},
		{ title: t('mobilePhone'), data: worker?.mobilePhone },
	]
	return (
		<DataGridTable
			hideSubTitle
			title={'contact_information'}
			information={contactInformation}
			titleClass={styles.tableTitleCell}
		/>
	)
}
