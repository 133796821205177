import { handbookSelectors } from '@/entity/handbook/index'
import { createSelector } from 'reselect'

export const options = createSelector(
	[
		handbookSelectors.ownerships,
		handbookSelectors.countries,
		handbookSelectors.regions,
		handbookSelectors.districts,
		handbookSelectors.populatedAreas,
		handbookSelectors.okeds,
		handbookSelectors.singleOptions,
	],
	(ownerships, countries, regions, districts, populatedAreas, okeds, single) => {
		const okedsFinal = [...okeds]
		if (single?.oked && !okeds?.find((item) => item.code === single.oked?.code)) {
			okedsFinal.push(single.oked)
		}
		return {
			ownerships,
			countries,
			regions,
			districts,
			populatedAreas,
			okeds: okedsFinal,
		}
	}
)
