import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { getCurrentLanguage } from '@/i18next'
import { headCells } from './table.headings'
import styles from './Table.module.scss'

export const Table = ({ data }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.eventDate || '-'}</td>
				<td>{lang === 'rus' ? row.laborType.nameRu : row.laborType.nameKz}</td>
				<td>{row.bin}</td>
				<td>{row.orgName ?? (lang === 'rus' ? row.orgNameRu : row.orgNameKz)}</td>
				<td>{row.establishedPost}</td>
			</tr>
		),
		[lang]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : null}</tbody>
			</table>
		</>
	)
}
