import { createSelector } from 'reselect'
import { handbookSelectors } from '@/entity/handbook'

export const isLoading = (state) => state.positionEvaluations?.isLoading
export const isSuccess = (state) => state.positionEvaluations?.isSuccess
export const error = (state) => state.positionEvaluations?.error
export const evaluations = (state) => state.positionEvaluations?.evaluations ?? []
export const calculation = (state) => state.positionEvaluations?.calculation
export const generatedPdf = (state) => state.positionEvaluations?.generatedPdf
export const formInitialData = (state) => state.positionEvaluations?.formInitialData
export const options = createSelector(
	[
		handbookSelectors.calcActivityArea,
		handbookSelectors.calcOrganizationType,
		handbookSelectors.calcPositionRegister,
		handbookSelectors.calcFunctionalDuty,
		handbookSelectors.calcPositionRegisterHasDutyFunction,
		handbookSelectors.calcFunctionalBlock,
		handbookSelectors.calcDutyFunction,
		handbookSelectors.calcBlockHasCalcDuty,
		handbookSelectors.calcFactor,
		handbookSelectors.calcAssessmentCriteria,
		handbookSelectors.calcBase,
		handbookSelectors.calcGradeHaveDuty,
		handbookSelectors.calcGradeLevel,
	],
	(
		calcActivityArea,
		calcOrganizationType,
		calcPositionRegister,
		calcFunctionalDuty,
		calcPositionRegisterHasDutyFunction,
		calcFunctionalBlock,
		calcDutyFunction,
		calcBlockHasCalcDuty,
		calcFactor,
		calcAssessmentCriteria,
		calcBase,
		calcGradeHaveDuty,
		calcGradeLevel,
	) => {
		return {
			calcActivityArea,
			calcOrganizationType,
			calcPositionRegister,
			calcFunctionalDuty,
			calcPositionRegisterHasDutyFunction,
			calcFunctionalBlock,
			calcDutyFunction,
			calcBlockHasCalcDuty,
			calcFactor,
			calcAssessmentCriteria,
			calcBase,
			calcGradeHaveDuty,
			calcGradeLevel,
		}
	}
)
