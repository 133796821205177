import * as types from './types'

export const actions = {
	addOpenedChildId: (payload) => ({
		type: types.ADD_OPENED_CHILD_ID,
		payload,
	}),
	removeOpenedChildId: (payload) => ({
		type: types.REMOVE_OPENED_CHILD_ID,
		payload,
	}),
}
