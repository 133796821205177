import { Popover } from 'antd'
import Badge from '@mui/material/Badge'
import { NotificationOutlined } from '@ant-design/icons'
import { InfiniteList } from '@features/notifications/list'
import './style.css'
import { useEffect, useState } from 'react'
import { notificationsThunks } from '@/entity/notifications/'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsSelectors } from '@/entity/notifications/index'
import { userSelectors } from '@/entity'
import { companySelectors } from '@/entity/company'
import { actions } from '@/entity/notifications/actions'

export const NotifyBadge = () => {
	const dispatch = useDispatch()
	const notifyData = useSelector(notificationsSelectors.notifications)
	const [unreadCount, setUnreadCount] = useState(0)
	const [isOpen, setIsOpen] = useState(false)
	const companies = useSelector(companySelectors.companies)
	const user = useSelector(userSelectors.user)
	const activeRole = useSelector(userSelectors.activeRole)

	useEffect(() => {
		if ((companies.length && activeRole === 'ROLE_EMPLOYER') || (user?.iin?.length && activeRole === 'ROLE_EMPLOYEE')) {
			dispatch(notificationsThunks.getNotificationsBadge(activeRole === 'ROLE_EMPLOYER'))
		} else {
			dispatch(actions.resetNotificationsBadge())
		}
	}, [companies, user, activeRole])

	useEffect(() => {
		setUnreadCount(notifyData?.badgeLength)
	}, [notifyData])

	const onVisibleChange = (e) => {
		setIsOpen(e)
	}

	const handleCloseNotifyPopover = () => {
		setIsOpen(false)
	}

	const content = (
		<InfiniteList
			id="infinitelist"
			content={notifyData?.badgeContent}
			isVisible={isOpen}
			onClose={handleCloseNotifyPopover}
		/>
	)

	return (
		<Popover
			placement="bottomRight"
			overlayClassName="wrapper-notify"
			content={content}
			open={isOpen}
			trigger="click"
			onOpenChange={onVisibleChange}
		>
			<Badge
				badgeContent={unreadCount}
				sx={{
					'& .MuiBadge-badge': {
						color: 'white',
						backgroundColor: '#17b67c',
					},
				}}
			>
				<NotificationOutlined style={{ fontSize: '16px' }} />
			</Badge>
		</Popover>
	)
}
