import { ReCAPTCHA } from 'react-google-recaptcha'

export const Captcha = ({ recaptcha, size = 'normal' }) => {
	const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.
	const TEST_SITE_KEY = '6LcButIdAAAAAHjPkbXzk5O8FPXENka923zOv5Aa'

	const onChange = (value) => {
		if (value !== null) {
			recaptcha(value)
		} else {
			recaptcha('[empty]')
		}
	}

	return <ReCAPTCHA sitekey={TEST_SITE_KEY} grecaptcha={grecaptchaObject} onChange={onChange} size={size} />
}

export default Captcha
