import * as types from './types'

const initialCompany = {
	bin: '',
	companyName: '',
	certificateExpiresAt: '',
	photoId: '',
	dokedCode: '',
	downershipTypeCode: '',
	ddistrictCode: '',
	dregionCode: '',
	dlocalityCode: '',
	house: '',
	office: '',
	website: '',
	lastname: '',
	firstname: '',
	middlename: '',
	officePhone: '',
	officePhoneInternal: '',
	mobilePhone: '',
	employeeCnt: '',
	email: '',
}
const initialState = {
	isLoading: false,
	isSuccess: false,
	error: false,
	company: initialCompany,
	activeCompanyId: null,
	xmlToSign: null,
	companies: [],
	pagination: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.SET_ERROR:
			return { ...state, error: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_COMPANY:
			return {
				...state,
				company: state.companies.find((company) => payload.id === company.id) || null,
				activeCompanyId: payload.id,
			}
		case types.SET_INITIAL_COMPANY:
			return { ...state, company: { ...initialCompany, ...payload } }
		case types.SET_COMPANIES:
			return { ...state, companies: payload }
		case types.SET_COMPANY_FIELDS:
			return { ...state, company: { ...state.company, ...payload } }
		case types.SET_CREATION_XML:
			return { ...state, xmlToSign: payload }
		case types.RESET_CREATION_XML:
			return { ...state, xmlToSign: null }
		default:
			return state
	}
}
