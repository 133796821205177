import { format, isAfter, parse, subYears } from 'date-fns'
import * as Yup from 'yup'
import { countriesIds, formates } from '@app/config'

export const transformStringDateFormatToDate = (format = 'dd.MM.yyyy') => {
	return Yup.date()
		.nullable()
		.transform(function (_, originalValue) {
			const isStringFormat = typeof originalValue === 'string'
			return isStringFormat ? parse(originalValue, format, new Date()) : originalValue
		})
}

Yup.addMethod(Yup.string, 'checkRemoteWork', function () {
	return this.when('remote_work', {
		is: (remoteWork) => !remoteWork,
		then: Yup.string().required({ key: 'required' }),
	})
})

Yup.addMethod(Yup.string, 'checkCountryWork', function () {
	return this.when('country_work', {
		is: (country) => Number(country) === countriesIds.kz,
		then: Yup.string().required({ key: 'required' }),
	})
})

Yup.addMethod(Yup.date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, minDate: format(dateBefore, formates.date, new Date()) } })
			: true
	})
})
