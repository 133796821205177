export const TOGGLE_LOADING = 'contracts/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'contracts/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'contracts/TOGGLE_ERROR'
export const SET_CONTRACTS = 'contracts/SET_CONTRACTS'
export const SET_CONTRACT = 'contracts/SET_CONTRACT'
export const SET_CONTRACT_LOADING = 'contracts/SET_CONTRACT_LOADING'
export const SET_CONTRACT_SUCCESS = 'contracts/SET_CONTRACT_SUCCESS'
export const SET_CONTRACT_ERROR = 'contracts/SET_CONTRACT_ERROR'
export const RESET_CONTRACT = 'contracts/RESET_CONTRACT'
export const SET_CREATE_CONTRACT_XML = 'contracts/SET_CREATE_CONTRACT_XML'
export const SET_TERMINATE_XML = 'contracts/SET_TERMINATE_XML'
export const SET_REMOVE_XML = 'contracts/SET_REMOVE_XML'
export const SET_RECOVER_XML = 'contracts/SET_RECOVER_XML'
export const SET_EDIT_XML = 'contracts/SET_EDIT_XML'
export const GET_DATA_BY_BIN = 'contracts/GET_DATA_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN = 'contracts/GET_EMPLOYER_PERMISSION_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN_START = 'contracts/GET_EMPLOYER_PERMISSION_BY_BIN_START'
export const GET_DATA_BY_BIN_START = 'contracts/GET_DATA_BY_BIN_START'
export const GET_XML_TO_REORGANIZE_LOADING = 'contracts/GET_XML_TO_REORGANIZE_LOADING'
export const CLEAR_XML_TO_REORGANIZE = 'contracts/CLEAR_XML_TO_REORGANIZE'
export const SEND_SIGNED_XML_REORGANIZE = 'contracts/SEND_SIGNED_XML_REORGANIZE'
export const SET_REORGANIZE_SUCCESS = 'contracts/SET_REORGANIZE_SUCCESS'
export const REORGANIZE_ERROR = 'contracts/REORGANIZE_ERROR'
export const GET_DATA_BY_BIN_ERROR = 'contracts/GET_DATA_BY_BIN_ERROR'
export const GET_EMPLOYER_PERMISSION_BY_BIN_ERROR = 'contracts/GET_EMPLOYER_PERMISSION_BY_BIN_ERROR'
export const CLEAR_DATA_BY_BIN = 'contracts/CLEAR_DATA_BY_BIN'
export const CLEAR_EMPLOYER_PERMISSION_BY_BIN = 'contracts/CLEAR_EMPLOYER_PERMISSION_BY_BIN'
export const GET_EMPLOYERS_LIST_TO_CHANGE = 'contracts/GET_EMPLOYERS_LIST_TO_CHANGE'
export const GET_REORGANIZE_XML = 'contracts/GET_REORGANIZE_XML'
export const SET_INITIAL_CONTRACT = 'contracts/SET_INITIAL_CONTRACT'
export const SET_CONTRACT_FIELDS = 'contracts/SET_CONTRACT_FIELDS'
export const SET_TERMINATION_REASONS = 'contracts/SET_TERMINATION_REASONS'
export const GET_FL_DATA = 'contracts/GET_FL_DATA'
export const GET_XML_FOR_UPDATE_IIN = 'contracts/GET_XML_FOR_UPDATE_IIN'
export const SEND_XML_FOR_UPDATE_IIN = 'contracts/SEND_XML_FOR_UPDATE_IIN'
export const RESET_UPDATE_IIN = 'contracts/RESET_UPDATE_IIN'
export const SET_ERROR_UPDATE_IIN = 'contracts/SET_ERROR_UPDATE_IIN'
export const SET_ADDITIONAL_CONTRACT_XML = 'conracts/SET_ADDITIONAL_CONTRACT_XML'
export const SET_ADDITIONAL_CONTRACT_IS_LOADING = 'conracts/SET_ADDITIONAL_CONTRACT_IS_LOADING'
export const SET_ADDITIONAL_CONTRACT_SUBMITTED = 'conracts/SET_ADDITIONAL_CONTRACT_SUBMITTED'
export const RESET_ADDITIONAL_CONTRACT = 'conracts/RESET_ADDITIONAL_CONTRACT'
export const SET_ADDITIONAL_CONTRACT = 'conracts/SET_ADDITIONAL_CONTRACT'
export const SET_CONTRACT_CARD_SUBMITTED = 'contract/SET_CONTRACT_CARD_SUBMITTED'
export const UPDATE_CONTRACT_AFTER_RECOVER = 'contract/UPDATE_CONTRACT_AFTER_RECOVER'
export const UPDATE_CONTRACT_AFTER_UPDATE_IIN = 'contract/UPDATE_CONTRACT_AFTER_UPDATE_IIN'
export const SET_EDIT_SUCCESS = 'contract/SET_EDIT_SUCCESS'
export const RESET_EDITING = 'contract/RESET_EDITING'
export const SET_PARAMS = 'contracts/SET_PARAMS'
export const SET_SOCIAL_LEAVE_IS_LOADING = 'contracts/SET_SOCIAL_LEAVE_IS_LOADING'
export const SET_SOCIAL_LEAVE_IS_SUCCESS = 'contracts/SET_SOCIAL_LEAVE_IS_SUCCESS'
export const SET_SOCIAL_LEAVE_XML = 'contracts/SET_SOCIAL_LEAVE_XML'
export const RESET_SOCIAL_LEAVE = 'contracts/RESET_SOCIAL_LEAVE'
export const SET_PROLONGATION_IS_SUCCESS = 'contracts/SET_PROLONGATION_IS_SUCCESS'
export const SET_PROLONGATION_IS_LOADING = 'contracts/SET_PROLONGATION_IS_LOADING'
export const SET_PROLONGATION_XML = 'contracts/SET_PROLONGATION_XML'
export const RESET_PROLONGATION = 'contracts/RESET_PROLONGATION'
export const SET_TERMINATION_IS_LOADING = 'contracts/SET_TERMINATION_IS_LOADING'
export const SET_TERMINATION_IS_SUCCESS = 'contracts/SET_TERMINATION_IS_SUCCESS'
export const RESET_TERMINATION = 'contracts/RESET_TERMINATION'
export const SET_SELECTED_CONTRACTS_STATE = 'contracts/SET_SELECTED_CONTRACTS_STATE'

// PACKAGE LOADING
export const GET_XML_TO_SIGN_SOME_LOADING = 'contracts/GET_XML_TO_SIGN_SOME_LOADING'
export const GET_XML_TO_SIGN_SOME = 'contracts/GET_XML_TO_SIGN_SOME'
export const GET_XML_TO_SIGN_SOME_ERROR = 'contracts/GET_XML_TO_SIGN_SOME_ERROR'
export const SEND_SIGNED_XML_PACKAGE_SOME = 'contracts/SEND_SIGNED_XML_PACKAGE_SOME'
export const DELETE_PACKAGE_SOME = 'contracts/DELETE_PACKAGE_SOME'
export const RESET_PACKAGE = 'contracts/RESET_PACKAGE'
export const SET_IS_EDIT = 'contracts/SET_IS_EDIT'

// ЭТД
export const ETD_TOGGLE_LOADING = 'contracts/etd/ETD_TOGGLE_LOADING'
export const ETD_TOGGLE_SUCCESS = 'contracts/etd/ETD_TOGGLE_SUCCESS'
export const ETD_SET_FILTER = 'contracts/etd/ETD_SET_FILTER'
export const ETD_SET_PARAMS = 'contracts/etd/ETD_SET_PARAMS'
export const ETD_GET_LIST_START = 'contracts/etd/ETD_GET_LIST_START'
export const ETD_GET_LIST_END = 'contracts/etd/ETD_GET_LIST_END'
export const ETD_GET_LIST_ERROR = 'contracts/etd/ETD_GET_LIST_ERROR'
export const ETD_GET_CURRENT_ETD = 'contracts/etd/ETD_GET_CURRENT_ETD'
export const ETD_SET_CURRENT_ETD = 'contracts/etd/ETD_SET_CURRENT_ETD'
export const ETD_SET_CURRENT_ETD_EDIT = 'contracts/etd/ETD_SET_CURRENT_ETD_EDIT'
export const ETD_SAVE_PROJECT = 'contracts/etd/ETD_SAVE_PROJECT'
export const ETD_SEND_TO_EMPLOYEE = 'contracts/etd/ETD_SEND_TO_EMPLOYEE'
export const ETD_DECLINE_EMPLOYEE = 'contracts/etd/ETD_DECLINE_EMPLOYEE'
export const ETD_DECLINE_EMPLOYER = 'contracts/etd/ETD_DECLINE_EMPLOYER'
export const ETD_SEND_FOR_IMPROVEMENT_EMPLOYEE = 'contracts/etd/ETD_SEND_FOR_IMPROVEMENT_EMPLOYEE'
export const DELETE_ETD = 'contracts/etd/DELETE_ETD'
export const ETD_STATUS_SIGN = 'contracts/etd/ETD_STATUS_SIGN'
export const ETD_IMAGE = 'contracts/etd/IMAGE'
export const ETD_IMAGE_KEY = 'contracts/etd/IMAGE_KEY'
export const ETD_ERROR = 'contracts/etd/ERROR'
export const RESET_ETD_QR = 'contracts/etd/RESET_ETD_QR'
