export const headCells = [
	{
		id: 'requestDate',
		numeric: false,
		label: 'pension.request_date',
	},
	{
		id: 'iin',
		numeric: true,
		label: 'yin',
	},
	{
		id: 'contractor',
		numeric: false,
		label: 'pension.contractor',
	},
	{
		id: 'birthDay',
		numeric: false,
		label: 'pension.birthday',
	},
	{
		id: 'state',
		numeric: false,
		label: 'pension.status_request',
	},
	{
		id: 'status',
		numeric: false,
		label: 'pension.state',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'pension.actions',
	},
]
