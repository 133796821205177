import { ModalTemplate } from '@/ui'
import styles from './ViewAccident.module.scss'
import { Information } from './Information'

export const ViewAccident = ({ onClose, accident }) => {
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper}`}>
				<Information accident={accident}></Information>
			</div>
		</ModalTemplate>
	)
}
