import React, { useMemo } from 'react'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import styles from './Military.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import { getMilitaryColumns } from './utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function Military({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const { t } = useTranslation()
	const military = useMemo(() => getMilitaryColumns(t, data.data, lang), [data.data])

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					{data.method[optionLang]}{' '}
					<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
						'DD.MM.YYYY'
					)}`}</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<DataGridTable
					hideSubTitle
					information={military}
					hideTitle
					titleClass={styles.tableTitleCell}
					rowClass={styles.dataRow}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default Military
