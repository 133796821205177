export const headCells = [
	{
		id: 'beginDate',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.period',
	},
	{
		id: 'bin',
		numeric: true,
		disablePadding: false,
		label: 'digitization.table.headers.bin',
	},
	{
		id: 'orgNameRu',
		numeric: true,
		disablePadding: false,
		label: 'digitization.table.headers.name',
	},
]