import React, { useState } from 'react'
import { DialogTitle, DialogContent, Dialog, IconButton, Button, Box } from '@mui/material'
import { Button as DialogButton } from '@/ui'
import currentStyle from './style.module.scss'
import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'

const ModalConfirm = ({ onClose, isOpen, message = '' }) => {
	const { t } = useTranslation()

	return (
		<Dialog classes={{ paper: currentStyle.dialog }} onClose={onClose} open={isOpen}>
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={currentStyle.messageStyle}>{message}</div>
				<div className={currentStyle.dialog__buttons__wrapper}>
					<DialogButton type="button" onClick={onClose}>
						{t('col_contract.return')}
					</DialogButton>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ModalConfirm
