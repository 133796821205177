import { useTranslation } from 'react-i18next'
import { ItemData } from '../itemData'
import styles from './style.module.scss'

export const TitleAndData = ({ title, data, maxTitleWidth = '1fr', maxDataWidth = '1fr' }) => {
	const { t } = useTranslation()
	const isDataArray = Array.isArray(data)
	return (
		<div className={styles.item} style={{ gridTemplateColumns: `${maxTitleWidth} ${maxDataWidth}` }}>
			<p className={styles.title}>{t(title)}:</p>
			{!isDataArray ? (
				<p className={styles.data}>{t(data)}</p>
			) : (
				<div className={styles.items}>
					{data.map((item, index) => {
						return <ItemData key={index} data={item} />
					})}
				</div>
			)}
		</div>
	)
}
