import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField } from 'formik'
import { formFieldNames } from '../../../lib/constants/formFieldNames'
import { Field } from '@/ui'
import styles from '../CollectiveForm.module.scss'

export const DifferentCoefficientFields = connect(() => {
	const { t } = useTranslation()
	const grades = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth']

	const coefficients = grades.flatMap((grade, index) => [
		{
			name: formFieldNames[`${grade}GradeMin`],
			labelKey: t('col_contract.grade_min', { number: index + 1 }),
		},
		{
			name: formFieldNames[`${grade}GradeMax`],
			labelKey: t('col_contract.grade_max', { number: index + 1 }),
		},
	])
	return (
		<div className={styles.twoFields}>
			{coefficients.map(({ name, labelKey }) => (
				<div key={labelKey} className={styles.mbFields}>
					<FormikField name={name}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="number"
								min={0}
								hideNumberArrows
								enablecheckfloat
								label={t(labelKey)}
								error={touched && error}
								placeholder={t('col_contract.placeholder_rate')}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			))}
		</div>
	)
})
