import React from 'react'
import { Button, Field, Title } from '@/ui'
import { Field as FormikField } from 'formik'
import { Trash, Add } from '@app/icons'
import styles from './ExtraGuarantee.module.scss'

export const ExtraGuarantee = ({
	values,
	extraGuaranteesCollectiveContract,
	removeGuarantee,
	addGuarantee,
	getGuaranteeLabel,
	t,
	title,
}) => (
	<section>
		<Title>{title}</Title>
		<p className={styles.subTitle}>{t('col_contract.type_guarantees')}</p>
		{values.extraGuarantee.map((guarantee, guaranteeIndex) => (
			<React.Fragment key={guaranteeIndex}>
				<p className={styles.subTitle}>
					{t('col_contract.type_benefits')} {guaranteeIndex + 1}{' '}
				</p>
				<div className={styles.fieldAndRemoveButton}>
					<FormikField name={`extraGuarantee.${guaranteeIndex}.code`}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.additional_guarantees')}
								placeholder={t('select_from_list')}
								options={extraGuaranteesCollectiveContract.filter(
									(extra) =>
										!values.extraGuarantee
											.map((item) => item.code)
											.filter((code) => code !== '' && !guarantee.code)
											.includes(extra.code)
								)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<Button
						transparent
						onClick={() => removeGuarantee(guaranteeIndex)}
						buttonClassName={styles.buttonRed}
						disabled={false}
					>
						<Trash className={styles.iconRed} />
						{t('remove')}
					</Button>
				</div>
				{guarantee.code && (
					<div className={styles.fieldsGuarantee}>
						<FormikField name={`extraGuarantee.${guaranteeIndex}.valueKz`}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'textarea'}
									isRequired
									label={getGuaranteeLabel(guarantee.code) + t('industryAgreement.labelKz')}
									placeholder={t('col_contract.enter_information')}
									error={touched && error ? t(error) : ''}
									{...field}
									showCount
									maxLength={4000}
									rows={6}
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={`extraGuarantee.${guaranteeIndex}.valueRu`}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'textarea'}
									isRequired
									label={getGuaranteeLabel(guarantee.code) + t('industryAgreement.labelRu')}
									placeholder={t('col_contract.enter_information')}
									error={touched && error ? t(error) : ''}
									{...field}
									showCount
									maxLength={4000}
									rows={6}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				)}
			</React.Fragment>
		))}
		<Button transparent onClick={addGuarantee} buttonClassName={styles.button}>
			<Add className={styles.iconGreen} />
			{t('col_contract.add_guarantees')}
		</Button>
	</section>
)
