import { Edit as EditIcon, View as ViewIcon } from '@app/images'

export default function onHistoryCheckActionMenuClick(row, e, setActionMenuItems, getChecksInfo, t) {
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	actions.splice(1, 0, { icon: EditIcon, title: t('history_check.replay'), action: 'edit' })
	setActionMenuItems(actions)
}
