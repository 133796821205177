import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	setPension: (payload) => ({
		type: types.SET_PENSION,
		payload,
	}),
	setConsentStatusPension: (payload) => ({
		type: types.SET_CONSENT_STATUS_PENSION,
		payload,
	}),
	setPensions: (payload) => ({
		type: types.SET_PENSIONS,
		payload,
	}),
	setPersons: (payload) => ({
		type: types.SET_PERSONS,
		payload,
	}),
	setOptions: (payload) => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setSingleOptions: (payload) => ({
		type: types.SET_SINGLE_OPTIONS,
		payload,
	}),
	setConsentAndPhoneData: (payload) => ({
		type: types.SET_CONSENT_AND_PHONE_DATA,
		payload,
	}),
	setParams: (payload) => ({
		type: types.SET_PARAMS,
		payload,
	}),
	setPensionXML: (payload) => ({
		type: types.SET_PENSION_XML,
		payload,
	}),
	clearXml: () => ({
		type: types.CLEAR_XML,
	}),
	toggleSuccessSave: (payload) => ({
		type: types.TOGGLE_SUCCESS_SAVE,
		payload,
	}),
}
