import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { Loader } from '@/components/Loader'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { api } from '@/shared'
import { Button, Field, Title } from '@/ui'
import { Add, Trash } from '@app/icons'
import { Col, message, Row } from 'antd'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formFieldNames } from './formFieldNames'
import styles from './UnionsForm.module.scss'

export const UnionsForm = ({
	options = {},
	attachFileCharter,
	attachFileProtocol,
	attachFileReference,
	fileCharter,
	fileProtocol,
	fileReference,
	onCancel,
	actions,
	cabinetTradeUnion = true,
}) => {
	const { t } = useTranslation()
	const { values, setFieldValue, isValid } = useFormikContext()
	const formik = useFormikContext()
	const dispatch = useDispatch()
	const searchChiefFio = useSelector(profUnionsSelectors.searchChiefFio)
	const searchName = useSelector(profUnionsSelectors.searchName)
	const searchEmployerName = useSelector(profUnionsSelectors.searchEmployerName)
	const isLoadingChiefFio = useSelector(profUnionsSelectors.isLoadingChiefFio)
	const isLoadingBin = useSelector(profUnionsSelectors.isLoadingBin)
	const typeCode = useSelector(profUnionsSelectors.typeCode)
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'
	const [documentList, setDocList] = useState([])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [checkDoc, setCheckDoc] = useState(true)
	const [isManagersVisible, setIsManagersVisible] = useState(false)
	const [isIndustryVisible, setIndustryVisible] = useState(false)
	const [isLocalVisible, setLocalVisible] = useState(false)
	const [isPrimaryVisible, setPrimaryVisible] = useState(false)
	const { unionType, okedsTop } = options
	const [filteredUnionType, setFilteredUnionType] = useState(unionType)
	const [isSearchParentBinIndex, setIsSearchParentBinIndex] = useState()
	const [isSearchEmployerBinIndex, setIsSearchEmployerBinIndex] = useState()
	const [isSearchManagerIinIndex, setIsSearchManagerIinIndex] = useState()

	useEffect(() => {
		if (actions.create) {
			let newFilteredUnionType = unionType
			if (isEmployee) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code === 'primary')
			} else if (cabinetTradeUnion) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code !== 'primary')
			}
			if (typeCode === 'industry' || !cabinetTradeUnion) {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code !== 'industry')
			} else if (typeCode === 'local') {
				newFilteredUnionType = newFilteredUnionType.filter((option) => option.code === 'primary')
			}
			setFilteredUnionType(newFilteredUnionType)
		}
	}, [typeCode, isEmployee, unionType])

	const onGetHandBookOptions = useCallback(
		(value) => {
			dispatch(profUnionsThunks.getPerson({ iin: value }))
		},
		[dispatch]
	)

	const onGetBin = useCallback(
		(value, typeBin = '') => {
			if (typeBin === 'unionName') {
				dispatch(profUnionsThunks.getEmployerProfUnitsUnionName({ bin: value }))
			} else if (typeBin === 'employerBin') {
				dispatch(profUnionsThunks.getEmployerProfUnitsEmployerBin({ bin: value }))
			} else {
				dispatch(profUnionsThunks.getEmployerProfParent({ bin: value }))
			}
		},
		[dispatch]
	)

	const handleSearchBin = async (searchField, typeBin) => {
		if (values.typeCode.length < 1) {
			return message.error('Выберите: "Тип проф.союза"')
		}
		if (values[searchField].length < 12) {
			return
		}
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values[searchField])) {
			onGetBin(values[searchField], typeBin)
		}
	}

	const handleSearchIin = async (searchField) => {
		if (values[searchField].length < 12) {
			return
		}
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values[searchField])) {
			onGetHandBookOptions(values[searchField])
		}
	}

	const handleSearchMember = async (iin, managerIndex) => {
		if (iin.length < 12) {
			return
		}
		try {
			setIsSearchManagerIinIndex(managerIndex)
			const { fullNamePerson } = await api.profile.getEmployerActivityByIIN({ iin: iin })
			setIsSearchManagerIinIndex(undefined)
			setFieldValue(`managers[${managerIndex}].fio`, fullNamePerson)
		} catch (error) {
			setIsSearchManagerIinIndex(undefined)
		}

	}

	const handleSearchParent = async (bin, parentIndex) => {
		if (bin.length < 12) {
			return
		}
		setIsSearchParentBinIndex(parentIndex)
		try {
			const { fullNameRu } = await api.profile.getEmployerActivityByBIN({ bin: bin })
			setIsSearchParentBinIndex(undefined)
			setFieldValue(`parents[${parentIndex}].parentName`, fullNameRu)
		} catch (error) {
			setIsSearchParentBinIndex(undefined)
		}

	}

	const handleSearchEmployer = async (bin, index) => {
		if (bin.length < 12) {
			return
		}
		setIsSearchEmployerBinIndex(index)
		try {
			const { fullNameRu } = await api.profile.getEmployerActivityByBIN({ bin: bin })
			setIsSearchEmployerBinIndex(undefined)
			setFieldValue(`employers[${index}].employerName`, fullNameRu)
		} catch (error) {
			setIsSearchEmployerBinIndex(undefined)
		}
	}

	const addStructManagers = () => {
		const managers = values?.managers
		if (managers && managers?.length !== 0) {
			setFieldValue('managers', [
				...values.managers,
				{
					iin: '',
					fio: '',
					position: '',
				},
			])
		} else {
			setFieldValue('managers', [
				{
					iin: '',
					fio: '',
					position: '',
				},
			])
		}
		setIsManagersVisible(true)
	}

	const addParents = () => {
		const parents = values?.parents
		if (parents && parents?.length !== 0) {
			setFieldValue('parents', [
				...values.parents,
				{
					parentIndustry: '',
					parentBin: '',
					parentName: '',
					parentType: '',
				},
			])
		} else {
			setFieldValue('parents', [
				{
					parentIndustry: '',
					parentBin: '',
					parentName: '',
					parentType: '',
				},
			])
		}
	}

	const addEmployers = () => {
		const employers = values?.employers
		if (employers && employers?.length !== 0) {
			setFieldValue('employers', [
				...employers,
				{
					employerBin: '',
					employerName: '',
				},
			])
		} else {
			setFieldValue('employers', [
				...values.employers,
				{
					employerBin: '',
					employerName: '',
				},
			])
		}

	}

	const updatedObject = (obj) => {
		let newObj = {}
		for (let key in obj) {
			newObj[key] = false
		}
		return newObj
	}

	const removeManagers = (index) => {
		const updatedUnits = [...values.managers]
		if (formik?.touched?.managers && formik?.touched?.managers.length !== 0) {
			const updatedTouched = [...formik?.touched?.managers].map((el, i) => i === index ? updatedObject(el) : el)
			formik.setTouched({ ...formik?.touched, managers: updatedTouched })
		}
		updatedUnits.splice(index, 1)
		setFieldValue('managers', updatedUnits)
	}

	const removeParents = (index) => {
		const updatedUnits = [...values.parents]
		if (formik?.touched?.parents && formik?.touched?.parents.length !== 0) {
			const updatedTouched = [...formik?.touched?.parents].map((el, i) => i === index ? updatedObject(el) : el)
			formik.setTouched({ ...formik?.touched, parents: updatedTouched })
		}
		updatedUnits.splice(index, 1)
		setFieldValue('parents', updatedUnits)
	}

	const removeEmployers = (index) => {
		const updatedUnits = [...values.employers]
		if (formik?.touched?.employers && formik?.touched?.employers.length !== 0) {
			const updatedTouched = [...formik?.touched?.employers].map((el, i) => i === index ? updatedObject(el) : el)
			formik.setTouched({ ...formik?.touched, employers: updatedTouched })
		}
		updatedUnits.splice(index, 1)
		setFieldValue('employers', updatedUnits)
	}

	const getDocumentCharter = (doc) => {
		setDocList(doc)
		attachFileCharter(doc)
	}
	const getDocumentProtocol = (doc) => {
		setDocList(doc)
		attachFileProtocol(doc)
	}
	const getDocumentReference = (doc) => {
		setDocList(doc)
		attachFileReference(doc)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}
	useEffect(() => {
		setCheckDoc(documentList.length >= 1 && !disableErrorSize)
	}, [documentList, disableErrorSize])

	useEffect(() => {
		if (searchChiefFio) {
			setFieldValue('chiefFio', searchChiefFio)
		}
	}, [searchChiefFio, cabinetTradeUnion])

	useEffect(() => {
		if (searchName) {
			setFieldValue('unionName', searchName)
		}
	}, [searchName])


	useEffect(() => {
		if (searchEmployerName && !isIndustryVisible) {
			setFieldValue('employerName', searchEmployerName)
		}
	}, [searchEmployerName, isLocalVisible])

	useEffect(() => {
		if (!values.typeCode) return
		// Default visibility
		let localVisible = false,
			industryVisible = false,
			primaryVisible = false
		if (values.typeCode === 'local') {
			localVisible = true
		} else if (values.typeCode === 'industry') {
			industryVisible = true
			if (actions.create) {
				setFieldValue('parents', [])
				setFieldValue('employers', [])
			}

		} else if (values.typeCode === 'primary') {
			primaryVisible = true
		}
		// Set the visibility
		setLocalVisible(localVisible)
		setIndustryVisible(industryVisible)
		setPrimaryVisible(primaryVisible)
	}, [values.typeCode])


	return (
		<FormikForm className={styles.form}>
			<Row gutter={[24, 24]} >
				<Col span={24} >
					<div className={styles.title} >{t('profUnions.unionInfo')}</div>
				</Col>
				<Col span={8} >
					<FormikField name={formFieldNames.dateCreate}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('profUnions.createDate')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</Col>
				<Col span={16} >
					<FormikField name={formFieldNames.typeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('profUnions.unionType')}
								placeholder={t('select_from_list')}
								options={filteredUnionType}
								error={touched && error}
								{...field}
								disabled={actions.edit}
							/>
						)}
					</FormikField>
				</Col>
				{
					!isPrimaryVisible && cabinetTradeUnion ?
						(
							<>
								<Col span={8} >
									<FormikField name={formFieldNames.bin}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												label={t('bin')}
												isRequired
												error={touched && error}
												disabled={cabinetTradeUnion}
												placeholder={t('enter_count_digitals', { count: 12 })}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
												{...field}
											/>
										)}
									</FormikField>
								</Col>
								<Col span={16} >
									<FormikField name={formFieldNames.unionName}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												disabled
												wrapperClassName={styles.field__input}
												label={t('name')}
												placeholder={t('filled_automate')}
												{...field}
												error={touched && error ? t(error) : ''}
												isRequired
											/>
										)}
									</FormikField>
								</Col>
							</>
						) : <></>
				}
				{
					isLocalVisible && !cabinetTradeUnion ?
						(
							<>
								<Col span={5} >
									<FormikField name={formFieldNames.bin}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												label={t('bin')}
												isRequired
												error={touched && error}
												disabled={cabinetTradeUnion}
												placeholder={t('enter_count_digitals', { count: 12 })}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
												{...field}
											/>
										)}
									</FormikField>
								</Col>
								<Col span={3} >
									<>
										{isLoadingBin ? (
											<div className={styles.loading}>
												<Loader></Loader>
											</div>
										) : (
											<div className={styles.buttonWrap}>
												<Button
													disabled={!values[formFieldNames.bin]}
													onClick={() => handleSearchBin(formFieldNames.bin, 'unionName')}
												>
													{t('find')}
												</Button>
											</div>

										)}
									</>
								</Col>
								<Col span={16} >
									<FormikField name={formFieldNames.unionName}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												disabled
												wrapperClassName={styles.field__input}
												label={t('name')}
												placeholder={t('filled_automate')}
												{...field}
												error={touched && error ? t(error) : ''}
												isRequired
											/>
										)}
									</FormikField>
								</Col>
							</>
						) : <></>
				}
				{
					isPrimaryVisible
						? (
							<Col span={24} >
								<FormikField name={formFieldNames.unionName}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											wrapperClassName={styles.field__input}
											label={t('name')}
											placeholder={t('profUnions.placeholder_primary')}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
										/>
									)}
								</FormikField>
							</Col>
						) : <></>
				}
				{
					isIndustryVisible &&
					<Col span={24} >
						<FormikField name={formFieldNames.industryCode}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType={'select'}
									isRequired
									label={t('profUnions.industry')}
									placeholder={t('select_from_list')}
									options={okedsTop}
									error={touched && error}
									{...field}
								/>
							)}
						</FormikField>
					</Col>
				}
				<Col span={24} >
					<FormikField name={formFieldNames.unionNote}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'textarea'}
								label={t('profUnions.descriptionUnion')}
								placeholder={t('profUnions.placeholderUnion')}
								error={touched && error ? t(error) : ''}
								{...field}
								showCount
								maxLength={4000}
								rows={6}
								{...field}
							/>
						)}
					</FormikField>
				</Col>
				{
					(isLocalVisible || isPrimaryVisible) &&
					values?.parents &&
					values.parents?.length !== 0 &&
					values.parents.map((parent, parentIndex) => (
						<React.Fragment key={parentIndex}>
							<Col span={24} >
								<div className={styles.titleAndButtonWrap} >
									<div>
										<p className={styles.subTitle}>
											{t('profUnions.memberUnit')}
										</p>
									</div>
									{
										(isLocalVisible || isPrimaryVisible) && cabinetTradeUnion && (actions.create || actions.edit) &&
										<Button
											transparent
											onClick={() => removeParents(parentIndex)}
											buttonClassName={styles.buttonRed}
											disabled={false}
										>
											<Trash className={styles.iconRed} />
											{t('remove')}
										</Button>
									}
								</div>
							</Col>
							<Col span={8} >
								<FormikField name={`parents.${parentIndex}.${formFieldNames.parentBin}`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											wrapperClassName={styles.field__input}
											hideNumberArrows
											label={t('bin')}
											placeholder={t('enter_count_digitals', { count: 12 })}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
											maxLength={12}
											pattern="[0-9]{12}"
											onInput={(e) => {
												e.target.value = e.target.value.replace(/[^0-9]/g, '')
											}}
										/>
									)}
								</FormikField>
							</Col>
							<Col span={3} >
								<>
									{isSearchParentBinIndex === parentIndex ? (
										<div className={styles.loading}>
											<Loader></Loader>
										</div>
									) : (
										<div className={styles.buttonWrap}>
											<Button onClick={() => handleSearchParent(parent.parentBin, parentIndex)}>{t('find')}</Button>
										</div>
									)}
								</>
							</Col>
							<Col span={13} >
								<FormikField name={`parents.${parentIndex}.${formFieldNames.parentName}`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											disabled
											wrapperClassName={styles.field__input}
											label={t('name')}
											placeholder={t('filled_automate')}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
										/>
									)}
								</FormikField>
							</Col>
						</React.Fragment>
					))
				}
				{
					(isLocalVisible || isPrimaryVisible) && (actions.create || actions.edit) &&
					<Col span={24} >
						<Button transparent onClick={addParents} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('profUnions.addMemberUnit')}
						</Button>
					</Col>
				}
				{
					!isIndustryVisible &&
					(isLocalVisible || isPrimaryVisible) &&
					values?.employers &&
					values.employers?.length !== 0 &&
					<>
						<Col span={24} >
							<Title>{t('employer_data')}</Title>
						</Col>
						{
							values.employers.map((employer, employerIndex) => (
								<React.Fragment key={employerIndex}>
									<Col span={8} >
										<FormikField name={`employers.${employerIndex}.${formFieldNames.employerBin}`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													wrapperClassName={styles.field__input}
													hideNumberArrows
													label={t('col_contract.bin')}
													placeholder={t('enter_count_digitals', { count: 12 })}
													{...field}
													error={touched && error ? t(error) : ''}
													isRequired
													maxLength={12}
													pattern="[0-9]{12}"
													onInput={(e) => {
														e.target.value = e.target.value.replace(/[^0-9]/g, '')
													}}
												/>
											)}
										</FormikField>
									</Col>
									<Col span={3} >
										{isSearchEmployerBinIndex === employerIndex ? (
											<div className={styles.loading}>
												<Loader></Loader>
											</div>
										) : (
											<div className={styles.buttonWrap}>
												<Button onClick={() => handleSearchEmployer(employer.employerBin, employerIndex)}>{t('find')}</Button>
											</div>
										)}
									</Col>
									<Col span={13} >
										<FormikField name={`employers.${employerIndex}.${formFieldNames.employerName}`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													type="text"
													disabled
													wrapperClassName={styles.field__input}
													label={t('col_contract.name')}
													placeholder={t('filled_automate')}
													{...field}
													error={touched && error ? t(error) : ''}
													isRequired
												/>
											)}
										</FormikField>
									</Col>
									{
										employerIndex !== 0 && (actions.create || actions.edit) &&
										<Col span={24} >
											<div className={styles.actions}>
												<Button
													transparent
													onClick={() => removeEmployers(employerIndex)}
													buttonClassName={styles.buttonRed}
													disabled={false}
												>
													<Trash className={styles.iconRed} />
													{t('remove')}
												</Button>
											</div>
										</Col>
									}
								</React.Fragment>
							))
						}
					</>
				}
				{
					(isLocalVisible) && !isIndustryVisible && (actions.create || actions.edit) &&
					<Col span={24} >
						<Button transparent onClick={addEmployers} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('add_employer_data')}
						</Button>
					</Col>
				}
				<Col span={24} >
					<Title>{t('profUnions.titleGoverning')}</Title>
				</Col>
				<Col span={8} >
					<FormikField name={formFieldNames.chiefIin}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								wrapperClassName={styles.field__input}
								hideNumberArrows
								label={t('profUnions.innChairman')}
								placeholder={t('enter_count_digitals', { count: 12 })}
								error={touched && (error && error?.key) ? t(error.key, { value: error.value }) : ''}
								isRequired
								maxLength={12}
								pattern="[0-9]{12}"
								onInput={(e) => {
									e.target.value = e.target.value.replace(/[^0-9]/g, '')
								}}
							/>
						)}
					</FormikField>
				</Col>
				<Col span={3} >
					<div>
						{isLoadingChiefFio ? (
							<div className={styles.loading}>
								<Loader></Loader>
							</div>
						) : (
							<div className={styles.buttonWrap}>
								<Button
									disabled={!values[formFieldNames.chiefIin]}
									onClick={handleSearchIin.bind(null, formFieldNames.chiefIin)}
								>
									{t('find')}
								</Button>
							</div>

						)}
					</div>
				</Col>
				<Col span={13} >
					<FormikField name={formFieldNames.chiefFio}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								disabled
								wrapperClassName={styles.field__input}
								label={t('employee_initials')}
								placeholder={t('filled_automate')}
								{...field}
								error={touched && error ? t(error) : ''}
								isRequired
							/>
						)}
					</FormikField>
				</Col>
				{
					values?.managers &&
					values.managers?.length !== 0 &&
					values.managers.map((manager, managerIndex) => (
						<React.Fragment key={managerIndex}>
							<Col span={24} >
								<div className={styles.titleAndButtonWrap} >
									<div>
										<p className={styles.subTitle}>
											{t('profUnions.managerGoverning')} {managerIndex + 1}
										</p>
									</div>
									{
										(actions.create || actions.edit)
										&&
										<Button
											transparent
											onClick={() => removeManagers(managerIndex)}
											buttonClassName={styles.buttonRed}
											disabled={false}
										>
											<Trash className={styles.iconRed} />
											{t('remove')}
										</Button>
									}
								</div>
							</Col>
							<Col span={8} >
								<FormikField name={`managers.${managerIndex}.iin`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											{...field}
											placeholder={t('enter_count_digitals', { count: 12 })}
											error={touched && (error && error?.key) ? t(error.key, { value: error.value }) : ''}
											wrapperClassName={styles.field__input}
											label={t('iin')}
											isRequired
											maxLength={12}
											pattern="[0-9]{12}"
											onInput={(e) => {
												e.target.value = e.target.value.replace(/[^0-9]/g, '')
											}}
										/>
									)}
								</FormikField>
							</Col>
							<Col span={3} >
								<>
									{isSearchManagerIinIndex === managerIndex ? (
										<div className={styles.loading}>
											<Loader></Loader>
										</div>
									) : (
										<div className={styles.buttonWrap}>
											<Button onClick={() => handleSearchMember(manager.iin, managerIndex)}>{t('find')}</Button>
										</div>
									)}
								</>

							</Col>
							<Col span={13} >
								<FormikField name={`managers.${managerIndex}.fio`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											disabled
											isRequired
											wrapperClassName={styles.field__input}
											label={t('employee_initials')}
											placeholder={t('filled_automate')}
											{...field}
											error={touched && (error && error?.key) ? t(error.key) : ''}
										/>
									)}
								</FormikField>
							</Col>
							<Col span={24} >
								<FormikField name={`managers.${managerIndex}.position`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											wrapperClassName={styles.field__input}
											label={t('profUnions.position')}
											placeholder={t('profUnions.position')}
											isRequired
											{...field}
											error={touched && (error && error?.key) ? t(error.key) : ''}
										/>
									)}
								</FormikField>
							</Col>


						</React.Fragment>
					))
				}
				{
					(actions.create || actions.edit)
					&&
					<Col span={24} >
						<Button transparent onClick={addStructManagers} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('profUnions.addMember')}
						</Button>
					</Col>
				}
				{
					(isLocalVisible || isIndustryVisible || isPrimaryVisible) &&
					<>
						<Col span={24} >
							<Title>{isPrimaryVisible ? t('profUnions.tradeUnionRegulations') : t('profUnions.charter')}</Title>
							<ConfirmDocument
								getDocument={getDocumentCharter}
								getErrorSize={getErrorSize}
								fileType={'TRADEUNION'}
								fileList={fileCharter}
								maxFiles={1}
							/>
						</Col>
						<Col span={24} >
							<Title>{t('profUnions.protocol')}</Title>
							<ConfirmDocument
								getDocument={getDocumentProtocol}
								getErrorSize={getErrorSize}
								fileType={'TRADEUNION'}
								fileList={fileProtocol}
								maxFiles={1}
							/>
						</Col>
						{
							!isPrimaryVisible &&
							<Col span={24} >
								<Title>{t('profUnions.registration')}</Title>
								<ConfirmDocument
									getDocument={getDocumentReference}
									getErrorSize={getErrorSize}
									fileType={'TRADEUNION'}
									fileList={fileReference}
									maxFiles={1}
								></ConfirmDocument>
							</Col>
						}

					</>
				}
			</Row>
			<div>
				<div className={styles.actions}>
					<Button type="bare" onClick={onCancel}>
						{t('cancel')}
					</Button>
					<Button disabled={!checkDoc} type="submit">
						{t('sign_and_save')}
					</Button>
				</div>
			</div>
		</FormikForm>
	)
}
