import * as types from './types'

export const actions = {
	currentReview: (payload) => ({
		type: types.SET_CURRENT_REVIEW,
		payload,
	}),


	getReviewAction: (payload) => ({
		type: types.GET_REVIEW_ACTION,
	}),

	getReviewActonDone: (payload) => ({
		type: types.GET_REVIEW_ACTION_DONE,
		payload,
	}),

	getReviewActonFail: (payload) => ({
		type: types.GET_REVIEW_ACTION_FAIL,
	}),



	reviewAction: (payload) => ({
		type: types.REVIEW_ACTION,
	}),

	reviewActonDone: (payload) => ({
		type: types.REVIEW_ACTION_DONE,
		payload,
	}),

	reviewActonFail: (payload) => ({
		type: types.REVIEW_ACTION_FAIL,
	}),
}
