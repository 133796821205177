import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setPhone: (payload) => ({
		type: types.SET_PHONE,
		payload,
	}),
	setQR: (payload) => ({
		type: types.SET_QR,
		payload,
	}),
	setQRKey: (payload) => ({
		type: types.SET_QR_KEY,
		payload,
	}),
	getQRStatus: (payload) => ({
		type: types.GET_QR_STATUS,
		payload,
	}),
	toggleSuccessSend: (payload) => ({
		type: types.TOGGLE_SUCCESS_SEND,
		payload,
	}),
	toggleErrorSend: (payload) => ({
		type: types.TOGGLE_ERROR_SEND,
		payload,
	}),
	resetVerification: () => ({
		type: types.RESET_VERIFICATION,
	}),
}
