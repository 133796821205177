import {
	contractsSelectors,
	contractsThunks,
	laborRecordsActions,
	laborRecordsSelectors,
	laborRecordsThunks,
} from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RequestTerminateForm } from '@features/laborRecords/components/requestAdjustment'
import { useDigitalSign } from '@/shared'
import { sendContractAdjustment } from '@/entity/laborRecords/model/thunks'
import { Question } from '@/components/question/Question'
import { RequestRemoveForm } from '@features/laborRecords/components/requestAdjustment/RequestRemoveForm'
import { useTranslation } from 'react-i18next'
import { RequestEditForm } from '@features/laborRecords/components/requestAdjustment/RequestEditForm'
import { Header } from '@features/laborRecords/components/Header/Header'
import { SubHeader } from '@features/laborRecords/components/SubHeader/SubHeader'
import { DetailsOfPartiesInformation } from '@features/laborRecords/components/DetailsOfPartiesInformation/DetailsOfPartiesInIformation'

export const EditAdjustment = ({ requestType, onClose }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { onSign, successPayload, onClearPayload, failedPayload } = useDigitalSign()
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const isSuccess = useSelector(laborRecordsSelectors.isAdjustmentXmlSuccess)
	const isLoading = useSelector(laborRecordsSelectors.isAdjustmentXmlLoading)
	const xml = useSelector(laborRecordsSelectors.adjustmentXml)
	const [sign, setSign] = useState(false)

	const shortContractData = useSelector(laborRecordsSelectors.shortContractData)

	const questionText = useMemo(() => {
		switch (true) {
			case requestType.terminate:
				return {
					title: t('labor_records.send_adjustments'),
					data: t('labor_records.sign_adjustments'),
				}
			case requestType.remove:
				return {
					title: t('labor_records.title_send_remove'),
					data: t('labor_records.sign_adjustments'),
				}
			case requestType.edit:
				return {
					title: t('labor_records.title_send_edit'),
					data: t('labor_records.sign_adjustments'),
				}
			default:
				return { title: '', data: '' }
		}
	}, [requestType, t])

	const handleProcess = () => {
		setSign(true)
	}

	const closeProcess = () => {
		setSign(false)
		dispatch(laborRecordsActions.resetAdjustmentXml())
	}

	const handleDataSubmission = useCallback(
		(data) => !isLoading && dispatch(laborRecordsThunks.detContractAdjustmentXml({ ...data })),
		[]
	)
	useEffect(
		() => !terminationReasons && dispatch(contractsThunks.getTerminationReasons()),
		[dispatch, terminationReasons]
	)
	const fullClear = () => {
		dispatch(laborRecordsActions.resetAdjustmentXml())
		onClearPayload()
		onClose()
	}
	const onClear = () => {
		onClearPayload()
		setSign(false)
	}
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => {
		sign && xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [sign, xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])

	useEffect(() => {
		if (successPayload) {
			dispatch(sendContractAdjustment({ signedXml: successPayload }))
		}
	}, [successPayload])

	return (
		<>
			<Header
				requestType={requestType}
				contractNumber={shortContractData.contractNumber}
				contractDate={shortContractData.contractDate}
			></Header>
			<SubHeader id={shortContractData.contractId} requestDate={shortContractData.entryDate}></SubHeader>
			<DetailsOfPartiesInformation
				shortContractData={shortContractData}
				hintInfo={requestType.edit}
			></DetailsOfPartiesInformation>
			{requestType.edit && terminationReasons ? (
				<RequestEditForm
					terminationReasons={terminationReasons}
					shortContractData={shortContractData}
					onSubmit={handleDataSubmission}
					onClose={onClose}
				></RequestEditForm>
			) : null}
			{requestType.terminate && terminationReasons ? (
				<RequestTerminateForm
					terminationReasons={terminationReasons}
					shortContractData={shortContractData}
					onSubmit={handleDataSubmission}
					onClose={onClose}
				></RequestTerminateForm>
			) : null}
			{requestType.remove ? (
				<RequestRemoveForm
					shortContractData={shortContractData}
					onSubmit={handleDataSubmission}
					onClose={onClose}
				></RequestRemoveForm>
			) : null}
			{xml ? <Question data={questionText} handleProcess={handleProcess} handleCancel={closeProcess}></Question> : null}
		</>
	)
}
