import { useTranslation } from 'react-i18next'
import styles from './TotalWorkBox.module.scss'
import { relativeTimeWithPlural } from '@/utils'
import { getCurrentLanguage } from '@/i18next'
import { useMemo, useState } from 'react'

export const TotalWorkBox = ({ experience, contractCount }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [totalExperience, setTotalExperience] = useState('')
	useMemo(
		() =>
			lang === 'rus'
				? setTotalExperience(
						' ' +
							relativeTimeWithPlural(experience.years ? experience.years : 0, 'y') +
							' ' +
							relativeTimeWithPlural(experience.months ? experience.months : 0, 'm')
				  )
				: setTotalExperience(' ' + experience.years + ' жыл ' + experience.months + ' ай'),
		[experience.months, experience.years, lang]
	)
	return (
		<div className={styles.content}>
			<div className={styles.content__left}>
				{t('labor_records.work_experience')}
				{totalExperience}
			</div>
			<div className={styles.content__right}>
				{t('labor_records.count_records')}
				{contractCount}
			</div>
		</div>
	)
}
