import * as types from './types'

export const initialSideBar = {
	openedChildsIds: [],
}

const initialState = {
	...initialSideBar,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.ADD_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: [...state.openedChildsIds, payload],
			}
		case types.REMOVE_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: state.openedChildsIds.filter((title) => title !== payload),
			}
		default:
			return state
	}
}
