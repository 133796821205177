import styles from './PositionEvaluationForm.module.scss'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Functions } from '@/entity/grading/positionEvaluation/ui/form/components/Functions'

export const PositionEvaluationResult = ({ data }) => {
	const { t } = useTranslation()

	const [items, setItems] = useState([])

	useEffect(() => {
		if (!data) return

		setItems([
			{
				label: t('position_evaluation_page.form.occupation'),
				value: data.result.activityArea,
			},
			{
				label: t('position_evaluation_page.form.group'),
				value: data.result.orgType,
			},
			{
				label: t('position_evaluation_page.form.bin'),
				value: data.result.bin,
			},
			{
				label: t('position_evaluation_page.form.organization'),
				value: data.result.orgName,
			},
			{
				label: t('position_evaluation_page.form.position_by_nkz'),
				value: data.result.posName,
			},
			{
				label: t('position_evaluation_page.form.functionalBlock'),
				value: data.result.funcBlock,
			},
			{
				label: t('position_evaluation_page.form.education'),
				value: data.result.education,
			},
			{
				label: t('position_evaluation_page.form.workExperience'),
				value: data.result.experience,
			},
			{
				label: t('position_evaluation_page.form.increaseForRuralWork'),
				value: data.result.isVillage ? t('yes') : t('no'),
			},
		])
	}, [data])

	return (
		<>
			<div className={styles.result}>
				<h2>{t('position_evaluation')}</h2>
				{items.map((item, index) => (
					<div key={index} className={styles.result_row}>
						<p>{item.label}:</p>
						<p>{item.value}</p>
					</div>
				))}
			</div>
			{/*Первоначальный вариант с таблицей функций*/}
			{/*<TableResult data={data.result.funcFactors} />*/}

			{/*Вариант с отображаением как на форме*/}
			{/*<div style={{ marginTop: '10px' }}>*/}
			{/*	<label>{t('position_evaluation_page.form.functionsAndFactors')}</label>*/}
			{/*	<Functions data={data.functions}></Functions>*/}
			{/*</div>*/}

			<ul className={styles.grading}>
				<li>
					<span className={styles.grading_label}>
						{t('position_evaluation_page.mrpOn', { year: new Date().getFullYear() })}:{' '}
					</span>
					<span className={styles.grading_item}>{data.calculation.mrp}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.pointCost')}: </span>
					<span className={styles.grading_item}>{data.calculation.pointPrice}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.grade')}: </span>
					<span className={styles.grading_item}>{data.calculation.grade}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.gradePoint')}: </span>
					<span className={styles.grading_item}>{data.calculation.gradePoint}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.educationPoint')}: </span>
					<span className={styles.grading_item}>{data.calculation.educationPoint}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.experiencePoint')}: </span>
					<span className={styles.grading_item}>{data.calculation.experiencePoint}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.categoryPoint')}: </span>
					<span className={styles.grading_item}>{data.calculation.categoryPoint}</span>
				</li>
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.finalScore')}: </span>
					<span className={styles.grading_item}>{data.calculation.finalPoint}</span>
				</li>
				{data.calculation.coef && (
					<li>
						<span className={styles.grading_label}>{t('position_evaluation_page.coef')}: </span>
						<span className={styles.grading_item}>{data.calculation.coef}</span>
					</li>
				)}
				<li>
					<span className={styles.grading_label}>{t('position_evaluation_page.salary')}: </span>
					<span className={styles.grading_item}>
						{new Intl.NumberFormat('ru-RU', {
							style: 'decimal',
							minimumFractionDigits: 2,
						}).format(data.calculation.do)}
					</span>
				</li>
			</ul>
		</>
	)
}
