import React from 'react'
import styles from './style.module.scss'

const supportedTitleTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const titles = {
	h1: styles.title,
	h2: styles.h2title,
	h3: styles.h3title,
	h4: styles.h4title,
	h5: styles.h5title,
	h6: styles.h6title,
}

const colors = {
	default: styles.defaultColor,
	black: styles.blackColor,
	darkBlue: styles.darkBlue,
	red: styles.redColor,
	white: styles.whiteColor,
	green: styles.greenColor,
	yellow: styles.yellowColor,
	blue: styles.blueColor,
}

const background = {
	default: styles.defaultBackgroundColor,
	red: styles.redBackgroundColor,
	green: styles.greenBackgroundColor,
	yellow: styles.yellowBackgroundColor,
	blue: styles.blueBackgroundColor,
	gray: styles.grayBackgroundColor,
	lgray: styles.lgrayBackgroundColor,
	notcolor: styles.notBackgroundColor,
}

export const Title = ({ children, type = 'h1', color = 'default', ground = 'default' }) => {
	if (!supportedTitleTags.includes(type)) return <span>This title type is't supported</span>
	return React.createElement(type, { className: `${titles[type]} ${colors[color]} ${background[ground]}` }, children)
}
