import styles from './AccidentForm.module.scss'
import { DataGridTable } from '@/ui'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { AccidentReport } from '@features/accidents/steps/accidentReport/AccidentReport'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Step } from '@/components/Step/Step'

export const Form = ({ onClose }) => {
	const { t } = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const accountData = [
		{ title: t('employer_iin_bin'), data: activeCompany.bin },
		{ title: t('name'), data: activeCompany.companyName },
	]

	return (
		<div>
			<div className={styles.tableCell}>
				<DataGridTable hideSubTitle hideTitle information={accountData} titleClass={styles.tableTitleCell} />
			</div>
			<section className={styles.steps}>
				<Step label="accidentsPage.accidentReport" index={1} opened={true}>
					<AccidentReport onClose={onClose} />
				</Step>
			</section>
		</div>
	)
}
