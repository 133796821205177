import { useDispatch, useSelector } from 'react-redux'
import { laborRecordsSelectors } from '@/entity'
import React, { useCallback, useState } from 'react'
import { Modal, ModalBackButton } from '@/components/Modal'
import { ViewAdjustment } from '@features/laborRecords/actions'
import styles from './View.module.scss'
import { View as ViewContract } from '@features/contracts/actions'
import { Loading } from '@/ui/atoms/loading'
import SocialLeaveMain from '@features/contracts/socialLeaves/SocialLeaveMain'
import { AdditionalAgreementMain } from '@features/contracts/additionalContracts'
import { handbookThunks } from '@/entity/handbook'

export const View = ({ onToggleAction, onToggleContractAction }) => {
	const dispatch = useDispatch()
	const isLoading = useSelector(laborRecordsSelectors.isRequestLoading)
	const isSuccess = useSelector(laborRecordsSelectors.isRequestSuccess)
	const [socialLeave, setSocialLeave] = useState(null)
	const onClose = useCallback(() => {
		onToggleAction('view', false)
	}, [onToggleAction])
	const [actions, setActions] = useState({
		view: false,
	})
	const onToggleViewContractAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}
	const handleViewAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, viewAdditionalAgreement: false }))
	}, [])
	const handleViewSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, viewSocialLeave: false }))
	}, [])
	return (
		<>
			<Modal windowClassName={styles.modal} onClick={onClose}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					{isLoading ? (
						<Loading isLoading={isLoading} isSuccess={isSuccess}></Loading>
					) : (
						<ViewAdjustment
							onToggleContractAction={onToggleContractAction}
							onToggleViewContractAction={onToggleViewContractAction}
							onClose={onClose}
						></ViewAdjustment>
					)}
				</div>
			</Modal>
			{actions.view ? (
				<ViewContract
					setForceSubmitEdit={false}
					onToggleAction={onToggleViewContractAction}
					setSocialLeave={setSocialLeave}
				/>
			) : null}
			{actions.viewSocialLeave && (
				<SocialLeaveMain
					onToggleAction={onToggleAction}
					socialLeave={socialLeave}
					onClose={handleViewSocialLeaveModalClose}
					mode={'view'}
				/>
			)}
			{actions.viewAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'view'}
					onToggleAction={onToggleAction}
					onClose={handleViewAdditionalContractModalClose}
				/>
			)}
		</>
	)
}
