export const headCells = [
	{
		id: 'dateCreate',
		numeric: false,
		label: 'date_created',
	},
	{
		id: 'typeCode',
		numeric: false,
		label: 'profUnions.unionType',
	},
	{
		id: 'bin',
		numeric: true,
		label: 'bin',
	},
	{
		id: 'union_name',
		numeric: false,
		label: 'col_contract.union_name',
	},
	{
		id: 'count_manager',
		numeric: true,
		label: 'profUnions.count_manager',
	},
	{
		id: 'status',
		numeric: true,
		label: 'contracts_tab.status',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'col_contract.table.actions',
	},
]
