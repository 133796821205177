import { Button } from '@/components/labor'
import { ChevronLaborUpGray, ChevronLaborDownGray } from '@app/images'
import { Title } from '@/ui'
import styles from './Chevron.module.scss'
export const Chevron = ({ chevron = false, title = '', changeChevron }) => {
	return (
		<div className={styles.button}>
			<Button img={chevron ? ChevronLaborUpGray : ChevronLaborDownGray} onClick={changeChevron}>
				<div className={styles.marginRight}>
					<Title>{title}</Title>
				</div>
			</Button>
		</div>
	)
}
