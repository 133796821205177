import React from 'react'
import styles from './SystemEnbekPopup.module.scss'
import { getCurrentLanguage } from '@/i18next'
import enbekLogo from '@/assets/home/enbek-eco-logo.svg'
import skillsLogo from '@/assets/home/skills-eco-logo.svg'
import business from '@/assets/home/businnes-eco-logo.svg'
import career from '@/assets/home/career-eco-logo.svg'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import LinkComponent from '@features/systemEnbekPopup/LinkComponent'

export const SystemEnbekPopup = () => {
	const lang = getCurrentLanguage()
	const { refEnbek, refSkills, refBusiness, refCareer } = useEnbekEcoSystemLink(lang)

	return (
		<div className={styles.popup}>
			<LinkComponent
				href={refEnbek}
				logo={enbekLogo}
				alt="enbek"
				title="Enbek"
				description="actual_resumes_vacancies"
			/>
			<LinkComponent
				href={refSkills}
				logo={skillsLogo}
				alt="enbek"
				title="Enbek Skills"
				description="learning_skills"
			/>
			<LinkComponent
				href={refBusiness}
				logo={business}
				alt="enbek"
				title="Enbek Business"
				description="support_business"
			/>
			<LinkComponent href={refCareer} logo={career} alt="enbek" title="Enbek Career" description="career_road" />
		</div>
	)
}
