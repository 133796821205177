import React, { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDigitalSign, useToggle } from '@/shared'
import { Button } from '@/ui'
import { companyActions, companySelectors, companyThunks } from '@/entity/company'
import { companyActionsSelectors, companyActionsThunks } from '@/features/company'
import { userSelectors, userThunks } from '@/entity'
import { handbookThunks } from '@/entity/handbook'
import { ModalTemplate } from '@/ui'
import { CompanyForm } from '@features/employerProfile'
import styles from './AddCompany.module.scss'
import { getXmlForSign } from '@features/employerProfile/model/thunks'
import { LoadingBlocker } from '@/components/Loader'

export const AddCompany = ({ buttonClassName = '', border = true, transparent = true }) => {
	const dispatch = useDispatch()
	const company = useSelector(companySelectors.company)
	const isSuccess = useSelector(companySelectors.isSuccess)
	const xmlToSign = useSelector(companySelectors.xmlToSign)
	const isLoading = useSelector((state) => state.company.isLoading)
	const error = useSelector((state) => state.company.error)
	const user = useSelector(userSelectors.user)
	const options = useSelector(companyActionsSelectors.options)
	const [isOpenedAddCompany, toggleOpenAddCompany] = useToggle(false)
	const { getKeyInfoCall, onClearPayload, onSign, successPayload, ecpInfo, failedPayload } = useDigitalSign()
	const [formData, setFormData] = useState(null)
	const { t } = useTranslation()

	useEffect(() => dispatch(companyActionsThunks.getOptions()), [dispatch])

	useEffect(() => isSuccess && toggleOpenAddCompany(false), [isSuccess])

	useEffect(() => {
		if (failedPayload) {
			setFormData(null)
		}
	}, [failedPayload])

	useEffect(() => {
		if (error) {
			setFormData(null)
			onClearPayload()
			dispatch(getXmlForSign())
		}
	}, [error])

	useEffect(() => {
		if (isOpenedAddCompany) {
			dispatch(getXmlForSign())
			dispatch(companyActions.setInitialCompany({ email: company?.email ? company?.email : '' }))
		} else {
			onClearPayload()
			dispatch(companyActions.resetCreationXml())
		}
	}, [isOpenedAddCompany])

	/** Подписать xml перед отправкой данных на бэк */
	useEffect(() => {
		if (formData && xmlToSign) {
			onClearPayload()
			onSign(xmlToSign)
		}
	}, [formData])

	/** После подписания эцп, отправить все данные на бэк */
	useEffect(() => {
		if (successPayload && xmlToSign && formData) {
			dispatch(companyThunks.createCompany(formData, successPayload))

			setFormData(null)
			onClearPayload()
			dispatch(getXmlForSign())
		}
	}, [dispatch, formData, successPayload, xmlToSign])

	const onGetHandBookOptions = useCallback(
		(label, code) => {
			label === 'district' && dispatch(handbookThunks.getRegions({ code }))
			label === 'region' && dispatch(handbookThunks.getPopulatedAreas({ code }))
			label === 'bin' && dispatch(userThunks.getEmployerActivityByBin({ code }))
		},
		[dispatch]
	)
	const onChooseECP = useCallback(() => getKeyInfoCall(), [getKeyInfoCall])

	return (
		<div>
			{isLoading && <LoadingBlocker />}
			<Button
				onClick={toggleOpenAddCompany}
				transparent={transparent}
				border={border}
				buttonClassName={buttonClassName}
				disabled={user?.manager}
			>
				{t('add_company')}
			</Button>
			{isOpenedAddCompany && !company?.id && (
				<ModalTemplate onClose={toggleOpenAddCompany}>
					<div className={`scroll ${styles.wrapper}`}>
						<CompanyForm
							initialValues={company}
							onCancel={toggleOpenAddCompany}
							options={options}
							onSubmit={setFormData}
							onGetHandBookOptions={onGetHandBookOptions}
							onChooseECP={onChooseECP}
							ecpInformation={ecpInfo}
							isTitle
							isCreateMode
						/>
					</div>
				</ModalTemplate>
			)}
		</div>
	)
}
