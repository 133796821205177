import React, { useMemo } from 'react'
import styles from '@pages/PersonalAffairs/components/DetailedAffair/modules/ID/ID.module.scss'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails } from '@mui/material'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { Table } from './Table/Table'
import { headCells, getTableData } from './utils'
import moment from 'moment'

function WorkActivity({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const tableData = useMemo(() => getTableData(data.data.contractData), [data])
	const { t } = useTranslation()

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_007')}</p>
					</div>
				)}
				<p>
					<Table headCells={headCells} data={tableData} />
				</p>
			</AccordionDetails>
		</Accordion>
	)
}

export default WorkActivity
