import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	setDictVersion: (payload) => ({
		type: types.SET_DICT_VERSION,
		payload,
	}),
}
