import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TitleWithInformationData } from '@/ui/molecules'

export const ContractAdditionalInfo = ({ contract }) => {
	const { t } = useTranslation()
	const additionalInformation = useMemo(
		() => [
			{
				title: t('contracts_tab.professionals_skills'),
				data: contract?.specSkillsList,
			},
			{
				title: t('contracts_tab.personal_qualities'),
				data: contract?.skillsList,
			},
		],
		[contract, t]
	)

	return (
		<TitleWithInformationData
			hideSubTitle
			title={t('contracts_tab.additional_information')}
			information={additionalInformation}
			maxTitleWidth={'215px'}
			maxDataWidth={'1fr'}
		/>
	)
}
