import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Loader, LoadingBlocker } from '@/components/Loader'
import { useDispatch } from 'react-redux'
import { contractsThunks } from '@/entity'

import styles from './ChangeContractBinForm.module.scss'
import { DateWarningModal } from '@/entity/contracts/ui/changeBinForm/DateWarningModal'

const headCells = [
	{
		id: 'name',
		numeric: true,
		disablePadding: false,
		label: 'contract.info.td_number',
	},
	{
		id: 'calories',
		numeric: true,
		disablePadding: false,
		label: 'iin',
	},
	{
		id: 'fat',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.employee_fio',
	},
	{
		id: 'carbs',
		numeric: true,
		disablePadding: false,
		label: 'contract.info.position',
	},
	{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'contract.info.registration_date',
	},
	{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'terminate_date_contract',
	},
	/*	{
		id: 'protein',
		numeric: true,
		disablePadding: false,
		label: 'contract_status',
	},*/
]

function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
	const { t } = useTranslation()

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" classes={{ root: styles.table__head__row }}>
					<Checkbox
						color="default"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						classes={{
							root: styles.checkbox__head__root,
							checked: styles.checkbox__checked,
						}}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? 'none' : 'normal'}
						classes={{ root: styles.table__head__row }}
					>
						{t(headCell.label)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export const ChangeBinTable = ({ bin, date, rows, loading, xmlLoading, buttonPressed }) => {
	const { t } = useTranslation()
	const [selected, setSelected] = useState([])
	const [disableSend, setDisableSend] = useState(false)
	const [isOpenInfo, setIsOpenInfo] = useState(false)
	const [disableButton, setDisableButton] = useState(true)
	const [disableButtonAll, setDisableButtonAll] = useState(true)
	const dispatch = useDispatch()

	const getFormatDate = (value) => {
		const dateArr = value?.split('.')
		if (dateArr) {
			return `${dateArr?.[1]}.${dateArr?.[0]}.${dateArr?.[2]}`
		} else {
			return false
		}
	}

	useEffect(() => {
		setDisableSend(false)
		if (selected && selected.length > 0) {
			selected.map((item) => validateDate(item.dateFrom))
		} else {
			rows.map((item) => validateDate(item.dateFrom))
		}
	}, [selected, date, rows])

	useEffect(() => {
		if (!disableSend && date.length > 9 && selected.length >= 1) {
			setDisableButton(false)
		} else if (!disableSend && date.length > 9 && rows.length >= 1) {
			setDisableButtonAll(false)
		}
	}, [date, disableSend, rows.length, selected.length])

	const validateDate = (value) => {
		if (date.length < 9) {
			setDisableSend(true)
		} else {
			const dateCalendar = new Date(getFormatDate(date))
			const valueDate = new Date(getFormatDate(value))
			if (dateCalendar < valueDate) {
				setDisableSend(true)
			}
		}
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			return setSelected(rows)
		}
		setSelected([])
		setDisableButton(true)
	}

	const handleSelect = (row, isSelected) => {
		if (!isSelected) {
			const newSelected = selected.filter((item) => item.externalId != row.externalId)
			setSelected(newSelected)
		} else {
			setSelected([...selected, row])
		}
		setDisableButton(true)
	}

	const checkIsSelected = (externalId) => selected.findIndex((item) => item.externalId == externalId) !== -1

	const handleSendSelected = () => {
		if (!disableSend) {
			const data = {
				contractList: selected.map((item) => item.externalId),
				oldbin: bin,
				reorganizationDate: date,
			}
			dispatch(contractsThunks.getReorganizeXml(data))
		} else {
			setIsOpenInfo(true)
		}
	}

	const handleSendAll = () => {
		if (!disableSend) {
			const data = {
				contractList: rows.map((item) => item.externalId),
				oldbin: bin,
				reorganizationDate: date,
			}
			dispatch(contractsThunks.getReorganizeXml(data))
		} else {
			setIsOpenInfo(true)
		}
	}

	const handleReset = () => {
		setSelected([])
		setDisableButton(true)
	}

	return (
		<>
			{isOpenInfo && <DateWarningModal handleProcess={setIsOpenInfo.bind(null, false)} />}
			<Box sx={{ width: '100%' }}>
				{xmlLoading && <LoadingBlocker />}
				<TableContainer classes={{ root: styles.table__container }} style={{ maxHeight: 400 }}>
					<Table sx={{ minWidth: 800 }} aria-labelledby="tableTitle" size="medium" stickyHeader>
						<EnhancedTableHead
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={rows.length}
						/>
						<TableBody>
							{!!rows.length &&
								rows.map((row) => {
									const isItemSelected = checkIsSelected(row.externalId)
									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.externalId}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': `enhanced-table-checkbox-${row.externalId}`,
													}}
													classes={{ checked: styles.checkbox__checked }}
													onChange={(_, isSelected) => handleSelect(row, isSelected)}
												/>
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.contractNumber}
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.iin}
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.fullName}
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.establishedPost}
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.dateFrom}
											</TableCell>
											<TableCell classes={{ root: styles.table__body_cell }} align="left">
												{row.dateTo}
											</TableCell>
											{/*<TableCell align="left">{row.protein}</TableCell>*/}
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
					{loading ? (
						<div className={styles.empty__table__plug}>
							<Loader />
						</div>
					) : (
						<>
							{rows.length == 0 && !buttonPressed && (
								<div className={styles.empty__table__plug}>{t('contracts_tab.enter_predecessor_bin')}</div>
							)}
							{rows.length == 0 && bin.length != 0 && buttonPressed && (
								<div className={styles.empty__table__plug}>
									{t('employment_contracts')} <br /> {t('contracts_tab.not_found_in_ESUTD')}
								</div>
							)}
						</>
					)}
				</TableContainer>

				{!!rows.length && (
					<Box className={styles.buttons__wrapper} display="flex" alignItems="center" justifyContent="flex-end">
						<div className={styles.button} onClick={handleReset}>
							{t('reset_all')}
						</div>
						<div className={styles.button} onClick={handleSendSelected}>
							<Button disabled={disableButton}>{t('contracts_tab.sign_and_send')}</Button>
						</div>
						<div className={styles.button} onClick={handleSendAll}>
							<Button disabled={disableButtonAll} className={styles.button}>
								{t('contracts_tab.sign_and_send_all')}
							</Button>
						</div>
					</Box>
				)}
			</Box>
		</>
	)
}
