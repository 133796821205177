import React, { useLayoutEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import styles from './PdfViewer.module.scss'
const PdfViewer = ({ isPdfFile }) => {
	const ref = useRef(null)
	const [numPages, setNumPages] = useState(0)
	const [width, setWidth] = useState(0)
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	useLayoutEffect(() => {
		setWidth(ref.current.offsetWidth)
	}, [])

	const pages = []
	for (let pageNum = 1; pageNum <= numPages; pageNum++) {
		pages.push(
			<div key={pageNum}>
				<Page pageNumber={pageNum} width={width} className={styles.page} />
			</div>
		)
	}

	return (
		<div className={styles.container}>
			<div className={`${styles.pdfViewerModal} pdf-viewer-modal scroll`} ref={ref}>
				{isPdfFile && (
					<Document file={isPdfFile} onLoadSuccess={onDocumentLoadSuccess} loading="...">
						{pages}
					</Document>
				)}
			</div>
		</div>
	)
}
export default PdfViewer
