import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'
import { Provider } from 'react-redux'
import { store } from '@/app/store'
import { i18next } from './i18next'
import { BrowserRouter } from 'react-router-dom'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'

i18next.then(() => {
	if (process.env.NODE_ENV === 'development') {
	}
})
ReactDOM.render(
	<StrictMode>
		<BrowserRouter>
			<Suspense fallback={<div>Loading...</div>}>
				<Provider store={store}>
					<App />
				</Provider>
			</Suspense>
		</BrowserRouter>
	</StrictMode>,
	document.getElementById('root')
)
