import { memo } from 'react'
import { Divider, Rate } from 'antd'
import styles from './RatingCount.module.scss'
import cl from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'

/**
 * @component
 * @param {Object} props
 * @param {Array<number>} props.array
 * @param {Object} props.avgObject
 * @param {number} props.avgObject.count
 * @param {number} props.avgObject.mark
 *
 * @example
 * <RatingCount array={[1, 2, 3, 4, 5]} avgObject={{ count: 15, mark: 45 }} />
 */

export const RatingCount = memo(({array, avgObject}) => {
	const { t } = useTranslation()

	const { role, contract, reviews, currentReview } =
		useSelector((state) => ({
			role: userSelectors.activeRole(state),
			contract: contractActionsSelectors.contractView()(state),
			reviews: state.review.reviews,
			currentReview: state.review.currentReview,
		}))


	return (
		<div className={styles.root}>
			<div className={styles.count}>
				<div className={styles.count__content}>
					<span className={styles.count__title}>
						{t('reviews.mark')}:
					</span>

					{
						array.map((item, index) => (
							<Rate value={index + 1} key={index} disabled className={cl(styles.count__subtitle, styles.count__rating)} />
						))
					}
				</div>

				<div className={styles.count__content}>
					<span className={styles.count__title}>
					{t('reviews.count')}:
				</span>

					{array.map((item, index) => (
						<span key={index} className={styles.count__subtitle}>{item}</span>
					))}

				</div>
			</div>

			<div className={styles.divider} />

			<div className={styles.avg_content}>
				<span className={styles.avg_content__title}>
					{avgObject.mark}
				</span>
				<span className={styles.avg_content__subtitle}>
					{t('reviews.avg_ball')}
				</span>
				<span className={styles.avg_content__mark}>{t('reviews.marks')} ({avgObject.count})</span>
			</div>
		</div>
	)
})