import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { verificationSelector, verificationThunks } from '@/entity/verification'
import styles from './ResultSms.module.scss'
import { Button } from '@/ui'
import React from 'react'
import { LoadingBlocker } from '@/components/Loader'
import { CodeVerification } from './CodeVerification'
export const ResultSms = ({ iin, phone }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isLoading = useSelector(verificationSelector.isLoading)
	const isSuccessSend = useSelector(verificationSelector.isSuccessSend)
	const isErrorSend = useSelector(verificationSelector.isErrorSend)
	const getPhone = (iin) => {
		dispatch(verificationThunks.sendPhoneForIin({ iin: iin }))
	}
	return (
		<>
			{isLoading ? <LoadingBlocker /> : null}
			{isSuccessSend && iin ? (
				<CodeVerification iin={iin}></CodeVerification>
			) : (
				<div className={styles.sms_text}>
					{t('tel_bmg')}
					<div className={styles.tel}>{phone.phone}</div>
					{t('send_sms')}
					<div className={styles.button_send}>
						<Button type="button" onClick={() => getPhone(iin)}>
							{t('send')}
						</Button>
					</div>
					<div className={styles.error}>{isErrorSend && !isLoading ? t('error_bmg_service') : null}</div>
				</div>
			)}
		</>
	)
}
