import React, { useState, useEffect, useCallback } from 'react'
import { Button } from '@/ui'
import { Modal, ModalBackButton } from '@/components/Modal'
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import Grid from '@mui/material/Grid'
import { Cross, CompareArrowsIcon, Add } from '@/app/icons'
import moment from 'moment'
import { DataGridTable } from '@/ui'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'

export const TransferView = ({ onClose, sendSelected, transfer, rejectSelected, openAdditional, isIn }) => {
	const [selected, setSelected] = useState([])

	const [actionMenuItems, setActionMenuItems] = useState([])

	useEffect(() => {
		if (actionMenuItems.length === 0 && transfer.isIn) {
			setActionMenuItems([
				{
					title: (
						<Box display="flex" alignItems="center" className={styles.menu__item}>
							<Box display="flex" alignItems="center" justifyContent="center" className={styles.icon}>
								<CompareArrowsIcon fill="#5B6880" />
							</Box>{' '}
							<div>{t('send_contract')}</div>
						</Box>
					),
					action: 'send',
				},
				{
					title: (
						<Box display="flex" className={styles.menu__item}>
							<Box display="flex" alignItems="center" justifyContent="center" className={styles.icon}>
								<Cross fill="#EB5757" />
							</Box>{' '}
							<div className={styles.reject__title}>{t('reject_transfer')}</div>
						</Box>
					),
					action: 'reject',
				},
			])
		}

		if (actionMenuItems.length === 0 && !transfer.isIn) {
			setActionMenuItems([
				{
					title: (
						<Box display="flex" alignItems="center" className={styles.menu__item}>
							<Box display="flex" alignItems="center" justifyContent="center" className={styles.icon}>
								<Add fill="#5B6880" />
							</Box>{' '}
							<div>{t('create_additional_agreement')}</div>
						</Box>
					),
					action: 'createAdditionalAgreement',
				},
				{
					title: (
						<Box display="flex" className={styles.menu__item}>
							<Box display="flex" alignItems="center" justifyContent="center" className={styles.icon}>
								<Cross fill="#EB5757" />
							</Box>{' '}
							<div className={styles.reject__title}>{t('reject_transfer')}</div>
						</Box>
					),
					action: 'reject',
				},
			])
		}
	}, [actionMenuItems, transfer])

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const selectTransfer = (action, externalId) => {
		if (action) {
			setSelected([...selected, externalId])
		} else {
			setSelected(selected.filter((id) => id !== externalId))
		}
	}

	const selectAll = (event, action) => {
		if (action) {
			setSelected(transfer.contracts.map((contract) => contract.externalId))
		} else {
			setSelected([])
		}
	}

	const onActionMenuChoose = useCallback((mode, row) => {
		if (mode === 'send') sendSelected([row.externalId])
		if (mode === 'reject') rejectSelected([row.externalId])
		if (mode === 'createAdditionalAgreement') openAdditional(row)
	}, [])

	const headCells = [
		{
			id: 'contractNumber',
			numeric: true,
			disablePadding: false,
			label: t('contracts_tab.contract_number'),
		},
		{
			id: 'iin',
			numeric: true,
			disablePadding: false,
			label: t('iin'),
		},
		{
			id: 'fullName',
			numeric: true,
			disablePadding: false,
			label: t('employee_fio'),
		},
		{
			id: 'establishedPost',
			numeric: true,
			disablePadding: false,
			label: t('contracts_tab.position'),
		},
		{
			id: 'status',
			numeric: true,
			disablePadding: false,
			label: t('transfer_status'),
		},
		{
			id: 'action',
			numeric: true,
			disablePadding: false,
			label: t('contracts_tab.action'),
		},
	]

	return (
		<Modal windowClassName={styles.modal} onClose={onClose}>
			<div className={`scroll ${styles.modal__wrapper}`}>
				<ModalBackButton onClick={onClose} />
				<div className={styles.modal__title}>{t('transfer_request_title')}</div>
				<div className={styles.modal__sub__title}>
					{t('transfer_request_form_date')}: {moment(transfer.createDate).format('DD.MM.YYYY')}
				</div>

				<div className={styles.title__info__branch}>{t('branch_info')}</div>

				<Grid container classes={{ root: styles.branch__info__wrapper }}>
					<Grid itex xs={6}>
						<DataGridTable
							hideTitle
							subTitle={t('employer_from')}
							information={[
								{ title: t('bin'), data: transfer.toBin },
								{ title: t('name'), data: language === 'rus' ? transfer.toNameRu : transfer.toNameKz },
							]}
						/>
					</Grid>
					<Grid itex xs={6}>
						<DataGridTable
							hideTitle
							subTitle={t('employer_to')}
							information={[
								{ title: t('bin'), data: transfer.fromBin },
								{ title: t('name'), data: language === 'rus' ? transfer.fromNameRu : transfer.fromNameKz },
							]}
						/>
					</Grid>
				</Grid>

				<div className={styles.title__info}>{t('contracts_tab.employees_data')}</div>

				<Box sx={{ width: '100%' }}>
					<TableContainer classes={{ root: styles.table__container }}>
						<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox" classes={{ root: styles.table__head__row }}>
										<Checkbox
											color="default"
											checked={selected.length === transfer.contracts.length}
											onChange={selectAll}
											classes={{
												root: styles.checkbox__head__root,
												checked: styles.checkbox__checked,
											}}
											inputProps={{
												'aria-label': 'select all desserts',
											}}
											disabled={!isIn}
										/>
									</TableCell>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											align="left"
											padding={headCell.disablePadding ? 'none' : 'normal'}
											classes={{ root: styles.table__head__row }}
										>
											{t(headCell.label)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{!!transfer.contracts &&
									transfer.contracts.map((row, index) => {
										const checked = selected.includes(row.externalId)
										return (
											<TableRow hover role="checkbox" key={row.externalId}>
												<TableCell padding="checkbox">
													<Checkbox
														checked={checked}
														onChange={(e, checked) => selectTransfer(checked, row.externalId)}
														inputProps={{
															'aria-labelledby': `enhanced-table-checkbox-${index}`,
														}}
														classes={{ checked: styles.checkbox__checked }}
														disabled={!isIn}
													/>
												</TableCell>
												<TableCell align="left">{row.contractNumber}</TableCell>
												<TableCell align="left">{row.iin}</TableCell>
												<TableCell align="left">{row.fullName}</TableCell>
												<TableCell align="left">{row.establishedPost}</TableCell>
												<TableCell align="left">{language === 'rus' ? row.status.nameRu : row.status.nameKz}</TableCell>
												<TableCell align="left">
													<DropDownMenu
														title={t('select_pls')}
														items={actionMenuItems}
														onItemClick={(mode) => onActionMenuChoose(mode, row)}
														iconIsComponent
													/>
												</TableCell>
											</TableRow>
										)
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>

				{!!selected.length && isIn && (
					<Box className={styles.buttons__wrapper} display="flex" alignItems="center">
						<div className={styles.button__view} onClick={selectAll.bind(null, null, false)}>
							{t('reset_all')}
						</div>
						<div className={styles.button__view}>
							<Button onClick={sendSelected.bind(null, selected)}>{t('give_selected')}</Button>
						</div>
						<div className={styles.button__view}>
							<Button onClick={rejectSelected.bind(null, selected)}>{t('refuse_for_selected')}</Button>
						</div>
					</Box>
				)}
			</div>
		</Modal>
	)
}
