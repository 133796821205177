import React from 'react'

export const Save = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.9367 4.87422L15.1258 1.06328C14.95 0.8875 14.7344 0.758594 14.5 0.688281V0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V5.93359C19.375 5.53516 19.218 5.15547 18.9367 4.87422ZM7 2.3125H13V4.75H7V2.3125ZM17.6875 17.6875H2.3125V2.3125H5.5V5.5C5.5 5.91484 5.83516 6.25 6.25 6.25H13.75C14.1648 6.25 14.5 5.91484 14.5 5.5V2.82344L17.6875 6.01094V17.6875ZM10 8.35938C8.13672 8.35938 6.625 9.87109 6.625 11.7344C6.625 13.5977 8.13672 15.1094 10 15.1094C11.8633 15.1094 13.375 13.5977 13.375 11.7344C13.375 9.87109 11.8633 8.35938 10 8.35938ZM10 13.6094C8.96406 13.6094 8.125 12.7703 8.125 11.7344C8.125 10.6984 8.96406 9.85938 10 9.85938C11.0359 9.85938 11.875 10.6984 11.875 11.7344C11.875 12.7703 11.0359 13.6094 10 13.6094Z"
				fill="#17B67C"
			/>
		</svg>
	)
}
