import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './EditContractDataField.module.scss'
import { Chevron } from '@/ui/atoms/chevron'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { Button, Checkbox, Field, SelectSearch } from '@/ui'
import { AdditonalReasonSelect } from '@/components/AdditionalReasonSelect'
import { Add } from '@app/icons'
import { getCurrentLanguage } from '@/i18next'
export const TerminationField = connect(
	({
		saveTerminationReasons,
		terminationReasons = [],
		contractDate,
		getAdditionalReasons,
		viewTerminationReasons,
		isView,
		formik: { setFieldValue, values },
		isFormDirty,
	}) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()
		const [chevron, setChevron] = useState(false)
		const changeChevron = () => {
			chevron ? setChevron(false) : setChevron(true)
		}
		const title = useMemo(() => t('labor_records.contract_terminate_info'), [t])
		const stateTerminationReasonsIs = useMemo(() => !values.terminationReasonsIs, [values.terminationReasonsIs])
		const dateArr = contractDate?.split('.')
		const [additionalReasons, setAdditionalReasons] = useState([])
		useEffect(() => {
			if (saveTerminationReasons != null) {
				const newArr = saveTerminationReasons
				newArr.splice(0, 1)
				const codes = newArr.map((item) => item.code)
				setAdditionalReasons(codes)
			}
		}, [saveTerminationReasons])
		useEffect(() => {
			getAdditionalReasons(additionalReasons)
		}, [additionalReasons, getAdditionalReasons])
		const handleAddReasonClick = useCallback(() => {
			setAdditionalReasons((value) => [...value, null])
		}, [])
		const handleRemoveReasonClick = useCallback((index) => {
			setAdditionalReasons((value) => {
				const newArr = [...value]
				newArr.splice(index, 1)
				return newArr
			})
		}, [])
		const lastTerminationReasons = useMemo(() => terminationReasons.filter(({ last }) => !!last), [terminationReasons])

		return (
			<div className={styles.wrapper}>
				<Chevron chevron={chevron} title={title} changeChevron={changeChevron}></Chevron>
				{chevron ? (
					<div className={styles.gridTwoWrapper}>
						<div className={'flex-1'}>
							{isView ? null : (
								<FormikField name={formFieldNames.terminationDateIs}>
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('terminate_date')}
											onChange={() => setFieldValue(field.name, !field.value)}
										/>
									)}
								</FormikField>
							)}
							<FormikField name={formFieldNames.terminationDate}>
								{({ field, meta: { touched, error } }) => {
									return (
										<Field
											minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
											{...field}
											fieldType="datePicker"
											maxDate={new Date()}
											label={isView ? t('terminate_date') : null}
											disabled={!values.terminationDateIs || isView}
											error={touched && error}
										/>
									)
								}}
							</FormikField>
						</div>
						<div className={'flex-1'}>
							{isView ? (
								<>
									<div className={styles.mBottom5}>
										<label>{t('contract_termination_reason')}</label>
									</div>
									{viewTerminationReasons?.map((item, index) => (
										<div key={`additonal-resaons-${index}`} className={styles.mBottom}>
											<SelectSearch
												value={lang === 'rus' ? item.nameRu : item.nameKz}
												className="flex-1"
												disabled={isView}
											></SelectSearch>
										</div>
									))}
								</>
							) : (
								<>
									<FormikField name={formFieldNames.terminationReasonsIs}>
										{({ field }) => (
											<Checkbox
												{...field}
												label={t('contract_termination_reason')}
												hint={t('labor_records.hint_termination_reason')}
												onChange={() => setFieldValue(field.name, !field.value)}
											/>
										)}
									</FormikField>
									<div className={styles.selectCol}>
										<div className="flex">
											<FormikField name={formFieldNames.terminationReason}>
												{({ field, meta: { touched, error } }) => (
													<SelectSearch
														options={lastTerminationReasons}
														fieldType="select"
														{...field}
														className="flex-1"
														error={touched && error}
														disabled={stateTerminationReasonsIs || isView}
													/>
												)}
											</FormikField>
											<Button
												transparent
												onClick={handleAddReasonClick}
												buttonClassName={styles.button}
												disabled={stateTerminationReasonsIs}
											>
												<Add className={styles.iconGreen} />
												{t('add')}
											</Button>
										</div>
										{additionalReasons.map((item, index) => (
											<div key={`additonal-resaons-${index}`} className="flex">
												<AdditonalReasonSelect
													index={index}
													setAdditionalReasons={setAdditionalReasons}
													value={item}
													terminationReasons={terminationReasons}
													removeReason={handleRemoveReasonClick}
													stateTerminationReasonsIs={stateTerminationReasonsIs}
												/>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
				) : null}
			</div>
		)
	}
)
