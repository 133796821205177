import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Cross } from '@app/images'
import { Modal } from '@/components/Modal'
import { Button } from '@/ui'
import styles from './NotificatonDetails.module.scss'
import { useHistory } from 'react-router-dom'
import { etdCodesAndLocal, ekdContract } from '@features/notifications/modal/constants'
import { renderWithHTML } from '@/utils'

export const NotificatonDetails = ({ data, visible, onClose }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const [isVisible, setIsVisible] = useState(false)
	const [modalData, setModaldata] = useState(null)
	const history = useHistory()

	useEffect(() => {
		if (visible) {
			setModaldata(data)
		}
		setIsVisible(visible)
	}, [data, visible])

	const handleCloseModal = useCallback(() => {
		onClose(!!data?.readDate)
	}, [data, onClose])

	const handleOpenAdditional = () => {
		const result = etdCodesAndLocal.find((v) => v.code === modalData?.notifType.code)
		history.push(`/contracts`, { refValue: modalData.contractId, local: result.local })
		onClose()
	}

	const handleOpenTradeUnion = () => {
		history.push(`/my-unions`)
		onClose()
	}

	const handleOpenEkd = () => {
		history.push(`/col-contracts`, { refValue: modalData.contractId, openModal: true })
		onClose()
	}

	return (
		<>
			{isVisible ? (
				<Modal onCancel={handleCloseModal} windowClassName={styles.modal}>
					<div className={styles.modal_content}>
						<div className={styles.headerButtons}>
							<Button type="bare" onClick={handleCloseModal}>
								<div className={styles.cross}>
									<img src={Cross} alt="cross" />
								</div>
							</Button>
						</div>
						<div className={styles.modal_content_header}>{t('notification')}</div>
						<div className={styles.modal_content_notifType}>
							{(language === 'rus' ? modalData?.notifType?.nameRu : modalData?.notifType?.nameKz) || ''}
						</div>
						<div className={styles.modal_content_notif_date}>
							{modalData?.eventDate?.substring(0, 10)} {modalData?.eventDate?.substring(11, 16)}
						</div>
						<div className={`${styles.modal_content_notif_content} scroll`}>
							{(language === 'rus' ? renderWithHTML(modalData?.notifRu) : renderWithHTML(modalData?.notifKz)) || ''}
						</div>
						<div className={styles.modal_content_footer}>
							{modalData?.notifType?.code === '18' && (
								<div className={styles.modal_button} onClick={handleOpenAdditional}>
									<span>{t('notification_open_contract')}</span>
								</div>
							)}
							{modalData.contractId && etdCodesAndLocal.find((v) => v.code === modalData?.notifType?.code) && (
								<div className={styles.modal_button} onClick={handleOpenAdditional}>
									<span>{t('notification_open_etd')}</span>
								</div>
							)}
							{modalData?.notifType?.code === 'tradeunion.new' && (
								<div className={styles.modal_button} onClick={handleOpenTradeUnion}>
									<span>{t('notification_open_my_unions')}</span>
								</div>
							)}
							{modalData.contractId && ekdContract.find((v) => v.code === modalData?.notifType?.code) && (
								<div className={styles.modal_button} onClick={handleOpenEkd}>
									<span>{t('open_ecd')}</span>
								</div>
							)}
							<div className={styles.modal_button} onClick={handleCloseModal}>
								<span>{t('close')}</span>
							</div>
						</div>
					</div>
				</Modal>
			) : null}
		</>
	)
}
