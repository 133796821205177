import { branchActions } from '@/entity/branch'
import { api } from '@/shared'
import { message } from 'antd'

export const getDataByBin = (bin) => {
	return async (dispatch) => {
		try {
			dispatch(branchActions.toggleLoading(true))
			const response = await api.branch.getDataByBin(bin)
			dispatch(branchActions.branchName(response))
			dispatch(branchActions.toggleSuccess(true))
		} catch (e) {
			message.error(e?.response?.data?.message || 'Неизвестная ошибка')
			dispatch(branchActions.toggleLoading(false))
			dispatch(branchActions.toggleSuccess(false))
		} finally {
			dispatch(branchActions.toggleLoading(false))
			dispatch(branchActions.toggleSuccess(false))
		}
	}
}
