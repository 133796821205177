import { styled } from '@mui/system'
import TabUnstyled, { tabClasses } from '@mui/base/Tab'
import { buttonClasses } from '@mui/base/Button'

const Tab = styled(TabUnstyled)`
	font-family: 'Inter', sans-serif;
	color: #aab8d1;
	cursor: pointer;
	font-size: 18px;
	font-weight: 100;
	min-width: 280px;
	line-height: 110%;
	border: none;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	padding: 11px;

	@media screen and (max-width: 768px) {
		font-size: 12px;
		min-width: auto;
	}

	// &:hover {
	// }

	// &:focus {
	//   color: #AAB8D1;
	//   border-radius: 3px;
	//   outline-offset: 2px;
	// }

	&.${tabClasses.selected} {
		color: #1d293f;
		border-bottom: 1px solid black;
	}

	&.${buttonClasses.disabled} {
		//   opacity: 0.5;
		cursor: not-allowed;
	}
`
export default Tab
