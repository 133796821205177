import { useTranslation } from 'react-i18next'
import { ConfirmModal, Icon, Title } from '@/ui'
import styles from './DateWarningModal.module.scss'
import { Warn } from '@app/images'

export const DateWarningModal = ({ handleProcess }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal onProcess={handleProcess} processButtonTitle={'modal_info.cancel_text'} hideBackButton priority={2}>
			<div className={styles.content}>
				<Icon src={Warn} />
				<Title>{t('contracts_tab.warning_info')}</Title>
			</div>
		</ConfirmModal>
	)
}
