import React from 'react'
import { ModalTemplate } from '@/ui'
import styles from '@features/contracts/registrationEmployerContract/form/style.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import { CollectiveContractForm } from '@/entity/collectiveContracts/ui/form/CollectiveContractForm'

export const Form = ({ initialValues, options = {}, onCancel, onSubmit, isEdit }) => {
	return (
		<div>
			<ModalTemplate onClose={onCancel}>
				<div className={`scroll ${styles.wrapper}`}>
					{options?.workTimeCollectiveContract?.length > 0 && options?.relaxTimeCollectiveContract?.length > 0 ? (
						<CollectiveContractForm
							isTitle
							initialValues={initialValues}
							options={options}
							onCancel={onCancel}
							onSubmit={onSubmit}
							isEdit={isEdit}
						></CollectiveContractForm>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
			</ModalTemplate>
		</div>
	)
}
