import { object, string, array, date } from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

const VictimValidationSchema = object().shape({
	iin: string()
		.nullable()
		.when('isResident', {
			is: true, // выполнить проверку, если isResident === true
			then: (schema) =>
				schema
					.matches(/^\d+$/, 'Недопустимый символ(ы)')
					.min(12, { key: 'min_length', value: 12 })
					.max(12, { key: 'max_length', value: 12 })
					.required({ key: 'required' }),
		}),
	surname: string()
		.nullable()
		.when('isResident', {
			is: true, // выполнить проверку, если isResident === true
			then: (schema) => schema.required({ key: 'required' }),
		}),
	secondname: string()
		.nullable()
		.when('isResident', {
			is: true, // выполнить проверку, если isResident === true
			then: (schema) => schema.required({ key: 'required' }),
		}),
	firstname: string()
		.nullable()
		.when('isResident', {
			is: true, // выполнить проверку, если isResident === true
			then: (schema) => schema.required({ key: 'required' }),
		}),
	birthDate: transformStringDateFormatToDate('dd.MM.yyyy')
		.nullable()
		.when('isResident', {
			is: false,
			then: (schema) => schema.required({ key: 'required' }),
		})
		.max(new Date(), { key: 'date.can_not_be_after_now' }),
	gender: string().required({ key: 'required' }).nullable(),
	passportNumber: string()
		.nullable()
		.when('isResident', {
			is: false, // выполнить проверку, если isResident === false
			then: (schema) => schema.required({ key: 'required' }),
		}),
	issuredBy: string()
		.nullable()
		.when('isResident', {
			is: false, // выполнить проверку, если isResident === false
			then: (schema) => schema.required({ key: 'required' }),
		}),
	issuredWhen: transformStringDateFormatToDate('dd.MM.yyyy')
		.nullable()
		.when('isResident', {
			is: false,
			then: (schema) => schema.required({ key: 'required' }),
		})
		.max(new Date(), { key: 'date.can_not_be_after_now' }),
	positionCode: string().required({ key: 'required' }).nullable(),
	establishedPost: object()
		.shape({
			nameRu: string().nullable(),
			nameKz: string().nullable(),
		})
		.test('nameRuOrNameKz', 'Укажите должность', (value) => {
			return value.nameRu || value.nameKz
		}),
	diagnosis: string().required({ key: 'required' }).nullable(),
})

const AccidentReportFormValidationSchema = object().shape({
	location: string().required({ key: 'required' }).nullable(),
	accidentDateTime: transformStringDateFormatToDate('dd.MM.yyyy HH:mm:ss')
		.required({ key: 'required' })
		.nullable()
		.max(new Date(), { key: 'date.can_not_be_after_now' }),
	region: string().required({ key: 'required' }).nullable(),
	district: string().required({ key: 'required' }).nullable(),
	circumstance: string().required({ key: 'required' }).nullable(),
	senderIin: string()
		.matches(/^\d+$/, 'Недопустимый символ(ы)')
		.min(12, { key: 'min_length', value: 12 })
		.max(12, { key: 'max_length', value: 12 })
		.required({ key: 'required' }),
	senderFio: string().required({ key: 'required' }).nullable(),
	senderEstablisedPost: object()
		.shape({
			nameRu: string().nullable(),
			nameKz: string().nullable(),
		})
		.test('nameRuOrNameKz', 'Укажите должность', (value) => {
			return value.nameRu || value.nameKz
		}),
	injureds: array().of(VictimValidationSchema),
})
export const AccidentReportValidationSchema = AccidentReportFormValidationSchema
