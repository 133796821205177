import { useState } from 'react'

export const useToggle = (initialValue = false) => {
	const [isOpen, setIsOpen] = useState(initialValue)

	const toggleValue = (value = null) => {
		setIsOpen((prevState) => (value !== null && typeof value !== 'object' ? value : !prevState))
	}

	return [isOpen, toggleValue]
}
