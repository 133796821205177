import { Add as AddIcon, View as ViewIcon } from '@app/images'

const onStaffingActionMenuClick = (row, e, setActionMenuItems, t) => {
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [
		{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
		{ icon: AddIcon, title: t('actions.appoint'), action: 'create' },
	]
	setActionMenuItems(actions)
}

export default onStaffingActionMenuClick
