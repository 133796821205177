import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { Preloader } from '@/ui'
import { companySelectors, companyThunks, companyActions } from '@/entity/company'
import { userThunks } from '@/entity/user'
import { companyActionsSelectors, companyActionsThunks } from '@/features/company'
import { handbookThunks } from '@/entity/handbook'
import { CompanyForm } from '@/features'
import { getXmlForSign } from '../model/thunks'

export const EditCompany = ({ tabControlRef }) => {
	const [formData, setFormData] = useState(null)
	const dispatch = useDispatch()
	const company = useSelector(companySelectors.company)
	const xmlToSign = useSelector(companySelectors.xmlToSign)
	const options = useSelector(companyActionsSelectors.options)
	const isLoading = useSelector((state) => state.company.isLoading)
	const error = useSelector((state) => state.company.error)
	const { getKeyInfoCall, successPayload, onSign, ecpInfo, onClearPayload, failedPayload } = useDigitalSign()

	useEffect(() => {
		dispatch(getXmlForSign())
	}, [dispatch])

	useEffect(() => {
		dispatch(companyActionsThunks.adjustCompanyOptions())
	}, [dispatch])

	useEffect(() => {
		if (error) {
			setFormData(null)
			onClearPayload()
			dispatch(getXmlForSign())
		}
	}, [error])

	useEffect(() => {
		/**
		 * Подписать xml перед отправкой данных на бэк
		 */
		if (formData && xmlToSign) {
			onSign(xmlToSign)
		}
	}, [formData, onSign, xmlToSign])

	useEffect(() => {
		if (failedPayload) {
			setFormData(null)
		}
	}, [failedPayload])

	useEffect(() => {
		/**
		 * После подписания эцп, отправить на все данные на бэк
		 */
		if (successPayload && xmlToSign && formData) {
			dispatch(companyThunks.editCompany(formData, successPayload))
			setFormData(null)
			onClearPayload()
			dispatch(getXmlForSign())
		}
	}, [dispatch, formData, successPayload, xmlToSign])

	const onChooseECP = () => {
		getKeyInfoCall()
	}

	const onGetHandBookOptions = useCallback(
		(label, code) => {
			label === 'district' && dispatch(handbookThunks.getRegions({ code }))
			label === 'region' && dispatch(handbookThunks.getPopulatedAreas({ code }))
			label === 'bin' && dispatch(userThunks.getEmployerActivityByBin({ code }))
		},
		[dispatch]
	)

	const onCancel = () => {
		tabControlRef?.current?.onChangeActiveTab('organisation_data')
		dispatch(companyActionsThunks.adjustCompanyOptions())
	}
	return company ? (
		<CompanyForm
			onSubmit={setFormData}
			initialValues={company}
			onGetHandBookOptions={onGetHandBookOptions}
			onChooseECP={onChooseECP}
			ecpInformation={ecpInfo}
			options={options}
			onCancel={onCancel}
		/>
	) : (
		<Preloader />
	)
}
