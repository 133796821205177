import { actions } from '@/entity/contracts/model/actions'

export const updateInitialContract = () => {
	return (dispatch, getState) => {
		const state = getState()
		const user = state.user.user
		const company = state.company.companies[0]
		dispatch(actions.setContractFields({ bin: user?.iin, companyName: company?.companyName }))
	}
}
