import React, { useEffect, useState } from 'react'
import { DialogTitle, DialogContent, Dialog, Button, Box } from '@mui/material'
import { Button as DialogButton } from '@/ui'
import styles from '../ContractCard.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { request } from '@/shared/api/request'
import { useDigitalSign } from '@/shared'
import { LoadingBlocker } from '@/components/Loader'
import CloseButton from '@/components/CloseButton/CloseButton'
import Timer from './Timer/Timer'
import { useDispatch, useSelector } from 'react-redux'
import { contractsSelectors, contractsThunks, contractsActions } from '@/entity'
import { message } from 'antd'

export const EmployeeSignModal = ({ onClose, updateContract, isOpen, contract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()
	const [templatesLoading, setTemplatesLoading] = useState(false)
	const [qr, setQr] = useState(false)
	const [qrTimer, setQrTimer] = useState(null)
	const [qrStatusInterval, setQrStatusInterval] = useState(null)
	const { successPayload, onClearPayload, onSignMultiple } = useDigitalSign()
	const errorEtd = useSelector(contractsSelectors.errorEtd)
	const image = useSelector(contractsSelectors.imageEtd)
	const isLoading = useSelector(contractsSelectors.isLoadingEtd)
	const isSuccess = useSelector(contractsSelectors.isSuccessEtd)
	const status = useSelector(contractsSelectors.statusEtd)
	const [statusSign, setStatusSign] = useState(null)
	const imageKey = useSelector(contractsSelectors.imageKey)

	useEffect(() => {
		return () => {
			//удаляется, прерываем интервала
			if (!qr) clearInterval(qrStatusInterval)
		}
	}, [qrStatusInterval, qr])
	const resetDataQr = () => {
		setQr(false)
		dispatch(contractsActions.resetEtdQR())
	}

	useEffect(() => {
		if (status && status?.code !== 'no_data') {
			setStatusSign(language === 'rus' ? status.name_ru : status.name_kz)
		}
	}, [status])

	useEffect(() => {
		if (isOpen) {
			setQr(false)
			setStatusSign(null)
		}
	}, [isOpen])

	useEffect(() => {
		let intervalId
		if (qr && imageKey) {
			intervalId = setInterval(() => {
				dispatch(contractsThunks.getStatusQrSign(imageKey))
			}, 10000)
			setQrStatusInterval(intervalId)
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}
	}, [qr, imageKey])

	useEffect(() => {
		if (isSuccess) {
			updateContract(true)
			resetDataQr()
			onClose()
		}
	}, [isSuccess])

	useEffect(() => {
		if (successPayload) sendSignedEtd(successPayload)
	}, [successPayload])

	const handleSign = async () => {
		try {
			setTemplatesLoading(true)
			const res = await request({
				url: '/contract/econtract/employee/xml',
				method: 'GET',
				params: {
					externalId: contract.externalId,
				},
			})
			onSignMultiple(res)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setTemplatesLoading(false)
		}
	}

	const sendSignedEtd = async (xml) => {
		try {
			setTemplatesLoading(true)
			await request({
				url: '/contract/econtract/employee/saveSign',
				method: 'POST',
				data: xml,
			})
			updateContract(true)
			onClearPayload()
			message.success(t('success_alert'))
			onClose()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setTemplatesLoading(false)
		}
	}

	const handleSignQr = async (qrTimer) => {
		try {
			setTemplatesLoading(true)
			setStatusSign(null)
			dispatch(contractsThunks.getImageQr(contract.externalId, 'ec_employee'))
			setQr(true)
			// Если таймер уже существует, отменяем его
			if (qrTimer) {
				clearTimeout(qrTimer)
			}
			// Устанавливаем новый таймер для сброса состояния qr через 1 минуту (60000 миллисекунд)
			const newQrTimer = setTimeout(() => {
				setQr(false)
				setStatusSign(t('time_out'))
			}, 60000) // 60000 миллисекунд = 1 минута
			setQrTimer(newQrTimer) // Сохраняем идентификатор нового таймера в состоянии
		} catch (error) {
		} finally {
			setTemplatesLoading(false)
		}
	}

	return (
		<Dialog classes={{ paper: styles.sign }} onClose={onClose} open={isOpen}>
			{templatesLoading || isLoading ? <LoadingBlocker /> : null}
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: styles.sign__title }}>
				{qr ? (
					<>
						{t('text_qr_sign_employee1')} <br />
						{t('text_qr_sign_employee2')} <br />
						{t('text_qr_sign_employee3')}
					</>
				) : (
					t('text_edt_sign_employee')
				)}
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={styles.dialog__buttons__wrapper}>
					{!qr ? (
						<div className={styles.reset__button__wrapper}>
							<div className={styles.flex_button}>
								<div className={styles.margin_button}>
									<DialogButton type="button" onClick={handleSign}>
										{t('sign_button_ecp')}
									</DialogButton>
								</div>
								<div className={styles.margin_button}>
									<DialogButton type="button" onClick={handleSignQr.bind(null, qrTimer)}>
										{t('sign_button_qr')}
									</DialogButton>
								</div>
							</div>
						</div>
					) : null}
					{qr && contract?.externalId && !isLoading ? (
						<>
							{errorEtd ? (
								<div className={styles.error__qr}>
									{errorEtd === 'was_signed' ? t('error_was_signed') : t('error_qr')}
								</div>
							) : (
								<>
									<Timer qr={qr}></Timer>
									<img src={image} alt={'QR Code'} />
								</>
							)}
						</>
					) : (
						<div className={styles.error__qr}>{statusSign}</div>
					)}
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button) }}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
