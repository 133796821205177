import styles from './PersonalData.module.scss'
import { useTranslation } from 'react-i18next'
import { Button, DataGridTable } from '@/ui'
import React, { useMemo } from 'react'
import { getColumns } from '@pages/PersonalAffairs/components/PersonalAffairCard/Info/utils'

export const PersonalData = ({ data, action, isDownload = false, avatar, isAllData, selectedMethod }) => {
	const { t } = useTranslation()
	const info = useMemo(() => getColumns(t, data?.personData), [])

	return (
		<div className={styles.personalData}>
			<DataGridTable
				hideSubTitle
				title={t('personal_affairs.card.title')}
				information={info}
				titleClass={styles.tableTitleCell}
			/>
			<img className={styles.personalData__img} src={avatar ? `data:image/jpeg;base64,${avatar}` : null} alt="" />
			<Button onClick={() => action(isAllData ? '' : selectedMethod || '')}>
				{isDownload
					? `${t('personal_affairs.card.table.headers.download')} PDF`
					: t('personal_affairs.card.info.label')}
			</Button>
		</div>
	)
}
