import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { reviewActions, reviewThunks } from '@/entity/review'
import { userSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'
import { styled } from '@mui/system'
import TabsUnstyled, { tabsClasses } from '@mui/base/Tabs'
import TabPanelUnstyled from '@mui/base/TabPanel'
import TabsListUnstyled from '@mui/base/TabsList'
import Tab from '@/ui/atoms/tab'
import { HrPagination } from '@/components/Pagination'
import { LoadingBlocker } from '@/components/Loader'
import { ContractReviewsItem, RatingCount, ReviewDeleteModal, ReviewEditModal } from '@/features'
import styles from './Reviews.module.scss'
import { getCurrentLanguage } from '@/i18next'

const Tabs = styled(TabsUnstyled)({})

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	max-width: 90vw;
	margin: 20px 34px 0 0;
	font-family: 'Inter', sans-serif;
	font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
	width: fit-content;
	max-width: 90vw;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	height: 41px;
`

const REVIEW_KEY = {
	ROLE_EMPLOYER: {
		MY: 'employerReview',
		ABOUT_ME: 'employeeReview',
	},
	ROLE_EMPLOYEE: {
		MY: 'employeeReview',
		ABOUT_ME: 'employerReview',
	},
}

const queryInitialState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const actionInitialState = {
	addReview: false,
	editReview: false,
	deleteReview: false,

	addAnswer: false,
	editAnswer: false,
	deleteAnswer: false,

	deleteReviewWithAnswer: false,
}

export const Reviews = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const [queryParams, setQueryParams] = useState(queryInitialState)

	const { role, contract, reviews, currentReview, review } = useSelector((state) => ({
		role: userSelectors.activeRole(state),
		contract: contractActionsSelectors.contractView()(state),
		reviews: state.review.reviews,
		currentReview: state.review.currentReview,
		review: state.review.review,
	}))

	const data =
		useMemo(() => {
			return (
				role && [
					{
						key: REVIEW_KEY?.[role]?.MY,
						label: t('reviews.my_reviews'),
					},
					{
						key: REVIEW_KEY?.[role]?.ABOUT_ME,
						label: t('reviews.reviews_about_me'),
					},
				]
			)
		}, [role, t]) || []

	const [activeTab, setActiveTab] = useState(null)
	const [actions, setActions] = useState(actionInitialState)

	useEffect(() => {
		if (role) {
			setActiveTab(REVIEW_KEY?.[role]?.MY)
			getReview(REVIEW_KEY?.[role]?.MY, queryParams)
		}
	}, [role])

	const getReview = (type, params) => {
		let currentType = {
			ROLE_EMPLOYER: 'employer',
			ROLE_EMPLOYEE: 'employee',
		}

		let body = {
			type: currentType?.[role],
			params: {
				page: params?.page,
				size: params?.pageSize,
				reviewType: type,
			},
		}

		dispatch(reviewThunks.getReview(body))
	}

	const handleChangeTab = (e, newValue) => {
		setActiveTab(newValue)

		getReview(newValue, queryParams)
	}

	const handleChangePage = (e, page) => {
		setQueryParams({ ...queryParams, page: page - 1 })

		let body = {
			...queryParams,
			page: page - 1,
		}

		getReview(activeTab, body)
	}
	const handleChangeRowsPerPage = (size) => {
		setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

		let body = {
			...queryParams,
			page: 0,
			pageSize: size,
		}

		getReview(activeTab, body)
	}

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const handleCloseModal = () => {
		setActions(actionInitialState)
	}


	/**
	 * функция сабмит для отзыва, собирает данные с формы и отправляется на бекенд
	 *
	 * @param rootReview обьект с данными
	 * @param onSuccess функция при успешном возврате данных с бекенда
	 * @param rootReview.key {modalViewType, formActionType, item} modalViewType = {review | answer}, formActionType = {add | edit}, item = элемент массива
	 * @param rootReview.values кастомный обьект
	 */

	const handleSubmitReviewForm = (rootReview, onSuccess) => {
		let type = {
			add: {
				review: {},
				answer: {
					parentExternalId: rootReview.key.item.review?.externalId,
				},
			},
			edit: {
				review: {
					externalId: rootReview.key.item.review?.externalId
				},
				answer: {
					externalId: rootReview.key.item.answer?.externalId,
					parentExternalId: rootReview.key.item.review?.externalId,
				}
			},
		}

		let body = {
			data: {
				...(rootReview.values.rating ? { rating: rootReview.values.rating } : {}),
				...(rootReview.values.reviewText ? { reviewText: rootReview.values.reviewText } : {}),
				iin: rootReview.values.iin,
				bin: rootReview.values.bin,
				reviewerType: rootReview.values.reviewerType,
				contractExternalId: rootReview.values?.contractExternalId,

				...type[rootReview.key.formActionType][rootReview.key.modalViewType]
			},
			onSuccess: onSuccess,
		}

		dispatch(reviewThunks.setReviewXml(body))
	}

	const handleSuccessReviewForm = (item) => {
		getReview(activeTab, queryParams)
	}

	const handleDeleteReviewForm = (values, onSuccess) => {
		let reviewType = {
			ROLE_EMPLOYEE: 'employeeDelete',
			ROLE_EMPLOYER: 'employerDelete',
		}

		let externalId = {
			ROLE_EMPLOYEE: {
				employerReview: 'answer',
				employeeReview: 'review',
			},
			ROLE_EMPLOYER: {
				employerReview: 'review',
				employeeReview: 'answer',
			},
		}

		let body = {
			data: {
				externalId: values[externalId[role][values.reviewTypeCode]].externalId,
				reviewType: reviewType[role],
			},
			onSuccess: onSuccess,
		}

		dispatch(reviewThunks.deleteReviewXml(body))
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={'reviews.title'}>
					<Tabs value={activeTab} onChange={handleChangeTab}>
						<TabsList>
							{data.map((item) => (
								<Tab value={item?.key} key={item?.key} id={item.key}>
									{item?.label}
								</Tab>
							))}
						</TabsList>

						{data.map((item) => (
							<TabPanel key={item.key} value={item.key}>
								<div className={styles.wrapper}>
									{(reviews?.data && activeTab === REVIEW_KEY?.[role]?.ABOUT_ME) && (
										<div className={styles.ratingContainer}>
											<RatingCount array={[
												reviews?.data?.oneCount,
												reviews?.data?.twoCount,
												reviews?.data?.threeCount,
												reviews?.data?.fourCount,
												reviews?.data?.fiveCount,
											]} avgObject={{
												mark: reviews?.data?.avgReviewRating,
												count: reviews?.data?.totalCount,
											}}/>
										</div>
									)}

									{reviews?.data?.page?.content?.map((review, index) => {
										let title = {
											ROLE_EMPLOYER: {
												employeeReview: review?.review?.employeeFio,
												employerReview: review?.review?.employeeFio,
											},
											ROLE_EMPLOYEE: {
												employeeReview: lang === 'rus' ? review?.review?.orgNameRu : review?.review?.orgNameKz,
												employerReview: lang === 'rus' ? review?.review?.orgNameRu : review?.review?.orgNameKz,
											},
										}

										return (
											<ContractReviewsItem
												onToggleAction={onToggleAction}
												item={review}
												key={`${review.contractExternalId}-${index}`}
												title={title[role][activeTab]}
											/>
										)
									})}
								</div>
							</TabPanel>
						))}
					</Tabs>
					<div style={{ margin: '36px 34px 0 0' }}>
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={queryParams.rowsPerPage}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={reviews?.data?.page?.numberOfElements}
							totalElements={reviews?.data?.page?.totalElements}
							totalPages={reviews?.data?.page?.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</div>

					{actions.addReview && (
						<ReviewEditModal
							onClose={handleCloseModal}
							modalViewType="review"
							formActionType="add"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleSubmitReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}

					{actions.editReview && (
						<ReviewEditModal
							onClose={handleCloseModal}
							modalViewType="review"
							formActionType="edit"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleSubmitReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}

					{actions.deleteReview && (
						<ReviewDeleteModal
							onClose={handleCloseModal}
							modalViewType="review"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleDeleteReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}

					{actions.addAnswer && (
						<ReviewEditModal
							onClose={handleCloseModal}
							modalViewType="answer"
							formActionType="add"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleSubmitReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}

					{actions.editAnswer && (
						<ReviewEditModal
							onClose={handleCloseModal}
							modalViewType="answer"
							formActionType="edit"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleSubmitReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}

					{actions.deleteAnswer && (
						<ReviewDeleteModal
							onClose={handleCloseModal}
							modalViewType="answer"
							item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
							onSubmit={handleDeleteReviewForm}
							onSuccess={handleSuccessReviewForm}
						/>
					)}
				</ProfileTemplate>
			</RedirectUnAuthUser>

			{(reviews.loading || review.loading) && <LoadingBlocker />}
		</>
	)
}
