import React from 'react'
import { ProfileTemplate } from '@/widgets'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/Tabs'
import TabsListUnstyled from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanelUnstyled from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import AffairsTab from '@pages/EmployeesPersonalFiles/EmployeeTabs/AffairsTab/AffairsTab'
import EmployeeRequestsTab from '@pages/EmployeesPersonalFiles/EmployeeTabs/RequestsTab/EmployeeRequestsTab'
import { RedirectUnAuthUser } from '@/processes'
import PreHeader from '@/components/home/components/PreHeader'

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	max-width: 90vw;
	margin: 32px 34px 0 0;
	font-family: 'Inter', sans-serif;
	font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
	width: fit-content;
	max-width: 90vw;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	height: 41px;
`
const EmployeesPersonalFilesPage = () => {
	const { t } = useTranslation()
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={'employee_affairs.tabs.first.title'}>
					<TabsUnstyled defaultValue={0}>
						<TabsList>
							<Tab value={0}>{t('employee_affairs.tabs.first.title')}</Tab>
							<Tab value={1}>{t('employee_affairs.tabs.second.title')}</Tab>
						</TabsList>
						<TabPanel value={0}>
							<AffairsTab />
						</TabPanel>
						<TabPanel value={1}>
							<EmployeeRequestsTab />
						</TabPanel>
					</TabsUnstyled>
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}

export default EmployeesPersonalFilesPage
