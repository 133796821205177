import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import styles from './StaffingFirstStructure.module.scss'
import { Button, Field, Title } from '@/ui'
import { Add, Trash } from '@app/icons'

export const Form = ({ onClose, staffType, actions }) => {
	const { t } = useTranslation()
	const { values, setFieldValue } = useFormikContext()

	const addStructuralUnit = () => {
		setFieldValue('units', [
			...values.units,
			{
				subsidiary: false,
				binBranch: '',
				deptTypeCode: '',
				dept: {
					nameKz: '',
					nameRu: '',
					code: '',
				},
			},
		])
	}

	const removeUnits = (index) => {
		const updatedUnits = [...values.units]
		updatedUnits.splice(index, 1)
		setFieldValue('units', updatedUnits)
	}
	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<Title>{t('staff_modal.staff_information')}</Title>
					</div>
					{values.units.map((unit, unitIndex) => (
						<React.Fragment key={unitIndex}>
							<div className={`${styles.fields} ${styles.threeFields}`}>
								{unit.subsidiary ? (
									<FormikField name={`units.${unitIndex}.binBranch`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="number"
												hideNumberArrows
												label={t('staff_modal.bin')}
												placeholder={t('staff_modal.bin_placeholder')}
												isRequired
												error={touched && error}
												{...field}
												maxLength={12}
											/>
										)}
									</FormikField>
								) : (
									<FormikField name={`units.${unitIndex}.deptTypeCode`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												fieldType="select"
												label={t('staff_modal.structure_type')}
												placeholder={t('staff_modal.structure_type_placeholder')}
												isRequired
												error={touched && error}
												{...field}
												options={staffType}
											/>
										)}
									</FormikField>
								)}
							</div>
							<FormikField name={`units.${unitIndex}.dept.nameKz`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										isRequired={(unit.dept.nameRu?.length || unit.dept.nameKz?.length) <= 1}
										label={t('staff_modal.structure_name_kz')}
										placeholder={t('staff_modal.structure_name_placeholder_kz')}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
							<FormikField name={`units.${unitIndex}.dept.nameRu`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										isRequired={(unit.dept.nameRu?.length || unit.dept.nameKz?.length) <= 1}
										label={t('staff_modal.structure_name')}
										placeholder={t('staff_modal.structure_name_placeholder')}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
							{actions.create
								? unitIndex > 0 && (
										<Button
											transparent
											onClick={() => removeUnits(unitIndex)}
											buttonClassName={styles.buttonRed}
											disabled={false}
										>
											<Trash className={styles.iconRed} />
											{t('remove')}
										</Button>
								  )
								: null}
						</React.Fragment>
					))}
					{actions.create ? (
						<Button transparent onClick={addStructuralUnit} buttonClassName={styles.button}>
							<Add className={styles.iconGreen} />
							{t('staff_modal.structure_add')}
						</Button>
					) : null}
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
