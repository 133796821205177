import React, { useMemo, useState } from 'react'
import styles from './MobileAppButton.module.scss'
import { useTranslation } from 'react-i18next'
import { Close8 } from '@app/images'
import icon from '../../../assets/home/banner-ico.svg'
import { getCurrentLanguage } from '@/i18next'

const MobileAppButton = ({ setAppButton }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const isMobile = navigator.userAgent.match(/iPhone|iPad|iPod/i)
	const [googlePlayLink, setGooglePlayLink] = useState()
	const [appStoreLink, setAppStoreLink] = useState()
	const [availableTxt, setAvailableTxt] = useState('')
	useMemo(() => setGooglePlayLink(process.env.REACT_APP_MOBILE_BANNER_GOOGLE), [])
	useMemo(() => setAppStoreLink(process.env.REACT_APP_MOBILE_BANNER_APPLE), [])
	const closeAppButton = () => {
		setAppButton(false)
	}

	const downloadLink = isMobile ? appStoreLink : googlePlayLink
	useMemo(() => setAvailableTxt(isMobile ? 'App Store' : 'Google Play'), [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.close}>
				<img onClick={closeAppButton} src={Close8} className={styles.closeIcon} alt="close_icon"></img>
			</div>
			<div className={styles.iconWrapper}>
				<img src={icon} className={styles.icon}></img>
				<div className={styles.text}>
					<p>Enbek HR - {t('portal_employment_contracts')}</p>
					{lang === 'rus' ? (
						<p className={styles.available}>
							{t('available')} {availableTxt}
						</p>
					) : (
						<p className={styles.available}>
							{availableTxt} {isMobile ? t('available') : t('available_google')}
						</p>
					)}
				</div>
			</div>
			<div className={styles.greenBtn}>
				<a href={downloadLink}>
					<button>{t('notification_open')}</button>
				</a>
			</div>
		</div>
	)
}

export default MobileAppButton
