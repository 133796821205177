import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { styled } from '@mui/system'
import TabPanelUnstyled from '@mui/base/TabPanel'
import TabsUnstyled from '@mui/base/Tabs'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import * as React from 'react'
import { PositionEvaluation } from '@/features'
import { PositionEvaluationForm } from '@/entity/grading/positionEvaluation/ui/form/PositionEvaluationForm'

const TabPanel = styled(TabPanelUnstyled)({
	width: '100%',
	maxWidth: '90vw',
	margin: '32px 34px 0 0',
	fontFamily: 'Inter, sans-serif',
	fontSize: '0.875rem',
})

const Tabs = styled(TabsUnstyled)({})

export const GradingPage = () => {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState(0)

	const handleChangeTab = (e, newValue) => setActiveTab(newValue)

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('grading_of_government_employees')}>
					<Tabs defaultValue={0} value={activeTab} onChange={handleChangeTab}>
						<TabsList>
							<Tab value={0}>{t('position_evaluation')}</Tab>
						</TabsList>
						<TabPanel value={0}>
							<PositionEvaluationForm />
						</TabPanel>
					</Tabs>
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}
