import React from 'react'
import styles from './style.module.scss'

const Radio = ({ label, id, handleChange, name, form }) => {
	return (
		<>
			<input
				className={styles.radio}
				type="radio"
				id={id}
				name={name}
				onChange={handleChange}
				value={id}
				checked={form[name] === id}
			/>
			<label htmlFor={id}>{label}</label>
		</>
	)
}

export default Radio
