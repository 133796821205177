import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components/index'
import { Search } from '@pages/PersonalAffairs/components/Search/Search'
import styles from '../../PersonalAffairsPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import { RequestPersonalAffairModal } from '@pages/PersonalAffairs/Tabs/PersonalAffairsMainTab/components/RequestPersonalAffairModal/RequestPersonalAffairModal'
import { headCells } from '@pages/PersonalAffairs/Tabs/PersonalAffairsMainTab/utils'
import CheckingSpecialSocialPaymentModal from '@pages/PersonalAffairs/Tabs/PersonalAffairsMainTab/components/CheckingSpecialSocialPaymentModal/CheckingSpecialSocialPaymentModal'

const PersonalAffairsMainTab = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isRequestModalOpen, setRequestModalOpen] = useState(false)
	const [isOpenModalRight, setOpenModalRight] = useState(false)
	const { content: affairs, totalElements } = useSelector(affairsSelectors.employerAffairs)

	const getData = useCallback((srcText = '', page = 0, size = 10) =>
		dispatch(affairsThunks.getEmployerAffairs(srcText, page, size))
	)

	useEffect(() => getData(), [dispatch])

	return (
		<div className={styles.wrapper}>
			{isRequestModalOpen && <RequestPersonalAffairModal onClose={() => setRequestModalOpen(false)} />}
			{isOpenModalRight ? <CheckingSpecialSocialPaymentModal onClose={() => setOpenModalRight(false)} /> : null}
			<div className={'flex'}>
				<Button buttonClassName={styles.wrapper__button} icon={addWhiteIcon} onClick={() => setRequestModalOpen(true)}>
					{t('personal_affairs.request_electronic_affair')}
				</Button>
				<Button buttonClassName={styles.wrapper__buttonRight} onClick={() => setOpenModalRight(true)}>
					{t('personal_affairs.checking_social_payments')}
				</Button>
			</div>
			<Search count={totalElements} fetchData={getData} />
			<Table headCells={headCells} getData={getData} data={affairs} />
		</div>
	)
}
export default PersonalAffairsMainTab
