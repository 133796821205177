import { DataGridTable } from '@/ui/atoms/dataGridTable/dataGridTable'
import { useTranslation } from 'react-i18next'
import styles from './InfoTable.module.scss';

export const CompanyAccountData = ({ company, hideSubTitle, hideTitle }) => {
  const { t } = useTranslation()

	const accountData = [
		{ title: t('bin_iin'), data: company?.bin },
		{ title: t('full_bin_name'), data: company?.companyName },
		{ title: t('certificate_expiry_date'), data: company?.certificateExpiresAt },
	]

	return (
		<DataGridTable
			hideSubTitle={hideSubTitle}
			hideTitle={hideTitle}
			title={'account_details'}
			information={accountData}
			titleClass={styles.tableTitleCell}
		/>
	)
}
