import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Processing = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <path d="M8 0.819336C3.58172 0.819336 0 4.40105 0 8.81934C0 13.2376 3.58172 16.8193 8 16.8193C12.4183 16.8193 16 13.2376 16 8.81934C16 4.40105 12.4183 0.819336 8 0.819336ZM8 15.8193C4.134 15.8193 1 12.6853 1 8.81934C1 4.95334 4.134 1.81934 8 1.81934C11.866 1.81934 15 4.95334 15 8.81934C15 12.6853 11.866 15.8193 8 15.8193Z" fill="#AAB8D1"/>
            <path d="M8 9.81934C8.55228 9.81934 9 9.37162 9 8.81934C9 8.26705 8.55228 7.81934 8 7.81934C7.44772 7.81934 7 8.26705 7 8.81934C7 9.37162 7.44772 9.81934 8 9.81934Z" fill="#AAB8D1"/>
            <path d="M11.5 9.81934C12.0523 9.81934 12.5 9.37162 12.5 8.81934C12.5 8.26705 12.0523 7.81934 11.5 7.81934C10.9477 7.81934 10.5 8.26705 10.5 8.81934C10.5 9.37162 10.9477 9.81934 11.5 9.81934Z" fill="#AAB8D1"/>
            <path d="M4.5 9.81934C5.05228 9.81934 5.5 9.37162 5.5 8.81934C5.5 8.26705 5.05228 7.81934 4.5 7.81934C3.94772 7.81934 3.5 8.26705 3.5 8.81934C3.5 9.37162 3.94772 9.81934 4.5 9.81934Z" fill="#AAB8D1"/>
        </Svg>
    )
};

export default Processing;