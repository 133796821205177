import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	positionEvaluations: [],
	formInitialData: {}
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_EVALUATIONS:
			return {
				...state,
				evaluations: payload.content,
			}
		case types.SET_FORM_INITIAL_DATA:
			return {
				...state,
				formInitialData: payload,
			}
		case types.SET_CALCULATION:
			return {
				...state,
				calculation: payload,
			}
		case types.SET_GENERATED_PDF:
			return {
				...state,
				generatedPdf: payload,
			}
		default:
			return state
	}
}
