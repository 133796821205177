import React, { useContext } from 'react'
import { Context } from './Context'

export const TableBody = ({ children }) => {
	const { data, tableName } = useContext(Context)

	return (
		<tbody>
			{data?.map((rowData, index) => (
				<React.Fragment key={`table-row-${tableName}-${index}`}>{children(rowData, index)}</React.Fragment>
			))}
		</tbody>
	)
}
