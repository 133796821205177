import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from '../EmployeesInNeed.module.scss'

const Search = ({ filters, setFilters, aidData, fetchData }) => {
	const { t } = useTranslation()
	const updateFilter = (e, name) => {
		setFilters({ ...filters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })
	}

	return (
		<div className={classes.searchRow}>
			<div className={classes.searchField}>
				<div className={classes.searchField__left}>
					<IconButton type="submit" aria-label="search">
						<SearchIcon />
					</IconButton>
					<InputBase
						fullWidth
						placeholder={t('welfare.tabs.search.enterIIN')}
						inputProps={{
							'aria-label': 'search',
						}}
						value={filters.srcText}
						onChange={(e) => updateFilter(e, 'srcText')}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault()
								fetchData()
							}
						}}
					/>
				</div>
				<span className={classes.searchField__button} onClick={() => fetchData()}>
					{t('find')}
				</span>
			</div>
			<div className={classes.searchText}>
				<span>{t('welfare.tabs.search.total')}: {aidData.total}</span>
				<span>{t('welfare.tabs.search.sendRequestCnt')}: {aidData.sendRequestCnt}</span>
				<span>{t('welfare.tabs.search.consentCnt')}: {aidData.consentCnt}</span>
			</div>
		</div>
	)
}

export default Search
