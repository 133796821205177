import React, { useEffect } from 'react'
import { Button } from '@/ui'
import { api, useDigitalSign } from '@/shared'
import { userThunks } from '@/entity'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const Verification = ({ user }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { successPayload, onSign, onClearPayload } = useDigitalSign()

	useEffect(() => {
		if (successPayload) {
			const base64 = Buffer.from(successPayload).toString('base64')
			dispatch(userThunks.verification({ xmlData: base64, id: user?.personDetails?.id }))
			onClearPayload()
		}
	}, [successPayload])
	const getUserXml = async () => {
		onSign(await api.profile.getXML())
	}
	return (
		<div>
			<Button onClick={() => getUserXml()}>{t('sign_ecp_rsa')}</Button>
		</div>
	)
}
