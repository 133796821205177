import { ModalTemplate } from '@/ui'
import { AccidentForm } from '@/entity/accidents/ui/form'
import { LoadingBlocker } from '@/components/Loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { accidentSelectors } from '@/entity'

export const Form = ({ onClose }) => {
	const isLoadingSend = useSelector(accidentSelectors.isLoadingSend)
	return (
		<>
			<ModalTemplate onClose={onClose}>
				<AccidentForm onClose={onClose} />
			</ModalTemplate>
			{isLoadingSend ? <LoadingBlocker /> : null}
		</>
	)
}
