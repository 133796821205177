import React from 'react'

export const Success = ({ className }) => {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g>
				<path d="M8.58838 0C4.17691 0 0.588379 3.58853 0.588379 8C0.588379 12.4115 4.17691 16 8.58838 16C12.9998 16 16.5884 12.4108 16.5884 8C16.5884 3.58916 12.9998 0 8.58838 0ZM8.58838 14.7607C4.86104 14.7607 1.82772 11.728 1.82772 8C1.82772 4.27203 4.86104 1.23934 8.58838 1.23934C12.3163 1.23934 15.349 4.27203 15.349 8C15.349 11.728 12.3157 14.7607 8.58838 14.7607Z" />
				<path d="M12.2592 5.2173C12.007 4.98802 11.6147 5.00599 11.3842 5.25942L7.60419 9.4218L5.77799 7.56527C5.53693 7.32111 5.14531 7.31739 4.90177 7.55783C4.65762 7.79764 4.6539 8.18989 4.89434 8.43405L7.18031 10.7578C7.29744 10.8768 7.45606 10.9431 7.62212 10.9431C7.62584 10.9431 7.63019 10.9431 7.6339 10.9437C7.80494 10.94 7.96606 10.8669 8.08069 10.7405L12.3013 6.09292C12.5312 5.83883 12.5126 5.4472 12.2592 5.2173Z" />
			</g>
		</svg>
	)
}
