export const getDeptAndDeptTypeCodeByExternalId = (obj, id) => {
	if (obj.externalId === id) {
		return {
			dept: obj.dept,
			deptTypeCode: obj.deptTypeCode,
		}
	}

	if (obj.childs !== null && obj.childs !== undefined) {
		for (let i = 0; i < obj.childs.length; i++) {
			const result = getDeptAndDeptTypeCodeByExternalId(obj.childs[i], id)

			if (result) {
				return result
			}
		}
	}

	return null
}
