import React from 'react'
import { useTranslation } from 'react-i18next'
import { Arrow } from '@/app/icons'
import { Cross } from '@app/images'
import { Button } from '@/ui'
import styles from './ModalWSBackButton.module.scss'

export const ModalWSBackButton = ({ onClick, backButtonIsShown = true, size }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.container}>
			{backButtonIsShown && (
				<Button transparent buttonClassName={styles.backButton} onClick={onClick} size={size}>
					<Arrow className={styles.iconGreen} /> {t('back')}
				</Button>
			)}
			<Button type="bare" onClick={onClick} buttonClassName={backButtonIsShown ? '' : styles.closeButton}>
				<div className={styles.cross}>
					<img src={Cross} alt="cross" />
				</div>
			</Button>
		</div>
	)
}
