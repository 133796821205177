import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { TerminateElectronic } from '@features/contracts/actions'
import { Decline, Revoke, Sign } from '@features/contracts/actions/terminateElectronic/modals'
// import { SignAll, SignSome, Delete as DeleteModal } from '../PackageLoadModals'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { eContractsSelectors, eContractsThunks, userSelectors } from '@/entity'
import { getActions, headCells } from './contractsTable.headings'
import styles from './contractsTable.module.scss'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { HrPagination } from '@/components/Pagination/hrPagination'
import { Status } from './Status'
import { useTermination } from '@/shared'

export const List = ({ setActiveTab }) => {
	const dispatch = useDispatch()
	const filter = useSelector((state) => state.contracts.etd.filter) || {}
	const { data } = useSelector((state) => state.contracts.etd.list)
	const isLoading = useSelector((state) => state.contracts.etd.isLoading)
	const error = useSelector((state) => state.contracts.etd.error)
	const { page, size, rowsPerPage } = useSelector((state) => state.contracts.etd.params)

	const [openView, setOpenview] = useState(false)
	const [openRevoke, setOpenRevoke] = useState(false)
	const [openDecline, setOpenDecline] = useState(false)
	const [openSign, setOpenSign] = useState(false)

	const activeRole = useSelector(userSelectors.activeRole)

	const [order] = useState('asc')

	const [orderBy, setOrderBy] = useState('')
	const [params, setParams] = useState({ page: 0, size: 10, orderBy: 'desc' })
	const terms = useSelector(eContractsSelectors.terminations)
	const paginationParams = useSelector(eContractsSelectors.eContractParams)
	useEffect(() => {
		dispatch(eContractsThunks.getTerms({}, params))
	}, [])

	useEffect(() => {
		dispatch(eContractsThunks.getTerms({}, params))
	}, [params])
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const optionLang = useMemo(() => (language === 'rus' ? 'nameRu' : 'nameKz'), [language])
	const [selectedContract, setSelectedContract] = useState(null)

	const isMobile = window.innerWidth < 768

	const handleChangePage = (...data) => {
		data.map((item) => setParams((prev) => ({ ...prev, page: item - 1 })))
	}

	const handleChangeRowsPerPage = (...data) => {
		data.map((item) => setParams((prev) => ({ ...prev, size: item })))
	}

	const onActionMenuChoose = (mode, row) => {
		const mappedContract = { ...row, termReasonsList: row.termReasons }
		setSelectedContract(mappedContract)
		switch (mode) {
			case 'view':
				setOpenview(true)
				break
			case 'sign':
				setOpenSign(true)
				break
			case 'decline':
				setOpenDecline(true)
				break
			case 'revoke':
				setOpenRevoke(true)
				break
			default:
				break
		}
	}

	const { handleSign, terminationReasons } = useTermination(selectedContract)
	const haveFilters = useMemo(() => Object.keys(filter).length !== 0, [filter])

	const onSignClick = (data) => {
		handleSign(data)
	}

	const onUpdate = useCallback(
		(e) => {
			dispatch(eContractsThunks.getTerms({}, params))
			setOpenview(false)
		},
		[dispatch]
	)
	return (
		<div className={styles.tableRow}>
			<table className={styles.table}>
				<thead>
					<tr>
						{headCells.map((head, index) => (
							<th key={index + 'thead-th'}>{t(head.label)}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: 'relative' }}>
					{
						//если все загружено
						!!terms?.length && !isLoading ? (
							terms.map((row, index) => {
								return (
									<tr key={index + '-table-row'}>
										<td className={styles.pixelGamingContractNumber} onClick={() => onActionMenuChoose('view', row)}>
											{row.initEmployer ? 'Работодатель' : 'Работник'}
										</td>
										<td
											className={styles.pixelGamingContragent}
											style={{ textTransform: 'capitalize' }}
											onClick={() => onActionMenuChoose('view', row)}
										>
											{activeRole === 'ROLE_EMPLOYEE' ? row?.bin : row.iin}
										</td>
										<td className={styles.pixelGamingPost} onClick={() => onActionMenuChoose('view', row)}>
											{row.employerName}
										</td>
										<td className={styles.pixelGamingDate} onClick={() => onActionMenuChoose('view', row)}>
											{row.createDate || '-'}
										</td>
										<td className={styles.pixelGamingDate} onClick={() => onActionMenuChoose('view', row)}>
											<Status status={row.stateCode} />
										</td>

										<td>
											<DropDownMenu
												title={t('select_pls')}
												items={getActions(t, row.stateCode.code, activeRole, row.initEmployer)}
												onItemClick={(mode) => onActionMenuChoose(mode, row)}
											/>
										</td>
									</tr>
								)
							})
						) : (
							<tr>
								<td colSpan={11} className={styles.messageCol}>
									{isLoading && (
										<LoadingScreen>
											<Title>
												Подождите! <br />
												Идет загрузка данных с ЕСУТД...
											</Title>
										</LoadingScreen>
									)}
									{!isLoading && haveFilters && !data?.length && (
										<div className={styles.tableLoading}>{t('no_data')}</div>
									)}
									{!isLoading && !haveFilters && !data?.length && (
										<div className={styles.tableLoading}>Вы еще не зарегистрировали ни одного договора!</div>
									)}
									{error && <div className={styles.tableLoading}>Произошла ошибка, повторите попытку позже!</div>}
								</td>
							</tr>
						)
					}
				</tbody>
			</table>

			{openView && selectedContract && (
				<TerminateElectronic
					selectedContract={selectedContract}
					isCreation={false}
					onSign={onSignClick}
					onUpdate={onUpdate}
					setActiveTab={setActiveTab}
					onClose={setOpenview.bind(null, false)}
				/>
			)}

			{openRevoke && selectedContract && (
				<Revoke selectedContract={selectedContract} onUpdate={onUpdate} onClose={setOpenRevoke.bind(null, false)} />
			)}
			{openDecline && selectedContract && (
				<Decline selectedContract={selectedContract} onUpdate={onUpdate} onClose={setOpenDecline.bind(null, false)} />
			)}
			{openSign && selectedContract && (
				<Sign selectedContract={selectedContract} onUpdate={onUpdate} onClose={setOpenSign.bind(null, false)} />
			)}

			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={isMobile ? rowsPerPage.filter((value) => value.size !== 100) : rowsPerPage}
				rowsPerPage={params.size}
				numberOfElements={paginationParams.numberOfElements}
				totalElements={paginationParams.totalElements}
				totalPages={paginationParams.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={params.page}
			/>
		</div>
	)
}
