import { actions } from './actions'
import { api } from '@/shared'
import { message } from 'antd'

export const getEmployerAffairs = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployerAffairs({ srcText, page, size })
			dispatch(actions.setEmployerAffairs(response))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.setError(null))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getEmployeeAffairs = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployeeAffairs({ srcText, page, size })
			dispatch(actions.setEmployeeAffairs(response))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.setError(null))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getEmployeeCard = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployeeCard({ srcText, page, size })
			dispatch(actions.setEmployeeCard(response))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.setError(null))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getAffairForEmployer = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getAffairForEmployer(id)
			let correlation = false
			dispatch(actions.setSelectedAffair({ ...response, id, correlation }))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.setError(null))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getEmployerRequests = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployerRequests({ srcText, page, size })
			dispatch(actions.setEmployerRequests(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getEmployeeRequests = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployeeRequests({ srcText, page, size })
			dispatch(actions.setEmployeeRequests(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getRequestForEmployer = (srcText = '', page = 0, size = 10) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getEmployerRequests({ srcText, page, size })
			dispatch(actions.setEmployerRequests(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getAffairForEmployerByCorrelationId = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.personalAffairs.getAffairForEmployerByCorrelationId(id)
			let correlation = true
			dispatch(actions.setSelectedAffair({ ...response, id, correlation }))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.setError(null))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setError(error))
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
