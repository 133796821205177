export const headCells = [
	{
		id: 'id',
		numeric: true,
		label: 'personal_affairs.table.field_number',
	},
	{
		id: 'employerName',
		numeric: false,
		label: 'personal_affairs.table.employer_name',
	},
	{
		id: 'iin',
		numeric: true,
		label: 'personal_affairs.table.iin',
	},
	{
		id: 'employeeFio',
		numeric: true,
		label: 'personal_affairs.table.employee_fio',
	},
	{
		id: 'requestDate',
		numeric: false,
		label: 'personal_affairs.table.request_date',
	},
	{
		id: 'consentDate',
		numeric: false,
		label: 'personal_affairs.table.consent_date',
	},
	{
		id: 'requestStatus',
		numeric: false,
		label: 'personal_affairs.table.request_status',
	},
]
