import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './StructureInformation.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const StructureInformation = ({ structure, deptTypeName, staffProject }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	let errors = structure.level === 0 ? staffProject.errors : structure.errors
	const errorInformation = () => {
		const errorDescriptions = errors.map((error) => error.code + ': ' + error[optionLang])
		return [
			{
				title: t('staff.errors'),
				data: errorDescriptions.join(',\n'),
			},
		]
	}
	const structureInformation = () => {
		return [
			{ title: t('staff_modal.code'), data: structure.dept.code },
			{ title: t('staff_modal.structure_type'), data: deptTypeName },
			{ title: t('staff_modal.structure_name_kz'), data: structure.dept.nameKz },
			{ title: t('staff_modal.structure_name'), data: structure.dept.nameRu },
		]
	}

	const staffingInformation = () => {
		return [
			{ title: t('staff_modal.order_date'), data: staffProject.orderDate },
			{ title: t('staff_modal.order_number'), data: staffProject.orderNumber },
			{ title: t('staff_modal.code'), data: structure.dept.code },
			{ title: t('staff_modal.structure_name_kz'), data: structure.dept.nameKz },
			{ title: t('staff_modal.structure_name'), data: structure.dept.nameRu },
			{ title: t('staff_modal.comment'), data: staffProject.note },
		]
	}
	return (
		<>
			<DataGridTable
				hideSubTitle
				titleClass={styles.tableTitleCell}
				title={structure.level === 0 ? t('staffing') : t('staff_modal.staff_information')}
				information={structure.level === 0 ? staffingInformation() : structureInformation()}
			/>
			{errors?.length > 0 && (
				<DataGridTable
					hideSubTitle
					titleClass={styles.tableTitleCell}
					dataClass={styles.dataClassCell}
					information={errorInformation()}
				/>
			)}
		</>
	)
}
