export const TOGGLE_LOADING = 'profUnions/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'profUnions/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'profUnions/TOGGLE_ERROR'
export const TOGGLE_LOADING_BIN = 'profUnions/TOGGLE_LOADING_BIN'
export const TOGGLE_SUCCESS_BIN = 'profUnions/TOGGLE_SUCCESS_BIN'
export const TOGGLE_LOADING_CHIEF_FIO = 'profUnions/TOGGLE_LOADING_CHIEF_FIO'
export const TOGGLE_SUCCESS_CHIEF_FIO = 'profUnions/TOGGLE_SUCCESS_CHIEF_FIO'
export const TOGGLE_LOADING_BIN_EMPLOYER = 'profUnions/TOGGLE_LOADING_BIN_EMPLOYER'
export const TOGGLE_SUCCESS_BIN_EMPLOYER = 'profUnions/TOGGLE_SUCCESS_BIN_EMPLOYER'
export const TOGGLE_LOADING_BIN_PARENT = 'profUnions/TOGGLE_LOADING_BIN_PARENT'
export const TOGGLE_SUCCESS_BIN_PARENT = 'profUnions/TOGGLE_SUCCESS_BIN_PARENT'
export const SET_PROF_UNIONS = 'profUnions/SET_PROF_UNIONS'
export const SET_CHAIRMAN_NAME = 'profUnions/SET_CHAIRMAN_NAME'
export const SET_PROF_UNIONS_XML = 'profUnions/SET_PROF_UNIONS_XML'
export const RESET_XML = 'profUnions/RESET_XML'
export const SET_PROF_UNIONS_LIST = 'profUnions/SET_PROF_UNIONS_LIST'
export const SET_SEARCH_CHIEF_FIO = 'profUnions/SET_SEARCH_CHIEF_FIO'
export const SET_SEARCH_NAME = 'profUnions/SET_SEARCH_NAME'
export const SET_SEARCH_PARENT_NAME = 'profUnions/SET_SEARCH_PARENT_NAME'
export const SET_PROF_MEMBERS = 'profUnions/SET_PROF_MEMBERS'
export const SET_NEW_MEMBERS = 'profUnions/SET_NEW_MEMBERS'
export const SET_MEMBER_STATUS = 'profUnions/SET_MEMBER_STATUS'
export const SET_SEARCH_EMPLOYER_NAME = 'profUnions/SET_SEARCH_EMPLOYER_NAME'
export const RESET = 'profUnions/RESET'
export const SET_MY_UNIONS = 'profUnions/SET_MY_UNIONS'
export const SET_PERSONS = 'profUnions/SET_PERSONS'
export const SET_PERSONS_LOADING = 'profUnions/SET_PERSONS_LOADING'
