import React, { useMemo } from 'react'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import styles from './Corruption.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import { getArrestedCorruptionColumns, getCorruptionColumns, getUncertain } from './utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function Corruption({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const { t } = useTranslation()
	const uncertain = useMemo(() => getUncertain(t, data.data.uncertain, lang), [data.data.uncertain])
	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					{data.method[optionLang]}{' '}
					<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
						'DD.MM.YYYY'
					)}`}</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_016')}</p>
					</div>
				)}
				{data.data.responseType === 'UNJUDGED' && (
					<div className={styles.row}>
						<p>{t('military.status')}:</p>
						<p>{t('corruption.type.unjudged')}</p>
					</div>
				)}
				{data.data.responseType === 'UNCERTAIN' && (
					<>
						<div className={styles.row}>
							<p>{t('military.status')}:</p>
							<p>{t('corruption.type.uncertain')}</p>
						</div>
						<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
							<p>{t('corruption.more_info_uncertain')}</p>
						</div>
						<DataGridTable
							hideSubTitle
							information={uncertain}
							hideTitle
							titleClass={styles.tableTitleCell}
							rowClass={styles.dataRow}
						/>
					</>
				)}

				{data.data.responseType === 'JUDGED' && (
					<>
						<div className={styles.row}>
							<p>{t('military.status')}:</p>
							<p>{t('corruption.type.judged')}</p>
						</div>
						<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
							<p>{t('corruption.more_info_judged')}</p>
						</div>
					</>
				)}

				{data.data.judgement.suau3.map((data, key) => {
					return (
						<React.Fragment key={key}>
							<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
								<p>{t('corruption.as_an_accused')}</p>
							</div>
							<DataGridTable
								hideSubTitle
								information={getCorruptionColumns(t, data, lang)}
								hideTitle
								titleClass={styles.tableTitleCell}
								rowClass={styles.dataRow}
							/>
						</React.Fragment>
					)
				})}
				{data.data.judgement.suau2.map((data, key) => {
					return (
						<React.Fragment key={key}>
							<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
								<p>{t('corruption.convicted_persons')}</p>
							</div>
							<DataGridTable
								hideSubTitle
								information={getArrestedCorruptionColumns(t, data)}
								hideTitle
								titleClass={styles.tableTitleCell}
								rowClass={styles.dataRow}
							/>
						</React.Fragment>
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default Corruption
