import Error from './Error';
import Invalid from './Invalid';
import Pending from './Pending';
import Processing from './Processing';
import Successful from './Successful';
import SuccessfulSaved from './SuccessfulSaved';
import Timeout from './Timeout';

const icons = {
    error: Error,
    invalid: Invalid,
    pending: Pending,
    processing: Processing,
    successful: Successful,
    timeout: Timeout,
    partsuccessful: Successful
};

const subicons = {
    "0": SuccessfulSaved,
    "1": SuccessfulSaved,
    "2": SuccessfulSaved,
    "3": SuccessfulSaved,
    "4": Successful,
    "5": Pending,
    "6": Invalid,
    "7": Timeout,
    "8": Error,
};

export default (iconName, width, height) => {
    if (!iconName) return null;
    const Component = icons[iconName];
    return <Component width={width} height={height} />;
}

export const getSubicon = (iconName, width, height) => {
    if (!iconName) return null;
    const Component = subicons[iconName];
    return <Component width={width} height={height} />;
}
