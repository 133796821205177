import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './RemoveStruct.module.scss'

export const RemoveStruct = ({ onSubmit, onClose, rowSelectMenu }) => {
	const { t } = useTranslation()
	let bold_question_remove = t('staff.bold_question_remove')

	return (
		<ModalTemplate onClose={onClose} hideBackButton={true}>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{t('attention')}</Title>
				<div className={styles.sizeText16}>
					{rowSelectMenu.level === 0 ? (
						<>
							{t('staff.remove_staffing_project')} <b>{bold_question_remove}</b>
						</>
					) : (
						<>
							{t('staff.remove_struct')} <b>{bold_question_remove}</b>
						</>
					)}
				</div>
			</div>
			<div className={styles.buttonStyle}>
				<Button type="submit" onClick={onSubmit}>
					{t('delete_yes')}
				</Button>
				<Button type="bare" onClick={onClose}>
					{t('no_cancel')}
				</Button>
			</div>
		</ModalTemplate>
	)
}
