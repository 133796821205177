import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	affairs: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	selectedAffair: {},
	employerRequests: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	selectedRequest: {},
	employeeRequests: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	employeeCard: null,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions

	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_AFFAIRS:
			return { ...state, affairs: payload }
		case types.SET_ERROR:
			return { ...state, error: payload }
		case types.SET_SELECTED_AFFAIR:
			return { ...state, selectedAffair: payload }
		case types.SET_EMPLOYER_REQUESTS:
			return { ...state, employerRequests: payload }
		case types.SET_SELECTED_REQUEST:
			return { ...state, selectedRequest: payload }
		case types.SET_EMPLOYEE_CARD:
			return { ...state, employeeCard: payload }
		case types.SET_EMPLOYEE_REQUESTS:
			return { ...state, employeeRequests: payload }
		default:
			return state
	}
}
