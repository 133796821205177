import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './PersonalAffairCard.module.scss'
import React, { useState } from 'react'
import { PersonalData } from '@pages/PersonalAffairs/components/PersonalAffairCard/Info/PersonalData'
import { Button, Title } from '@/ui'
import { headCells } from '@pages/PersonalAffairs/components/PersonalAffairCard/utils'
import { useTranslation } from 'react-i18next'
import { PersonalTable } from '@pages/PersonalAffairs/components/PersonalAffairCard/Table/PersonalTable'
import { useDispatch, useSelector } from 'react-redux'
import { affairsActions, affairsSelectors } from '@/entity/personalAffairs'
import DetailedAffair from '@pages/PersonalAffairs/components/DetailedAffair/DetailedAffair'
import {downloadPdfByCorrId, downloadPdfById} from '@/shared/api/rest/personalAffairs'

export const PersonalAffairCard = ({ onClose, cardInfo }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const selectedAffair = useSelector(affairsSelectors.selectedAffair)
	const data = cardInfo || selectedAffair
	const [detailedView, setDetailedView] = useState(false)
	const [selectedMethod, setSelectedMethod] = useState()
	const { methodInfoData } = data || {}
	const photos = methodInfoData?.find(({ method }) => method.code === '010')?.data.document
	const avatar = (
		photos?.find(({ documentType }) => documentType.code === '002') ||
		photos?.find(({ documentType }) => documentType.code === '001')
	)?.photo

	const _onClose = () => {
		onClose && onClose()
		dispatch(affairsActions.setSelectedAffair(null))
	}
	const onCloseDetailedView = () => {
		setDetailedView(false)
		setSelectedMethod(null)
	}
	const onOpenDetailedView = (method) => {
		setDetailedView(true)
		setSelectedMethod(method)
	}

	const downloadPDF = async (code = null) => {
		let res
		if (data.correlation) {
			res = await downloadPdfByCorrId(data.id, code || selectedMethod || '')
		} else {
			res = await downloadPdfById(data.id, code || selectedMethod || '')
		}
		const file = new Blob([res], { type: 'application/pdf' })
		const fileURL = URL.createObjectURL(file)
		window.open(fileURL)
	}

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={_onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<PersonalData data={data} action={() => setDetailedView(true)} avatar={avatar} />
					<Title>{t('personal_affairs.card.table.title')}</Title>
					<PersonalTable
						headCells={headCells}
						data={methodInfoData}
						openDetailedView={() => setDetailedView(true)}
						onOpenDetailedView={onOpenDetailedView}
						downloadPDF={downloadPDF}
					/>
					<Button onClick={_onClose} buttonClassName={styles.closeButton}>
						{t('close')}
					</Button>
				</div>
			</Modal>
			{detailedView && (
				<DetailedAffair
					data={data}
					onClose={onCloseDetailedView}
					selectedMethod={selectedMethod}
					downloadPDF={downloadPDF}
				/>
			)}
		</>
	)
}
