import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from './RecoverWarningModal.module.scss'
import { Warning as WarningIcon } from '@app/icons'

export const Warning = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			cancelButtonTitle={'contracts_tab.recover.cancel_text'}
			processButtonTitle={'go_to_settings'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<WarningIcon />
				<p>{t('role_select_warning')}</p>
			</div>
		</ConfirmModal>
	)
}
