import React, { forwardRef } from 'react'
import styles from './AdditionalInfoField.module.scss'
import InputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField } from 'formik'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import Input from '@mui/material/Input'
import { Field } from '@/ui'

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
	const { ...other } = props
	return (
		<InputMask
			{...other}
			mask="+7 (999) 999-99-99"
			definitions={{
				'#': /[0-9]/,
			}}
			inputRef={ref}
		/>
	)
})
export const AdditionalInfoField = connect(({ isView }) => {
	const { t } = useTranslation()
	return (
		<>
			{' '}
			<div className="flex">
				<div className={styles.field}>
					<div className={styles.phone__field__label}>{t('labor_records.phone')} *</div>
					<FormikField name={formFieldNames.phone}>
						{({ field, meta: { touched, error } }) => (
							<>
								<Input
									classes={{ root: styles.phone__field }}
									fullWidth
									{...field}
									inputComponent={TextMaskCustom}
									placeholder={t('labor_records.placeholder_phone')}
									disabled={isView}
								/>
								<div className={styles.error}>{touched && t(error?.key)}</div>
							</>
						)}
					</FormikField>
				</div>
				<div className={styles.field}>
					<FormikField name={formFieldNames.email}>
						{({ field, meta: { touched, error } }) => (
							<Field
								label={t('labor_records.email')}
								type="text"
								isRequired
								placeholder={t('labor_records.placeholder_email')}
								{...field}
								error={touched && error ? t(error) : ''}
								disabled={isView}
								labelClassName={styles.field}
							/>
						)}
					</FormikField>
				</div>
			</div>
			<div className={styles.otherConditions}>
				<FormikField name={formFieldNames.comment}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType={'textarea'}
							isRequired
							label={t('labor_records.comment')}
							placeholder={t('labor_records.placeholder_comment')}
							error={touched && error ? t(error) : ''}
							showCount
							maxLength={4000}
							rows={6}
							{...field}
							disabled={isView}
							labelClassName={styles.field}
						/>
					)}
				</FormikField>
			</div>
		</>
	)
})
