import { getFromLocalStorage } from '@/shared'
import React, { useMemo, useState } from 'react'
import { getCurrentLanguage } from '@/i18next'

export const RedirectExternalUnAuthUser = ({ children }) => {
	const lang = getCurrentLanguage()
	const [refEnbek, setRefEnbek] = useState()

	useMemo(
		() =>
			lang === 'rus'
				? setRefEnbek(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE)
				: setRefEnbek(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE),
		[lang]
	)

	return <>{!getFromLocalStorage('isLogged') ? (window.location.href = refEnbek) : <>{children}</>}</>
}
