import { ProfileTemplate, Tabs } from '@/widgets'
import { ChangeProfile, EmployerProfile } from '@/features'
import styles from './style.module.scss'
import { RedirectUnAuthUser } from '@/processes'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { profUnionsThunks, userActions, userSelectors } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { EditCompany } from '@features/employerProfile'
import { AddCompany } from '@features/employerProfile'
import { getCurrentLanguage } from '@/i18next'
import PreHeader from '@/components/home/components/PreHeader'
import { employerProfileSelectors } from '@features/employerProfile/model'

//Data array must consist of key of tabs and as value any renderable content
export const EmployerProfilePage = () => {
	const tabControlRef = useRef(null)
	const dispatch = useDispatch()
	const [urlAuth, setUrlAuth] = useState()
	const lang = getCurrentLanguage()
	const { flEmployer, bin } = useSelector(employerProfileSelectors.activeCompany)
	useMemo(
		() =>
			lang === 'rus'
				? setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_EMPLOYER)
				: setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_EMPLOYER),
		[lang]
	)
	let tabs = ['organisation_data', { label: 'account_settings', url: urlAuth }]
	let data = {
		organisation_data: (
			<div className={styles.wrapper}>
				<EmployerProfile addCompany={<AddCompany />} changeProfile={<ChangeProfile />} />
			</div>
		),
		account_settings: <div />,
	}

	// Add the 'edit' tab only if flEmployer is not true
	if (!flEmployer) {
		tabs.splice(1, 0, 'edit')
		data = {
			...data,
			edit: (
				<div className={styles.wrapper}>
					<EditCompany tabControlRef={tabControlRef} />
				</div>
			),
		}
	}

	useEffect(() => {
		dispatch(userActions.setActiveRole('ROLE_EMPLOYER'))
	}, [])

	useEffect(() => {
		if (bin) {
			dispatch(profUnionsThunks.getTradeUnion(false))
		}
	}, [bin])

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={'my_profile'}>
					<Tabs tabs={tabs} data={data} ref={tabControlRef} />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}
