import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RecoverModal.module.scss'
import { Field, DatePicker, Button, SelectSearch } from '@/ui'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'

export const Form = ({ restoreOrganizationTypes, contract, restoreOrganizationCourts, onClose }) => {
	const { t } = useTranslation()

	const { isValid, values, setFieldValue } = useFormikContext()

	useEffect(() => {
		if (values?.drestoreOrgTypeCode !== '1') {
			setFieldValue('dcourtCode', '')
		}
		if (values?.drestoreOrgTypeCode !== '3') {
			setFieldValue('restoreNote', '')
		}
	}, [values])

	const dateArr = contract?.terminationDate?.split('.')

	return (
		<FormikForm>
			<div className={styles.contarct__info__form__line}>
				<div className={styles.contarct__info__form__item__left}>
					<label>{t('contracts_tab.recover.recover_date')}*</label>
					<FormikField name={'restoreDate'}>
						{({ field, meta: { touched, error } }) => (
							<DatePicker
								error={touched && error ? t(error) : ''}
								{...field}
								showIcon
								maxDate={new Date()}
								minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
								placeholder={t('default_date_value')}
							/>
						)}
					</FormikField>
				</div>

				<div className={styles.contarct__info__form__item__right}>
					<label>{t('contracts_tab.recover.drestoreOrgTypeCode')}*</label>
					<FormikField name={'drestoreOrgTypeCode'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								options={restoreOrganizationTypes || []}
								fieldType="select"
								{...field}
								placeholder={t('contracts_tab.recover.select_from_dict')}
								error={touched && error ? t(error) : ''}
							/>
						)}
					</FormikField>
				</div>
			</div>

			<div className={styles.contarct__info__form__line}>
				{values.drestoreOrgTypeCode === '3' ? (
					<div className={styles.contarct__info__form__note}>
						<FormikField name={'restoreNote'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'textarea'}
									isRequired
									label={t('contracts_tab.recover.note')}
									placeholder={t('contracts_tab.recover.note_placeholder')}
									error={touched && error ? t(error) : ''}
									{...field}
									showCount
									maxLength={3000}
									rows={3}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				) : (
					<div className={styles.contarct__info__form__item__left}>
						<label>{t('contracts_tab.recover.restoreOrder')}*</label>
						<FormikField name={'restoreOrder'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									placeholder={t('contracts_tab.recover.put_order_number')}
									error={touched && error ? t(error) : ''}
								/>
							)}
						</FormikField>
					</div>
				)}

				{values.drestoreOrgTypeCode === '1' && (
					<div className={styles.contarct__info__form__item__right}>
						<label>{t('contracts_tab.recover.dcourtCode')}</label>
						<FormikField name={'dcourtCode'}>
							{({ field, meta: { touched, error } }) => (
								<SelectSearch
									options={restoreOrganizationCourts || []}
									fieldType="select"
									{...field}
									placeholder={t('contracts_tab.recover.select_from_dict_or_input')}
									error={touched && error ? t(error) : ''}
								/>
							)}
						</FormikField>
					</div>
				)}
			</div>

			<div className={styles.button__wrapper}>
				<div className={styles.cancel__button} onClick={onClose}>
					{t('cancel')}
				</div>
				<Button disabled={!isValid} type="submit">
					{t('contracts_tab.recover.sign_and_recover')}
				</Button>
			</div>
		</FormikForm>
	)
}
