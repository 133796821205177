import * as types from './types'

const initialState = {
	content: [],
	pageable: null,
	totalPages: 0,
	totalElements: 6,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: true,
	error: '',
	badgeContent: [],
	badgeLength: 0,
	queryState: {
		page: 0,
		pageSize: 10,
		unread: false,
		typeCode: 'NOTIF',
		searchText: null,
		rowsPerPage: [
			{ size: 10, label: '10' },
			{ size: 30, label: '30' },
			{ size: 50, label: '50' },
			{ size: 100, label: '100' },
			// { size: null, label: 'pagination.show_all' },
		],
	},
	isLoading: false,
	isSuccess: false,
	tags: [],
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.SET_NOTIFICATIONS:
			return {
				...state,
				content: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_TAGS:
			return {
				...state,
				tags: payload,
			}

		case types.SET_NOTIFICATIONS_BADGE:
			return {
				...state,
				badgeContent: payload.content,
				badgeLength: payload.totalElements,
			}
		case types.RESET_NOTIFICATIONS_BADGE:
			return {
				...state,
				badgeContent: [],
				badgeLength: 0,
			}

		case types.SET_NOTIFICATION_READ: {
			var dateTimeNow = new Date()
			return {
				...state,
				badgeContent: state.badgeContent.map((item, i) =>
					item.id == payload
						? { ...item, readDate: dateTimeNow.toLocaleDateString() + ' ' + dateTimeNow.toLocaleTimeString() }
						: item
				),
				content: state.content.map((item, i) =>
					item.id == payload
						? { ...item, readDate: dateTimeNow.toLocaleDateString() + ' ' + dateTimeNow.toLocaleTimeString() }
						: item
				),
				badgeLength: state.badgeLength - 1,
			}
		}

		case types.SET_NOTIFICATION_READ_FRON_UNREAD_PANEL: {
			return {
				...state,
				content: state.content.filter((item) => item.id != payload),
				badgeLength: state.badgeLength - 1,
			}
		}

		case types.SET_NOTIFICATION_UNREAD: {
			return {
				...state,
				badgeContent: state.badgeContent.map((item, i) => (item.id == payload ? { ...item, readDate: null } : item)),
				content: state.content.map((item, i) => (item.id == payload ? { ...item, readDate: null } : item)),
				badgeLength: state.badgeLength + 1,
			}
		}

		case types.SET_ERROR:
			return {
				...state,
				error: payload,
			}

		case types.SET_QUERY_PARAMS:
			return {
				...state,
				queryState: payload,
			}

		case types.SET_ALL:
			return {
				...state,
				notifications: payload,
			}
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		default:
			return state
	}
}
