import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { Error } from '@/ui'
import styles from './style.module.scss'
import { VariableSizeList as List } from 'react-window'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
const ITEM_HEIGHT_IN_PX = 42

const Option = ({
	code,
	id,
	label,
	onSelect,
	style,
	setSize,
	disabled,
	active,
	setActive,
}) => {
	const ref = useRef()
	useEffect(() => ref.current && setSize(ref.current.offsetHeight + 24), [ref])

	const handleClick = useCallback(() => {
		if (disabled) {
			setActive(id === active ? null : id)
			return null
		}
		onSelect(code)
	}, [code, onSelect, disabled])
	return (
		<li className={`${styles.option}`} onClick={handleClick} style={style}>
			<div className={disabled ? styles.icon__wrapper : styles.icon__wrapper__child}>
				{disabled &&
					(active === id ? <KeyboardArrowUpIcon fontSize={'large'} /> : <KeyboardArrowDownIcon fontSize={'large'} />)}
			</div>
			<label className={disabled ? styles.parent__label : ''} ref={ref}>
				{label}
			</label>
		</li>
	)
}

export const Options = ({ options, onSelect, showItems = 5, onBlur, optionsStyles = {}, hideNotFoundError }) => {
	const [active, setActive] = useState(null)
	const parents = options.filter((option) => option.parentId === null)
	for (let i = 0; i < parents.length; i++) {
		parents.splice(i + 1, 0, ...options.filter((f) => f.parentId === parents[i].id))
	}
	const _options = parents?.filter((item) => item.parentId === active || item.parentId === null)
	const isOptionsLessThenShowItems = _options.length < showItems
	const listHeight = useMemo(
		() => (!isOptionsLessThenShowItems ? showItems : _options.length) * ITEM_HEIGHT_IN_PX,
		[isOptionsLessThenShowItems, _options.length, showItems]
	)
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const lang = useMemo(() => (currentLanguage === 'rus' ? 'nameRu' : 'nameKz'), [currentLanguage])

	const listRef = useRef()
	const sizeMap = useRef({})
	const setSize = useCallback((index, size) => {
		sizeMap.current = { ...sizeMap.current, [index]: size }
		listRef.current.resetAfterIndex(index)
	}, [])
	const renderRow = ({ index, style }) => {
		const { id, code, icon, parentId, fndChild, ...option } = _options[index]
		return (
			<React.Fragment key={`option-${id}`}>
				<Option
					code={code}
					id={id}
					label={option[currentLanguage] || option[lang]}
					onSelect={onSelect}
					style={style}
					setSize={(ref) => setSize(index, ref)}
					disabled={parentId === null}
					active={active}
					setActive={setActive}
				/>
			</React.Fragment>
		)
	}

	const getSize = (index) => sizeMap.current[index] || ITEM_HEIGHT_IN_PX
	return (
		<ul
			className={`${!isOptionsLessThenShowItems ? ` scroll` : ''} ${styles.options}`}
			style={{ height: listHeight, ...optionsStyles, paddingLeft: 0 }}
			onBlur={onBlur}
		>
			{_options.length > 0 ? (
				<List
					className="scroll"
					ref={listRef}
					height={listHeight}
					itemCount={_options.length}
					itemSize={getSize}
					onClick
				>
					{renderRow}
				</List>
			) : (
				<>
					{!hideNotFoundError && (
						<p className={styles.error}>
							<Error>{t('no_data_find')}</Error>
						</p>
					)}
				</>
			)}
		</ul>
	)
}
