import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import styles from '../TerminateElectronic.module.scss'
import { useTranslation } from 'react-i18next'
import { Button as AppButton } from '@/ui'
import { useSelector } from 'react-redux'
import { contractsSelectors, userSelectors } from '@/entity'
import { request } from '@/shared/api/request'
import { useDigitalSign } from '@/shared'
import CloseButton from '@/components/CloseButton/CloseButton'
import { message } from 'antd'

export const Sign = ({ onClose, onUpdate, selectedContract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const activeRole = useSelector(userSelectors.activeRole)
	const { successPayload, onClearPayload, onSign, onSignMultiple } = useDigitalSign()

	const onSubmit = async () => {
		if (activeRole == 'ROLE_EMPLOYEE') {
			try {
				const res = await request({
					url: '/contract/eContractTerm/employee/xml',
					method: 'GET',
					params: {
						termExternalId: selectedContract.termExternalId,
					},
				})
				onSign(res.xmlData)
			} catch (error) {
				const err = error?.response?.data?.message || 'Неизвестная ошибка'
				message.error(err ? err : 'Неизвестная ошибка', 5)
			}
		} else {
			try {
				const res = await request({
					url: '/contract/eContractTerm/employer/xml',
					method: 'GET',
					params: {
						termExternalId: selectedContract.termExternalId,
					},
				})
				onSignMultiple(res)
			} catch (error) {
				const err = error?.response?.data?.message || 'Неизвестная ошибка'
				message.error(err ? err : 'Неизвестная ошибка', 5)
			}
		}
	}

	const senSignedEtd = async () => {
		if (successPayload) {
			if (activeRole == 'ROLE_EMPLOYEE') {
				try {
					// setTemplatesLoading(true)
					const res = await request({
						url: `/contract/eContractTerm/employee/saveSign`,
						method: 'POST',
						data: {
							xmlData: successPayload,
						},
					})
					onClearPayload()
					message.success(t('success_alert'))
					onUpdate()
					onClose()
				} catch (error) {
					const err = error?.response?.data?.message || 'Неизвестная ошибка'
					message.error(err ? err : 'Неизвестная ошибка', 5)
				}
			} else {
				try {
					await request({
						url: `/contract/eContractTerm/employer/saveSign`,
						method: 'POST',
						data: successPayload,
					})
					onClearPayload()
					message.success(t('success_alert'))
					onUpdate()
					onClose()
				} catch (error) {
					const err = error?.response?.data?.message || 'Неизвестная ошибка'
					alert(err ? err : 'Неизвестная ошибка')
				}
			}
		}
	}

	useEffect(() => senSignedEtd(), [successPayload])

	const [disableContinue, setDisableContinue] = useState(false)

	return (
		<Dialog scroll={'paper'} classes={{ paper: styles.modal__dialog }} onClose={onClose} open={true}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box className={styles.modal__text}>{t('electronic_contract.terminate')}</Box>
				<Box display="flex" alignItems="center" justifyContent="center">
					<AppButton onClick={onSubmit}>{t('sign_button')}</AppButton>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="center">
					<AppButton transparent onClick={onClose}>
						{t('cancel')}
					</AppButton>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
