import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Field as FormikField, connect } from 'formik'
import { Field } from '@/ui'
import styles from './ContractPosition.module.scss'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { usePrevious } from '@/shared'

export const ContractPosition = connect(
	({
		positions = [],
		positionsByKnz = [],
		workCondition = [],
		positionsByKnzFull = [],
		formik: { setFieldValue, values, errors },
		isFormDirty,
		isElectronicButton = true,
		immigrant = false,
	}) => {
		const lang = getCurrentLanguage()

		const [currentWorkCondition, setCurrentWorkCondition] = useState(workCondition)
		const establishedPosition = useMemo(
			() => values.destablishedPostCode || values.establishedPost,
			[values.destablishedPostCode, values.establishedPost]
		)
		const { t } = useTranslation()
		const handleEstablishedPositionChange = useCallback(
			(code) => {
				let position = positions?.find((item) => item.code === code)
				if (positions?.find((item) => item.code === code)) {
					/** Если значение из справочника, то заполняем destablishedPostCode */
					setFieldValue('establishedPost', lang === 'rus' ? position[lang || 'rus'] : position[lang || 'kz'])
					setFieldValue('destablishedPostCode', code)
				} else {
					/** Если ручной ввод, то заполняем значение establishedPost */
					setFieldValue('establishedPost', code)
					setFieldValue('destablishedPostCode', null)
				}
			},
			[positions, setFieldValue]
		)
		const { dpositionCode, dworkingConditionsCode } = values
		useEffect(() => {
			if (!!dpositionCode) {
				GetPositionTree(dpositionCode)
			} else setFieldValue('positionParents', '')
		}, [dpositionCode])
		const prevValue = usePrevious(dworkingConditionsCode)
		useEffect(() => {
			if (prevValue) {
				setFieldValue('positionParents', '')
				setFieldValue('dpositionCode', null)
				setFieldValue('dharmfulConditionsTypeCode', null)
			}
		}, [dworkingConditionsCode])

		let GetPositionTree = (code) => {
			const itemMap = {}
			positionsByKnzFull.forEach((item) => {
				itemMap[item.id] = item
			})
			const parentNames = []
			let currentItem = positionsByKnz.find((i) => i.code === code)

			while (currentItem && currentItem.parentId !== null) {
				parentNames.unshift(lang === 'rus' ? currentItem.rus : currentItem.kz)
				currentItem = itemMap[currentItem.parentId]
			}
			setFieldValue('positionParents', parentNames.join(' / '))
		}

		// Здесь добавляем useMemo для фильтрации positionsByKnz
		const filteredPositionsByKnz = useMemo(() => {
			return immigrant ? positionsByKnz.filter((item) => item.laborImmigrant === true) : positionsByKnz
		}, [immigrant, positionsByKnz])

		useEffect(() => {
			if (immigrant) {
				setFieldValue('dworkingConditionsCode', '0')
			}
		}, [immigrant])

		const isAfter2014 = (dateString) => {
			// Разделение строки даты на день, месяц и год
			const [day, month, year] = dateString.split('.')
			// Создание объекта Date с правильным форматом (месяцы в JS начинаются с 0)
			const dateFrom = new Date(year, month - 1, day)
			const referenceDate = new Date('2014-01-01') // для начала 2014 года
			return dateFrom >= referenceDate
		}

		useEffect(() => {
			if (!values.dateFrom || isAfter2014(values.dateFrom)) {
				setCurrentWorkCondition(workCondition)
			} else {
				if (values.dworkingConditionsCode === '2') {
					setFieldValue('dworkingConditionsCode', null)
					setFieldValue('dpositionCodeList1', null)
					setFieldValue('dpositionCodeList2', null)
					setFieldValue('dpositionCodeList3', null)
				}
				setCurrentWorkCondition(workCondition.filter((it) => it.code !== '2'))
			}
		}, [values.dateFrom])

		return isElectronicButton ? (
			<div className={styles.col}>
				<Field
					options={positions}
					type="text"
					fieldType="selectAndInput"
					isRequired
					placeholder={t('position_placeholder')}
					onChange={handleEstablishedPositionChange}
					value={establishedPosition}
					hint={t('hint_position')}
					label={t('contract.info.position')}
					error={isFormDirty && !establishedPosition && { key: 'required' }}
				/>
				<FormikField name="dpositionCode">
					{({ field, meta: { touched, error } }) => (
						<Field
							fieldType="selectSearch"
							label={t('contract.info.nkz_position')}
							hint={t('hint_position_knz')}
							placeholder={t('choose_from_directory')}
							options={filteredPositionsByKnz}
							error={(touched || isFormDirty) && error}
							isRequired
							{...field}
						/>
					)}
				</FormikField>
			</div>
		) : (
			<div className={styles.col}>
				<div className={styles.fields}>
					<Field
						options={positions}
						type="text"
						fieldType="selectAndInput"
						isRequired
						placeholder={t('position_placeholder')}
						onChange={handleEstablishedPositionChange}
						value={establishedPosition}
						hint={t('hint_position')}
						label={t('contract.info.position')}
						error={isFormDirty && !establishedPosition && { key: 'required' }}
					/>
					<FormikField name="dworkingConditionsCode">
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="select"
								isRequired
								label={t('contract.info.working_condition')}
								placeholder={t('choose_from_directory')}
								options={currentWorkCondition}
								error={(touched || isFormDirty) && error}
								type="text"
								hint={immigrant ? '' : t('hint_working_condition')}
								{...field}
								disabled={immigrant}
							/>
						)}
					</FormikField>
				</div>
				<FormikField name="dpositionCode">
					{({ field, meta: { touched, error } }) => (
						<Field
							fieldType="selectSearch"
							label={t('contract.info.nkz_position')}
							hint={t('hint_position_knz')}
							placeholder={t('choose_from_directory')}
							options={filteredPositionsByKnz}
							error={(touched || isFormDirty) && error}
							isRequired
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name="positionParents">
					{({ field }) => (
						<Field
							labelClassName={styles.formLabel}
							type="text"
							fieldType="textarea"
							label={t('hierarchy_categories')}
							rows={5}
							disabled={true}
							{...field}
						/>
					)}
				</FormikField>
			</div>
		)
	}
)
