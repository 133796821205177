import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Arrow } from '@app/icons'
import { useToggle, useClickOutside } from '@/shared'
import { Options } from '@/ui/atoms'
import { InputWithIcon } from '@/ui'
import styles from './style.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const SelectAndInput = ({
	value,
	onChange,
	options = [],
	showItems = 5,
	placeholder,
	onBlur,
	required = false,
	...props
}) => {
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const [lastUsedType, setLastUsedType] = useState('select')
	const [filteredOptions, setFilteredOptions] = useState(options)
	const fieldRef = useRef()
	const currentLang = getCurrentLanguage()

	useEffect(() => {
		if (filteredOptions.length === 0 && options?.length) setFilteredOptions(options)
	}, [options])

	const onSelect = (id) => {
		onChange(id)
		toggleOpenedOptions()
		setLastUsedType('select')
		setFilteredOptions(options.filter(({ code }) => code === id))
	}

	const onChangeValue = (value) => {
		setLastUsedType('input')
		setFilteredOptions(options.filter((option) => option[currentLang]?.toLowerCase().includes(value.toLowerCase())))
		onChange(value)
	}

	const onClickOutside = (event) => {
		event.target.blur()
		toggleOpenedOptions()
	}

	const selectedValue = useMemo(
		() => (lastUsedType === 'select' ? options.find(({ code }) => code === value) ?? value : value),
		[lastUsedType, options, value]
	)

	useClickOutside(fieldRef, isOpenedOptions, onClickOutside)
	return (
		<div className={styles.wrapper} ref={fieldRef}>
			<InputWithIcon
				onChange={onChangeValue}
				placeholder={placeholder}
				onClick={toggleOpenedOptions}
				value={selectedValue?.[currentLang] ?? selectedValue}
				iconSide={'right'}
				icon={
					<Arrow
						styles={{ fill: '#AAB8D1', transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`, width: '10px' }}
					/>
				}
				required={required}
				{...props}
			/>
			{isOpenedOptions && (
				<Options onSelect={onSelect} options={filteredOptions} showItems={showItems} onBlur={onBlur} />
			)}
		</div>
	)
}
