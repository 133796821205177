import React, { useEffect } from 'react'
import { Field as FormikField, connect } from 'formik'
import { Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { isHarmfulWorkingConditions } from '@/utils'

export const WorkingConditions = connect(
	({ workActivityTypeGroups = [], formik: { setFieldValue, values }, isFormDirty }) => {
		const { t } = useTranslation()

		//Включение валидация списков
		useEffect(() => {
			setFieldValue('dpositionCodeList1', null)
			setFieldValue('dpositionCodeList2', null)
			setFieldValue('dpositionCodeList3', null)

			switch (values.dworkingConditionsCode) {
				case '1':
					setFieldValue('requiredList1', true)
					setFieldValue('requiredList2', true)
					setFieldValue('requiredList3', true)
					break
				case '2':
					setFieldValue('requiredList1', false)
					setFieldValue('requiredList2', false)
					setFieldValue('requiredList3', true)
					break
				default:
					setFieldValue('requiredList1', false)
					setFieldValue('requiredList2', false)
					setFieldValue('requiredList3', false)
					break
			}
		}, [values.dworkingConditionsCode])

		useEffect(() => {
			if (values.dharmfulConditionsTypeCode && isHarmfulWorkingConditions(values.dworkingConditionsCode)) {
				const prefix = values.dharmfulConditionsTypeCode?.charAt(1)
				setFieldValue(`dpositionCodeList${prefix}`, values.dharmfulConditionsTypeCode)
			}
		}, [])

		useEffect(() => {
			let trueIndex = null
			if (values.dpositionCodeList1) trueIndex = '1'
			else if (values.dpositionCodeList2) trueIndex = '2'
			else if (values.dpositionCodeList3) trueIndex = '3'
			;['1', '2', '3'].forEach((index) => {
				setFieldValue(`requiredList${index}`, index === trueIndex)
			})

			if (!trueIndex) {
				;['1', '2', '3'].forEach((index) => {
					setFieldValue(`requiredList${index}`, false)
				})
			}
		}, [values.dpositionCodeList1, values.dpositionCodeList2, values.dpositionCodeList3])

		useEffect(() => {
			const hasNonEmptyValue = [values.dpositionCodeList1, values.dpositionCodeList2, values.dpositionCodeList3].some(
				(val) => val != null && val !== ''
			)

			hasNonEmptyValue &&
				setFieldValue(
					'dharmfulConditionsTypeCode',
					values.dpositionCodeList1 || values.dpositionCodeList2 || values.dpositionCodeList3
				)
		}, [values.dpositionCodeList1, values.dpositionCodeList2, values.dpositionCodeList3])

		return (
			<>
				<FormikField name="dpositionCodeList1">
					{({ field, meta: { touched, error } }) => {
						return (
							<Field
								fieldType="selectSearch"
								label={t('conditions1')}
								hint={t('conditions_hint1')}
								placeholder={t('choose_from_directory')}
								options={workActivityTypeGroups.list1.filter((item) => item.parentId != null)}
								error={(touched || isFormDirty) && error}
								disabled={
									Boolean(values.dpositionCodeList2 || values.dpositionCodeList3) ||
									values.dworkingConditionsCode !== '1'
								}
								{...field}
							/>
						)
					}}
				</FormikField>
				<FormikField name="dpositionCodeList2">
					{({ field, meta: { touched, error } }) => {
						return (
							<Field
								fieldType="selectSearch"
								label={t('conditions2')}
								placeholder={t('choose_from_directory')}
								hint={t('conditions_hint2')}
								options={workActivityTypeGroups.list2.filter((item) => item.parentId != null)}
								error={(touched || isFormDirty) && error}
								disabled={
									Boolean(values.dpositionCodeList1 || values.dpositionCodeList3) ||
									values.dworkingConditionsCode !== '1'
								}
								{...field}
							/>
						)
					}}
				</FormikField>
				<FormikField name="dpositionCodeList3">
					{({ field, meta: { touched, error } }) => {
						return (
							<Field
								fieldType="selectSearch"
								label={t('conditions3')}
								placeholder={t('choose_from_directory')}
								hint={t('conditions_hint3')}
								options={workActivityTypeGroups.list3.filter((item) => item.parentId != null)}
								error={(touched || isFormDirty) && error}
								disabled={
									Boolean(values.dpositionCodeList1 || values.dpositionCodeList2) ||
									!isHarmfulWorkingConditions(values.dworkingConditionsCode)
								}
								{...field}
							/>
						)
					}}
				</FormikField>
			</>
		)
	}
)
