import React, { useState, useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, IconButton, Button, Box } from '@mui/material'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Field } from '@/ui'
import CloseButton from '@/components/CloseButton/CloseButton'

export const SaveTemplate = ({ isOpen, onClose, onConfirm, selectedTemplate }) => {
	const { t } = useTranslation()

	const [value, setValue] = useState('Шаблон 1')

	useEffect(() => {
		if (selectedTemplate) {
			setValue(selectedTemplate.templateName)
		}
	}, [selectedTemplate])

	return (
		<Dialog classes={{ paper: styles.dialog__save }} onClose={onClose} open={isOpen}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box></Box>
					<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Box className={`${styles.title} ${styles.title__save}`}>{t('set_new_template_name')}:</Box>
				<Box display="flex" justifyContent="space-between">
					<Box className={styles.save__input__wrapper}>
						<Field value={value} onChange={setValue} />
					</Box>
					<Button
						onClick={onConfirm.bind(null, value)}
						variant="contained"
						classes={{ root: `${styles.confirm__button} ${styles.confirm__button__save}` }}
					>
						{t('save')}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
