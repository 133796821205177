import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect } from 'react'
import styles from './CorrectionRequests.module.scss'
import { laborRecordsSelectors, laborRecordsThunks } from '@/entity'
import { Table } from './components/Table'
import { Loading } from '@/ui/atoms/loading'

export const CorrectionRequests = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector(laborRecordsSelectors.isAdjustmentLoading)
	const isSuccess = useSelector(laborRecordsSelectors.isAdjustmentSuccess)
	const request = useSelector(laborRecordsSelectors.requestsAdjustment)

	const fetchData = useCallback(() => {
		dispatch(laborRecordsThunks.getRequestsAdjustmentJur())
	}, [dispatch])

	useEffect(() => fetchData(), [fetchData])

	return (
		<div className={styles.wrapper}>
			{!!request?.length ? (
				<Table tableData={request}></Table>
			) : (
				<Loading isLoading={isLoading} isSuccess={isSuccess} request={request}></Loading>
			)}
		</div>
	)
}
