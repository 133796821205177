import React from 'react'
import { DialogTitle, DialogContent, Dialog, IconButton, Button, Box } from '@mui/material'
import { Button as DialogButton } from '@/ui'
import styles from '../style.module.scss'
import currentStyle from './style.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'

const ModalReset = ({ onClose, onReset, isOpen }) => {
	const { t } = useTranslation()

	return (
		<Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={isOpen}>
			<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
			<DialogTitle classes={{ root: cn(styles.title, styles.reset__title) }}>
				{t('electronic_contract.reset_question')}
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: '40px' }}>
				<div className={currentStyle.dialog__buttons__wrapper}>
					<div className={currentStyle.reset__button__wrapper}>
						<DialogButton type="button" onClick={onReset} size={'16'}>
							{t('electronic_contract.reset')}
						</DialogButton>
					</div>
					<Button
						type="button"
						variant="text"
						classes={{ root: cn(styles.button, styles.reset__button, currentStyle.reset__button) }}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ModalReset
