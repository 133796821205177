import TabPanel from '@mui/base/TabPanel'
import TabsUnstyled from '@mui/base/Tabs'
import * as React from 'react'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import { useTranslation } from 'react-i18next'
import PersonalAffairsMainTab from './Tabs/PersonalAffairsMainTab/PersonalAffairsMainTab'
import PersonalAffairsRequestMainTab from './Tabs/PersonalAffairsRequestMainTab/PersonalAffairsRequestMainTab'
import ProactiveMainTab from './Tabs/ProactiveMainTab/ProactiveMainTab'
import { ProfileTemplate } from '@/widgets'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import WorkerWelfareMainTab from './Tabs/WorkerWelfareMainTab/WorkerWelfareMainTab'
import SocialPaymentMainTab from './Tabs/SocialPaymentMainTab/SocialPaymentMainTab'
import styles from './PersonalAffairsPage.module.scss'
const PersonalAffairsPage = () => {
	const { t } = useTranslation()
	const { allowProactive } = useSelector(employerProfileSelectors.activeCompany)
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<ProfileTemplate title={'personal_affairs.worker'}>
				<TabsUnstyled defaultValue={0}>
					<TabsList>
						<Tab className={styles.minWidth} value={0}>
							{t('personal_affairs.title')}
						</Tab>
						<Tab className={styles.minWidth} value={1}>
							{t('personal_affairs.requests_title')}
						</Tab>
						{allowProactive ? (
							<Tab className={styles.minWidth} value={2}>
								{t('personal_affairs.request_proactive')}
							</Tab>
						) : null}

						<Tab className={styles.minWidth} value={4}>
							{t('payment.tab')}
						</Tab>
					</TabsList>
					<TabPanel value={0}>
						<PersonalAffairsMainTab />
					</TabPanel>
					<TabPanel value={1}>
						<PersonalAffairsRequestMainTab />
					</TabPanel>
					{allowProactive ? (
						<TabPanel value={2}>
							<ProactiveMainTab />
						</TabPanel>
					) : null}
					<TabPanel value={4}>
						<SocialPaymentMainTab />
					</TabPanel>
				</TabsUnstyled>
			</ProfileTemplate>
		</>
	)
}
export default PersonalAffairsPage
