import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Timeout = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3856_39595)">
                    <path d="M10.8491 10.1024L8.61828 8.42924V5.02105C8.61828 4.67837 8.34128 4.40137 7.99859 4.40137C7.65591 4.40137 7.37891 4.67837 7.37891 5.02105V8.73912C7.37891 8.93431 7.47062 9.11837 7.62678 9.23487L10.1055 11.0939C10.217 11.1776 10.3472 11.2178 10.4767 11.2178C10.6657 11.2178 10.8516 11.1329 10.973 10.9693C11.1788 10.696 11.123 10.3075 10.8491 10.1024Z" fill="#AAB8D1"/>
                    <path d="M8 0.69043C3.58853 0.69043 0 4.27896 0 8.69043C0 13.1019 3.58853 16.6904 8 16.6904C12.4115 16.6904 16 13.1019 16 8.69043C16 4.27896 12.4115 0.69043 8 0.69043ZM8 15.4511C4.27266 15.4511 1.23934 12.4178 1.23934 8.69043C1.23934 4.96309 4.27266 1.92977 8 1.92977C11.728 1.92977 14.7607 4.96309 14.7607 8.69043C14.7607 12.4178 11.7273 15.4511 8 15.4511Z" fill="#AAB8D1"/>
                </g>
                <defs>
                    <clipPath id="clip0_3856_39595">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.69043)"/>
                    </clipPath>
                </defs>
            </svg>
        </Svg>
    )
};

export default Timeout;
