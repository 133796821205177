import { api } from '@/shared'
import { reviewActions } from '@/entity/review/index'
import { message } from 'antd'

export const setReviewXml = (payload) => {
	const {data, onSuccess} = payload
	return async (dispatch) => {
		try {
			dispatch(reviewActions.reviewAction())

			const res = await api.review.postReviewXml(data)

			if(res){
				onSuccess(res)
				dispatch(reviewActions.reviewActonDone())
			}

		} catch (error) {
			dispatch(reviewActions.reviewActonFail())
		}
	}
}

export const setReview = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch, getState) => {
		const state = getState()

		try {
			dispatch(reviewActions.reviewAction())

			const res = await api.review.postReview(data)

			if(res){
				onSuccess()

				dispatch(reviewActions.reviewActonDone(res))

				dispatch(reviewActions.currentReview(null))
			}

		} catch (error) {
			dispatch(reviewActions.reviewActonFail())

			message.error(error.response.data.message)
		}
	}
}

export const deleteReviewXml = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(reviewActions.reviewAction())

			const res = await api.review.deleteReviewXml(data)

			if(res){
				onSuccess(res)
				dispatch(reviewActions.reviewActonDone(res))
			}

		} catch (error) {

			dispatch(reviewActions.reviewActonFail())
		}
	}
}

export const deleteReview = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch, getState) => {
		const state = getState()

		try {
			dispatch(reviewActions.reviewAction())

			const res = await api.review.postReview(data)

			if(res){
				onSuccess(res)

				dispatch(reviewActions.reviewActonDone(res))

				dispatch(reviewActions.currentReview(null))
			}

		} catch (error) {
			dispatch(reviewActions.reviewActonFail())

			message.error(error.response.data.message)
		}
	}
}

export const getReview = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(reviewActions.getReviewAction())

			const res = await api.review.getReview(payload)

			if(res){
				dispatch(reviewActions.getReviewActonDone(res))
			}

		} catch (error) {
			message.error(error.message)

			dispatch(reviewActions.getReviewActonFail())
		}
	}
}