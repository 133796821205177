import { styled } from '@mui/system'
import TabsListUnstyled from '@mui/base/TabsList'

const TabsList = styled(TabsListUnstyled)`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`
export default TabsList
