import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	isError: false,
	isRefreshContracts: false,
	laborRecords: [],
	isAdjustmentLoading: false,
	isAdjustmentSuccess: false,
	isAdjustmentError: false,
	isAdjustmentRefresh: false,
	requestsAdjustmentList: [],
	isRequestLoading: false,
	isRequestSuccess: false,
	isRequestError: false,
	requestDetail: [],
	shortContractData: [],
	adjustmentXml: null,
	isAdjustmentXmlLoading: false,
	isAdjustmentXmlSuccess: false,
	cancelAdjustmentXml: null,
	isCancelAdjustmentXmlLoading: false,
	isCancelAdjustmentXmlSuccess: false,
	isLocalDigitContractAdded: false,
	digitContractLocal: [],
}
export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.TOGGLE_ERROR:
			return {
				...state,
				isError: payload,
			}
		case types.SET_LABOR_RECORDS:
			return {
				...state,
				contractCount: payload.contractCount,
				contractList: payload.contractList,
				experience: payload.experience,
				laborDigitalizationCount: payload.laborDigitalizationCount,
				laborDigitalizationList: payload.laborDigitalizationList,
				photo: payload.photo,
			}
		case types.TOGGLE_ADJUSTMENT_LOADING:
			return {
				...state,
				isAdjustmentLoading: payload,
			}
		case types.TOGGLE_ADJUSTMENT_SUCCESS:
			return {
				...state,
				isAdjustmentSuccess: payload,
			}
		case types.TOGGLE_ADJUSTMENT_ERROR:
			return {
				...state,
				isAdjustmentError: payload,
			}
		case types.TOGGLE_ADJUSTMENT_REFRESH:
			return {
				...state,
				isAdjustmentRefresh: payload,
			}
		case types.SET_REQUESTS_ADJUSTMENT:
			return {
				...state,
				requestsAdjustmentList: payload.requestsAdjustmentList,
			}
		case types.TOGGLE_REQUEST_LOADING:
			return {
				...state,
				isRequestLoading: payload,
			}
		case types.TOGGLE_REQUEST_SUCCESS:
			return {
				...state,
				isRequestSuccess: payload,
			}
		case types.TOGGLE_REQUEST_ERROR:
			return {
				...state,
				isRequestError: payload,
			}
		case types.SET_REQUEST_DETAIL:
			return {
				...state,
				requestDetail: payload,
			}
		case types.SET_SHORT_CONTRACT_DATA:
			return {
				...state,
				shortContractData: payload,
			}
		case types.SET_ADJUSTMENT_XML:
			return {
				...state,
				adjustmentXml: payload,
			}
		case types.TOGGLE_ADJUSTMENT_XML_LOADING:
			return {
				...state,
				isAdjustmentXmlLoading: payload,
			}
		case types.TOGGLE_ADJUSTMENT_XML_SUCCESS:
			return {
				...state,
				isAdjustmentXmlSuccess: payload,
			}
		case types.TOGGLE_LOCAL_DIGIT_SUCCESS:
			return {
				...state,
				isLocalDigitContractAdded: payload,
			}
		case types.RESET_ADJUSTMENT:
			return {
				...state,
				adjustmentXml: null,
				isAdjustmentXmlLoading: false,
				isAdjustmentXmlSuccess: false,
				isLocalDigitContractAdded: false
			}
		case types.SET_CANCEL_ADJUSTMENT_XML:
			return {
				...state,
				cancelAdjustmentXml: payload,
			}
		case types.TOGGLE_CANCEL_ADJUSTMENT_XML_LOADING:
			return {
				...state,
				isCancelAdjustmentXmlLoading: payload,
			}
		case types.TOGGLE_CANCEL_ADJUSTMENT_XML_SUCCESS:
			return {
				...state,
				isCancelAdjustmentXmlSuccess: payload,
			}
		case types.DIGIT_CONTRACT_LOCAL:
			return {
				...state,
				digitContractLocal: payload,
			}
		case types.REFRESH_CONTRACTS:
			return {
				...state,
				isRefreshContracts: payload,
			}
		default:
			return state
	}
}
