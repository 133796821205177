export const Arrow = ({ styles, className, onClick }) => {
	return (
		<svg
			width="5"
			height="8"
			viewBox="0 0 5 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={styles}
			onClick={onClick}
		>
			<path d="M3.86131 0.273438L0.0566406 4.0781L3.86131 7.88277L4.80397 6.9401L1.94197 4.0781L4.80397 1.2161L3.86131 0.273438Z" />
		</svg>
	)
}
