import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mainSelectors, mainThunks, mainActions } from '@/app/store/main'
import { useDispatch, useSelector } from 'react-redux'
import styles from './modalFeedback.style.module.scss'
import { useDropzone } from 'react-dropzone'
import paper from '../../assets/home/PaperClip.svg'
import Button from 'components/Button'
import doc from '../../assets/home/doc.svg'
import trash from '../../assets/home/trash.svg'
import Captcha from '@/components/Captcha/Captcha'
import { EMAIL_REGEX, IIN_REGEX, PHONE_REGEX } from '@/constants/regex'
import s from './verificationEmailInput.module.css'
import NumberFormat from 'react-number-format'
import { Select, SelectList } from '@/ui'
import { handbookSelectors } from '@/entity/handbook'
import { getCurrentLanguage } from '@/i18next'
import classNames from 'classnames'

export const ModalFeedback = ({ visible, onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { getInputProps, acceptedFiles } = useDropzone({ noClick: true })
	const isSuccessSend = useSelector(mainSelectors.isSuccessSend)
	const [isVisible, setIsVisible] = useState(false)
	const [files, setFiles] = useState([])
	const [errorForm, setErrorForm] = useState('')
	const [imgName, setImgName] = useState('')
	const [showErrorPhone, setShowErrorPhone] = useState(false)
	const [showErrorMail, setShowErrorMail] = useState(false)
	const [showErrorIin, setShowErrorIin] = useState(false)
	const [mail, setMail] = useState('')
	const [iin, setIin] = useState('')
	const [phone, setPhone] = useState('')
	const [kato, setKato] = useState(null)
	const [form, setForm] = useState({
		topic: '',
		message: '',
		name: '',
	})
	const [captchaValue, setCaptchaValue] = useState('[empty]')
	const [disable, setDisable] = useState(true)
	const [isCompact, setIsCompact] = useState(window.innerWidth < 375)

	const districts = useSelector(handbookSelectors.districts)

	useEffect(() => {
		setIsVisible(visible)
		dispatch(mainActions.toggleSuccessSend(false))
	}, [dispatch, visible])

	useEffect(() => {
		setFiles([...files, ...acceptedFiles])
	}, [acceptedFiles])

	useEffect(() => {
		if (isSuccessSend) {
			setForm({
				topic: '',
				message: '',
				name: '',
			})
			setPhone('')
			setMail('')
			setIin('')
			setFiles([])
			setCaptchaValue('[empty]')
		}
	}, [isSuccessSend])

	useEffect(() => {
		if (captchaValue === '[empty]') {
			setDisable(true)
		} else {
			setDisable(false)
		}
	}, [captchaValue])
	useEffect(() => {
		if (!showErrorPhone && !showErrorMail && !showErrorIin) setErrorForm('')
	}, [showErrorPhone, showErrorMail, showErrorIin])

	const validForm = (e) => {
		if (!mail || !phone || showErrorMail || showErrorIin || !form.message || showErrorPhone) {
			e.preventDefault()
			setErrorForm('fill_in_full_data')
		} else {
			setErrorForm('')
			setImgName('')
			let formData = new FormData()
			formData.set('topic', form.topic)
			formData.set('data', form.message)
			formData.set('phone', phone)
			formData.set('name', form.name)
			formData.set('mail', mail)
			formData.set('kato', kato)
			formData.set('iin', iin)

			if (checkFilesSize()) {
				for (let i = 0; i < files.length; i++) {
					formData.append(`fileUpload`, files[i])
				}
				dispatch(mainThunks.setFeedback(formData, captchaValue))
			} else {
				setErrorForm('max_file_size')
			}
		}
	}

	const getFileName = (e) => {
		const files = e.target.files
		setForm((prevState) => ({ ...prevState, files }))
		setImgName(files[0].name)
	}

	const checkFilesSize = () => {
		const MAX_SIZE_IN_BYTES = 15728640
		const filesSize = files.reduce((acc, file) => file.size + acc, 0)
		return filesSize <= MAX_SIZE_IN_BYTES
	}

	const onChange = (field) => (e) => {
		const value = e.target.value
		setForm((prevState) => ({ ...prevState, [field]: value }))
	}

	const onSubmit = async (e) => {
		e.preventDefault()
		if (isSuccessSend) {
			dispatch(mainThunks.contactUs({ ...form, files }))
		}
	}

	const getCaptchaValue = (value) => {
		setCaptchaValue(value)
	}

	const emailHandler = (e) => {
		const value = e.target.value
		let result = EMAIL_REGEX.test(value)
		setMail(value)
		if (result) {
			setShowErrorMail(false)
		} else {
			setShowErrorMail(true)
		}
	}

	const iinHandler = (e) => {
		const value = e.target.value
		let result = IIN_REGEX.test(value)
		setIin(value)
		if (result) {
			setShowErrorIin(false)
		} else {
			setShowErrorIin(true)
		}
	}

	const verifyHandler = (e) => {
		const value = e.target.value
		let result = PHONE_REGEX.test(value)
		setPhone(value)
		if (result) {
			setShowErrorPhone(false)
		} else {
			setShowErrorPhone(true)
		}
	}

	useEffect(() => {
		const handleResize = () => {
			setIsCompact(window.innerWidth < 375)
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			<Modal width={504} height={673} centered open={isVisible} onCancel={onClose} footer={null}>
				<form className={styles.form} onSubmit={onSubmit}>
					<p className={styles.title}>{t('feedback')}</p>
					<p>{t('ask_feedback')}</p>
					<label className={styles.inputStyle}>
						<input onChange={onChange('name')} placeholder={t('your_name')} type="text" value={form.name} />
					</label>
					<label className={styles.inputStyle}>
						<NumberFormat
							className={showErrorPhone ? s.inputFieldError : s.inputField}
							format="+# (###) ### - ## - ##"
							mask=""
							name="phoneNumberInput"
							placeholder={t('your_phone_number')}
							value={phone}
							onChange={verifyHandler}
						/>
						{showErrorPhone && <div className={s.errorMessage}> {t('invalid_phone_number_entered')}</div>}
					</label>
					<label className={styles.inputStyle}>
						<input
							className={showErrorMail ? s.inputFieldError : s.inputField}
							type="email"
							name="EmailNumberInput"
							placeholder={t('your_mail')}
							value={mail}
							onChange={emailHandler}
						/>
						{showErrorMail && <div className={s.errorMessage}> {t('invalid_mail_entered')} </div>}
					</label>
					<label className={styles.inputStyle}>
						<input
							className={showErrorIin ? s.inputFieldError : s.inputField}
							placeholder={t('enter_iin')}
							value={form.iin}
							onChange={iinHandler}
						/>
						{showErrorIin && <div className={s.errorMessage}> {t('not_correct_iin')} </div>}
					</label>
					<div className={classNames(styles.inputStyle, 'd-inline-block')}>
						<Select
							value={kato}
							onChange={(code) => setKato(code)}
							optionsStyles={{
								border: '1px solid rgb(204, 204, 204)',
								borderRadius: '4px',
								boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
							}}
							placeholder={'Выберите регион из справочника'}
							options={districts}
							iconClasses={styles.selectIcon}
						/>
					</div>
					<label className={styles.inputStyle}>
						<input onChange={onChange('topic')} placeholder={t('question_topic')} value={form.topic} />
					</label>
					<label className={styles.inputStyle}>
						<textarea onChange={onChange('message')} placeholder={t('question')} value={form.message} />
					</label>
					<Captcha recaptcha={getCaptchaValue} size={isCompact ? 'compact' : 'normal'} />
					<ul className="file-list">
						{files.map(({ name }, idx) => (
							<li key={name}>
								<div>
									<img src={doc} />
									<span>{name}</span>
								</div>
								<div
									className="file-list__delete"
									onClick={() => {
										const newFiles = [...files]
										newFiles.splice(idx, 1)
										setFiles(newFiles)
									}}
								>
									<img src={trash} />
									<span>{t('remove')}</span>
								</div>
							</li>
						))}
					</ul>
					<p className={errorForm ? 'errorForm active' : 'errorForm'}>{t(errorForm)}</p>
					<div className={styles.formBtn}>
						<div className="greenBtn">
							<label className="input-file">
								<input onChange={getFileName} type="file" {...getInputProps()} />
								<div className="custom-file">
									<img src={paper} />
									<span>{t('attache_file')}</span>
								</div>
							</label>
						</div>
						<div>
							<Button onClick={validForm} type="submit" disabled={disable}>
								{t('send')}
							</Button>
						</div>
					</div>
					<p>{imgName}</p>
				</form>
			</Modal>
		</>
	)
}
