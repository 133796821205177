import Tooltip from '@mui/material/Tooltip'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { contractsSelectors, contractsThunks, contractsActions } from '@/entity/contracts'
import { contractActionsSelectors } from '@features/contracts/model'
import { useDigitalSign } from '@/shared'

import { Unsign } from '@app/icons'
import {
	ContractTerminationConfirmationModal,
	DeleteEtd,
	Edit,
	Recover,
	Remove,
	TerminateContract,
	TerminateElectronic,
	Update,
	View,
	RecoverReject,
} from '@features/contracts/actions'
import { AdditionalAgreementMain } from '@features/contracts/additionalContracts'
import { SignAll, SignSome } from '../packageLoadModals'
import { LoadingScreen, LoadingBlocker } from '@/components/Loader'
import { Title } from '@/ui'
import { userSelectors } from '@/entity'
import { ProlongationModal } from '@features/contracts/prolongation/ProlongationModal'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { getCurrentLanguage } from '@/i18next'
import SocialLeaveMain from '@features/contracts/socialLeaves/SocialLeaveMain'
import onContractActionMenuClick from '@features/contracts/table/actionMenu/onContractActionMenuClick'
import { api } from '@/shared'
import fileDownload from 'js-file-download'
import { useDispatch, useSelector } from 'react-redux'
import { headCells } from './contractsTable.headings'
import styles from './contractsTable.module.scss'
import { handbookThunks } from '@/entity/handbook'
import { useHistory, useLocation } from 'react-router-dom'
import { ContractState } from '@/components/contractState/ContractState'
import { message } from 'antd'
import { calculateAge, getConsentStatus } from '@/utils'
import { ReviewDeleteModal, ReviewEditModal } from '@/features'
import { reviewActions, reviewThunks } from '@/entity/review'

export const ContractsTable = ({ filters, page, pageSize, setFilters, fetchData, setActiveTab }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const languageFields = {
		rus: 'companyName',
		kz: 'companyNameKaz',
	}
	const location = useLocation()
	const dispatch = useDispatch()
	const { contracts, contract, contractIsLoading, activeRole, isLoading, error, isSuccess, selectedContracts, currentReview, review } =
		useSelector((state) => ({
			contracts: contractsSelectors.contracts(state),
			contract: contractActionsSelectors.contractView()(state),
			contractIsLoading: contractsSelectors.contractIsLoading(state),
			activeRole: userSelectors.activeRole(state),
			isLoading: contractsSelectors.isLoading(state),
			error: contractsSelectors.error(state),
			isSuccess: contractsSelectors.isSuccess(state),
			selectedContracts: state.contracts.selectedContracts,
			currentReview: state.review.currentReview,
			review: state.review.review,
		}))

	const [actions, setActions] = useState({
		view: false,
		sign: false,
		edit: false,
		terminate: false,
		remove: false,
		signPackage: false,
		signSome: false,
		update: false,
		recover: false,
		terminateForm: false,
		terminateFormElectronic: false,
		prolongation: false,
		createSocialLeave: false,
		viewSocialLeave: false,
		removeSocialLeave: false,
		editSocialLeave: false,
		signSocialLeave: false,
		createAdditionalAgreement: false,
		editAdditionalAgreement: false,
		viewAdditionalAgreement: false,
		removeAdditionalAgreement: false,
		signAdditionalAgreement: false,
		declineAdditionalAgreement: false,
		downloadAdditionalAgreement: false,
		editTermination: false,
		download: false,
		deleteEtd: false,
		recoverReject: false,

		addReview: false,
		editReview: false,
		deleteReview: false,

		addAnswer: false,
		editAnswer: false,
		deleteAnswer: false,

		deleteReviewWithAnswer: false,
	})
	const [socialLeave, setSocialLeave] = useState(null)
	const [fileLoading, setFileLoading] = useState(false)
	const [createTerminationApp, setCreateTerminationApp] = useState(false)
	const [contractsToSelect, setContractsToSelect] = useState([])
	const history = useHistory()
	const [annuityDate, setAnnuityDate] = useState(null)
	const [annuity, setAnnuity] = useState(false)
	const [checkTerminate, setCheckTerminate] = useState(false)
	useEffect(() => {
		if (contract?.birthDate) {
			setAnnuityDate(calculateAge(contract.birthDate))
		}
	}, [contract])
	useEffect(() => {
		if (contract.terminationDate) {
			let dateParts = contract.terminationDate.split('.')
			let terminateYear = dateParts[2]
			/**
			 * Represents the beginning year of an annuity.
			 *
			 * @type {string}
			 */
			const annuityBeginYear = '2024'
			if (terminateYear >= annuityBeginYear) {
				setCheckTerminate(true)
			} else {
				setCheckTerminate(false)
			}
		}
	}, [contract.terminationDate])

	useEffect(() => {
		if (checkTerminate) {
			getConsentStatus(
				contract?.additionalContracts,
				contract.dworkingConditionsCode,
				contract.employeeRequest,
				annuityDate,
				setAnnuity
			)
		} else {
			setAnnuity(false)
		}
	}, [checkTerminate, contract])
	useEffect(() => {
		if (location.state) {
			let row = {}
			row.isLocalContract = location.state.local
			row.externalId = location.state.refValue
			onToggleAction('view', true, row, true)
			history.push(`/contracts`)
		}
	}, [location.key])
	useEffect(() => {
		setContractsToSelect(contracts.filter((contract) => contract.dcontractState.code === '2'))
	}, [contracts])
	const [actionMenuItems, setActionMenuItems] = useState([])
	const lang = getCurrentLanguage()
	const onToggleAction = (action, value, row, localOrRemote = false) => {
		setActions((prevState) => {
			if (action === 'sign') {
				onActionMenuChooseMiddleWare(action, row)
				return prevState
			}
			return { ...prevState, [action]: value }
		})
		if (row && action !== 'sign') {
			getContractById(row.externalId, row.isLocalContract, localOrRemote)
		}
	}
	const updateList = () => {
		dispatch(contractsThunks.getContracts(filters, page, pageSize))
	}
	const getContractById = useCallback(
		(id, isLocalContract, localOrRemote = false) => {
			dispatch(contractsThunks.getContract(id, isLocalContract, localOrRemote))
		},
		[dispatch]
	)
	const handleContractTerminationConfirm = useCallback(
		(setCreateEmp) => {
			if (contract.econtractFound) {
				setCreateTerminationApp(setCreateEmp)
				setActions((value) => ({ ...value, terminate: false, terminateFormElectronic: true }))
			} else {
				setActions((value) => ({ ...value, terminate: false, terminateForm: true }))
			}
		},
		[contract]
	)
	const handleContractTerminationFormClose = useCallback(() => {
		setActions((value) => ({ ...value, terminateForm: false }))
	}, [])
	const handleSuccessClose = () => {
		updateList()
		setActions((value) => ({ ...value, terminateForm: false, editTermination: false, view: false }))
	}
	const handleElectronicContractTerminationFormClose = useCallback(() => {
		updateList()
		setActions((value) => ({
			...value,
			terminateFormElectronic: false,
			terminateForm: false,
			editTermination: false,
			view: false,
		}))
	}, [dispatch])
	const handleCreateAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, createAdditionalAgreement: false }))
	}, [])
	const handleViewAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, viewAdditionalAgreement: false }))
	}, [])
	const handleRemoveAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, removeAdditionalAgreement: false }))
	}, [])
	const handleEditAdditionalAgreementModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, editAdditionalAgreement: false }))
	}, [])
	const handleSignAdditionalAgreementModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, signAdditionalAgreement: false }))
	}, [])
	const handleDeclineAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, declineAdditionalAgreement: false }))
	}, [])
	const handleProlongationModalClose = useCallback(() => {
		setActions((value) => ({ ...value, prolongation: false }))
	}, [])
	const handleViewSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, viewSocialLeave: false }))
	}, [])
	const handleEditSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, editSocialLeave: false }))
	}, [])
	const handleRemoveSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, removeSocialLeave: false }))
	}, [])
	const handleCreateSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, createSocialLeave: false }))
	}, [])
	const handleSignSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, signSocialLeave: false }))
	}, [])
	const handleEditTerminationModalClose = useCallback(() => {
		setActions((value) => ({ ...value, editTermination: false }))
	}, [])
	const handleRecoveryRejectModalClose = useCallback(() => {
		setActions((value) => ({ ...value, recover: false, remove: false, recoverReject: false }))
	}, [])

	const handleReviewModalClose = useCallback(() => {
		setActions((value) => ({
			...value,
			addReview: false,
			editReview: false,
			deleteReview: false,

			addAnswer: false,
			editAnswer: false,
			deleteAnswer: false,

			deleteReviewWithAnswer: false
		}))

		onClearPayload()
	}, [])


	const donwloadReference = async (contract) => {
		try {
			setFileLoading(true)
			const file = await api.contracts.getPdfReference(contract.externalId)
			fileDownload(file, `${contract.iin}.pdf`)
		} catch (error) {
			if (typeof error?.response?.data?.text == 'function') {
				const parsedError = JSON.parse(await error.response.data.text())
				const err = parsedError.message || 'Неизвестная ошибка'
				message.error(err ? err : 'Неизвестная ошибка', 5)
			} else {
				message.error(error.response.data || 'Неизвестная ошибка', 5)
			}
		} finally {
			setFileLoading(false)
		}
	}
	const options = useSelector(contractActionsSelectors.options)
	const { professionalSkills = [], personalQualities = [] } = options
	const [forceSubmitEdit, setForceSubmitEdit] = useState(false)
	const onActionMenuChooseMiddleWare = (mode, row) => {
		let isSubmitAvaliable = true
		const specSkillsList = row.specSkillsList
		for (let i = 0; i < specSkillsList.length; i++) {
			const element = specSkillsList[i]
			const fullElement = professionalSkills.find((item) => item.code === element)
			if (fullElement?.isActual === 0) {
				isSubmitAvaliable = false
				break
			}
		}
		const skillsList = contract.skillsList
		for (let i = 0; i < skillsList.length; i++) {
			const element = skillsList[i]
			const fullElement = personalQualities.find((item) => item.code === element)
			if (fullElement?.isActual === 0) {
				isSubmitAvaliable = false
				break
			}
		}
		if (isSubmitAvaliable) {
			setForceSubmitEdit(false)
			setActions((prevState) => {
				return { ...prevState, sign: true }
			})
		} else {
			setForceSubmitEdit(true)
			onToggleAction('edit', true)
		}
	}
	const onActionMenuChoose = (mode, row) => {
		if (mode === 'donwload') {
			return donwloadReference(row)
		}
		if (mode === 'sendEmployee') {
			return handleSendToEmployee(row)
		}
		if (mode === 'signEmployer') {
			return
		}
		onToggleAction(mode, true, row)
	}
	const fastView = (row) => {
		onToggleAction('view', true, row)
	}
	const haveFilters = useMemo(
		() =>
			Object.entries(filters)
				.filter(([key]) => key !== 'bin')
				.some(([key, value]) => value),
		[filters]
	)
	const optionLang = useMemo(() => (lang === 'rus' ? 'nameRu' : 'nameKz'), [lang])
	const handleSelectRow = (selected, row) => {
		if (selected) {
			dispatch(contractsActions.setSelectedContracts([...selectedContracts, row]))
		} else {
			dispatch(
				contractsActions.setSelectedContracts(
					selectedContracts.filter((contract) => contract.externalId !== row.externalId)
				)
			)
		}
	}
	const selectAll = (selected) => {
		if (selected) {
			return dispatch(contractsActions.setSelectedContracts(contractsToSelect))
		}
		dispatch(contractsActions.setSelectedContracts([]))
	}
	const checkSelected = (row) =>
		selectedContracts.findIndex((contract) => contract.externalId === row.externalId) !== -1
	const { successPayload, onClearPayload, onSign } = useDigitalSign()
	const { loading, packageXml, packageError, result } = useSelector((state) => ({
		packageXml: state.contracts.packageLoading.xml,
		packageError: state.contracts.packageLoading.error,
		...state.contracts.packageLoading,
	}))
	const success = () => {
		message.success(t('success_alert'), 5)
	}
	useEffect(() => {
		if (result) {
			success()
			dispatch(contractsActions.resetPackage())
			onToggleAction('signPackage', false)
		}
	}, [result])
	useEffect(() => {
		if (packageError) {
			onClearPayload()
			onToggleAction('signPackage', false)
		}
	}, [packageError])
	const signAddtionalAndLeaves = () => {
		dispatch(
			contractsThunks.getXmlToSignSome({
				contractExternalIds: [contract.externalId],
			})
		)
	}
	useEffect(() => {
		if (packageXml && !loading) {
			onSign(packageXml)
		}
	}, [packageXml])
	useEffect(() => {
		if (successPayload) {
			dispatch(contractsThunks.sendSignedXmlPackageSome({ xmlData: successPayload }, {}, filters, page, pageSize))
		}
	}, [successPayload])
	const responseRecover = useSelector((state) => state.contracts.recoverXML.response)
	useEffect(() => {
		if (responseRecover) {
			updateList()
			getContractById(contract.externalId, contract.isLocalContract)
		}
	}, [responseRecover])
	const openEtd = async () => dispatch(contractsThunks.getEtd({ externalId: contract.externalId }))
	const updateData = () => {
		getContractById(contract.externalId, contract.isLocalContract)
		updateList()
		openEtd()
		success()
	}
	const { data: sendToEmployee } = useSelector((state) => state.contracts.etd.sendToEmployee)
	const {
		data: declineEmployee,
		loading: declineEmployeeloading,
		error: declineEmployeeError,
	} = useSelector((state) => state.contracts.etd.declineEmployee)
	const {
		data: declineEmployer,
		loading: declineEmployerloading,
		error: declineEmployerError,
	} = useSelector((state) => state.contracts.etd.declineEmployer)
	const {
		data: sendForImprovementEmployee,
		loading: sendForImprovementEmployeeloading,
		error: sendForImprovementEmployeeError,
	} = useSelector((state) => state.contracts.etd.sendForImprovementEmployee)
	const {
		data: deleteEtdData,
		loading: deleteEtdloading,
		error: deleteEtdError,
	} = useSelector((state) => state.contracts.etd.deleteEtd)
	useEffect(() => {
		if (sendToEmployee) {
			dispatch(
				contractsActions.sendToEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
			updateList()
			success()
		}
	}, [sendToEmployee])
	useEffect(() => {
		if (declineEmployee) {
			dispatch(
				contractsActions.declineEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
			updateData()
		}
	}, [declineEmployee])
	useEffect(() => {
		if (declineEmployeeError) {
			const err = declineEmployeeError?.response?.data?.message || declineEmployeeError || 'error'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			dispatch(
				contractsActions.declineEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
		}
	}, [declineEmployeeError])
	useEffect(() => {
		if (declineEmployer) {
			dispatch(
				contractsActions.declineEmployer({
					data: null,
					loading: false,
					error: null,
				})
			)
			updateData()
		}
	}, [declineEmployer])
	useEffect(() => {
		if (declineEmployerError) {
			const err = declineEmployerError?.response?.data?.message || declineEmployerError || 'error'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			dispatch(
				contractsActions.declineEmployer({
					data: null,
					loading: false,
					error: null,
				})
			)
		}
	}, [declineEmployerError])
	useEffect(() => {
		if (sendForImprovementEmployee) {
			dispatch(
				contractsActions.sendForImprovementEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
			updateData()
		}
	}, [sendForImprovementEmployee])
	useEffect(() => {
		if (sendForImprovementEmployeeError) {
			const err = sendForImprovementEmployeeError?.response?.data?.message || sendForImprovementEmployeeError || 'error'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			dispatch(
				contractsActions.sendForImprovementEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
		}
	}, [sendForImprovementEmployeeError])
	useEffect(() => {
		if (deleteEtdData) {
			dispatch(
				contractsActions.deleteEtd({
					data: null,
					loading: false,
					error: null,
				})
			)
			updateList()
			onToggleAction('deleteEtd', false)
			onToggleAction('view', false)
			success()
		}
	}, [deleteEtdData])
	useEffect(() => {
		if (deleteEtdError) {
			const err = deleteEtdError?.response?.data?.message || deleteEtdError || 'error'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			dispatch(
				contractsActions.sendForImprovementEmployee({
					data: null,
					loading: false,
					error: null,
				})
			)
		}
	}, [deleteEtdError])
	const handleSendToEmployee = (row) => dispatch(contractsThunks.sendToEmployee({ externalId: row.externalId }))
	// const handleSignToEmployer = (row) =>
	const deleteEtd = () => dispatch(contractsThunks.deleteEtd({ externalId: contract.externalId }))
	const getTableRow = (row, index) => (
		<tr key={index + '-table-row'} onClick={() => fastView(row)}>
			<td className={styles.pixelGamingCheckbox}>
				<input
					onClick={(e) => e.stopPropagation()}
					onChange={(e) => handleSelectRow(e.target.checked, row)}
					type="checkbox"
					checked={checkSelected(row)}
					disabled={row.dcontractState.code !== '2'}
				/>
			</td>
			<td className={styles.pixelGamingContractNumber}>{row.contractNumber || '-'}</td>
			<td className={styles.pixelGamingContragent} style={{ textTransform: 'capitalize' }}>
				{activeRole === 'ROLE_EMPLOYEE' ? row?.[languageFields[language]] : row?.fullName?.toLowerCase() || ""}
			</td>
			<td className={styles.pixelGamingPost}>
				{(row.destablishedPost && row.destablishedPost[optionLang]) || row.establishedPost || '-'}
			</td>
			<td className={styles.pixelGamingDate}>{row.registerDate || '-'}</td>
			<td className={styles.pixelGamingDate}>{row.dateFrom || '-'}</td>
			<td className={styles.pixelGamingDate}>{row.dateTo || '-'}</td>
			<td className={styles.pixelGamingStatus}>{row.dcontractStatus[optionLang]}</td>
			<td className={styles.pixelGamingState}>
				<ContractState dcontractState={row.dcontractState} lang={lang} />
			</td>
			<td className={styles.pixelGamingType}>
				{row.isElectronic ? t('contracts_tab.type_is_electronic') : t('contracts_tab.type_is_not_electronic')}
			</td>

			<td onClick={(e) => onContractActionMenuClick(row, e, setActionMenuItems, t, activeRole)}>
				<DropDownMenu
					title={t('select_pls')}
					items={actionMenuItems}
					onItemClick={(mode) => onActionMenuChoose(mode, row)}
				/>
			</td>
			<td>
				{row.dsendStatusCode &&
				!row.desutdRegErrorCode &&
				(activeRole === 'ROLE_EMPLOYER' || (activeRole === 'ROLE_EMPLOYEE' && row.econtractFound)) ? (
					<Tooltip
						placement="top-start"
						title={t('package_loading.warning_text')}
						classes={{
							popper: styles.tooltip__popper,
							tooltip: styles.tooltip__root,
						}}
					>
						<span className={styles.signature__icon__wrapper}>
							<Unsign fill="#FFBC34" />
						</span>
					</Tooltip>
				) : null}

				{row.desutdRegErrorCode &&
				(activeRole === 'ROLE_EMPLOYER' || (activeRole === 'ROLE_EMPLOYEE' && row.econtractFound)) ? (
					<Tooltip
						placement="top-start"
						title={t('package_loading.error_text')}
						classes={{
							popper: styles.tooltip__popper,
							tooltip: styles.tooltip__root,
						}}
					>
						<span className={styles.signature__icon__wrapper}>
							<Unsign fill="#E65332" />
						</span>
					</Tooltip>
				) : null}
			</td>
		</tr>
	)

	const tableBody = useMemo(() => contracts.map((row, index) => getTableRow(row, index)), [contracts, getTableRow])

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{(isLoading || (!error && !isLoading && !isSuccess)) && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && haveFilters && <div className={styles.tableLoading}>{t('no_data')}</div>}
					{!isLoading && isSuccess && !haveFilters && <div className={styles.tableLoading}>{t('no_contract')}</div>}
					{error && !isLoading && !isSuccess && <div className={styles.tableLoading}>{t('has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, contracts, haveFilters, error]
	)

	/**
	 * функция сабмит для отзыва, собирает данные с формы и отправляется на бекенд
	 *
	 * @param rootReview обьект с данными
	 * @param onSuccess функция при успешном возврате данных с бекенда
	 * @param rootReview.key {modalViewType, formActionType, item} modalViewType = {review | answer}, formActionType = {add | edit}, item = элемент массива
	 * @param rootReview.values кастомный обьект
	 */

	const handleSubmitReviewForm = (rootReview, onSuccess) => {
		let type = {
			add: {
				review: {},
				answer: {
					parentExternalId: rootReview.key.item.review?.externalId,
				}
			},
			edit: {
				review: {
					externalId: rootReview.key.item.review?.externalId
				},
				answer: {
					externalId: rootReview.key.item.answer?.externalId,
					parentExternalId: rootReview.key.item.review?.externalId,
				}
			},
		}

		let body = {
			data: {
				...(rootReview.values.rating ? { rating: rootReview.values.rating } : {}),
				...(rootReview.values.reviewText ? { reviewText: rootReview.values.reviewText } : {}),
				iin: rootReview.values.iin,
				bin: rootReview.values.bin,
				reviewerType: rootReview.values.reviewerType,
				contractExternalId: rootReview.values?.contractExternalId,

				...type[rootReview.key.formActionType][rootReview.key.modalViewType]
			},
			onSuccess: onSuccess,
		}

		dispatch(reviewThunks.setReviewXml(body))
	}


	const handleDeleteReviewForm = (values, onSuccess) => {
		let reviewType = {
			ROLE_EMPLOYEE: 'employeeDelete',
			ROLE_EMPLOYER: 'employerDelete',
		}

		let externalId = {
			ROLE_EMPLOYEE: {
				employerReview: 'answer',
				employeeReview: 'review',
			},
			ROLE_EMPLOYER: {
				employerReview: 'review',
				employeeReview: 'answer',
			},
		}

		let body = {
			data: {
				externalId: values[externalId[activeRole][values.reviewTypeCode]].externalId,
				reviewType: reviewType[activeRole],
			},
			onSuccess: onSuccess,
		}

		dispatch(reviewThunks.deleteReviewXml(body))
	}

	const handleSuccessReviewForm = (item) => {
		dispatch(contractsThunks.getContract(item?.contractExternalId, false))
	}

	return (
		<>
			{(fileLoading || loading || review.loading) && <LoadingBlocker />}
			{actions.view && (
				<View
					updateList={updateList}
					setForceSubmitEdit={setForceSubmitEdit}
					onToggleAction={onToggleAction}
					setSocialLeave={setSocialLeave}
					onTermination={handleContractTerminationConfirm}
				/>
			)}
			{actions.sign && (
				<View
					setForceSubmitEdit={setForceSubmitEdit}
					onToggleAction={onToggleAction}
					setSocialLeave={setSocialLeave}
					forceSign={true}
					updateList={updateList}
					onTermination={handleContractTerminationConfirm}
				/>
			)}
			{actions.edit && (
				<Edit
					onToggleAction={onToggleAction}
					forceSubmitEdit={forceSubmitEdit}
					setForceSubmitEdit={setForceSubmitEdit}
				/>
			)}
			{actions.terminate && (
				<ContractTerminationConfirmationModal
					onToggleAction={onToggleAction}
					onConfirm={handleContractTerminationConfirm}
				/>
			)}
			{actions.remove && !annuity ? <Remove onToggleAction={onToggleAction} /> : null}
			{actions.remove && annuity ? (
				<RecoverReject handleProcess={handleRecoveryRejectModalClose} handleCancel={handleRecoveryRejectModalClose} />
			) : null}
			{actions.deleteEtd && <DeleteEtd onToggleAction={onToggleAction} onSuccessAction={deleteEtd} />}
			{actions.update && <Update onToggleAction={onToggleAction} />}
			{actions.recoverReject ? (
				<RecoverReject
					handleProcess={onToggleAction.bind(null, 'recoverReject', false)}
					handleCancel={handleRecoveryRejectModalClose}
				/>
			) : null}
			{actions.recover && !annuity ? <Recover onClose={onToggleAction.bind(null, 'recover', false)} /> : null}
			{actions.recover && annuity ? (
				<RecoverReject handleProcess={handleRecoveryRejectModalClose} handleCancel={handleRecoveryRejectModalClose} />
			) : null}
			{actions.signPackage && (
				<SignAll
					handleProcess={signAddtionalAndLeaves}
					handleCancel={onToggleAction.bind(null, 'signPackage', false)}
				/>
			)}
			{actions.signSome && (
				<SignSome handleCancel={onToggleAction.bind(null, 'signSome', false).bind(null, 'recover', false)} />
			)}
			{actions.terminateFormElectronic && (
				<TerminateElectronic
					onToggleAction={onToggleAction}
					onClose={handleElectronicContractTerminationFormClose}
					createTerminationApp={createTerminationApp}
					setActiveTab={setActiveTab}
				/>
			)}
			{actions.prolongation && contract?.predictAutoProlongationData ? (
				<ProlongationModal show onClose={handleProlongationModalClose} />
			) : null}
			{actions.terminateForm && (
				<TerminateContract show onClose={handleContractTerminationFormClose} successClose={handleSuccessClose} />
			)}
			{actions.viewAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'view'}
					onToggleAction={onToggleAction}
					onClose={handleViewAdditionalContractModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.removeAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'remove'}
					onClose={handleRemoveAdditionalContractModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.createAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'create'}
					onClose={handleCreateAdditionalContractModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.editAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'edit'}
					onClose={handleEditAdditionalAgreementModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.signAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'sign'}
					onClose={handleSignAdditionalAgreementModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.declineAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'decline'}
					onClose={handleDeclineAdditionalContractModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
					setFilters={setFilters}
					fetchData={fetchData}
				/>
			)}
			{actions.downloadAdditionalAgreement && <AdditionalAgreementMain mode={'download'} />}
			{actions.createSocialLeave && (
				<SocialLeaveMain
					mode={'create'}
					onClose={handleCreateSocialLeaveModalClose}
					page={page}
					pageSize={pageSize}
					filters={filters}
				/>
			)}
			{actions.viewSocialLeave && (
				<SocialLeaveMain
					onToggleAction={onToggleAction}
					socialLeave={socialLeave}
					onClose={handleViewSocialLeaveModalClose}
					mode={'view'}
					page={page}
					pageSize={pageSize}
					filters={filters}
				/>
			)}
			{actions.removeSocialLeave && (
				<SocialLeaveMain
					mode={'remove'}
					onClose={handleRemoveSocialLeaveModalClose}
					socialLeave={socialLeave}
					page={page}
					pageSize={pageSize}
					filters={filters}
				/>
			)}
			{actions.editSocialLeave && (
				<SocialLeaveMain
					mode={'edit'}
					onClose={handleEditSocialLeaveModalClose}
					socialLeave={socialLeave}
					page={page}
					pageSize={pageSize}
					filters={filters}
				/>
			)}
			{actions.signSocialLeave && (
				<SocialLeaveMain
					mode={'sign'}
					onClose={handleSignSocialLeaveModalClose}
					socialLeave={socialLeave}
					page={page}
					pageSize={pageSize}
					filters={filters}
				/>
			)}
			{actions.editTermination && !contractIsLoading && (
				<TerminateContract
					show
					onClose={handleEditTerminationModalClose}
					successClose={handleSuccessClose}
					isEdit={true}
				/>
			)}

			{
				actions.addReview && (
					<ReviewEditModal onClose={handleReviewModalClose} modalViewType="review" formActionType="add"
													 item={{ ...contract, contractExternalId: contract?.externalId }}
													 onSubmit={handleSubmitReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			{
				actions.editReview && (
					<ReviewEditModal onClose={handleReviewModalClose} modalViewType="review" formActionType="edit"
													 item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
													 onSubmit={handleSubmitReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			{
				actions.deleteReview && (
					<ReviewDeleteModal onClose={handleReviewModalClose} modalViewType="review"
														 item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
														 onSubmit={handleDeleteReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			{
				actions.addAnswer && (
					<ReviewEditModal onClose={handleReviewModalClose} modalViewType="answer" formActionType="add"
													 item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
													 onSubmit={handleSubmitReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			{
				actions.editAnswer && (
					<ReviewEditModal onClose={handleReviewModalClose} modalViewType="answer" formActionType="edit"
													 item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
													 onSubmit={handleSubmitReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			{
				actions.deleteAnswer && (
					<ReviewDeleteModal onClose={handleReviewModalClose} modalViewType="answer"
														 item={{ ...currentReview, bin: currentReview?.review?.orgBin, iin: currentReview?.review?.iin }}
														 onSubmit={handleDeleteReviewForm} onSuccess={handleSuccessReviewForm} />
				)
			}

			<div className={styles.tableRow}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th>
								<input
									onClick={(e) => e.stopPropagation()}
									onChange={(e) => selectAll(e.target.checked)}
									type="checkbox"
									checked={selectedContracts.length === contractsToSelect.length && selectedContracts.length}
								/>
							</th>
							{headCells.map((head, index) => (
								<th key={index + 'thead-th'}>{t(head.label)}</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: 'relative' }}>{isLoading || contracts.length < 1 ? tableLoading : tableBody}</tbody>
				</table>
			</div>
		</>
	)
}
