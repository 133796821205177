import styles from './Differences.module.scss'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'
export const Differences = ({ data, onClose }) => {
	const { t } = useTranslation()
	return (
		<ModalWS show={true} windowClassName={styles.modal}>
			<ModalWSBackButton onClick={onClose} />
			<div className={`scroll`}>
				<div>
					<table className={styles.table}>
						<tbody>
						{data.map((item) =>(
								<tr key={item.id}>
									<td className={styles.tableCell}>
										{t('pension.payments.form.diff_form.diff' + item.expType.code)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className={styles.footer}>
					<Button type="bare" onClick={onClose}>
						{t('close')}
					</Button>
				</div>
			</div>
		</ModalWS>
	)
}
