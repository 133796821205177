import { request } from '@/shared/api/request'

export const get = (externalId, isLocal) => {
	return request({ url: 'contract/get/' + externalId + '?isLocalContract=' + isLocal, method: 'GET' })
}

export const getlocalOrRemote = (externalId, isLocal) => {
	return request({ url: 'contract/getLocalOrRemote/' + externalId, method: 'GET' })
}

export const getPdfReference = (externalId) => {
	return request({
		url: 'contract/export/certOfEmployment',
		method: 'GET',
		responseType: 'blob',
		params: { externalId },
	})
}
