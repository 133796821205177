import React, { useEffect, useState } from 'react'
import styles from './Documents.module.scss'
import { Title, Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { ConfirmDocument } from './ConfirmDocument/ConfirmDocument'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { Radio } from '@/entity/contracts/ui/form/components'

export const Documents = ({ onClose, document, setDoc, nextPage, attachProjectFileData }) => {
	const { t } = useTranslation()
	const getDocument = async (doc) => {
		setDoc(doc)
	}
	const [contractDTO, setContractDTO] = useState(null)

	useEffect(() => {
		if (Object.keys(attachProjectFileData).length > 0) {
			setContractDTO({
				...attachProjectFileData,
			})
		}
	}, [])
	return (
		<ModalWS show={true} windowClassName={styles.modal}>
			<ModalWSBackButton onClick={onClose} />
			<div>
				<div className={styles.header}>
					<Title className={styles.title}>{t('draft_etd')}</Title>
				</div>
				<Formik
					initialValues={{ type: '' }}
					enableReinitialize
					onSubmit={(data) => {
						nextPage({
							contractDTO,
							fileId: document.id,
							clang: data.language,
						})
					}}
				>
					{({ values }) => (
						<Form>
							<div className={styles.option__block}>
								<div className={styles.option__title}>{t('electronic_contract.options.language')}</div>
								<Field name="language">
									{({ field, form: { touched, errors }, name, options, children, ...props }) => {
										const fieldName = name || field?.name
										return (
											<RadioGroup {...field} {...props} name={fieldName}>
												<FormControlLabel
													control={<Radio />}
													label={t('electronic_contract.options.1.1')}
													value="kz"
													classes={{ root: styles.label__root }}
												/>
												<FormControlLabel
													control={<Radio />}
													label={t('electronic_contract.options.1.2')}
													value="ru"
													classes={{ root: styles.label__root }}
												/>
												<FormControlLabel
													control={<Radio />}
													label={t('electronic_contract.options.1.3')}
													value="rk"
													classes={{ root: styles.label__root }}
												/>
												{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
											</RadioGroup>
										)
									}}
								</Field>
							</div>
							<ConfirmDocument getDocument={getDocument}></ConfirmDocument>
							<div className={styles.footer}>
								<Button type={'submit'} disabled={!(values.language && document)}>
									{t('continue')}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</ModalWS>
	)
}
