import styles from './TableExperience.module.scss'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { opvSelectors } from '@/entity/opv'
import { getCurrentLanguage } from '@/i18next'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { Edit, Remove } from '@app/images'
import { dateSort } from '@features/laborRecords/utils'
export const TableExperience = ({ view, data, removeExp, editExp }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const isLoading = useSelector(opvSelectors.isLoading)
	const isSuccess = useSelector(opvSelectors.isSuccess)
	const actions = [
		{ icon: Edit, title: t('actions.edit'), action: 'edit' },
		{ icon: Remove, title: t('actions.remove'), action: 'remove' },
	]
	const onActionMenuChoose = (mode, row, index) => {
		if (mode === 'remove') {
			removeExp(index)
		} else {
			editExp(row, index)
		}
	}

	const getTableRow = useCallback(
		(row, index, view) => {
			return (
				<tr key={index + '-table-row'}>
					<td>{row.dateFrom}</td>
					<td>{row.dateTo}</td>
					<td>{row.expName}</td>
					<td>{lang === 'rus' ? row.expType.nameRu : row.expType.nameKz}</td>
					<td>{lang === 'rus' ? row.prefExpType?.nameRu : row.prefExpType?.nameKz}</td>
					{!view && (
						<td>
							<DropdownMenu
								title={t('select_pls')}
								items={actions}
								onItemClick={(mode) => onActionMenuChoose(mode, row, index)}
							/>
						</td>
					)}
				</tr>
			)
		},
		[t]
	)

	const tableBody = useMemo(
		() => dateSort(data, false).map((row, index) => getTableRow(row, index, view)),
		[data, getTableRow, view]
	)

	const labels = [
		t('pension.payments.form.table.date_from'),
		t('pension.payments.form.table.date_by'),
		t('pension.payments.form.table.name_of_experience'),
		t('pension.payments.form.table.type_of_experience'),
		t('pension.payments.form.table.benefit_type'),
		!view ? t('pension.payments.form.table.actions') : '',
	]
	const tableHeader = useMemo(
		() => (
			<>
				{labels.map((label, index) => (
					<th key={index + 'thead-th'} className={styles.thText}>
						{label}
					</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan="6" className={styles.message}>
					{t('pension.payments.form.table.add_entries')}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{data.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}
