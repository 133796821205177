import { request } from '@/shared/api/request'

export const createAdditionalContractXml = (payload) => {
	return request({ url: 'contract/additional/registration/xml', method: 'POST', data: payload })
}

export const createAdditionalContractXmlTransfer = (payload, params) => {
	return request({ url: '/contract/branchTransfer/registration/xml', method: 'POST', data: payload, params })
}

export const sendSignedAdditionalContract = (payload) => {
	return request({ url: 'contract/additional/sendRequest', method: 'POST', data: payload })
}

export const getDeleteAdditionalContractXml = (payload) => {
	return request({ url: 'contract/additional/deletion/xml', method: 'DELETE', data: payload })
}

export const createLocalAdditionalAgreement = (payload) =>
	request({ url: 'contract/additional/local/createOrUpdate', method: 'POST', data: payload })

export const deleteLocalAdditionalAgreement = (payload) =>
	request({ url: `contract/additional/local/deletion/${payload}`, method: 'DELETE', data: payload })
