import { object, string } from 'yup'

export const PositionEvaluationSchema = object().shape({
	activityArea: string()
		.required({ key: 'required' }).nullable(),
	group: string().required({ key: 'required' }).nullable(),
	position_by_nkz: string().required({ key: 'required' }).nullable(),
	education: string().required({ key: 'required' }).nullable(),
	workExperience: string().required({ key: 'required' }).nullable(),
})
