import styles from './Transfer.module.scss'
import { Button } from '@/ui'
import React, { useEffect, useState } from 'react'
import { getTransferDetail } from '@/shared/api/rest/personalAffairs/getTransferDetail'
import { message } from 'antd'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { useTranslation } from 'react-i18next'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { LoadingBlocker } from '@/components/Loader'
import { datePickerlocaleKz, datePickerlocaleRu } from '@/i18next'
import { Arrow } from '@app/icons'
import { downloadTransfer } from '@/shared/api/rest/personalAffairs'

export const Transfer = ({ activeCompany, itemsYears }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [loading, setLoading] = useState(true)
	const [calendars, setCalendars] = useState([])
	const [lastUpdateDate, setLastUpdateDate] = useState(null)
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
	const [openIndex, setOpenIndex] = useState(null)

	useEffect(async () => {
		try {
			if (activeCompany.bin) {
				setLoading(true)
				const result = await getTransferDetail(activeCompany.bin)
				if (result) {
					setCalendars(result.monthDataList)
					setLastUpdateDate(result.lastUpdate)
					setLoading(false)
				}
			}
		} catch (error) {
			setCalendars([])
		} finally {
			setLoading(false)
		}
	}, [])

	const handleTransferData = async () => {
		try {
			if (activeCompany.bin) {
				setLoading(true)
				const result = await getTransferDetail(activeCompany.bin, false)
				if (result) {
					setCalendars(result.monthDataList)
					setLastUpdateDate(result.lastUpdate)
					setLoading(false)
				}
			}
		} catch (error) {
			setLoading(false)
			const err = error?.response?.data?.message || 'Попробуйте позже, технические проблемы на стороне сервиса'
			message.error(err ? err : 'Попробуйте позже, технические проблемы на стороне сервиса', 5)
		}
	}

	const handleYearChange = (action) => {
		const yearInt = parseInt(action, 10)
		if (!isNaN(yearInt)) {
			setSelectedYear(yearInt)
		}
	}

	const filteredCalendars = calendars.filter((calendar) => {
		const year = new Date(calendar.monthReference.split('.').reverse().join('.')).getFullYear()
		return year === selectedYear
	})

	const getDate = (date) => {
		return (
			(language === 'rus' ? datePickerlocaleRu : datePickerlocaleKz).months[parseInt(date.split('.')[1], 10) - 1] +
			' ' +
			date.split('.')[2]
		)
	}

	const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	const toggleItem = (index) => {
		setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
	}

	const parseComment = (comment) => {
		const regex = /ru:\s*([^;]*);kz:\s*(.*)/
		const matches = comment.match(regex)
		if (matches) {
			return language === 'rus' ? matches[1] : matches[2]
		}
		return comment
	}

	const downloadFile = async (period) => {
		const response = await downloadTransfer(period, activeCompany.bin)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = getDate(period)
		document.body.appendChild(a)
		a.click()
	}

	return (
		<>
			<div className={styles.update}>
				<Button onClick={handleTransferData}>{t('update')}</Button>
				<InfoDevelopment title={'welfare.info'} small={true} child={lastUpdateDate} />
			</div>
			<div className={styles.dropdown}>
				<DropdownMenu title={selectedYear.toString()} items={itemsYears} onItemClick={handleYearChange} />
			</div>
			{filteredCalendars
				.sort((a, b) => {
					const dateA = new Date(a.monthReference.split('.').reverse().join('.'))
					const dateB = new Date(b.monthReference.split('.').reverse().join('.'))
					return dateA - dateB
				})
				.map((calendar, index) => (
					<section key={index}>
						<div className={styles.row}>
							<div className={styles.title}>{getDate(calendar.monthReference)}</div>
							{!!calendar.employeeDatas ? (
								<>
									<div className={styles.wrap}>
										<div className={styles.wrapRow}>
											<div className={styles.title}>{formatNumber(calendar.emplCount) || '-'}</div>
											<div className={styles.gray}>{t('transfer.emplCount')}</div>
										</div>
										<div className={styles.wrapRow}>
											<div className={`${styles.title} ${styles.red}`}>{formatNumber(calendar.errorCount) || '-'}</div>
											<div className={styles.gray}>{t('transfer.errorCount')}</div>
										</div>
									</div>
									<div>
										{openIndex === index ? (
											<>
												<span className={`${styles.green} ${styles.pointer}`} onClick={() => toggleItem(index)}>
													{t('payment.briefly')} <Arrow className={`${styles.icon} ${styles.transform}`} />
												</span>
												<div className={`scroll ${styles.tableWrapper}`}>
													<table className={styles.table}>
														<thead>
															<tr>
																<th>{t('transfer.table.fio')}</th>
																<th>{t('transfer.table.docDate')}</th>
																<th>{t('transfer.table.payDate')}</th>
																<th>{t('transfer.table.refer')}</th>
																<th>{t('transfer.table.amount')}</th>
																<th>{t('transfer.table.period')}</th>
																<th>{t('transfer.table.error')}</th>
																<th>{t('transfer.table.retDate')}</th>
																<th>{t('transfer.table.retRefer')}</th>
																<th>{t('transfer.table.retAmount')}</th>
															</tr>
														</thead>
														<tbody>
															{calendar.employeeDatas.map((info, index) => (
																<tr key={index}>
																	<td>
																		{info.lastname + ' ' + (info.firstname || '') + ' ' + (info.middlename || '')}
																	</td>
																	<td>{info.docDate}</td>
																	<td>{info.payDate}</td>
																	<td>{info.refer}</td>
																	<td>{info.amount}</td>
																	<td>{info.period}</td>
																	<td>{language === 'rus' ? info.errorNameRu : info.errorNameKz}</td>
																	<td>{info.retDate}</td>
																	<td>{info.retRefer}</td>
																	<td>{info.retAmount}</td>
																</tr>
															))}
														</tbody>
													</table>
													<span
														className={`${styles.green} ${styles.pointer}`}
														onClick={() => downloadFile(calendar.monthReference)}
													>
														{t('labor_records.download')}
													</span>
												</div>
											</>
										) : (
											<span className={`${styles.green} ${styles.pointer}`} onClick={() => toggleItem(index)}>
												{t('payment.details')} <Arrow className={styles.icon} />
											</span>
										)}
									</div>
								</>
							) : (
								<div className={styles.italic}>{parseComment(calendar.comment)}</div>
							)}
						</div>
					</section>
				))}
			{loading && <LoadingBlocker />}
		</>
	)
}
