export const checkEquality = (selectedContract, workPlaceRowSelect) => {
	let errors = {
		positionCodeError: false,
		establishedPostError: false,
	}

	if (selectedContract.positionCode !== workPlaceRowSelect.positionCode) {
		errors.positionCodeError = true
	}

	if (selectedContract.establishedPost.code) {
		if (selectedContract.establishedPost.code !== workPlaceRowSelect.establishedPost.code) {
			errors.establishedPostError = true
		}
	}

	return errors
}
