import * as types from './types'

const initialState = {
	packageLoading: {
		loading: false,
		error: null,
		xml: null,
		result: null,
	},
	terminations: [],
	page: 0,
	totalElements: 0,
	totalPages: 0,
	termination: {
		isLoading: false,
		isSuccess: false
	}
}

export function reducer(state = initialState, { type, payload }) {
	switch (type) {
		case types.E_SET_TERMINATION_XML: {
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					xml: payload,
				},
				termination: { ...state.termination, xml: payload },
			}
		}
		case types.SET_TERMS:
			return {
				...state,
				terminations: payload.content,
				totalElements: payload.totalElements,
				totalPages: payload.totalPages,
			}
		case types.SET_TERMINATION_LOADING:
			return { ...state, termination: { ...state.termination, isLoading: payload } }
		case types.SET_TERMINATION_SUCCESS:
			return { ...state, termination: { ...state.termination, isSuccess: payload } }
		default:
			return state
	}
}
