import { request } from '@/shared/api/request'

export const getNotifications = (payload, tags = ['all']) => {
	return request({
		url: `/notification/get/pageByType${payload}`,
		method: 'POST',
		data: tags,
	})
}
export const setRead = (idNotif, employer) => {
	return request({ url: `notification/setread/${idNotif}?employer=${employer}`, method: 'POST' })
}
export const setReadTermination = (termExternalId) => {
	return request({ url: `/contract/eContractTerm/employer/setRead?termExternalId=${termExternalId}`, method: 'GET' })
}
