import { Button, ModalTemplate, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import classNames from 'classnames'
import styles from './PositionEvaluationForm.module.scss'
import * as React from 'react'
import { LoadingBlocker } from '@/components/Loader'
import { Form } from './Form'
import { PositionEvaluationResult } from '@/entity/grading/positionEvaluation/ui/form/PositionEvaluationResult'
import { useRef, useState } from 'react'
import { PositionEvaluationSchema, positionEvaluationsThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'

export const PositionEvaluationForm = ({ onCancel }) => {
	const { t } = useTranslation()
	const [calculationData, setCalculationData] = useState(null)
	const dispatch = useDispatch()

	const generatePDF = () => {
		dispatch(positionEvaluationsThunks.generatePdf(calculationData.result))
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Formik initialValues={{}} validationSchema={PositionEvaluationSchema} onSubmit={(data) => {}}>
					<Form onCalculated={(data) => setCalculationData(data)}></Form>
				</Formik>
				<div>
					<div className={styles.downloadButton}>
						{calculationData && <Button onClick={generatePDF}>Скачать PDF</Button>}
					</div>
					<div>{calculationData && <PositionEvaluationResult data={calculationData}></PositionEvaluationResult>}</div>
				</div>
			</div>
		</>
	)
}
