import SelectButton from '@/components/entrance/SelectButton'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@/ui'
import styles from './HistoryCheckTheme.module.scss'
import { Table } from '../Table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { formsSelectors, formsThunks } from '@/entity/forms'

export const HistoryCheckTheme = ({ activateQuestionModal }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeTheme = useSelector(formsSelectors.theme)
	const getData = useCallback(
		(page = 0, size = 10) => dispatch(formsThunks.getChecks(activeTheme, page, size)),
		[activeTheme, dispatch]
	)

	useEffect(() => getData(), [dispatch, getData])

	return (
		<div className={styles.wrapper}>
			<SelectButton iconsize={'big'} onClick={activateQuestionModal}>
				{t('be_tested')}
			</SelectButton>
			<div className={styles.mrg}>
				<Title ground={'notcolor'}>{t('history_passing')}</Title>
				<Table getData={getData} />
			</div>
		</div>
	)
}
