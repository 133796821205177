import { Title } from '@/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Docs.module.scss'
import { ProfUnionsViewConfirmDocument } from '@/components/ProfUnionsViewDocuments/ProfUnionsViewConfirmDocument'

export const Docs = ({ tradeUnion, onToggleAction, myUnion }) => {
	const { t } = useTranslation()
	const { fileCharter, fileProtocol, fileReference } = tradeUnion
	const files = [
		{ file: fileCharter, typeName: t('profUnions.charter') },
		{ file: fileProtocol, typeName: t('profUnions.protocol') },
		{ file: fileReference, typeName: t('profUnions.registration') }
	]
	const data = files.filter((item) => item.file !== null)
	return (
		<section>
			<div className={styles.docs}>
				<Title>{t('profUnions.docs')}</Title>
			</div>
			<ProfUnionsViewConfirmDocument data={data} local={true} />
		</section>
	)
}
