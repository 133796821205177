export const TOGGLE_LOADING = 'positionEvaluation/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'positionEvaluation/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'positionEvaluation/TOGGLE_ERROR'
export const SET_EVALUATIONS = 'positionEvaluation/SET_EVALUATIONS'
export const SET_CALCULATION = 'positionEvaluation/SET_CALCULATION'
export const SET_GENERATED_PDF = 'positionEvaluation/SET_GENERATED_PDF'

export const SET_FORM_INITIAL_DATA = 'positionEvaluation/SET_FORM_INITIAL_DATA'


