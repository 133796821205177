export const calculateAge = (birthdate) => {
	if (!birthdate) return false
	let dateParts = birthdate.split('.')
	let birthdateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

	let today = new Date()
	let age = today.getFullYear() - birthdateObject.getFullYear()
	let m = today.getMonth() - birthdateObject.getMonth()

	if (m < 0 || (m === 0 && today.getDate() < birthdateObject.getDate())) {
		age--
	}

	return age >= 55
}
