import React from 'react'
import styles from './ViewEmployeeInformation.module.scss'
import { Button, ModalTemplate, Title } from '@/ui'
import { EmployeeInformation } from './EmployeeInformation/EmployeeInformation'
import { useTranslation } from 'react-i18next'

export const ViewEmployeeInformation = ({ position, handleCancel }) => {
	const { t } = useTranslation()
	return (
		<ModalTemplate onClose={handleCancel} priority={3}>
			<div className={`scroll ${styles.wrapper}`}>
				<section>
					<Title color={'black'}>{position.fullName}</Title>
				</section>
				<div className={styles.informationSections}>
					<section>
						<EmployeeInformation position={position}></EmployeeInformation>
					</section>
				</div>
				<div className={styles.close}>
					<Button onClick={handleCancel}>{t('close')}</Button>
				</div>
			</div>
		</ModalTemplate>
	)
}
