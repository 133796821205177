import React, { useCallback, useEffect } from 'react'
import { WorkplaceCard } from './WorkplaceCard'
import styles from '@features/staffing/Staffing.module.scss'
import { ModalTemplate } from '@/ui'
import { useDispatch } from 'react-redux'
import { getWorkPlaceList } from '@/entity/staffing/thunks'

export const View = ({ onClose, onToggleAction, workPlaceRowSelect, deactivateStaff }) => {
	const dispatch = useDispatch()
	const onCreate = useCallback(() => onToggleAction('create', true), [onToggleAction])
	useEffect(() => {
		dispatch(getWorkPlaceList(workPlaceRowSelect.externalId))
	}, [])
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper}`}>
				<WorkplaceCard
					workPlaceRowSelect={workPlaceRowSelect}
					onClose={onClose}
					onCreate={onCreate}
					deactivateStaff={deactivateStaff}
				></WorkplaceCard>{' '}
			</div>
		</ModalTemplate>
	)
}
