import React from 'react'
import styles from '../AboutProject/styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Title } from '@Root/components/Typography'
import Footer from '@/components/home/components/Footer'
import Header from '@/components/Header'
import PreHeader from '@/components/home/components/PreHeader'

/*const resolveNextProblems = [
	'for_worker_page.services.shorting_terms_of_employment',
	'for_worker_page.services.proactive_registration',
	'for_worker_page.services.opportunity_of_getting_services',
	'for_worker_page.services.protect_from_socials_risks',
]
const effectsForEmployers = [
	'for_worker_page.effects_for_workers_list.order_has_power',
	'for_worker_page.effects_for_workers_list.easy_assign',
	'for_worker_page.effects_for_workers_list.discipline_of_interaction',
	'for_worker_page.effects_for_workers_list.shorting_time_within_employment',
]*/
export const ForWorkerPage = () => {
	const { t } = useTranslation()
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<Header active="for_worker" dark />
			<div className={`${styles.page} container`}>
				<Title level={1}>{t('for_worker')}</Title>
				<div className={styles.main_content}>
					<p>{t('on_the_basis')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('first_of_all')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('in_the_near_future')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('ESUTD_is_reference_base')}</p>
				</div>
				<div className={styles.main_content}>
					<p>
						<b>{t('main_effects')}</b>
					</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('remote_and_easy')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('reducing_the_time')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('receive_proactive')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('receipt_of_commercial')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('monitoring_compliance')}</p>
				</div>
			</div>
			<Footer />
		</>
	)
}
