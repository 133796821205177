import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	themesLoading: (payload) => ({
		type: types.THEMES,
		payload,
	}),
	questionsLoading: (payload) => ({
		type: types.QUESTIONS,
		payload,
	}),
	activeTheme: (payload) => ({
		type: types.THEME,
		payload,
	}),
	activeThemeLabel: (payload) => ({
		type: types.THEMELABEL,
		payload,
	}),
	statusCheck: (payload) => ({
		type: types.STATUSCHECK,
		payload,
	}),
	statusSelect: (payload) => ({
		type: types.STATUSSELECT,
		payload,
	}),
	selfTestResultResponse: (payload) => ({
		type: types.TESTRESULT,
		payload,
	}),
	answerSelfTestLoading: (payload) => ({
		type: types.PDF,
		payload,
	}),
	setChecks: (payload) => ({
		type: types.SET_CHECKS,
		payload,
	}),
	setSelectedCheck: (payload) => ({
		type: types.SET_SELECTED_CHECK,
		payload,
	}),
	checksAll: (payload) => ({
		type: types.CHECKS_ALL,
		payload,
	}),
	resultTypes: (payload) => ({
		type: types.RESULT_TYPES,
		payload,
	})
}
