import { useTranslation } from 'react-i18next'
import { ConfirmModal, Title } from '@/ui'
import styles from './AdditionalContractStyles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { contractsSelectors, contractsThunks } from '@/entity'
import { useCallback, useMemo } from 'react'
import { request } from '@/shared/api/request'
import { actions } from '@/entity/contracts/model/actions'
import { message } from 'antd'

export const RemoveAdditionalAgreementModal = ({ onCancel, externalId }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { registerDate, contractNumber, econtractFound, additionalContracts } =
		useSelector(contractsSelectors.contract) || {}
	const { contractNum, dcontractState } = useSelector(contractsSelectors.additionalContract) || {}
	const isLocal = useMemo(() => dcontractState.id === 2, [dcontractState])

	const onDelete = useCallback(async () => {
		if (econtractFound) {
			await request({
				url: '/contract/eAdContract/delete',
				method: 'DELETE',
				params: {
					externalId: externalId,
				},
			})
			message.success(t('success_alert'), 5)
			dispatch(
				actions.setContractFields({
					additionalContracts: additionalContracts.filter((a) => a.externalId !== externalId),
				})
			)
			onCancel()
		} else {
			isLocal
				? dispatch(contractsThunks.deleteLocalAdditionalContract())
				: dispatch(contractsThunks.deleteAdditionalContract())
		}
	}, [dispatch, isLocal])

	return (
		<ConfirmModal
			onProcess={onDelete}
			onCancel={onCancel}
			cancelButtonTitle={'cancel'}
			processButtonTitle={'delete_action'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Title className={styles.content__title}>{t('contracts_tab.deleteAdditionalContract')}</Title>
				<p
					dangerouslySetInnerHTML={{
						__html: t('contracts_tab.deleteAdditionalContractText', {
							additionalContent: t('contracts_tab.deleteAdditionalContractNumber', {
								additionalContractNumber: contractNum,
								contractNumber,
								registerDate,
							}),
						}),
					}}
				/>
			</div>
		</ConfirmModal>
	)
}
