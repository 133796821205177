import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Input, Select } from '@/ui'
import styles from '../SocialLeaveStyles.module.scss'
import { DatePicker } from '@/ui'
import s from '@/components/entrance/radioSelect.module.css'
const LeaveTypeSelect = ({ setHideFields, options, placeholder, value, onChange, disabled = false }) => {
	const handleTypeSelect = useCallback(
		(code) => {
			setHideFields(code === '2')
			onChange(code)
		},
		[onChange, setHideFields]
	)

	return (
		<Select
			disabled={disabled}
			onChange={handleTypeSelect}
			options={options}
			placeholder={placeholder}
			value={value || ''}
		/>
	)
}
export const Form = ({ socialLeave, onCancel, contract, socialLeaveTypes, setIsLocal, setHideFields, hideFields }) => {
	const { t } = useTranslation()
	const { isValid, values, setValues } = useFormikContext()
	const [radio, setRadio] = useState(null)
	const isSigned = socialLeave?.dcontractState?.id === 1

	useEffect(() => {
		const result =
			contract.pregnantSickLeaves && contract.pregnantSickLeaves.length
				? contract.pregnantSickLeaves.find(
						(a) =>
							a.dleaveType.code === values.dleaveTypeCode &&
							a.beginDate === values.beginDate &&
							a.endDate === values.endDate &&
							a.firstDayDate === values.firstDayDate
				  )
				: null
		if (result) {
			const index = contract.pregnantSickLeaves.indexOf(result)
			if (index >= 0) setRadio(index)
		}
	}, [])
	const chooseRadio = (data) => {
		setValues({
			dleaveTypeCode: data ? data.dleaveType.code : '',
			beginDate: data ? data.beginDate : '',
			endDate: data ? data.endDate : '',
			firstDayDate: data ? data.firstDayDate : '',
		})
		setHideFields(false)
	}
	return (
		<FormikForm className={styles.form}>
			{contract.pregnantSickLeaves && contract.pregnantSickLeaves.length > 0 && (
				<div className={styles.radioLabel}>
					<p style={{ fontWeight: 700 }}>{t('social_leave.form.text')}</p>
					<div>
						<input
							type="radio"
							className={s.radio}
							value="bin"
							id="radio1"
							checked={radio === null}
							name="entranceType"
							onChange={() => {
								setRadio(null)
								chooseRadio(null)
							}}
						/>
						<label htmlFor="radio1">{t('social_leave.form.oundata')}</label>
					</div>
					{contract.pregnantSickLeaves.map((sickLeave, index) => {
						return (
							<div key={index}>
								<input
									type="radio"
									className={s.radio}
									value="bin"
									id="radio2"
									checked={radio === index}
									name="entranceType"
									onChange={() => {
										setRadio(index)
										chooseRadio(sickLeave)
									}}
								/>
								<label htmlFor="radio2">
									{t('social_leave.form.noworkstart')} {sickLeave.beginDate} {t('social_leave.form.noworkteel')} {sickLeave.endDate} ({t('social_leave.form.noworkfinish')}{' '}
									{sickLeave.firstDayDate})
								</label>
							</div>
						)
					})}
				</div>
			)}

			<div className={styles.col1}>
				<div className={styles.row}>
					<div className="flex-1">
						<label>{t('social_leave.type')}*</label>
						<FormikField name="dleaveTypeCode">
							{({ field }) => (
								<LeaveTypeSelect
									options={socialLeaveTypes}
									disabled={radio != null}
									placeholder={t('select_from_list')}
									setHideFields={setHideFields}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				</div>
				<div className={styles.row}>
					<div className="flex-1">
						<label>{t('social_leave.begin_date_label')}*</label>
						<FormikField name="beginDate">
							{({ field }) => (
								<DatePicker disabled={radio != null} {...field} showIcon placeholder={t('default_date_value')} />
							)}
						</FormikField>
					</div>
					<div className="flex-1">
						<label>{t('social_leave.end_date_label')}*</label>
						<FormikField name="endDate">
							{({ field }) => (
								<DatePicker disabled={radio != null} {...field} showIcon placeholder={t('default_date_value')} />
							)}
						</FormikField>
					</div>
					<div className="flex-1">
						{!hideFields && (
							<>
								<label>{t('social_leave.first_date_label')}*</label>
								<FormikField name="firstDayDate">
									{({ field }) => (
										<DatePicker disabled={radio != null} {...field} showIcon placeholder={t('default_date_value')} />
									)}
								</FormikField>
							</>
						)}
					</div>
				</div>
				{!hideFields && (
					<div className={styles.row}>
						<div className="flex-1">
							<label>{t('social_leave.days_off_label')}</label>
							<FormikField name="daysOff">
								{({ field }) => <Input type="number" {...field} placeholder={t('social_leave.days_off_placeholder')} />}
							</FormikField>
						</div>
					</div>
				)}
				{!hideFields && (
					<div className={styles.row}>
						<div className="flex-1">
							<label>{t('social_leave.sheet_num_label')}*</label>
							<FormikField name="timeSheetNum">
								{({ field }) => <Input {...field} placeholder={t('social_leave.sheet_num_placeholder')} />}
							</FormikField>
						</div>
						<div className="flex-1">
							<label>{t('social_leave.department_ru')}</label>
							<FormikField name="departmentRu">
								{({ field }) => <Input {...field} placeholder={t('social_leave.department_placeholder')} />}
							</FormikField>
						</div>
						<div className="flex-1">
							<label>{t('social_leave.department_kz')}</label>
							<FormikField name="departmentKz">
								{({ field }) => <Input {...field} placeholder={t('social_leave.department_placeholder')} />}
							</FormikField>
						</div>
					</div>
				)}
			</div>
			<div className={styles.footer}>
				<Button type="bare" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<Button
					textColor="green"
					borderColor="green"
					transparent
					border
					disabled={!isValid || isSigned}
					onClick={() => setIsLocal(true)}
					type="submit"
				>
					{t('save')}
				</Button>
				<Button type="submit" disabled={!isValid || contract?.isLocalContract} onClick={() => setIsLocal(false)}>
					{t('save_and_sign')}
				</Button>
			</div>
		</FormikForm>
	)
}
