import { sendByBin } from '@/shared/api/rest/oppv'

export const getPaymentState = async (iin) => {
	const response = await sendByBin({ iin: iin })
	if (response?.outStatusCntuniq015per >= 1) {
		return 'personal_affairs.accepted_payments'
	} else if (response?.outStatusCntuniq015per === null) {
		return 'personal_affairs.empty_status'
	} else {
		return 'personal_affairs.not_accepted_payments'
	}
}
