import React from 'react'
import { Link } from 'react-router-dom'

const MenuItem = ({ link, title, external = false, href = '' }) => {
	return (
		<li>
			{external ? (
				<a href={href} target="_blank" rel="noopener noreferrer" className={'link'}>
					{title}
				</a>
			) : (
				<Link to={link} className={'link'}>
					{title}
				</Link>
			)}
		</li>
	)
}

export default MenuItem
