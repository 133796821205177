import { request } from '@/shared/api/request'

export const downloadFileUni = (contractFileId) =>
	request({ url: 'contract/downloadFileUni?contractFileId=' + contractFileId, method: 'GET', responseType: 'blob' })

export const downloadFileUniAdditional = (subContractFileId) =>
	request({
		url: 'contract/downloadFileUni?subContractFileId=' + subContractFileId,
		method: 'GET',
		responseType: 'blob',
	})
