import { Modal } from '@/components/Modal'
import styles from '@features/review/modals/Modal.module.scss'
import React, { memo, useEffect } from 'react'
import cl from 'classnames'
import { Button, Textarea } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contractsThunks, eColContractsThunks, userSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'
import { reviewActions, reviewThunks } from '@/entity/review'
import { useDigitalSign } from '@/shared'
import { message } from 'antd'

/**
 * @param {review | answer} modalViewType
 */

export const ReviewDeleteModal = memo(({
																				 modalViewType,
																				 onClose,
																				 onSubmit,
																				 onSuccess,
																				 item,
																			 }) => {

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const dispatch = useDispatch()

	const { successPayload, onSign, onClearPayload, failedPayload } = useDigitalSign()

	const { role, contract, currentReview } =
		useSelector((state) => ({
			role: userSelectors.activeRole(state),
			contract: contractActionsSelectors.contractView()(state),
			currentReview: state.review.currentReview,
		}))

	useEffect(() => {
		if(successPayload){
			let body = {
				data: {
					externalId: item?.externalId,
					signedXml: successPayload
				},
				onSuccess: () => {
					onClearPayload()
					handleClose()
					message.success(t('reviews.success_payload'), 5)

					onSuccess(item)
				}
			}
			dispatch(reviewThunks.deleteReview(body))
		}
	}, [successPayload])

	const title = {
		review: t('reviews.delete_review'),
		answer: t('reviews.delete_answer'),
	}

	const subtitle = {
		review: {
			ROLE_EMPLOYEE: t('reviews.delete_review_employer_agreement'),
			ROLE_EMPLOYER: t('reviews.delete_review_employee_agreement'),
		},
		answer: {
			ROLE_EMPLOYEE: t('reviews.delete_answer_agreement'),
			ROLE_EMPLOYER: t('reviews.delete_answer_agreement'),
		},
	}

	const handleSubmit = () => {
		onSubmit(item,
			(xml) => {
				onSign(xml)
			},
		)
	}

	const handleClose = () => {
		onClose()
		dispatch(reviewActions.currentReview(null))
		onClearPayload()
	}

	return (
		<Modal windowClassName={styles.modal_wrapper} onClose={handleClose}>
			<p className={styles.title}>
				{title?.[modalViewType]}
			</p>

			<p className={styles.subtitle}>
				{subtitle?.[modalViewType]?.[role]}
			</p>

			<div className={cl(styles.footer, 'mt-4')}>
				<Button onClick={handleSubmit}>
					{t('sign_and_send')}
				</Button>

				<Button transparent onClick={handleClose}>
					{t('no_cancel')}
				</Button>
			</div>

		</Modal>
	)
})