import React, { useCallback, useMemo, useState } from 'react'
import styles from './Table.module.scss'
import { useTranslation } from 'react-i18next'
import { HrPagination } from '@/components/Pagination'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useSelector } from 'react-redux'
import { affairsSelectors } from '@/entity/personalAffairs'
import { headCells, actions } from './utils'
import renderIcon from '@pages/PersonalAffairs/components/Icons'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { colors } from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/utils'
import { getCurrentLanguage } from '@/i18next'
import { formatDate } from '@/utils'

export const Table = ({ selectedRequest, setSelectedAction, setSelectedRequest, getData }) => {
	const { t } = useTranslation()
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)
	const { content: data, numberOfElements, totalElements } = useSelector(affairsSelectors.employeeRequests)
	const lang = getCurrentLanguage()
	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)

	const menuActions = useMemo(
		() =>
			actions
				.filter(({ action }) => (selectedRequest?.requestStatus.code === 'PENDING' ? actions : action === 'view'))
				.map((action) => ({ ...action, title: t(action.title) })),
		[t, selectedRequest?.requestStatus.code]
	)

	const onActionMenuChoose = (mode) => setSelectedAction(mode)

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'}>
				<td>{index + 1 || '-'}</td>
				<td>{row[lang === 'rus' ? 'orgNameRu' : 'orgNameKz'] || '-'}</td>
				<td>{row.requestDate ? formatDate(row.requestDate) : '-'}</td>
				<td>{row.consentDate ? formatDate(row.consentDate) : '-'}</td>
				<td>
					<p className={styles.textStatusWrapper}>
						{renderIcon(row.requestStatus.code.toLowerCase())}
						<span style={{ color: colors[row.requestStatus.code.toLowerCase()] }} className={styles.textStatus}>
							{row.requestStatus?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
						</span>
					</p>
				</td>
				<td>
					<DropDownMenu
						title={t('select_pls')}
						items={menuActions}
						onItemClick={onActionMenuChoose}
						onButtonClick={() => setSelectedRequest(row)}
					/>
				</td>
			</tr>
		),
		[t, menuActions]
	)

	const tableBody = useMemo(() => {
		return !!data && data.map((row, index) => getTableRow(row, index))
	}, [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th
						key={index + 'thead-th'}
						className={styles.thText}
						style={{ maxWidth: head.id === 'consentDate' ? '140px' : '100%' }}
					>
						{t(head.label)}
					</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	const handleChangePage = (e, _page) => {
		setPage(_page - 1)
		if (_page - 1 < 0) return getData('', 0, size)
		getData('', _page - 1 || 0, size)
	}

	const handleChangeRowsPerPage = (_size) => {
		setSize(_size)
		if (page - 1 < 0) return getData('', 0, _size)
		getData('', page - 1 || 0, _size)
	}

	const totalPages = useSelector((state) => state.affairs?.employeeRequests?.totalPages)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 30, label: '30' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
					// {size: null, label: 'pagination.show_all'},
				]}
				rowsPerPage={size}
				numberOfElements={numberOfElements}
				totalElements={totalElements}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={page}
			/>
		</>
	)
}
