import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	setEmployerAffairs: (payload) => ({
		type: types.SET_AFFAIRS,
		payload,
	}),
	setEmployeeAffairs: (payload) => ({
		type: types.SET_AFFAIRS,
		payload,
	}),
	setEmployeeCard: (payload) => ({
		type: types.SET_EMPLOYEE_CARD,
		payload,
	}),
	setError: (payload) => ({
		type: types.SET_ERROR,
		payload,
	}),
	setSelectedAffair: (payload) => ({
		type: types.SET_SELECTED_AFFAIR,
		payload,
	}),
	setEmployerRequests: (payload) => ({
		type: types.SET_EMPLOYER_REQUESTS,
		payload,
	}),
	setSelectedRequest: (payload) => ({
		type: types.SET_SELECTED_REQUEST,
		payload,
	}),
	setEmployeeRequests: (payload) => ({
		type: types.SET_EMPLOYEE_REQUESTS,
		payload,
	}),
}
