import styles from './PersonalTable.module.scss'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View as ViewIcon } from '@app/images'
import exportIcon from 'assets/pages/personalAffairs/export.svg'
import { getCurrentLanguage } from '@/i18next'
import { formatDate } from '@/utils'

export const PersonalTable = ({ headCells, data, onOpenDetailedView, downloadPDF }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const langOption = lang === 'rus' ? 'nameRu' : 'nameKz'

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'}>
				<td>{row.method?.code || '-'}</td>
				<td>{row.method?.[langOption] || '-'}</td>
				<td>{row.requestDate ? formatDate(row.requestDate) : '-'}</td>
				<td>{row.consentDate ? formatDate(row.consentDate) : '-'}</td>
				<td onClick={() => onOpenDetailedView(row.method?.code)}>
					<div className={styles.view__td}>
						<img src={ViewIcon} alt="" className={styles.icon} />
						{t('actions.view')}
					</div>
				</td>
				<td onClick={() => downloadPDF(row.method?.code)}>
					<img src={exportIcon} alt="" className={styles.icon} />
					{t('personal_affairs.card.table.headers.download')}
				</td>
			</tr>
		),
		[t]
	)

	const tableBody = useMemo(() => !!data && data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</>
	)
}
