import React, { useState } from 'react'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanel from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import GeneralInformation from './GeneralInformation/GeneralInformation'
import EmployeesInNeed from './EmployeesInNeed/EmployeesInNeed'
const WorkerWelfareMainTab = () => {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState(0)
	const handleChangeTab = (e, newValue) => setActiveTab(newValue)
	return (
		<>
			<section>
				<TabsUnstyled defaultValue={0} value={activeTab} onChange={handleChangeTab}>
					<TabsList>
						<Tab value={0}>{t('welfare.tabs.general')}</Tab>
						<Tab value={1}>{t('welfare.tabs.employeeList')}</Tab>
					</TabsList>
					<TabPanel value={0}>
						<GeneralInformation setActiveTab={setActiveTab} />
					</TabPanel>
					<TabPanel value={1}>
						<EmployeesInNeed />
					</TabPanel>
				</TabsUnstyled>
			</section>
		</>
	)
}

export default WorkerWelfareMainTab
