import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import styles from './DetailsOfPartiesInformation.module.scss'
import { Box } from '@mui/system'
import { DataGridTable, Title } from '@/ui'

export const DetailsOfPartiesInformation = ({ shortContractData, subTitleAction, hintInfo }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const detailsOfPartiesEmployee = useMemo(
		() => [
			{ title: t('yin'), data: shortContractData?.iin },
			{ title: t('fio'), data: shortContractData?.employeeFio },
		],
		[shortContractData, t]
	)

	const languageFields = {
		rus: 'orgNameRu',
		kz: 'orgNameKz',
	}

	return (
		<div>
			<div className={styles.title}>
				<Title>{t('parties_requisites')}</Title>
			</div>
			{hintInfo ? <div className={styles.hint_info}>{t('labor_records.hint_information')}</div> : null}
			<div className={styles.container}>
				<div className="flex-1">
					<p className={styles.subTitle}>{t('employer')}:</p>
					<Box display="flex" alignItems="flex-start">
						<div className={styles.titles}>
							<div className={styles.shynar}>{t('bin_iin')}:</div>
							<div className={styles.shynar}>{t('name')}:</div>
						</div>
						<div>
							<div className={styles.values}>{shortContractData.bin}</div>
							<div className={styles.values}>{shortContractData?.[languageFields[language]]}</div>
						</div>
					</Box>
				</div>
				<div className="flex-1">
					<DataGridTable
						hideTitle
						subTitle={t('employee')}
						titleClass={styles.shynar2}
						subTitleClass={styles.subTitle}
						information={detailsOfPartiesEmployee}
						subTitleAction={subTitleAction}
					/>
				</div>
			</div>
		</div>
	)
}
