import styles from './Terminations.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
export const Terminations = ({ detail }) => {
	const { t } = useTranslation()
	const { terminationReasons } = detail
	const lang = getCurrentLanguage()
	return (
		<>
			{detail.terminationReasons != null ? (
				<div className={styles.wrapper}>
					{t('labor_records.reason_termination')}
					{terminationReasons.map((value, index) => {
						return (
							<div key={index} className={styles.wrapper__content}>
								{index + 1 + ')'}
								<div className={styles.wrapper__content__left}>{lang === 'rus' ? value.nameRu : value.nameKz}</div>
							</div>
						)
					})}
				</div>
			) : null}
		</>
	)
}
