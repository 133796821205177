import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formFieldNames } from '@/entity'
import { IINValidationScheme } from '@/shared'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { Field, Button } from '@/ui'
import styles from './VerificationSms.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { verificationSelector, verificationThunks } from '@/entity/verification'
import { LoadingBlocker } from '@/components/Loader'
import { ResultSms } from './resultSms/ResultSms'

export const VerificationSms = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isLoading = useSelector(verificationSelector.isLoading)
	const isSuccess = useSelector(verificationSelector.isSuccess)
	const isError = useSelector(verificationSelector.isError)
	const phone = useSelector(verificationSelector.phone)
	const [iin, setIin] = useState(null)
	const initialValues = {
		[formFieldNames.iin]: '',
	}
	const getPhone = (value) => {
		setIin(value)
		dispatch(verificationThunks.getPhoneForIin({ iin: value }))
	}
	return (
		<>
			{isLoading ? <LoadingBlocker /> : null}
			{isSuccess && iin && phone ? (
				<ResultSms iin={iin} phone={phone} />
			) : (
				<Formik initialValues={initialValues} validationSchema={IINValidationScheme}>
					{({ values, isValid }) => {
						return (
							<FormikForm>
								<div className={styles.flexMobile}>
									<div className={styles.fields}>
										<FormikField name={formFieldNames.iin}>
											{({ field, meta: { touched, error } }) => (
												<Field
													hideNumberArrows
													label={t('iin')}
													placeholder={t('enter_iin', { count: 12 })}
													error={touched && error}
													{...field}
													maxLength={12}
													pattern="[0-9]{12}"
													onInput={(e) => {
														e.target.value = e.target.value.replace(/[^0-9]/g, '')
													}}
												/>
											)}
										</FormikField>
									</div>
									<div className={styles.fieldAndButton}>
										<Button disabled={!isValid} type="button" onClick={() => getPhone(values.iin)}>
											{t('send')}
										</Button>
									</div>
								</div>
								{isSuccess && phone === null ? t('error_bmg') : null}
								<div className={styles.error}>{isError && !isLoading ? t('error_bmg_service') : null}</div>
							</FormikForm>
						)
					}}
				</Formik>
			)}
		</>
	)
}
