import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from './tradeMembersSearch.module.scss'

const MembersTradeSearch = ({setSearchIin, fetchData, searchIin}) => {
	const { t } = useTranslation()

	return (
		<div className={classes.searchRow}>
			<div className={classes.searchField}>
				<div className={classes.searchField__left}>
					<IconButton type="submit" aria-label="search">
						<SearchIcon />
					</IconButton>
					<InputBase
						fullWidth
						placeholder={t('membersSearchInput')}
						inputProps={{
							'aria-label': 'search',
						}}
						value={searchIin}
						onChange={(e) => {
							setSearchIin(e.target.value)
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault()
								fetchData()
							}
						}}
					/>
				</div>
				<span className={classes.searchField__button} onClick={() => fetchData()}>
					{t('find')}
				</span>
			</div>
		</div>
	)
}

export default MembersTradeSearch
