export const isLoading = (state) => state.eColContracts.isLoading
export const isSuccess = (state) => state.eColContracts.isSuccess
export const isLoadingContract = (state) => state.eColContracts.eColContract.isLoading
export const isSuccessContract = (state) => state.eColContracts.eColContract.isSuccess
export const eColContracts = (state) => state.eColContracts
export const eColContract = (state) => state.eColContracts.eColContract.data
export const eHtml = (state) => state.eColContracts.eColContract.eHtml
export const eHtmlSuccess = (state) => state.eColContracts.eColContract.eHtmlSuccess
export const currentEcd = (state) => state.eColContracts.eColContract.currentEcd
export const currentEcdEdit = (state) => state.eColContracts.eColContract.currentEcdEdit
export const isSendSuccessContract = (state) => state.eColContracts.eColContract.isSendSuccess
export const eColXml = (state) => state.eColContracts.eColContract.eColXml
