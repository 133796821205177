import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formsActions, formsSelectors } from '@/entity/forms'
import { downloadPdfById } from '@/shared/api/rest/selftest'
import styles from './Checklist.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import React from 'react'
import { Title } from '@/ui'
import SelectButton from '@/components/entrance/SelectButton'
import { getCurrentLanguage } from '@/i18next'
import { colorsTitle } from '@pages/Forms/Tabs/utils'
import check from '../../../../../assets/entrance/componentButton.svg'
import SmallButton from '@/components/entrance/SmallButton'

export const CheckList = ({ onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const selectedCheck = useSelector(formsSelectors.selectedCheck)
	const data = selectedCheck || ''

	const _onClose = () => {
		onClose && onClose()
		dispatch(formsActions.setSelectedCheck(null))
	}

	const downloadPDF = async () => {
		let lng = lang === 'rus' ? 'ru' : 'kz'
		const res = await downloadPdfById(lng, data.id)
		const file = new Blob([res], { type: 'application/pdf' })
		const fileURL = URL.createObjectURL(file)
		window.open(fileURL)
	}

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={_onClose} size={16} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.checkResult}>
						<div className={styles.flex}>
							<Title>
								{t('history_check.checklist')} "{data.section?.[lang === 'rus' ? 'nameRu' : 'nameKz' || '-']}"
							</Title>
							<SelectButton onClick={downloadPDF}>{t('download_result')}</SelectButton>
						</div>
						<div className={styles.flex}>
							<Title ground={'notcolor'} color={colorsTitle[data.result?.code?.toLowerCase()]}>
								{t('result')}
							</Title>
							<Title color={'white'} ground={colorsTitle[data.result?.code?.toLowerCase()]}>
								{data.result?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
							</Title>
						</div>
						<div className={styles.mb}>
							<p>{t('service_online_labor_consultant_check')}</p>
							<p>
								<b>{t('history_check.business_name')}: </b>
								{data?.orgName || '-'}
							</p>
							<p>
								<b>{t('self_test_date')}</b> {data.createDate} {t('year')}
							</p>
							<p>
								<b>{t('chapter')}</b> {data.section.code.replace(/\D+/g, '') + '. '}
								{data.section?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
							</p>
							<p>
								<b>{t('self_test_duration')}</b> {data.duration}
							</p>
						</div>
						<div className={styles.mt}>
							{Object.keys(data.breachList).length > 0 ? (
								<>
									<Title ground={'notcolor'}>{t('following_violations_identified')}</Title>
									{data.breachList.map((value, index) => {
										return (
											<p key={value.nameRu}>
												{index + 1 + '. '}
												{value?.[lang === 'rus' ? 'nameRu' : 'nameKz']}
											</p>
										)
									})}
								</>
							) : (
								<>
									<Title ground={'notcolor'} type={'h3'}>
										{t('no_violations_found')}
									</Title>
								</>
							)}
							{Object.keys(data.recomList).length > 0 ? (
								<div className={styles.mt}>
									<Title ground={'notcolor'}>{t('recommendationsTest')}</Title>
									{data.recomList.map((value, index) => {
										return (
											<p key={value.nameRu}>
												{index + 1 + '. '}
												{value?.[lang === 'rus' ? 'nameRu' : 'nameKz']}
											</p>
										)
									})}
								</div>
							) : (
								<></>
							)}
							<div className={styles.mt}>
								<Title ground={'notcolor'}>{t('history_check.answers_questions')}</Title>
								{data.questAnswList.map((value, index) => {
									return (
										<div className={styles.question} key={value.nameRu}>
											<Title ground={'notcolor'} color={'green'}>
												{' '}
												{t('question_number')}
												{index + 1}
											</Title>
											<p>{value.question?.[lang === 'rus' ? 'nameRu' : 'nameKz']}</p>
											<div className={styles.answ}>
												<img src={check} alt="check" />
												<p>{value.answer?.[lang === 'rus' ? 'nameRu' : 'nameKz']}</p>
											</div>
										</div>
									)
								})}
							</div>
							<div className={styles.flexDown}>
								<div className={styles.mr20}>
									<SelectButton onClick={downloadPDF}>{t('download_result')}</SelectButton>
								</div>
								<div className={styles.mr20}>
									<SmallButton onClick={_onClose}>{t('close')}</SmallButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}
