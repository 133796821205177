import styles from '@/entity/grading/positionEvaluation/ui/form/PositionEvaluationForm.module.scss'
import { Button, Checkbox, Field } from '@/ui'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { formFieldNames, positionEvaluationsSelectors, positionEvaluationsThunks } from '@/entity'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingBlocker } from '@/components/Loader'
import { getCurrentLanguage } from '@/i18next'
import { contractActionsSelectors } from '@features/contracts/model'
import { Functions } from '@/entity/grading/positionEvaluation/ui/form/components/Functions'
import { string } from 'yup'

export const Form = ({ onCalculated }) => {
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const lang = useMemo(() => (currentLanguage === 'rus' ? 'nameRu' : 'nameKz'), [currentLanguage])
	const [isFormDirty, setFormDirty] = useState(false)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const dispatch = useDispatch()

	const { resetForm, setFieldValue, values, submitForm, isSubmitting, validateForm, setTouched, errors, ...form } =
		useFormikContext()

	const [activityAreas, setActivityAreas] = useState([])
	const [filteredGroups, setFilteredGroups] = useState([])
	const [filteredPositions, setFilteredPositions] = useState([])
	const [categories, setCategories] = useState([])
	const [functionalBlock, setFunctionalBlock] = useState()
	const [educations, setEducations] = useState([])
	const [workExperiences, setWorkExperiences] = useState([])
	const [functions, setFunctions] = useState([])

	const {
		calcActivityArea,
		calcOrganizationType,
		calcPositionRegister,
		calcFunctionalDuty,
		calcPositionRegisterHasDutyFunction,
		calcFunctionalBlock,
		calcDutyFunction,
		calcBlockHasCalcDuty,
		calcFactor,
		calcAssessmentCriteria,
		calcBase,
		calcGradeHaveDuty,
		calcGradeLevel,
	} = useSelector(positionEvaluationsSelectors.options)

	const { isLoading, calculation } = useSelector((state) => ({
		isLoading: positionEvaluationsSelectors.isLoading(state),
		calculation: positionEvaluationsSelectors.calculation(state),
	}))

	useEffect(() => {
		if (calcActivityArea && calcActivityArea.length > 0)
			setActivityAreas(
				calcActivityArea.map(({ nameRu, nameKz, id, code, ...props }) => ({
					rus: nameRu,
					kz: nameKz,
					code: id,
					...props,
				}))
			)
	}, [calcActivityArea])

	useEffect(() => {
		if (calculation) onCalculatedInternal()
	}, [calculation])

	useEffect(() => {
		dispatch(positionEvaluationsThunks.getOptions())
	}, [])

	useEffect(() => {
		if (!activeCompany) return
		setFieldValue('bin', activeCompany.bin)
		setFieldValue('organization', activeCompany.companyName)
	}, [activeCompany])

	const onActivityChange = (id) => {
		setFieldValue('group', null)
		setFieldValue('position_by_nkz', null)
		setFieldValue('education', null)
		setFieldValue('workExperience', null)
		setFieldValue('functionalBlock', null)
		setFieldValue('activityArea', id)
		setFilteredGroups(
			calcOrganizationType
				.filter((item) => item.activityAreaId === id)
				.map(({ nameRu, nameKz, id, code, ...props }) => ({ rus: nameRu, kz: nameKz, code: id, ...props }))
		)
	}

	const onGroupChange = (id) => {
		setFieldValue('position_by_nkz', null)
		setFieldValue('education', null)
		setFieldValue('workExperience', null)
		setFieldValue('functionalBlock', null)
		setFieldValue('group', id)
		const activityId = values.activityArea
		setFilteredPositions(
			calcPositionRegister
				.filter((item) => item.activityAreaId === activityId && item.organizationTypeId === id)
				.map(({ nameRu, nameKz, id, code, ...props }) => ({
					rus: code + ' ' + nameRu,
					kz: code + ' ' + nameKz,
					code: id,
					...props,
				}))
		)
	}

	const onPositionChange = (id) => {
		setFieldValue('education', null)
		setFieldValue('workExperience', null)
		setFieldValue('functionalBlock', null)

		if (!id) {
			setFieldValue('position_by_nkz', null)
			return
		}
		setFieldValue('position_by_nkz', id)
		const selectedPosition = calcPositionRegister.find((it) => it.id === id)

		const gradeLevel = calcGradeLevel.find((item) => item.id == selectedPosition.gradeLevel)
		if (gradeLevel) {
			setFieldValue('gradeLevelCode', gradeLevel.code)
			const fds = calcGradeHaveDuty.filter((item) => item.gradeLevelId === gradeLevel.id)

			setCategories(
				calcFunctionalDuty
					.filter((item) => fds.some((x) => x.dutyCode === 'category' && x.functionalDutyId === item.id))
					.map(({ nameRu, nameKz, id, code, ...props }) => ({ rus: nameRu, kz: nameKz, code: id, ...props }))
			)

			setEducations(
				calcFunctionalDuty
					.filter((item) => fds.some((x) => x.dutyCode === 'education' && x.functionalDutyId === item.id))
					.map(({ nameRu, nameKz, id, code, ...props }) => ({ rus: nameRu, kz: nameKz, code: id, ...props }))
			)

			setWorkExperiences(
				calcFunctionalDuty
					.filter((item) => fds.some((x) => x.dutyCode === 'experience' && x.functionalDutyId === item.id))
					.map(({ nameRu, nameKz, id, code, ...props }) => ({ rus: nameRu, kz: nameKz, code: id, ...props }))
			)

			const currentDate = new Date()
			setFieldValue(
				'coef',
				calcBase.find(
					(item) =>
						item.code === gradeLevel.highDo &&
						new Date(item.startedAt) <= currentDate &&
						new Date(item.endedAt) >= currentDate &&
						!item.locked
				)?.dataValue
			)
		} else {
			setFieldValue('gradeLevelCode', null)
			setCategories([])
			setEducations([])
			setWorkExperiences([])
			setFieldValue('coef', null)
		}

		setFieldValue('category', null)
		setFieldValue('education', null)
		setFieldValue('workExperience', null)

		const funcBlock = calcFunctionalBlock.find((it) => it.id === selectedPosition.functionBlockId)
		if (funcBlock) {
			setFunctionalBlock(funcBlock)
			setFieldValue('functionalBlock', funcBlock[lang])
		} else {
			setFieldValue('functionalBlock', undefined)
		}

		const posFunctions = calcPositionRegisterHasDutyFunction.filter((item) =>
			selectedPosition.posRegHasDutyFuncIds.includes(item.id)
		)
		const functionsList = calcDutyFunction
			.filter((item) => posFunctions.some((x) => x.dutyFunctionId === item.id))
			.map((item) => {
				const fd = calcFunctionalDuty.find((x) => x.id === item.functionalDutyId)
				const factor = calcFactor.find((x) => x.id === fd.factorId)
				return {
					fn: item,
					functionalDuty: fd,
					factor: factor,
					criteria: calcAssessmentCriteria.find((x) => x.id === factor.assessmentCriteriaId),
				}
			})
		setFunctions(functionsList)
	}

	const getValue = (list, code) => {
		return list.find((it) => it.id === code)[lang]
	}

	const getItem = (list, code) => {
		const item = list.find((it) => it.id === code)
		if (item) {
			return { code: item.code, id: item.id, nameRu: item.nameRu, nameKz: item.nameKz, pointsNumber: item.pointsNumber }
		}
		return null
	}

	const onClick = () => {
		setFormDirty(true)

		if (Object.keys(errors).length > 0) return

		const currentDate = new Date()

		dispatch(
			positionEvaluationsThunks.calculate({
				educationId: String(values.education),
				experienceId: String(values.workExperience),
				categoryId: String(values.category),
				gradeLevelCode: values.gradeLevelCode,
				mrpId: String(
					calcBase.find(
						(item) =>
							item.code === 'MRP' &&
							new Date(item.startedAt) <= currentDate &&
							new Date(item.endedAt) >= currentDate &&
							!item.locked
					)?.id
				),
				salaryPriceId: String(
					calcBase.find(
						(item) =>
							item.code === 'SALARY_PRICE' &&
							new Date(item.startedAt) <= currentDate &&
							new Date(item.endedAt) >= currentDate &&
							!item.locked
					)?.id
				),
				isVillage: !!values.increaseForRuralWork,
				coef: values.coef,
			})
		)
	}

	const onCalculatedInternal = () => {
		const calculateData = {
			calculation: calculation,
			functions: functions,
			result: {
				activityArea: getValue(calcActivityArea, values.activityArea),
				bin: values.bin,
				education: getValue(calcFunctionalDuty, values.education),
				experience: getValue(calcFunctionalDuty, values.workExperience),
				funcBlock: values.functionalBlock,
				funcFactors: functions.map((it, index) => ({
					number: index + 1,
					function: it.fn[lang],
					factor: it.functionalDuty[lang],
				})),
				isVillage: !!values.increaseForRuralWork,
				orgName: values.organization,
				orgType: getValue(calcOrganizationType, values.group),
				posName: getValue(calcPositionRegister, values.position_by_nkz),
				mrp: calculation.mrp,
				pointPrice: calculation.pointPrice,
				grade: calculation.grade,
				gradePoint: calculation.gradePoint,
				educationPoint: calculation.educationPoint,
				experiencePoint: calculation.experiencePoint,
				categoryPoint: calculation.categoryPoint,
				totalPoint: calculation.finalPoint,
				coef: calculation.coef,
				do: calculation.do,
			},
		}
		onCalculated(calculateData)
	}

	const clear = () => {
		setFieldValue('activityArea', undefined)
		setFieldValue('group', undefined)
		setFieldValue('position_by_nkz', undefined)
		setFieldValue('education', undefined)
		setFieldValue('workExperience', undefined)
		setFieldValue('functionalBlock', undefined)
		setFunctions([])
		onCalculated(null)
		setFormDirty(true)
	}

	const removeFunction = (index) => {
		const fns = functions.filter((_, i) => i !== index)
		setFunctions(fns)
	}

	return (
		<>
			{isLoading && <LoadingBlocker />}
			<FormikForm className={styles.form}>
				<h2>{t('position_evaluation')}</h2>
				<FormikField name={'activityArea'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							options={activityAreas}
							label={t('position_evaluation_page.form.occupation')}
							error={(touched || isFormDirty) && error}
							disabled={activityAreas.length === 0}
							isRequired
							{...field}
							onChange={onActivityChange}
						/>
					)}
				</FormikField>
				<FormikField name={'group'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							options={filteredGroups}
							label={t('position_evaluation_page.form.group')}
							error={(touched || isFormDirty) && error}
							disabled={filteredGroups.length === 0}
							{...field}
							onChange={onGroupChange}
						/>
					)}
				</FormikField>
				<FormikField name={'bin'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="input"
							label={t('position_evaluation_page.form.bin')}
							error={(touched || isFormDirty) && error}
							disabled
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'organization'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="input"
							label={t('position_evaluation_page.form.organization')}
							error={(touched || isFormDirty) && error}
							disabled
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'position_by_nkz'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="selectSearch"
							options={filteredPositions}
							label={t('position_evaluation_page.form.position_by_nkz')}
							disabled={filteredPositions.length === 0}
							isRequired
							error={(touched || isFormDirty) && error}
							{...field}
							onChange={onPositionChange}
						/>
					)}
				</FormikField>
				<FormikField name={'functionalBlock'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="input"
							label={t('position_evaluation_page.form.functionalBlock')}
							error={(touched || isFormDirty) && error}
							disabled
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'education'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							options={educations}
							label={t('position_evaluation_page.form.education')}
							disabled={educations.length === 0}
							error={(touched || isFormDirty) && error}
							isRequired
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'workExperience'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							options={workExperiences}
							label={t('position_evaluation_page.form.workExperience')}
							error={(touched || isFormDirty) && error}
							isRequired
							disabled={workExperiences.length === 0}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'category'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							options={categories}
							label={t('position_evaluation_page.form.category')}
							error={(touched || isFormDirty) && error}
							isRequired
							disabled={categories.length === 0}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'increaseForRuralWork'}>
					{({ field, meta: { touched, error } }) => (
						<Checkbox
							{...field}
							label={t('position_evaluation_page.form.increaseForRuralWork')}
							onChange={() => setFieldValue(field.name, !field.value)}
							value={field.value}
						/>
					)}
				</FormikField>
				{/*<div>*/}
				{/*	<label>{t('position_evaluation_page.form.functionsAndFactors')}</label>*/}
				{/*	/!*<Functions data={functions} removeFunction={removeFunction}></Functions>*!/*/}
				{/*	<Functions data={functions}></Functions>*/}
				{/*</div>*/}

				<div className={styles.buttons}>
					<Button type="submit" onClick={onClick}>
						{t('position_evaluation_page.form.calculate')}
					</Button>
					<Button onClick={clear}>{t('cancel')}</Button>
				</div>
			</FormikForm>
		</>
	)
}
