import React from 'react'

export const EcoSystemEnbek = () => {
	return (
		<svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill="none">
			<rect x="0.0820312" y="0.209961" width="43" height="43" rx="21.5" fill="#17B67C" />
			<path
				d="M25.7683 11.9375H28.9606C30.2814 11.9375 31.3529 13.0183 31.3529 14.3515V17.5706C31.3529 18.9029 30.2814 19.9846 28.9606 19.9846H25.7683C24.4465 19.9846 23.375 18.9029 23.375 17.5706V14.3515C23.375 13.0183 24.4465 11.9375 25.7683 11.9375Z"
				stroke="white"
			/>
			<path
				d="M14.2019 11.9375H17.3932C18.715 11.9375 19.7865 13.0183 19.7865 14.3515V17.5706C19.7865 18.9029 18.715 19.9846 17.3932 19.9846H14.2019C12.8801 19.9846 11.8086 18.9029 11.8086 17.5706V14.3515C11.8086 13.0183 12.8801 11.9375 14.2019 11.9375Z"
				stroke="white"
			/>
			<path
				d="M14.2019 23.4355H17.3932C18.715 23.4355 19.7865 24.5163 19.7865 25.8506V29.0686C19.7865 30.4019 18.715 31.4827 17.3932 31.4827H14.2019C12.8801 31.4827 11.8086 30.4019 11.8086 29.0686V25.8506C11.8086 24.5163 12.8801 23.4355 14.2019 23.4355Z"
				stroke="white"
			/>
			<path
				d="M25.7683 23.4355H28.9606C30.2814 23.4355 31.3529 24.5163 31.3529 25.8506V29.0686C31.3529 30.4019 30.2814 31.4827 28.9606 31.4827H25.7683C24.4465 31.4827 23.375 30.4019 23.375 29.0686V25.8506C23.375 24.5163 24.4465 23.4355 25.7683 23.4355Z"
				stroke="white"
			/>
		</svg>
	)
}
