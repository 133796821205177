import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './locales'

//I18next must be entity in feature, because this function is here as related to the i18next entity, not shared/lin
export * from './getCurrentLanguage'

export const i18next = i18n.use(initReactI18next).init({
	resources,
	fallbackLng: 'kz',
	lng: 'kz',
	debug: false,
	ns: ['translation'],
	defaultNS: 'translation',
	supportedLngs: ['kz', 'rus'],
	keySeparator: '.',
	react: {
		useSuspense: false,
	},
	interpolation: {
		escapeValue: false,
	},
})

export const datePickerlocaleRu = {
	days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
	daysShort: ['Воск', 'Пон', 'Втор', 'Сред', 'Четв', 'Пятн', 'Субб'],
	daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
	months: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Мая',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],
	monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
	today: 'сегодня',
	clear: 'очистить',
	format: 'dd.mm.yyyy',
	titleFormat: 'MM yyyy' /* Leverages same syntax as 'format' */,
	weekStart: 1,
	defaultViewDate: new Date(),
}

export const datePickerlocaleKz = {
	days: ['Жексенбi', 'Дүйсенбi', 'Сейсенбi', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі'],
	daysShort: ['Жекс', 'Дүй', 'Сей', 'Сәр', 'Бей', 'Жұм', 'Сен'],
	daysMin: ['Жк', 'Дс', 'Се', 'Ср', 'Бс', 'Жм', 'Сб'],
	months: [
		'Қаңтар',
		'Ақпан',
		'Наурыз',
		'Сәуір',
		'Мамыр',
		'Маусым',
		'Шілде',
		'Тамыз',
		'Қыркүйек',
		'Қазан',
		'Қараша',
		'Желтоқсан',
	],
	monthsShort: ['Қаң', 'Ақп', 'Нау', 'Сәу', 'Мам', 'Мау', 'Шіл', 'Там', 'Қыр', 'Қаз', 'Қар', 'Жел'],
	today: 'бүгін',
	clear: 'жою',
	format: 'dd.mm.yyyy',
	titleFormat: 'MM yyyy' /* Leverages same syntax as 'format' */,
	weekStart: 1,
	defaultViewDate: new Date(),
}