import { useRef } from 'react'

import styles from './style.module.scss'
import { SmallLightArrow } from '@app/images'
import { useToggle, useClickOutside } from '@/shared'

export const Select = ({ roles, activeRole, userRoles = [], onChangeRole }) => {
	const [isOpen, toggleOpen] = useToggle(false)

	const selectRef = useRef(null)

	const onClickOutside = () => {
		if (isOpen) toggleOpen()
	}

	const onChange = (role) => {
		onChangeRole(role)
		toggleOpen()
	}

	useClickOutside(selectRef, isOpen, onClickOutside)

	return (
		<div className={styles.select} ref={selectRef}>
			<div className={styles.information}>
				<span>Вы вошли как:</span>
				<span className={styles.active} onClick={toggleOpen}>
					{activeRole?.title}
					<img src={SmallLightArrow} alt="arrow" />
				</span>
			</div>

			{isOpen && (
				<div className={styles.list}>
					{roles.map((item) => {
						const isActive = activeRole?.label === item.label
						const hasRole = true
						return (
							<div
								className={`${styles.item} ${isActive ? styles.activeItem : ''} ${!hasRole ? styles.blockedItem : ''}`}
								onClick={hasRole ? () => onChange(item) : null}
								key={item.label}
							>
								<p className={styles.title}>{item.title}</p>
								{isActive ? (
									<span className={styles.activeRole}>активная роль</span>
								) : (
									<span className={styles.changeRole}>Переключиться</span>
								)}
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}
