import React, { useMemo, useRef, useState, useCallback } from 'react'
import { Arrow } from '@app/icons'
import { useToggle, useClickOutside } from '@/shared'
import { Options } from '@/ui/atoms'
import { InputWithIcon } from '@/ui'
import styles from './SelectSearch.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Close8 } from '@app/images'

export const SelectSearch = ({
	value,
	onChange,
	onCustomChange,
	options = [],
	optionObjectKey = null,
	showItems = 5,
	placeholder,
	onBlur,
	className = '',
	hideNotFoundError = false,
	...props
}) => {
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const [searchValue, setSearchValue] = useState('')
	const fieldRef = useRef()

	const currentLang = getCurrentLanguage()

	const onSelect = useCallback(
		(id) => {
			if (onCustomChange) onCustomChange(id)
			onChange(id)
			setSearchValue('')
			toggleOpenedOptions()
		},
		[onChange, toggleOpenedOptions]
	)

	const onClickOutside = useCallback(() => toggleOpenedOptions(), [toggleOpenedOptions])

	const handleValueType = useCallback(
		(inputValue) => {
			setSearchValue(inputValue)
			if (onCustomChange) onCustomChange(null)
			onChange(null)
			if (!isOpenedOptions) {
				toggleOpenedOptions()
			}
		},
		[onChange, toggleOpenedOptions, isOpenedOptions]
	)

	const selectedValue = useMemo(() => {
		const option = options.find(({ code }) => code === value)
		const optionLang = currentLang === 'rus' ? 'nameRu' : 'nameKz'
		return searchValue || option?.[currentLang] || option?.[optionLang] || value
	}, [currentLang, options, searchValue, value])

	const filteredOptions = useMemo(() => {
		return options?.filter((option) => {
			const text = optionObjectKey
				? option[optionObjectKey]
				: currentLang === 'rus'
				? option.nameRu || option.rus
				: option.kz || option.nameKz
			return text && text.toLowerCase().includes(searchValue.toLowerCase())
		})
	}, [currentLang, options, searchValue])

	useClickOutside(fieldRef, isOpenedOptions, onClickOutside)

	const selectedOption = useMemo(() => options.find(({ code }) => value === code), [value, options])

	const onClear = () => {
		onChange('')
		setSearchValue('')
	}

	return (
		<div className={`${styles.wrapper} ${className}`} ref={fieldRef}>
			<InputWithIcon
				onChange={handleValueType}
				placeholder={placeholder}
				onClick={() => toggleOpenedOptions()}
				value={selectedValue}
				iconSide={'right'}
				icon={
					!selectedOption ? (
						<Arrow
							styles={{ fill: '#AAB8D1', transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`, width: '10px' }}
						/>
					) : (
						<img onClick={onClear} src={Close8} className={styles.closeIcon} alt="close_icon" />
					)
				}
				autoComplete="off"
				{...props}
			/>
			{isOpenedOptions && (
				<Options
					optionObjectKey={optionObjectKey}
					hideNotFoundError={hideNotFoundError}
					onSelect={onSelect}
					options={filteredOptions}
					showItems={showItems}
					onBlur={onBlur}
				/>
			)}
		</div>
	)
}
