import { actions } from './actions'
import { api } from '@/shared'

export const getDictionaryVersion = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.dict.getDictsVersion()
			localStorage.setItem('dictVersion', JSON.stringify(response))
			dispatch(actions.setDictVersion(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleLoading(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
