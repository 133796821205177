import { useDispatch, useSelector } from 'react-redux'
import {
	contractsSelectors,
	contractsThunks,
	eContractsActions,
	eContractsSelectors,
	eContractsThunks,
	userSelectors,
} from '@/entity'
import { useEffect, useState } from 'react'
import { useDigitalSign } from '@/shared'

export function useElectronicTermination(onUpdate = () => {}, onClose  = () => {}) {
	const dispatch = useDispatch()
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const terminationIsLoading = useSelector(eContractsSelectors.terminationIsLoading)
	const isTerminationSuccess = useSelector(eContractsSelectors.terminationIsSuccess)
	const xml = useSelector(eContractsSelectors.terminationXML)
	const isEmployer = useSelector(userSelectors.isEmployer)

	const { successPayload, onClearPayload, onSign, onSignMultiple } = useDigitalSign()

	useEffect(() => {
		if (!terminationReasons) dispatch(contractsThunks.getTerminationReasons())
	}, [terminationReasons, dispatch])

	useEffect(() => {
		if (isTerminationSuccess) {
			onClearPayload();
			onUpdate();
			onClose();
			dispatch(eContractsActions.setTerminationSuccess(false))
		}
	}, [isTerminationSuccess])

	useEffect(() => {
		if (xml) {
			if (isEmployer)
				onSignMultiple(xml)
			else
				onSign(xml);
			dispatch(eContractsActions.setTerminateXML(null))
		}
	}, [xml])

	useEffect(() => {
		if (successPayload) {
			dispatch(eContractsThunks.saveSign(successPayload));
		}
	}, [successPayload])

	const handleSignWithoutEmployee = (submitData, externalId) => {
		dispatch(eContractsThunks.saveAndGetXml(submitData, externalId))
	}

	const handleSign = (submitData) => {
		dispatch(eContractsThunks.getXml(submitData))
	}

	return {
		terminationReasons,
		terminationIsLoading,
		handleSignWithoutEmployee,
		handleSign
	}
}