import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	branchName: (payload) => ({
		type: types.SET_BRANCH_NAME,
		payload,
	}),
	resetBranch: () => ({
		type: types.RESET_BRANCH,
	}),
}
