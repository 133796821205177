import { FormControlLabel, RadioGroup, FormGroup, Box } from '@mui/material'
import styles from '../style.module.scss'
import { Radio } from '../Radio'
import { Checkbox } from '../Checkbox'
import { Field, Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Field as FieldComponent, Button } from '@/ui'

export const TemplateForm = ({ onSaveTemplate, onOpenProject, isAdditional, onClose, isLaborImmigrant }) => {
	const { t } = useTranslation()
	const { values, errors, validateForm } = useFormikContext()

	const handleSaveTemplate = async () => {
		const result = await validateForm()
		if (Object.values(result).length === 0) {
			onSaveTemplate(values)
		} else {
			console.log(result)
		}
	}

	const _onOpenProject = async () => {
		const result = await validateForm()
		if (Object.values(result).length === 0) {
			onOpenProject(values)
		} else {
			console.log(result)
		}
	}

	return (
		<Form>
			<div className={styles.option__block}>
				<div className={styles.option__title}>{t('electronic_contract.options.employerType')}</div>
				<Field name="employerType">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.0.1')}
									value="0.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.0.2')}
									value="0.2"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.0.3')}
									value="0.3"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>
			<div className={styles.option__block}>
				<div className={styles.option__title}>{t('electronic_contract.options.employeeType')}</div>
				<Field name="employeeType">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.14.1')}
									value="14.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.14.2')}
									value="14.2"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.14.3')}
									value="14.3"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>
			<div className={styles.option__block}>
				<div className={styles.option__title}>
					{t('electronic_contract.options.language')}
					{!isAdditional ? ' *' : ''}
				</div>
				<Field name="language">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={isAdditional ? <Radio disabled /> : <Radio />}
									label={t('electronic_contract.options.1.1')}
									value="1.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={isAdditional ? <Radio disabled /> : <Radio />}
									label={t('electronic_contract.options.1.2')}
									value="1.2"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>

			<div className={styles.option__block}>
				<div className={styles.option__title}>{t('electronic_contract.options.jobType')}</div>
				<Field name="jobType">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.2.1')}
									value="2.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.2.2')}
									value="2.2"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>

			{!isLaborImmigrant ? (
				<div className={styles.option__block}>
					<div className={styles.option__title}>
						{t('electronic_contract.options.workCondition')} {' *'}
					</div>
					<Field name="workCondition">
						{({ field, form: { errors }, name, options, children, ...props }) => {
							const fieldName = name || field?.name
							return (
								<RadioGroup {...field} {...props} name={fieldName}>
									<FormControlLabel
										control={<Radio />}
										label={t('electronic_contract.options.3.1')}
										value="3.1"
										classes={{ root: styles.label__root }}
									/>
									<FormControlLabel
										control={<Radio />}
										label={t('electronic_contract.options.3.2')}
										value="3.2"
										classes={{ root: styles.label__root }}
									/>
									{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
								</RadioGroup>
							)
						}}
					</Field>
				</div>
			) : null}

			<div className={styles.option__block}>
				<div className={styles.option__title}>{t('electronic_contract.options.workBeginning')}</div>
				<Field name="workBeginning">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.4.1')}
									value="4.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.4.2')}
									value="4.2"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>

			<div className={styles.option__block}>
				<div className={styles.option__title}>{t('electronic_contract.options.term')}</div>
				<Field name="term">
					{({ field, form: { errors }, name, options, children, ...props }) => {
						const fieldName = name || field?.name
						return (
							<RadioGroup {...field} {...props} name={fieldName}>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.1')}
									value="5.1"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.2')}
									value="5.2"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.3')}
									value="5.3"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.4')}
									value="5.4"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.5')}
									value="5.5"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.6')}
									value="5.6"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.7')}
									value="5.7"
									classes={{ root: styles.label__root }}
								/>
								<FormControlLabel
									control={<Radio disabled />}
									label={t('electronic_contract.options.5.9')}
									value="5.9"
									classes={{ root: styles.label__root }}
								/>
								{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
							</RadioGroup>
						)
					}}
				</Field>
			</div>

			{!isLaborImmigrant ? (
				<>
					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.probitionary')} {' *'}
						</div>
						<Field name="probitionary">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.6.1')}
											value="6.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.6.2')}
											value="6.2"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.6.3')}
											value="6.3"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.6.4')}
											value="6.4"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.workingTime')} {' *'}
						</div>
						<Field name="workingTime">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.7.1')}
											value="7.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.7.2')}
											value="7.2"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.salary')} {' *'}
						</div>
						<Field name="salary">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.8.1')}
											value="8.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.8.2')}
											value="8.2"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.bonus')} {' *'}
						</div>
						<Field name="bonus">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName} helpertext={errors?.[fieldName]}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.9.1')}
											value="9.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.9.2')}
											value="9.2"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.9.3')}
											value="9.3"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.mandatory')} {' *'}
						</div>
						<Field name="mandatory">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.10.1')}
											value="10.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.10.2')}
											value="10.2"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.downtime')}</div>
						<Field name="downtime">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.11.1')}
											value="11.1"
											{...field}
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.overtime')}</div>
						<Field name="overtime">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.12.1')}
											value="12.1"
											{...field}
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>
							{t('electronic_contract.options.paymentMethod')} {' *'}
						</div>
						<Field name="paymentMethod">
							{({ field, form: { errors }, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<RadioGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.13.1')}
											value="13.1"
											classes={{ root: styles.label__root }}
										/>
										<FormControlLabel
											control={<Radio />}
											label={t('electronic_contract.options.13.2')}
											value="13.2"
											classes={{ root: styles.label__root }}
										/>
										{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
									</RadioGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.employeeRights')}</div>
						<Field name="employeeRightsIs">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.34')}
											value="34"
											{...field}
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
						<Field name="employeeRights">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								if (!values.employeeRightsIs) {
									return null
								}
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FieldComponent
											placeholder={t('electronic_contract.employee_rights_placeholder')}
											electronic_contract
											rows={8}
											fieldType="textarea"
											{...field}
											plac
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.employeeResp')}</div>
						<Field name="employeeRespIs">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.35')}
											value="35"
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
						<Field name="employeeResp">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								if (!values.employeeRespIs) {
									return null
								}
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FieldComponent
											placeholder={t('electronic_contract.employee_works_placeholder')}
											rows={8}
											fieldType="textarea"
											{...field}
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.employerRights')}</div>
						<Field name="employerRightsIs">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.36')}
											value="36"
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
						<Field name="employerRights">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								if (!values.employerRightsIs) {
									return null
								}
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FieldComponent
											placeholder={t('electronic_contract.employer_rights_placeholder')}
											rows={8}
											fieldType="textarea"
											{...field}
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>

					<div className={styles.option__block}>
						<div className={styles.option__title}>{t('electronic_contract.options.employerResp')}</div>
						<Field name="employerRespIs">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FormControlLabel
											control={<Checkbox checked={field?.value} />}
											label={t('electronic_contract.options.37')}
											value="37"
											classes={{ root: styles.label__root }}
										/>
									</FormGroup>
								)
							}}
						</Field>
						<Field name="employerResp">
							{({ field, name, options, children, ...props }) => {
								const fieldName = name || field?.name
								if (!values.employerRespIs) {
									return null
								}
								return (
									<FormGroup {...field} {...props} name={fieldName}>
										<FieldComponent
											placeholder={t('electronic_contract.employer_works_placeholder')}
											rows={8}
											fieldType="textarea"
											{...field}
										/>
									</FormGroup>
								)
							}}
						</Field>
					</div>
				</>
			) : null}

			<Box display="flex" alignItems="center" justifyContent="flex-end">
				{isAdditional ? (
					<div className={styles.button__wrapper}>
						<Button onClick={onClose}>{t('cancel')}</Button>
					</div>
				) : (
					<div className={styles.button__wrapper}>
						<Button onClick={handleSaveTemplate}>{t('save_as_template')}</Button>
					</div>
				)}
				{isAdditional ? (
					<Button onClick={_onOpenProject.bind(null, values, errors)} type="submit">
						{t('electronic_contract.open_add_contract_project')}
					</Button>
				) : (
					<Button onClick={_onOpenProject.bind(null, values, errors)} type="submit">
						{t('electronic_contract.open_contract_project')}
					</Button>
				)}
			</Box>
		</Form>
	)
}
