import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

export const contractTerminationSchema = yup.object().shape({
	terminationDate: transformStringDateFormatToDate().required({ key: 'required' }),
	terminationReason: yup.string().required(),
})

export const contractTerminationApplicationSchema = yup.object().shape({
	terminationDate: transformStringDateFormatToDate().required({ key: 'required' }),
	statement: yup.string().required(),
})
