import { ConfirmModal, Title } from '@/ui'
import styles from './SocialLeaveStyles.module.scss'
import { contractsSelectors, contractsThunks } from '@/entity'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const RemoveSocialLeaveModal = ({ onClose, socialLeave, isLoading }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isLocal = useMemo(() => socialLeave.status === 'NOT_SIGNED', [socialLeave])
	const contract = useSelector(contractsSelectors.contract)

	const onDelete = useCallback(
		() =>
			isLocal
				? dispatch(contractsThunks.deleteLocalSocialLeave(socialLeave.externalId))
				: dispatch(contractsThunks.deleteSocialLeave(socialLeave)),
		[isLocal, socialLeave]
	)

	return (
		<ConfirmModal
			onProcess={onDelete}
			onCancel={onClose}
			cancelButtonTitle={'cancel'}
			processButtonTitle={isLocal ? 'delete_action' : 'yes_sign_and_delete'}
			hideBackButton
			priority={2}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<Title className={styles.content__title}>{t('social_leave.delete_title')}</Title>
				<p className={styles.content__body}>
					{t('social_leave.delete_confirm', {
						leaveNumber: socialLeave?.id || '',
						contractNumber: contract?.contractNumber || '',
						entryDate: socialLeave?.entryDate || '',
					})}
				</p>
			</div>
		</ConfirmModal>
	)
}
export default RemoveSocialLeaveModal
