export const WorkerWelfare = {
	bin: '',
	actualDate: null,
	allEmployeeQuantity: null,
	manEmployeeQuantity: null,
	womanEmployeeQuantity: null,
	pensionerQantity: null,
	largeFamiliesEmployeeQuantity: null,
	disabledEmployeeQuantity: null,
	guardiansEmployeeQantity: null,
	dregEmployeeQuantity: null,
	aspEmployeeQuantity: null,
	harmfullEmployeeQuantity: null,
	belowSubsistenceLevel: null,
	belowMinSalary: null,
	dfmCategoryA: null,
	dfmCategoryB: null,
	dfmCategoryC: null,
	dfmCategoryD: null,
	dfmCategoryE: null,
}
