import * as Yup from 'yup'

export const ValidationSchema = () =>
	Yup.object().shape({
		nameKz: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '2',
				then: Yup.string().required({ key: 'required' }),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),

		nameRu: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '2',
				then: Yup.string().required({ key: 'required' }),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),

		position: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '3',
				then: Yup.string().required({ key: 'required' }),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),

		staffUnitsCount: Yup.number()
			.when('multiCreate', {
				is: (multiCreate) => multiCreate === '1' || multiCreate === undefined,
				then: Yup.number()
					.required({ key: 'required' })
					.moreThan(0, { key: 'staff.greater' })
					.max(2.75, { key: 'staff.less_2_75' }),
				otherwise: Yup.number()
					.required({ key: 'required' })
					.integer({ key: 'staff.enter_integer' })
					.moreThan(0, { key: 'staff.greater' })
					.max(1000, { key: 'staff.less_1000' }),
			})
			.nullable(),
		grossSalary: Yup.number().required({ key: 'required' }).nullable(),
	})
