import { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { contractsSelectors, contractsThunks, contractsActions } from '@/entity/contracts'
import { SecurityModal } from './SecurityModal'
import { message } from 'antd'

export const Update = ({ onToggleAction }) => {
	const dispatch = useDispatch()
	const contract = useSelector(contractsSelectors.contract)
	const { flData, xmlForUpdateIin, updateIinResponse, error } = useSelector((state) => state.contracts.updateIin)
	const { successPayload, onSign, failedPayload } = useDigitalSign()

	const { t } = useTranslation()

	const [iin, setIin] = useState(null)

	useEffect(() => {
		if (xmlForUpdateIin) {
			onSign(xmlForUpdateIin)
		}
	}, [xmlForUpdateIin, onSign])

	useEffect(() => {
		if (successPayload) {
			dispatch(
				contractsThunks.sendUpdateIinXml({
					signedXml: successPayload,
					entityId: contract.id,
					externalId: contract.externalId,
				})
			)
		}
	}, [successPayload])

	useEffect(() => {
		if (updateIinResponse) {
			dispatch(
				contractsActions.updateContractAfterUpdateIin({
					iin,
					fullName: flData.fullNamePerson,
				})
			)
			message.success(t('success_alert'))
			close()
		}
	}, [updateIinResponse])

	useEffect(() => {
		if (failedPayload) {
			message.error(t('error'))
		}
	}, [failedPayload])

	useEffect(() => {
		if (error) {
			message.error(error.message)
		}
	}, [error])

	const onSubmit = (data) => {
		setIin(data?.iin || '')
		dispatch(contractsThunks.getFLData({ iin: data?.iin || '' }))
	}

	const onSignAndSend = () => {
		dispatch(contractsThunks.getXmlForUpdateIin({ iin, bin: contract.bin, externalId: contract.externalId }))
	}

	const close = () => {
		onToggleAction('update', false)
		dispatch(contractsActions.resetUpdateIinXml())
	}

	return (
		<SecurityModal
			onSubmit={onSubmit}
			onSignAndSend={onSignAndSend}
			onClose={close}
			contract={contract}
			flData={flData}
		/>
	)
}
