import * as types from './types'

export const initialMembers = {
	members: [{
		dateIn: '',
		fio: '',
		iin: '',
	}],
	empty: false,
	first: false,
	last: false,
	number: 0,
	numberOfElements: 0,
	pageable: null,
	sort: null,
	size: 0,
	totalElements: 0,
	totalPages: 0,
}

export const initialPersons = {
	persons: [],
	empty: false,
	first: false,
	last: false,
	number: 0,
	numberOfElements: 0,
	pageable: null,
	sort: null,
	size: 0,
	totalElements: 0,
	totalPages: 0,
}


export const initialProfUnions = {
	bin: '',
	unionName: '',
	dateCreate: '',
	fileCharter: {},
	fileProtocol: {},
	fileReference: {},
	typeCode: '',
	industryCode: '',
	parents: [
		{
			parentIndustry: '',
			parentBin: '',
			parentName: '',
			parentType: '',
		}
	],
	unionNote: '',
	chiefIin: '',
	chiefFio: '',
	managers: [],
	kato: '',
	externalId: null,
	searchChiefFio: null,
	searchName: null,
	searchParentName: null,
	searchEmployerName: null,
	employers: [
		{
			employerBin: '',
			employerName: '',
		}
	]
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	isLoadingBin: false,
	isSuccessBin: false,
	isLoadingBinParent: false,
	isSuccessBinParent: false,
	isLoadingChiefFio: false,
	isSuccessChiefFio: false,
	isLoadingBinEmployer: false,
	isSuccessBinEmployer: false,
	profUnions: initialProfUnions,
	xml: null,
	profUnionsList: [],
	empty: false,
	first: false,
	last: false,
	number: 0,
	numberOfElements: 0,
	pageable: null,
	sort: null,
	size: 0,
	totalElements: 0,
	totalPages: 0,
	members: initialMembers,
	persons: initialPersons,
	myUnions: [],
	isPersonsLoading: false
}

export const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.TOGGLE_LOADING_BIN:
			return { ...state, isLoadingBin: payload }
		case types.TOGGLE_SUCCESS_BIN:
			return { ...state, isSuccessBin: payload }
		case types.TOGGLE_LOADING_CHIEF_FIO:
			return { ...state, isLoadingChiefFio: payload }
		case types.TOGGLE_SUCCESS_CHIEF_FIO:
			return { ...state, isSuccessChiefFio: payload }
		case types.SET_PROF_UNIONS:
			return { ...state, profUnions: payload }
		case types.SET_SEARCH_CHIEF_FIO:
			return {
				...state,
				profUnions: {
					...state.profUnions,
					searchChiefFio: payload,
				},
			}
		case types.SET_SEARCH_NAME:
			return {
				...state,
				profUnions: {
					...state.profUnions,
					searchName: payload,
				},
			}
		case types.SET_SEARCH_PARENT_NAME:
			return {
				...state,
				profUnions: {
					...state.profUnions,
					searchParentName: payload,
				},
			}
		case types.SET_PROF_UNIONS_XML:
			return { ...state, xml: payload }
		case types.RESET_XML:
			return {
				...state,
				xml: null,
			}
		case types.SET_PERSONS:
			return {
				...state,
				persons: {
					persons: payload.content,
					pageable: payload.pageable,
					totalPages: payload.totalPages,
					totalElements: payload.totalElements,
					last: payload.last,
					number: payload.number,
					sort: payload.sort,
					size: payload.size,
					first: payload.first,
					numberOfElements: payload.numberOfElements,
					empty: payload.empty,
				},
			}
		case types.SET_PERSONS_LOADING:
			return {
				...state,
				isPersonsLoading: payload.content
			}
		case types.SET_PROF_UNIONS_LIST:
			return {
				...state,
				profUnionsList: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_PROF_MEMBERS:
			return {
				...state,
				members: {
					members: payload.content,
					pageable: payload.pageable,
					totalPages: payload.totalPages,
					totalElements: payload.totalElements,
					last: payload.last,
					number: payload.number,
					sort: payload.sort,
					size: payload.size,
					first: payload.first,
					numberOfElements: payload.numberOfElements,
					empty: payload.empty,
				},
			}
		case types.SET_NEW_MEMBERS:
			return {
				...state,
				members: {
					...state.members,
					members: [...state.members.members, ...payload],
				},
			}
		case types.SET_MEMBER_STATUS:
			return {
				...state,
				members: {
					...state.members,
					members: state.members.members.map((member) =>
						member.iin === payload.iin ? { ...member, status: payload.status } : member
					),
				},
			}
		case types.SET_SEARCH_EMPLOYER_NAME:
			return {
				...state,
				profUnions: {
					...state.profUnions,
					searchEmployerName: payload,
				},
			}
		case types.RESET:
			return {
				...state,
				profUnions: {
					...state.profUnions,
					searchChiefFio: null,
					searchName: null,
					searchParentName: null,
					searchEmployerName: null,
				},
			}
		case types.SET_MY_UNIONS:
			return { ...state, myUnions: payload }
		default:
			return state
	}
}
