import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from './PackageLoadModals.module.scss'

export const SignSome = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			cancelButtonTitle={'no_cancel'}
			processButtonTitle={'yes_sign'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<p className={styles.title}>{t('selected_documents_will_signed')}</p>
				<p className={styles.text}>{t('sure_sign_selected_documents')}</p>
			</div>
		</ConfirmModal>
	)
}
