import { Header } from '@features/laborRecords/frame'
import { SubHeader } from '@features/laborRecords/components/SubHeader/SubHeader'
import styles from './ViewAdjustment.module.scss'
import { Button } from '@/ui'
import { useCallback } from 'react'
import { contractsThunks, laborRecordsSelectors } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DetailsOfPartiesInformation } from '@features/laborRecords/components/DetailsOfPartiesInformation/DetailsOfPartiesInIformation'
import {
	RequestEditForm,
	RequestRemoveForm,
	RequestTerminateForm,
} from '@features/laborRecords/components/requestAdjustment'

export const ViewAdjustment = ({ onToggleContractAction, onToggleViewContractAction, onClose }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const requestDetail = useSelector(laborRecordsSelectors.requestDetail)

	const getContractById = useCallback(
		(id, isLocalContract) => dispatch(contractsThunks.getContract(id, isLocalContract)),
		[dispatch]
	)
	const fastView = (id) => {
		getContractById(id, false)
		onToggleViewContractAction('view', true)
	}

	return (
		<>
			<Header
				requestType={requestDetail.requestType}
				contractNumber={requestDetail.contractNumber}
				contractDate={requestDetail.contractDate}
			></Header>
			<SubHeader
				id={requestDetail.requestsAdjustmentId}
				requestDate={requestDetail.requestsAdjustmentDate}
				statusRequest={requestDetail.requestStatus}
			></SubHeader>
			<Button buttonClassName={styles.wrapper_button} onClick={() => fastView(requestDetail.contractId)}>
				{t('labor_records.view_contract')}
			</Button>
			<DetailsOfPartiesInformation shortContractData={requestDetail} hintInfo={true}></DetailsOfPartiesInformation>
			{requestDetail.requestType?.code === '2' ? (
				<RequestTerminateForm
					shortContractData={requestDetail}
					onClose={onClose}
					onToggleContractAction={onToggleContractAction}
					isView={true}
				></RequestTerminateForm>
			) : null}
			{requestDetail.requestType?.code === '3' ? (
				<RequestRemoveForm
					shortContractData={requestDetail}
					onClose={onClose}
					onToggleContractAction={onToggleContractAction}
					isView={true}
				></RequestRemoveForm>
			) : null}
			{requestDetail.requestType?.code === '1' ? (
				<RequestEditForm
					shortContractData={requestDetail}
					onClose={onClose}
					onToggleContractAction={onToggleContractAction}
					isView={true}
				></RequestEditForm>
			) : null}
		</>
	)
}
