import React from 'react'
import { Title } from '@/ui'
import { DocumentState, SignStatus } from '@features/contracts/badges'
import styles from './TitleContractInfo.module.scss'

export const TitleContractInfo = ({ contract, documentState, t }) => {
	return (
		<div className={styles.mainInformation}>
			<div>
				<Title color="black">
					{t('collective_contract', {
						contractNumber: contract?.collectiveContractNumber || ' ',
						dateFrom: contract?.dateFrom || ' ',
					})}
				</Title>
				<p className={styles.identifier}>ID: {contract.externalId}</p>
				<p className={styles.registrationDate}>
					{t('registration_date')}: {contract.createDate}
				</p>
			</div>
			<div className={styles.detailInformation}>
				<DocumentState item={documentState} />
				<SignStatus item={contract.state} />
			</div>
		</div>
	)
}
