import s from '../../components/entrance/smallButton.module.css'

const SmallButton = (props) => {
	const { children, width, buttondisable } = props
	return (
		<button className={buttondisable ? s.btnDis : s.btn} disabled={buttondisable} style={width} {...props}>
			{children}
		</button>
	)
}

export default SmallButton
