import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UnionsForm } from './UnionsForm'
import { ProfUnionsValidationSchema } from '@/entity/profUnions'
import { Formik } from 'formik'
import { Title } from '@/ui'
import styles from './ProfUnionsForm.module.scss'

export const ProfUnionsForm = ({ initialValues, onSubmit, options, onCancel, actions, cabinetTradeUnion }) => {
	const { t } = useTranslation()
	const [fileCharterMiddleWare, setFileCharterMiddleWare] = useState()
	const [fileProtocolMiddleWare, setFileProtocolMiddleWare] = useState()
	const [fileReferenceMiddleWare, setFileReferenceMiddleWare] = useState()
	const { fileCharter, fileProtocol, fileReference } = initialValues

	const onSubmitMiddleWare = (data) => {
		let fileCharter = fileCharterMiddleWare[0] ? { id: fileCharterMiddleWare[0].id } : null
		let fileProtocol = fileProtocolMiddleWare[0] ? { id: fileProtocolMiddleWare[0].id } : null
		let fileReference
		if (fileReferenceMiddleWare !== undefined && fileReferenceMiddleWare[0]) {
			fileReference = { id: fileReferenceMiddleWare[0].id }
		} else {
			fileReference = null
		}
		let preparedData = {
			...data,
			fileCharter,
			fileProtocol,
			fileReference,
		}
		delete preparedData.searchChiefFio
		delete preparedData.searchName
		delete preparedData.searchParentName
		delete preparedData.searchEmployerName
		// Вызов onSubmit с подготовленными данными
		onSubmit(preparedData)
	}
	const attachFileCharter = (values) => {
		setFileCharterMiddleWare(values)
	}
	const attachFileProtocol = (values) => {
		setFileProtocolMiddleWare(values)
	}
	const attachFileReference = (values) => {
		setFileReferenceMiddleWare(values)
	}

	return (
		<div>
			{' '}
			<div className={styles.title}>
				<Title color={'black'} >{actions.edit ? t('profUnions.editTitle') : t('profUnions.createTitle')}</Title>
			</div>
			<Formik validationSchema={ProfUnionsValidationSchema} initialValues={initialValues} onSubmit={onSubmitMiddleWare}>
				<UnionsForm
					options={options}
					onCancel={onCancel}
					attachFileCharter={attachFileCharter}
					attachFileProtocol={attachFileProtocol}
					attachFileReference={attachFileReference}
					fileCharter={fileCharter}
					fileProtocol={fileProtocol}
					fileReference={fileReference}
					actions={actions}
					cabinetTradeUnion={cabinetTradeUnion}
				/>
			</Formik>
		</div>
	)
}
