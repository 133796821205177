import styles from './Activities.module.scss'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { contractsThunks, laborRecordsActions, laborRecordsSelectors, laborRecordsThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { View } from '@features/contracts/actions'
import { Button } from '@/components/labor/Button/Button'
import { ColorRectangle } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import { ChevronLaborUp, ChevronLaborDown } from '@app/images'
import { Details } from '@features/laborRecords/frame/Activities/Details'
import { Edit } from '@features/laborRecords/actions/edit/Edit'
import { DigitizationModal } from '../../actions/digitalization'
import { Question } from '@/components/question/Question'
import { useDigitalSign } from '@/shared'
import SocialLeaveMain from '@features/contracts/socialLeaves/SocialLeaveMain'
import { AdditionalAgreementMain } from '@features/contracts/additionalContracts'
import { handbookThunks } from '@/entity/handbook'
export const Activities = ({ contract }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [orgName, setOrgName] = useState('')
	const [establishedPost, setEstablishedPost] = useState('')
	const [chevron, setChevron] = useState(true)
	const [details, setDetails] = useState([])
	const isLoading = useSelector(laborRecordsSelectors.isAdjustmentXmlLoading)
	const xml = useSelector(laborRecordsSelectors.adjustmentXml)
	const [sign, setSign] = useState(false)
	const { onSign, successPayload, onClearPayload } = useDigitalSign()
	const isSuccess = useSelector(laborRecordsSelectors.isAdjustmentXmlSuccess)
	const isRefreshContracts = useSelector(laborRecordsSelectors.isRefreshContracts)
	const [socialLeave, setSocialLeave] = useState(null)
	const [actions, setActions] = useState({
		view: false,
		edit: false,
		digitalization: false,
		digitalizationRemove: false,
	})
	const [requestType, setRequestType] = useState({
		edit: false,
		terminate: false,
		remove: false,
	})
	const handleViewAdditionalContractModalClose = useCallback(() => {
		dispatch(handbookThunks.getContractTerms())
		setActions((value) => ({ ...value, viewAdditionalAgreement: false }))
	}, [])
	const handleViewSocialLeaveModalClose = useCallback(() => {
		setActions((value) => ({ ...value, viewSocialLeave: false }))
	}, [])
	useMemo(() => (lang === 'rus' ? setOrgName(contract.orgNameRu) : setOrgName(contract.orgNameKz)), [contract, lang])
	useMemo(() => (contract.establishedPost ? setEstablishedPost(contract.establishedPost) : ''), [contract])
	useMemo(() => setDetails(contract.contractRecordList ?? contract.laborDigitalizationRecordList), [contract])
	const getShortContractData = useCallback((id) => dispatch(laborRecordsThunks.getShortContractData(id)), [dispatch])
	const getContractById = useCallback(
		(id, isLocalContract) => dispatch(contractsThunks.getContract(id, isLocalContract)),
		[dispatch]
	)
	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}
	const onToggleRequestType = (type, value) => {
		setRequestType((prevState) => {
			return { ...prevState, [type]: value }
		})
	}
	const handleClose = useCallback(() => {
		setRequestType((value) => ({ ...value, edit: false, terminate: false, remove: false }))
		setActions((value) => ({ ...value, edit: false, view: false }))
	}, [])
	const fastView = (record) => {
		getContractById(record.id, false)
		onToggleAction('view', true)
	}
	const editView = (record, requestType) => {
		getShortContractData(record.id)
		onToggleRequestType(requestType, true)
		onToggleAction('edit', true)
	}
	const changeChevron = () => {
		chevron ? setChevron(false) : setChevron(true)
	}

	const questionText = {
		title: t('digitization.confirm.remove_contract.title'),
		data: t('digitization.confirm.remove_contract.data'),
	}

	const removeLocalDigitContract = (id) => {
		handleDataSubmission([id])
		onToggleAction('digitalizationRemove', true)
	}

	const handleDataSubmission = useCallback(
		(data) => !isLoading && dispatch(laborRecordsThunks.deleteContractDigitXml(data)),
		[]
	)
	const handleProcess = () => {
		setSign(true)
	}
	const closeProcess = () => {
		setSign(false)
		dispatch(laborRecordsActions.resetAdjustmentXml())
	}
	const fullClear = () => {
		dispatch(laborRecordsActions.resetAdjustmentXml())
		onClearPayload()
		onToggleAction('signChosen', false)
	}

	useEffect(() => {
		sign && xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [sign, xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])

	useEffect(() => {
		if (successPayload) {
			dispatch(
				laborRecordsThunks.sendContractDigit({ signedXml: successPayload, isRefreshContracts: isRefreshContracts })
			).then(closeProcess())
		}
	}, [successPayload])
	return (
		<div className={styles.wrapper}>
			<div key={contract.id} className={styles.wrapper__top}>
				<div className={styles.wrapper__date}>
					{contract.dateFrom + ' - '}
					{contract.dateTo ? contract.dateTo : t('labor_records.until_now')}
				</div>
				{contract.digitalization ? (
					<>
						<Button green onClick={() => onToggleAction('digitalization', true)}>
							{t('labor_records.edit')}
						</Button>
						<Button onClick={() => removeLocalDigitContract(contract.id)}>{t('labor_records.remove')}</Button>
					</>
				) : (
					<>
						<Button green onClick={() => fastView(contract)}>
							{t('labor_records.open')}
						</Button>
						{contract.dateTo ? (
							<Button green onClick={() => editView(contract, 'edit')}>
								{t('labor_records.edit')}
							</Button>
						) : (
							<Button onClick={() => editView(contract, 'terminate')}>{t('labor_records.terminate')}</Button>
						)}
						<Button onClick={() => editView(contract, 'remove')}>{t('labor_records.remove')}</Button>
					</>
				)}
			</div>
			<div className={styles.wrapper__mLeft}>
				<div className={styles.hint__info}>
					{contract.digitalization ? (
						<>
							<ColorRectangle green>{t('labor_records.el_labor')}</ColorRectangle>
							{t('labor_records.laborDigitalization')}
						</>
					) : (
						<>
							<ColorRectangle blue>{t('labor_records.labor')}</ColorRectangle>
							{t('labor_records.contract_data')}
						</>
					)}
				</div>
				<label>
					{orgName}
					{t('labor_records.bin_iin') + contract.bin + ')'}
				</label>
				<div className={styles.wrapper__mb10}>
					{establishedPost === '' ? 'Что то пошло не так...' : establishedPost}
				</div>
				<Button green img={chevron ? ChevronLaborDown : ChevronLaborUp} onClick={changeChevron}>
					{chevron ? t('labor_records.detail') : t('labor_records.short')}
				</Button>
				{!chevron ? <Details details={details}></Details> : null}
			</div>
			{actions.view ? (
				<View setForceSubmitEdit={false} onToggleAction={onToggleAction} setSocialLeave={setSocialLeave} />
			) : null}
			{actions.edit ? <Edit requestType={requestType} onClose={handleClose} /> : null}
			{actions.digitalization && (
				<DigitizationModal
					singed={true}
					items={contract.laborDigitalizationRecordList}
					id={contract.id}
					onClose={() => onToggleAction('digitalization', false)}
				/>
			)}
			{xml && actions.digitalizationRemove && (
				<Question
					data={questionText}
					processButtonTitle={'digitization.sign_and_remove'}
					handleProcess={handleProcess}
					handleCancel={closeProcess}
				></Question>
			)}
			{actions.viewSocialLeave && (
				<SocialLeaveMain
					onToggleAction={onToggleAction}
					socialLeave={socialLeave}
					onClose={handleViewSocialLeaveModalClose}
					mode={'view'}
				/>
			)}
			{actions.viewAdditionalAgreement && (
				<AdditionalAgreementMain
					mode={'view'}
					onToggleAction={onToggleAction}
					onClose={handleViewAdditionalContractModalClose}
				/>
			)}
		</div>
	)
}
