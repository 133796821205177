import { Edit as EditIcon, Remove as RemoveIcon, Sign as SignIcon, View as ViewIcon } from '@app/images'
import { CrossRed } from '@app/icons/crossRed/CrossRed'
import { StateCode } from './config.js'

export default function onContractActionMenuClick(
	row,
	e,
	setActionMenuItems,
	t,
	activeRole,
	parentContract,
	positionsByKnz
) {
	const statusId = parentContract.dcontractStatus?.id
	const isElectron = parentContract.econtractFound
	const stateCode = row.dcontractState?.code
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'viewAdditionalAgreement' }]
	//ДС
	if (!isElectron) {
		if (statusId === 2) {
			setActionMenuItems(actions)
			return
		}
		if (activeRole === 'ROLE_EMPLOYER')
			actions = [
				...actions,
				{ icon: EditIcon, title: t('actions.edit'), action: 'editAdditionalAgreement' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'removeAdditionalAgreement' },
			]
		if (stateCode === 2)
			actions.splice(1, 0, { icon: SignIcon, title: t('actions.sign'), action: 'signAdditionalAgreement' })
		const result = Boolean(positionsByKnz.find(({ code }) => code === parentContract?.dpositionCode))
		if (row.oldBin || parentContract.dcontractStatus.code === '2') {
			actions.filter(
				(item) =>
					item.action !== 'editAdditionalAgreement' &&
					item.action !== 'removeAdditionalAgreement' &&
					item.action !== 'signAdditionalAgreement'
			)
		}
		if (!result) {
			actions.filter((item) => item.action !== 'signAdditionalAgreement')
		}
	} else {
		//ЭДС
		if (statusId === 1) {
			if (activeRole === 'ROLE_EMPLOYER') {
				if (
					[
						StateCode.IN_DEVELOP,
						StateCode.IN_PROGRESS,
						StateCode.REJECT_EMPLOYEE,
						StateCode.REJECT_EMPLOYER,
						StateCode.EXPIRED,
					].includes(stateCode)
				) {
					actions = [...actions, { icon: RemoveIcon, title: t('actions.remove'), action: 'removeAdditionalAgreement' }]
				}
				if (stateCode === StateCode.ON_SIGN_EMPLOYER) {
					actions = [
						...actions,
						{ icon: <CrossRed />, title: t('decline'), action: 'declineAdditionalAgreement' },
						{ icon: SignIcon, title: t('actions.sign'), action: 'signAdditionalAgreement' },
					]
				}
			}
			if (activeRole === 'ROLE_EMPLOYEE' && stateCode === StateCode.ON_SIGN_EMPLOYEE) {
				actions = [
					...actions,
					{ icon: <CrossRed />, title: t('decline'), action: 'declineAdditionalAgreement' },
					{ icon: SignIcon, title: t('actions.sign'), action: 'signAdditionalAgreement' },
				]
				setActionMenuItems(actions)
				return
			}
		}
	}
	setActionMenuItems(actions)
}
