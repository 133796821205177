import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from './contraxtsSearch.module.scss'

const CollectiveContractSearch = ({ filters, setFilters, fetchData }) => {
	const { t } = useTranslation()

	const updateFilter = (e, name) => {
		setFilters({ ...filters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })
	}

	return (
		<div className={classes.searchRow}>
			<div className={classes.searchField}>
				<div className={classes.searchField__left}>
					<IconButton type="submit" aria-label="search">
						<SearchIcon />
					</IconButton>
					<InputBase
						fullWidth
						placeholder={t('col_contract.searchInput')}
						inputProps={{
							'aria-label': 'search',
						}}
						value={filters.srcText}
						onChange={(e) => updateFilter(e, 'srcText')}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault()
								fetchData()
							}
						}}
					/>
				</div>
				<span className={classes.searchField__button} onClick={() => fetchData()}>
					{t('find')}
				</span>
			</div>
		</div>
	)
}

export default CollectiveContractSearch
