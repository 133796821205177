import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	opvList: {
		opvRecordList: [],
		recordsCount: 0,
	},
	selectedOpv: null,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.OPV_LIST:
			return { ...state, opvList: payload }
		case types.SELECTED_OPV:
			return { ...state, selectedOpv: payload }
		default:
			return state
	}
}
