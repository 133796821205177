import styles from './style.module.scss'
import { Title, TitleAndData } from '@/ui/atoms'
import { useTranslation } from 'react-i18next'

export const TitleWithInformationData = ({ title, information, maxDataWidth, maxTitleWidth, subTitle, hideTitle, hideSubTitle }) => {
	const { t } = useTranslation()

	return (
		<div>
			{!hideTitle && <Title>{t(title)}</Title>}
			{!hideSubTitle && <p className={styles.subTitle}>{subTitle}:</p>}
			<div className={`${styles.information} ${hideSubTitle && hideTitle ? styles.hideMargin : ''}`}>
				{information.map(({ title, data }) => {
					return <TitleAndData key={title} title={title} data={data} maxTitleWidth={maxTitleWidth} maxDataWidth={maxDataWidth} />
				})}
			</div>
		</div>
	)
}
