import * as Yup from 'yup'

const objectStructure = {
	dept: Yup.object().shape({
		nameKz: Yup.string().required({ key: 'required' }),
		nameRu: Yup.string().required({ key: 'required' }),
	}),
	deptTypeCode: Yup.string().when('subsidiary', {
		is: false,
		then: Yup.string().required({ key: 'required' }).nullable(),
	}),
}

export const ValidationStructSchema = Yup.object().shape({
	units: Yup.array().of(Yup.object().shape(objectStructure)),
})
