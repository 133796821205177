export const colors = {
	DRAFT: '#AAB8D1',
	APPROVED: '#17B67C',
}

export const status = {
	DRAFT: 'profUnions.not_approved',
	APPROVED: 'profUnions.approved',
}

export const statusMyUnion = {
	DRAFT: 'profUnions.not_approved_members',
	APPROVED: 'profUnions.approved_members',
	not_member: "profUnions.not_members"
}

export const colorsMyUnion = {
	false: '#AAB8D1',
	true: '#17B67C',
}

export const colorsMembers = {
	DRAFT: '#AAB8D1',
	APPROVED: '#17B67C',
}

export const statusMembers = {
	DRAFT: 'profUnions.not_approved_members',
	APPROVED: 'profUnions.approved_members',
}
