import React from 'react'
import styles from './style.module.scss'

export const Preloader = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.ring}>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	)
}
