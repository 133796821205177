import { actions } from './actions'
import { api } from '@/shared'

export const getOppvLast = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.oppv.getOppvLast()
			dispatch(actions.oppv(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const sendOppv = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.oppv.sendOppv(params)
			dispatch(actions.oppv(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getBankByAccount = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.error(null))
			dispatch(actions.toggleLoading(true))
			const response = await api.oppv.getBankByAccount(params)
			dispatch(actions.oppv(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
			dispatch(actions.error(error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
