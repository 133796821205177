import React, { useState } from 'react'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { formFieldNames } from '@/entity'
import { IINValidationScheme } from '@/shared'
import styles from './CheckingSpecialSocialPaymentForm.module.scss'
import { Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { sendByBin } from '@/shared/api/rest/oppv'
import { message } from 'antd'
import { Loader } from '@/components/Loader'
import { getPaymentState } from '@/utils'

const CheckingSpecialSocialPaymentForm = () => {
	const { t } = useTranslation()
	const initialValues = {
		[formFieldNames.iin]: '',
	}
	const [statusPayment, setStatusPayment] = useState('')
	const [loading, setLoading] = useState(false)

	return (
		<Formik initialValues={initialValues} validationSchema={IINValidationScheme}>
			{({ setFieldValue, values, ...form }) => {
				const getStatusPayment = async () => {
					try {
						setLoading(true)
						const response = await getPaymentState(values.iin)
						setStatusPayment(response)
						setLoading(false)
					} catch (error) {
						setLoading(false)
						const err = error?.response?.data?.message || 'Попробуйте позже, технические проблемы на стороне сервиса'
						message.error(err ? err : 'Попробуйте позже, технические проблемы на стороне сервиса', 5)
					}
				}
				return (
					<FormikForm>
						<FormikField name={formFieldNames.iin}>
							{({ field, meta: { touched, error } }) => (
								<div className={styles.fieldAndButton}>
									<Field
										hideNumberArrows
										label={t('iin')}
										placeholder={t('enter_count_digitals', { count: 12 })}
										error={touched && error}
										{...field}
										maxLength={12}
										pattern="[0-9]{12}"
										onInput={(e) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, '')
										}}
									/>
									<div>
										<Button
											type="button"
											onClick={getStatusPayment}
											disabled={!!(touched && error) || values.iin === ''}
										>
											{t('contracts_tab.check')}
										</Button>
									</div>
								</div>
							)}
						</FormikField>
						<div className={styles.labelStatus}>
							{t('personal_affairs.status')}
							<label>{t(statusPayment)}</label>
							{loading ? (
								<div className={styles.loader}>
									<Loader />
								</div>
							) : null}
						</div>
					</FormikForm>
				)
			}}
		</Formik>
	)
}

export default CheckingSpecialSocialPaymentForm
