import React, { useEffect } from 'react'
import { Field as FormikField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Field, Title } from '@/ui'
import styles from './Conditions.module.scss'
import { EndDateField } from '@features/contracts/registrationEmployerContract/form/fields'

export const ContractTerm = ({ contractTerms = [], isFormDirty }) => {
	const { t } = useTranslation()

	const { values, setFieldValue } = useFormikContext()
	useEffect(() => {
		if (values.dcontractDateCode === '1' || values.dcontractDateCode === '8') {
			setFieldValue('contractEndDate', null)
		}
	}, [values])

	const dateArr = values?.dateFrom?.split('.')

	return (
		<div className={styles.conditionSection}>
			<Title className={styles.title}>{t('change_term')}</Title>
			<div className={styles.row}>
				<div className="flex-2">
					<FormikField name="dcontractDateCode">
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('contract_validity')}
								placeholder={t('choose_from_directory')}
								options={contractTerms}
								error={(touched || isFormDirty) && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className="flex-1">
					{/*TODO исправить значение по умолчанию в компоненте DatePicker*/}
					<EndDateField
						minDate={dateArr?.length > 2 ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : null}
						isFormDirty={isFormDirty}
						fieldName="contractEndDate"
					/>
				</div>
			</div>
		</div>
	)
}
