export const getSocialLeaveDataByType = (socialLeave, t, lang) =>
	socialLeave?.dleaveTypeCode?.value === '2'
		? [
				{ title: t('contracts_tab.type'), data: socialLeave?.dleaveTypeCode?.name },
				{ title: t('social_leave.begin_date_label'), data: socialLeave?.beginDate },
				{ title: t('social_leave.end_date_label'), data: socialLeave?.endDate },
				{
					title: t('contracts_tab.status'),
					data: socialLeave?.status === 'NOT_SIGNED' ? t('pending_sign') : t('signed_status'),
				},
				{ title: t('social_leave.entryDate'), data: socialLeave?.entryDate },
		  ]
		: [
				{ title: t('contracts_tab.type'), data: socialLeave?.dleaveTypeCode?.name },
				{ title: t('social_leave.begin_date_label'), data: socialLeave?.beginDate },
				{ title: t('social_leave.end_date_label'), data: socialLeave?.endDate },
				{ title: t('social_leave.first_date_label'), data: socialLeave?.firstDayDate },
				{ title: t('social_leave.days_off_label'), data: socialLeave?.daysOff },
				{ title: t('social_leave.sheet_num_label'), data: socialLeave?.timeSheetNum },
				{
					title: t('social_leave.department'),
					data: lang === 'rus' ? socialLeave?.departmentRu : socialLeave?.departmentKz,
				},
				{
					title: t('contracts_tab.status'),
					data: socialLeave?.status === 'NOT_SIGNED' ? t('pending_sign') : t('signed_status'),
				},
				{ title: t('social_leave.entryDate'), data: socialLeave?.entryDate },
		  ]
