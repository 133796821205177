export const isLoading = (state) => state.pension.isLoading
export const isSuccess = (state) => state.pension.isSuccess
export const persons = (state) => state.pension.persons
export const pensions = (state) => state.pension.pensions
export const pension = (state) => state.pension.pension
export const pensionStatuses = (state) => state.pension.pensionStatuses
export const pensionStates = (state) => state.pension.pensionStates
export const pensionPrefExp = (state) => state.pension.pensionPrefExp
export const pensionExp = (state) => state.pension.pensionExp
export const pensionDocType = (state) => state.pension.pensionDocType
export const consentAndPhoneData = (state) => state.pension.consentAndPhoneData
export const pensionXml = (state) => state.pension.pensionXml
export const isSuccessSave = (state) => state.pension.isSuccessSave
export const pensionsAll = (state) => state.pension
