import { Sign as SignIcon, View as ViewIcon } from '@app/images'

export default function onActionMenuClick(row, e, setActionMenuItems, t, isEmployee) {
	const status = row.status
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (status === 'DRAFT' && !isEmployee) {
		actions = [...actions, { icon: SignIcon, title: t('actions.approve'), action: 'approve' }]
	}
	setActionMenuItems(actions)
}
