import {formatDate} from "@/utils";

export const getData = (data, t) => {
    const {
        nameMO,
        placeOfWorkStudy,
        position,
        dateOfLastCheckup,
        detectLastDiseases,
        fioofDoct,
        concOfTherap,
        fioofTherap,
        dateOfTherap,
        nameCodeTherap,
        concOfNarcologist,
        dateOfNarcologist,
        nameCodeNarcologist,
        testForPsychSubst,
        dateOfPsychoactiveSubstances,
        resulOfNarcologist,
        fioofNarcologist,
        concOfPsychiatrist,
        dateOfPsychiatrist,
        resulOfPsychiatrist,
        fluoroExamDate,
        dateOfFluoro,
        resulOfFluoro,
        concOfProfCondition,
        labData,
        fiofilledPerson,
        fioheadMedOrg
    } = data || {}

    return [
        {title: t('personal_affairs.med_organization_address'), data: nameMO},
        {title: t('personal_affairs.work_education_address'), data: placeOfWorkStudy},
        {title: t('contracts_tab.position'), data: position},
        {title: t('personal_affairs.last_examination'), data: formatDate(dateOfLastCheckup)},
        {title: t('personal_affairs.last_examination_diseases'), data: detectLastDiseases},
        {title: t('personal_affairs.doctor_fio'), data: fioofDoct},
        {title: t('personal_affairs.therap_conclusion'), data: concOfTherap},
        {title: t('personal_affairs.therap_fio'), data: fioofTherap},
        {title: t('personal_affairs.date'), data: formatDate(dateOfTherap)},
        {title: t('personal_affairs.name_code'), data: nameCodeTherap},
        {title: t('personal_affairs.narco_conclusion'), data: concOfNarcologist},
        {title: t('personal_affairs.narco_fio'), data: fioofNarcologist},
        {title: t('personal_affairs.narco_fio'), data: formatDate(dateOfNarcologist)},
        {title: t('personal_affairs.narco_date'), data: nameCodeNarcologist},
        {title: t('personal_affairs.liquid_examination'), data: testForPsychSubst},
        {title: t('personal_affairs.date'), data: formatDate(dateOfPsychoactiveSubstances)},
        {title: t('personal_affairs.card.table.headers.result'), data: resulOfNarcologist},
        {title: t('personal_affairs.psycho_conclusion'), data: concOfPsychiatrist},
        {title: t('personal_affairs.date'), data: formatDate(dateOfPsychiatrist)},
        {title: t('personal_affairs.card.table.headers.result'), data: resulOfPsychiatrist},
        {title: t('personal_affairs.x_ray_data'), data: fluoroExamDate},
        {title: t('personal_affairs.date'), data: formatDate(dateOfFluoro)},
        {title: t('personal_affairs.card.table.headers.result'), data: resulOfFluoro},
        {title: t('personal_affairs.doctor_conclusion'), data: concOfProfCondition},
        {title: t('personal_affairs.lab_data'), data: labData},
        {title: t('personal_affairs.filling_fio'), data: fiofilledPerson},
        {title: t('personal_affairs.head_fio'), data: fioheadMedOrg},
    ].map(item => !!item.data ? item : {...item, data: '-'})
}
