import React from 'react'
import { Loader } from './Loader'
import styles from './Loader.module.scss'

export const LoadingBlocker = ({ children, localStyles = {} }) => {
	return (
		<div style={localStyles} className={styles.blocker}>
			<div className={styles.blockerLoader}>
				<Loader />
			</div>
			{children}
		</div>
	)
}
