import { actions } from './actions'
import { api } from '@/shared'
import { userActions } from '@/entity'
import { message } from 'antd'
import { Buffer } from 'buffer'
import { companyActions } from '@/entity/company'
import { handbookThunks } from '@/entity/handbook'

export const createCompany = (data, xml) => {
	return async (dispatch, getState) => {
		dispatch(actions.toggleLoading(true))
		dispatch(actions.setError(null))
		try {
			const base64 = Buffer.from(xml).toString('base64')
			const state = getState()
			const { id: userId } = state.user?.user || null
			const newData = await api.company.createCompany({ userId }, { ...data, xml: base64 })
			const parsedCompanies = newData.companies.map((company, i) => ({
				id: company.id,
				rus: company.companyName,
				kz: company.companyName,
				code: String(company.id),
			}))
			dispatch(actions.setCompanies(newData.companies.map((company) => ({ ...company, code: String(company.id) }))))
			dispatch(userActions.setEmployerCompanies(parsedCompanies))
			dispatch(actions.toggleSuccess(true))
			message.success('Предприятия было создано успешно')
		} catch (error) {
			dispatch(actions.setError(error?.response || {}))
			message.error(error?.response?.data?.message || 'Предприятие не было создано')
			dispatch(companyActions.resetCreationXml())
		}
		dispatch(actions.toggleSuccess(false))
		dispatch(actions.toggleLoading(false))
	}
}

export const editCompany = (data, xml) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const userId = state?.user?.user?.id
			const base64 = Buffer.from(xml).toString('base64')
			const newData = await api.company.editCompany(
				{ userId },
				{
					...data,
					xml: base64,
				}
			)
			const parsedCompanies = newData.companies.map((company, i) => ({
				id: company.id,
				rus: company.companyName,
				kz: company.companyName,
				code: String(company.id),
			}))
			dispatch(actions.setCompanies(newData.companies.map((company) => ({ ...company, code: String(company.id) }))))
			dispatch(userActions.setEmployerCompanies(parsedCompanies))
			dispatch(handbookThunks.findOne({ dictCode: 'kato', saveBy: 'district', code: data.ddistrictCode }))
			dispatch(handbookThunks.findOne({ dictCode: 'kato', saveBy: 'region', code: data.dregionCode }))
			dispatch(handbookThunks.findOne({ dictCode: 'kato', saveBy: 'populatedArea', code: data.dlocalityCode }))
			dispatch(companyActions.setCompany({ id: data.id }))
			message.success('Предприятие было сохранено успешно')
		} catch (error) {
			message.error(error?.response?.data?.message || 'Предприятие не было сохранено')
		}
	}
}
