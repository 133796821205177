import { useTranslation } from 'react-i18next'
import styles from './Fillter.module.scss'
import { useEffect, useState } from 'react'
import { Close8, Preference } from '@app/images'
import { DatePicker } from '@/components/DatePicker'
import { Select } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { formsSelectors, formsThunks } from '@/entity/forms'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'

const HrButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'capitalize',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	backgroundColor: '#17B67C',
	fontFamily: 'Inter',
	'&:hover': {
		backgroundColor: '#17B67C',
		boxShadow: 'none',
		color: 'white',
	},
})

const HrResetButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	fontFamily: 'Inter',
	height: '32px',
	fontWeight: 700,
	color: '#AAB8D1',
	'&:hover': {
		backgroundColor: 'transparent',
		boxShadow: 'none',
	},
})

export const Filter = ({ paramFilters, setParamFilters, resetFilter, fetchData, count = 0 }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [filter, setFilter] = useState(false)
	const selectThemes = useSelector(formsSelectors.themes)
	const resultTypes = useSelector(formsSelectors.resultTypes)

	useEffect(() => {
		dispatch(formsThunks.getResultTypes())
	}, [dispatch])

	const updateFilter = (e, name) =>
		setParamFilters({ ...paramFilters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })

	return (
		<>
			<div className={styles.filterRow}>
				<div className={styles.filter__button} onClick={() => setFilter(!filter)}>
					{filter ? (
						<>
							<img src={Close8} className={styles.filter__icon} />
							{t('history_check.check_history')}
						</>
					) : (
						<>
							<img src={Preference} className={styles.filter__icon} />
							{t('history_check.filter')}
						</>
					)}
				</div>
				<span className={styles.filter__title}>
					{t('history_check.total_checks')}
					{count}
				</span>
			</div>
			{filter && (
				<div className={styles.filter__row}>
					<div className={styles.filter__field}>
						<div className={styles.filter__label}>{t('history_check.check_date')}</div>
						<DatePicker
							wrapperClasses={styles.filter__input}
							inputClassName={styles.filter__datePickerInput}
							value={paramFilters.date}
							onChange={(e) => updateFilter(e, 'date')}
							iconClasses={styles.filter__datepickerIcon}
						/>
					</div>
					<div className={styles.filter__field}>
						<div className={styles.filter__label}>{t('history_check.check_subject')}</div>
						<Select
							required
							options={selectThemes}
							inputClasses={styles.filter__input}
							iconClasses={styles.filter__selectIcon}
							value={paramFilters.sectionCode || ''}
							onChange={(e) => updateFilter(e, 'sectionCode')}
						/>
					</div>
					<div className={styles.filter__field}>
						<div className={styles.filter__label}>{t('history_check.result')}</div>
						<Select
							required
							options={resultTypes}
							inputClasses={styles.filter__input}
							iconClasses={styles.filter__selectIcon}
							value={paramFilters.resultCode || ''}
							onChange={(e) => updateFilter(e, 'resultCode')}
						/>
					</div>
					<div className={styles.filter__searchButtonsRow}>
						<HrResetButton variant="text" onClick={resetFilter}>
							{t('reset_all')}
						</HrResetButton>
						<HrButton style={{ height: '32px' }} variant="contained" onClick={() => fetchData()}>
							{t('search')}
						</HrButton>
					</div>
				</div>
			)}
		</>
	)
}
