import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableColumn } from '@/components/Table'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { SignStatus } from '../badges'
import styles from './SocialLeavesTable.module.scss'
import { Edit as EditIcon, Remove as RemoveIcon, Sign as SignIcon, View as ViewIcon } from '@app/images'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { Unsign } from '@app/icons'
import Tooltip from '@mui/material/Tooltip'

export const SocialLeavesTable = ({ data, onViewButtonClick, setSocialLeave, parentContract }) => {
	const { t } = useTranslation()
	const [selectedSocialLeave, setSelectedSocialLeave] = useState(null)
	const activeRole = useSelector(userSelectors.activeRole)
	const [menuItems, setMenuItems] = useState([])

	const onSelectSocialLeave = (socialLeave) => {
		setSelectedSocialLeave(socialLeave)
		setSocialLeave(socialLeave)
	}

	useEffect(() => {
		let items = [{ icon: ViewIcon, title: t('actions.view'), action: 'viewSocialLeave' }]
		if (activeRole === 'ROLE_EMPLOYER')
			items = [
				...items,
				{ icon: EditIcon, title: t('actions.edit'), action: 'editSocialLeave' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'removeSocialLeave' },
			]
		if (selectedSocialLeave?.status === 'NOT_SIGNED')
			items.splice(1, 0, { icon: SignIcon, title: t('actions.sign'), action: 'signSocialLeave' })

		if (parentContract.dcontractStatus.code == '2') {
			items = items.filter(
				(item) =>
					!(item.action == 'editSocialLeave' || item.action == 'removeSocialLeave' || item.action == 'signSocialLeave')
			)
		}
		setMenuItems(items)
	}, [activeRole, selectedSocialLeave?.status, t])

	return (
		<Table tableName="social-leaves" data={data} emptyPlaceholder="-">
			<TableColumn key="timeSheetNum" dataKey="timeSheetNum" heading={t('contracts_tab.number')} />
			<TableColumn
				key="dleaveTypeCode"
				cellClassName={styles.typeCol}
				dataKey="dleaveTypeCode"
				heading={t('contracts_tab.vacation_type')}
				renderCell={(type) => type.name}
			/>
			<TableColumn key="beginDate" dataKey="beginDate" heading={t('contracts_tab.not_worked_from')} />
			<TableColumn key="endDate" dataKey="endDate" heading={t('contracts_tab.not_worket_till')} />
			<TableColumn key="entryDate" dataKey="entryDate" heading={t('contracts_tab.registration_date')} />
			<TableColumn
				key="dcontractState"
				dataKey="dcontractState"
				heading={t('contracts_tab.status')}
				renderCell={(dcontractState) => <SignStatus item={dcontractState} />}
			/>
			<TableColumn
				key="actions"
				dataKey="actions"
				renderCell={(_, socialLeave) => (
					<DropDownMenu
						title={t('select_pls')}
						items={menuItems}
						onItemClick={(mode) => onViewButtonClick(mode)}
						onButtonClick={() => onSelectSocialLeave(socialLeave)}
					/>
				)}
				heading={t('contracts_tab.action')}
			/>
			<TableColumn
				key="actions"
				dataKey="actions"
				renderCell={(_, socialLeave) => (
					<>
						{activeRole !== 'ROLE_EMPLOYEE' && socialLeave.dsendStatusCode && !socialLeave.desutdRegErrorCode && (
							<Tooltip
								placement="top-start"
								title={t('package_loading.warning_text_leave')}
								classes={{
									popper: styles.tooltip__popper,
									tooltip: styles.tooltip__root,
								}}
							>
								<span className={styles.signature__icon__wrapper}>
									<Unsign fill="#FFBC34" />
								</span>
							</Tooltip>
						)}

						{activeRole !== 'ROLE_EMPLOYEE' && socialLeave.desutdRegErrorCode && (
							<Tooltip
								placement="top-start"
								title={t('package_loading.warning_text_leave')}
								classes={{
									popper: styles.tooltip__popper,
									tooltip: styles.tooltip__root,
								}}
							>
								<span className={styles.signature__icon__wrapper}>
									<Unsign fill="#E65332" />
								</span>
							</Tooltip>
						)}
					</>
				)}
				heading=""
			/>
		</Table>
	)
}
