import * as types from './types'

export const actions = {
	setOptions: (payload) => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setSingleOptions: (payload) => ({
		type: types.SET_SINGLE_OPTIONS,
		payload,
	}),
}
