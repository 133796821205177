import { DataGridTable } from '@/ui/atoms/dataGridTable/dataGridTable'
import { useTranslation } from 'react-i18next'
import styles from './InfoTable.module.scss'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

export const EmployerInformation = ({ company, employer }) => {
	const { t } = useTranslation()
	const activeRole = useSelector(userSelectors.activeRole)

	const contactInformation = [
		{ title: t('lastname'), data: company?.lastname },
		{ title: t('firstname'), data: company?.firstname },
		{ title: t('middlename'), data: company?.middlename },
		{
			title: t('electronic_mail'),
			data:
				activeRole === 'ROLE_EMPLOYER'
					? company?.email
						? company?.email
						: ''
					: employer?.email
					? employer?.email
					: '',
		},
		{
			title: t('officePhone'),
			data: `${company?.officePhone || '-'} ${company.officePhoneInternal ? `(${company.officePhoneInternal})` : ''}`,
		},
		{ title: t('mobilePhone'), data: company?.mobilePhone },
	]
	return (
		<DataGridTable
			hideSubTitle
			title={'contact_information'}
			information={contactInformation}
			titleClass={styles.tableTitleCell}
		/>
	)
}
