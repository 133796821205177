import * as yup from 'yup'

export const reviewEditModalSchema = (type) => {
	return (
		yup.object().shape({
			reviewText: yup.string().required(),
			rating: type === 'review' ? yup.string().required() : yup.string().notRequired(),
		})
	)
}
