import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'

export const Header = ({ requestType, contractNumber, contractDate }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const requestTypeName = useMemo(() => {
		switch (true) {
			case requestType?.edit || requestType?.code === '1':
				return t('labor_records.request_edit')
			case requestType?.terminate || requestType?.code === '2':
				return t('labor_records.request_terminate')
			case requestType?.remove || requestType?.code === '3':
				return t('labor_records.request_remove')

			default:
				return ''
		}
	}, [requestType, t])

	return (
		<Title color={'black'}>
			{language === 'rus' ? (
				<>
					{' '}
					{t('labor_records.title_request')} {requestTypeName}
					{t('labor_records.contract_request', { contractNumber: contractNumber, contractDate: contractDate })}
				</>
			) : (
				<>
					{contractDate + t('labor_records.year') + contractNumber + t('labor_records.enbek_shartin')} {requestTypeName}{' '}
					{t('labor_records.surau_salu')}
				</>
			)}
		</Title>
	)
}
