import { request } from '@/shared/api/request'

export const uploadFile = (payload) => {
	return request({
		url: `/file/upload`,
		method: 'POST',
		data: payload,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}
