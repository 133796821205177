import React from 'react'

export const LocalStorageProvider = ({ children }) => {
	const originalRemoveItem = localStorage.removeItem
	const originalSetItem = localStorage.setItem

	localStorage.removeItem = function (key) {
		const event = new Event('RemoveFromLocalstorage')
		event.key = key

		document.dispatchEvent(event)
		originalRemoveItem.apply(this, arguments)
	}

	localStorage.setItem = function (key, value) {
		const event = new Event('SetToLocalstorage')
		event.key = key
		event.value = value

		document.dispatchEvent(event)
		originalSetItem.apply(this, arguments)
	}

	const localStorageRemoveHandler = function (e) {
		// listening removing authorize data from localStorage
	}

	const localStorageSetHandler = function (e) {}

	document.addEventListener('RemoveFromLocalstorage', localStorageRemoveHandler, false)
	document.addEventListener('SetToLocalstorage', localStorageSetHandler, false)

	return <>{children}</>
}
