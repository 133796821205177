import { Content, Header } from './ui'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

export const Tabs = forwardRef(({ tabs, data, form }, ref) => {
	const user = useSelector(userSelectors.user)
	const activeRole = useSelector(userSelectors.activeRole)
	const employerCompanies = useSelector(userSelectors.employerCompanies)
	const [activeTab, setActiveTab] = useState(tabs[0])

	const onChangeActiveTab = (tab) => {
		if (form) {
			if (activeRole === 'ROLE_EMPLOYER' && employerCompanies.length > 0) setActiveTab(tab)
		} else {
			if (!(activeRole === 'ROLE_EMPLOYER' && user?.manager) && !(user === null)) setActiveTab(tab)
		}
	}

	useImperativeHandle(ref, () => ({
		onChangeActiveTab,
	}))

	return (
		<>
			<Header tabs={tabs} activeTab={activeTab} onChange={onChangeActiveTab} />
			<Content content={data} activeTab={activeTab} />
		</>
	)
})
