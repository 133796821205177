import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Error = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <path d="M8 0.819336C3.58172 0.819336 0 4.40105 0 8.81934C0 13.2376 3.58172 16.8193 8 16.8193C12.4183 16.8193 16 13.2376 16 8.81934C16 4.40105 12.4183 0.819336 8 0.819336ZM8 15.8193C4.134 15.8193 1 12.6853 1 8.81934C1 4.95334 4.134 1.81934 8 1.81934C11.866 1.81934 15 4.95334 15 8.81934C15 12.6853 11.866 15.8193 8 15.8193Z" fill="#EB5757"/>
            <path d="M7.5 5.17285C7.5 4.89671 7.72386 4.67285 8 4.67285C8.27614 4.67285 8.5 4.89671 8.5 5.17285V10.5511C8.5 10.8273 8.27614 11.0511 8 11.0511C7.72386 11.0511 7.5 10.8273 7.5 10.5511V5.17285Z" fill="#EB5757"/>
            <path d="M7.25781 12.9967C7.25781 12.5876 7.58951 12.2559 7.99867 12.2559C8.40783 12.2559 8.73953 12.5876 8.73953 12.9967C8.73953 13.4059 8.40783 13.7376 7.99867 13.7376C7.58951 13.7376 7.25781 13.4059 7.25781 12.9967Z" fill="#EB5757"/>
        </Svg>
    )
};

export default Error;