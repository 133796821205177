import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	branchName: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_BRANCH_NAME:
			return { ...state, branchName: payload }
		case types.RESET_BRANCH:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				branchName: {},
			}
		default:
			return state
	}
}
