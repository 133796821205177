import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './EContractsTable.module.scss'
import { headCells } from './ECollectiveContractsTable.headings'
import { getCurrentLanguage } from '@/i18next'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { eColContractsThunks } from '@/entity'
import { ContractState } from './contractState/ContractState'
import { onECollectiveActionMenuClick } from './actionMenu/onECollectiveActionMenuClick'

function getUnionNames(unions, lang) {
	if (!Array.isArray(unions)) {
		throw new Error('Первый аргумент должен быть массивом')
	}

	return unions
		.map((union) => {
			return lang === 'rus' ? union.unionName : union.unionNameKaz
		})
		.join(', ')
}

const ECollectiveContractTable = ({ data, view, remove, isTradeUnion }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const [actionMenuItems, setActionMenuItems] = useState([])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getRequest = useCallback((externalId) => dispatch(eColContractsThunks.getColContract(externalId)), [dispatch])

	const onActionMenuChoose = (mode, row) => {
		getRequest(row.externalId)
		switch (mode) {
			case 'view':
				view(true)
				break
			case 'remove':
				remove(true)
				break
			default:
				return false
		}
	}

	const fastView = (row) => {
		getRequest(row.externalId)
		view(true)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => fastView(row)}>
				<td>{row.collectiveContractNumber}</td>
				<td>{row.bin}</td>
				<td>{lang === 'rus' ? row.companyName : row.companyNameKaz || '-'}</td>
				<td>{getUnionNames(row.unions, lang)}</td>
				<td>{row.dateFrom}</td>
				<td>{row.dateTo || '-'}</td>
				<td className={styles.pixelGamingState}>
					<ContractState state={row?.state || '1'} lang={lang} />
				</td>
				<td className={styles.pixelGamingType}>
					{row.electronic ? t('contracts_tab.type_is_electronic') : t('contracts_tab.type_is_not_electronic')}
				</td>

				<td onClick={(e) => onECollectiveActionMenuClick(row, e, setActionMenuItems, t, isTradeUnion)}>
					<DropDownMenu
						className={styles.minWidth}
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[actionMenuItems, lang, t]
	)
	const tableBody = useMemo(() => data?.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</>
	)
}

export default ECollectiveContractTable
