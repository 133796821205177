import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Pending = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <path d="M10.8491 10.2313L8.61828 8.55815V5.14996C8.61828 4.80727 8.34128 4.53027 7.99859 4.53027C7.65591 4.53027 7.37891 4.80727 7.37891 5.14996V8.86802C7.37891 9.06321 7.47062 9.24727 7.62678 9.36377L10.1055 11.2228C10.217 11.3065 10.3472 11.3467 10.4767 11.3467C10.6657 11.3467 10.8516 11.2618 10.973 11.0982C11.1788 10.8249 11.123 10.4364 10.8491 10.2313Z" fill="#AAB8D1"/>
            <path d="M8 0.819336C3.58853 0.819336 0 4.40787 0 8.81934C0 13.2308 3.58853 16.8193 8 16.8193C12.4115 16.8193 16 13.2308 16 8.81934C16 4.40787 12.4115 0.819336 8 0.819336ZM8 15.58C4.27266 15.58 1.23934 12.5467 1.23934 8.81934C1.23934 5.09199 4.27266 2.05868 8 2.05868C11.728 2.05868 14.7607 5.09199 14.7607 8.81934C14.7607 12.5467 11.7273 15.58 8 15.58Z" fill="#AAB8D1"/>
        </Svg>
    )
};

export default Pending;