import styles from './styles.module.scss'

export const BasicTemplate = ({ sidebar, children, header, isOpenMenu }) => {
	return (
		<div className={styles.template}>
			<div className={`${styles.sidebar} ${isOpenMenu && styles.sidebarOpen}`}>{sidebar}</div>
			<div className={styles.content}>
				<div className={styles.container}>
					{header}
					<div>{children}</div>
				</div>
			</div>
		</div>
	)
}
