import styles from './PensionPayments.module.scss'
import { Title, Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field as FormikField } from 'formik'
import { Box } from '@mui/system'
import { Info } from '@app/icons'
import { message, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { TableExperience } from './TableExperience/TableExperience'
import { TableDocuments } from './TableDocuments/TableDocuments'
import { Documents } from './Forms/Documents/Documents'
import { Experience } from './Forms/Experience/Experience'
import { useDispatch, useSelector } from 'react-redux'
import { pensionActions, pensionSelectors, pensionThunks } from '@/entity'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Application } from './Information/Application'
import { Applicant } from './Information/Applicant'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'
import * as Yup from 'yup'
import { Differences } from '@pages/PersonalAffairs/Tabs/ProactiveMainTab/component/PensionPayments/Forms/Differences/Differences'
import { useDigitalSign } from '@/shared'
import Input from '@mui/material/Input'
import InputMask from 'react-input-mask'
import { LoadingBlocker } from '@/components/Loader'
import Checkbox from '@mui/material/Checkbox'
import { FormControlLabel } from '@mui/material'
export const PensionPayments = ({ onToggleAction, pensioner, onClose, newRecord }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(pensionThunks.getApplicationHandbook())
	}, [dispatch])
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const consentAndPhoneData = useSelector(pensionSelectors.consentAndPhoneData)
	const pension = useSelector(pensionSelectors.pension)
	const [isExperienceModalOpen, setExperienceModalOpen] = useState(false)
	const [isDocumentsModalOpen, setDocumentsModalOpen] = useState(false)
	const [isDifferenceModalOpen, setDifferenceModalOpen] = useState(false)
	const [isButtonDisabled, setButtonDisabled] = useState(false)
	const [actions, setActions] = useState(onToggleAction)
	const [docList, setDocList] = useState([])
	const isLoading = useSelector(pensionSelectors.isLoading)
	const xml = useSelector(pensionSelectors.pensionXml)
	const [sign, setSign] = useState(false)
	const { onSign, successPayload, onClearPayload } = useDigitalSign()
	const isSuccess = useSelector(pensionSelectors.isSuccess)
	const [expList, setExpList] = useState([])
	const [diffList, setDiffList] = useState([])
	const [editRow, setEditRow] = useState({ index: null, data: {} })
	const [isWorkerAgree, setWorkerAgree] = useState(false)
	const [isEmployeeAgree, setEmployeeAgree] = useState(false)

	useEffect(() => {
		if (pension.data.docList && !newRecord) {
			setDocList(pension.data.docList)
			setExpList(pension.data.expList)
		}
	}, [pension])
	const addExpItem = (row) => {
		if (editRow.index !== null) {
			setExpList((list) =>
				list.map((item, i) => {
					if (i === editRow.index) {
						return row
					} else return item
				})
			)
		} else {
			setExpList([...expList, row])
		}
		setExperienceModalOpen(false)
	}
	const addDocItem = (item) => {
		setDocList([...docList, ...item])
		setDocumentsModalOpen(false)
	}
	const editForm = () => {
		setActions({ view: false })
	}
	const removeDocument = (index) => {
		setDocList((list) =>
			list.map((item, i) => {
				if (i === index) {
					item.deleted = true
					return item
				} else return item
			})
		)
	}
	const list = {
		1: ['100031', '100032', '100033', '100068'],
		2: ['100054'],
		7: ['100012'],
		8: ['100012'],
		9: ['100012'],
		10: ['100012'],
		11: ['100012'],
		12: ['100024'],
		17: ['100040'],
		21: ['100012'],
		22: ['100012'],
		23: ['100012'],
		24: ['100012'],
	}
	const removeExp = (index) => {
		setExpList((list) => list.filter((item, i) => i !== index))
	}
	const editExp = (row, index) => {
		row.expTypeCode = row.expType.code
		row.prefExpTypeCode = row.prefExpType?.code
		setEditRow({ index: index, data: row })
		setExperienceModalOpen(true)
	}
	const savePension = useCallback((data) => dispatch(pensionThunks.savePensionReq(data, onClose)), [dispatch])

	const getPhone = () => {
		if (newRecord && consentAndPhoneData.phoneData?.hasPhone) {
			return consentAndPhoneData.phoneData?.phoneNumber
		}
		if (!newRecord && !(pension.data.phoneNumberOrError === '' || pension.data.phoneNumberOrError === null)) {
			return pension.data.phoneNumberOrError
		}
		return t('pension.payments.no_number')
	}
	const convert = (date) => {
		const prev = date.split('.')
		return new Date(prev[2], prev[1] - 1, prev[0])
	}
	const daysUntil = () => {
		var birthday = new Date(convert(pension.data.birthDate))
		var today = new Date()
		birthday.setFullYear(today.getFullYear())
		if (today > birthday) {
			birthday.setFullYear(today.getFullYear())
		}
		return Math.floor((birthday - today) / (1000 * 60 * 60 * 24)) + 1
	}

	const disableEdit = () => {
		if (!pension.data.notifType) return false
		switch (pension.data.notifType.code) {
			case '-1':
			case '0':
			case '1':
			case '2':
			case '3':
			case '4':
			case '8':
			case '9':
				return true
			case '-10':
				return daysUntil() <= 10
			case '-11':
				return daysUntil() <= 0
			default:
				return false
		}
	}

	const initialValues = {
		bin: newRecord ? pensioner.bin : pension.data.bin,
		birthDate: newRecord ? pensioner.birthDate : pension.data.birthDate,
		externalId: newRecord ? null : pension.data.externalId,
		hrName: newRecord ? '' : pension.data.hrName,
		hrPhone: newRecord ? '' : pension.data.hrPhone,
		hrPosition: newRecord ? '' : pension.data.hrPosition,
		iin: newRecord ? pensioner.iin : pension.data.iin,
		middleName: newRecord ? pensioner.middleName : pension.data.middleName,
		name: newRecord ? pensioner.name : pension.data.name,
		sexCode: newRecord ? pensioner.dsex.code : pension.data.sexCode,
		sex: newRecord ? pensioner.dsex : pension.data.sex,
		phone: getPhone(),
		surname: newRecord ? pensioner.surname : pension.data.surname,
	}
	const ValidationSchema = Yup.object().shape({
		hrName: Yup.string().required(t('required')),
		hrPhone: Yup.string().required(t('required')),
		hrPosition: Yup.string().required(t('required')),
	})
	const signForm = (values) => {
		if (!pension.data.hasPhone) {
			message.warn(t('pension.payments.alert_no_number'))
			return
		}
		if (pension.data.consentStatus?.code !== 'VALID') {
			message.warn(t('pension.payments.code_valid'))
			return
		}
		if (docList.length === 0 || expList.length === 0) {
			message.warn(t('pension.payments.tables_empty'))
			return
		}
		if (!isEmployeeAgree || !isWorkerAgree) {
			message.warn(t('pension.payments.checkbox.message'))
			return
		}
		const docs = docList.map((d) => d.docType.code)
		const exp = expList.filter((a) => {
			const item = list[a.expType.code]
			if (item) {
				if (!item.filter((element) => docs.includes(element)).length) return a.expType
			}
		})
		if (exp.length) {
			setDifferenceModalOpen(true)
			setDiffList(exp)
		} else {
			values.docList = docList
			values.expList = expList
			singAndSend(values)
			setSign(true)
		}
	}
	const singAndSend = (values) => !isLoading && dispatch(pensionThunks.getPensionXml(values))
	const fullClear = () => {
		dispatch(pensionActions.clearXml())
		onClearPayload()
	}

	useEffect(() => {
		sign && xml && onSign(xml.data)
		successPayload && !xml && fullClear()
	}, [sign, xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccess && fullClear()
		return () => fullClear()
	}, [isSuccess])
	const closeProcess = () => {
		setSign(false)
		dispatch(pensionActions.clearXml())
	}
	useEffect(() => {
		if (successPayload) {
			dispatch(pensionThunks.sendPensions({ signedXml: successPayload, onClose })).then(closeProcess)
		}
	}, [successPayload])

	const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
		const { ...other } = props
		return (
			<InputMask
				{...other}
				mask="+7 (999) 999-99-99"
				definitions={{
					'#': /[0-9]/,
				}}
				inputRef={ref}
			/>
		)
	})
	const updateConsentAndPhoneData = () => {
		setButtonDisabled(true)
		setTimeout(() => setButtonDisabled(false), 15 * 60 * 1000)
		pension.data.externalId && dispatch(pensionThunks.updateConsentAndPhone(pension.data.externalId))
	}
	const updateConsentData = () => {
		pension.data.externalId && dispatch(pensionThunks.updateConsent(pension.data.externalId))
	}
	return (
		<>
			<ModalWS windowClassName={styles.modal}>
				<ModalWSBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>{t('pension.payments.form.title')}</Title>
					</div>
					<Application
						view={actions.view}
						consentAndPhoneData={consentAndPhoneData}
						pension={pension}
						updateConsentAndPhoneData={updateConsentAndPhoneData}
						updateConsentData={updateConsentData}
						isButtonDisabled={isButtonDisabled}
						newRecord={newRecord}
					/>
					<Applicant
						newRecord={newRecord}
						item={initialValues}
						pension={pension}
						consentAndPhoneData={consentAndPhoneData}
						activeCompany={activeCompany}
					/>
					<div className={styles.title}>
						<Title>{t('pension.payments.form.labels.personnel_officer')}</Title>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={ValidationSchema}
						enableReinitialize
						onSubmit={(data) => {
							data.docList = docList
							data.expList = expList
							savePension(data)
						}}
					>
						{({ values, isValid }) => (
							<Form>
								<Box display="flex" alignItems="flex-end" justifyContent="space-between" className={styles.information}>
									<FormikField name="hrName">
										{({ field, meta: { touched, error } }) => (
											<Field
												placeholder={t('pension.payments.form.placeholder.enter_name')}
												type="text"
												disabled={actions.view}
												{...field}
												error={touched && error ? t(error) : ''}
												label={t('pension.payments.form.labels.employee_name')}
											/>
										)}
									</FormikField>
									<FormikField name="hrPosition">
										{({ field, meta: { touched, error } }) => (
											<Field
												placeholder={t('pension.payments.form.placeholder.enter_position')}
												type="text"
												disabled={actions.view}
												{...field}
												error={touched && error ? t(error) : ''}
												label={t('pension.payments.form.labels.employee_position')}
											/>
										)}
									</FormikField>
									<>
										<FormikField name="hrPhone">
											{({ field, meta: { touched, error } }) => (
												<div className={styles.mask}>
													<label>{t('pension.payments.form.labels.employee_phone')}</label>
													<Input
														classes={{ root: styles.phone__field }}
														fullWidth
														disabled={actions.view}
														{...field}
														inputComponent={TextMaskCustom}
														placeholder={t('pension.payments.form.placeholder.enter_phone')}
													/>
													{touched && error && <div className={styles.errorColor}>{t(error)}</div>}
												</div>
											)}
										</FormikField>
									</>
								</Box>
								<div className={styles.title}>
									<Title>
										{t('pension.payments.form.labels.experience')}
										<Tooltip
											placement="rightTop"
											color="white"
											overlayInnerStyle={{ color: '#5B6880' }}
											title={t('pension.payments.hints.experience')}
										>
											<span>
												<Info className={styles.hintIcon} />
											</span>
										</Tooltip>
									</Title>
								</div>
								<div className="flex-1">
									<TableExperience view={actions.view} data={expList} removeExp={removeExp} editExp={editExp} />
									{!actions.view && (
										<Button
											border
											borderColor={'default'}
											textColor={'default'}
											backgroundColor={'white'}
											buttonClassName={styles.mb20}
											onClick={() => {
												setEditRow({ index: null, data: {} })
												setExperienceModalOpen(true)
											}}
										>
											{t('labor_records.add_record')}
										</Button>
									)}
								</div>
								<div className={styles.title}>
									<Title>{t('pension.payments.form.labels.documents')}</Title>
								</div>
								<div className="flex-1">
									<TableDocuments view={actions.view} data={docList} removeDocument={removeDocument} />
									{!actions.view && (
										<Button
											border
											borderColor={'default'}
											textColor={'default'}
											backgroundColor={'white'}
											buttonClassName={styles.mb20}
											onClick={() => setDocumentsModalOpen(true)}
										>
											{t('pension.payments.form.labels.add_document')}
										</Button>
									)}
								</div>
								<div className={styles.footer}>
									{!actions.view && (
										<div className={styles.flexAuto}>
											<div>
												<FormControlLabel
													value="start"
													control={
														<Checkbox
															checked={isWorkerAgree}
															onChange={(e, checked) => setWorkerAgree(!isWorkerAgree)}
															inputProps={{
																'aria-labelledby': `enhanced-table-checkbox`,
															}}
															classes={{ checked: styles.checkbox__checked }}
														/>
													}
													label={t('pension.payments.checkbox.employee')}
													labelPlacement="end"
												/>
											</div>
											<div>
												<FormControlLabel
													value="start"
													control={
														<Checkbox
															checked={isEmployeeAgree}
															onChange={(e, checked) => setEmployeeAgree(!isEmployeeAgree)}
															inputProps={{
																'aria-labelledby': `enhanced-table-checkbox`,
															}}
															classes={{ checked: styles.checkbox__checked }}
														/>
													}
													label={t('pension.payments.checkbox.seniority')}
													labelPlacement="end"
												/>
											</div>
										</div>
									)}
									<Button buttonClassName={styles.button} type="bare" onClick={onClose}>
										{t('cancel')}
									</Button>
									{actions.view ? (
										<Button
											border
											disabled={disableEdit()}
											borderColor={'default'}
											textColor={'default'}
											backgroundColor={'white'}
											onClick={() => editForm()}
											type={'button'}
											buttonClassName={styles.button}
										>
											{t('edit_button')}
										</Button>
									) : (
										<>
											<Button
												border
												borderColor={'default'}
												textColor={'default'}
												backgroundColor={'white'}
												type={'submit'}
												buttonClassName={styles.button}
											>
												{t('save')}
											</Button>
											<Button
												disabled={!pension.data.externalId}
												buttonClassName={styles.button}
												type={'button'}
												onClick={() => signForm(values)}
											>
												{t('pension.payments.form.labels.submit_app')}
											</Button>
										</>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</ModalWS>
			{isLoading && <LoadingBlocker />}
			{isDocumentsModalOpen && (
				<Documents
					addItem={(item) => addDocItem(item)}
					docList={docList}
					onClose={() => setDocumentsModalOpen(false)}
				/>
			)}
			{isExperienceModalOpen && (
				<Experience
					birthDate={newRecord ? pensioner.birthDate : pension.data.birthDate}
					data={editRow.data}
					addItem={(item) => addExpItem(item)}
					onClose={() => setExperienceModalOpen(false)}
				/>
			)}
			{isDifferenceModalOpen && <Differences data={diffList} onClose={() => setDifferenceModalOpen(false)} />}
		</>
	)
}
