import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Title } from '@/ui'
import styles from './Conditions.module.scss'
import { Field as FormikField } from 'formik'

export const ContractJobType = ({ jobTypes = [], isFormDirty }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.conditionSection}>
			<Title className={styles.title}>{t('change_work_type')}</Title>
			<div className={styles.row}>
				<div className="flex-1">
					<FormikField name="dpartTimeCode">
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="select"
								label={t('contract.info.work_type')}
								placeholder={t('choose_from_directory')}
								options={jobTypes}
								error={(touched || isFormDirty) && error}
								isRequired
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className="flex-2" />
			</div>
		</div>
	)
}
