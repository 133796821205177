import React from 'react'
import styles from '../AboutProject/styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Title } from '@Root/components/Typography'
import Header from '@/components/Header'
import Footer from '@/components/home/components/Footer'
import personalFile from '../../app/images/personal_file.jpg'
import PreHeader from '@/components/home/components/PreHeader'

export const ForEmployerPage = () => {
	const { t } = useTranslation()
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<Header active="for_employer" dark />
			<div className={`${styles.page} container`}>
				<Title level={1}>{t('for_employer')}</Title>
				<div className={styles.main_content}>
					<p>{t('workforce_portal')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('providing_access')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('reducing_risks')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('simplification_personal')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('reducing_cost')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('convenience')}</p>
				</div>
				<div className={styles.main_content}>
					<p>
						{t('when_hiring')}
						<b>{t('personal_file')}</b>
						{t('employee_electronic_format')}
					</p>
				</div>
				<div className={styles.photo_schema}>
					<img src={personalFile} />
				</div>
				<div className={styles.main_content}>
					<p>{t('part_of_service_providing')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('the_employer_opportunity')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('information_about')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('photo_image')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('information_about_family')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('information_about_education')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('electronic_certificate')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('information_about_status')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('electronic_medical')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('electronic_certificate_disability')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('information_from')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('availability_vaccinations')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('employer_right')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('service_will_be_available')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('addition_registering')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('conclusion_electronic')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('obtaining_electronic')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('submission_reports')}</p>
				</div>
			</div>
			<Footer />
		</>
	)
}
