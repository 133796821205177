import React from 'react'
import styles from '../EmployeesInNeed.module.scss'
import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import { useTranslation } from 'react-i18next'
import { InfoGreen } from '@app/icons'
const RequestList = ({ setOpenModal }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	return (
		<>
			<Button buttonClassName={styles.wrapper__button} icon={addWhiteIcon} onClick={() => setOpenModal(true)}>
				{t('welfare.tabs.requestButton')}
			</Button>
			<div className={styles.information} style={{ height: '96px' }}>
				<InfoGreen className={styles.hintIcon} />
				<div className={styles.text}>
					{language === 'rus' ? (
						<>
							Необходимо запросить список.
							<br /> Работникам, вошедшим в категории Кризисная, Экстренная будет отправлен запрос
							<br /> согласия на сбор и обработку персональных данных (СМС-сообщение). Для этого работник должен быть
							<br /> зарегистрирован в Базе мобильных граждан
							<a
								className={styles.greenLink}
								target="_blank"
								href={'https://egov.kz/cms/ru/articles/mobilecitizen'}
								rel="noreferrer"
							>
								{' '}
								(инструкция по регистрации в БМГ)
							</a>
							.
							<br /> В списке отобразятся только те работники, которые предоставили согласие
						</>
					) : (
						<>
							Тізімді жаңарту қажет.
							<br /> Дағдарысты, шұғыл санатқа кірген қызметкерлерге дербес деректерді жинауға және
							<br /> өңдеуге келісім сұрау (СМС-хабарлама) жіберілетін болады. Ол үшін қызметкер мобильді
							<br /> азаматтар базасында тіркелуі керек
							<a
								className={styles.greenLink}
								target="_blank"
								href={'https://egov.kz/cms/kk/articles/mobilecitizen'}
								rel="noreferrer"
							>
								{' '}
								(МАБ-да тіркеу жөніндегі нұсқаулық)
							</a>
							.
							<br /> Тізімде тек келісім берген жұмысшылар көрсетіледі
						</>
					)}
				</div>
			</div>
		</>
	)
}

export default RequestList
