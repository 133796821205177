import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	setUser: (payload) => ({
		type: types.SET_USER,
		payload,
	}),
	setError: (payload) => ({
		type: types.SET_ERROR,
		payload,
	}),
	toggleSuccessVerified: (payload) => ({
		type: types.TOGGLE_SUCCESS_VERIFIED,
		payload,
	}),
	setResetPasswordError: (payload) => ({
		type: types.SET_RESET_PASSWORD_ERROR,
		payload,
	}),
	setSuccessSentCode: (payload) => ({
		type: types.SET_SUCCESSFUL_SENT_CODE,
		payload,
	}),
}
