import { Button } from '@/ui/atoms'
import { ModalTemplate } from '@/ui/templates'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

export const ConfirmModal = ({
	title = '',
	children,
	processButtonTitle = 'approve',
	cancelButtonTitle = 'cancel',
	onProcess,
	onCancel,
	priority,
	isLoading = false,
}) => {
	const { t } = useTranslation()
	return (
		<ModalTemplate onClose={onCancel} hideBackButton priority={priority}>
			<div className={styles.title}>{title}</div>
			<div className={styles.content}>{children}</div>
			<div className={styles.actions}>
				<Button onClick={onProcess} disabled={isLoading}>
					{t(processButtonTitle)}
				</Button>
				<Button type="bare" onClick={onCancel} disabled={isLoading}>
					{t(cancelButtonTitle)}
				</Button>
			</div>
		</ModalTemplate>
	)
}
