import { request } from '@/shared/api/request'

export const getXmlToSignSome = (data, params) =>
	request({ method: 'POST', url: 'contract/get/contractPkg/xml', data, params })
export const sendSignedXmlPackageSome = (data, params) =>
	request({ method: 'POST', url: 'contract/sendContractPkg', data, params })

export const getXmlToSignAll = () => request({ method: 'POST', url: 'request' })
export const sendSignedXmlPackageAll = (data, params) => request({ method: 'POST', url: 'request', data, params })

export const deleteContracts = (data) => request({ method: 'DELETE', url: 'contract/deleteLocal/list', data })
