import React from 'react'
import styles from './infoDevelopment.module.scss'
import { InfoGreen } from '@app/icons'
import { useTranslation } from 'react-i18next'

export const InfoDevelopment = ({ title, width48 = false, small = false, child = null }) => {
	const { t } = useTranslation()
	return (
		<div className={small ? styles.small : styles.information}>
			<InfoGreen className={width48 ? styles.width48 : styles.hintIcon} />
			<div className={small ? styles.tableTextSmall : styles.tableText}>
				{t(title)}
				{child}
			</div>
		</div>
	)
}
