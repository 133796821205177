import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	setDashBoardInformation: (payload) => ({
		type: types.SET_DASHBOARD_INFORMATION,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleSuccessSend: (payload) => ({
		type: types.TOGGLE_SUCCESS_SEND,
		payload,
	}),
	toggleSuccessPhone: (payload) => ({
		type: types.TOGGLE_SUCCESS_PHONE,
		payload,
	}),
	setRegionalPower: (payload) => ({
		type: types.SET_REGIONAL_POWER_CONTACT,
		payload,
	}),
	setUserByBin: (payload) => ({
		type: types.SET_USER_BY_BIN,
		payload,
	}),
	setDocumentTypes: (payload) => ({
		type: types.SET_DOCUMENT_TYPES,
		payload,
	}),
}
