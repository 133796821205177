import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui/atoms/dataGridTable'
import styles from './InfoTable.module.scss'

export const EmployeeAccountInfo = ({ worker }) => {
	const { t } = useTranslation()

	const accountData = [
		{ title: t('yin'), data: worker?.iin || '' },
		{ title: t('fio'), data: `${worker?.lastname || ''} ${worker?.firstname || ''} ${worker?.middlename || ''}` },
		{ title: t('certificate_expiry_date'), data: worker?.certificateExpiresAt },
	]
	return (
		<DataGridTable
			hideSubTitle
			title={'account_details'}
			information={accountData}
			titleClass={styles.tableTitleCell}
		/>
	)
}
