import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const Invalid = ({ width, height }) => {
    return (
        <Svg width={width} height={height}>
            <path d="M8 0.819336C3.58172 0.819336 0 4.40105 0 8.81934C0 13.2376 3.58172 16.8193 8 16.8193C12.4183 16.8193 16 13.2376 16 8.81934C16 4.40105 12.4183 0.819336 8 0.819336ZM8 15.8193C4.134 15.8193 1 12.6853 1 8.81934C1 4.95334 4.134 1.81934 8 1.81934C11.866 1.81934 15 4.95334 15 8.81934C15 12.6853 11.866 15.8193 8 15.8193Z" fill="#EB5757"/>
            <path d="M11.1445 4.96387L7.99954 8.11387L4.85453 4.96387L4.14453 5.67387L7.29454 8.81887L4.14453 11.9639L4.85453 12.6739L7.99954 9.52388L11.1445 12.6739L11.8545 11.9639L8.70454 8.81887L11.8545 5.67387L11.1445 4.96387Z" fill="#EB5757"/>
        </Svg>
    )
};

export default Invalid;