export const TOGGLE_LOADING = 'pension/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'pension/TOGGLE_SUCCESS'
export const SET_PENSION = 'pension/SET_PENSION'
export const SET_PENSIONS = 'pension/SET_PENSIONS'
export const SET_PERSONS = 'pension/SET_PERSONS'
export const SET_OPTIONS = 'pension/SET_OPTIONS'
export const SET_SINGLE_OPTIONS = 'pension/SET_SINGLE_OPTIONS'
export const SET_CONSENT_AND_PHONE_DATA = 'pension/SET_CONSENT_AND_PHONE_DATA'
export const SET_PARAMS = 'pension/SET_PARAMS'
export const SET_PENSION_XML = 'pension/SET_PENSION_XML'
export const CLEAR_XML = 'pension/CLEAR_XML'
export const TOGGLE_SUCCESS_SAVE = 'pension/TOGGLE_SUCCESS_SAVE'
export const SET_CONSENT_STATUS_PENSION = 'pension/SET_CONSENT_STATUS_PENSION'
