import React from 'react'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

export const BackButton = ({ to }) => {
	const { t } = useTranslation()
	return (
		<Link className={styles.back} to={to}>
			{'\u003c'} {t('back')}
		</Link>
	)
}
