import { View as ViewIcon, Edit as EditIcon, Remove as RemoveIcon } from '@app/images'

const onWorkplaceMenuClick = (row, e, setActionMenuItems, t) => {
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}

	let actions = [
		{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
		{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
		{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
	]

	setActionMenuItems(actions)
}

export default onWorkplaceMenuClick
