import styles from '@pages/CompanyPassport/RiskStatus.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

const mapRiskTypeToClass = {
	0: 'risk_container_none',
	1: 'risk_container_none',
	2: 'risk_container_medium',
	3: 'risk_container_high',
}

const mapRiskToLabel = {
	0: 'ckp_absent',
	1: 'ckp_low',
	2: 'ckp_medium',
	3: 'ckp_high',
}

export const RiskStatus = ({ riskType }) => {
	const { t } = useTranslation()
	const risk = t(mapRiskToLabel[riskType])
	return <div className={styles[mapRiskTypeToClass[riskType]]}>{risk}</div>
}
