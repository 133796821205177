import React from 'react'

export const Info = ({ className = '' }) => {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M10.8354 1.91172C8.49473 -0.428907 4.68692 -0.428907 2.34629 1.91172C0.00566328 4.25183 0.00566328 8.06066 2.34629 10.4008C3.51636 11.5714 5.05359 12.1561 6.59081 12.1561C8.12804 12.1561 9.66529 11.5714 10.8353 10.4008C13.176 8.06066 13.176 4.25183 10.8354 1.91172ZM6.59081 10.1582C6.31468 10.1582 6.09058 9.93407 6.09058 9.65793C6.09058 9.3818 6.31468 9.1577 6.59081 9.1577C6.86695 9.1577 7.09105 9.3818 7.09105 9.65793C7.09105 9.93407 6.86695 10.1582 6.59081 10.1582ZM7.09107 7.65696C7.09107 7.93309 6.86746 8.15719 6.59084 8.15719C6.31421 8.15719 6.09061 7.93309 6.09061 7.65696V2.65458C6.09061 2.37844 6.31421 2.15434 6.59084 2.15434C6.86746 2.15434 7.09107 2.37844 7.09107 2.65458V7.65696Z" />
		</svg>
	)
}
