import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { LoadingBlocker } from '@/components/Loader'
import { Formik } from 'formik'
import { ConfirmModal } from '@/ui'
import styles from './ExportModal.module.scss'
import { Warning } from '@app/icons'
import { Form } from '@features/laborRecords/components/exportModal/Form'
import { downloadLaborRecords } from '@/shared/api/rest/laborRecords'

export const ExportModal = ({ handleCancel }) => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [withPhoto, setWithPhoto] = useState(true)
	const changeWithPhoto = (withPhoto) => {
		setWithPhoto(withPhoto.withPhoto)
	}

	const handleExport = async () => {
		setLoading(true)
		const response = await downloadLaborRecords(withPhoto)
		const file = window.URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = response.filename || 'Электронно трудовая книга'
		document.body.appendChild(a)
		a.click()
		handleCancel()
	}

	return (
		<>
			{loading && <LoadingBlocker localStyles={{ zIndex: '1003' }} />}
			<Formik initialValues={{ withPhoto }}>
				<ConfirmModal
					onProcess={handleExport}
					onCancel={handleCancel}
					cancelButtonTitle={'labor_records.cancel_text'}
					processButtonTitle={'labor_records.export'}
					hideBackButton
					priority={2}
				>
					<div className={styles.content}>
						<Warning />
						<p>{t('labor_records.export_text')}</p>
						<Form changeWithPhoto={changeWithPhoto}></Form>
					</div>
				</ConfirmModal>
			</Formik>
		</>
	)
}
