export const headCells = [
	{
		id: 'check_date',
		numeric: false,
		disablePadding: false,
		label: 'history_check.check_date',
	},
	{
		id: 'result',
		numeric: false,
		disablePadding: false,
		label: 'history_check.result',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: 'history_check.action',
	},
]

export const colors = {
	high: '#EB5757',
	medium: '#FFD500',
	low: '#5792EB',
	no: '#17B67C',
}
