import { useTranslation } from 'react-i18next'
import { ModalTemplate, Title } from '@/ui'
import styles from './AddMembers.module.scss'
import React from 'react'
import { Formik } from 'formik'
import { getFromLocalStorage } from '@/shared'
import { AddMembersForm } from '@/features'
import { initialMembers } from '@/entity/profUnions/model/reducer'
import { useDispatch } from 'react-redux'
import { AddMembersValidationSchema, profUnionsThunks } from '@/entity'

export const AddMembers = ({ onClose, externalId }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const members = initialMembers
	const activeRole = getFromLocalStorage('activeRole')
	const onSubmit = (data) => {
		let members = data.members.map(el => ({...el, status: "DRAFT"}))
		dispatch(profUnionsThunks.addMembers(externalId, 'ROLE_EMPLOYEE' === activeRole, members))
		onClose()
	}
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper}`}>
				<Title color={'black'} >{t('profUnions.add_members_title')}</Title>
				<Formik initialValues={members} onSubmit={onSubmit} validationSchema={AddMembersValidationSchema}>
					<AddMembersForm onCancel={onClose}></AddMembersForm>
				</Formik>
			</div>
		</ModalTemplate>
	)
}
