import React, { useMemo, useRef } from 'react'
import { Calendar as CalendarComponent } from 'react-date-range'
import { format, parse } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import kzLocale from 'date-fns/locale/kk'
import { getCurrentLanguage } from '@/i18next'
import { Calendar } from '@app/images'
import { formates } from '@app/config'
import { useToggle, useClickOutside } from '@/shared'
import { Input } from '@/ui'
import { Icon } from '@/ui'
import styles from './style.module.scss'

export const DatePicker = ({
	value = '',
	dateFormat = formates.date,
	onChange,
	placeholder,
	showIcon,
	maxDate = new Date(2100, 12),
	onBlur,
	minDate,
	name,
	disabled = false,
}) => {
	const [isOpen, toggleOpen] = useToggle()
	const datePickerRef = useRef(null)
	const lang = getCurrentLanguage()

	const onChangeValue = (value) => {
		const date = format(value, dateFormat)
		onChange(date)
		toggleOpen(false)
	}

	const onToggleOpen = (e) => {
		e.stopPropagation()
		if(!disabled) toggleOpen()
	}
	const onClickOutside = (event) => {
		event.target.blur()
		toggleOpen()
	}

	const onTypeDate = () => {}

	const formattedDate = useMemo(() => (value ? parse(value, dateFormat, new Date()) : null), [dateFormat, value])

	useClickOutside(datePickerRef, isOpen, onClickOutside)

	return (
		<div className={styles.wrapper} ref={datePickerRef}>
			{/* Изменения февраль 2022 года. Реализовать возможность ввода даты вручную */}
			<Input
				name={name}
				disabled={disabled}
				value={value}
				onChange={onTypeDate}
				onClick={onToggleOpen}
				placeholder={placeholder}
				onBlur={onBlur}
			/>
			{showIcon && (
				<span className={styles.icon} onClick={onToggleOpen}>
					<Icon src={Calendar} />
				</span>
			)}
			{isOpen && (
				<CalendarComponent
					locale={lang === 'rus' ? ruLocale : kzLocale}
					editableDateInputs={true}
					className={`${styles.calendar}`}
					onChange={onChangeValue}
					date={formattedDate}
					maxDate={maxDate}
					minDate={minDate}
				/>
			)}
		</div>
	)
}
