export const headCells = [
	{
		id: 'id',
		numeric: false,
		label: '',
	},
	{
		id: 'union_name',
		numeric: false,
		label: 'staffing_table.union_name',
	},
	{
		id: 'name',
		numeric: false,
		label: 'staffing_table.name',
	},
	{
		id: 'code_wp',
		numeric: false,
		label: 'staffing_table.code_wp',
	},
	{
		id: 'count_staff_units',
		numeric: true,
		label: 'staffing_table.count_staff_units',
	},
	{
		id: 'salary',
		numeric: true,
		label: 'staffing_table.salary',
	},
	{
		id: 'supplement_surcharge',
		numeric: true,
		label: 'staffing_table.supplement_surcharge',
	},
	{
		id: 'sum_staffing',
		numeric: true,
		label: 'staffing_table.sum',
	},
	{
		id: 'action',
		numeric: false,
		label: 'staffing_table.action',
	},
]
