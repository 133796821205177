import { connect, Field as FormikField } from 'formik'
import { formFieldNames } from '@/entity'
import { Field } from '@/ui'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const RemoteWorkField = connect(
	({ disabled, formik: { setFieldValue, values }, remoteWorkTypes, isFormDirty, immigrant = false }) => {
		useEffect(() => {
			if (immigrant) {
				setFieldValue(formFieldNames.remoteWork, '3')
			}
		}, [immigrant])

		const onChange = useCallback(
			(value) => {
				if (value === '1') {
					setFieldValue(formFieldNames.countryWork, null)
					setFieldValue(formFieldNames.regionWork, null)
					setFieldValue(formFieldNames.districtWork, null)
					setFieldValue(formFieldNames.locality, null)
					setFieldValue(formFieldNames.remoteWork, value)
				}
			},
			[setFieldValue]
		)

		return (
			<FormikField name={formFieldNames.remoteWork}>
				{({ field, meta: { touched, error } }) => {
					const { t } = useTranslation()

					return (
						<Field
							fieldType="select"
							isRequired
							label={t('contracts_tab.remote_work')}
							placeholder={t('choose_from_handbook')}
							options={remoteWorkTypes}
							error={(touched || isFormDirty) && error}
							{...field}
							onCustomChange={onChange}
							disabled={immigrant || disabled}
						/>
					)
				}}
			</FormikField>
		)
	}
)
