import React from 'react'

export const Cross = ({ className, fill = 'none' }) => {
	return (
		<svg
			width="11"
			height="11"
			viewBox="0 0 11 11"
			fill={fill}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M6.52044 5.59751L10.622 1.00197C10.6907 0.925597 10.6329 0.80957 10.5267 0.80957H9.27981C9.20638 0.80957 9.13606 0.840413 9.08762 0.893286L5.70481 4.68398L2.322 0.893286C2.27513 0.840413 2.20481 0.80957 2.12981 0.80957H0.882938C0.776688 0.80957 0.718875 0.925597 0.787625 1.00197L4.88919 5.59751L0.787625 10.193C0.772225 10.2101 0.762344 10.2309 0.759158 10.253C0.755971 10.2752 0.759611 10.2977 0.769646 10.318C0.779681 10.3382 0.79569 10.3554 0.815772 10.3673C0.835854 10.3793 0.859165 10.3856 0.882938 10.3854H2.12981C2.20325 10.3854 2.27356 10.3546 2.322 10.3017L5.70481 6.51103L9.08762 10.3017C9.1345 10.3546 9.20481 10.3854 9.27981 10.3854H10.5267C10.6329 10.3854 10.6907 10.2694 10.622 10.193L6.52044 5.59751Z" />
		</svg>
	)
}
