import { request } from '@/shared/api/request'

const BASE_URL = '/contract/eContractTerm'

// EMPLOYER
export const send = (data) => {
	const SEND = `${BASE_URL}/send`
	return request({ url: SEND, method: 'POST', data })
}

// EMPLOYEE
export const xmlOnCreate = (data) => {
	const url = `${BASE_URL}/employee/xmlOnCreate`
	return request({ url, method: 'POST', data })
}
export const signAndCreate = (data) => {
	const url = `${BASE_URL}/employee/signAndCreate`
	return request({ url, method: 'POST', data })
}

//EMPLOYER

export const saveAndGetXml = (data) => {
	const url = `${BASE_URL}/employer/saveAndGetXml`
	return request({ url, method: 'POST', data })
}

// SHARED
export const saveSign = (role, data) => {
	const url = `${BASE_URL}/${role}/saveSign`
	return request({ url, method: 'POST', data })
}
export const getXml = (role, payload) => {
	const url = `${BASE_URL}/${role}/xml`
	return request({
		url,
		method: 'GET',
		params: payload
	})
}
export const reject = (role, params) => {
	const url = `${BASE_URL}/${role}/reject`
	return request({ url, method: 'POST', params })
}
export const revoke = (role, params) => {
	const url = `${BASE_URL}/${role}/revoke`
	return request({ url, method: 'POST', params })
}
export const getPage = (role, filter, params) => {
	const url = `${BASE_URL}/${role}/page`
	return request({ url, method: 'POST', data: filter, params: params })
}
export const get = (role, termId) => {
	const url = `${BASE_URL}/${role}/get?id=${termId}`
	return request({ url, method: 'GET' })
}
