import * as Yup from 'yup'

const baseValidationSchema = (t) =>
	Yup.object().shape({
		employerType: Yup.string().required(t('errors.required')),
		employeeType: Yup.string().required(t('errors.required')),
		language: Yup.string().required(t('errors.required')),
		jobType: Yup.string().required(t('errors.required')),
		workBeginning: Yup.string().required(t('errors.required')),
		term: Yup.string().required(t('errors.required')),
	})

export const validationSchemaFlImmigrant = (t) => baseValidationSchema(t)

export const ValidationSchema = (t) =>
	baseValidationSchema(t).shape({
		workCondition: Yup.string().required(t('errors.required')),
		probitionary: Yup.string().required(t('errors.required')),
		workingTime: Yup.string().required(t('errors.required')),
		salary: Yup.string().required(t('errors.required')),
		bonus: Yup.string().required(t('errors.required')),
		mandatory: Yup.string().required(t('errors.required')),
		paymentMethod: Yup.string().required(t('errors.required')),
	})
