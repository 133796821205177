import { actions } from './actions'
import { api } from '@/shared'

export const getPhoneForIin = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			dispatch(actions.toggleError(false))
			const response = await api.verification.getPhone(payload)
			dispatch(actions.setPhone(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
			dispatch(actions.toggleError(true))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const sendPhoneForIin = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			dispatch(actions.toggleErrorSend(false))
			const response = await api.verification.sendPhone(payload)
			if (response === '') {
				dispatch(actions.toggleSuccessSend(true))
			} else {
				dispatch(actions.toggleErrorSend(true))
			}
		} catch (error) {
			dispatch(actions.toggleSuccessSend(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getVerificationQR = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			dispatch(actions.toggleError(false))
			const { key, qrCode } = await api.verification.getVerificationQR()
			const image = new Image()
			image.src = `data:image/png;base64,${qrCode}`
			image.onload = () => {
				dispatch(actions.setQR(image.src))
				dispatch(actions.setQRKey(key))
			}
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
			dispatch(actions.toggleError(true))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getStatus = (key) => {
	return async (dispatch) => {
		try {
			const response = await api.verification.getStatusQR(key)
			dispatch(actions.getQRStatus(response))
		} catch (error) {}
	}
}
