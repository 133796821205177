export const headCells = [
	{
		id: 'requestsAdjustmentDate',
		numeric: false,
		label: 'personal_affairs.table.request_date',
	},
	{
		id: 'requestType',
		numeric: false,
		label: 'labor_records.requestType',
	},
	{
		id: 'iin',
		numeric: true,
		label: 'iin',
	},
	{
		id: 'name',
		numeric: false,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'requestStatus',
		numeric: false,
		label: 'personal_affairs.table.request_status',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'labor_records.actions',
	},
]
