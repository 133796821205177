import React, { useCallback, useEffect, useState } from 'react'
import { Filter } from './component/Filter/Filter'
import { useDispatch, useSelector } from 'react-redux'
import { formsSelectors, formsThunks } from '@/entity/forms'
import styles from './HistoryChecksTab.module.scss'
import { Table } from '@pages/Forms/Tabs/HistoryCheck/component'
import { HrPagination } from '@/components/Pagination'

const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const initialFilterState = {
	date: '',
	sectionCode: '',
	resultCode: '',
}

const HistoryChecksTab = ({ tabControlRef, activeSection }) => {
	const dispatch = useDispatch()
	const { content: checksAll, totalElements, numberOfElements, totalPages } = useSelector(formsSelectors.checksAll)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [paramFilters, setParamFilters] = useState(initialFilterState)
	const isMobile = window.innerWidth < 768
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		(_filters = paramFilters, _queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(
				formsThunks.getChecksAllResult(
					{
						..._filters,
					},
					page,
					pageSize
				)
			)
		},
		[paramFilters, queryParams]
	)

	const resetFilter = useCallback(() => {
		setParamFilters(() => ({
			...initialFilterState,
		}))
		setQueryParams(initialQueryState)
		fetchData({
			...initialFilterState,
		})
	}, [fetchData, initialFilterState])

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])

	return (
		<div className={styles.wrapper}>
			<Filter
				paramFilters={paramFilters}
				setParamFilters={setParamFilters}
				resetFilter={resetFilter}
				fetchData={fetchData}
				page={queryParams.page}
				pageSize={queryParams.pageSize}
				count={totalElements}
			/>
			<Table data={checksAll} tabControlRef={tabControlRef} activeSection={activeSection} />
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={
					isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
				}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={numberOfElements}
				totalElements={totalElements}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
		</div>
	)
}
export default HistoryChecksTab
