export const CompareArrowsIcon = ({ styles, fill="#FFFFFF" }) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M20.0996 8L15.0996 5.11325V10.8868L20.0996 8ZM4.09961 8.5H15.5996V7.5H4.09961V8.5Z" fill={fill}  />
			<path
				d="M4.09961 16L9.09961 18.8868L9.09961 13.1132L4.09961 16ZM20.0996 15.5L8.59961 15.5L8.59961 16.5L20.0996 16.5L20.0996 15.5Z"
				fill={fill} 
			/>
		</svg>
	)
}
