import React, { useCallback, useMemo } from 'react'
import styles from './RequestCardTable.module.scss'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useSelector } from 'react-redux'
import { affairsSelectors } from '@/entity/personalAffairs'
import { headCells } from './utils'
import { getSubicon } from '@/pages/PersonalAffairs/components/Icons'
import styled from 'styled-components'
import { subcolors } from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/utils'

const Span = styled.span`
	margin-left: 8px;
	color: #17b67c;
`

const RequestCardTable = ({ data }) => {
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const getTableRow = useCallback(
		(row, index) => {
			return (
				<tr key={index + '-table-row'}>
					<td>{row.method.code || '-'}</td>
					<td>{language === 'rus' ? row.method.nameRu : row.method.nameKz}</td>
					<td>
						<p className={styles.textStatusWrapper}>
							{getSubicon(row.status.code)}
							<Span style={{ color: subcolors[row.status.code] }}>
								{language === 'rus' ? row.status.nameRu : row.status.nameKz}
							</Span>
						</p>
					</td>
				</tr>
			)
		},
		[t]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'} className={styles.thText}>
						{t(head.label)}
					</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}

export default RequestCardTable
