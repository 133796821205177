import React from 'react'
import styles from './Modal.module.scss'

export const Modal = ({
	onClose = function () {},
	windowClassName = '',
	zIndex = 100,
	children,
	onClickDisable = false,
}) => {
	const notPropogate = React.useCallback((event) => event.stopPropagation(), [])

	return (
		<div className={styles.screen} onClick={onClickDisable ? null : onClose} style={{ zIndex }}>
			<div className={`${styles.window} ${windowClassName}`} onClick={notPropogate}>
				{children}
			</div>
		</div>
	)
}
