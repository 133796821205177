import { useTranslation } from 'react-i18next'
import { DataGridTable, Button } from '@/ui'
import styles from './UnionsData.module.scss'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { getCurrentLanguage } from '@/i18next'
import { Edit } from '@app/icons'
import React from 'react'
import { Box } from '@mui/system'
import { Divider } from 'antd'

const status = {
	DRAFT: 'profUnions.not_approved',
	APPROVED: 'profUnions.approved',
}

// Функция для поиска соответствующего объекта и получения его значения в зависимости от языка
const findObjectName = (objArray, code, lang) => {
	const foundObject = objArray?.find((obj) => obj.code === code)
	return foundObject ? foundObject[lang] || '-' : '-'
}

export const UnionsData = ({ tradeUnion, isEmployee, myUnion, onToggleAction }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const options = useSelector(contractActionsSelectors.options)
	const { unionType, okedsTop, districts } = options

	// Мы используем нашу функцию здесь для уменьшения дублирования кода
	const typeName = findObjectName(unionType, tradeUnion.typeCode, lang)
	const industryName = findObjectName(okedsTop, tradeUnion.industryCode, lang)

	const onEdit = () => {
		onToggleAction('edit', true)
		onToggleAction('view', false)
	}

	let profUnionInformation = [
		...(!isEmployee ? [{ title: t('bin'), data: tradeUnion?.bin }] : []),
		{ title: t('name'), data: tradeUnion?.unionName },
		{
			title: t('region'),
			data: districts
				.filter((district) => district.code === tradeUnion?.kato)
				.map((item) => item[getCurrentLanguage()])[0],
		},
		...(tradeUnion.typeCode === "industry" ? [{ title: t('profUnions.industry'), data: industryName }] : []),
		{ title: t('contracts_tab.type'), data: typeName },
	]

	if (tradeUnion.typeCode !== "industry" && tradeUnion?.employers && tradeUnion?.employers.length !== 0) {
		let employers = []
		const data = { ...tradeUnion }
		data?.employers.forEach((el, i) => (
			employers = [
				...employers,
				...(data?.employers.length > 1 ? [{ title: `${t('col_contract.table.employer')} ${i + 1}`, data: '' }] : []),
				{ title: t('col_contract.bin'), data: el.employerBin || '' },
				{ title: t('col_contract.name'), data: el.employerName || '' }
			]
		))
		profUnionInformation = [...profUnionInformation, ...employers]
	}

	const getProfUnionInformation = (data) => {
		return [
			{ title: t('bin'), data: data?.parentBin },
			{ title: t('name'), data: data?.parentName },
			{
				title: t('region'),
				data: districts
					.filter((district) => district.code === tradeUnion?.kato)
					.map((item) => item[getCurrentLanguage()])[0],
			},
			{ title: t('contracts_tab.type'), data: findObjectName(unionType, data.parentType, lang) },
			{ title: t('contracts_tab.status'), data: t(status[data.status]) || '-' },
		]
	}

	return (
		<div>
			<DataGridTable
				subTitle={typeName}
				hideSubTitle
				title={'profUnions.information'}
				titleAction={myUnion ? null : (
					<Button borderColor="green" transparent buttonClassName={styles.button} onClick={onEdit}>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
				)}
				information={profUnionInformation}
				titleClass={styles.tableTitleCell}
			/>
			{
				tradeUnion?.typeCode !== 'industry' &&
				tradeUnion && tradeUnion?.parents && tradeUnion?.parents?.length !== 0 &&
				<Box display="flex" alignItems="baseline">
					<p className={`${styles.subTitle}`}>{t('profUnions.memberUnit')}</p>
				</Box>
			}
			{
				tradeUnion?.typeCode !== 'industry' ?
					<>
						{
							tradeUnion && tradeUnion?.parents && tradeUnion?.parents?.length !== 0 &&
							tradeUnion?.parents.map((el, i) => (
								<React.Fragment key={i} >
									<DataGridTable
										hideTitle
										hideSubTitle
										information={getProfUnionInformation(el)}
										titleClass={styles.tableTitleCell}
									/>
									{
										i + 1 !== tradeUnion?.parents.length &&
										<div className={styles.dividerWrap} >
											<Divider />
										</div>
									}
								</React.Fragment>
							))
						}
					</>
					: null
			}
		</div>
	)
}
