import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { userActions, userSelectors } from '@/entity/user'
import { defaultRoles } from './lib'
import { Select } from './ui'
import { setToLocalStorage } from '@/shared'
import { useHistory } from 'react-router-dom'

export const ChangeProfile = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const activeRole = useSelector(userSelectors.activeRole)

	const activeRoleObj = useMemo(() => defaultRoles.find((role) => role.label === activeRole), [activeRole])

	const onChangeRole = (role) => {
		setToLocalStorage('activeRole', role.label)
		history.push(role.path)
		dispatch(userActions.setActiveRole(role.label))
	}

	return activeRole ? <Select roles={defaultRoles} activeRole={activeRoleObj} onChangeRole={onChangeRole} /> : <></>
}
