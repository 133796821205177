import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './LaborActivities.module.scss'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import LaborRecordsSearch from '@features/laborRecords/components/Search/LaborRecordsSearch'
import { useDispatch, useSelector } from 'react-redux'
import { laborRecordsSelectors, laborRecordsThunks } from '@/entity/laborRecords'
import { TotalWorkBox } from '@features/laborRecords/components/Box/TotalWorkBox'
import { dateSort } from '@features/laborRecords/utils'
import { Activities } from '@features/laborRecords/frame/Activities/Activities'
import { LoadingScreen } from '@/components/Loader'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import { DigitizationModal } from './actions/digitalization'

const initialFilterState = {
	bin: '',
	srcText: '',
	dateFrom: '',
	dateTo: '',
	orgName: '',
	withPhoto: false,
}
export const LaborActivities = ({ tabControlRef }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isOpenModal, setOpenModal] = useState(false)
	const [filters, setFilters] = useState(initialFilterState)
	const isSuccess = useSelector(laborRecordsSelectors.isSuccess)
	const isLoading = useSelector(laborRecordsSelectors.isLoading)
	const isError = useSelector(laborRecordsSelectors.isError)
	const laborRecords = useSelector(laborRecordsSelectors.laborRecords)
	const isAdjustmentXmlSuccess = useSelector(laborRecordsSelectors.isAdjustmentXmlSuccess)
	const isLocalDigitContractAdded = useSelector(laborRecordsSelectors.isLocalDigitContractAdded)
	const isRefreshContracts = useSelector(laborRecordsSelectors.isRefreshContracts)
	const [experience, setExperience] = useState(null)
	const [contractCount, setContractCount] = useState(0)
	const [contractList, setContractList] = useState([])
	const [loading, setLoading] = useState(false)
	const fetchData = useCallback(
		(_filters = filters) => {
			dispatch(laborRecordsThunks.getLaborRecords({ ..._filters }))
		},
		[dispatch, filters, isRefreshContracts]
	)

	const resetFilter = useCallback(() => {
		setFilters(() => ({ ...initialFilterState }))
		fetchData({
			...initialFilterState,
		})
	}, [fetchData])

	useEffect(() => fetchData(), [isRefreshContracts])

	useEffect(() => {
		if (isSuccess) {
			let { experience, contractCount, laborCount, contractList, laborDigitalizationList, laborDigitalizationCount } =
				laborRecords
			setExperience(!experience ? 0 : experience)
			setContractCount(
				(!contractCount ? 0 : contractCount) + (!laborCount ? 0 : laborCount) + laborDigitalizationCount ?? 0
			)
			setContractList([
				...(contractList ?? []),
				...(laborDigitalizationList ?? []).map((obj) => ({ ...obj, digitalization: 'true' })),
			])
			setLoading(true)
		}
	}, [isSuccess, laborRecords])

	useEffect(() => {
		if (isAdjustmentXmlSuccess) tabControlRef?.current?.onChangeActiveTab('correction_requests_tab')
	}, [isAdjustmentXmlSuccess])

	useEffect(() => {
		if (isLocalDigitContractAdded) tabControlRef?.current?.onChangeActiveTab('unsigned_records_tab')
	}, [isLocalDigitContractAdded])

	const frame = useMemo(
		() =>
			contractList !== null ? (
				dateSort(contractList)?.map((contract) => <Activities key={contract.id} contract={contract}></Activities>)
			) : (
				<div className={styles.info}>{t('labor_records.info_no_records')}</div>
			),
		[contractList, t]
	)

	return (
		<div className={styles.wrapper}>
			{/*Комментируется оцифровка 15.05.2023*/}
			{/*<Button buttonClassName={styles.wrapper__button} icon={addWhiteIcon} onClick={() => setOpenModal(true)}>
				{t('labor_records.add_record')}
			</Button>*/}
			{isOpenModal && <DigitizationModal onClose={() => setOpenModal(false)} />}
			<LaborRecordsSearch
				filters={filters}
				setFilters={setFilters}
				resetFilter={resetFilter}
				fetchData={fetchData}
			></LaborRecordsSearch>
			{loading && !!contractList ? (
				<TotalWorkBox experience={experience} contractCount={contractCount}></TotalWorkBox>
			) : null}
			{isLoading && !isError ? (
				<LoadingScreen>
					<Title>
						{' '}
						{t('wait')} <br />
						{t('labor_records.loading')}
					</Title>
				</LoadingScreen>
			) : (
				frame
			)}
			{isError ? <div className={styles.error}>{t('has_error')}</div> : null}
		</div>
	)
}
