import { useTranslation } from 'react-i18next'
import { ConfirmModal, Title } from '@/ui'
import styles from './SecurityModal.module.scss'

export const SecurityModal = ({ onProcess, onCancel, isLoading, contract }) => {
	const { t } = useTranslation()
	const { contractNumber, dateFrom, isLocalContract } = contract || {}

	return (
		<ConfirmModal
			onProcess={onProcess}
			onCancel={onCancel}
			cancelButtonTitle={'no_cancel'}
			processButtonTitle={isLocalContract ? 'delete_yes' : 'contracts_tab.remove.process_button_title'}
			hideBackButton
			priority={20}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<Title>{t('contracts_tab.remove.title')}</Title>
				<p>{t('question_delete_project_etd')}</p>
				{/* <p
					dangerouslySetInnerHTML={{
						__html: t('contracts_tab.remove.are_you_sure_no_recovery', { contractNumber, dateFrom }),
					}}
				/> */}
			</div>
		</ConfirmModal>
	)
}
