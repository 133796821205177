import {
	View as ViewIcon,
	Sign as SignIcon,
	Remove as RemoveIcon,
	Reject as RejectIcon,
	Edit as EditIcon,
} from '@app/images'
import { StateCode, inProcess } from './config'

export const onECollectiveActionMenuClick = (row, e, setActionMenuItems, t, isTradeUnion) => {
	const stateCode = row.state?.code
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}

	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]

	/*	if (isTradeUnion) {
		if (inProcess(stateCode)) {
			actions = [
				...actions,
				{ icon: SignIcon, title: t('actions.sendEmployer'), action: 'sendEmployer' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
			]
		}

		if (StateCode.REJECT_EMPLOYER === stateCode) {
			actions = [...actions, { icon: RemoveIcon, title: t('actions.remove'), action: 'remove' }]
		}

		if (StateCode.WAIT_UNION_SIGN === stateCode) {
			actions = [
				...actions,
				{ icon: SignIcon, title: t('actions.sign'), action: 'sign' },
				{ icon: RejectIcon, title: t('actions.reject'), action: 'reject' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
			]
		}
	} else {
		if (StateCode.WAIT_EMPLOYER_SIGN === stateCode) {
			actions = [
				...actions,
				{ icon: SignIcon, title: t('actions.sign'), action: 'sign' },
				{ icon: EditIcon, title: t('actions.revision'), action: 'revision' },
				{ icon: RejectIcon, title: t('actions.reject'), action: 'reject' },
			]
		}
	}*/

	setActionMenuItems(actions)
}
