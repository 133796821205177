import { Add as AddIcon, Edit as EditIcon, View as ViewIcon, Remove as RemoveIcon } from '@app/images'

const onProjectActionMenuClick = (row, e, setActionMenuItems, t) => {
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [
		{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
		{ icon: AddIcon, title: t('actions.create'), action: 'create' },
		{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
		{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
	]
	setActionMenuItems(actions)
}

export default onProjectActionMenuClick
