import React from 'react'
import styles from '../GeneralInformation.module.scss'
const Wrapper = ({ title, description, percent = null, color = 'green' }) => {
	return (
		<div className={styles.block}>
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description}</div>
			{percent && <div className={`${styles.percent} ${styles[color]}`}>{percent}</div>}
		</div>
	)
}
export default Wrapper
