import { View as ViewIcon, Confirm } from '@app/images'

export default function onRequestActionMenuClick(row, e, setActionMenuItems, t) {
	const status = row.status.code
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (status === '1' || status === '2') {
		actions.splice(1, 0, { icon: Confirm, title: t('actions.edit'), action: 'edit' })
	}
	setActionMenuItems(actions)
}
