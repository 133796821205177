import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccessVerified: false,
	isSuccessSentCode: false,
	user: null,
	error: '',
	resetPasswordError: '',
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS_VERIFIED:
			return {
				...state,
				isSuccessVerified: payload,
			}
		case types.SET_USER:
			return {
				...state,
				user: payload,
			}
		case types.SET_ERROR:
			return {
				...state,
				error: payload,
			}
		case types.SET_RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPasswordError: payload,
			}
		case types.SET_SUCCESSFUL_SENT_CODE:
			return {
				...state,
				isSuccessSentCode: payload,
			}
		default:
			return state
	}
}
