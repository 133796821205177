import { request } from '@/shared/api/request'

export const sendSignedXml = (payload) => {
	return request({
		url: 'colcontract/sendContract',
		method: 'POST',
		data: payload,
		headers: {
			'Content-Type': 'text/plain',
		},
	})
}
