export const TOGGLE_LOADING = 'personalAffairs/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'personalAffairs/TOGGLE_SUCCESS'
export const SET_AFFAIRS = 'personalAffairs/SET_AFFAIRS'
export const SET_EMPLOYEE_CARD = 'personalAffairs/SET_EMPLOYEE_CARD'
export const SET_EMPLOYEE_AFFAIRS = 'personalAffairs/SET_EMPLOYEE_AFFAIRS'
export const SET_ERROR = 'personalAffairs/SET_ERROR'
export const SET_SELECTED_AFFAIR = 'personalAffairs/SET_SELECTED_AFFAIR'
export const SET_EMPLOYER_REQUESTS = 'personalAffairs/SET_EMPLOYER_REQUESTS'
export const SET_SELECTED_REQUEST = 'personalAffairs/SET_SELECTED_REQUEST'
export const SET_EMPLOYEE_REQUESTS = 'personalAffairs/SET_EMPLOYEE_REQUESTS'

