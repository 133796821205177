import { request } from '@/shared/api/request'

export const getTransfersList = (params) => {
	return request({ url: '/contract/branchTransfer/get/page', method: 'GET', params })
}

export const getTransfers = (id) => {
	return request({ url: `/contract/branchTransfer/get/detail?size=999999&reqId=${id}`, method: 'GET' })
}

export const sendSelected = (data) => {
	return request({ url: '/contract/branchTransfer/save/approvePart', method: 'POST', data })
}

export const rejectSelected = (data) => {
	return request({ url: '/contract/branchTransfer/save/rejectPart', method: 'POST', data })
}

export const sendAdditionalAgreement = (data, params) => {
	return request({ url: '/contract/branchTransfer/send/additional', method: 'POST', data, params })
}

export const getContractDetail = (externalId, params) => {
	return request({ url: `/contract/branchTransfer/get/contract/${externalId}`, method: 'GET', params })
}

export const getDataByIin = (iin, bin) => {
	return request({ url: `/contract/branchTransfer/requestContractData?iin=${iin}&bin=${bin}`, method: 'GET' })
}
