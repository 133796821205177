import { useTranslation } from 'react-i18next'
import block1 from '../../../app/images/employer_ico_6.svg'
import block4 from '../../../app/images/employer_ico_3.svg'
import block2 from '../../../app/images/employer_ico_7.svg'
import block5 from '../../../app/images/employer_ico_8.svg'
import GreenLine from '@app/images/employerLine.svg'
import GreenRectangleLeft from '@app/images/employerRectangleLeft.svg'
import Slider from 'react-slick'

const array = [
	{
		icon: block1,
		title: 'government_unemployment_support',
		body: 'termination_employment',
	},
	{
		icon: block4,
		title: 'control_hr_documents',
		body: 'managing_documents_security',
	},
	{
		icon: block1,
		title: 'receive_state_support',
		body: 'use_opportunity',
	},
	{
		icon: block2,
		title: 'save_time_collecting_documents',
		body: 'manage_personal_documents',
	},
	{
		icon: block5,
		title: 'get_automated_services',
		body: 'confirm_your_employment',
	},
]
const settings = {
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipeToSlide: true,
	responsive: [
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
}

const Worker = () => {
	const { t } = useTranslation()
	return (
		<div className="worker" id="worker">
			<div className="container">
				<div className="home">
					<div className="green-line">
						<img src={GreenLine} />
					</div>
					<div className="home-block_1">
						<h2 className="title">{t('for_worker')}</h2>
						<p>{t('based_entered_data_employment')}</p>
						<div className="home-block_1-item">
							<img src={block1} />
							<div className="home-block_1-item-frame">
								<p>{t('government_unemployment_support')}</p>
								<span>{t('termination_employment')}</span>
							</div>
						</div>
						<div className="home-block_2-item">
							<img src={block2} />
							<div className="home-block_1-item-frame">
								<p>{t('save_time_collecting_documents')}</p>
								<span>{t('manage_personal_documents')}</span>
							</div>
						</div>
					</div>
					<div className="home-block_2">
						<div className="home-block_1">
							<div className="home-block_1-item">
								<img src={block4} />
								<div className="home-block_1-item-frame">
									<p>{t('control_hr_documents')}</p>
									<span>{t('managing_documents_security')}</span>
								</div>
							</div>
							<div className="home-block_1-item">
								<img src={block1} />
								<div className="home-block_1-item-frame">
									<p>{t('receive_state_support')}</p>
									<span>{t('use_opportunity')}</span>
								</div>
							</div>
							<div className="home-block_2-item">
								<img src={block5} />
								<div className="home-block_1-item-frame">
									<p>{t('get_automated_services')}</p>
									<span>{t('confirm_your_employment')}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="home-block_3">
						<div className="home-block_1">
							<div className="home-block_1-item">
								<img src={block2} />
								<div className="home-block_1-item-frame">
									<p>{t('save_time_collecting_documents')}</p>
									<span>{t('manage_personal_documents')}</span>
								</div>
							</div>
							<div className="home-block_1-item">
								<img src={block5} />
								<div className="home-block_1-item-frame">
									<p>{t('get_automated_services')}</p>
									<span>{t('confirm_your_employment')}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="home-block_column">
						<h2 className="title">{t('for_worker')}</h2>
						<p>{t('based_entered_data_employment')}</p>
						<div className="home-slider">
							<Slider {...settings}>
								{array.map((arr, index) => {
									return (
										<div key={arr.title} className="home-slider-block">
											<div className="home-block_1-item">
												<img src={arr.icon} />
												<div className="home-block_1-item-frame">
													<p>{t(arr.title)}</p>
													<span>{t(arr.body)}</span>
												</div>
											</div>
										</div>
									)
								})}
							</Slider>
						</div>
					</div>
				</div>
			</div>
			<div className="green-rectangle-block">
				<img src={GreenRectangleLeft} />
			</div>
		</div>
	)
}

export default Worker
