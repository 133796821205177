import { useTranslation } from 'react-i18next'
import { ConfirmModal, Title } from '@/ui'
import styles from './SecurityModal.module.scss'

export const SecurityModal = ({ onProcess, onCancel, isLoading, contract }) => {
	const { t } = useTranslation()
	const { isLocalContract } = contract || {}

	return (
		<ConfirmModal
			onProcess={onProcess}
			onCancel={onCancel}
			cancelButtonTitle={'no_cancel'}
			processButtonTitle={isLocalContract ? 'delete_yes' : 'contracts_tab.remove.process_button_title'}
			hideBackButton
			priority={2}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<Title>{t('contracts_tab.remove_contract.title')}</Title>
				<p>{t('contracts_tab.remove_contract.are_you_sure_no_recovery')}</p>
			</div>
		</ConfirmModal>
	)
}
