import React from 'react'
import styles from './ModalWS.module.scss'

export const ModalWS = ({
	onClose = function () {},
	windowClassName = '',
	zIndex = 1002,
	overflowY = 'scroll',
	children,
	onClickDisable = false,
}) => {
	const notPropogate = React.useCallback((event) => event.stopPropagation(), [])

	return (
		<div className={styles.screen} onClick={onClickDisable ? null : onClose} style={{ zIndex, overflowY }}>
			<div className={`${styles.window} ${windowClassName}`} onClick={notPropogate}>
				{children}
			</div>
		</div>
	)
}
