import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm } from 'formik'
import styles from './StaffingFirstStructure.module.scss'
import { formFieldNames } from './formFieldNames'
import { Button, Field, Title } from '@/ui'

export const FirstForm = ({ onClose, isEdit }) => {
	const { t } = useTranslation()
	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<Title>{t('staff_modal.staff_information')}</Title>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<FormikField name={formFieldNames.orderNumber}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									label={t('staff_modal.order_number')}
									placeholder={t('staff_modal.order_number_placeholder')}
									isRequired
									error={touched && error}
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.orderDate}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="datePicker"
									label={t('staff_modal.order_date')}
									isRequired
									placeholder={t('default_date_value')}
									error={touched && error}
									{...field}
								/>
							)}
						</FormikField>
					</div>
					{isEdit ? (
						<>
							<FormikField name={formFieldNames.nameKz}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										label={t('staff_modal.structure_name_kz')}
										placeholder={t('staff_modal.structure_name_placeholder_kz')}
										error={touched && error}
										{...field}
										disabled
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.nameRu}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										label={t('staff_modal.structure_name')}
										placeholder={t('staff_modal.structure_name_placeholder')}
										error={touched && error}
										{...field}
										disabled
									/>
								)}
							</FormikField>
						</>
					) : null}
					<FormikField name={formFieldNames.note}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'textarea'}
								label={t('staff_modal.comment')}
								placeholder={t('staff_modal.comment_placeholder')}
								error={touched && error ? t(error) : ''}
								showCount
								maxLength={4000}
								rows={3}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
