export const TOGGLE_LOADING = 'eCollectiveContracts/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'eCollectiveContracts/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'eCollectiveContracts/SET_CONTRACT_ERROR'
export const SET_E_COL_CONTRACTS = 'eCollectiveContracts/SET_E_COL_CONTRACTS'
export const SET_E_COL_CONTRACT = 'eCollectiveContracts/SET_E_COL_CONTRACT'
export const SET_E_COL_CONTRACT_LOADING = 'eCollectiveContracts/SET_E_COL_CONTRACT_LOADING'
export const SET_E_COL_CONTRACT_SUCCESS = 'eCollectiveContracts/SET_E_COL_CONTRACT_SUCCESS'
export const SET_E_COL_CONTRACT_ERROR = 'eCollectiveContracts/SET_E_COL_CONTRACT_ERROR'
export const SET_INITIAL_E_COL_CONTRACT = 'eCollectiveContracts/SET_INITIAL_E_COL_CONTRACT'
export const SET_E_COL_XML = 'eCollectiveContracts/SET_E_COL_XML'
export const SET_E_HTML = 'eCollectiveContracts/SET_E_HTML'
export const SET_E_HTML_SUCCESS = 'eCollectiveContracts/SET_E_HTML_SUCCESS'
export const RESET_E_COL_CONTRACT = 'eCollectiveContracts/RESET_E_COL_CONTRACT'
export const SET_CURRENT_ECD = 'eCollectiveContracts/SET_CURRENT_ECD'
export const RESET_CURRENT_ECD = 'eCollectiveContracts/RESET_CURRENT_ECD'
export const SET_CURRENT_ECD_EDIT = 'eCollectiveContracts/SET_CURRENT_ECD_EDIT'
export const SET_E_COL_CONTRACT_SEND_SUCCESS = 'eCollectiveContracts/SET_E_COL_CONTRACT_SEND_SUCCESS'
