import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './RemovePosition.module.scss'

export const RemovePosition = ({ onSubmit, onClose }) => {
	const { t } = useTranslation()

	return (
		<ModalTemplate onClose={onClose} hideBackButton={true}>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{t('staff.del_current_position')}</Title>
			</div>
			<div className={styles.buttonStyle}>
				<Button type="submit" onClick={onSubmit}>
					{t('staff_modal.yes')}
				</Button>
				<Button type="bare" onClick={onClose}>
					{t('staff_modal.no')}
				</Button>
			</div>
		</ModalTemplate>
	)
}
