import { Title } from '@/ui'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './../PensionPayments.module.scss'
import {getCurrentLanguage} from "@/i18next";
export const Applicant = ({ newRecord, item, pension, consentAndPhoneData, activeCompany }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	return (
		<>
			<div className={styles.title}>
				<Title>{t('pension.payments.form.labels.applicant_info')}</Title>
			</div>
			<div className={styles.container}>
				<div className="flex-1">
					<Box display="flex" alignItems="flex-start">
						<table className={styles.table}>
							<tbody>
								<tr>
									<td>{t('pension.payments.form.labels.iin')}:</td>
									<td>{item.iin}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.fio')}:</td>
									<td className={styles.lowercase}>
										<div className={styles.capitalize}>{item.surname} </div>
										<div className={styles.capitalize}> {item.name} </div>
										<div className={styles.capitalize}> {item.middleName}</div>
									</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.date_of_birth')}:</td>
									<td>{item.birthDate}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.sex')}:</td>
									<td>{lang === 'rus' ? item?.sex?.nameRu : item?.sex?.nameKz}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.phone')}:</td>
									<td>
										<span
											style={{
												color: (newRecord ? consentAndPhoneData.phoneData.hasPhone : pension.data.hasPhone)
													? 'black'
													: 'red',
											}}
										>
											{item.phone}
										</span>
									</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.place_of_work')}:</td>
									<td>{activeCompany.companyName}</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</div>
			</div>
		</>
	)
}
