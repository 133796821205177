import React from 'react'
import { Button } from '@/ui'
import styles from '../styles.module.scss'
import { CompareArrowsIcon } from '@app/icons'
import { useTranslation } from 'react-i18next'

export const TranslationRequestsEmpty = ({ onClick }) => {
	const { t } = useTranslation()
	const isMobile = window.innerWidth < 768
	return (
		<>
			<div className={styles.empty_message}>
				{t('transfer_list_empty_message')}
			</div>
			<div>
				<Button disabled={isMobile} onClick={onClick} icon={<CompareArrowsIcon fill="#FFFFFF" />}>
					{t('transfer_request_btn')}
				</Button>
			</div>
		</>
	)
}
