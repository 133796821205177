export const headCells = [
	{
		id: 'fio',
		numeric: false,
		label: 'staffing_table.fio',
	},
	{
		id: 'substitute_fio',
		numeric: false,
		label: 'staffing_table.substitute_fio',
	},
	{
		id: 'positions_combination',
		numeric: false,
		label: 'positions_combination',
	},
	{
		id: 'salary',
		numeric: true,
		label: 'workplace.tariff_rate',
	},
	{
		id: 'tariff_rate',
		numeric: true,
		label: 'staffing_table.sum',
	},
	{
		id: 'start_contract_date',
		numeric: false,
		label: 'contracts_tab.start_contract_date',
	},
	{
		id: 'end_contract_date',
		numeric: false,
		label: 'contracts_tab.end_contract_date',
	},
	{
		id: 'status',
		numeric: false,
		label: 'staffing_table.status',
	},
	{
		id: 'action',
		numeric: false,
		label: 'staffing_table.action',
	},
]
