import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleLoadingSend: (payload) => ({
		type: types.TOGGLE_LOADING_SEND,
		payload,
	}),
	toggleSuccessSend: (payload) => ({
		type: types.TOGGLE_SUCCESS_SEND,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setAccidents: (payload) => ({
		type: types.SET_ACCIDENTS,
		payload,
	}),
	setReportXml: (payload) => ({
		type: types.SET_REPORT_XML,
		payload,
	}),
	setAccident: (payload) => ({
		type: types.SET_ACCIDENT,
		payload,
	}),
	resetReportXml: () => ({
		type: types.RESET_REPORT_XML,
	}),
}
