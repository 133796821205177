import React, { useMemo } from 'react'
import styles from './Medicine.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import { DataGridTable } from '@/ui'
import { getData } from './utils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

function Medicine({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const { t } = useTranslation()

	const medicineData = useMemo(() => getData(data.data, t), [data.data])

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_009')}</p>
					</div>
				)}
				{!data.data.methodDataError && (
					<p>
						<DataGridTable
							hideSubTitle
							information={medicineData}
							hideTitle
							titleClass={styles.tableTitleCell}
							rowClass={styles.dataRow}
						/>
					</p>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default Medicine
