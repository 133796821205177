export const headCells = [
	{
		id: 'contractNumber',
		numeric: false,
		disablePadding: true,
		label: 'contracts_tab.contract_employer_number',
	},
	{
		id: 'companyName',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'establishedPost',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.position',
	},
	{
		id: 'registerDate',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.registration_date',
	},
	{
		id: 'dateFrom',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.conclusion_date',
	},
	{
		id: 'dateTo',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.expired_date',
	},
	{
		id: 'dcontractStatus.nameRu',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.status',
	},
	{
		id: 'state',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.state',
	},
	{
		id: 'isElectronic',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.type',
	},
	{
		id: 'action',
		numeric: true,
		disablePadding: false,
		label: 'contracts_tab.action',
	},
	{
		id: 'signatureState',
		numeric: false,
		disablePadding: false,
		label: '',
	},
]
