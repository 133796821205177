import Box from '@mui/material/Box'
import { Clock, Success, CrossRounded, Dots } from '@app/icons'
import styles from './contractsTable.module.scss'
import { useTranslation } from 'react-i18next'

const statuses = {
	1: {
		icon: <Success className={styles.status__icon__success} />,
		wrapperClassName: styles.status__label__success,
	},
	2: {
		icon: <Success className={styles.status__icon__success} />,
		wrapperClassName: styles.status__label__success,
	},
	4: {
		icon: <Clock className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
	6: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	7: {
		icon: <Dots className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
	8: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	9: {
		icon: <Clock className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
	10: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	11: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	12: {
		icon: <CrossRounded className={styles.status__icon__error} />,
		wrapperClassName: styles.status__label__error,
	},
	13: {
		icon: <Clock className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
	15: {
		icon: <Clock className={styles.status__icon} />,
		wrapperClassName: styles.status__label,
	},
}

const lang = {
	rus: 'nameRu',
	kz: 'nameKz',
}

export const Status = ({ status }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const statusElement = statuses[status.code] ?? statuses[1]
	return (
		<Box display="flex" alignItems="center">
			<Box>{statusElement.icon ?? statusElement.icon}</Box>
			<Box className={statusElement.wrapperClassName}>{language == 'rus' ? status.nameRu : status.nameKz}</Box>
		</Box>
	)
}
