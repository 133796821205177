export const colors = {
    error: '#EB5757',
    invalid: '#EB5757',
    pending: '#AAB8D1',
    processing: '#AAB8D1',
    successful: '#17B67C',
    timeout: '#AAB8D1',
    partsuccessful: '#17B67C'
}
export const subcolors = {
    "0": '#AAB8D1',
    "1": '#AAB8D1',
    "2": '#AAB8D1',
    "3": '#AAB8D1',
    "4": '#17B67C',
    "5": '#AAB8D1',
    "6": '#EB5757',
    "7": '#AAB8D1',
    "8": '#EB5757',
}
