export const cookies = {
	cookie: document.cookie,
	get: function (key, isPart) {
		if (isPart) {
			const exactedCookies = document.cookie.split('; ')?.find((item) => item.includes(key))
			const parsedCookies = exactedCookies?.split('=')

			return {
				key: parsedCookies?.[0],
				value: parsedCookies?.[1],
			}
		}
		const match = document.cookie.match(new RegExp('(^| )' + key + '=([^;]+)'))
		if (match) return match[2]
	},
	remove: function (key) {
		this.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
	},
	set: function (name, value, options = {}) {
		options = {
			path: '/',
			...options,
		}

		if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString()
		}

		let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

		for (let optionKey in options) {
			updatedCookie += '; ' + optionKey
			let optionValue = options[optionKey]
			if (optionValue !== true) {
				updatedCookie += '=' + optionValue
			}
		}

		document.cookie = updatedCookie
	},
}
