import React from 'react';

export const RefreshIcon = ({ className = "" }) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.5569 2.955L13.5941 3.69C12.2813 2.05167 10.2396 1 7.94696 1C3.98818 1 0.783936 4.125 0.778815 7.99167C0.773693 11.8617 3.98476 15 7.94696 15C11.0419 15 13.6794 13.0833 14.6832 10.3983C14.7088 10.3283 14.6713 10.25 14.5996 10.2267L13.6316 9.90167C13.5979 9.89037 13.561 9.89242 13.5287 9.90737C13.4965 9.92232 13.4716 9.94899 13.4592 9.98167C13.4285 10.065 13.3943 10.1483 13.3585 10.23C13.0632 10.9133 12.6398 11.5267 12.1004 12.0533C11.5653 12.5767 10.9317 12.9944 10.2345 13.2833C9.51238 13.5817 8.74247 13.7333 7.95038 13.7333C7.15657 13.7333 6.38837 13.5817 5.66627 13.2833C4.96836 12.9956 4.33459 12.5778 3.8004 12.0533C3.26385 11.531 2.83653 10.9117 2.54226 10.23C2.23669 9.52333 2.08134 8.77333 2.08134 7.99833C2.08134 7.22333 2.23669 6.47333 2.54226 5.76667C2.83759 5.08333 3.26095 4.47 3.8004 3.94333C4.33984 3.41667 4.96806 3.00333 5.66627 2.71333C6.38837 2.415 7.15828 2.26333 7.95038 2.26333C8.74418 2.26333 9.51238 2.415 10.2345 2.71333C10.9324 3.00108 11.5662 3.41886 12.1004 3.94333C12.2694 4.10833 12.4281 4.28333 12.5749 4.46667L11.5473 5.25C11.5269 5.26536 11.5114 5.28601 11.5026 5.30957C11.4937 5.33314 11.4919 5.35866 11.4972 5.38321C11.5026 5.40776 11.5149 5.43033 11.5328 5.44834C11.5507 5.46635 11.5735 5.47906 11.5985 5.485L14.5961 6.20167C14.6815 6.22167 14.7652 6.15833 14.7652 6.07333L14.7788 3.05833C14.7771 2.94833 14.6457 2.88667 14.5569 2.955Z"
    />
  </svg>
    ;
};
