import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TableSign.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { headCells } from './table.headings'
import { Dots, Success } from '@app/icons'
export const TableSign = ({ adContract }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const statusEmployee = {
		dcontractStatus: {
			nameRu: adContract.signEmployeeDate ? 'Подписан' : 'Ожидает подписи',
			nameKz: adContract.signEmployeeDate ? 'Қол қойылған' : 'Қолын күтуде',
		},
	}

	const statusEmployer = {
		dcontractStatus: {
			nameRu: adContract.signEmployerDate ? 'Подписан' : 'Ожидает подписи',
			nameKz: adContract.signEmployerDate ? 'Қол қойылған' : 'Қолын күтуде',
		},
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<>
			<div className={styles.status__title}>{t('electronic_contract.status_sign_eds')}</div>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>
					<tr>
						<td>{t('employee')}</td>
						<td>{adContract.iin || '-'}</td>
						<td>{adContract.fullName || '-'}</td>
						<td>{adContract.signEmployeeDate || '-'}</td>
						<td>
							{adContract.signEmployeeDate ? (
								<Success className={styles.iconSuccess} />
							) : (
								<Dots className={styles.iconClock} />
							)}
							<span className={adContract.signEmployeeDate ? styles.textSuccess : styles.textClock}>
								{lang === 'rus' ? statusEmployee.dcontractStatus.nameRu : statusEmployee.dcontractStatus.nameKz}
							</span>
						</td>
					</tr>
					<tr>
						<td>{t('employer')}</td>
						<td>{adContract.bin || '-'}</td>
						<td>{adContract.employerName || '-'}</td>
						<td>{adContract.signEmployerDate || '-'}</td>
						<td>
							{adContract.signEmployerDate ? (
								<Success className={styles.iconSuccess} />
							) : (
								<Dots className={styles.iconClock} />
							)}
							<span className={adContract.signEmployerDate ? styles.textSuccess : styles.textClock}>
								{lang === 'rus' ? statusEmployer.dcontractStatus.nameRu : statusEmployer.dcontractStatus.nameKz}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}
