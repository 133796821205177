import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styles from './RequestTerminateForm.module.scss'
import { Field, Button, Title } from '@/ui'
import { requestRemoveSchema } from './RequestRemove.schema'
import { Formik, Form, Field as FormikField } from 'formik'
import { ViewConfirmDocument } from '@features/laborRecords/components/document/ViewConfirmDocument'
import { ConfirmDocument } from '@features/laborRecords/components/document/ConfirmDocument'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { AdditionalInfoField } from './fields'

export const RequestRemoveForm = ({ shortContractData, onSubmit, isView, onClose, onToggleContractAction }) => {
	const { t } = useTranslation()
	const activeRole = useSelector(userSelectors.activeRole)
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const { contractId, phone, email, comment, note, docList, requestStatus } = shortContractData
	const [documentList, setDocList] = useState([])
	const getDocument = (docList) => {
		setDocList(docList)
	}
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}

	const handleDataProcess = (data) => {
		const toSubmit = {
			deleteContractId: contractId,
			comment: data.comment,
			contractId: contractId,
			email: data.email,
			phone: data.phone,
		}
		if (documentList.length > 0) toSubmit.docList = documentList
		onSubmit(toSubmit)
	}
	const actionConfirm = () => {
		onToggleContractAction('confirm', true)
	}
	const actionReject = () => {
		onToggleContractAction('reject', true)
	}
	return (
		<div className={styles.wrapper}>
			<Formik
				initialValues={{
					phone: isView ? phone : '',
					email: isView ? email : '',
					comment: isView ? comment : '',
					note: isView ? note : '',
				}}
				onSubmit={handleDataProcess}
				validationSchema={requestRemoveSchema}
				enableReinitialize
			>
				{({ isValid, values }) => {
					return (
						<Form>
							{isView && docList ? (
								<div>
									<Title>{t('labor_records.confirming_document')}</Title>
									<ViewConfirmDocument data={docList}></ViewConfirmDocument>
								</div>
							) : null}
							{!isView ? (
								<div>
									<Title>{t('labor_records.confirming_document_no_required')}</Title>
									<ConfirmDocument getDocument={getDocument} getErrorSize={getErrorSize}></ConfirmDocument>
								</div>
							) : null}
							<div className={styles.wrapper}>
								<Title>{t('labor_records.additional_information')}</Title>
								<AdditionalInfoField isView={isView}></AdditionalInfoField>
								{isView ? (
									<>
										{requestStatus?.code === '1' ? null : (
											<div className={styles.answer}>
												<Title>{t('labor_records.note')}</Title>
												<FormikField name="note">
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType={'textarea'}
															isRequired
															label={t('labor_records.comment')}
															error={touched && error ? t(error) : ''}
															{...field}
															disabled
															labelClassName={styles.field}
														/>
													)}
												</FormikField>
											</div>
										)}
										<div className={styles.cancel__view}>
											{activeRole !== 'ROLE_EMPLOYEE' ? (
												<>
													<Button onClick={actionConfirm} disabled={!(requestStatus?.code === '1')}>
														{t('labor_records.confirm')}
													</Button>
													<Button onClick={actionReject} disabled={!(requestStatus?.code === '1')}>
														{t('labor_records.reject')}
													</Button>
												</>
											) : null}
											<Button onClick={onClose}>{t('close')}</Button>
										</div>
									</>
								) : (
									<div className={styles.actions}>
										<div className={styles.cancel__button} onClick={onClose}>
											{t('labor_records.cancel')}
										</div>
										<Button
											disabled={
												disableErrorSize ||
												!isValid ||
												values.comment === '' ||
												values.phone === '' ||
												values.email === ''
											}
											type="submit"
										>
											{t('sign_and_send')}
										</Button>
									</div>
								)}
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}
