import React, { useMemo, useRef, useCallback } from 'react'
import { useToggle, useClickOutside } from '@/shared'
import { Options } from '@/ui/atoms'
import styles from './style.module.scss'
import { InputWithIcon } from '@/ui'
import { Arrow } from '@app/icons'
import { getCurrentLanguage } from '@/i18next'

export const MultiSelect = ({ options = [], placeholder, value = [], onChange, onBlur }) => {
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const selectRef = useRef(null)
	const currentLang = getCurrentLanguage()
	const onSelect = useCallback(
		(code) => {
			const val = value ? value : []
			if (val.includes(code)) {
				onChange(val.filter((item) => item !== code))
			} else {
				onChange([...val, code])
			}
		},
		[onChange, value]
	)

	const onClickOutside = useCallback(
		(event) => {
			event.target.blur()
			toggleOpenedOptions()
		},
		[toggleOpenedOptions]
	)

	const values = useMemo(() => {
		const optionLang = currentLang === 'rus' ? 'nameRu' : 'nameKz'
		return options
			?.filter(({ code }) => value.includes(code))
			?.map((option) => option[currentLang] || option[optionLang])
			?.join(', ')
	}, [value, options, currentLang])

	useClickOutside(selectRef, isOpenedOptions, onClickOutside)

	return (
		<div className={styles.select} ref={selectRef}>
			<InputWithIcon
				readOnly
				type="select"
				value={values}
				onClick={toggleOpenedOptions}
				placeholder={placeholder}
				iconSide={'right'}
				icon={
					<Arrow
						styles={{ fill: '#AAB8D1', transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`, width: '10px' }}
					/>
				}
			/>
			{isOpenedOptions && (
				<Options options={options} showItems={5} onSelect={onSelect} actives={value} multiSelect onBlur={onBlur} />
			)}
		</div>
	)
}
