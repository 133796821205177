import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formsSelectors, formsThunks } from '@/entity/forms'
import { QuestionModal } from '@pages/Forms/Tabs/Checks/component/Сonfirmation/QuestionModal'
import { Theme } from '@/components/SelfTest/component/Theme'
import { getCurrentLanguage } from '@/i18next'

export const SelfTest = forwardRef(({ themes, createTitle, sectionCode, activeSection }, ref) => {
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const selectActiveTheme = useSelector(formsSelectors.theme)
	const statusCheckQuestion = useSelector(formsSelectors.statusCheck)
	const [isActive, setIsActive] = useState(false)
	const [tmpActiveLabel, setTmpActiveLabel] = useState()
	const [tmpCode, setTmpCode] = useState()
	const handleModalCancelClick = useCallback(() => setIsActive(false), [])

	useEffect(() => {
		if (activeSection !== null) onChangeActiveTheme(activeSection, true)
	}, [activeSection])

	const handleModalOnClick = () => {
		dispatch(formsThunks.setActiveTheme(tmpCode, tmpActiveLabel))
		dispatch(formsThunks.searchQuestions(tmpCode))
		dispatch(formsThunks.setStatusCheck(false))
		dispatch(formsThunks.setStatusSelect(false))
		createTitle(tmpActiveLabel)
		sectionCode(tmpCode)
		setIsActive(false)
	}

	const onChangeActiveTheme = (theme, replay) => {
		if (!replay && selectActiveTheme != null && selectActiveTheme !== theme.code && statusCheckQuestion) {
			setIsActive(true)
			setTmpCode(theme.code)
			setTmpActiveLabel(theme?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-')
		} else {
			createTitle(theme)
			sectionCode(theme.code)
			dispatch(formsThunks.setActiveTheme(theme.code, theme?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'))
			dispatch(formsThunks.searchQuestions(theme.code))
			dispatch(formsThunks.setStatusCheck(false))
		}
	}

	useImperativeHandle(ref, () => ({
		onChangeActiveTheme,
	}))

	return (
		<>
			{isActive && (
				<QuestionModal
					cancelButtonTitle={'cancel'}
					handleProcess={handleModalOnClick}
					onCancel={handleModalCancelClick}
				></QuestionModal>
			)}
			<Theme themes={themes} activeTheme={selectActiveTheme} onChange={onChangeActiveTheme} />
		</>
	)
})
