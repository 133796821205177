import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from './Form'

import { accidentActions, AccidentReportValidationSchema, accidentSelectors, accidentThunks } from '@/entity'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { useDigitalSign } from '@/shared'

export const AccidentReport = ({ onClose }) => {
	const dispatch = useDispatch()
	const districts = useSelector(handbookSelectors.districts)
	const positions = useSelector(handbookSelectors.positions)
	const positionsNkz = useSelector(handbookSelectors.positionsByKnz)
	const genders = useSelector(handbookSelectors.genders)
	const isSuccessSend = useSelector(accidentSelectors.isSuccessSend)
	const [isFormDirty, setFormDirty] = useState(false)
	const { successPayload, onSign, onClearPayload, failedPayload } = useDigitalSign()
	const { externalId, xmlForSigned } = useSelector(accidentSelectors.reportXml)

	const onClear = () => {
		onClearPayload()
		dispatch(accidentActions.resetReportXml())
	}

	useEffect(() => {
		if (!districts.length) dispatch(handbookThunks.getDistrictsAuth())
		if (!positions.length) dispatch(handbookThunks.getPositions())
		if (!positionsNkz.length) dispatch(handbookThunks.getPositionsByKnz())
		if (!genders.length) dispatch(handbookThunks.getGender())
	}, [])

	useEffect(() => {
		xmlForSigned && onSign(xmlForSigned)
		successPayload && !xmlForSigned && onClear()
	}, [xmlForSigned]) /** подписание xml */

	useEffect(() => {
		if (successPayload) {
			dispatch(accidentThunks.sendSignedReport({ externalId, signedXml: successPayload }))
			onClear()
		}
	}, [successPayload])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => {
		if (isSuccessSend) {
			onClose()
		}
	}, [isSuccessSend])

	const onSubmit = (data) => {
		const updatedInjureds = data.injureds.map((injured) => {
			const { fio, ...injuredWithoutFio } = injured
			if (injured.isResident === true) {
				const { birthDate, passportNumber, issuredBy, issuredWhen, ...residentFields } = injuredWithoutFio
				return residentFields
			} else if (injured.isResident === false) {
				const { iin, ...nonResidentFields } = injuredWithoutFio
				return nonResidentFields
			}
			return injuredWithoutFio
		})

		const updatedData = {
			...data,
			injureds: updatedInjureds,
		}

		dispatch(accidentThunks.saveReport(updatedData))
	}

	return (
		<Formik
			initialValues={{ injureds: [{ isResident: true }] }}
			validationSchema={AccidentReportValidationSchema}
			onSubmit={onSubmit}
		>
			<Form
				onClose={onClose}
				positions={positions}
				districts={districts}
				positionsNkz={positionsNkz}
				genders={genders}
				isFormDirty={isFormDirty}
				setFormDirty={setFormDirty}
			/>
		</Formik>
	)
}
