import * as Yup from 'yup'
import { formates } from '@app/config'
import { format, isAfter, subYears } from 'date-fns'

Yup.addMethod(Yup.date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, minDate: format(dateBefore, formates.date, new Date()) } })
			: true
	})
})

export const IINValidationScheme = Yup.object().shape({
	iin: Yup.string()
		.matches(/^\d+$/, 'Недопустимый символ(ы)')
		.min(12, { key: 'min_length', value: 12 })
		.max(12, { key: 'max_length', value: 12 })
		.required({ key: 'required' }),
})

export const BINValidationScheme = Yup.object().shape({
	bin: Yup.string()
		.matches(/^\d+$/, 'Недопустимый символ(ы)')
		.min(12, { key: 'min_length', value: 12 })
		.max(12, { key: 'max_length', value: 12 })
		.required({ key: 'required' }),
})

export const SMSCodeValidationScheme = Yup.object().shape({
	code: Yup.string()
		.matches(/^\d+$/, 'Недопустимый символ(ы)')
		.min(6, { key: 'min_length', value: 6 })
		.max(6, { key: 'max_length', value: 6 })
		.required({ key: 'required' }),
})
