import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField } from 'formik'
import { Field, Title } from '@/ui'
import styles from './Conditions.module.scss'

export const ContractWorkingHours = connect(
	({ workingTimeModes = [], formik: { values, setFieldValue }, isFormDirty }) => {
		const { t } = useTranslation()
		const { dworkingHoursCode } = values

		const onWorkingHoursChange = useCallback(
			(value) => {
				setFieldValue('dworkingHoursCode', value)
				value !== '2' && setFieldValue('tariffRate', null)
			},
			[setFieldValue]
		)

		return (
			<div className={styles.conditionSection}>
				<Title className={styles.title}>{t('change_working_hours')}</Title>
				<div className={styles.row}>
					<div className="flex-2">
						<FormikField name="dworkingHoursCode">
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType={'select'}
									type="datePicker"
									isRequired
									label={t('contracts_tab.working_time_mode')}
									placeholder={t('choose_from_directory')}
									options={workingTimeModes}
									error={(touched || isFormDirty) && error}
									{...field}
									onChange={onWorkingHoursChange}
								/>
							)}
						</FormikField>
					</div>
					<div className="flex-1">
						<FormikField name="tariffRate">
							{({ field, meta: { touched, error } }) => (
								<Field
									type="number"
									max={1}
									min={0}
									hideNumberArrows
									label={t('contracts_tab.tarrif_rate')}
									placeholder={t('value_from_to', { from: 0, to: 1 })}
									error={(touched || isFormDirty) && error}
									hint={t('hint_tarrif')}
									isRequired={dworkingHoursCode === '3'}
									disabled={dworkingHoursCode !== '3'}
									{...field}
									enablecheckfloat
								/>
							)}
						</FormikField>
					</div>
				</div>
			</div>
		)
	}
)
