export const clearXmlReducer = (state) => {
	return {
		...state,
		contracts: {
			...state.contracts,
			createContractXML: null,
			removeXML: null,
			recoverXML: {
				...state.contracts.recoverXML,
				data: null,
			},
			additionalContract: {
				...state.contracts.additionalContract,
				xml: null,
			},
			editXML: null,
			reorgonizeXml: null,
			updateIin: {
				...state.contracts.updateIin,
				xmlForUpdateIin: null,
			},
		},
		company: {
			...state.company,
			xmlToSign: null,
		},
		colContracts: {
			...state.colContracts,
			colXML: null,
		},
	}
}
