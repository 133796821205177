export const checkProjectErrors = (node) => {
	// Проверка наличия ошибок
	if (node.errors && node.errors.length > 0)
		return true

		// Проверка наличия ошибок в полях childs и workPlaces
	;['childs', 'workPlaces'].forEach((field) => {
		if (node[field]) {
			for (let item of node[field]) {
				if (checkProjectErrors(item)) return true
			}
		}
	})

	return false
}

export const checkWorkPlacesErrors = (workPlace) => {
	// Проверка наличия ошибок
	return workPlace.errors && workPlace.errors.length > 0
}
