import * as Yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared'

export const ValidationSchema = () =>
	Yup.object().shape({
		iin: Yup.string()
			.required({ key: 'required' })
			.matches(/^[0-9]+$/, 'not_correct_iin')
			.min(12, { key: 'min_12_length' })
			.max(12, { key: 'max_12_length' }),
		fullName: Yup.string().required({ key: 'required' }),
		contracts: Yup.string()
			.when('isCombining', {
				is: (isCombining) => isCombining === true,
				then: Yup.string().nullable(),
				otherwise: Yup.string().required({ key: 'required' }).nullable(),
			})
			.nullable(),
		tariffRate: Yup.number().required({ key: 'required' }),
		factSalary: Yup.number().required({ key: 'required' }),
		beginDate: transformStringDateFormatToDate().required({ key: 'required' }),
		substitutionIin: Yup.string()
			.when('isReplacement', {
				is: (isReplacement) => isReplacement === true,
				then: Yup.string().required({ key: 'required' }).nullable(),
				otherwise: Yup.string().nullable(),
			})
			.nullable(),
		substitutionName: Yup.string()
			.when('isReplacement', {
				is: (isReplacement) => isReplacement === true,
				then: Yup.string().required({ key: 'required' }).nullable(),
				otherwise: Yup.string().nullable(),
			})
			.nullable(),
	})
