import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { pensionSelectors } from '@/entity'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import styles from './Search.module.scss'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { Close8, Preference } from '@app/images'
import { Select } from '@/ui'

const HrButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'capitalize',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	backgroundColor: '#17B67C',
	fontFamily: 'Inter',
	'&:hover': {
		backgroundColor: '#17B67C',
		boxShadow: 'none',
		color: 'white',
	},
})

const HrResetButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	fontFamily: 'Inter',
	height: '32px',
	fontWeight: 700,
	color: '#AAB8D1',
	'&:hover': {
		backgroundColor: 'transparent',
		boxShadow: 'none',
	},
})
const Search = ({ filters, setFilters, fetchData, resetFilter }) => {
	const { t } = useTranslation()
	// справочники для расширенного поиска
	const statusList = useSelector(pensionSelectors.pensionStatuses)
	const stateList = useSelector(pensionSelectors.pensionStates)
	const [advancedSearch, setAdvancedSearch] = useState(false)

	const updateFilter = (e, name) =>
		setFilters({ ...filters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })

	return (
		<>
			<div className={styles.searchRow}>
				<div className={styles.searchField}>
					<div className={styles.searchField__left}>
						<IconButton type="submit" aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							fullWidth
							placeholder={t('pension.searchInput')}
							inputProps={{
								'aria-label': 'search',
							}}
							value={filters.src}
							onChange={(e) => updateFilter(e, 'src')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault()
									fetchData()
								}
							}}
						/>
					</div>
					<span className={styles.searchField__button} onClick={() => fetchData()}>
						{t('find')}
					</span>
				</div>
				<div className={styles.advancedSearch__button} onClick={() => setAdvancedSearch(!advancedSearch)}>
					{advancedSearch ? (
						<>
							<img src={Close8} className={styles.advancedSearch__icon} />
							{t('contracts_tab.advancedSearchHide')}
						</>
					) : (
						<>
							<img src={Preference} className={styles.advancedSearch__icon} />
							{t('contracts_tab.advancedSearch')}
						</>
					)}
				</div>
			</div>
			{advancedSearch && (
				<div className={styles.advancedSearch__row}>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('yin')}</div>
						<input
							type="text"
							className={styles.advancedSearch__input}
							value={filters.iin}
							onChange={(e) => updateFilter(e, 'iin')}
							placeholder={t('enter_12_digits')}
						/>
					</div>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('contracts_tab.contractor')}</div>
						<input
							type="text"
							className={styles.advancedSearch__input}
							value={filters.name}
							onChange={(e) => updateFilter(e, 'name')}
							placeholder={t('enter_fio')}
						/>
					</div>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('pension.status_request')}</div>
						<Select
							required
							options={statusList}
							inputClasses={styles.advancedSearch__input}
							iconClasses={styles.advancedSearch__selectIcon}
							value={filters.status || ''}
							onChange={(e) => updateFilter(e, 'status')}
						/>
					</div>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('pension.state')}</div>
						<Select
							required
							options={stateList}
							inputClasses={styles.advancedSearch__input}
							iconClasses={styles.advancedSearch__selectIcon}
							value={filters.state || ''}
							onChange={(e) => updateFilter(e, 'state')}
						/>
					</div>
					<div className={styles.advancedSearch__searchButtonsRow}>
						<HrResetButton variant="text" onClick={resetFilter}>
							{t('reset_all')}
						</HrResetButton>
						<HrButton style={{ height: '32px' }} variant="contained" onClick={() => fetchData()}>
							{t('search')}
						</HrButton>
					</div>
				</div>
			)}
		</>
	)
}

export default Search
