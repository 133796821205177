export const formFieldNames = {
	bin: 'bin',
	enterpriseName: 'companyName',
	iin: 'iin',
	employeeInitials: 'fullName',
	electronicEmploymentContract: 'isElectronic',
	contractEmployerNumber: 'contractNumber',
	validityTerm: 'dcontractDateCode',
	dateOfContractSigning: 'registerDate',
	startContractDate: 'dateFrom',
	endContractDate: 'dateTo',
	jobType: 'dpartTimeCode',
	workingTimeMode: 'dworkingHoursCode',
	tarrifRate: 'tariffRate',
	countryWork: 'dcountryCode',
	regionWork: 'dregionCode',
	districtWork: 'ddistrictCode',
	locality: 'dlocalityCode',
	addressWork: 'workingPlace',
	remoteWork: 'dremoteWorkCode',
	professionalsSkills: 'specSkillsList',
	personalQualities: 'skillsList',
	foreignLaborInfo: {
		codes: {
			value1: 'value1',
			value2: 'value2',
			value3: 'value3',
		},
		dnonResidentAttractingBasisCode: "dnonResidentAttractingBasisCode",
		isMigration: "isMigration",
		dnonResidentEducationCode: "dnonResidentEducationCode",
		nonResidentPermitDate: "nonResidentPermitDate",
		nonResidentPermitNumber: "nonResidentPermitNumber"
	},
	isResident: 'isResident',
}
