import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { formFieldNames } from '../../../lib/constants/formFieldNames'
import { Field } from '@/ui'
import styles from '../CollectiveForm.module.scss'

export const RateFields = connect(() => {
	const { t } = useTranslation()
	return (
		<>
			<div className={styles.mbFields}>
				<FormikField name={formFieldNames.firstCategoryRate}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							label={t('col_contract.tariff_rate_category_workers')}
							error={touched && error}
							placeholder={t('col_contract.placeholder_size_rate')}
							{...field}
							isRequired
						/>
					)}
				</FormikField>
			</div>
			<div className={`${styles.fields} ${styles.threeFields}`}>
				<FormikField name={formFieldNames.industryAgreementCoefficient}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							label={t('col_contract.industry_agreement_coefficient')}
							error={touched && error}
							placeholder={t('col_contract.placeholder_rate')}
							{...field}
							isRequired
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.minCoefficient}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							label={t('col_contract.min_coefficient')}
							error={touched && error}
							placeholder={t('col_contract.placeholder_rate')}
							{...field}
							isRequired
						/>
					)}
				</FormikField>
				<FormikField name={formFieldNames.maxCoefficient}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="number"
							min={0}
							hideNumberArrows
							enablecheckfloat
							label={t('col_contract.max_coefficient')}
							error={touched && error}
							placeholder={t('col_contract.placeholder_rate')}
							{...field}
							isRequired
						/>
					)}
				</FormikField>
			</div>
		</>
	)
})
