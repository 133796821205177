import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from '@mui/material/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import './hrPagination.css'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiPaginationItem-root': {
			color: '#AAB8D1',
			height: '32px',
			fontSize: '12px',
			lineHeight: '16px',
		},
		'& .Mui-selected': {
			backgroundColor: '#17B67C',
			color: '#FFFFFF',
		},
		'& .MuiPaginationItem-previousNext': {
			'&::after': {
				// content: '"test"'
			},
		},
	},
}))

export const HrPagination = ({
	numberOfElements, //количество элементов на странице отображено сейчас
	totalPages, //общее количество страниц
	totalElements, //общее количество элементов
	handleChangePage, //переключение страницы
	handleChangeRowsPerPage, //смена количества элементов на страницу
	rowsPerPageitems, //список количество элементов на однустраницу
	rowsPerPage, //количество элементов на однустраницу
	page,
	noMarginBottom,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<div className={`hr-pagination-wrapper ${noMarginBottom ? 'margin-bottom-zero' : ''}`}>
			<div className="hr-pagination-left">
				<div className="content-show-per-page">{t('pagination.show_per_page_text')}:</div>
				{rowsPerPageitems.map((item) => (
					<div
						key={item.label}
						className={`content-button-per-page ${rowsPerPage === item.size ? 'content-button-per-page-active' : ''}`}
						onClick={() => handleChangeRowsPerPage(item.size)}
					>
						{item.size != null ? item.label : t(item.label)}
					</div>
				))}
				<div className="content-button-per-page"> | </div>
				<div className="content-button-per-page-count">
					{t('pagination.showed')} {numberOfElements || 0} {t('pagination.from')} {totalElements || 0}
				</div>
			</div>
			<div className="hr-pagination-right">
				<Pagination
					disabled={totalPages === 0}
					count={totalPages || 1}
					onChange={handleChangePage}
					page={page + 1}
					shape="rounded"
					className={classes.root}
				/>
			</div>
		</div>
	)
}
