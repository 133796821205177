import { useTranslation } from 'react-i18next'
import styles from './ContractReviewsItem.module.scss'
import { Rate } from 'antd'
import cl from 'classnames'
import { Button } from '@/ui'
import { Delete, Edit } from '@app/icons'
import React, { memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'
import { reviewActions } from '@/entity/review'
import { formatDate } from '@/utils'

/**
 * @component
 * @example
 * <ContractReviewsItem onToggleAction={() => action(key)} title={'string'} item={{answer: {...object}, review: {...object}, reviewTypeCode: 'string'}} />
 */

export const ContractReviewsItem = memo(({ item, onToggleAction, title }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()

	const { role, contract } = useSelector((state) => ({
		role: userSelectors.activeRole(state),
		contract: contractActionsSelectors.contractView()(state),
	}))

	const handleAnswer = (key) => {
		onToggleAction(key, true)
		dispatch(reviewActions.currentReview(item))
	}

	const answerLabel = {
		employerReview: t('reviews.employee_answer'),
		employeeReview: t('reviews.employer_answer'),
	}

	const addReviewComponent = useMemo(() => {
		return (
			<div className={cl(styles.footer_wrapper, 'mt-2')}>
				<Button type="bare" buttonClassName={styles.edit_button} onClick={() => handleAnswer('addReview')}>
					<Edit />
					<span className="ms-2">{t('reviews.set_review')}</span>
				</Button>
			</div>
		)
	}, [t])

	const reviewComponent = useMemo(() => {
		return (
			<>
				<div className={cl(styles.footer_wrapper, 'mt-2')}>
					<Button type="bare" buttonClassName={styles.edit_button} onClick={() => handleAnswer('editReview')}>
						<Edit />
						<span className="ms-2">{t('reviews.edit_review')}</span>
					</Button>

					<Button type="bare" buttonClassName={styles.delete_button} onClick={() => handleAnswer('deleteReview')}>
						<Delete />
						<span className="ms-2">{t('reviews.delete_review')}</span>
					</Button>
				</div>
			</>

		)
	}, [t])

	const answerComponent = useMemo(() => {
		return (
			<>
				<div className={cl(styles.footer_wrapper, 'mt-2')}>
					<Button type="bare" buttonClassName={styles.edit_button} onClick={() => handleAnswer('editAnswer')}>
						<Edit />
						<span className="ms-2">{t('reviews.edit_answer')}</span>
					</Button>

					<Button type="bare" buttonClassName={styles.delete_button} onClick={() => handleAnswer('deleteAnswer')}>
						<Delete />
						<span className='ms-2'>{t('reviews.delete_answer')}</span>
					</Button>
				</div>
			</>
		)
	}, [t])

	const addAnswerComponent = useMemo(() => {
		return (
			<div className={cl('ps-4', styles.text)}>
				<Button type="bare" buttonClassName={styles.edit_button} onClick={() => handleAnswer('addAnswer')}>
					<span>{t('reviews.answer')}</span>
				</Button>
			</div>
		)
	}, [t])

	const footerButton = {
		employerReview: {
			ROLE_EMPLOYEE: item?.answer?.externalId ? answerComponent : addAnswerComponent,
			ROLE_EMPLOYER: item?.review?.deleted ? addReviewComponent : item?.review && reviewComponent,
		},
		employeeReview: {
			ROLE_EMPLOYEE: item?.review?.deleted ? addReviewComponent : item?.review && reviewComponent,
			ROLE_EMPLOYER: item?.answer?.externalId ? answerComponent : addAnswerComponent,
		},
	}

	const reviewDeleted = {
		employerReview: t('reviews.review_deleted_employer'),
		employeeReview: t('reviews.review_deleted_employee'),
	}

	return (
		<div className={styles.wrapper}>
			<span className={styles.title}>{title}</span>

			<span className={styles.subtitle}>{formatDate(item?.review?.entryDate)}</span>

			<Rate value={item?.rating} disabled className={styles.rating} />

			<span className={cl(styles.text, {
				[styles.text_deleted]: item?.review?.deleted,
				[styles.text_null]: !item?.review?.text,
			})}>{item?.review?.deleted ? reviewDeleted?.[item?.reviewTypeCode] : item?.review?.text ? item?.review?.text : t('reviews.review_text_null')}</span>

			<div className={cl('ps-4', styles.text)}>
				{
					item?.answer?.text &&
					<>
						<strong>{answerLabel?.[item?.reviewTypeCode]}:</strong> {'  '}
						<span>{item?.answer?.text}</span>
					</>
				}

				{onToggleAction && footerButton[item?.reviewTypeCode]?.[role]}
			</div>
		</div>
	)
})
