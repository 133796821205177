import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setEColContract: (payload) => ({
		type: types.SET_E_COL_CONTRACT,
		payload,
	}),
	setEColContractLoading: (payload) => ({
		type: types.SET_E_COL_CONTRACT_LOADING,
		payload,
	}),
	setEColContractSuccess: (payload) => ({
		type: types.SET_E_COL_CONTRACT_SUCCESS,
		payload,
	}),
	setEColContractError: (payload) => ({
		type: types.SET_E_COL_CONTRACT_ERROR,
		payload,
	}),
	setEColContracts: (payload) => ({
		type: types.SET_E_COL_CONTRACTS,
		payload,
	}),
	setEColXML: (payload) => ({
		type: types.SET_E_COL_XML,
		payload,
	}),
	setEHtml: (payload) => ({
		type: types.SET_E_HTML,
		payload,
	}),
	setEHtmlSuccess: (payload) => ({
		type: types.SET_E_HTML_SUCCESS,
		payload,
	}),
	resetEColContract: () => ({
		type: types.RESET_E_COL_CONTRACT,
	}),
	setCurrentEcd: (payload) => ({
		type: types.SET_CURRENT_ECD,
		payload,
	}),
	setCurrentEcdEdit: (payload) => ({
		type: types.SET_CURRENT_ECD_EDIT,
		payload,
	}),
	resetCurrentEcd: () => ({
		type: types.RESET_CURRENT_ECD,
	}),
	setEColContractSendSuccess: (payload) => ({
		type: types.SET_E_COL_CONTRACT_SEND_SUCCESS,
		payload,
	}),
}
