import React, { useMemo } from 'react'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import styles from './ID.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import { getAddress, getBirthPlace, getDocuments } from './utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function ID({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const { t } = useTranslation()
	const birthPlace = useMemo(() => getBirthPlace(data, optionLang), [data.data.birthPlace])
	const address = useMemo(() => getAddress(data, optionLang), [data.data.regAddress])
	const documents = useMemo(() => getDocuments(data, t, optionLang), [data.data.documents])

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_001')}</p>
					</div>
				)}
				<p>
					<p className={styles.title}>{t('personal_affairs.born_place')}</p>
					<p>{birthPlace}</p>
				</p>
				<p>
					<p className={styles.title}>{t('personal_affairs.documents')}</p>
					<div className={styles.documentRow}>
						{documents.map((document) => (
							<DataGridTable
								hideSubTitle
								information={document}
								hideTitle
								titleClass={styles.tableTitleCell}
								rowClass={styles.dataRow}
							/>
						))}
					</div>
				</p>
				<p>
					<p className={styles.title}>{t('personal_affairs.address_full_registration')}:</p>
					<p>{address}</p>
				</p>
			</AccordionDetails>
		</Accordion>
	)
}

export default ID
