export const Trash = ({ className = '' }) => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M9.9375 1.5H7.875V1.125C7.875 0.50468 7.37032 0 6.75 0H5.25C4.62968 0 4.125 0.50468 4.125 1.125V1.5H2.0625C1.54556 1.5 1.125 1.92056 1.125 2.4375V3.75C1.125 3.95709 1.29291 4.125 1.5 4.125H1.70494L2.02891 10.9285C2.05753 11.5294 2.55112 12 3.15262 12H8.84737C9.4489 12 9.94249 11.5294 9.97109 10.9285L10.2951 4.125H10.5C10.7071 4.125 10.875 3.95709 10.875 3.75V2.4375C10.875 1.92056 10.4544 1.5 9.9375 1.5ZM4.875 1.125C4.875 0.918234 5.04323 0.75 5.25 0.75H6.75C6.95677 0.75 7.125 0.918234 7.125 1.125V1.5H4.875V1.125ZM1.875 2.4375C1.875 2.33412 1.95912 2.25 2.0625 2.25H9.9375C10.0409 2.25 10.125 2.33412 10.125 2.4375V3.375C10.0094 3.375 2.3539 3.375 1.875 3.375V2.4375ZM9.22193 10.8928C9.21239 11.0931 9.04786 11.25 8.84737 11.25H3.15262C2.95212 11.25 2.78759 11.0931 2.77807 10.8928L2.45578 4.125H9.54422L9.22193 10.8928Z" />
			<path d="M6 10.5C6.20709 10.5 6.375 10.3321 6.375 10.125V5.25C6.375 5.04291 6.20709 4.875 6 4.875C5.79291 4.875 5.625 5.04291 5.625 5.25V10.125C5.625 10.3321 5.79288 10.5 6 10.5Z" />
			<path d="M7.875 10.5C8.08209 10.5 8.25 10.3321 8.25 10.125V5.25C8.25 5.04291 8.08209 4.875 7.875 4.875C7.66791 4.875 7.5 5.04291 7.5 5.25V10.125C7.5 10.3321 7.66788 10.5 7.875 10.5Z" />
			<path d="M4.125 10.5C4.33209 10.5 4.5 10.3321 4.5 10.125V5.25C4.5 5.04291 4.33209 4.875 4.125 4.875C3.91791 4.875 3.75 5.04291 3.75 5.25V10.125C3.75 10.3321 3.91788 10.5 4.125 10.5Z" />
		</svg>
	)
}
