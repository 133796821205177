import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { formFieldNames } from '../../lib'
import { Field, Button } from '@/ui'
import styles from './ChangeContractBinForm.module.scss'
import { ChangeBinTable } from './Table'
import { contractsThunks, contractsActions, contractsSelectors } from '@/entity'
import { useSelector, useDispatch } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { message } from 'antd'

export const Form = ({ buttonPressed, onClose }) => {
	const { t } = useTranslation()

	const { isValid, values, setFieldValue } = useFormikContext()

	const dispatch = useDispatch()

	const { successPayload, onSignMultiple, onClearPayload } = useDigitalSign()

	const [formPaginationData, setFormPaginationData] = useState({ page: 0, size: 10 })
	const isSuccess = useSelector(contractsSelectors.reorganizeIsSuccess)

	const handleChangePagination = (key, value) => {
		setFormPaginationData({ ...formPaginationData, [key]: value })
	}

	const { checkData, error, loading, contractPage, xmlLoading, xml, result } = useSelector((state) => ({
		...state.contracts.reorganize,
		contractPage: state.contracts.reorganize.checkData?.contractPage,
	}))

	useEffect(() => {
		if (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}, [error])

	useEffect(() => {
		if (result) {
			if (isSuccess) {
				message.success(t('success_alert'))
				onClose()
			} else {
				message.success(t('error_reorganization_date'))
			}
		}
	}, [result])

	useEffect(() => {
		if (successPayload) {
			dispatch(contractsThunks.sendSignedXml(successPayload))
			onClearPayload()
		}
	}, [successPayload])

	useEffect(() => {
		if (xml) {
			onSignMultiple(xml)
			dispatch(contractsActions.clearXmlToReorganize())
		}
	}, [xml])

	useEffect(() => {
		if (checkData) {
			setFieldValue(formFieldNames.employeeInitials, checkData.fullNameRu)
		}
	}, [checkData])

	return (
		<FormikForm className={styles.form}>
			<div className={styles.fields__wrapper}>
				{contractPage && contractPage.length > 0 ? (
					<FormikField name="reorganizationDate">
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('contracts_tab.date_employee_transfer')}
								isRequired
								placeholder={t('default_date_value')}
								maxDate={new Date()}
								error={touched && error ? error : ''}
								{...field}
							/>
						)}
					</FormikField>
				) : (
					<div className={styles.fieldAndButton}>
						<FormikField name="oldBin">
							{({ field, meta: { touched, error } }) => (
								<Field
									wrapperClassName={styles.field__input}
									hideNumberArrows
									label={t('contracts_tab.predecessor_bin')}
									placeholder={t('enter_count_digitals', { count: 12 })}
									{...field}
									error={touched && error ? t(error) : ''}
								/>
							)}
						</FormikField>
						<Button disabled={!isValid} type="submit">
							{t('contracts_tab.check')}
						</Button>
					</div>
				)}
				<div className={styles.fields__item__no__button}>
					<FormikField name={formFieldNames.employeeInitials}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								disabled
								wrapperClassName={styles.field__input}
								label={t('enterprise_name')}
								placeholder={t('filled_automate')}
								{...field}
								error={touched && error ? t(error) : ''}
							/>
						)}
					</FormikField>
				</div>
			</div>

			<div className={styles.workers__info}>
				<div className={styles.workers__info__title}>{t('contracts_tab.employees_data')}</div>
				<ChangeBinTable
					rows={contractPage || []}
					buttonPressed={buttonPressed}
					onChangePagination={handleChangePagination}
					formPaginationData={formPaginationData}
					bin={values.oldBin || ''}
					date={values.reorganizationDate || ''}
					loading={loading}
					xmlLoading={xmlLoading}
				/>
			</div>
		</FormikForm>
	)
}
