export function composeAddress(address, toConcat, language = 'rus') {
	const { country, region, district, locality, street, house } = address
	return [
		(language === 'rus' ? country?.nameRu : country?.nameKz) || country,
		(language === 'rus' ? district?.nameRu : district?.nameKz) || district,
		(language === 'rus' ? region?.nameRu : region?.nameKz) || region,
		(language === 'rus' ? locality?.nameRu : locality?.nameKz) || locality,
		street,
		house,
		toConcat,
	]
		.filter((x) => x)
		.join(', ')
}
