import { useEffect, useState } from 'react'
import LogoLang from '../../assets/home/lang.svg'
import { useTranslation } from 'react-i18next'

const LangFooter = ({ header, dark }) => {
	const { t, i18n } = useTranslation()
	const langs = [
		{ value: 'kz', label: 'rus' },
		{ value: 'rus', label: 'rus' },
	]
	const langHeader = [
		{ value: 'kz', label: 'language' },
		{ value: 'rus', label: 'language' },
	]
	const [activeLang, setActiveLang] = useState(langs[0])

	//TODO
	function trimLocal(str) {
		if (str === undefined || str === null) return 'kz'
		return str.replace(/[^a-zA-ZА-Яа-яЁё]/gi, '').replace(/\s+/gi, ', ')
	}
	//
	useEffect(() => {
		const setLanguage = async () => {
			const lang = trimLocal(localStorage.getItem('lang'))
			if (lang) {
				setActiveLang(langs.find((item) => item.value === lang))
				await i18n.changeLanguage(lang)
			} else {
				await i18n.changeLanguage(langs[0].value)
			}
		}
		setLanguage()
	}, [])

	const selectLang = async (lang) => {
		setActiveLang(lang)
		await i18n.changeLanguage(lang.value)
		localStorage.setItem('lang', lang.value)
	}

	return (
		<div>
			{header && header
				? langHeader
						.filter((lang) => lang.value !== activeLang.value)
						.map((lang) => {
							return (
								<div key={lang.label} className={`internationalization ${dark ? 'internationalization-dark' : ''}`}>
									<p onClick={() => selectLang(lang)}>{t(lang.label)}</p>
								</div>
							)
						})
				: langs
						.filter((lang) => lang.value !== activeLang.value)
						.map((lang) => {
							return (
								<div key={lang.label} className="footer-copyright-lang">
									<p onClick={() => selectLang(lang)}>
										{t(lang.label)}
										<img src={LogoLang} />
									</p>
								</div>
							)
						})}
		</div>
	)
}

export default LangFooter
