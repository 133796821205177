import React, { useMemo } from 'react'
import { Button, DataGridTable, Title } from '@/ui'
import styles from '../additionalContracts/AdditionalContractStyles.module.scss'
import tableStyles from './SocialLeaveStyles.module.scss'
import { Edit, Pencil, Trash } from '@app/icons'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { getSocialLeaveDataByType } from '@features/contracts/socialLeaves/utils'
import { Modal, ModalBackButton } from '@/components/Modal'
import { SignStatus } from '@features/contracts/badges'
import { useSelector } from 'react-redux'
import { contractsSelectors, userSelectors } from '@/entity'

export const ViewSocialLeaveModal = ({ socialLeave, onClose, onDelete, onEdit, onSign }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const lang = getCurrentLanguage()

	const data = React.useMemo(() => getSocialLeaveDataByType(socialLeave, t, lang), [lang, socialLeave, t])
	const contract = useSelector(contractsSelectors.contract)
	const status = useMemo(() => ({ code: socialLeave?.status === 'NOT_SIGNED' ? 2 : 1 }), [socialLeave])
	const activeRole = useSelector(userSelectors.activeRole)

	const esutdRegErrors = useSelector((state) => state.handbook.esutdRegErrors)

	const errorData = esutdRegErrors.find((item) => item.code == socialLeave?.desutdRegErrorCode)

	return (
		<Modal windowClassName={styles.socialLeaveModal}>
			<ModalBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<div className={styles.header}>
					<Title className={styles.title}>
						{t('social_leave.view_title', {
							contractNumber: contract?.contractNumber,
							entryDate: contract?.entryDate,
						})}
					</Title>
					<div className={styles.headerRight}>
						<SignStatus item={status} />
					</div>
				</div>
				<p>
					{!socialLeave.desutdRegErrorCode && (
						<>
							{t('social_leave.entryDate')}: {socialLeave.entryDate}
						</>
					)}

					{socialLeave.desutdRegErrorCode && (
						<p className={styles.registrationDate}>
							{t('social_leave.entryDate')}:{' '}
							<span className={styles.error}>
								{t('package_loading.contract_error_text', {
									errorText: errorData?.[language],
									errorNumber: errorData?.code,
								})}
							</span>
						</p>
					)}
				</p>
				<div className={styles.actions}>
					{activeRole === 'ROLE_EMPLOYER' &&
						socialLeave?.dcontractState?.code !== '1' &&
						contract?.dcontractStatus?.code !== '2' && (
							<Button textColor="green" buttonClassName={styles.button} onClick={onSign}>
								<Pencil className={styles.iconWhite} />
								{t('sign_button')}
							</Button>
						)}
					{activeRole === 'ROLE_EMPLOYER' && contract?.dcontractStatus?.code !== '2' && (
						<Button borderColor="green" transparent border buttonClassName={styles.button} onClick={onEdit}>
							<Edit className={styles.iconGreen} />
							{t('edit_action')}
						</Button>
					)}
					{activeRole === 'ROLE_EMPLOYER' && contract?.dcontractStatus?.code !== '2' && (
						<Button type="bare" buttonClassName={styles.button} onClick={onDelete}>
							<Trash className={styles.iconGrey} />
							{t('delete_action')}
						</Button>
					)}
				</div>
				<DataGridTable
					title={t('vacation_info')}
					information={data}
					hideSubTitle
					titleClass={tableStyles.tableTitleCell}
				/>
				<Button borderColor="green" buttonClassName={styles.closeButton} onClick={onClose}>
					{t('social_leave.close')}
				</Button>
			</div>
		</Modal>
	)
}
