import { isValidElement } from 'react'
import { customButtonsTypes } from '@/shared'
import styles from './Button.module.scss'

const initialType = 'button'

const stylesBySize = {
	default: styles.default,
	small: styles.small,
}
const iconStylesBySize = {
	big: styles.bigSize,
	default: styles.defaultSize,
	small: styles.smallSize,
}

const borderColors = {
	default: styles.defaultBorder,
	grey: styles.greyBorder,
	red: styles.redBorder,
}

const textColors = {
	default: styles.defaultText,
	grey: styles.greyText,
}

const backgroundColors = {
	green: styles.backgroundGreen,
	white: styles.backgroundWhite,
}
export const Button = ({
	children,
	transparent,
	border,
	icon,
	type = initialType,
	onClick,
	size = 'default',
	iconSize = 'default',
	textColor,
	backgroundColor,
	borderColor,
	hidden,
	buttonClassName = '',
	disabled = false,
}) => {
	const isCustomType = customButtonsTypes.includes(type)
	const isReactElement = isValidElement(icon)
	return (
		<button
			className={`${type === 'bare' ? styles.bare : styles.button} ${disabled ? styles.bare__disabled : ''}  ${
				textColor ? textColors[textColor] : ''
			} ${borderColors ? borderColors[borderColor] : ''} ${transparent ? styles.transparent : ''} ${
				border ? styles.border : ''
			} ${stylesBySize[size]} ${hidden ? styles.hidden : ''}
       ${buttonClassName} ${backgroundColor ? backgroundColors[backgroundColor] : ''} `}
			type={isCustomType ? initialType : type}
			onClick={onClick}
			disabled={disabled}
		>
			{icon ? (
				<span className={styles.icon}>
					{isReactElement ? icon : <img src={icon} alt="button" className={`${iconStylesBySize[iconSize]}`} />}
				</span>
			) : null}
			{children}
		</button>
	)
}
