import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Logout } from '@/features'
import { userSelectors } from '@/entity/user/model/index'
import { EmployerProfile } from './EmployerProfile'
import { EmployeeProfile } from './EmployeeProfile'
import styles from './ProfilePopup.module.scss'
import { companySelectors } from '@/entity/company'

const ProfileWindowTab = ({ active = false, text = '', href = '', role = '', handleTabClick }) => {
	return (
		<div className={`${styles.tab} ${active ? styles.active : ''}`} onClick={() => handleTabClick(href, role)}>
			{text}
		</div>
	)
}

export const ProfilePopup = ({ show, handleTabClick }) => {
	const activeRole = useSelector(userSelectors.activeRole)
	const companies = useSelector(companySelectors.companies)
	const employer = useSelector(userSelectors.user)
	const isEmployerWithoutCompanies = companies.length === 0 && activeRole === 'ROLE_EMPLOYER'
	const isEmployeeWithoutIIN = activeRole === 'ROLE_EMPLOYEE' && !employer?.iin
	const { t } = useTranslation()
	const blockRef = useRef(null)

	return (
		<div className={`${styles.popup} ${show ? styles.show : ''}`}>
			<div className={styles.container}>
				<nav className={styles.tabs}>
					<ProfileWindowTab
						href="/employer"
						role={'ROLE_EMPLOYER'}
						active={activeRole === 'ROLE_EMPLOYER'}
						text={t('who_employer')}
						handleTabClick={handleTabClick}
					/>
					<ProfileWindowTab
						href="/worker"
						role={'ROLE_EMPLOYEE'}
						active={activeRole === 'ROLE_EMPLOYEE'}
						text={t('who_worker')}
						handleTabClick={handleTabClick}
					/>
				</nav>
				{show ? (
					<div ref={blockRef} className={styles.mainContent}>
						{activeRole === 'ROLE_EMPLOYER' && <EmployerProfile />}
						{activeRole === 'ROLE_EMPLOYEE' && <EmployeeProfile />}
					</div>
				) : (
					<div style={{ height: blockRef?.current?.clientHeight }} className={styles.mainContent__plug}></div>
				)}
				<div className={styles.email}>
					<p>{employer?.email ? employer?.email : ''}</p>
				</div>
				<div className={styles.footer}>
					<NavLink to={activeRole === 'ROLE_EMPLOYEE' ? '/worker' : '/employer'} className={styles.link}>
						{t('go_to_profile')}
					</NavLink>
					{isEmployerWithoutCompanies || isEmployeeWithoutIIN ? null : (
						<NavLink to="/notifications" className={styles.link}>
							{t('notification_centre')}
						</NavLink>
					)}
					<Logout>
						<span className={styles.link}>{t('logout')}</span>
					</Logout>
				</div>
			</div>
		</div>
	)
}
