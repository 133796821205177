import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import doc from '@/assets/home/doc.svg'
import { downloadFileByUid } from '@/shared/api/rest/laborRecords'
import styles from './ViewConfirmDocument.module.scss'

export const ViewConfirmDocument = ({ data }) => {
	const { t } = useTranslation()

	const downloadFile = async (row) => {
		const response = await downloadFileByUid(row.uid)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = row.name || 'file'
		document.body.appendChild(a)
		a.click()
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} className={styles.file}>
				<td>
					<img src={doc} />
					<label>{row.name}</label>
					<label>
						{row.size > 1e6 ? '(' + (row.size / 1e6).toFixed(1) + ' МБ)' : '(' + (row.size / 1000).toFixed() + ' КБ)'}
					</label>
					<span onClick={() => downloadFile(row)}>{t('labor_records.download')}</span>
				</td>
			</tr>
		),
		[t]
	)

	const filesSize = useMemo(() => (data.reduce((acc, file) => file.size + acc, 0) / 1e6).toFixed(1), [data])

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	return (
		<div className={styles.wrapper}>
			<table>
				<tbody>{tableBody}</tbody>
			</table>
			{filesSize > 0.0 ? (
				<div className={styles.size}>{t('labor_records.full_size') + ' (' + filesSize + ' МБ)'}</div>
			) : null}
		</div>
	)
}
