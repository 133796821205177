import { View as ViewIcon, Reject, Confirm } from '@app/images'

export default function onRequestActionMenuClick(row, e, setActionMenuItems, t) {
	const statusId = row.requestStatus?.code
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (statusId === '1') {
		actions.splice(1, 0, { icon: Confirm, title: t('labor_records.confirm'), action: 'confirm' })
		actions.splice(2, 0, { icon: Reject, title: t('actions.reject'), action: 'reject' })
	}
	setActionMenuItems(actions)
}
