import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from './ExportModal.module.scss'
import { Warning } from '@app/icons'
import { api } from '@/shared/api'
import fileDownload from 'js-file-download'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity/user'
import { companySelectors } from '@/entity/company'
import { LoadingBlocker } from '@/components/Loader'
import { Formik } from 'formik'
import { Form } from '@features/contracts/exportModal/Form'

export const ExportModal = ({ filters, handleCancel }) => {
	const { t } = useTranslation()
	const activeRole = useSelector(userSelectors.activeRole)
	const company = useSelector(companySelectors.company)
	const user = useSelector(userSelectors.user)
	const [loading, setLoading] = useState(false)
	const [selected, setSelected] = useState('8') //по умолчанию текущий список договоров
	const [dcontractStatusCode, setDcontractStatusCode] = useState(null)
	const changeStatusCode = (getContractStatusCode) => {
		setSelected(getContractStatusCode)
		setDcontractStatusCode(selected === '8' ? filters.dcontractStatusCode : selected) //текущий список договоров
	}

	const handleExport = async () => {
		try {
			setLoading(true)
			let res
			if (activeRole === 'ROLE_EMPLOYER') {
				res = await api.contracts.exportBinXsl(
					filters.srcText,
					filters.contractNumber,
					filters.bin,
					filters.establishedPost,
					filters.employeeName,
					dcontractStatusCode === '7' ? null : dcontractStatusCode, //все договора
					filters.dcontractTypeCode,
					filters.registerDate,
					filters.dateFrom,
					filters.dateTo,
					filters.terminationDate,
					filters.dsendStatusCode
				)
			} else {
				res = await api.contracts.exportIinXsl(
					filters.srcText,
					filters.contractNumber,
					filters.iin,
					filters.establishedPost,
					filters.companyName,
					dcontractStatusCode === '7' ? null : dcontractStatusCode, ////все договора
					filters.dcontractTypeCode,
					filters.registerDate,
					filters.dateFrom,
					filters.dateTo,
					filters.terminationDate,
					filters.dsendStatusCode
				)
			}
			if (res) {
				if (activeRole === 'ROLE_EMPLOYER') {
					fileDownload(res, `${company.bin}.xlsx`)
				} else {
					fileDownload(res, `${user.iin}.xlsx`)
				}
				handleCancel()
			} else {
				throw res
			}
		} catch (error) {
			alert(error?.response?.data?.message || `Ошибка: ${error?.status}`)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{loading && <LoadingBlocker localStyles={{ zIndex: '1003' }} />}
			<Formik initialValues={{ dcontractStatusCode: selected }}>
				<ConfirmModal
					onProcess={handleExport}
					onCancel={handleCancel}
					cancelButtonTitle={'contracts_tab.recover.cancel_text'}
					processButtonTitle={'contracts_tab.export'}
					hideBackButton
					priority={2}
				>
					<div className={styles.content}>
						<Warning />
						<p>{t('contracts_tab.export_text')}</p>
						<Form getContractStatusCode={changeStatusCode}></Form>
					</div>
				</ConfirmModal>
			</Formik>
		</>
	)
}
