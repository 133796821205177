import { useEffect, useState } from 'react'
import { useToggle } from '@/shared'

export const useTimer = (startWith, isStart = false) => {
	const [second, setSecond] = useState(startWith)
	const [isCompleted, toggleCompleted] = useToggle()

	useEffect(() => {
		let interval = null
		if (isStart) {
			if (isCompleted) toggleCompleted()
			const INTERVAL_FREQUENCY = 1000
			interval = setInterval(() => {
				setSecond((prevState) => {
					const nextValue = prevState - 1
					if (nextValue === 0) {
						clearInterval(interval)
						setSecond(startWith)
						toggleCompleted()
					}
					return nextValue
				})
			}, INTERVAL_FREQUENCY)
		}
		return () => {
			clearInterval(interval)
		}
	}, [isCompleted, isStart, startWith, toggleCompleted])

	return { second, isCompleted }
}
