import * as yup from 'yup'

/**
 * Валидация для СО с dleaveTypeCode 1
 */
export const code2Schema = yup.object().noUnknown(false).shape({
	dleaveTypeCode: yup.string().required(),
	beginDate: yup.string().required(),
	endDate: yup.string().required(),
})

/**
 * Валидация для СО с dleaveTypeCode 2
 */
export const code1Schema = yup.object().shape({
	dleaveTypeCode: yup.string().required(),
	beginDate: yup.string().required(),
	endDate: yup.string().required(),
	firstDayDate: yup.string().required(),
	daysOff: yup.number().positive().integer(),
	timeSheetNum: yup.string().required(),
	departmentRu: yup.string(),
	departmentKz: yup.string(),
})
