import { SearchPanel } from '@/components/SearchPanel/SearchPanel'
import { useState } from 'react'

export const PositionEvaluationSearch = ({ onSearch }) => {
	const [searchValue, setSearchValue] = useState('')

	return (
		<SearchPanel
			searchPlaceholder=""
			searchValue={searchValue}
			onSearchValueChange={setSearchValue}
			fetchData={onSearch}
		>
			Тут будут дополнительные условия поиска
		</SearchPanel>
	)
}
