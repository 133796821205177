export const headCells = [
	{
		id: 'eventDate',
		numeric: false,
		label: 'labor_records.date',
	},
	{
		id: 'laborType',
		numeric: false,
		label: 'labor_records.record_type',
	},
	{
		id: 'bin',
		numeric: true,
		label: 'bin',
	},
	{
		id: 'name',
		numeric: false,
		label: 'name',
	},
	{
		id: 'establishedPost',
		numeric: false,
		label: 'labor_records.establishedPost',
	},
]
