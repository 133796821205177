import { LoadingBlocker } from '@/components/Loader'
import { Modal, ModalBackButton } from '@/components/Modal'
import { contractsSelectors, contractsThunks, contractsActions } from '@/entity/contracts'
import { ContractProject, NewTemplate } from '@/entity/contracts/ui/form/components'
import { request } from '@/shared/api/request'
import { Title } from '@/ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AdditionalContractStyles.module.scss'
import { AdditionalContractForm } from './form'
import { handbookThunks } from '@/entity/handbook'
import { message } from 'antd'
import { EmploymentContractFile } from '@/entity/contracts/ui/form/employmentСontractFile/employmentСontractFile'

export const CreateEditAdditionalAgreementModal = ({
	onClose,
	createHandler,
	additionalContractTransfer,
	isEdit = false,
	setParamMap,
	page,
	pageSize,
	filters = {},
	setFilters,
	fetchData,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const contract = useSelector(contractsSelectors.contract)
	const dispatch = useDispatch()
	const [openElectronic, setOpenElectronic] = useState(false)
	const [openProject, setOpenProject] = useState(false)
	const [html, setHtml] = useState(null)
	const [newtemplateData, setNewtemplateData] = useState({})
	const [options, setOptions] = useState(null)
	const [document, setDoc] = useState(null)
	const [isPdfView, setPdfView] = useState(false)
	const [attachProjectFileData, setAttachProjectFileData] = useState({})
	const handleFormSubmit = async (data, isLocal) => {
		if (contract.econtractFound) {
			const { bin, id: contractId, externalId: contractExternalId, iin, foreignLaborInfo, isResident } = contract
			const changeAdditionalData = { bin, contractId, contractExternalId, iin, foreignLaborInfo, isResident, ...data }
			setNewtemplateData(changeAdditionalData)
			const res = await request({
				url: 'contract/eAdContract/options',
				method: 'POST',
				data: { ...changeAdditionalData },
			})
			setOpenElectronic(true)
			setOptions(res)
			return
		}

		/** Обработчик создания соглашения, если создание происходит в родительском компоненте */
		if (createHandler) return createHandler(data)
		setParamMap(data.paramMap)
		if (isLocal) {
			dispatch(contractsThunks.saveLocalAdditionalContract(data, page, pageSize))
		} else {
			dispatch(contractsThunks.saveAdditionalContract(data, page, pageSize))
		}
		dispatch(handbookThunks.getContractTerms())
	}

	function transformDataToOptions(data) {
		const paramOrder = [
			'employerType',
			'language',
			'jobType',
			'workCondition',
			'workBeginning',
			'term',
			'probitionary',
			'workingTime',
			'salary',
			'bonus',
			'mandatory',
			'downtime',
			'overtime',
			'paymentMethod',
			'employeeRights',
			'employeeResp',
			'employerRights',
			'employerResp',
		]

		return paramOrder
			.map((paramCode) => {
				let paramValueCode = data[paramCode]

				// Проверяем, есть ли соответствующий ключ с "Is" в конце
				const isKey = `${paramCode}Is`

				// Добавляем условие, чтобы пропустить объект, если есть isKey и его значение false
				if (data[isKey] === false) {
					return null
				}

				if (data[isKey]) {
					if (paramCode === 'employeeResp' && data.employeeRespIs) {
						paramValueCode = '35'
					} else if (paramCode === 'employeeRights' && data.employeeRightsIs) {
						paramValueCode = '34'
					} else if (paramCode === 'employerResp' && data.employerRespIs) {
						paramValueCode = '37'
					} else if (paramCode === 'employerRights' && data.employerRightsIs) {
						paramValueCode = '36'
					}
					// Если есть, устанавливаем значения textValueKaz и textValueRus
					return {
						paramValueCode,
						textValueRus: data[paramCode],
						textValueKaz: data[paramCode],
						paramCode,
					}
				} else if (data?.downtime || data?.overtime) {
					// Добавляем условия для downtime и overtime
					if (paramCode === 'downtime') {
						paramValueCode = '11.1'
					} else if (paramCode === 'overtime') {
						paramValueCode = '12.1'
					}
					return {
						paramValueCode,
						textValueRus: null,
						textValueKaz: null,
						paramCode,
					}
				} else {
					// Иначе, просто устанавливаем paramValueCode и оставляем textValueRus и textValueKaz равными null
					return {
						paramValueCode: data[paramCode],
						textValueRus: null,
						textValueKaz: null,
						paramCode,
					}
				}
			})
			.filter((item) => item !== null) // Удаляем null элементы из массива
	}

	const handleOpenProject = async (data) => {
		const options = transformDataToOptions(data)
		try {
			const body = {
				dto: { ...newtemplateData },
				options: options,
			}
			const res = await request({ url: 'contract/eAdContract/html', method: 'POST', data: body })
			const langs = {
				rus: 'valueRu',
				kz: 'valueKz',
			}
			setHtml(res[langs[language]])
			setOpenProject(true)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}

	const [templatesLoading, setTemplatesLoading] = useState(false)

	const handleSendToEmployee = async (data, isSend) => {
		try {
			setTemplatesLoading(true)
			await request({
				url: '/contract/eAdContract/save',
				method: 'POST',
				data: {
					additionalContractDTO: newtemplateData,
					externalId: newtemplateData?.externalId,
					optionsList: options,
					valueList: data,
				},
				params: {
					isSend,
				},
			})
			dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
			dispatch(contractsActions.setAdditionalContractSubmitted())
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
			message.success(t('success_alert'), 5)
			onClose()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка')
		} finally {
			setTemplatesLoading(false)
		}
	}

	const attachFileData = (values) => {
		const { bin, id: contractId, externalId: contractExternalId, iin } = contract
		const changeAdditionalData = {
			additionalContractDTO: { bin, contractId, contractExternalId, iin, ...values },
			fileId: document.id,
			optionsList: options,
			valueList: [],
		}
		setAttachProjectFileData(changeAdditionalData)
		setPdfView(true)
	}

	const CloseAndUpdateFilter = () => {
		dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
		setFilters({ ...filters, dcontractStatusCode: '6' })
		fetchData({ ...filters, dcontractStatusCode: '6' })
		onClose()
	}
	return (
		<>
			<Modal windowClassName={styles.modal}>
				{templatesLoading && <LoadingBlocker />}
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<Title>
						{isEdit
							? t('edit_contr_title', { contractNumber: contract?.contractNumber || ' ' })
							: t('additional_contract_creation_title', {
									contractNumber: contract?.contractNumber || additionalContractTransfer?.contractNumber,
									registerDate: contract?.registerDate || additionalContractTransfer?.registerDate,
							  })}
					</Title>
					<AdditionalContractForm
						onSubmit={handleFormSubmit}
						onCancelEditing={onClose}
						additionalContractTransfer={additionalContractTransfer}
						isEdit={isEdit}
						document={document}
						setDoc={setDoc}
						nextPage={attachFileData}
					/>
					{openElectronic && options ? (
						<NewTemplate
							isOpen={openElectronic}
							onClose={setOpenElectronic.bind(null, false)}
							formData={options}
							isAdditional={true}
							onOpenProject={handleOpenProject}
						/>
					) : null}
					{openProject && (
						<ContractProject
							isOpen={openProject}
							onClose={setOpenProject.bind(null, false)}
							htmlData={html}
							isAdditional={true}
							handleSendToEmployee={handleSendToEmployee}
							lang={language}
						/>
					)}
				</div>
			</Modal>
			{isPdfView && (
				<EmploymentContractFile
					onClose={() => setPdfView(false)}
					documentData={document}
					attachProjectFileData={attachProjectFileData}
					CloseAndUpdateFilter={CloseAndUpdateFilter}
					filters={filters}
					page={page}
					pageSize={pageSize}
					saveUrl={'/contract/eAdContract/save'}
				/>
			)}
		</>
	)
}
