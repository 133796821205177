import React, { useMemo } from 'react'
import { Accordion, AccordionDetails } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import styles from './Education.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import { getEducationColumns } from './utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getArrestedCorruptionColumns } from '@pages/PersonalAffairs/components/DetailedAffair/modules/Corruption/utils'

function Education({ data }) {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const { t } = useTranslation()
	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					{data.method[optionLang]}{' '}
					<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
						'DD.MM.YYYY'
					)}`}</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.type_002')}</p>
					</div>
				)}
				{data.data.education.map((data, key) => {
					return (
						<DataGridTable
							key={key}
							hideSubTitle
							information={getEducationColumns(t, data, optionLang)}
							hideTitle
							titleClass={styles.tableTitleCell}
							rowClass={styles.dataRow}
						/>
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default Education
