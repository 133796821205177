import React from 'react'

export const Clock = ({ className }) => {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path d="M11.4385 9.41291L9.20764 7.73979V4.3316C9.20764 3.98891 8.93064 3.71191 8.58795 3.71191C8.24526 3.71191 7.96826 3.98891 7.96826 4.3316V8.04966C7.96826 8.24485 8.05998 8.42891 8.21614 8.54541L10.6948 10.4044C10.8064 10.4881 10.9365 10.5284 11.066 10.5284C11.255 10.5284 11.4409 10.4435 11.5624 10.2799C11.7682 10.0066 11.7124 9.61804 11.4385 9.41291Z" />
				<path d="M8.58838 0C4.17691 0 0.588379 3.58853 0.588379 8C0.588379 12.4115 4.17691 16 8.58838 16C12.9998 16 16.5884 12.4115 16.5884 8C16.5884 3.58853 12.9998 0 8.58838 0ZM8.58838 14.7607C4.86104 14.7607 1.82772 11.7273 1.82772 8C1.82772 4.27266 4.86104 1.23934 8.58838 1.23934C12.3163 1.23934 15.349 4.27266 15.349 8C15.349 11.7273 12.3157 14.7607 8.58838 14.7607Z" />
			</g>
		</svg>
	)
}
