import React, { useEffect, useState } from 'react'
import { Field as FormikField, connect } from 'formik'
import { Field, Error } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from '@/entity/contracts/ui/form/ContractForm.module.scss'
import { contractsThunks, formFieldNames } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { getFromLocalStorage } from '@/shared'
import { formatDate } from '@/utils'

export const ContractDuration = connect(
	({
		nonresidentAttractingBasis = [],
		immigrantType,
		isEdit,
		eContract,
		setIsPermitError,
		isPermitError,
		formik: { setFieldValue, setFieldTouched, setFieldError, values, errors },
		nonresidentEducation,
		isFormDirty,
	}) => {
		const dispatch = useDispatch()
		const irsPermissionList = useSelector((state) => state.contracts.irsPermissionList)
		const { t } = useTranslation()
		const [withPermission, setWithPermission] = useState(false)
		const [childsValue1, setChildsValue1] = useState([])
		const [childsValue2, setChildsValue2] = useState([])
		const [filteredNumberPermissionsList, setFilteredNumberPermissionsList] = useState([])
		const [loadCalendar, setLoadCalendar] = useState(true)
		const parsedActiveCompany = getFromLocalStorage('activeCompany')
		const residentType = parsedActiveCompany?.flEmployer ? 'TI' : 'IRS'

		// Zhanadil - получаем по бину работодателя - пермишены для найма
		useEffect(() => {
			dispatch(contractsThunks.getEmployerPermissionByBin(parsedActiveCompany?.bin))
		}, [])

		useEffect(() => {
			const childs = nonresidentEducation.filter(
				(item) =>
					item.parentId === nonresidentEducation.find((i) => i.code === values.foreignLaborInfo.codes.value1)?.id
			)
			setChildsValue1(childs)
			if (childs.findIndex((c) => c.code === values.foreignLaborInfo.codes.value2) === -1) {
				setFieldValue('foreignLaborInfo.codes.value2', null)
				setFieldValue('foreignLaborInfo.codes.value3', null)
			}
			if (!childs.length) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value1)
			}
		}, [values.foreignLaborInfo.codes.value1])

		useEffect(() => {
			if (!values.foreignLaborInfo.codes.value2) {
				setChildsValue2([])
				setFieldValue('foreignLaborInfo.codes.value3', null)
				return
			}

			const childs = nonresidentEducation.filter(
				(item) =>
					item.parentId === nonresidentEducation.find((i) => i.code === values.foreignLaborInfo.codes.value2)?.id
			)
			setChildsValue2(childs)
			if (childs.findIndex((c) => c.code === values.foreignLaborInfo.codes.value3) === -1) {
				setFieldValue('foreignLaborInfo.codes.value3', null)
			}
			if (!childs.length) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value2)
			}
		}, [values.foreignLaborInfo.codes.value2])

		useEffect(() => {
			if (values.foreignLaborInfo.codes.value3) {
				setFieldValue('foreignLaborInfo.dnonResidentEducationCode', values.foreignLaborInfo.codes.value3)
			}
		}, [values.foreignLaborInfo.codes.value3])

		// Zhanadil - исходя из выбора в dnonResidentAttractingBasisCode, меняем значения зависимых полей
		useEffect(() => {
			if (values.foreignLaborInfo.dnonResidentAttractingBasisCode) {
				const permissionList =
					irsPermissionList?.length > 0 ? irsPermissionList?.filter((v) => v.residentType === residentType) : []
				setFilteredNumberPermissionsList(permissionList)
				const basis = nonresidentAttractingBasis.find(
					(item) => item.code === values.foreignLaborInfo.dnonResidentAttractingBasisCode
				)
				if (basis.parentId === 1 && Boolean(permissionList?.length < 1)) {
					setIsPermitError(`required_permit_${residentType.toLowerCase()}`)
				}
				if (basis.parentId !== 1) {
					setFieldValue('foreignLaborInfo.nonResidentPermitNumber', null)
					setFieldValue('foreignLaborInfo.nonResidentPermitDate', null)
				}
				setWithPermission(basis.parentId === 1)
				if (basis.parentId !== 1) setIsPermitError('')
				if (basis.parentId && !Boolean(permissionList?.length < 1)) {
					setIsPermitError('')
				}
			}
		}, [values.foreignLaborInfo.dnonResidentAttractingBasisCode, irsPermissionList])

		// Zhanadil - меняем значение поля "срок действия ТД" исходя от типа иммигранта
		useEffect(() => {
			if (immigrantType !== 'TI') {
				setFieldValue(formFieldNames.validityTerm, '7')
			}
			if (immigrantType === 'TI') {
				setFieldValue(formFieldNames.validityTerm, '9')
			}
		}, [immigrantType])

		useEffect(() => {
			if (values.foreignLaborInfo.isMigration === 1) {
				setLoadCalendar(false)
				setTimeout(() => {
					setLoadCalendar(true)
				}, 100)
			}
			if (!values.foreignLaborInfo.nonResidentPermitDate)
				setFilteredNumberPermissionsList(irsPermissionList?.filter((v) => v.residentType === residentType))
		}, [values.foreignLaborInfo.nonResidentPermitDate, values.foreignLaborInfo.isMigration])

		// Zhanadil - Обработчик для SelectSearch
		const handlePermitNumberChange = (selectedPermitNumber) => {
			const selectedPermit = irsPermissionList?.find((item) => item.permitCode === selectedPermitNumber)
			if (selectedPermit) {
				setFieldValue('foreignLaborInfo.nonResidentPermitNumber', selectedPermitNumber)
				setFieldValue('foreignLaborInfo.nonResidentPermitDate', formatDate(selectedPermit?.permitIssueDate))
				if ((isEdit && !eContract) || !isEdit) {
					setFieldValue('dateFrom', formatDate(selectedPermit?.permitBeginDate))
					setFieldValue('dateTo', formatDate(selectedPermit?.permitEndDate))
				}
			} else {
				setFilteredNumberPermissionsList(irsPermissionList?.filter((v) => v.residentType === residentType))
				setFieldValue('foreignLaborInfo.nonResidentPermitNumber', '')
				setFieldValue('foreignLaborInfo.nonResidentPermitDate', '')
			}
		}

		// Zhanadil - Обработчик для DatePicker
		const handlePermitDateChange = (date, field) => {
			const formattedDate = formatDate(date)
			const selectedPermit = irsPermissionList.filter((item) => item.permitIssueDate === formattedDate)

			if (date) {
				setFieldValue('foreignLaborInfo.nonResidentPermitDate', formatDate(date))
				setFilteredNumberPermissionsList(selectedPermit)
			} else {
				setFieldValue('foreignLaborInfo.nonResidentPermitDate', '')
				setFieldValue('foreignLaborInfo.nonResidentPermitNumber', '')
			}
		}

		return (
			<>
				<FormikField name={'foreignLaborInfo.dnonResidentAttractingBasisCode'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="selectTree"
							label={t('contractDuration.base')}
							isRequired
							placeholder={t('choose_from_handbook')}
							options={nonresidentAttractingBasis}
							error={(touched || isFormDirty) && error}
							hint={t('contractDuration.hint.base')}
							{...field}
						/>
					)}
				</FormikField>
				{isPermitError && <Error classNameError={styles.errorIsPermit}>{t(`validation.${isPermitError}`)}</Error>}
				<div className={`${styles.fields} ${styles.threeFields}`}>
					<FormikField name={'foreignLaborInfo.nonResidentPermitNumber'}>
						{({ field, meta: { touched, error } }) => {
							return (
								<Field
									type="text"
									hideNotFoundError={Boolean(error)}
									onCustomChange={handlePermitNumberChange}
									fieldType="selectSearch"
									label={t('contractDuration.permit_number')}
									placeholder={t('contractDuration.permit_number_placeholder')}
									isRequired={withPermission}
									{...field}
									options={filteredNumberPermissionsList}
									optionObjectKey="permitCode"
									hint={t('contractDuration.hint.permit_number')}
									disabled={!withPermission}
									error={(touched || isFormDirty) && error}
								/>
							)
						}}
					</FormikField>
					{loadCalendar ? (
						<FormikField name={'foreignLaborInfo.nonResidentPermitDate'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="datePicker"
									onCustomChange={handlePermitDateChange}
									label={t('contractDuration.dateOfPermit')}
									isRequired={withPermission}
									placeholder={t('default_date_value')}
									error={(touched || isFormDirty) && error}
									hint={t('contractDuration.hint.dateOfPermit')}
									disabled={!withPermission}
									{...field}
								/>
							)}
						</FormikField>
					) : null}
				</div>
				<FormikField name={'foreignLaborInfo.codes.value1'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.education')}
							isRequired
							placeholder={t('choose_from_handbook')}
							options={nonresidentEducation.filter((item) => item.parentId === null)}
							error={(touched || isFormDirty) && error}
							hint={t('contractDuration.hint.education')}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'foreignLaborInfo.codes.value2'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.speciality')}
							isRequired={childsValue1.length}
							placeholder={t('choose_from_handbook')}
							options={childsValue1}
							error={(touched || isFormDirty) && error}
							disabled={!values.foreignLaborInfo.codes.value1 || !childsValue1.length}
							hint={t('contractDuration.hint.speciality')}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name={'foreignLaborInfo.codes.value3'}>
					{({ field, meta: { touched, error } }) => (
						<Field
							type="text"
							fieldType="select"
							label={t('contractDuration.qualification')}
							isRequired={childsValue2.length}
							placeholder={t('choose_from_handbook')}
							options={childsValue2}
							error={(touched || isFormDirty) && error}
							disabled={!values.foreignLaborInfo.codes.value2 || !childsValue2.length}
							hint={t('contractDuration.hint.qualification')}
							{...field}
						/>
					)}
				</FormikField>
			</>
		)
	}
)
