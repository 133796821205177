import { useState, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Upload, UploadImagePlaceholder } from '@app/images'
import { useError } from '@/shared'
import styles from './style.module.scss'

export const UploadImage = ({ onChange, initialValue, disabled, maxSize = 204800 }) => {
	const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
		multiple: false,
		disabled: disabled,
		maxSize: maxSize,
		accept: ['image/png'],
	})
	const [file, setFile] = useState([])
	const { errors, setError } = useError()
	const { t } = useTranslation()

	const imgSrc = useMemo(() => {
		if (Array.isArray(file) && file.length > 0) return URL.createObjectURL(file[0])
		if (initialValue && typeof initialValue === 'string')
			return `${process.env.REACT_APP_API_URL}/file/notype/${initialValue}`
		return UploadImagePlaceholder
	}, [file, initialValue])

	const size = useMemo(() => (maxSize / 1024).toFixed(0), [maxSize])

	useEffect(() => {
		setFile(acceptedFiles)
		if (acceptedFiles?.length > 0) onChange(acceptedFiles[0])
		if (fileRejections?.length > 0) {
			const error = fileRejections[0]?.errors?.filter(({ code }) => code === 'file-invalid-type').length
				? t('wrong_type_photo_error')
				: t('max_size_photo_error')
			setError('image', error)
		}
	}, [acceptedFiles, onChange, fileRejections])

	return (
		<div className={styles.upload} {...getRootProps()}>
			<img src={imgSrc} alt="avatar" className={styles.image} />
			{!disabled && (
				<>
					<div className={styles.info}>
						<p className={styles.label}>{t('drag_file')}</p>
						<div className={styles.button}>
							<img src={Upload} alt={'upload'} />
							<span>{t('upload_file_from_your_pc')}</span>
						</div>
						{errors.image && <p className={styles.error}>{errors.image}</p>}
						<p className={styles.formats}>
							{t('file_formats')}: .png {t('to')} {size} {t('kb')}
						</p>
					</div>
					<input className={styles.imageInput} {...getInputProps()} type="file" accept="image/*" />
				</>
			)}
		</div>
	)
}
