import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import React from 'react'
import { Redirect } from 'react-router-dom'

export const RedirectToRole = ({ children }) => {
	const activeRole = useSelector(userSelectors.activeRole)

	if (activeRole === 'ROLE_EMPLOYER') return <Redirect to={'/role'} />
	return <>{children}</>
}
