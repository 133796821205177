import { useTranslation } from 'react-i18next'
import React from 'react'
import styles from './style.module.scss'
import { Cross } from '@app/images'
import { Arrow } from '@app/icons'

export const ModalTemplate = ({ children, onClose, hideBackButton, priority = 1 }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.wrapper} style={{ zIndex: `100${priority}` }}>
			<div className={styles.template} style={{ zIndex: `10${priority}` }}>
				<div className={`${styles.header} ${hideBackButton ? styles.single : styles.pair}`}>
					{!hideBackButton && (
						<div onClick={onClose} className={styles.back}>
							<Arrow styles={{ fill: '#17B67C', width: '5px' }} />
							{t('back')}
						</div>
					)}
					<div onClick={onClose} className={styles.cross}>
						<img src={Cross} alt="cross" />
					</div>
				</div>
				<div className={styles.container}>{children}</div>
			</div>
		</div>
	)
}
