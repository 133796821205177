import React, { useCallback } from 'react'
import styles from './SwitchBox.module.scss'
import { Info } from '@/app/icons'
import { Tooltip } from 'antd'
import { Label } from '@/ui/atoms'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 12,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			//transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#17B67C',
			},
			'&.Mui-disabled': {
				color: 'rgb(179,178,178)',
			},
		},
		'&.Mui-disabled': {
			'& + .MuiSwitch-track': {
				opacity: 0.12,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
		'& .Mui-disabled + .MuiSwitch-track': {
			opacity: '0.12',
		},
	},
}))

export const SwitchBox = ({
	name,
	onChange = function () {},
	value = false,
	label,
	isRequired,
	labelSize = 'default',
	labelClassName = '',
	disabled = false,
	disableLabelClick = false,
	hint = '',
}) => {
	const handleClick = useCallback(() => {
		onChange(!value)
	}, [disabled, disableLabelClick, onChange])

	// Исправление косяка с отправкой пустой строки в самом начале
	if (typeof value !== 'boolean') {
		value = false
	}

	return (
		<label htmlFor={name} className={styles.container}>
			<AntSwitch
				className={styles.container_switch}
				id={name}
				checked={value}
				onChange={handleClick}
				inputProps={{ 'aria-label': 'controlled' }}
				disabled={disabled}
			/>
			{/*<span className={styles.checkmark} />*/}
			{hint ? (
				<Tooltip
					onClick={handleClick}
					placement="top"
					color="white"
					overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
					title={hint}
				>
					<label name={name}>
						{label}
						{isRequired ? ' *' : ''}
						<Info className={styles.hintIcon} />
					</label>
				</Tooltip>
			) : (
				<Label name={name} size={labelSize} className={labelClassName}>
					{label}
					{isRequired ? ' *' : ''}
				</Label>
			)}
		</label>
	)
}
