import React, { useEffect, useState } from 'react'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title, Button } from '@/ui'
import styles from './DigitizationFormModal.module.scss'
import { useTranslation } from 'react-i18next'
import { DigitizationForm } from './DigitizationForm'
import { Form, Formik } from 'formik'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
export const DigitizationFormModal = ({ position, eventDates, edit, onClose, onOpenOpv, addItem, saveItem, isNew }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const laborTypes = useSelector(handbookSelectors.laborType)
	if (edit) {
		edit.termReasonCodes = edit.termReasonCodes ?? edit.terminationReasons
	}
	const [additionalReasons, setAdditionalReasons] = useState(
		edit ? (edit.termReasonCodes?.length >= 1 ? edit.termReasonCodes.slice(1) : []) : []
	)

	useEffect(() => {
		dispatch(handbookThunks.getLaborType())
	}, [dispatch])

	const validationSchema = Yup.object().shape({
		laborTypeCode: Yup.string().required('required').nullable(),
		orgName: Yup.string().required('required'),
		eventDate: Yup.string().required('required'),
		establishedPost: Yup.string().required('required'),
		terminationReason: Yup.array().when('laborTypeCode', {
			is: (laborTypeCode) => laborTypeCode === '4',
			then: Yup.array().min(1, 'required').nullable(),
		}),
	})

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>
							{edit ? t('digitization.form.titleEdit') : t('digitization.form.titleCreate')}
						</Title>
					</div>
					<Formik
						initialValues={{
							id: edit ? edit.id : null,
							laborType: edit ? edit.laborType : null,
							laborTypeCode: edit ? edit.laborType.code : isNew ? '1' : null,
							bin: edit ? edit.bin : '',
							orgName: edit ? edit.orgName : '',
							eventDate: edit ? edit.eventDate : '',
							establishedPost: edit ? edit.establishedPost : '',
							terminationReason: edit ? (edit.termReasonCodes ? edit.termReasonCodes[0] : []) : [],
							terminationReasons: edit ? edit.termReasonCodes : null,
						}}
						validationSchema={validationSchema}
						onSubmit={async (values) => {
							values.terminationReasons =
								values.terminationReason.length || additionalReasons.length
									? [values.terminationReason, ...additionalReasons]
									: null
							const { code, rus, kz } = laborTypes.find((e) => e.code === values.laborTypeCode)
							values.laborType = { code, nameRu: rus, nameKz: kz }
							edit ? saveItem(values) : addItem(values)
						}}
					>
						<Form>
							<DigitizationForm
								eventDates={eventDates}
								position={position}
								onOpenOpv={onOpenOpv}
								onClose={onClose}
								laborTypes={laborTypes}
								additionalReasons={additionalReasons}
								setAdditionalReasons={setAdditionalReasons}
								isNew={isNew}
							/>
							<div className={styles.footer}>
								<Button type="bare" onClick={onClose}>
									{t('cancel')}
								</Button>
								<Button type="submit">{edit ? t('save') : t('labor_records.add_record')}</Button>
							</div>
						</Form>
					</Formik>
				</div>
			</Modal>
		</>
	)
}

export default DigitizationFormModal
