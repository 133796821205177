import { request } from '@/shared/api/request'
export const exportBinXsl = (
	srcText,
	contractNumber,
	bin,
	establishedPost,
	employeeName,
	dcontractStatusCode,
	dcontractTypeCode,
	registerDate,
	dateFrom,
	dateTo,
	terminationDate,
	dsendStatusCode
) => {
	return request({
		url: 'contract/export/xsl',
		method: 'GET',
		responseType: 'blob',
		params: {
			srcText,
			contractNumber,
			bin,
			establishedPost,
			employeeName,
			dcontractStatusCode,
			dcontractTypeCode,
			registerDate,
			dateFrom,
			dateTo,
			terminationDate,
			dsendStatusCode,
		},
	})
}

export const exportIinXsl = (
	srcText,
	contractNumber,
	iin,
	establishedPost,
	companyName,
	dcontractStatusCode,
	dcontractTypeCode,
	registerDate,
	dateFrom,
	dateTo,
	terminationDate,
	dsendStatusCode
) => {
	return request({
		url: 'contract/export/xsl',
		method: 'GET',
		responseType: 'blob',
		params: {
			srcText,
			contractNumber,
			iin,
			establishedPost,
			companyName,
			dcontractStatusCode,
			dcontractTypeCode,
			registerDate,
			dateFrom,
			dateTo,
			terminationDate,
			dsendStatusCode,
		},
	})
}
