import { handbookThunks } from '@/entity/handbook'
import { agreementsActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'

export const getDict = () => {
	return (dispatch, getState) => {
		const { industryAgreement, extraGuaranteesCollectiveContract } = getState().handbook
		if (!industryAgreement.length) dispatch(handbookThunks.getIndustryAgreement())
		if (!extraGuaranteesCollectiveContract.length) dispatch(handbookThunks.getExtraGuaranteesCollectiveContract())
	}
}

export const getXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(agreementsActions.setAgreementLoading(true))
			const agreementXml = await api.agreement.getXml(data)
			dispatch(agreementsActions.setAgreementXml(agreementXml))
		} catch (error) {
			dispatch(agreementsActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(agreementsActions.setAgreementLoading(false))
		}
	}
}

export const getPage = (page, size, srcText) => {
	return async (dispatch) => {
		try {
			dispatch(agreementsActions.toggleLoading(true))
			const agreements = await api.agreement.getPage(page, size, srcText)
			dispatch(agreementsActions.setAgreements(agreements))
		} catch (error) {
			dispatch(agreementsActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(agreementsActions.toggleLoading(false))
		}
	}
}

export const sendAgreement = (signedXml) => {
	return async (dispatch) => {
		try {
			dispatch(agreementsActions.toggleLoading(true))
			const agreement = await api.agreement.sendIndustryAgreement(signedXml)
			dispatch(agreementsActions.setAgreementSuccess(true))
			message.success('Соглашение успешно отправлено')
		} catch (error) {
			dispatch(agreementsActions.toggleError(error))
			message.error(
				'Ошибка отправки соглашения:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(agreementsActions.toggleLoading(false))
		}
	}
}

export const getAgreement = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(agreementsActions.toggleLoading(true))
			const agreement = await api.agreement.getAgreement(externalId)
			dispatch(agreementsActions.setAgreement(agreement))
		} catch (error) {
			dispatch(agreementsActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(agreementsActions.toggleLoading(false))
		}
	}
}
