import { request } from '@/shared/api/request'
import { removeFromLocalStorage } from '@/shared'

export const getDictionaries = (dictName, params = {}, path, diffPath) => {
	async function getData(dictName) {
		const dictVersions = JSON.parse(localStorage.getItem('dictVersion'))
		const dictVersion = dictVersions.find((d) => d.code === dictName)
		const localDictVersion = localStorage.getItem(dictName + '_version')
		let result
		if (localDictVersion && +localDictVersion === dictVersion.version) {
			// Проверяем, доступны ли данные в кэше
			if ('caches' in window) {
				const cache = await caches.open('myCache') // Открываем кэш с именем 'myCache'
				const cacheResponse = await cache.match(dictName) // Проверяем наличие данных в кэше
				// Если данные доступны в кэше, возвращаем их
				if (cacheResponse) {
					return await cacheResponse.json()
				} else {
					// Если данные не доступны в кэше, запрашиваем их и записываем
					removeFromLocalStorage(dictName)
					result = await request({
						url: diffPath ? diffPath : (path ? path : '') + 'dict/' + dictName,
						method: 'GET',
						params,
					})
					const cache = await caches.open('myCache')
					const cacheData = new Response(JSON.stringify(result))
					await cache.put(dictName, cacheData)
				}
			}
		} else {
			result = await request({
				url: diffPath ? diffPath : (path ? path : '') + 'dict/' + dictName,
				method: 'GET',
				params,
			})
			localStorage.setItem(dictName + '_version', dictVersion.version)

			// Сохраняем данные в кэше
			if ('caches' in window) {
				const cache = await caches.open('myCache')
				const cacheResponse = await cache.match(dictName) // Проверяем наличие данных в кэше
				if (cacheResponse) {
					// Если данные доступны в кэше, удаляем их
					await caches.delete(dictName)
				}
				const cacheData = new Response(JSON.stringify(result))
				await cache.put(dictName, cacheData)
			}
		}

		return result
	}

	return getData(dictName)
}
