import React, { useEffect } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './AccidentForm.module.scss'
import { Form } from '@/entity/accidents/ui/form/Form'
import classNames from 'classnames'

export const AccidentForm = ({ onClose }) => {
	const { t } = useTranslation()

	return (
		<div className={classNames('scroll', styles.modal)}>
			<Title color="black">{t('accidentsPage.registration_new_accident')}</Title>
			<Form onClose={onClose} />
		</div>
	)
}
