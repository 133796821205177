import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setLaborRecords: (payload) => ({
		type: types.SET_LABOR_RECORDS,
		payload,
	}),
	toggleAdjustmentLoading: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_LOADING,
		payload,
	}),
	toggleAdjustmentSuccess: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_SUCCESS,
		payload,
	}),
	toggleAdjustmentError: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_ERROR,
		payload,
	}),
	setRequestsAdjustment: (payload) => ({
		type: types.SET_REQUESTS_ADJUSTMENT,
		payload,
	}),
	toggleAdjustmentRefresh: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_REFRESH,
		payload,
	}),
	toggleRequestLoading: (payload) => ({
		type: types.TOGGLE_REQUEST_LOADING,
		payload,
	}),
	toggleRequestSuccess: (payload) => ({
		type: types.TOGGLE_REQUEST_SUCCESS,
		payload,
	}),
	toggleRequestError: (payload) => ({
		type: types.TOGGLE_REQUEST_ERROR,
		payload,
	}),
	setRequestDetail: (payload) => ({
		type: types.SET_REQUEST_DETAIL,
		payload,
	}),
	setShortContractData: (payload) => ({
		type: types.SET_SHORT_CONTRACT_DATA,
		payload,
	}),
	setAdjustmentXml: (payload) => ({
		type: types.SET_ADJUSTMENT_XML,
		payload,
	}),
	toggleAdjustmentXmlLoading: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_XML_LOADING,
		payload,
	}),
	toggleAdjustmentXmlSuccess: (payload) => ({
		type: types.TOGGLE_ADJUSTMENT_XML_SUCCESS,
		payload,
	}),
	isLocalDigitContractAdded: (payload) => ({
		type: types.TOGGLE_LOCAL_DIGIT_SUCCESS,
		payload,
	}),
	resetAdjustmentXml: () => ({
		type: types.RESET_ADJUSTMENT,
	}),
	setCancelAdjustmentXml: (payload) => ({
		type: types.SET_CANCEL_ADJUSTMENT_XML,
		payload,
	}),
	toggleCancelAdjustmentXmlLoading: (payload) => ({
		type: types.TOGGLE_CANCEL_ADJUSTMENT_XML_LOADING,
		payload,
	}),
	toggleCancelAdjustmentXmlSuccess: (payload) => ({
		type: types.TOGGLE_CANCEL_ADJUSTMENT_XML_SUCCESS,
		payload,
	}),
	setDigitContractLocal: (payload) => ({
		type: types.DIGIT_CONTRACT_LOCAL,
		payload,
	}),
	isRefreshContracts: (payload) => ({
		type: types.REFRESH_CONTRACTS,
		payload,
	}),
}
