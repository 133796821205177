import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

export const requestTerminateSchema = yup.object().shape({
	terminationDate: transformStringDateFormatToDate().when('contractDate', (contractDate) => {
		if (contractDate)
			return transformStringDateFormatToDate()
				.min(contractDate, { key: 'date.end_before_start' })
				.max(new Date(), { key: 'date.can_not_be_after_now' })
				.nullable()
				.required({ key: 'required' })
	}),
	terminationReason: yup.string().required(),
	phone: yup.string().required({ key: 'required' }),
	email: yup.string().email('invalid_email').required('required'),
	comment: yup.string().required('required'),
})
