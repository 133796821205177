import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { useDispatch, useSelector } from 'react-redux'
import { pensionSelectors, pensionThunks } from '@/entity'
import styles from './Table.module.scss'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { Question } from '@/components/question/Question'

const Table = ({ getPensioner, onClose }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = useMemo(() => (lang === 'rus' ? 'nameRu' : 'nameKz'), [lang])
	const dispatch = useDispatch()
	const isLoading = useSelector(pensionSelectors.isLoading)
	const isSuccess = useSelector(pensionSelectors.isSuccess)
	const [pensioner, setPensioner] = useState(null)
	const persons = useSelector(pensionSelectors.persons)
	const fetchData = useCallback(() => {
		dispatch(pensionThunks.getPersonList())
	}, [dispatch])
	useEffect(() => fetchData(), [fetchData])
	const [actions, setActions] = useState({
		view: false,
	})
	const questionText = {
		kdp: {
			title: t('pension.modal.title'),
			data: t('pension.modal.data'),
		},
	}
	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			setPensioner(row)
			return { ...prevState, [action]: value }
		})
	}
	const fastView = (row) => {
		onToggleAction('view', true, row)
	}
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => fastView(row)}>
				<td style={{ width: 100 }}>{row.iin || '-'}</td>
				<td style={{ width: 480, textTransform: 'capitalize' }}>{row.fullName.toLowerCase()}</td>
				<td style={{ width: 100 }}>{row.dsex[optionLang] || '-'}</td>
				<td style={{ width: 100 }}>{row.birthDate || '-'}</td>
				<td style={{ width: 100 }}>{row.dayBeforeBirthday || '-'}</td>
			</tr>
		),
		[fastView, optionLang]
	)
	const tableBody = useMemo(() => persons?.map((row, index) => getTableRow(row, index)), [getTableRow, persons])
	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={5} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('pension.loading')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !persons?.length && (
						<div className={styles.tableLoading}>{t('pension.no_pension_workers')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, t, isSuccess, persons?.length]
	)
	const handleProcess = () => {
		dispatch(pensionThunks.getPhoneAndConsent(pensioner))
		getPensioner(pensioner)
		setActions({ view: false })
		onClose()
	}
	const closeProcess = () => {
		setActions({ view: false })
	}
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>
						<th style={{ width: 100 }}>{t('yin')}</th>
						<th style={{ width: 480 }}>{t('pension.contractor')}</th>
						<th style={{ width: 100 }}>{t('pension.gender')}</th>
						<th style={{ width: 100 }}>{t('pension.birthday')}</th>
						<th style={{ width: 100 }}>{t('pension.out_day_work')}</th>
					</tr>
				</thead>
				<tbody className={`scroll`}>{!!persons?.length ? tableBody : tableLoading}</tbody>
			</table>
			{actions.view ? (
				<Question
					data={questionText.kdp}
					processButtonTitle={'pension.modal.send_more'}
					handleProcess={handleProcess}
					handleCancel={closeProcess}
				></Question>
			) : null}
		</>
	)
}

export default Table
