export const sumStaffUnitsCount = (childs) => {
	if (!Array.isArray(childs)) {
		return 0
	}

	return childs.reduce((sum, child) => {
		if (!child) {
			return sum
		}

		let count = 0

		// Add up staffUnitsCount of the current child's workPlaces
		if (Array.isArray(child.workPlaces)) {
			count += child.workPlaces.reduce((sum, workPlace) => {
				return sum + (parseFloat(workPlace.staffUnitsCount) || 0)
			}, 0)
		}

		count += sumStaffUnitsCount(child.childs)

		return sum + count
	}, 0)
}
