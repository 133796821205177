import { connect, Field as FormikField, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from './EditContractDataField.module.scss'
import { Checkbox, Field, Title } from '@/ui'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { AdditionalContractPositionField } from './AdditionalContractPositionField'

export const AdditionalContractField = connect(
	({ positions = [], positionsByKnz = [], isView, formik: { setFieldValue, values }, isFormDirty }) => {
		const { t } = useTranslation()
		return (
			<div className={styles.wrapper}>
				<FieldArray name={formFieldNames.additionalContracts}>
					{() => (
						<>
							{values.additionalContracts.map((item, index) => (
								<div key={index} className={styles.gridWrapper}>
									<div className={'flex-1'}>
										{isView ? (
											<Title type={'h5'}>
												{t('labor_records.view_contract_num', {
													contractNumber: item.subContractNumber,
												})}
											</Title>
										) : (
											<Title type={'h5'}>
												{t('labor_records.add_contract_num', {
													contractNumber: item.subsidiaryContractNumber,
													registerDate: item.subsidiaryContractDate,
												})}
											</Title>
										)}
										<div className={styles.mLeft}>
											{isView ? null : (
												<FormikField name={`additionalContracts.${index}.subsidiaryContractNumberIs`}>
													{({ field }) => (
														<Checkbox
															{...field}
															label={t('add_contract_num')}
															onChange={() => setFieldValue(field.name, !field.value)}
														/>
													)}
												</FormikField>
											)}
											<FormikField
												name={
													isView
														? `additionalContracts.${index}.subContractNumber`
														: `additionalContracts.${index}.subsidiaryContractNumber`
												}
											>
												{({ field, meta: { touched, error } }) => (
													<Field
														error={(touched || isFormDirty) && error}
														{...field}
														label={isView ? t('add_contract_num') : null}
														disabled={!values.additionalContracts[index].subsidiaryContractNumberIs || isView}
													/>
												)}
											</FormikField>
										</div>
									</div>
									<AdditionalContractPositionField
										index={index}
										positions={positions}
										positionsByKnz={positionsByKnz}
										isView={isView}
									></AdditionalContractPositionField>
								</div>
							))}
						</>
					)}
				</FieldArray>
			</div>
		)
	}
)
