import { Title } from '@/ui'
import { Box } from '@mui/system'
import { Edit, Info, RefreshIcon } from '@app/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './../PensionPayments.module.scss'
import { getCurrentLanguage } from '@/i18next'
import Button from "@mui/material/Button";
export const Application = ({
	view,
	consentAndPhoneData,
	updateConsentAndPhoneData,
	updateConsentData,
	pension,
	newRecord,
	isButtonDisabled,
}) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const getConstText = () => {
		if (newRecord) {
			return (
				(lang === 'rus'
					? consentAndPhoneData.consentData?.responseNameRu
					: consentAndPhoneData.consentData?.responseNameKz) ?? ''
			)
		} else {
			return (lang === 'rus' ? pension.data.consentStatus?.nameRu : pension.data.consentStatus?.nameKz) ?? '-'
		}
	}
	const getNotifyColor = () => {
		switch (pension.data.notifType.code) {
			case '2':
			case '4':
				return '#eb5757' // red
			case '3':
			case '-1':
			case '0':
			case '1':
			case '-10':
				return '#17b67c' // green
			case '8':
			case '9':
			case '-11':
				return '#ffd500' // yellow
			default:
				return '#5792EB' // blue
		}
	}
	return (
		<>
			<div className={styles.title}>
				<Title>{t('pension.payments.form.labels.app_info')}</Title>
			</div>
			<div className={styles.container}>
				<div className="flex-1">
					<Box display="flex" alignItems="flex-start">
						<table className={styles.table}>
							<tbody>
								<tr>
									<td>{t('pension.payments.form.labels.app_id')}:</td>
									<td>{newRecord ? '-' : pension.data.responseNumber ?? '-'}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.app_creation_date')}:</td>
									<td>{newRecord ? '-' : pension.data.sendDate ?? '-'}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.app_last_modified_date')}:</td>
									<td>{newRecord ? '-' : pension.data.updateDate ?? '-'}</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.app_status')}:</td>
									<td>
										{newRecord
											? '-'
											: (lang === 'rus' ? pension.data.status?.nameRu : pension.data.status?.nameKz) ?? '-'}
									</td>
								</tr>
								{pension.data.notifType && (
									<tr>
										<td>{t('pension.payments.form.labels.note')}:</td>
										<td>
											<span style={{ color: getNotifyColor() }}>
												{lang === 'rus' ? pension.data.notifType?.nameRu : pension.data.notifType?.nameKz}
											</span>
										</td>
									</tr>
								)}

								<tr>
									<td>{t('pension.payments.form.labels.app_state')}:</td>
									<td>
										{newRecord
											? '-'
											: (lang === 'rus' ? pension.data.state?.nameRu : pension.data.state?.nameKz) ?? '-'}
									</td>
								</tr>
								<tr>
									<td>{t('pension.payments.form.labels.app_consent')}:</td>
									<td>
										{getConstText()}
										{!view && !pension.data.hasConsent && pension.data.externalId && (
											<>
												<Button
													variant="text"
													className={styles.buttonLink}
													onClick={() => updateConsentAndPhoneData()}
												>
													<span className={styles.updateButton} onClick={() => updateConsentData()}>
														<RefreshIcon className={styles.refreshIcon} />
														<span>{t('pension.payments.form.labels.update_status')}</span>
														<Tooltip
															placement="rightTop"
															color="white"
															overlayInnerStyle={{ color: '#5B6880' }}
															title={t('pension.payments.hints.update_status')}
														>
															<span>
																<Info className={styles.hintIcon} />
															</span>
														</Tooltip>
													</span>
												</Button>
												<Button
													disabled={isButtonDisabled}
													variant="text"
													className={styles.buttonLink}
													onClick={() => updateConsentAndPhoneData()}
												>
													<span className={styles.updateButton}>
														<Edit className={styles.refreshIcon} />
														<span>{t('pension.payments.form.labels.request_again')}</span>
														<Tooltip
															placement="rightTop"
															color="white"
															overlayInnerStyle={{ color: '#5B6880' }}
															title={t('pension.payments.hints.request_again')}
														>
															<span>
																<Info className={styles.hintIcon} />
															</span>
														</Tooltip>
													</span>
												</Button>
											</>
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</div>
			</div>
		</>
	)
}
