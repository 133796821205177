import { useEffect, useState } from 'react'

const UseEnbekEcoSystemLink = (lang) => {
	const [refEnbek, setRefEnbek] = useState()
	const [refSkills, setRefSkills] = useState()
	const [refBusiness, setBusiness] = useState()
	const [refCareer, setCareer] = useState()

	useEffect(() => {
		const prefix = lang === 'rus' ? 'ru' : 'kk'
		setRefEnbek(process.env.REACT_APP_REDIRECT_ENBEK + prefix)
		setRefSkills(process.env.REACT_APP_REDIRECT_SKILLS + prefix)
		setBusiness(process.env.REACT_APP_REDIRECT_BUSINESS)
		setCareer(process.env.REACT_APP_REDIRECT_CAREER + prefix)
	}, [lang])

	return { refEnbek, refSkills, refBusiness, refCareer }
}

export default UseEnbekEcoSystemLink
