export const headCells = [
	{
		id: 'iin',
		numeric: true,
		label: 'yin',
	},
	{
		id: 'contractor',
		numeric: false,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'contract_number',
		numeric: false,
		label: 'contracts_tab.contract_number',
	},
	{
		id: 'position',
		numeric: false,
		label: 'contracts_tab.position',
	},
]
