import React from 'react'
import { useTranslation } from 'react-i18next'
import { Arrow } from '@/app/icons'
import { Cross } from '@app/images'
import { Button } from '@/ui'
import styles from './ModalBackButton.module.scss'
import classnames from 'classnames'

export const ModalBackButton = ({ onClick, backButtonIsShown = true, size, noMargin }) => {
	const { t } = useTranslation()

	const containerClasses = classnames(styles.container, {
		[styles.containerNoMargin]: noMargin,
	})

	return (
		<div className={containerClasses}>
			{backButtonIsShown && (
				<Button transparent buttonClassName={styles.backButton} onClick={onClick} size={size}>
					<Arrow className={styles.iconGreen} /> {t('back')}
				</Button>
			)}
			<Button type="bare" onClick={onClick} buttonClassName={backButtonIsShown ? '' : styles.closeButton}>
				<div className={styles.cross}>
					<img src={Cross} alt="cross" />
				</div>
			</Button>
		</div>
	)
}
