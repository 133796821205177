import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SmallLightArrow } from '@app/images'
import { useClickOutside, useToggle } from '@/shared'
import { Checkbox } from '../checkbox'
import { Button } from '../button'
import styles from './style.module.scss'

const initialValue = []
export const SelectList = ({ options = [], onApply, placeholder, value: selectedValue, ...props }) => {
	const [isOpenedList, toggleOpenList] = useToggle(false)
	const listRef = useRef(null)

	const values = useMemo(() => selectedValue.map(({ value }) => value).join(', '), [selectedValue])

	const onChange = (item) => {
		const isPresent = selectedValue.find(({ key }) => key === item.key)
		if (isPresent) {
			onApply(selectedValue.filter(({ key }) => key !== item.key))
			return
		}
		onApply([...selectedValue, item])
	}

	const onReset = (e) => {
		e.stopPropagation()
		onApply(initialValue)
	}

	const onSubmit = (e) => {
		e.stopPropagation()
		onApply(selectedValue)
		toggleOpenList()
	}

	const onClickOutside = () => {
		toggleOpenList(false)
	}

	const onToggleOpenList = (e) => {
		e.stopPropagation()
		toggleOpenList()
	}

	useClickOutside(listRef, isOpenedList, onClickOutside)
	const { t } = useTranslation()
	return (
		<div className={styles.select} ref={listRef}>
			<div className={styles.active} onClick={onToggleOpenList}>
				<span>{selectedValue.length > 0 ? values : t(placeholder)}</span> <img src={SmallLightArrow} alt='arrow' className={styles.arrow} />
			</div>
			{isOpenedList && (
				<ul className={styles.list}>
					{options.map(({ key, value }) => {
						const isChecked = selectedValue.find((item) => item.key === key)
						return (
							<li key={key} className={styles.item}>
								<Checkbox onChange={() => onChange({ key, value })} value={!!isChecked} />
								<p>{value}</p>
							</li>
						)
					})}
					<div className={styles.actions}>
						<Button type='bare' onClick={onReset}>
							{t('cancel')}
						</Button>
						<Button onClick={onSubmit}>{t('apply')}</Button>
					</div>
				</ul>
			)}
		</div>
	)
}
