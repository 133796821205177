import React from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { ECollectiveForm } from './ECollectiveForm'
import { Formik } from 'formik'
import { EColContractValidationSchema } from '@/entity/eCollectiveContracts'

export const ECollectiveContractForm = ({ isTitle, initialValues, onSubmit, options, onCancel }) => {
	const { t } = useTranslation()

	const onSubmitMiddleWare = (data) => {
		onSubmit(data)
	}

	return (
		<div>
			{isTitle ? <Title color={'black'}>{t('contracts_tab.registration_new_collective_contract')}</Title> : null}
			<Formik
				validationSchema={EColContractValidationSchema(t)}
				initialValues={initialValues}
				onSubmit={onSubmitMiddleWare}
			>
				<ECollectiveForm options={options} onCancel={onCancel}></ECollectiveForm>
			</Formik>
		</div>
	)
}
