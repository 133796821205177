import { handbookSelectors } from '@/entity/handbook/index'
import { createSelector } from 'reselect'
import { contractsSelectors } from '@/entity'

export const options = createSelector(
	[
		handbookSelectors.contractTerms,
		handbookSelectors.positionsByKnz,
		handbookSelectors.positionsByKnzFull,
		handbookSelectors.positions,
		handbookSelectors.jobTypes,
		handbookSelectors.workingTimeModes,
		handbookSelectors.countries,
		handbookSelectors.regions,
		handbookSelectors.districts,
		handbookSelectors.populatedAreas,
		handbookSelectors.professionalSkills,
		handbookSelectors.personalQualities,
		handbookSelectors.remoteWorkTypes,
		handbookSelectors.contractTypes,
		handbookSelectors.socialLeaveTypes,
		handbookSelectors.workActivityTypes,
		handbookSelectors.workCondition,
		handbookSelectors.workTimeCollectiveContract,
		handbookSelectors.relaxTimeCollectiveContract,
		handbookSelectors.unionTypeCollectiveContract,
		handbookSelectors.extraGuaranteesCollectiveContract,
		handbookSelectors.workActivityTypeGroups,
		handbookSelectors.nonresidentAttractingBasis,
		handbookSelectors.nonresidentEducation,
		handbookSelectors.ccEnterpriseType,
		handbookSelectors.ccOwnershipType,
		handbookSelectors.staffType,
		handbookSelectors.staffStatus,
		handbookSelectors.staffEmpStatus,
		handbookSelectors.unionType,
		handbookSelectors.okedsTop,
	],
	(
		contractTerms,
		positionsByKnz,
		positionsByKnzFull,
		positions,
		jobTypes,
		workingTimeModes,
		countries,
		regions,
		districts,
		populatedAreas,
		professionalSkills,
		personalQualities,
		remoteWorkTypes,
		contractTypes,
		socialLeaveTypes,
		workActivityTypes,
		workCondition,
		workTimeCollectiveContract,
		relaxTimeCollectiveContract,
		unionTypeCollectiveContract,
		extraGuaranteesCollectiveContract,
		workActivityTypeGroups,
		nonresidentAttractingBasis,
		nonresidentEducation,
		ccEnterpriseType,
		ccOwnershipType,
		staffType,
		staffStatus,
		staffEmpStatus,
		unionType,
		okedsTop
	) => {
		return {
			contractTerms,
			positionsByKnz,
			positionsByKnzFull,
			positions,
			jobTypes,
			workingTimeModes,
			countries,
			regions,
			districts,
			populatedAreas,
			professionalSkills,
			personalQualities,
			remoteWorkTypes,
			contractTypes,
			socialLeaveTypes,
			workActivityTypes,
			workCondition,
			workTimeCollectiveContract,
			relaxTimeCollectiveContract,
			unionTypeCollectiveContract,
			extraGuaranteesCollectiveContract,
			workActivityTypeGroups,
			nonresidentAttractingBasis,
			nonresidentEducation,
			ccEnterpriseType,
			ccOwnershipType,
			staffType,
			staffStatus,
			staffEmpStatus,
			unionType,
			okedsTop,
		}
	}
)

export const viewAllOptions = createSelector(
	[handbookSelectors.contractStatuses, handbookSelectors.contractStates],
	(contractStatuses, contractStates) => {
		return { contractStatuses, contractStates }
	}
)

//todo исправить! нужно при fail очищать стейт
const checkContract = () => {
	return contractsSelectors
		? createSelector([contractsSelectors.contract, options, viewAllOptions], (contract, options, viewAllOptions) => {
				const {
					dpositionCode,
					destablishedPostCode,
					dworkingHoursCode,
					dregionCode,
					ddistrictCode,
					dlocalityCode,
					dcountryCode,
					dContractStatus,
					dContractState,
					dremoteWorkCode,
					dContractType,
					skillsList,
					specSkillsList,
					dpartTimeCode,
					district,
					region,
					locality,
					country,
					position,
					socialLeaves,
					dcontractDateCode,
					dharmfulConditionsTypeCode,
				} = contract || {}
				const lang = localStorage.getItem('lang') || 'rus'
				const optionsLang = lang === 'rus' ? 'nameRu' : 'nameKz'
				return {
					...contract,
					dpositionCode: options.positionsByKnz?.find((item) => item.code === dpositionCode)?.[lang],
					destablishedPostCode: options.positions?.find((item) => item.id === destablishedPostCode)?.[lang],
					dworkingHoursCode: options.workingTimeModes?.find((item) => item.code === dworkingHoursCode)?.[lang],
					dregionCode: options.regions?.find((item) => item.id === dregionCode)?.value,
					ddistrictCode: options.districts?.find((item) => item.id === ddistrictCode)?.value,
					dlocalityCode: options.populatedAreas?.find((item) => item.id === dlocalityCode)?.value,
					dcountryCode: options.countries?.find((item) => item.id === dcountryCode)?.value,
					dContractStatus: viewAllOptions.contractStatuses?.find((item) => item.id === dContractStatus)?.value,
					dContractState: viewAllOptions.contractStates?.find((item) => item.id === dContractState)?.value,
					dremoteWorkCode: options.remoteWorkTypes?.find((item) => item.code === dremoteWorkCode)?.[lang],
					dContractType: options.contractTypes?.find((item) => item.id === dContractType)?.value,
					skillsList: options.personalQualities
						?.filter(({ code }) => skillsList?.includes(code))
						?.map((item) => item[lang]),
					specSkillsList: options.professionalSkills
						?.filter(({ code }) => specSkillsList?.includes(code))
						?.map((item) => item[lang]),
					skillsListFull: skillsList,
					specSkillsListFull: specSkillsList,
					dpartTimeCode: options.jobTypes?.find((item) => item.code === dpartTimeCode)?.[lang],
					region: region ? region[optionsLang] : null,
					district: district ? district[optionsLang] : null,
					locality: locality ? locality[optionsLang] : null,
					country: country ? country[optionsLang] : null,
					position: position ? position[optionsLang] : null,
					socialLeaves: socialLeaves?.map((item) => ({
						...item,
						dleaveTypeCode:
							{
								name: options.socialLeaveTypes?.find((type) => type.code === item.dleaveTypeCode)?.[lang],
								value: item.dleaveTypeCode,
							} || item.dleaveTypeCode,
					})),
					dcontractDateCode: options?.contractTerms?.find((item) => item.code === dcontractDateCode)?.[lang],
					harmfulConditions: options.workActivityTypes?.find((item) => item.code === dharmfulConditionsTypeCode)?.[
						lang
					],
					dharmfulConditionsTypeCode: dharmfulConditionsTypeCode,
				}
		  })
		: {}
}
export const contractView = checkContract
