export const formFieldNames = {
	bin: 'bin',
	regActionDate: 'regActionDate',
	companyName: 'companyName',
	companyNameKaz: 'companyNameKaz',
	certificateExpiresAt: 'certificateExpiresAt',
	photoId: 'photoId',
	dokedCode: 'dokedCode',
	downershipTypeCode: 'downershipTypeCode',
	ddistrictCode: 'ddistrictCode',
	dregionCode: 'dregionCode',
	dlocalityCode: 'dlocalityCode',
	street: 'street',
	house: 'house',
	office: 'office',
	website: 'website',
	employeeCnt: 'employeeCnt',
	about: 'about',
	lastname: 'lastname',
	firstname: 'firstname',
	middlename: 'middlename',
	officePhone: 'officePhone',
	officePhoneInternal: 'officePhoneInternal',
	mobilePhone: 'mobilePhone',
	email: 'email',
}
