//Статус заявки
export const colors = {
	1: '#AAB8D1', //Проект заявки
	2: '#AAB8D1', //В обработке
	3: '#17B67C', //Принято решение
	4: '#EB5757', //Отказано
	5: '#5792EB', //Не отправлено
	6: '#ffd500', //Возвращено
}
export const colorsState = {
	1: '#17B67C', //Завершено
	2: '#EB5757', //Просрочено
	3: '#17B67C', //Отправлено
	4: '#AAB8D1', //Осталось XX
}
