import { createSelector } from 'reselect'
import { companySelectors } from '@/entity/company'
import { handbookSelectors } from '@/entity/handbook'

export const activeCompany = createSelector(
	[companySelectors.company, handbookSelectors.singleOptions],
	(company, singleOptions) => {
		const { district, oked, ownershipType, populatedArea, region } = singleOptions
		return {
			...company,
			downershipTypeCode: ownershipType,
			ddistrictCode: district,
			dregionCode: region,
			dlocalityCode: populatedArea,
			dokedCode: oked,
		}
	}
)
