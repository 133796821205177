export const TOGGLE_LOADING = 'industryAgreement/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'industryAgreement/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'industryAgreement/SET_CONTRACT_ERROR'
export const SET_AGREEMENTS = 'industryAgreement/SET_AGREEMENTS'
export const SET_AGREEMENT = 'industryAgreement/SET_AGREEMENT'
export const SET_AGREEMENT_LOADING = 'industryAgreement/SET_AGREEMENT_LOADING'
export const SET_AGREEMENT_SUCCESS = 'industryAgreement/SET_AGREEMENT_SUCCESS'
export const SET_AGREEMENT_XML = 'industryAgreement/SET_AGREEMENT_XML'
export const RESET_AGREEMENT_XML = 'industryAgreement/RESET_AGREEMENT_XML'
export const SET_AGREEMENT_ERROR = 'industryAgreement/SET_AGREEMENT_ERROR'
