export const company = (state) => state.company.company

export const isLoading = (state) => state.company.isLoading

export const isSuccess = (state) => state.company.isSuccess

export const companies = (state) => state.company.companies

export const activeCompanyId = (state) => state.company.activeCompanyId

export const xmlToSign = (state) => state.company.xmlToSign
