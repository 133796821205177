import { isValidElement, useMemo } from 'react'
import { Input, Icon } from '@/ui/atoms'
import React from 'react'
import styles from './style.module.scss'

export const InputWithIcon = ({ icon, iconSide = 'left', inputClasses = '', iconClasses = '', onClick, valueIcon, ...props }) => {
	const isReactElement = useMemo(() => isValidElement(icon), [icon])

	return (
		<div className={styles.field}>
			{icon && !props.disabled && (
				<span
					onClick={onClick}
					className={`${styles.icon} ${iconSide === 'left' ? styles.left : styles.right} ${iconClasses}`}
				>
					{isReactElement ? icon : <Icon src={icon} />}
				</span>
			)}
			{valueIcon && (
				<span
					onClick={onClick}
					className={`${styles.icon} ${styles.left__select} ${iconClasses} ${styles.icon__wrapper}`}
				>
					{isReactElement ? valueIcon : <Icon src={valueIcon} />}
				</span>
			)}
			<Input onClick={onClick} classes={`${inputClasses} ${styles.input}`} {...props} />
		</div>
	)
}
