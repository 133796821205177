import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styles from './LaborRecords.module.scss'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { Close8, Preference } from '@app/images'
import { DatePicker } from '@/components/DatePicker'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import { laborRecordsSelectors } from '@/entity'
import Box from '@mui/material/Box'
import { Download } from '@app/icons'
import { ExportModal } from '@features/laborRecords/components/exportModal'

const HrButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'capitalize',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	backgroundColor: '#17B67C',
	fontFamily: 'Inter',
	'&:hover': {
		backgroundColor: '#17B67C',
		boxShadow: 'none',
		color: 'white',
	},
})

const HrResetButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	fontFamily: 'Inter',
	height: '32px',
	fontWeight: 700,
	color: '#AAB8D1',
	'&:hover': {
		backgroundColor: 'transparent',
		boxShadow: 'none',
	},
})
const LaborRecordsSearch = ({ filters, setFilters, fetchData, resetFilter }) => {
	const { t } = useTranslation()
	const [advancedSearch, setAdvancedSearch] = useState(false)
	const [openExportModal, setExportModal] = useState(false)
	const contractList = useSelector(laborRecordsSelectors.contractList)
	const isSuccess = useSelector(laborRecordsSelectors.isSuccess)
	const isLoading = useSelector(laborRecordsSelectors.isLoading)

	const updateFilter = (e, name) =>
		setFilters({ ...filters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })
	const closeModal = () => setExportModal(false)
	return (
		<>
			<div className={styles.searchRow}>
				<div className={styles.searchField}>
					<div className={styles.searchField__left}>
						<IconButton type="submit" aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							fullWidth
							placeholder={t('labor_records.searchInput')}
							inputProps={{
								'aria-label': 'search',
							}}
							value={filters.srcText}
							onChange={(e) => updateFilter(e, 'srcText')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault()
									fetchData()
								}
							}}
						/>
					</div>
					<span className={styles.searchField__button} onClick={() => fetchData()}>
						{t('find')}
					</span>
				</div>
				<div className={styles.advancedSearch__button} onClick={() => setAdvancedSearch(!advancedSearch)}>
					{advancedSearch ? (
						<>
							<img src={Close8} className={styles.advancedSearch__icon} />
							{t('contracts_tab.advancedSearchHide')}
						</>
					) : (
						<>
							<img src={Preference} className={styles.advancedSearch__icon} />
							{t('contracts_tab.advancedSearch')}
						</>
					)}
				</div>
				{!!contractList?.length && isSuccess && !isLoading && (
					<Box
						onClick={setExportModal.bind(null, true)}
						className={styles.export__wrapper}
						display="flex"
						alignItems="center"
					>
						<Download />
						<div className={styles.export__text}>{t('labor_records.export')}</div>
					</Box>
				)}
			</div>
			{advancedSearch && (
				<div className={styles.advancedSearch__row}>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('labor_records.dateFrom')}</div>
						<DatePicker
							wrapperClasses={styles.advancedSearch__input}
							inputClassName={styles.advancedSearch__datePickerInput}
							value={filters.dateFrom}
							onChange={(e) => updateFilter(e, 'dateFrom')}
							iconClasses={styles.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('labor_records.dateTo')}</div>
						<DatePicker
							wrapperClasses={styles.advancedSearch__input}
							inputClassName={styles.advancedSearch__datePickerInput}
							value={filters.dateTo}
							onChange={(e) => updateFilter(e, 'dateTo')}
							iconClasses={styles.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={styles.advancedSearch__field}>
						<div className={styles.advancedSearch__label}>{t('bin_iin')}</div>
						<input
							type="text"
							className={styles.advancedSearch__input}
							value={filters.bin}
							onChange={(e) => updateFilter(e, 'bin')}
							placeholder={t('labor_records.bin_iin_placeholder')}
						/>
					</div>
					<div className={styles.advancedSearch__search_name}>
						<div className={styles.advancedSearch__label}>{t('name')}</div>
						<input
							type="text"
							className={styles.advancedSearch__input}
							value={filters.orgName}
							onChange={(e) => updateFilter(e, 'orgName')}
							placeholder={t('labor_records.orgName')}
						/>
					</div>
					<div className={styles.advancedSearch__searchButtonsRow}>
						<HrResetButton variant="text" onClick={resetFilter}>
							{t('reset_all')}
						</HrResetButton>
						<HrButton style={{ height: '32px' }} variant="contained" onClick={() => fetchData()}>
							{t('search')}
						</HrButton>
					</div>
				</div>
			)}
			{openExportModal && <ExportModal handleCancel={closeModal} />}
		</>
	)
}

export default LaborRecordsSearch
