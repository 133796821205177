import { Checkbox, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import styles from './EditContractDataField.module.scss'
import { connect, Field as FormikField } from 'formik'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { ContractPositionField } from './ContractPositionField'
import { Chevron } from '@/ui/atoms/chevron'

export const EditContractDataField = connect(
	({ positions = [], positionsByKnz = [], isView, formik: { setFieldValue, values } }) => {
		const { t } = useTranslation()
		const [chevron, setChevron] = useState(true)
		const changeChevron = () => {
			chevron ? setChevron(false) : setChevron(true)
		}
		const title = useMemo(() => t('contract_info'), [t])

		return (
			<div className={styles.wrapper}>
				<Chevron chevron={chevron} title={title} changeChevron={changeChevron}></Chevron>
				{chevron ? (
					<div className={styles.gridWrapper}>
						<div className={'flex-1'}>
							{isView ? null : (
								<FormikField name={formFieldNames.contractNumberIs}>
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('labor_records.contract_employer_number')}
											onChange={() => setFieldValue(field.name, !field.value)}
										/>
									)}
								</FormikField>
							)}
							<FormikField name={formFieldNames.contractNumber}>
								{({ field, meta: { touched, error } }) => (
									<Field
										error={touched && error}
										{...field}
										label={isView ? t('labor_records.contract_employer_number') : null}
										disabled={!values.contractNumberIs || isView}
									/>
								)}
							</FormikField>
						</div>
						<ContractPositionField
							positions={positions}
							positionsByKnz={positionsByKnz}
							isView={isView}
						></ContractPositionField>
					</div>
				) : null}
			</div>
		)
	}
)
