import { request } from '@/shared/api/request'

export const downloadPdfById = (id, methodCode) =>
	request({
		url: `contract/persCard/pdfById?reqId=${id}&method=${methodCode}`,
		method: 'GET',
		responseType: 'blob',
	})

export const downloadEmployeePdf = (methodCode) => {
	return request({
		url: `contract/persCard/employee/pdf/?method=${methodCode}`,
		method: 'GET',
		responseType: 'blob',
	})
}

export const downloadPdfByCorrId = (id, methodCode) =>
	request({
		url: `contract/persCard/pdf?corrId=${id}&method=${methodCode}`,
		method: 'GET',
		responseType: 'blob',
	})
