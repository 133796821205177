import { Route } from 'react-router-dom'
import PreHeader from './components/PreHeader'
import Header from './components/Header'
import Worker from './components/Worker'
import Employer from './components/Employer'
import Form from './components/Form'
import Info from './components/Info'
import Footer from './components/Footer'
import Sidebar from '../home/components/Sidebar'
import Banners from './components/Banners'
import { useState } from 'react'
import MobileAppButton from '@/widgets/header/MobileAppButton/MobileAppButton'

const Home = () => {
	const [sidebar, setSidebar] = useState(false)
	const [appButton, setAppButton] = useState(true)
	return (
		<div className="rep">
			<Route
				exact
				path="/"
				render={() => (
					<>
						{/*раскомментировать в случае траура*/}
						{/*<PreHeader />*/}
						<Sidebar setSidebar={setSidebar} sidebar={sidebar} />
						{appButton ? <MobileAppButton setAppButton={setAppButton} /> : null}
						<Header setSidebar={setSidebar} appButton={appButton} />
						<Info />
						<Employer />
						<Worker />
						{/*<Banners />*/}
						<Form />
						<Footer />
					</>
				)}
			/>
		</div>
	)
}

export default Home
