import React, { useState, useMemo } from 'react'
import { Formik } from 'formik'
import { code1Schema, code2Schema } from './socialLeave.schema'
import { Title } from '@/ui'
import styles from '../../additionalContracts/form/AdditionalContractForm.module.scss'
import { useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { useTranslation } from 'react-i18next'
import { contractsSelectors } from '@/entity'
import { Form } from './Form'

export const SocialLeaveForm = ({ onCancel, onSubmit, socialLeave }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const socialLeaveTypes = useSelector(handbookSelectors.socialLeaveTypes)
	const contract = useSelector(contractsSelectors.contract)
	const [isLocal, setIsLocal] = useState(false)
	const [hideFields, setHideFields] = useState(socialLeave ? socialLeave.dleaveTypeCode.value === '2' : true)
	const initialValues = useMemo(
		() =>
			socialLeave
				? {
						...socialLeave,
						dleaveTypeCode: socialLeave.dleaveTypeCode.value,
						departmentRu: socialLeave.departmentRu || '',
						departmentKz: socialLeave.departmentKz || '',
				  }
				: hideFields
				? { beginDate: '', endDate: '' }
				: {
						dleaveTypeCode: '',
						beginDate: '',
						endDate: '',
						firstDayDate: '',
						daysOff: 0,
						timeSheetNum: '',
						departmentRu: '',
						departmentKz: '',
				  },
		[hideFields, socialLeave]
	)

	const handleSubmit = (data) => {
		onSubmit(
			{
				...data,
				paramMap: {
					leavetype: socialLeaveTypes?.find((item) => item.code === data.dleaveTypeCode)?.[language],
				},
			},
			isLocal
		)
	}

	return (
		<>
			<div className={styles.mainInfoRow}>
				<div className={styles.row}>
					<p className={styles.dataCol}>{t('worker_iin')}:</p>
					<p className={styles.greyText}>{contract?.iin}</p>
				</div>
				<div className={styles.row}>
					<p className={styles.dataCol}>{t('personal_short_information')}:</p>
					<p className={styles.greyText}>{contract?.fullName}</p>
				</div>
			</div>

			<Title>{t('social_leave.info')}</Title>
			<Formik
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validationSchema={hideFields ? code2Schema : code1Schema}
			>
				<Form
					socialLeave={socialLeave}
					onCancel={onCancel}
					contract={contract}
					socialLeaveTypes={socialLeaveTypes}
					setIsLocal={setIsLocal}
					setHideFields={setHideFields}
					hideFields={hideFields}
				/>
			</Formik>
		</>
	)
}
