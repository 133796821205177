import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	isLoadingSend: false,
	isSuccessSend: false,
	error: null,
	accidents: {
		content: [],
		pageable: null,
		totalPages: 0,
		totalElements: 0,
		last: false,
		number: 0,
		sort: null,
		size: 0,
		first: false,
		numberOfElements: 0,
		empty: false,
	},
	selectedAccidents: [],
	reportXml: {},
	accident: null,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_LOADING_SEND:
			return { ...state, isLoadingSend: payload }
		case types.TOGGLE_SUCCESS_SEND:
			return { ...state, isSuccessSend: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_ACCIDENTS:
			return {
				...state,
				accidents: payload,
			}
		case types.SET_REPORT_XML:
			return {
				...state,
				reportXml: payload,
			}
		case types.SET_ACCIDENT:
			return {
				...state,
				accident: payload,
			}
		case types.RESET_REPORT_XML:
			return {
				...state,
				reportXml: {},
			}
		default:
			return state
	}
}
