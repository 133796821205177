import styles from '../styles.module.scss'
import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export const TransferList = ({ listIncome, listOutcome, isIn, setIsIn, onViewTransfer }) => {
	const { t, i18n } = useTranslation()
	const [order, setOrder] = useState('asc')
	const [orderBy, setOrderBy] = useState('')

	const headCells = [
		{
			id: 'name',
			numeric: true,
			disablePadding: false,
			label: t('transfer_request_form_date'),
		},
		{
			id: 'fat',
			numeric: true,
			disablePadding: false,
			label: isIn ? t('transfer_bin_to') : t('transfer_bin_from'),
		},
		{
			id: 'carbs',
			numeric: true,
			disablePadding: false,
			label: isIn ? t('transfer_name_to') : t('transfer_name_from'),
		},
		{
			id: 'protein',
			numeric: true,
			disablePadding: false,
			label: t('transfer_status_request'),
		},
	]

	const renderTableRows = useCallback(
		(list) => {
			return list.map((row) => (
				<TableRow key={row.id} hover role="checkbox" onClick={() => onViewTransfer(row)}>
					<TableCell align="left">{moment(row.createDate).format('DD.MM.YYYY')}</TableCell>
					<TableCell align="left">{isIn ? row.fromBin : row.toBin}</TableCell>
					<TableCell align="left">
						{i18n.language === 'rus' ? (isIn ? row.fromNameRu : row.toNameRu) : isIn ? row.fromNameKz : row.toNameKz}
					</TableCell>
					<TableCell align="left">{i18n.language === 'rus' ? row.status.nameRu : row.status.nameKz}</TableCell>
				</TableRow>
			))
		},
		[i18n.language, isIn, onViewTransfer]
	)

	return (
		<>
			<Box display="flex" alignItems="center" className={styles.filter}>
				<Box
					onClick={() => setIsIn(false)}
					className={`${styles.filter__item} ${!isIn ? styles.filter__item__active : ''}`}
				>
					{t('transfer_request_outgoing')} ({listOutcome?.length})
				</Box>
				<Box
					onClick={() => setIsIn(true)}
					className={`${styles.filter__item} ${isIn ? styles.filter__item__active : ''}`}
				>
					{t('transfer_request_incoming')} ({listIncome?.length})
				</Box>
			</Box>

			<Box sx={{ width: '100%' }}>
				<TableContainer classes={{ root: styles.table__container }}>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										key={headCell.id}
										align="left"
										padding={headCell.disablePadding ? 'none' : 'normal'}
										sortDirection={orderBy === headCell.id ? order : false}
										classes={{ root: styles.table__head__row }}
									>
										{t(headCell.label)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{Boolean((isIn ? listIncome : listOutcome)?.length) && renderTableRows(isIn ? listIncome : listOutcome)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	)
}
