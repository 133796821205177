import React from 'react'

export const Edit = ({ className = '', fill = 'white' }) => {
	return (
		<svg
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_2901_25410)">
				<path d="M11.3279 7.08375C11.0749 7.08375 10.8699 7.28878 10.8699 7.54177V11.0838H1.06838V1.28224H4.61037C4.86335 1.28224 5.06838 1.07721 5.06838 0.824227C5.06838 0.571242 4.86335 0.366211 4.61037 0.366211H0.610359C0.357375 0.366211 0.152344 0.571242 0.152344 0.824227V11.5418C0.152344 11.7948 0.357398 11.9998 0.610359 11.9998H11.3279C11.5809 11.9998 11.7859 11.7948 11.7859 11.5418V7.54177C11.7859 7.28881 11.5809 7.08375 11.3279 7.08375Z" />
				<path d="M12.0177 1.7678L10.3843 0.134203C10.2985 0.0482344 10.182 0 10.0605 0C9.93898 0 9.82264 0.0482344 9.73669 0.134203L4.3321 5.53877C4.27653 5.5942 4.23638 5.6629 4.215 5.73832L3.57378 8.01314C3.52889 8.17268 3.57364 8.34398 3.69087 8.46124C3.77789 8.54827 3.89485 8.59545 4.01468 8.59545C4.05605 8.59545 4.09789 8.5898 4.13881 8.57834L6.41363 7.93711C6.48919 7.91573 6.55791 7.87542 6.61332 7.82002L12.0177 2.41545C12.1967 2.23664 12.1967 1.94655 12.0177 1.7678ZM6.05025 7.08778L4.67729 7.47464L5.06431 6.10198L10.0605 1.1058L11.0463 2.09161L6.05025 7.08778Z" />
				<path d="M4.99538 5.5222L4.34766 6.16992L5.98099 7.80325L6.62871 7.15553L4.99538 5.5222Z" />
			</g>
			<defs>
				<clipPath id="clip0_2901_25410">
					<rect width="12" height="12" fill={fill} transform="translate(0.152344)" />
				</clipPath>
			</defs>
		</svg>
	)
}
