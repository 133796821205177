import { useState, useEffect } from 'react'

const initialState = {}
export const useError = () => {
	const [errors, setError] = useState(initialState)

	const onToggleError = (field, message) => setError({ [field]: message })

	useEffect(() => {
		const timeout = setTimeout(() => setError(initialState), 10000)
		return () => clearTimeout(timeout)
	}, [errors])

	return { errors, setError: onToggleError }
}
