import { contractActionsSelectors } from '@features/contracts/model'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContractCard } from './ContractCard'

export const View = ({
	onToggleAction,
	setSocialLeave,
	forceSign = false,
	setForceSubmitEdit,
	updateList,
	onTermination,
}) => {
	const contract = useSelector(contractActionsSelectors.contractView())
	const dispatch = useDispatch()

	const onClose = useCallback(() => {
		forceSign ? onToggleAction('sign', false) : onToggleAction('view', false)
	}, [onToggleAction, forceSign])
	const onDelete = useCallback(() => {
		onToggleAction('remove', true)
	}, [onToggleAction])
	const onEdit = useCallback(() => onToggleAction('edit', true), [onToggleAction])
	const onTerminate = useCallback(() => {
		onToggleAction('terminate', true)
	}, [onToggleAction])
	const handleAdditionalContractCreateClick = useCallback(() => {
		onToggleAction('createAdditionalAgreement', true)
	}, [onToggleAction])
	const handleProlongationClick = useCallback(() => {
		onToggleAction('prolongation', true)
	}, [onToggleAction])

	const onSignPackage = useCallback(() => {
		onToggleAction('signPackage', true)
	}, [onToggleAction])

	const onSignSome = useCallback(() => {
		onToggleAction('signSome', true)
	}, [onToggleAction])

	const handleAdditionalContractViewClick = useCallback(
		(contract) => {
			onToggleAction('viewAdditionalContract', true)
		},
		[onToggleAction, dispatch]
	)
	const handleSocialLeavesClick = useCallback(
		(value) => {
			if (value === 'create') value = 'createSocialLeave'
			if (value === 'view') value = 'viewSocialLeave'
			if (value === 'remove') value = 'removeSocialLeave'
			if (!value) return
			onToggleAction(value, true)
		},
		[onToggleAction]
	)
	const onEditTermination = useCallback(() => onToggleAction('editTermination', true), [onToggleAction])
	const onRecover = useCallback(() => onToggleAction('recover', true), [onToggleAction])

	return (
		<ContractCard
			onClose={onClose}
			onDelete={onDelete}
			onSignPackage={onSignPackage}
			onSignSome={onSignSome}
			onRecover={onRecover}
			onTerminate={onTerminate}
			onProlong={handleProlongationClick}
			contract={contract}
			onAdditionalContractView={handleAdditionalContractViewClick}
			onAdditionalContractCreate={handleAdditionalContractCreateClick}
			handleSocialLeavesClick={handleSocialLeavesClick}
			setSocialLeave={setSocialLeave}
			onToggleAction={onToggleAction}
			onUpdateIin={onToggleAction.bind(null, 'update', true)}
			onEdit={onEdit}
			forceSign={forceSign}
			onEditTermination={onEditTermination}
			setForceSubmitEdit={setForceSubmitEdit}
			updateList={updateList}
			onTermination={onTermination}
		/>
	)
}
