import { request } from '@/shared/api/request'

export const uploadFile = (payload, fileType = '') => {
	const url = `/file/s3/upload${fileType ? `/${fileType}` : ''}`
	return request({
		url: url,
		method: 'POST',
		data: payload,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}
