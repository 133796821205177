import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './SocialModal.module.scss'
import React from 'react'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
export const SocialModal = ({ onClose, cardInfo }) => {
	const history = useHistory()
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const openContract = () => {
		history.push(`/contracts`, { refValue: cardInfo.id, local: false })
		onClose()
	}
	const getColor = (code, li = true) => {
		switch (code) {
			case 'afrorefuse':
			case 'autorefuse_alr_have':
			case 'autorefuse_bmg':
			case 'afrorefuse_regadr':
			case 'autorefuse_noanswer':
			case 'autorefuse_regadr':
			case 'autorefuse':
			case 'end':
				return li ? styles.red_li : styles.red
			case 'getDocPhone':
			case 'inComeEhrTrud':
			case 'ENPF_Possibility':
			case 'smsOSNS':
			case 'IBAN_Logic':
			case 'sendFullNotifications':
			case 'getFullNotifications':
			case 'waitKSZH_order':
			case 'ok':
				return li ? '' : styles.gray400
			case 'waitKSZH_waiting':
			case 'waitKSZH_error':
				return li ? styles.yellow_li : styles.yellow
			case 'in_cbd':
				return li ? styles.green_li : styles.green
			default:
				return ''
		}
	}
	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div>
						<Title color="black">{t('payment.title')}</Title>
						<p className={styles.gray400}>
							{t('request_id')}: {cardInfo.id}
						</p>
						<p className={styles.gray}>
							{t('transfer_request_date')}: {cardInfo.requestDate}
						</p>
						<p className={styles.gray}>
							{t('transfer_status_request')}:{' '}
							<span>{language === 'rus' ? cardInfo.requestStatusRu : cardInfo.requestStatusKz}</span>
						</p>
						<div className={styles.title}>{t('employee')}:</div>
						<p className={styles.gray}>
							{t('yin')}: {cardInfo.iin}
						</p>
						<p className={styles.gray}>
							{t('fio')}: {cardInfo.fio}
						</p>
					</div>
					<Button onClick={openContract} buttonClassName={styles.openContractButton}>
						{t('notification_open_contract')}
					</Button>
					<Title>{t('payment.status_history')}</Title>
					<div className={`${styles.list} ${styles.gray}`}>
						<ul>
							{cardInfo.statusHistories.map((history, imdex) => (
								<li key={imdex} className={getColor(history.messageCode)}>
									<span>{history.requestDate}</span>
									<span> - </span>
									<span className={getColor(history.messageCode, false)}>
										{language === 'rus' ? history.messageRu : history.messageKz}
									</span>
									<div>{language === 'rus' ? history.nodeRu : history.nodeKz}</div>
								</li>
							))}
						</ul>
					</div>
					<Button onClick={onClose} buttonClassName={styles.closeButton}>
						{t('close')}
					</Button>
				</div>
			</Modal>
		</>
	)
}
