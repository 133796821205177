import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/ui'
import styles from './PackageLoadModals.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const Delete = ({ handleProcess, handleCancel }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			cancelButtonTitle={'no_cancel'}
			processButtonTitle={'delete_yes'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<p className={styles.title}>{t('unsigned_documents_will_removed_your_account')}</p>
				{lang === 'rus' ? (
					<p className={styles.text}>
						Вы уверены, что хотите удалить{' '}
						<span className={styles.highlighted__text}>
							неподписанные документы <br />
						</span>{' '}
						без возможности восстановления?
					</p>
				) : (
					<p className={styles.text}>
						Қол қойылмаған құжаттарды{' '}
						<span className={styles.highlighted__text}>
							қалпына келтірусіз жойғыңыз <br />
						</span>{' '}
						келетініне сенімдісіз бе?
					</p>
				)}
			</div>
		</ConfirmModal>
	)
}
