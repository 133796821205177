import { Logo as LogoImage } from '@/app/images'
import { Link } from 'react-router-dom'
import enbekLogo from '../../../assets/home/enbek-logo.svg'
import businessEnbekLogo from '../../../assets/home/business-enbek-logo.svg'
import skillsEnbekLogo from '../../../assets/home/skills-enbek-logo.svg'
import careerEnbekLogo from '../../../assets/home/career-enbek-logo.svg'
import appStoreRu from '../../../assets/home/app-store-ru.svg'
import googlePlay from '../../../assets/home/google-apps-ru.svg'
import logoInstagram from '../../../assets/home/instagram.svg'
import { useTranslation } from 'react-i18next'
import LangFooter from '@/components/LangFooter'
import { useEffect, useMemo, useState } from 'react'
import { ModalFeedback } from '@/components/ModalFeedback/ModalFeedback'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'

import { mainSelectors } from '@app/store/main'
import { userSelectors } from '@/entity'

const Footer = () => {
	const isSuccessSend = useSelector(mainSelectors.isSuccessSend)
	const activeRole = useSelector(userSelectors.activeRole)
	const companies = useSelector(userSelectors.employerCompanies)
	const user = useSelector(userSelectors.user)
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [modalShow, setModalShow] = useState(false)
	let thisYear = new Date().getFullYear()
	const [urlAuth, setUrlAuth] = useState()
	const [refEnbek, setRefEnbek] = useState()
	const [refSkills, setRefSkills] = useState()
	const [refBusiness, setBusiness] = useState()
	const [refCareer, setCareer] = useState()
	const [refTerms, setRefTerms] = useState()
	const [refContract, setRefContract] = useState('/contracts')
	const [googlePlayLink, setGooglePlayLink] = useState()
	const [appStoreLink, setAppStoreLink] = useState()
	const [instagramLink, setInstagramLink] = useState()
	useMemo(() => setGooglePlayLink(process.env.REACT_APP_MOBILE_BANNER_GOOGLE), [])
	useMemo(() => setAppStoreLink(process.env.REACT_APP_MOBILE_BANNER_APPLE), [])
	useMemo(() => setInstagramLink(process.env.REACT_APP_INSTAGRAM_LINK), [])

	useEffect(() => {
		if (isSuccessSend) setModalShow(false)
	}, [isSuccessSend])

	useMemo(
		() =>
			lang === 'rus'
				? [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbek(process.env.REACT_APP_REDIRECT_ENBEK + 'ru'),
						setRefSkills(process.env.REACT_APP_REDIRECT_SKILLS + 'ru'),
						setBusiness(process.env.REACT_APP_REDIRECT_BUSINESS),
						setCareer(process.env.REACT_APP_REDIRECT_CAREER + 'ru'),
						setRefTerms(process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_RU),
				  ]
				: [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbek(process.env.REACT_APP_REDIRECT_ENBEK + 'kk'),
						setRefSkills(process.env.REACT_APP_REDIRECT_SKILLS + 'kk'),
						setBusiness(process.env.REACT_APP_REDIRECT_BUSINESS),
						setCareer(process.env.REACT_APP_REDIRECT_CAREER + 'kk'),
						setRefTerms(process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_KZ),
				  ],
		[lang]
	)

	useMemo(
		() =>
			(activeRole === 'ROLE_EMPLOYER' && !companies.length) || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
				? setRefContract('/reference-information/3')
				: setRefContract('/contracts'),
		[activeRole, companies.length]
	)

	const handleClickFeedback = () => {
		setModalShow(true)
	}

	const handleClose = () => {
		setModalShow(false)
	}

	return (
		<div className="footer">
			<div className="container-footer">
				<ModalFeedback visible={modalShow} onClose={handleClose} />
				<div className="footer-wrapper">
					<div className="footer-support">
						<div className="block-left">
							<span>{t('difficulties')}</span>
							<div className="block-left_contact">
								<p>{t('contact_technical_support')}</p>
							</div>
						</div>
						<div className="block-right">
							<div className="greenBtn" onClick={handleClickFeedback}>
								<button>{t('send_message')}</button>
							</div>
						</div>
					</div>
					<div className="footer-menu">
						<div className="footer-menu-logo">
							<div className="footer-menu-logo-soc">
								<Link to={'/'}>
									<img src={LogoImage} alt="enbek" />
								</Link>
								<p>{t('digital_ecosystem')}</p>
								<a href={instagramLink} target="_blank" rel="noopener noreferrer">
									<img src={logoInstagram} alt="instagram"></img>
								</a>
								<div className="footer-menu-logo-number">
									<span>1414</span>
									<p>{t('conversations_kz')}</p>
									<p>{t('voice_robot')}</p>
								</div>
							</div>
						</div>
						<div className="footer-menu-general">
							<span>{t('main')}</span>
							{!user ? (
								<p>
									<a className={'link'} href={urlAuth}>
										{t('employment_contract')}
									</a>
								</p>
							) : (
								<p>
									<a className={'link'} href={refContract}>
										{t('employment_contract')}
									</a>
								</p>
							)}
							<p>
								<Link to={'/forms'} className={'link'}>
									{t('online_consultant')}
								</Link>
							</p>
							<p>
								<Link to={'/reference-information/2'} className={'link'}>
									{t('services')}
								</Link>
							</p>
							<p>
								<Link to={'/reference-information/3'} className={'link'}>
									{t('questions')}
								</Link>
							</p>
						</div>
						<div className="footer-menu-portal">
							<span>{t('portal')}</span>
							<p>
								<Link to={'/about-project'} className={'link'}>
									{t('about_project')}
								</Link>
							</p>
							<p>
								<Link to={'/for-employers'} className={'link'}>
									{t('for_employer')}
								</Link>
							</p>
							<p>
								<Link to={'/for-workers'} className={'link'}>
									{t('for_worker')}
								</Link>
							</p>
							<p>
								<a href={refTerms} target="_blank" rel="noopener noreferrer">
									{' '}
									{t('terms_use')}
								</a>
							</p>
							<p>
								<Link to={'/reference-information/1'} className={'link'}>
									{t('helpTab')}
								</Link>
							</p>
						</div>
						<div className="footer-menu-frame_logo">
							<div className="footer-menu-frame_logo-wrapper">
								<div>
									<a href={refEnbek} target="_blank" rel="noopener noreferrer">
										<img src={enbekLogo} alt="enbek" />
									</a>
								</div>
								<div>
									<a href={refBusiness} target="_blank" rel="noopener noreferrer">
										<img src={businessEnbekLogo} alt="enbek-business" />
									</a>
								</div>
								<div>
									<a href={refSkills} target="_blank" rel="noopener noreferrer">
										<img src={skillsEnbekLogo} alt="enbek-skills" />
									</a>
								</div>
								<div>
									<a href={refCareer} target="_blank" rel="noopener noreferrer">
										<img src={careerEnbekLogo} alt="enbek-career" />
									</a>
								</div>
							</div>
							<div className="footer-menu-frame_logo-app_button">
								<a href={appStoreLink} target="_blank" rel="noopener noreferrer">
									<img src={appStoreRu} alt="app-store" />
								</a>
								<a href={googlePlayLink} target="_blank" rel="noopener noreferrer">
									<img src={googlePlay} alt="google-play" />
								</a>
							</div>
						</div>
					</div>
					<div className="footer-menu-mobile">
						<div className="footer-menu-mobile-left">
							<div className="footer-menu-mobile-block">
								<Link to={'/'}>
									<img src={LogoImage} alt="enbek" />
								</Link>
								<p>{t('digital_ecosystem')}</p>
								<a href={instagramLink} target="_blank" rel="noopener noreferrer">
									<img src={logoInstagram} alt="instagram"></img>
								</a>
							</div>
							<div className="footer-menu-general">
								<span>{t('main')}</span>
								{!user ? (
									<p>
										<a className={'link'} href={urlAuth}>
											{t('employment_contract')}
										</a>
									</p>
								) : (
									<p>
										<Link to={'/contracts'} className={'link'}>
											{t('employment_contract')}
										</Link>
									</p>
								)}
								<p>
									<Link to={'/forms'} className={'link'}>
										{t('consultant')}
									</Link>
								</p>
								<p>
									<Link to={'/reference-information/2'} className={'link'}>
										{t('services')}
									</Link>
								</p>
								<p>
									<Link to={'/reference-information/3'} className={'link'}>
										{t('questions')}
									</Link>
								</p>
							</div>
						</div>
						<div className="footer-menu-mobile-right">
							<div className="footer-menu-mobile-block">
								<span>1414</span>
								<p>{t('conversations_kz')}</p>
								<p>{t('voice_robot')}</p>
							</div>
							<div className="footer-menu-portal">
								<span>{t('portal')}</span>
								<p>
									<Link to={'/about-project'} className={'link'}>
										{t('about_project')}
									</Link>
								</p>
								<p>
									<Link to={'/for-employers'} className={'link'}>
										{t('for_employer')}
									</Link>
								</p>
								<p>
									<Link to={'/for-workers'} className={'link'}>
										{t('for_worker')}
									</Link>
								</p>
								<p>
									<a href={refTerms} target="_blank" rel="noopener noreferrer">
										{' '}
										{t('terms_use')}
									</a>
								</p>
								<p>
									<Link to={'/reference-information/1'} className={'link'}>
										{t('helpTab')}
									</Link>
								</p>
							</div>
						</div>
					</div>
					<div className="footer-disable">
						<div className="footer-menu-frame_logo-wrapper">
							<div>
								<a href={refEnbek} target="_blank" rel="noopener noreferrer">
									<img src={enbekLogo} alt="enbek" />
								</a>
							</div>
							<div>
								<a href={refBusiness} target="_blank" rel="noopener noreferrer">
									<img src={businessEnbekLogo} alt="enbek-business" />
								</a>
							</div>
							<div>
								<a href={refSkills} target="_blank" rel="noopener noreferrer">
									<img src={skillsEnbekLogo} alt="enbek-skills" />
								</a>
							</div>
							<div>
								<a href={refCareer} target="_blank" rel="noopener noreferrer">
									<img src={careerEnbekLogo} alt="enbek-career" />
								</a>
							</div>
						</div>
						<div className="footer-menu-frame_logo-app_button">
							<a href={appStoreLink} target="_blank" rel="noopener noreferrer">
								<img src={appStoreRu} alt="app-store" />
							</a>
							<a href={googlePlayLink} target="_blank" rel="noopener noreferrer">
								<img src={googlePlay} alt="google-play" />
							</a>
						</div>
					</div>
					<div className="footer-line" />
					<div className="footer-copyright">
						<p>
							&copy; 2020 - {thisYear}, {t('crtr')}
						</p>
						<LangFooter />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
