export const headCells = [
	{
		id: 'dateIn',
		numeric: false,
		label: 'profUnions.date_in',
	},
	{
		id: 'iin',
		numeric: false,
		label: 'iin',
	},
	{
		id: 'fio',
		numeric: true,
		label: 'profUnions.fio',
	},
	{
		id: 'status',
		numeric: true,
		label: 'profUnions.status_members',
	},
	{
		id: 'actions',
		numeric: false,
		label: 'col_contract.table.actions',
	},
]
