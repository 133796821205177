import { useCallback, useMemo } from 'react'
import styles from './InputNumber.module.scss'

export const InputNumber = ({
	hideNumberArrows,
	onChange,
	type,
	min,
	max,
	classes = '',
	value,
	enablecheckfloat = false,
	...props
}) => {
	const onChangeValue = useCallback(
		(event) => {
			let value = event.target.value
			if (type === 'number' && enablecheckfloat) {
				value = value
					.replace(',', '.')
					.replace(/[^\d\.]/g, '')
					.replace(/\./, 'x')
					.replace(/\./g, '')
					.replace(/x/, '.')

				if (value.startsWith('.')) {
					const arr = value.split()
					value = ['0', ...arr].join('')
				}
				if (value[0] == 0 && value[1] == 0) {
					value = '0'
				}
				if (value[0] == 0 && value[1] && value[1] != '.') {
					const arr = value.split('')
					arr.splice(0, 1)
					value = ['0', '.', ...arr].join('')
				}

				const formattedValue = Number(value)
				if (formattedValue < min) {
					value = min
				}
				if (formattedValue > max) {
					value = max
				}
			}

			// создаем новый объект события
			const newEvent = {
				target: {
					name: event.target.name,
					value: value,
				},
			}

			// передаем новый объект события обратно в Formik
			onChange(newEvent)
		},
		[max, min, onChange, type]
	)

	let inputType = ''

	if (!enablecheckfloat) {
		inputType = type
	} else {
		inputType = type === 'number' ? undefined : type
	}

	const isForSelect = useMemo(() => type === 'select', [type])
	return (
		<input
			className={`${styles.input} ${hideNumberArrows ? styles.hideNumberArrows : ''} ${
				isForSelect ? styles.select : ''
			} ${classes}`}
			type={inputType}
			onChange={onChangeValue}
			value={value || ''}
			{...props}
		/>
	)
}
