import React, { useEffect, useState } from 'react'
import { TranslationRequestsEmpty, AddTransferModal, TransferList, TransferView } from './components'
import { api } from '@/shared/api'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from '@/components/Loader'
import { LoadingBlocker } from '@/components/Loader'
import { Button } from '@/ui'
import { CompareArrowsIcon } from '@app/icons'
import { useDispatch } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { CreateEditAdditionalAgreementModal } from '@features/contracts/additionalContracts/CreateEditAdditionalAgreementModal'
import { getFromLocalStorage } from '@/shared'
import { branchActions, transferActions } from '@/entity'
import { HrPagination } from '@/components/Pagination'
import { message } from 'antd'

const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}
export const TransferRequests = () => {
	const dispatch = useDispatch()
	const [openAddTransferModal, setOpenAddTransferModal] = useState(false)
	const [openViewTransferModal, setOpenViewTransferModal] = useState(false)
	const [transfer, setTransfer] = useState(null)
	const [openAdditional, setOpenAdditional] = useState(false)
	const [additionalContract, setAdditionalContract] = useState(null)
	const [contractDetail, setContractDetail] = useState(null)
	const [queryParams, setQueryParams] = useState(initialQueryState)

	const { successPayload, onSign } = useDigitalSign()

	const activeCompany = getFromLocalStorage('activeCompany', false)

	const [loading, setLoading] = useState(false)
	const [loadingBlock, setLoadingBlock] = useState(false)

	const { t } = useTranslation()

	const [formPaginationData, setFormPaginationData] = useState({ page: 0, size: 10, totalPages: 0, totalElements: 0 })
	const [formPaginationDataIin, setFormPaginationDataIin] = useState({
		page: 0,
		size: 10,
		totalPages: 0,
		totalElements: 0,
	})

	const [listIncome, setListIncome] = useState(null)
	const [listOutcome, setListOutcome] = useState(null)

	const [isIn, setIsIn] = useState(false)

	useEffect(() => {
		if (successPayload) {
			handleXmlAdditional(successPayload)
		}
	}, [successPayload])

	const getList = async (isIn, page, size) => {
		try {
			setLoading(true)
			const list = await api.transfers.getTransfersList({ page, size, isIn })
			if (isIn) {
				setListIncome(list?.content)
				setFormPaginationDataIin({
					...formPaginationDataIin,
					totalPages: list.totalPages,
					totalElements: list.totalElements,
					size,
					page,
				})
			} else {
				setListOutcome(list?.content)
				setFormPaginationData({
					...formPaginationData,
					totalPages: list.totalPages,
					totalElements: list.totalElements,
					size,
					page,
				})
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoading(false)
		}
	}

	const getTransferInfo = async (transfer) => {
		try {
			setLoadingBlock(true)
			const transferInfo = await api.transfers.getTransfers(transfer.id)
			setTransfer({
				...transfer,
				contracts: transferInfo?.content,
				isIn,
			})
			setOpenViewTransferModal(true)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoadingBlock(false)
		}
	}

	useEffect(() => {
		if (!listIncome) {
			getList(true, formPaginationDataIin.page, formPaginationDataIin.size)
		}
	}, [listIncome])

	useEffect(() => {
		if (!listOutcome) {
			getList(false, formPaginationData.page, formPaginationData.size)
		}
	}, [listOutcome])

	const changePagination = (e, page) => {
		getList(false, page - 1, formPaginationData.size)
	}
	const changePaginationIin = (e, page) => {
		getList(true, page - 1, formPaginationData.size)
	}

	const handleChangeRowsPerPage = (value) => {
		getList(false, formPaginationData.page, value)
	}
	const handleChangeRowsPerPageIin = (value) => {
		getList(true, formPaginationData.page, value)
	}
	const handleAddNewTranfer = () => {
		getList(false, formPaginationData.page, formPaginationData.size)
	}
	const handleAddNewTranferIin = () => {
		getList(true, formPaginationDataIin.page, formPaginationDataIin.size)
	}
	const handleViewTransfer = (transfer) => getTransferInfo(transfer)

	const sendSelected = async (contracts, isIn) => {
		try {
			setLoadingBlock(true)
			await api.transfers.sendSelected({
				contracts,
				reqId: transfer.id,
			})
			getTransferInfo(transfer)
			isIn ? handleAddNewTranferIin() : handleAddNewTranfer()
			message.success(t('success_alert'))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoadingBlock(false)
		}
	}

	const rejectSelected = async (contracts, isIn) => {
		try {
			setLoadingBlock(true)
			await api.transfers.rejectSelected({
				contracts,
				reqId: transfer.id,
			})
			message.success(t('success_alert'))
			isIn ? handleAddNewTranferIin() : handleAddNewTranfer()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoadingBlock(false)
		}
	}

	const handleSubmitAdditionalForm = async (formData) => {
		try {
			setLoadingBlock(true)
			const data = {
				...formData,
				bin: activeCompany.bin,
				oldbin: contractDetail.bin,
				contractExternalId: additionalContract.externalId,
				externalId: null,
			}
			const xml = await api.contracts.createAdditionalContractXmlTransfer(data, { reqId: transfer.id })
			setAdditionalContract({
				...additionalContract,
				...formData,
			})
			onSign(xml)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoadingBlock(false)
		}
	}

	const handleXmlAdditional = async (successPayload, isIn) => {
		try {
			setLoadingBlock(true)
			await api.transfers.sendAdditionalAgreement(
				{
					entityId: additionalContract.id,
					externalId: additionalContract.externalId,
					paramMap: {
						addname: additionalContract.contractNum,
						adddate: additionalContract.registerDate,
					},
					signedXml: successPayload,
					bin: activeCompany.bin,
					oldbin: contractDetail.oldbin,
				},
				{ reqId: transfer.id }
			)
			message.success(t('success_alert'))
			setOpenAdditional(false)
			isIn ? handleAddNewTranferIin() : handleAddNewTranfer()
			setOpenViewTransferModal(false)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setLoadingBlock(false)
		}
	}

	const getContractDetail = async (externalId) => {
		try {
			setLoadingBlock(true)
			const response = await api.transfers.getContractDetail(externalId, { reqId: transfer.id })
			setContractDetail(response)
			return response
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
			throw error
		} finally {
			setLoadingBlock(false)
		}
	}

	const openAdditionalAgreement = async (contract) => {
		setAdditionalContract(contract)
		try {
			await getContractDetail(contract.externalId)
			setOpenAdditional(true)
		} catch (error) {
			setOpenAdditional(false)
		}
	}

	const openCreateTransferModal = () => setOpenAddTransferModal(true)

	const isMobile = window.innerWidth < 768

	const onClose = () => {
		setOpenAddTransferModal(false)
		setOpenViewTransferModal(false)
		dispatch(branchActions.resetBranch())
		dispatch(transferActions.resetContracts())
	}

	return (
		<>
			{loadingBlock && <LoadingBlocker />}
			{loading ? (
				<LoadingScreen />
			) : (
				<>
					{listIncome?.length === 0 && listOutcome?.length === 0 ? (
						<TranslationRequestsEmpty onClick={openCreateTransferModal} />
					) : null}

					{listIncome?.length !== 0 || listOutcome?.length !== 0 ? (
						<>
							<Button disabled={isMobile} onClick={openCreateTransferModal} icon={<CompareArrowsIcon fill="#FFFFFF" />}>
								{t('transfer_request_btn')}
							</Button>
							<TransferList
								listIncome={listIncome}
								listOutcome={listOutcome}
								isIn={isIn}
								setIsIn={setIsIn}
								onViewTransfer={handleViewTransfer}
								handleAddNewTranferIin={handleAddNewTranferIin}
							/>

							<HrPagination
								id="hr-change-bin-pagination"
								rowsPerPageitems={
									isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
								}
								rowsPerPage={isIn ? formPaginationDataIin.size : formPaginationData.size}
								numberOfElements={isIn ? listIncome?.length : listOutcome?.length}
								totalElements={isIn ? formPaginationDataIin.totalElements : formPaginationData.totalElements}
								totalPages={isIn ? formPaginationDataIin.totalPages : formPaginationData.totalPages}
								handleChangePage={isIn ? changePaginationIin : changePagination}
								handleChangeRowsPerPage={isIn ? handleChangeRowsPerPageIin : handleChangeRowsPerPage}
								page={isIn ? formPaginationDataIin.page : formPaginationData.page}
								noMarginBottom
							/>
							<div style={{ marginBottom: 40 }}></div>
						</>
					) : null}
				</>
			)}

			{openAddTransferModal && (
				<AddTransferModal addNewTransfer={isIn ? handleAddNewTranferIin : handleAddNewTranfer} onClose={onClose} />
			)}

			{openViewTransferModal && transfer && (
				<TransferView
					transfer={transfer}
					onClose={setOpenViewTransferModal.bind(null, false)}
					sendSelected={sendSelected}
					rejectSelected={rejectSelected}
					openAdditional={openAdditionalAgreement}
					isIn={isIn}
				/>
			)}

			{openAdditional && (
				<CreateEditAdditionalAgreementModal
					additionalContractTransfer={additionalContract}
					onClose={setOpenAdditional.bind(null, false)}
					createHandler={handleSubmitAdditionalForm}
				/>
			)}
		</>
	)
}
