import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components/Table/Table'
import { Search } from '@pages/PersonalAffairs/components/Search/Search'
import styles from '../../PersonalAffairsPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { affairsActions, affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import { RequestPersonalAffairModal } from '@pages/PersonalAffairs/Tabs/PersonalAffairsMainTab/components/RequestPersonalAffairModal/RequestPersonalAffairModal'
import PersonalAffairCardRequest from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/RequestCard'
import { PersonalAffairCard } from '@pages/PersonalAffairs/components/PersonalAffairCard/PersonalAffairCard'

const PersonalAffairsRequestMainTab = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { totalElements } = useSelector(affairsSelectors.employerRequests)
	const [isOpenModal, setOpenModal] = useState(false)
	const [isOpenAffairsCard, setOpenAffairsCard] = useState(false)
	const [selectedRequest, setSelectedRequest] = useState(null)
	const selectedAffair = useSelector(affairsSelectors.selectedAffair)
	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)
	const handleRowClick = (row) => {
		setSelectedRequest(row)
		setOpenAffairsCard(true)
	}

	const handlePersonalAffairClick = (id) => {
		dispatch(affairsThunks.getAffairForEmployerByCorrelationId(id))
		setOpenAffairsCard(false)
	}

	const getData = useCallback(
		(srcText = '', page = 0, size = 10) => dispatch(affairsThunks.getEmployerRequests(srcText, page, size)),
		[dispatch]
	)

	useEffect(() => {
		getData()
		return () => affairsActions.setSelectedAffair({})
	}, [])

	return (
		<div className={styles.wrapper}>
			{isOpenModal && <RequestPersonalAffairModal page={page} size={size} onClose={() => setOpenModal(false)} />}
			{isOpenAffairsCard && (
				<PersonalAffairCardRequest
					onClose={() => setOpenAffairsCard(false)}
					openAffairsCard={handlePersonalAffairClick}
					data={selectedRequest}
				/>
			)}
			{selectedAffair?.id && <PersonalAffairCard />}
			<Button buttonClassName={styles.wrapper__button} icon={addWhiteIcon} onClick={() => setOpenModal(true)}>
				{t('personal_affairs.request_electronic_affair')}
			</Button>
			<Search count={totalElements} fetchData={getData} isRequests />
			<Table page={page} setPage={setPage} size={size} setSize={setSize} getData={getData} openModal={handleRowClick} />
		</div>
	)
}
export default PersonalAffairsRequestMainTab
