import * as types from './types'
export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	opvList: (payload) => ({
		type: types.OPV_LIST,
		payload,
	}),
	selectedOpv: (payload) => ({
		type: types.SELECTED_OPV,
		payload,
	}),
}
