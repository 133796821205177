import React from 'react'
import styles from './RequestCard.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Button, DataGridTable, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import RequestCardTable from './Table/RequestCardTable'
import renderIcon from '@/pages/PersonalAffairs/components/Icons'
import styled from 'styled-components'
import { getCurrentLanguage } from '@/i18next'
import { colors } from '@pages/PersonalAffairs/components/PersonalAffairsCardRequest/utils'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import warningIcon from 'assets/pages/personalAffairs/warning.svg'
import { formatDate } from '@/utils'

const Span = styled.span`
	margin-left: 8px;
	font-size: 18px;
	line-height: 20px;
	color: #17b67c;
`

const PersonalAffairCardRequest = ({ onClose, openAffairsCard, data, onSign, onReject }) => {
	const { t } = useTranslation()
	const {
		id,
		requestDate,
		iin,
		employeeFio,
		bin,
		orgNameRu,
		orgNameKz,
		errorNameRu,
		errorNameKz,
		requestStatus,
		methodStatuses,
		errorCode,
		correlationId,
		consentDate,
		local,
	} = data
	const lang = getCurrentLanguage()
	const statusCode = requestStatus.code.toLowerCase()
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'

	return (
		<Modal windowClassName={styles.modal}>
			<ModalBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<div className={styles.headerWrapper}>
					<div className={styles.header}>
						<Title className={styles.title}>{t('request_employee_ld')}</Title>
						<p className={styles.textStatusWrapper}>
							{renderIcon(statusCode, 24, 24)}
							<Span style={{ color: colors[statusCode] }}>
								{lang === 'rus' ? requestStatus.nameRu : requestStatus.nameKz}
							</Span>
						</p>
					</div>
					<div>
						<p className={styles.textGray}>
							{t('request_id')}: {id}
						</p>
						<p className={styles.textGray}>
							{t('form_date')}: {requestDate ? formatDate(requestDate) : '-'}
						</p>
					</div>
				</div>
				<div className={styles.containerData}>
					<div className={styles.containerDataItem}>
						<DataGridTable
							hideSubTitle
							title={t('employee_data_ld')}
							information={[
								{ title: t('personal_affairs.table.iin'), data: iin },
								{ title: t('personal_affairs.table.employee_fio'), data: employeeFio },
							]}
							titleClass={styles.tableTitleCell}
						/>
					</div>
					<div className={styles.containerDataItem}>
						<DataGridTable
							hideSubTitle
							title={t('employer_data_ld')}
							information={[
								{ title: t('employer_iin_bin'), data: bin },
								{ title: t('name'), data: lang === 'rus' ? orgNameRu : orgNameKz },
							]}
							titleClass={styles.tableTitleCell}
						/>
					</div>
				</div>
				<div className={styles.tableWrapper}>
					<Title className={styles.title}>{t('personal_affairs.requested_information')}</Title>
					<RequestCardTable data={methodStatuses} />
					{!local && (
						<p className={styles.warning}>
							<img src={warningIcon} alt="" />
							Данные запрошены из другой системы. Просмотр недоступен на портале
						</p>
					)}
				</div>
				<div>
					<Title className={styles.title}>{t('personal_affairs.table.request_status')}:</Title>
					<p className={styles.textStatusName}>
						{t('personal_affairs.table.request_status')}:
						<span className={styles.textRequestStatus}>
							{lang === 'rus' ? requestStatus.nameRu : requestStatus.nameKz}
						</span>
					</p>
					<p className={styles.textStatusName}>
						{t('personal_affairs.sign_date')}:
						<span className={styles.textDateSign}>
							{!requestStatus.code.includes('PENDING') ? formatDate(consentDate) : '-'}
						</span>
					</p>
					{errorCode.includes('E') && (
						<p className={styles.textStatusName}>
							{t('error')}:
							<span className={styles.textError}>
								{lang === 'rus' ? errorNameRu : errorNameKz} (Код ошибки: {errorCode})
							</span>
						</p>
					)}
				</div>
				{!isEmployee && (
					<div className={styles.buttonsWrapper}>
						<Button type="button" buttonClassName={styles.button} onClick={onClose}>
							{t('close')}
						</Button>
						<Button
							type="button"
							buttonClassName={styles.button}
							onClick={() => openAffairsCard(correlationId)}
							disabled={!['processing', 'successful', 'partsuccessful'].includes(statusCode) || !local}
						>
							{t('personal_affairs.go_to_personal_affair')}
						</Button>
					</div>
				)}
				{isEmployee && statusCode === 'pending' && (
					<div className={styles.buttonsWrapper}>
						<Button
							type="button"
							buttonClassName={styles.button}
							onClick={() => openAffairsCard(correlationId)}
							disabled={!['processing', 'successful', 'partsuccessful'].includes(statusCode) || !local}
						>
							{t('personal_affairs.go_to_personal_affair')}
						</Button>
						<Button
							type="button"
							buttonClassName={styles.button}
							onClick={onReject}
							// disabled={statusCode === 'pending'}
						>
							{t('decline')}
						</Button>
						<Button
							type="button"
							buttonClassName={styles.button}
							onClick={onSign}
							// disabled={statusCode === 'pending'}
						>
							Подписать ЭЦП и дать согласие
						</Button>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default PersonalAffairCardRequest
