import React from 'react'
import styles from './SystemEnbekPopup.module.scss'
import { useTranslation } from 'react-i18next'
const LinkComponent = ({ href, logo, alt, title, description }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.popupLink}>
			<a href={href} target="_blank" rel="noopener noreferrer">
				<div className={'enbek-system'}>
					<div className={styles.logo}>
						<img src={logo} alt={alt} />
					</div>
					<div className={styles.text}>
						<h5>{title}</h5>
						<p>{t(description)}</p>
					</div>
				</div>
			</a>
		</div>
	)
}

export default LinkComponent
