import { array, object, string } from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

export const ProfUnionsValidationSchema = object().shape({
	bin: string().when('typeCode', {
		is: (typeCode) => typeCode !== 'primary',
		then: string()
			.required({ key: 'required' })
			.matches(/^[0-9]+$/, 'contracts_tab.not_correct_bin')
			.min(12, { key: 'min_12_length' }),
		otherwise: string().nullable(),
	}),
	unionName: string().required('required'),
	dateCreate: transformStringDateFormatToDate().required({ key: 'required' }),
	typeCode: string().required('required'),
	chiefIin: string()
		.required({ key: 'required' })
		.matches(/^[0-9]+$/, { key: 'not_correct_iin' })
		.min(12, { key: 'validation.min_length', value: 12 })
		.max(12, { key: 'validation.max_length', value: 12 }),
	chiefFio: string().required('required'),
	industryCode: string().when('typeCode', {
		is: (typeCode) => typeCode === 'industry',
		then: string().required('required'),
		otherwise: string().nullable(),
	}),
	parents: array()
		.of(
			object().shape({
				parentBin: string().when('typeCode', {
					is: (typeCode) => typeCode !== 'industry',
					then: string().required('required'),
					otherwise: string().nullable(),
				}),
				parentName: string().when('typeCode', {
					is: (typeCode) => typeCode !== 'industry',
					then: string().required('required'),
					otherwise: string().nullable(),
				}),
			})
		).nullable().defined(),
	employers: array()
		.of(
			object().shape({
				employerBin: string().when('typeCode', {
					is: (typeCode) => typeCode !== 'industry',
					then: string().required('required'),
					otherwise: string().nullable(),
				}),
				employerName: string().when('typeCode', {
					is: (typeCode) => typeCode !== 'industry',
					then: string().required('required'),
					otherwise: string().nullable(),
				}),
			})
		).nullable().defined(),
	managers: array()
		.of(
			object().shape({
				iin: string()
					.required({ key: 'required' })
					.matches(/^[0-9]+$/, { key: 'not_correct_iin' })
					.min(12, { key: 'validation.min_length', value: 12 })
					.max(12, { key: 'validation.max_length', value: 12 }),
				fio: string().required({ key: 'required' }),
				position: string().required({ key: 'required' }),
			})
		).nullable().defined(),
})

export const AddMembersValidationSchema = object().shape({
	members: array()
		.of(
			object().shape({
				iin: string()
					.required({ key: 'required' })
					.matches(/^[0-9]+$/, { key: 'contracts_tab.not_correct_bin' })
					.min(12, { key: 'min_12_length' }),
				fio: string().required({ key: 'required' }),
				dateIn: transformStringDateFormatToDate().required({ key: 'required' }),
			})
		)
		.required(),
})
