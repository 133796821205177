import {formatDate} from "@/utils";

export const getEducationColumns = (t, data, langOption) => {
    const {
        eduOrg,
        dateOfArrival,
        dateOfDisposal,
        dateOfIssueAttestat,
        attestatSeries,
        attestatNumber,
        diplomaSeriesAndNumber,
        status,
        cource,
        language,
        released,
        parallel,
        studyForm,
        typeOfDiploma,
        reasonOfRetirementTipo,
        reasonOfRetirementVuz,
        specialtyAndClassifierTipo1,
        specialtyAndClassifierTipo2,
        specialtyAndClassifierTipo3,
        specialtyAndClassifierVuz1,
        specialtyAndClassifierVuz2,
        specialtyAndClassifierVuz3,
        educationCategory
    } = data || {}

    return [
        {title: t('personal_affairs.ou_name'), data: eduOrg?.[langOption]},
        {title: t('personal_affairs.receipt_date'), data: formatDate(dateOfArrival)},
        {title: t('contracts_tab.expired_date'), data: formatDate(dateOfDisposal)},
        {title: t('personal_affairs.certificate_issue_date'), data: formatDate(dateOfIssueAttestat)},
        {title: t('personal_affairs.certificate_series'), data: attestatSeries},
        {title: t('personal_affairs.certificate_number'), data: attestatNumber},
        {title: t('personal_affairs.diploma_number'), data: diplomaSeriesAndNumber},
        {title: t('contracts_tab.status'), data: status?.[langOption]},
        {title: t('personal_affairs.course'), data: cource?.[langOption]},
        {title: t('personal_affairs.language'), data: language?.[langOption]},
        {title: t('personal_affairs.release'), data: released?.[langOption]},
        {title: t('personal_affairs.class'), data: parallel?.[langOption]},
        {title: t('personal_affairs.education_form'), data: studyForm?.[langOption]},
        {title: t('personal_affairs.diploma_type'), data: typeOfDiploma?.[langOption]},
        {title: t('personal_affairs.education_category'), data: educationCategory?.[langOption]},
        {title: t('personal_affairs.tipo_cause'), data: reasonOfRetirementTipo?.[langOption]},
        {title: t('personal_affairs.vuz_cause'), data: reasonOfRetirementVuz?.[langOption]},
        {title: t('personal_affairs.spec_tipo_1'), data: specialtyAndClassifierTipo1?.[langOption]},
        {title: t('personal_affairs.spec_tipo_2'), data: specialtyAndClassifierTipo2?.[langOption]},
        {title: t('personal_affairs.spec_tipo_3'), data: specialtyAndClassifierTipo3?.[langOption]},
        {title: t('personal_affairs.spec_vuz_1'), data: specialtyAndClassifierVuz1?.[langOption]},
        {title: t('personal_affairs.spec_vuz_2'), data: specialtyAndClassifierVuz2?.[langOption]},
        {title: t('personal_affairs.spec_vuz_3'), data: specialtyAndClassifierVuz3?.[langOption]},
    ].map(item => !!item.data ? item : {...item, data: '-'})
}
