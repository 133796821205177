export const getWorkPlaces = (staffProject, targetId) => {
	for (let child of staffProject.childs) {
		if (child.externalId === targetId) {
			return child.workPlaces
		}
		if (Array.isArray(child.childs)) {
			const result = getWorkPlaces(child, targetId)
			if (result) {
				return result
			}
		}
	}
}
