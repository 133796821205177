import React, { useMemo, useState } from 'react'
import { StaffChevronDown, StaffChevronUp } from '@app/images'
import { headCells } from './tableProjectTreeView.headings'
import { useTranslation } from 'react-i18next'
import onProjectActionMenuClick from './actionMenu/onProjectActionMenuClick'
import styles from './TableProjectTreeView.module.scss'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useDispatch, useSelector } from 'react-redux'
import { staffActions, staffSelectors } from '@/entity/staffingProject'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import renderIcon from '@pages/PersonalAffairs/components/Icons'
import { checkProjectErrors } from '@/utils'

const TableProjectTreeView = ({
	node,
	toggleViewExtended,
	setActions,
	staffType,
	setActiveRowId,
	activeRowId,
	setRowSelectMenu,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [actionMenuItems, setActionMenuItems] = useState([])
	const isOpen = useSelector((state) => state.staff.openedChildsIds.includes(node.externalId))

	const handleToggleClick = (e) => {
		e.stopPropagation()
		if (node.childs?.length > 0) {
			if (isOpen) {
				dispatch(staffActions.removeOpenedChildId(node.externalId))
			} else {
				dispatch(staffActions.addOpenedChildId(node.externalId))
			}
		}
	}

	const handleRowClick = (e) => {
		setActiveRowId(node.externalId)
		toggleViewExtended(true)
		e.stopPropagation()
	}

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		setRowSelectMenu(row)
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}

	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	return (
		<>
			<tr onClick={handleRowClick} className={activeRowId === node.externalId ? styles.active : ''}>
				<td
					style={{
						paddingLeft: `${node.level * 10}px`,
					}}
					className={styles['td-cursor-pointer']}
					onClick={handleToggleClick}
				>
					{node.childs?.length > 0 ? (
						isOpen ? (
							<img className={styles.treeView} src={StaffChevronUp} alt="minus" />
						) : (
							<img className={styles.treeView} src={StaffChevronDown} alt="plus" />
						)
					) : null}
				</td>
				<td
					style={{
						paddingLeft: `${node.level * 10 + 10}px`, // всегда добавляем дополнительный отступ
					}}
					className={`${styles['td-cursor-pointer']} ${styles['td-padding-left']}`}
				>
					{lang === 'rus' ? node.dept.nameRu : node.dept.nameKz}
				</td>
				<td>{showDeptType(node.deptTypeCode)}</td>
				<td>{node.dept.code}</td>
				<td onClick={(e) => onProjectActionMenuClick(node, e, setActionMenuItems, t)}>
					{' '}
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, node)}
					/>
				</td>
				<td>{checkProjectErrors(node) ? renderIcon('error') : null}</td>
			</tr>
			{isOpen &&
				node.childs?.length > 0 &&
				node.childs.map((child) => (
					<TableProjectTreeView
						key={child.externalId}
						node={{ ...child, level: node.level + 1 }}
						toggleViewExtended={toggleViewExtended}
						setActiveRowId={setActiveRowId}
						activeRowId={activeRowId}
						staffType={staffType}
						setActions={setActions}
						setRowSelectMenu={setRowSelectMenu}
					/>
				))}
		</>
	)
}

export const TreeViewProject = ({
	toggleViewExtended,
	setActions,
	staffProject,
	staffType,
	setRowSelectMenu,
	activeRowId,
	setActiveRowId,
}) => {
	const isLoading = useSelector(staffSelectors.isLoading)
	const { t } = useTranslation()

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)
	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>
				{!!staffProject && !isLoading ? (
					staffProject.childs.map((child) => (
						<TableProjectTreeView
							key={child.externalId}
							node={{ ...child, level: 0 }}
							toggleViewExtended={toggleViewExtended}
							setActions={setActions}
							staffType={staffType}
							setActiveRowId={setActiveRowId}
							activeRowId={activeRowId}
							setRowSelectMenu={setRowSelectMenu}
						/>
					))
				) : (
					<tr>
						<td colSpan={5} className={styles.messageCol}>
							{isLoading && (
								<LoadingScreen>
									<Title>
										{t('staff.stop')} <br />
										{t('labor_records.loading')}
									</Title>
								</LoadingScreen>
							)}
							{!isLoading && !staffProject && (
								<div className={styles.tableLoading}>{t('staffing_table.info_no_staff')}</div>
							)}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
