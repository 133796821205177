import React, { useEffect } from 'react'
import { useDigitalSign } from '@/shared'
import { initialIndustryAgreement } from '@/entity/industryAgreement/model/reducer'
import { IndustryAgreement } from './form/IndustryAgreement'
import { useDispatch, useSelector } from 'react-redux'
import { agreementsActions, agreementsSelectors, agreementsThunks } from '@/entity'

export const CreateOrEdit = ({ agreement, isEdit, fetchData, onClose, onEditSuccess }) => {
	const dispatch = useDispatch()
	const agreementXml = useSelector(agreementsSelectors.agreementXml)
	const isSuccess = useSelector(agreementsSelectors.isSuccessIndustryAgreement)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()

	const onClear = () => {
		onClearPayload()
		dispatch(agreementsActions.resetAgreementXml())
		dispatch(agreementsActions.setAgreementSuccess(false))
	}

	useEffect(() => {
		if (successPayload) {
			dispatch(agreementsThunks.sendAgreement(successPayload))
			onClearPayload()
		}
	}, [successPayload])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => agreementXml && onSign(agreementXml), [agreementXml])

	useEffect(() => {
		if (isSuccess) {
			onClear()
			fetchData()
			onClose()
			if (isEdit) {
				onEditSuccess()
			}
		}
		return onClear()
	}, [isSuccess])

	const onSubmit = (data) => {
		dispatch(agreementsThunks.getXml(data))
	}

	return (
		<IndustryAgreement
			initialValues={isEdit ? agreement : initialIndustryAgreement}
			onCancel={onClose}
			onSubmit={onSubmit}
			isEdit={isEdit}
		></IndustryAgreement>
	)
}
