import { transferActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'

export const getDataByIin = (iin, bin) => {
	return async (dispatch) => {
		try {
			dispatch(transferActions.toggleLoading(true))
			const response = await api.transfers.getDataByIin(iin, bin)
			dispatch(transferActions.contracts(response))
			dispatch(transferActions.toggleSuccess(true))
		} catch (e) {
			message.error(e?.response?.data?.message || 'Неизвестная ошибка')
			dispatch(transferActions.toggleLoading(false))
			dispatch(transferActions.toggleSuccess(false))
		} finally {
			dispatch(transferActions.toggleLoading(false))
		}
	}
}
