import { ConfirmModal, Title } from '@/ui'
import styles from './Question.module.scss'

export const Question = ({ data, handleProcess, handleCancel, processButtonTitle }) => {
	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={handleCancel}
			processButtonTitle={processButtonTitle ?? 'labor_records.sign_and_send'}
			cancelButtonTitle={'no_cancel'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{data.title}</Title>
				<p>{data.data}</p>
			</div>
		</ConfirmModal>
	)
}
