import React, { useCallback, useEffect, useState } from 'react'
import styles from './ProactiveMainTab.module.scss'
import { Button } from '@/ui'
import addWhiteIcon from '@/assets/pages/personalAffairs/addWhite.svg'
import { useTranslation } from 'react-i18next'
import Search from './component/Search/Search'
import { pensionSelectors, pensionThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import Table from './component/Table/Table'
import { HrPagination } from '@/components/Pagination'
import PensionPersons from './component/PensionPersons/PensionPersons'
import { LoadingBlocker } from '@/components/Loader'
import { PensionPayments } from './component/PensionPayments/PensionPayments'
import { getCurrentLanguage } from '@/i18next'

const initialFilterState = {
	iin: '',
	name: '',
	src: '',
	state: '',
	status: '',
}
const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const ProactiveMainTab = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const statusList = useSelector(pensionSelectors.pensionStatuses)
	const stateList = useSelector(pensionSelectors.pensionStates)
	const [pensioner, setPensioner] = useState(null)
	const [isPensionPersonsModalOpen, setPensionPersonsModalOpen] = useState(false)
	const [filters, setFilters] = useState(initialFilterState)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const isMobile = window.innerWidth < 768
	const pensionsData = useSelector(pensionSelectors.pensionsAll)
	const isSuccess = useSelector(pensionSelectors.isSuccessSave)

	const getPensioner = (personData) => {
		setPensioner(personData)
	}
	const fetchData = useCallback(
		(_filters = filters, _queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(pensionThunks.getPensions({ ..._filters, iin: _filters.iin }, page, pageSize))
		},
		[dispatch, filters, queryParams, isSuccess]
	)
	const resetFilter = useCallback(() => {
		setFilters(() => ({ ...initialFilterState }))
		fetchData({
			...initialFilterState,
		})
	}, [fetchData])
	useEffect(() => {
		dispatch(pensionThunks.getHandbook())
	}, [])
	useEffect(() => {
		fetchData()
	}, [queryParams.page, queryParams.pageSize, isSuccess])
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))
	const onClose = () => {
		setPensioner(null)
	}
	const downloadPDF = () => {
		const fileURL =
			lang === 'rus' ? '../../../files/РП проактив v2 рус_edited.pdf' : '../../../files/РП проактив v2 каз_edited.pdf'
		window.open(fileURL, '_blank')
	}
	const openVideoInstruction = () => {
		const fileURL = lang === 'rus' ? process.env.REACT_APP_SUPPORT_REF_14 : process.env.REACT_APP_SUPPORT_REF_15
		window.open(fileURL, '_blank')
	}
	return statusList && stateList ? (
		<div className={styles.wrapper}>
			<div className={styles.allowProactive}>
				<Button
					buttonClassName={styles.wrapper__button}
					icon={addWhiteIcon}
					onClick={() => setPensionPersonsModalOpen(true)}
				>
					{t('pension.create_request')}
				</Button>
				<div>
					<p onClick={downloadPDF}>{t('pension.instructions')}</p>
					<p onClick={openVideoInstruction}>{t('pension.video_instructions')}</p>
				</div>
			</div>
			{isPensionPersonsModalOpen && (
				<PensionPersons getPensioner={getPensioner} onClose={() => setPensionPersonsModalOpen(false)} />
			)}
			<Search
				filters={filters}
				setFilters={setFilters}
				fetchData={fetchData}
				resetFilter={resetFilter}
				page={queryParams.page}
				pageSize={queryParams.pageSize}
			/>
			<Table />
			{/* пагинация */}
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={
					isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
				}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={pensionsData.numberOfElements}
				totalElements={pensionsData.totalElements}
				totalPages={pensionsData.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
			{pensioner != null && (
				<PensionPayments onToggleAction={{ view: false }} pensioner={pensioner} onClose={onClose} newRecord={true} />
			)}
		</div>
	) : (
		<LoadingBlocker />
	)
}

export default ProactiveMainTab
