import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	isSuccessSend: false,
	isSuccessPhone: false,
	dashBoardInformation: null,
	regionalPowerContact: null,
	userByBin: '',
	documentTypes: [],
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.TOGGLE_SUCCESS_SEND:
			return {
				...state,
				isSuccessSend: payload,
			}
		case types.TOGGLE_SUCCESS_PHONE:
			return {
				...state,
				isSuccessPhone: payload,
			}
		case types.SET_DASHBOARD_INFORMATION:
			return {
				...state,
				dashBoardInformation: payload,
			}
		case types.SET_REGIONAL_POWER_CONTACT:
			return {
				...state,
				regionalPowerContact: payload,
			}
		case types.SET_USER_BY_BIN:
			return {
				...state,
				userByBin: payload,
			}
		case types.SET_DOCUMENT_TYPES:
			return {
				...state,
				documentTypes: payload,
			}
		default:
			return state
	}
}
