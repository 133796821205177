import React, { useCallback } from 'react'
import { ModalTemplate } from '@/ui'
import styles from '../StaffingFirstStructure.module.scss'
import { StructureCard } from './StructureCard'

export const View = ({ onClose, onToggleAction, rowSelectMenu, staffProject, history = false }) => {
	const onEdit = useCallback(() => onToggleAction('edit', true), [onToggleAction])
	const onRemove = useCallback(() => onToggleAction('remove', true), [onToggleAction])
	return (
		<ModalTemplate onClose={onClose}>
			<div className={`scroll ${styles.wrapper} ${styles.wrapper_view}`}>
				<StructureCard
					onClose={onClose}
					onEdit={onEdit}
					onRemove={onRemove}
					rowSelectMenu={rowSelectMenu}
					staffProject={staffProject}
					history={history}
				></StructureCard>
			</div>
		</ModalTemplate>
	)
}
