export const modalAdditionButton = [
	{
		code: 'cntr.new',
		title: 'notification_open_contract',
	},
	{
		code: 'cntr.add',
		title: 'notification_open_addition_contract',
	},
]

export const etdCodesAndLocal = [
	{ code: 'etd.send_to_employee', local: true },
	{ code: 'eaddc.send_to_employee', local: false },
	{ code: 'etd.employee_reject', local: true },
	{ code: 'eaddc.employee_reject', local: false },
	{ code: 'eaddc.employee_sign', local: false },
	{ code: 'eaddc.employer_reject', local: false },
	{ code: 'eaddc.employer_sign', local: false },
	{ code: 'ecterm.send_to_employer', local: false },
	{ code: 'etd.employer_sign', local: false },
	{ code: 'etd.employee_sign', local: true },
	{ code: 'etd.employer_reject', local: true },
	{ code: 'ecterm.timeout_employer', local: false },
	{ code: 'ecterm.employer_reject', local: false },
	{ code: 'ecterm.send_to_employee', local: false },
	{ code: 'ecterm.timeout_employee', local: false },
	{ code: 'ecterm.employee_reject', local: false },
	{ code: 'ecterm.employer_sign', local: false },
	{ code: 'ecterm.employee_sign', local: false },
	{ code: 'uni.e_doc_sign', local: true },
]

export const tradeUnion = [{ code: 'tradeunion.new', local: true }]

export const ekdContract = [
	{ code: 'ekd.employer_reject' },
	{ code: 'ekd.tradeunion_sign' },
	{ code: 'ekd.employer_sign' },
	{ code: 'ekd.tradeunion_reject' },
	{ code: 'ekd.send_to_employer' },
]
