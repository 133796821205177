import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setStaffProject: (payload) => ({
		type: types.SET_STAFF_PROJECT,
		payload,
	}),
	addOpenedChildId: (payload) => ({
		type: types.ADD_OPENED_CHILD_ID,
		payload,
	}),
	removeOpenedChildId: (payload) => ({
		type: types.REMOVE_OPENED_CHILD_ID,
		payload,
	}),
	setStaffXml: (payload) => ({
		type: types.SET_STAFF_XML,
		payload,
	}),
	setStatus: (payload) => ({
		type: types.SET_STATUS,
		payload,
	}),
}
