export const TOGGLE_LOADING = 'collectiveContracts/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'collectiveContracts/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'collectiveContracts/SET_CONTRACT_ERROR'
export const SET_COL_CONTRACTS = 'collectiveContracts/SET_COL_CONTRACTS'
export const SET_COL_CONTRACT = 'collectiveContracts/SET_COL_CONTRACT'
export const SET_COL_CONTRACT_LOADING = 'collectiveContracts/SET_COL_CONTRACT_LOADING'
export const SET_COL_CONTRACT_SUCCESS = 'collectiveContracts/SET_COL_CONTRACT_SUCCESS'
export const SET_INITIAL_COL_CONTRACT = 'collectiveContracts/SET_INITIAL_COL_CONTRACT'
export const SET_COL_XML = 'collectiveContracts/SET_COL_XML'
export const RESET_COL_XML = 'collectiveContracts/RESET_COL_XML'
