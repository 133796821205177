import { Button, Field, ModalTemplate, Title } from '@/ui'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SolutionEmployer.module.scss'
import { Form, Formik, Field as FormikField } from 'formik'
import { solutionEmployerSchema } from './SolutionEmployer.schema'

export const SolutionEmployer = ({ action, onSubmit, handleCancel, requestAdjustmentId }) => {
	const { t } = useTranslation()
	const questionText = useMemo(() => {
		switch (true) {
			case action.confirm:
				return {
					title: t('labor_records.title_sign_confirm'),
					body: t('labor_records.body_sign_confirm'),
					note: t('labor_records.note_text'),
				}
			case action.reject:
				return {
					title: t('labor_records.title_sign_reject'),
					body: t('labor_records.body_sign_reject'),
					note: '',
				}
			default:
				return { title: '', body: '' }
		}
	}, [action, t])
	const handleDataProcess = (data) => {
		const toSubmit = {
			approve: action.confirm,
			id: requestAdjustmentId,
			note: data.note,
		}
		onSubmit(toSubmit)
	}
	return (
		<ModalTemplate onClose={handleCancel} hideBackButton={true}>
			<Formik
				initialValues={{
					note: questionText.note,
				}}
				onSubmit={handleDataProcess}
				validationSchema={solutionEmployerSchema}
				enableReinitialize
			>
				{({ isValid, values }) => {
					return (
						<Form>
							<div className={styles.content}>
								{' '}
								<Title color={'darkBlue'}>{questionText.title}</Title>
								<p>{questionText.body}</p>
							</div>
							<FormikField name="note">
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										fieldType={'textarea'}
										placeholder={t('labor_records.note_placeholder')}
										error={touched && error ? t(error) : ''}
										showCount
										maxLength={4000}
										rows={6}
										{...field}
										labelClassName={styles.field}
									/>
								)}
							</FormikField>
							<div className={styles.actions}>
								<Button disabled={!isValid || values.note === ''} type="submit">
									{t('labor_records.sign_and_send')}
								</Button>
								<Button type="bare" onClick={handleCancel}>
									{t('no_cancel')}
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</ModalTemplate>
	)
}
