export const headCells = [
	{
		id: 'serial_number',
		numeric: true,
		label: '№',
	},
	{
		id: 'workplace_code_label',
		numeric: false,
		label: 'staffing_table.workplace_code_label',
	},
	{
		id: 'position_name',
		numeric: false,
		label: 'staffing_table.position_name',
	},
	{
		id: 'rate',
		numeric: true,
		label: 'staffing_table.rate',
	},
	{
		id: 'salary',
		numeric: true,
		label: 'staffing_table.salary',
	},
	{
		id: 'supplement_surcharge',
		numeric: true,
		label: 'staffing_table.supplement_surcharge',
	},
	{
		id: 'action',
		numeric: false,
		label: 'staffing_table.action',
	},
]
