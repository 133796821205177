import React from 'react'
import { Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Field, Title } from '@/ui'
import styles from './Conditions.module.scss'

export const ContractOther = ({ isFormDirty }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.conditionSection}>
			<Title className={styles.title}>{t('change_contract_other')}</Title>
			<FormikField name="otherConditions">
				{({ field, meta: { touched, error } }) => (
					<Field
						type="text"
						fieldType={'textarea'}
						isRequired
						label={t('change_other')}
						placeholder={t('enter_other_working_conditions')}
						error={(touched || isFormDirty) && error}
						showCount
						maxLength={3500}
						rows={6}
						{...field}
					/>
				)}
			</FormikField>
		</div>
	)
}
