import React from 'react'

export const Pencil = ({ className = '', fill = 'none' }) => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M11.8127 2.468L9.5322 0.187359C9.41241 0.0673394 9.24978 0 9.08012 0C8.91046 0 8.74804 0.0673394 8.62805 0.187359L1.08263 7.73262C1.00504 7.81 0.948991 7.90591 0.919149 8.0112L0.0239246 11.1871C-0.0387369 11.4098 0.0237283 11.6489 0.187401 11.8126C0.308895 11.9341 0.472175 12 0.63948 12C0.697233 12 0.75564 11.9921 0.812772 11.9761L3.98868 11.0809C4.09417 11.0511 4.19011 10.9948 4.26746 10.9174L11.8127 3.37217C12.0625 3.12255 12.0625 2.71756 11.8127 2.468ZM3.48136 9.89517L1.56455 10.4353L2.10487 8.5189L9.08012 1.5438L10.4564 2.92007L3.48136 9.89517Z" />
			<path d="M2.00781 7.71095L1.10352 8.61523L3.38383 10.8955L4.28813 9.99123L2.00781 7.71095Z" />
		</svg>
	)
}
