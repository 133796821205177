import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { Radio } from '@/entity/contracts/ui/form/components/Radio'
import styles from './Form.module.scss'

export const Form = ({ changeWithPhoto }) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()

	useEffect(() => {
		changeWithPhoto(values)
	}, [changeWithPhoto, values])

	return (
		<Field name="withPhoto">
			{({ field, name, children, ...props }) => {
				const fieldName = name || field?.name
				return (
					<RadioGroup classes={{ root: styles.radioGroup }} {...field} {...props} name={fieldName}>
						<FormControlLabel
							control={<Radio />}
							label={t('labor_records.with_photo')}
							value={true}
							classes={{ root: styles.label__root }}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t('labor_records.without_photo')}
							value={false}
							classes={{ root: styles.label__root }}
						/>
					</RadioGroup>
				)
			}}
		</Field>
	)
}
