import React from 'react'

export const InsertRowAbove = ({ className = '' }) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.7297 5.75H2.27031C1.98281 5.75 1.75 5.97344 1.75 6.25V13.5C1.75 13.7766 1.98281 14 2.27031 14H13.7281C14.0156 14 14.2484 13.7766 14.2484 13.5V6.25C14.25 5.97344 14.0172 5.75 13.7297 5.75ZM5.625 12.875H2.875V10.375H5.625V12.875ZM5.625 9.375H2.875V6.875H5.625V9.375ZM9.375 12.875H6.625V10.375H9.375V12.875ZM9.375 9.375H6.625V6.875H9.375V9.375ZM13.125 12.875H10.375V10.375H13.125V12.875ZM13.125 9.375H10.375V6.875H13.125V9.375ZM14.125 3H1.875C1.80625 3 1.75 3.05625 1.75 3.125V4.375C1.75 4.44375 1.80625 4.5 1.875 4.5H14.125C14.1938 4.5 14.25 4.44375 14.25 4.375V3.125C14.25 3.05625 14.1938 3 14.125 3Z"
				fill="#17B67C"
			/>
		</svg>
	)
}
