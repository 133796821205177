import { handbookThunks } from '@/entity/handbook'
import { companyActions } from '@/entity/company'
import { actions } from '@/entity/handbook/actions'
import { setToLocalStorage, api } from '@/shared'
import { saveCurrentBin } from '@/shared/api/rest/profile'

//TODO ID по умолчанию, используется в случае если company.id равен 123456789 для ФЛ
const DEFAULT_ID = 123456789

export const getCompany = (payload) => {
	return async (dispatch, getState) => {
		const state = getState()
		const companies = state.company.companies
		const company = companies.find((company) => company.id === Number(payload.id))
		if (company?.id) {
			const flEmployer = company.id === DEFAULT_ID
			setToLocalStorage('activeCompany', company)
			dispatch(companyActions.setCompany({ id: company.id }))
			saveCurrentBin(company?.bin, flEmployer)
		}
		dispatch(
			getOptions({
				ownershipType: company?.downershipTypeCode,
				oked: company?.dokedCode,
				region: company?.dregionCode,
				district: company?.ddistrictCode,
				populatedArea: company?.dlocalityCode,
			})
		)
	}
}

export const getCompanyForBin = (bin) => {
	return async (dispatch, getState) => {
		const state = getState()
		const companies = state.company.companies
		const company = companies.find((item) => item.bin === bin)
		if (company?.bin) {
			const flEmployer = company.id === DEFAULT_ID
			setToLocalStorage('activeCompany', company)
			dispatch(companyActions.setCompany({ id: company.id }))
			await saveCurrentBin(company?.bin, flEmployer)
		}
		dispatch(
			getOptions({
				ownershipType: company?.downershipTypeCode,
				oked: company?.dokedCode,
				region: company?.dregionCode,
				district: company?.ddistrictCode,
				populatedArea: company?.dlocalityCode,
			})
		)
	}
}
export const getXmlForSign = () => {
	return async (dispatch) => {
		try {
			const xml = await api.company.getXmlToSignForCompanyCreation()
			dispatch(companyActions.setCreationXml(xml))
		} catch (err) {
			alert(err.message || err)
		}
	}
}

export const getOptions = ({ ownershipType, oked, region, district, populatedArea }) => {
	return async (dispatch) => {
		if (ownershipType) {
			dispatch(
				handbookThunks.findOne({
					dictCode: 'ownershipType',
					saveBy: 'ownershipType',
					code: ownershipType,
				})
			)
		}
		if (oked) {
			dispatch(handbookThunks.findOne({ dictCode: 'oked', saveBy: 'oked', code: oked }))
		}
		if (region) {
			dispatch(handbookThunks.findOne({ dictCode: 'kato', saveBy: 'region', code: region }))
		}
		if (district) {
			dispatch(handbookThunks.findOne({ dictCode: 'kato', saveBy: 'district', code: district }))
		}
		if (populatedArea) {
			dispatch(
				handbookThunks.findOne({
					dictCode: 'kato',
					saveBy: 'populatedArea',
					code: populatedArea,
				})
			)
		} else {
			dispatch(
				actions.setSingleOptions({
					name: 'populatedArea',
					data: { id: null, code: null, rus: null, kz: null },
				})
			)
		}
		try {
			//TODO разобраться для чего этот вызов
			const xml = await api.company.getXmlToSignForCompanyCreation()
			dispatch(companyActions.setCreationXml(xml))
		} catch (err) {
			alert(err.message || err)
		}
	}
}
