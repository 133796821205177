export const isLoading = (state) => state.profUnions.isLoading
export const isSuccess = (state) => state.profUnions.isSuccess
export const error = (state) => state.profUnions.error
export const isLoadingChiefFio = (state) => state.profUnions.isLoadingChiefFio
export const isLoadingBin = (state) => state.profUnions.isLoadingBin
export const isLoadingBinParent = (state) => state.profUnions.isLoadingBinParent
export const isLoadingBinEmployer = (state) => state.profUnions.isLoadingBinEmployer
export const xml = (state) => state.profUnions.xml
export const searchChiefFio = (state) => state.profUnions.profUnions.searchChiefFio
export const searchName = (state) => state.profUnions.profUnions.searchName
export const searchParentName = (state) => state.profUnions.profUnions.searchParentName
export const searchEmployerName = (state) => state.profUnions.profUnions.searchEmployerName
export const bin = (state) => state.profUnions.profUnions.bin
export const parentName = (state) => state.profUnions.profUnions.parentName
export const unionName = (state) => state.profUnions.profUnions.unionName
export const profUnionsList = (state) => state.profUnions.profUnionsList
export const personsData = (state) => state.profUnions.persons
export const profUnions = (state) => state.profUnions
export const tradeUnion = (state) => state.profUnions.profUnions
export const typeCode = (state) => state.profUnions.profUnions.typeCode
export const members = (state) => state.profUnions.members
export const myUnions = (state) => state.profUnions.myUnions
