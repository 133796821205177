import { connect, FieldArray, Field as FormikField } from 'formik'
import styles from './EditContractDataField.module.scss'
import { formFieldNames } from '@/entity/laborRecords/lib/constants'
import { Checkbox, Field, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

export const SocialLeaveField = connect(
	({ contractDate, socialLeaveTypes = [], isView, formik: { setFieldValue, values, errors } }) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()
		const dateArr = contractDate?.split('.')
		const onChange = (field, index) => {
			setFieldValue(`socialLeaves.${index}.endDateIs`, !field.value)
			if (values.socialLeaves[index]?.dleaveType.code === '1') {
				setFieldValue(`socialLeaves.${index}.workDateIs`, !field.value)
			}
		}
		return (
			<div className={styles.wrapper}>
				<FieldArray name={formFieldNames.socialLeaves}>
					{() => (
						<>
							{values.socialLeaves.map((item, index) => (
								<div key={index} className={styles.mBottom}>
									<div className={styles.mBottom}>
										<div className={styles.gridTwoReversWrapper}>
											<div className={'flex-1'}>
												<Title type={'h5'}>{t('labor_records.social_leave_num')}</Title>
												<div className={styles.mLeft}>
													<FormikField
														name={
															lang === 'rus'
																? `socialLeaves.${index}.dleaveType.nameRu`
																: `socialLeaves.${index}.dleaveType.nameKz`
														}
													>
														{({ field }) => (
															<Field
																labelClassName={styles.social}
																label={t('soc_vacation_type')}
																{...field}
																disabled={true}
															/>
														)}
													</FormikField>
												</div>
											</div>
											<div className={'flex-1'}>
												{values.socialLeaves[index]?.dleaveType.code === '1' ? (
													<div className={styles.mTop}>
														{isView ? null : <FormikField name={`socialLeaves.${index}.daysOffCountIs`}>
															{({ field }) => (
																<Checkbox
																	{...field}
																	label={t('labor_records.weekend')}
																	onChange={() => setFieldValue(field.name, !field.value)}
																/>
															)}
														</FormikField>}
														<FormikField name={`socialLeaves.${index}.daysOffCount`}>
															{({ field, meta: { touched, error } }) => (
																<Field
																	type="number"
																	min={0}
																	max={365}
																	hideNumberArrows
																	error={touched && error}
																	{...field}
																	disabled={!values.socialLeaves[index].daysOffCountIs || isView}
																/>
															)}
														</FormikField>
													</div>
												) : null}
											</div>
										</div>
									</div>
									<div className={styles.gridWrapper}>
										<div className={'flex-1'}>
											<div className={styles.mLeft}>
												{isView ? null : (
													<FormikField name={`socialLeaves.${index}.beginDateIs`}>
														{({ field }) => (
															<Checkbox
																{...field}
																label={t('labor_records.vacation_date_start')}
																onChange={() => setFieldValue(field.name, !field.value)}
															/>
														)}
													</FormikField>
												)}
												<FormikField name={`socialLeaves.${index}.beginDate`}>
													{({ field, meta: { touched, error } }) => {
														return (
															<Field
																minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
																{...field}
																fieldType="datePicker"
																maxDate={new Date()}
																disabled={!values.socialLeaves[index].beginDateIs || isView}
																error={touched && error}
															/>
														)
													}}
												</FormikField>
											</div>
										</div>
										<div className={'flex-1'}>
											{isView ? null : (
												<FormikField name={`socialLeaves.${index}.endDateIs`}>
													{({ field }) => (
														<Checkbox
															{...field}
															label={t('labor_records.vacation_date_end')}
															onChange={() => onChange(field, `${index}`)}
														/>
													)}
												</FormikField>
											)}
											<FormikField name={`socialLeaves.${index}.endDate`}>
												{({ field, meta: { touched, error } }) => {
													return (
														<Field
															minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
															{...field}
															fieldType="datePicker"
															maxDate={new Date()}
															disabled={!values.socialLeaves[index].endDateIs}
															error={touched && error}
														/>
													)
												}}
											</FormikField>
										</div>
										<div className={'flex-1'}>
											{values.socialLeaves[index].dleaveType.code === '1' ?
												(<>{isView ? null : (
												<FormikField name={`socialLeaves.${index}.workDateIs`}>
													{({ field }) => (
														<Checkbox
															{...field}
															label={t('vacation_date_work_start')}
															onChange={() => onChange(field, `${index}`)}
														/>
													)}
												</FormikField>
											)}
											<FormikField name={`socialLeaves.${index}.workDate`}>
												{({ field, meta: { touched, error } }) => {
													return (
														<Field
															minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
															maxDate={new Date()}
															{...field}
															fieldType="datePicker"
															disabled={!values.socialLeaves[index].workDateIs || !values.socialLeaves[index].endDateIs || isView}
															error={touched && error}
														/>
													)
												}}
											</FormikField></>) : null}
										</div>
									</div>
								</div>
							))}
						</>
					)}
				</FieldArray>
			</div>
		)
	}
)
