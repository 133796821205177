import React from 'react'
import './Typography.scss'

const classname = 'typography'

const TitleType = (type, children, className) => {
	switch (type) {
		case 1:
			return <h1 className={`${classname} ${className ? className : ''}`}> {children}</h1>

		case 2:
			return <h2 className={`${classname} ${className ? className : ''}`}>{children}</h2>

		case 3:
			return <h3 className={`${classname} ${className ? className : ''}`}>{children}</h3>

		case 4:
			return <h4 className={`${classname} ${className ? className : ''}`}>{children}</h4>

		default:
			return <h1 className={`${classname} ${className ? className : ''}`}>{children}</h1>
	}
}

export const Title = ({ children, level, className }) => {
	return TitleType(level, children, className)
}
export const Subtitle = ({ children }) => {
	return <p className={`${classname} subtitle`}>{children}</p>
}
