import styles from './TableDocuments.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { opvSelectors } from '@/entity/opv'
import { getCurrentLanguage } from '@/i18next'
import trash from '@/assets/home/trash.svg'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
export const TableDocuments = ({ view, data, removeDocument }) => {
	const { t } = useTranslation()
	const isLoading = useSelector(opvSelectors.isLoading)
	const isSuccess = useSelector(opvSelectors.isSuccess)
	const lang = getCurrentLanguage()
	const downloadFile = async (row) => {
		const response = await downloadFileByUid(row.docId)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = row.file.fileName
		document.body.appendChild(a)
		a.click()
	}
	const getTableRow = (row, index, view) => {
		return (
			!row.deleted && (
				<tr key={index + '-table-row'}>
					<td>{row.docType ? (lang === 'rus' ? row.docType.nameRu : row.docType.nameKz) : '-'}</td>
					<td>
						<a onClick={() => downloadFile(row)}>{row.file?.fileName}</a>
					</td>
					{!view && (
						<td>
							<div
								className={styles.file__delete}
								onClick={() => {
									removeDocument(index)
								}}
							>
								<img src={trash} alt={t('remove')} />
								<span>{t('remove')}</span>
							</div>
						</td>
					)}
				</tr>
			)
		)
	}

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index, view)), [data, getTableRow, view])
	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan="6" className={styles.message}>
					{t('pension.payments.form.table.add_entries')}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>{t('pension.payments.form.table.document_type')}</th>
						<th>{t('pension.payments.form.table.document_name')}</th>
					</tr>
				</thead>
				<tbody>{data.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}
