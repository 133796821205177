import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './DetailsOfPartiesInformation.module.scss'
import { Box } from '@mui/system'

export const DetailsOfPartiesInformation = ({ contract, subTitleAction }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const detailsOfPartiesEmoloyer = useMemo(
		() => [
			{ title: t('bin_iin'), data: contract.bin },
			{ title: t('name'), data: contract.companyName },
		],
		[contract, t]
	)

	const detailsOfPartiesEmployee = useMemo(
		() => [
			{ title: t('yin'), data: contract?.iin },
			{ title: t('fio'), data: contract?.fullName },
		],
		[contract, t]
	)

	const languageFields = {
		rus: 'companyName',
		kz: 'companyNameKaz',
	}

	return (
		<div className={styles.container}>
			<div className="flex-1">
				<p className={styles.subTitle}>{t('employer')}:</p>
				<Box display="flex" alignItems="flex-start">
					<div className={styles.titles}>
						<div>{t('bin_iin')}:</div>
						<div className={styles.mt}>{t('name')}:</div>
					</div>
					<div>
						<div className={styles.values}>{contract.bin}</div>
						<div className={`${styles.values} ${styles.mt}`}>{contract?.[languageFields[language]]}</div>
					</div>
				</Box>
				{/* <DataGridTable
					hideTitle
					subTitle={t('employer')}
					subTitleClass={styles.subTitle}
					information={detailsOfPartiesEmoloyer}
				/> */}
			</div>
			<div className="flex-1">
				<DataGridTable
					hideTitle
					subTitle={t('employee')}
					subTitleClass={styles.subTitle}
					information={detailsOfPartiesEmployee}
					subTitleAction={subTitleAction}
				/>
			</div>
		</div>
	)
}
