import { useState, useRef, useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, Button, Box, List, ListItemButton, ListItemText } from '@mui/material'
import { Button as FormButton } from '@/ui'
import styles from '../style.module.scss'
import cn from 'classnames'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import localSstyles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import ModalReset from './ModalReset'
import ModalConfirm from './ModalConfirm'
import IconSave from './IconSave'
import '@/assets/bootstrap/bootstrap-datepicker.css'
import '@/assets/bootstrap/bootstrap.min.css'
import { datePickerlocaleRu, datePickerlocaleKz } from '@/i18next/index'
import ListItem from '@mui/material/ListItem'
import CloseButton from '@/components/CloseButton/CloseButton'

export const ContractProject = ({
	isOpen,
	onClose,
	isAdditional = false,
	htmlData,
	handleSendToEmployee,
	lang,
	CloseAndUpdateFilter,
}) => {
	const [modalResetOpen, setModalResetOpen] = useState(false)
	const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
	const [modalConfirmMessage, setModalConfirmMessage] = useState('')
	const [html, setHtml] = useState(null)

	useEffect(() => {
		if (htmlData) {
			setHtml(htmlData)
		}
	}, [htmlData])

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const handleSaveTemplate = async (isSendToEmployee) => {
		const availableEmpty = ['beginDate']
		var el = document.createElement('html')
		el.innerHTML = refSubContainer.current.innerHTML

		const elementsCollection = el.getElementsByClassName('field')
		const editTableBlocks = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName != 'INPUT')
		const dateInputs = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName == 'INPUT')

		const parsedData = []
		const parsedDataNumbers = []
		const datatoValidate = {}

		if (editTableBlocks && editTableBlocks?.length) {
			for (let i = 0; i < editTableBlocks.length; i++) {
				const element = editTableBlocks[i]
				if (element.classList.contains('number')) {
					parsedDataNumbers.push({
						code: element.getAttribute('data-name'),
						value: element.innerText,
						required: Boolean(element.getAttribute('required')),
					})
				}
				const findElement = parsedData.find((el) => el.code === element.getAttribute('data-name'))
				if (!findElement) {
					if (!(['1.5.4rus', '1.5.4kaz'].includes(element.getAttribute('data-name')) && element.innerText === ''))
						parsedData.push({
							code: element.getAttribute('data-name'),
							value: element.innerText,
							required: Boolean(element.getAttribute('required')),
						})
				}
				datatoValidate[element.getAttribute('data-name')] = element.innerText
			}
		}

		if (dateInputs && dateInputs?.length) {
			for (let i = 0; i < dateInputs.length; i++) {
				const element = dateInputs[i]
				parsedData.push({
					code: element.getAttribute('data-name'),
					value: element.value,
					required: true,
				})
				// }
				datatoValidate[element.getAttribute('data-name')] = element.value
			}
		}

		const emptyItems = parsedData.filter(
			(item) => item.value.replace(/\s/g, '') == '' && !availableEmpty.includes(item.code) && item.required
		)

		const wrongParsedDataNumbers = parsedDataNumbers.filter((item) => {
			return /[^0-9,]/g.test(item.value) && item.required
		})

		if (wrongParsedDataNumbers.length != 0) {
			ChangeColor(parsedDataNumbers, '#17B67C')
			const _wrongParsedDataNumbers = wrongParsedDataNumbers.map((item) => {
				return {
					...item,
					label: editTableBlocks.find((element) => element.getAttribute('data-name') == item.code)?.ariaLabel,
				}
			})
			ChangeColor(_wrongParsedDataNumbers, 'red')
			setModalConfirmOpen(true)
			setModalConfirmMessage(
				lang === 'rus' ? 'Значения полей должны быть числовыми' : 'Өріс мәндері сандық болуы керек'
			)
			setModalConfirmMessage(
				<List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
					<b>
						{lang === 'rus'
							? 'Значение полей должны быть числовым в электронном трудовом договоре:'
							: 'Өрістердің мәні электрондық еңбек шартында сандық болуы керек:'}
					</b>
					<br />
					<br />
					{_wrongParsedDataNumbers.map((item, index) => (
						<ListItem disablePadding key={index}>
							<ListItemButton>
								<ListItemText>{item.label + ' ' + getNumberOfDataName(item.code)}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)
			return null
		}

		if (emptyItems.length !== 0) {
			ChangeColor(parsedData, '#17B67C')
			const _emptyItems = emptyItems.map((item) => {
				return {
					...item,
					label: editTableBlocks.find((element) => element.getAttribute('data-name') === item.code)?.ariaLabel,
				}
			})
			ChangeColor(_emptyItems, 'red')
			setModalConfirmOpen(true)
			setModalConfirmMessage(
				<List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="contacts">
					<ListItem disablePadding key={'3-default'}>
						<ListItemButton>
							<ListItemText>
								<b>
									{lang === 'rus'
										? 'Вы не заполнили поля в электронном трудовом договоре:'
										: 'Сіз электронды еңбек шартында келесі өрістерді толтырмадыңыз:'}
								</b>
							</ListItemText>
						</ListItemButton>
					</ListItem>
					{_emptyItems.map((item, index) => (
						<ListItem disablePadding key={index}>
							<ListItemButton>
								<ListItemText>{getItemText(item)}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)
			return null
		}
		handleSendToEmployee(parsedData, isSendToEmployee, CloseAndUpdateFilter)
	}

	const getItemText = (item) => {
		if (item?.label === undefined) {
			return lang === 'rus' ? 'указать дату(вводной части ЭТД)' : 'күнді корсету(ЭЕШ-тің кіріспе бөлігі)'
		}

		if (item.code === 'placeConclusion') {
			return lang === 'rus'
				? 'Вы не заполнили место заключения в электронном трудовом договоре'
				: 'Сіз электронды еңбек шартында жасалған орынды толтырмадыңыз'
		}

		return item.label + ' ' + getNumberOfDataName(item.code)
	}

	const ChangeColor = (items, color) => {
		items.map((a) => (refSubContainer.current.querySelector('[data-name="' + a.code + '"]').style.color = color))
	}

	const getNumberOfDataName = (code) => {
		const codeNumber = refSubContainer.current
			.querySelector('[data-name="' + code + '"]')
			.parentElement.textContent.split(' ')
			.at(0)
		if (!isNaN(codeNumber.charAt(0))) return (lang === 'rus' ? 'пунктов' : 'тармақтар') + ' (' + codeNumber + ')'
		else return ' (' + (lang === 'rus' ? 'вводной части ЭТД' : 'ЭЕШ-тың кіріспе бөлігі') + ')'
	}

	const handleModalOpen = () => {
		setModalResetOpen(true)
	}

	const handleReset = () => {
		setHtml(null)
		setTimeout(() => setHtml(htmlData))
		setModalResetOpen(false)
	}

	const refContainer = useRef(null)
	const refSubContainer = useRef(null)

	$.fn.bootstrapDP.defaults.language = language
	$.fn.bootstrapDP.defaults.defaultViewDate = new Date()
	$.fn.bootstrapDP.defaultViewDate = new Date()
	$.fn.bootstrapDP.dates['rus'] = datePickerlocaleRu
	$.fn.bootstrapDP.dates['kz'] = datePickerlocaleKz

	return (
		<>
			<Dialog scroll={'paper'} classes={{ paper: styles.dialog__new__template }} onClose={onClose} open={isOpen}>
				<DialogTitle>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Button
							startIcon={<ArrowBackIosIcon />}
							classes={{ root: cn(styles.button, styles.back__button) }}
							variant="text"
							onClick={onClose}
						>
							{t('back')}
						</Button>
						<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
					</Box>
				</DialogTitle>
				<DialogContent>
					{!isAdditional && (
						<Box className={styles.sub__title}>{t('electronic_contract.open_contract_project_title')}</Box>
					)}
					{isAdditional && <Box className={styles.sub__title}>{t('electronic_contract.project')}</Box>}

					<div className={localSstyles.editor__wrapper} ref={refContainer}>
						<div
							dangerouslySetInnerHTML={{ __html: html }}
							ref={refSubContainer}
							className={localSstyles.editor__subwrapper}
						></div>
					</div>

					<div className={localSstyles.buttons__wrapper}>
						<Button
							type="button"
							variant="text"
							classes={{ root: cn(styles.button, styles.reset__button) }}
							onClick={handleModalOpen}
						>
							{t('electronic_contract.resert_values')}
						</Button>
						<Button
							type="button"
							startIcon={<IconSave />}
							classes={{ root: cn(styles.button, localSstyles.button__save) }}
							sx={{ paddingLeft: '20px !important' }}
							onClick={handleSaveTemplate.bind(null, false)}
						>
							{t('save')}
						</Button>
						<FormButton type="submit" onClick={handleSaveTemplate.bind(null, true)}>
							{t('send_to_sign_worker')}
						</FormButton>
					</div>
				</DialogContent>
			</Dialog>
			<ModalReset onClose={() => setModalResetOpen(false)} isOpen={modalResetOpen} onReset={handleReset} />
			<ModalConfirm
				onClose={() => setModalConfirmOpen(false)}
				isOpen={modalConfirmOpen}
				message={modalConfirmMessage}
			/>
		</>
	)
}
