export const calculateStaffUnits = (data) => {
	let totalStaffUnits = 0
	let totalFreeStaffUnits = 0

	if (data.workPlaces) {
		for (let workplace of data.workPlaces) {
			totalStaffUnits += workplace.staffUnitsCount || 0 // прибавляем staffUnitsCount, если оно есть
			totalFreeStaffUnits += workplace.staffFreeCount || 0 // прибавляем staffFreeCount, если оно есть
		}
	}

	if (data.childs) {
		for (let child of data.childs) {
			let { totalStaffUnits: childStaffUnits, totalFreeStaffUnits: childFreeStaffUnits } = calculateStaffUnits(child)
			totalStaffUnits += childStaffUnits
			totalFreeStaffUnits += childFreeStaffUnits
		}
	}
	return { totalStaffUnits, totalFreeStaffUnits }
}
