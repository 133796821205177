import { DialogTitle, DialogContent, Dialog, IconButton, Button, Box } from '@mui/material'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'
import React from 'react'

export const DeleteTemplate = ({ isOpen, onClose, onConfirm }) => {
	const { t } = useTranslation()

	return (
		<Dialog classes={{ paper: styles.dialog__delete }} onClose={onClose} open={isOpen}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box></Box>
					<Box className={styles.title}>{t('electronic_contract.sure_delete_template')}</Box>
					<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Box display="flex" justifyContent="center">
					<Button onClick={onConfirm} variant="contained" classes={{ root: styles.confirm__button }}>
						{t('remove')}
					</Button>
				</Box>
				<Box display="flex" justifyContent="center">
					<Button onClick={onClose} variant="text" classes={{ root: styles.button }}>
						{t('cancel')}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
