export const checks = (state) => state.forms.checks
export const isLoading = (state) => state.forms.isLoading
export const isSuccess = (state) => state.forms.isSuccess
export const themes = (state) => state.forms.themes
export const questions = (state) => state.forms.questions
export const theme = (state) => state.forms.theme
export const statusCheck = (state) => state.forms.statusCheck
export const testResult = (state) => state.forms.testResult
export const selectedCheck = (state) => state.forms.selectedCheck
export const checksAll = (state) => state.forms.checksAll
export const resultTypes = (state) => state.forms.resultTypes
