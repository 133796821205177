export const TOGGLE_LOADING = 'verification/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'verification/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'verification/TOGGLE_ERROR'
export const SET_PHONE = 'verification/SET_PHONE'
export const SET_QR = 'verification/SET_QR'
export const SET_QR_KEY = 'verification/SET_QR_KEY'
export const GET_QR_STATUS = 'verification/GET_QR_STATUS'
export const TOGGLE_SUCCESS_SEND = 'verification/TOGGLE_SUCCESS_SEND'
export const TOGGLE_ERROR_SEND = 'verification/TOGGLE_ERROR_SEND'
export const RESET_VERIFICATION = 'verification/RESET_VERIFICATION'
