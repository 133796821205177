export const headCells = [
	{
		id: 'id',
		numeric: false,
		label: '',
	},
	{
		id: 'union_name',
		numeric: false,
		label: 'staffing_table.union_name',
	},
	{
		id: 'name',
		numeric: false,
		label: 'staffing_table.name',
	},
	{
		id: 'code',
		numeric: false,
		label: 'staffing_table.code_wp',
	},
	{
		id: 'action',
		numeric: false,
		label: 'staffing_table.action',
	},
	{
		id: 'error',
		numeric: false,
	},
]
