import * as types from './types'

const initialState = {
	positionsByKnz: [],
	positionsByKnzFull: [],
	positions: [],
	jobTypes: [],
	remoteWorkTypes: [],
	workingTimeModes: [],
	countries: [],
	regions: [],
	districts: [],
	populatedAreas: [],
	professionalSkills: [],
	personalQualities: [],
	contractStatuses: [],
	contractStates: [],
	ownerships: [],
	contractTerms: [],
	contractTypes: [],
	okeds: [],
	singleOptions: {
		partTime: null,
		oked: null,
	},
	socialLeaveTypes: [],
	restoreOrganizationTypes: [],
	restoreOrganizationCourts: [],
	sendStatuses: [],
	esutdRegErrors: [],
	laborType: [],
	workActivityTypes: [],
	workCondition: [],
	workTimeCollectiveContract: [],
	relaxTimeCollectiveContract: [],
	extraGuaranteesCollectiveContract: [],
	unionTypeCollectiveContract: [],
	workActivityTypeGroups: {
		list1: [],
		list2: [],
		list3: [],
	},
	nonresidentAttractingBasis: [],
	nonresidentEducation: [],
	ccEnterpriseType: [],
	ccOwnershipType: [],
	staffType: [],
	staffStatus: [],
	staffEmpStatus: [],
	unionType: [],
	okedsTop: [],
	calcActivityArea: [],
	calcAssessmentCriteria: [],
	calcBlockHasCalcDuty: [],
	calcDutyFunction: [],
	calcFactor: [],
	calcFunctionalBlock: [],
	calcFunctionalDuty: [],
	calcOrganizationType: [],
	calcPositionRegister: [],
	calcPositionRegisterHasDutyFunction: [],
	calcBase: [],
	calcGradeHaveDuty: [],
	calcGradeLevel: [],
	accidentSendStatus: [],
	genders: [],
	industryAgreement: [],
	reviewType: [],
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case types.SET_OPTIONS:
			return {
				...state,
				[payload.name]: payload.data,
			}
		case types.SET_SINGLE_OPTIONS:
			return {
				...state,
				singleOptions: { ...state.singleOptions, [payload.name]: payload.data },
			}
		default:
			return state
	}
}
