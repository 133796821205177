import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'
import { DataGridTable, Title } from '@/ui'
import React, { useCallback, useMemo } from 'react'
import { employerProfileSelectors } from '@features/employerProfile/model'
import styles from './Information.module.scss'
import { Step } from '@/components/Step/Step'
import { formatSelectData } from '@/utils'

export const Information = ({ accident }) => {
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const accountData = [
		{ title: t('employer_iin_bin'), data: activeCompany.bin },
		{ title: t('name'), data: activeCompany.companyName },
	]

	const address =
		(accident.region ? formatSelectData(accident.region)[lang] : '') +
		', ' +
		(accident.district ? formatSelectData(accident.district)[lang] : '') +
		', ' +
		(accident.location ? accident.location : '')

	const registrationInfo = () => {
		return [
			{
				title: t('accidentsPage.view.accidentShortDate'),
				data: new Date(accident.accidentDateTime).toLocaleString('ru-RU') || '-',
			},
			{ title: t('accidentsPage.register.incidentScene'), data: address },
			{ title: t('region'), data: accident.region ? formatSelectData(accident.region)[lang] : '' },
			{ title: t('district'), data: accident.district ? formatSelectData(accident.district)[lang] : '' },
			{ title: t('accidentsPage.view.circumstances'), data: accident.circumstance || '' },
		]
	}

	const victim = (row) => {
		return [
			{ title: t('accidentsPage.register.resident'), data: row.isResident ? t('yes') : t('no') },
			...(row.isResident
				? [{ title: t('iin'), data: row.iin || '' }]
				: [
						{
							title: t('accidentsPage.register.passport'),
							data:
								lang === 'rus'
									? '№ ' + row.passportNumber + t('accidentsPage.view.issued') + row.issuredWhen + ' ' + row.issuredBy
									: row.issuredBy + ' ' + row.issuredWhen + t('accidentsPage.view.issued') + '№ ' + row.passportNumber,
						},
						{ title: t('accidentsPage.register.birthday'), data: row.birthDate || '' },
				  ]),
			{ title: t('gender'), data: formatSelectData(row.gender)[lang] || '' },
			{ title: t('accidentsPage.register.position'), data: formatSelectData(row.establishedPost)[lang] || '' },
			{ title: t('accidentsPage.view.diagnosis'), data: row?.diagnosis || '' },
		]
	}

	const getTableRow = useCallback((row) => {
		const fullPersonName = row.surname + ' ' + row.firstname + ' ' + row.secondname
		return (
			<div className={styles.mb}>
				<DataGridTable
					key={row.externalId}
					customTitle={fullPersonName}
					hideTitle
					hideSubTitle
					information={victim(row)}
					titleClass={styles.tableTitleCell}
					rowClass={styles.ml}
				/>
			</div>
		)
	}, [])

	const injureds = useMemo(() => accident.injureds?.map((row) => getTableRow(row)), [accident, getTableRow])

	const senderPerson = () => {
		return [
			{ title: t('iin'), data: accident.senderIin },
			{ title: t('fio'), data: accident.senderFullName },
			{ title: t('accidentsPage.register.position'), data: formatSelectData(accident.senderEstablishedPost)[lang] },
		]
	}

	const personCharge = () => {
		return [
			{ title: t('position_evaluation_page.form.bin'), data: accident.inspectorpOrganBin },
			{ title: t('fio'), data: accident.inspectorFio },
			{
				title: t('accidentsPage.register.position'),
				data: lang === 'rus' ? accident.inspectorPositionNameRu : accident.inspectorPositionNameKz,
			},
			{
				title: t('accidentsPage.view.organization'),
				data: lang === 'rus' ? accident.inspectorpOrganNameRu : accident.inspectorpOrganNameKz,
			},
			{ title: t('fio'), data: accident.inspectorPhoneNumber },
		]
	}

	return (
		<>
			<Title color="black">{t('accidentsPage.registration_new_accident')}</Title>
			<div className={styles.tableCell}>
				<DataGridTable hideSubTitle hideTitle information={accountData} titleClass={styles.tableTitleCell} />
			</div>
			<section className={styles.steps}>
				<Step label="accidentsPage.accidentReport" index={1} opened={true}>
					<div className={styles.wrapper}>
						<DataGridTable hideTitle hideSubTitle information={registrationInfo()} titleClass={styles.tableTitleCell} />
					</div>
					<div className={styles.wrapper}>
						<Title type={'h5'} color={'darkBlue'}>
							{t('accidentsPage.view.victims')}
						</Title>
						{injureds}
					</div>
					<div className={styles.wrapper}>
						<Title type={'h5'} color={'darkBlue'}>
							{t('accidentsPage.register.applicant')}
						</Title>
						<DataGridTable hideTitle hideSubTitle information={senderPerson()} titleClass={styles.tableTitleCell} />
					</div>
				</Step>
				{/*TODO временно закомментировано 14.08.2024 до реализации в Охране Труда
				<Step label="accidentsPage.view.personCharge" index={2} opened={true}>
					<section style={{ padding: '32px 64px', display: 'flex', gap: '8px' }}>
						{accident.inspectorFio ? (
							<DataGridTable hideTitle hideSubTitle information={personCharge()} titleClass={styles.tableTitleCell} />
						) : (
							<>
								<InfoGreen></InfoGreen>
								<label style={{ fontFamily: 'Inter', fontStyle: 'italic' }}>
									{t('accidentsPage.view.responsiblePerson')}
								</label>
							</>
						)}
					</section>
				</Step>*/}
			</section>
		</>
	)
}
