import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field as FormikField } from 'formik'
import { Field, Button } from '@/ui'
import styles from './ProlongationForm.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const ProlongationForm = ({ dcontractDate, endDate, onSubmit, onCancel }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dicName = useMemo(
		() => (lang === 'rus' ? dcontractDate.nameRu : dcontractDate.nameKz),
		[dcontractDate.nameKz, dcontractDate.nameRu, lang]
	)

	const handleDataProcess = (data) => {
		const toSubmit = {
			dcontractDateCode: dcontractDate.code,
			dateTo: data.dateTo,
		}
		onSubmit(toSubmit)
	}

	const leapYear = (year, month, date) => {
		if (year % 4 === 0) {
			if ((date === '01' && month === '03') || (date === '29' && month === '02')) {
				return 1
			}
		}
		return 0
	}

	const dateArr = endDate?.split('.')
	const calcDate = useMemo(() => (dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''), [dateArr])
	const beginDate = useMemo(
		() =>
			dateArr ? new Date(calcDate.setDate(calcDate.getDate() - 1 - leapYear(dateArr[2], dateArr[1], dateArr[0]))) : '',
		[calcDate, dateArr]
	)
	const controlDate = useMemo(() => (dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''), [dateArr])

	return (
		<Formik
			initialValues={{
				dcontractDateCode: dicName,
				dateTo: endDate,
			}}
			onSubmit={handleDataProcess}
		>
			{({ values }) => (
				<Form className={styles.form}>
					<div className={styles.row}>
						<div className="flex-1">
							<FormikField name="dcontractDateCode">
								{({ field }) => <Field label={t('contract_term')} type="text" isRequired {...field} disabled={true} />}
							</FormikField>
						</div>
						<div className="flex-1">
							<FormikField name="dateTo">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="datePicker"
										label={t('contract_end_date')}
										isRequired={dcontractDate.code !== '1'}
										minDate={dateArr ? beginDate : ''}
										disabled={dcontractDate.code === '1'}
										placeholder={t('default_date_value')}
										maxDate={dateArr ? controlDate : ''}
										error={touched && error ? error : ''}
										{...field}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1" />
					</div>
					<div className={styles.footer}>
						<Button type="bare" buttonClassName={styles.button} onClick={onCancel}>
							{t('cancel')}
						</Button>
						<Button
							type="submit"
							disabled={!values.dateTo && dcontractDate.code !== '1'}
							buttonClassName={styles.button}
						>
							{t('sign_and_prolong')}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
