export const colors = {
	1: '#AAB8D1',
	2: '#17B67C',
	3: '#EB5757',
	4: '#EB5757',
}
export const type = {
	1: { edit: true },
	2: { terminate: true },
	3: { remove: true },
}
export function dateSort(array, desc = true) {
	return array.sort((a, b) => {
		const [Aday, Amonth, Ayear] = (desc ? a : b).dateFrom.split('.')
		const [Bday, Bmonth, Byear] = (desc ? b : a).dateFrom.split('.')
		return new Date(Byear, Bmonth - 1, Bday) - new Date(Ayear, Amonth - 1, Aday)
	})
}

export function dateSortNotArray(array) {
	return array.sort((a, b) => {
		const [Aday, Amonth, Ayear] = a.dateFrom.split(".");
		const [Bday, Bmonth, Byear] = b.dateFrom.split(".");
		return new Date(Byear, Bmonth - 1, Bday) - new Date(Ayear, Amonth - 1, Aday)
	})
}