import * as types from './types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	user: null,
	activeRole: null,
	userByIin: null,
	employerCompanies: [],
	language: null,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_USER:
			return { ...state, user: payload }
		case types.SET_ACTIVE_ROLE:
			return { ...state, activeRole: payload }
		case types.SET_USER_BY_IIN:
			return { ...state, userByIin: payload }
		case types.SET_EMPLOYER_COMPANIES:
			return { ...state, employerCompanies: payload }
		case types.CLEAR_ERROR_COMPANIES:
			return {
				...state,
				user: { ...state.user, errorCompanies: null },
			}
		default:
			return state
	}
}
