export const formFieldNames = {
	industryAgreementNameKz: 'industryAgreementNameKz',
	industryAgreementNameRu: 'industryAgreementNameRu',
	authStateNameKz: 'authStateNameKz',
	authStateNameRu: 'authStateNameRu',
	industryCodes: 'industryCodes',
	createDate: 'createDate',
	dateFrom: 'dateFrom',
	dateTo: 'dateTo',
	industrialCoef: 'industrialCoef',
	baseSalary: 'baseSalary',
	oneToTwoGradeCoef: 'oneToTwoGradeCoef',
	twoToThreeGradeCoef: 'twoToThreeGradeCoef',
	threeToFourGradeCoef: 'threeToFourGradeCoef',
	fourToFiveGradeCoef: 'fourToFiveGradeCoef',
	fiveToSixGradeCoef: 'fiveToSixGradeCoef',
	sixToSevenGradeCoef: 'sixToSevenGradeCoef',
	sevenToEightGradeCoef: 'sevenToEightGradeCoef',
	updateDateTime: 'updateDateTime',
	updateTextKz: 'updateTextKz',
	updateTextRu: 'updateTextRu',
}