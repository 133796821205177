import styles from './Table.module.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import onRequestActionMenuClick from './actionMenu/onRequestActionMenuClick'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { colors } from '@/features/laborRecords/utils'
import { dateSort } from './utils'
import { laborRecordsActions, laborRecordsSelectors, laborRecordsThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { View } from '@features/laborRecords/actions'
import { LoadingBlocker } from '@/components/Loader'
import { QuestionRevoke } from '@/components/question/QuestionRevoke'
import { sendCancelRequestAdjustment } from '@/entity/laborRecords/model/thunks'
import { useDigitalSign } from '@/shared'

export const Table = ({ data }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { onSign, successPayload, onClearPayload, failedPayload } = useDigitalSign()
	const lang = getCurrentLanguage()
	const isLoading = useSelector(laborRecordsSelectors.isRequestLoading)
	const isSuccess = useSelector(laborRecordsSelectors.isRequestSuccess)
	const isRefresh = useSelector(laborRecordsSelectors.isAdjustmentRefresh)
	const isSuccessCancelAdjustment = useSelector(laborRecordsSelectors.isCancelAdjustmentXmlSuccess)
	const isLoadingCancelAdjustment = useSelector(laborRecordsSelectors.isCancelAdjustmentXmlLoading)
	const xml = useSelector(laborRecordsSelectors.cancelAdjustmentXml)
	const requestDetail = useSelector(laborRecordsSelectors.requestDetail)
	const [actionMenuItems, setActionMenuItems] = useState([])
	const [isOpenQuestionRevoke, setIsOpenQuestionRevoke] = useState(false)
	const [requestAdjustmentId, setRequestAdjustmentId] = useState(null)
	const [actions, setActions] = useState({
		view: false,
		revoke: false,
	})

	useEffect(() => {
		if (isRefresh) {
			dispatch(laborRecordsThunks.getRequestsAdjustmentDetail(requestAdjustmentId))
		}
	}, [isRefresh])

	useEffect(() => {
		if (requestDetail?.requestsAdjustmentId != null) {
			data.splice(
				data.findIndex((item) => item.requestsAdjustmentId === requestAdjustmentId),
				1,
				requestDetail
			)
		}
	}, [requestDetail])

	const getRequest = useCallback((id) => dispatch(laborRecordsThunks.getRequestsAdjustmentDetail(id)), [dispatch])

	const handleProcess = () => {
		!isLoadingCancelAdjustment && dispatch(laborRecordsThunks.getCancelRequestAdjustmentXml(requestAdjustmentId))
	}

	const fullClear = () => {
		dispatch(laborRecordsActions.setCancelAdjustmentXml(null))
		dispatch(laborRecordsActions.toggleCancelAdjustmentXmlSuccess(false))
		setIsOpenQuestionRevoke(false)
		onClearPayload()
	}
	const onClear = () => {
		onClearPayload()
		dispatch(laborRecordsActions.setCancelAdjustmentXml(null))
	}
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => {
		xml && onSign(xml)
		successPayload && !xml && fullClear()
	}, [xml]) /** подписание xml */

	useEffect(() => {
		/** очистка и выход при удачной операции */
		isSuccessCancelAdjustment && fullClear()
		return () => fullClear()
	}, [isSuccessCancelAdjustment])

	useEffect(() => {
		if (successPayload) {
			dispatch(sendCancelRequestAdjustment({ signedXml: successPayload }))
		}
	}, [successPayload])

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			if (action === 'view' && value) {
				getRequest(row.requestsAdjustmentId)
			} else if (action === 'revoke' && value) {
				setRequestAdjustmentId(row.requestsAdjustmentId)
				setIsOpenQuestionRevoke(true)
			}
			return { ...prevState, [action]: value }
		})
	}

	const fastView = (row) => {
		onToggleAction('view', true, row)
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => fastView(row)}>
				<td style={{ width: 100 }}>{row.requestsAdjustmentDate}</td>
				<td style={{ width: 100 }}>{lang === 'rus' ? row.requestType.nameRu : row.requestType.nameKz}</td>
				<td style={{ width: 100 }}>{row.bin}</td>
				<td style={{ width: 480 }}>{lang === 'rus' ? row.orgNameRu : row.orgNameKz}</td>
				<td style={{ width: 170 }}>
					<span style={{ color: colors[row.requestStatus?.code] }}>
						{lang === 'rus' ? row.requestStatus.nameRu : row.requestStatus.nameKz}
					</span>
				</td>
				<td style={{ width: 140 }} onClick={(e) => onRequestActionMenuClick(row, e, setActionMenuItems, t)}>
					<DropdownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, row)}
					/>
				</td>
			</tr>
		),
		[actionMenuItems, lang, onActionMenuChoose, t]
	)

	const tableBody = useMemo(() => dateSort(data)?.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	return (
		<>
			{isOpenQuestionRevoke ? (
				<QuestionRevoke handleProcess={handleProcess} handleCancel={fullClear}></QuestionRevoke>
			) : null}
			{(isLoading || isLoadingCancelAdjustment) && <LoadingBlocker />}
			<table className={styles.table}>
				<thead>
					<tr>
						<th style={{ width: 100 }}>{t('personal_affairs.table.request_date')}</th>
						<th style={{ width: 100 }}>{t('labor_records.requestType')}</th>
						<th style={{ width: 100 }}>{t('bin')}</th>
						<th style={{ width: 480 }}>{t('history_check.business_name')}</th>
						<th style={{ width: 170 }}>{t('personal_affairs.table.request_status')}</th>
						<th style={{ width: 140 }}>{t('labor_records.actions')}</th>
					</tr>
				</thead>
				<tbody className={`scroll`}>{tableBody}</tbody>
			</table>
			{actions.view && !isLoading && isSuccess ? <View onToggleAction={onToggleAction}></View> : null}
		</>
	)
}
