import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Title } from '@/ui'
import styles from './StructureCard.module.scss'
import { Edit, Trash } from '@app/icons'
import { StructureInformation } from '@features/staffing/CreateStaffng/structureForm/view/StructureInformation'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { getCurrentLanguage } from '@/i18next'

export const StructureCard = ({ onClose, onEdit, onRemove, rowSelectMenu, staffProject, history }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { staffType } = useSelector(contractActionsSelectors.options)
	const staffing = rowSelectMenu.level === 0
	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	const deptTypeName = showDeptType(rowSelectMenu.deptTypeCode)

	return (
		<div>
			{staffing ? null : <Title color="black">{deptTypeName}</Title>}
			{!history ? (
				<div className={styles.actions}>
					<Button
						onClick={onEdit}
						buttonClassName={styles.button}
						textColor="green"
						borderColor="green"
						transparent
						border
					>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
					<Button onClick={onRemove} buttonClassName={styles.button} type="bare">
						<Trash className={styles.iconGrey} />
						{t('remove')}
					</Button>
				</div>
			) : null}
			<div className={styles.informationSections}>
				<section>
					<StructureInformation
						structure={rowSelectMenu}
						deptTypeName={deptTypeName}
						staffProject={staffProject}
						staffing={staffing}
					></StructureInformation>
				</section>
			</div>
			<div className={styles.close}>
				<Button onClick={onClose}>{t('close')}</Button>
			</div>
		</div>
	)
}
