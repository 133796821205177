export const formFieldNames = {
	contractDate: 'contractDate',
	terminationDate: 'terminationDate',
	terminationReason: 'terminationReason',
	phone: 'phone',
	email: 'email',
	comment: 'comment',
	note: 'note',
	contractNumber: 'contractNumber',
	contractNumberIs: 'contractNumberIs',
	dpositionCodeIs: 'dpositionCodeIs',
	dpositionCode: 'dpositionCode',
	establishedPositionIs: 'establishedPositionIs',
	destablishedPostCode: 'destablishedPostCode',
	establishedPost: 'establishedPost',
	terminationDateIs: 'terminationDateIs',
	terminationReasonsIs: 'terminationReasonsIs',
	terminationReasons: 'terminationReasons',
	additionalContracts: 'additionalContracts',
	subsidiaryContractNumberIs: 'subsidiaryContractNumberIs',
	subsidiaryContractNumber: 'subsidiaryContractNumber',
	dpositionIs: 'dpositionIs',
	socialLeaves: 'socialLeaves',
}
