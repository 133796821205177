import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { contractsActions, contractsSelectors, contractsThunks } from '@/entity/contracts'
import { SecurityModal } from '@features/contracts/actions/remove/SecurityModal'
import { LoadingBlocker } from '@/components/Loader'

export const Remove = ({ onToggleAction }) => {
	const dispatch = useDispatch()
	const contract = useSelector(contractsSelectors.contract)
	const isSuccess = useSelector(contractsSelectors.contractIsSuccess)
	const removeXML = useSelector(contractsSelectors.removeXML)
	const thunkLoading = useSelector(contractsSelectors.contractIsLoading)
	const { successPayload, onSign, onClearPayload, isLoading, failedPayload } = useDigitalSign()

	useEffect(() => {
		if (successPayload) {
			dispatch(
				contractsThunks.remove({
					signedXml: successPayload,
					entityId: contract?.id,
					externalId: contract?.externalId,
				})
			)
		}
	}, [successPayload])

	const onClear = () => {
		onClearPayload()
		dispatch(contractsActions.setRemoveXML(null))
	}
	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])
	useEffect(() => {
		removeXML && onSign(removeXML)
		return onClearPayload
	}, [removeXML])

	useEffect(() => isSuccess && onCancel(), [isSuccess])

	const onCancel = useCallback(() => onToggleAction('remove', false), [onToggleAction])

	const onProcess = useCallback(() => {
		if (contract?.isLocalContract) {
			dispatch(contractsThunks.deleteLocalContract(contract?.externalId))
			onCancel()
			return
		}
		contract?.id
			? dispatch(contractsThunks.generateRemoveXML(contract?.externalId))
			: dispatch(contractsThunks.generateExternalRemoveXML(contract))
	}, [contract, dispatch, onCancel])

	return isLoading || thunkLoading ? (
		<LoadingBlocker />
	) : (
		<SecurityModal onProcess={onProcess} onCancel={onCancel} contract={contract} />
	)
}
