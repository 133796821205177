import React from 'react'
import styles from './Loader.module.scss'

export const Loader = () => {
	return (
		<svg
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={styles.loader}
		>
			<path
				d="M15.3502 0C6.87123 0 0 6.87123 0 15.3502H4.63278C4.63278 9.44231 9.43884 4.63278 15.3502 4.63278C21.2616 4.63278 26.0677 9.43884 26.0677 15.3502C26.0677 21.2616 21.2616 26.0677 15.3502 26.0677V30.7005C23.8292 30.7005 30.7005 23.8292 30.7005 15.3502C30.7005 6.87123 23.8292 0 15.3502 0Z"
				fill="url(#paint0_linear_3064_16804)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_3064_16804"
					x1="0"
					y1="15.3501"
					x2="30.7012"
					y2="15.3501"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#2FAE7B" stopOpacity="0" />
					<stop offset="1" stopColor="#2FAE7B" />
				</linearGradient>
			</defs>
		</svg>
	)
}
