import Select from 'react-select'
import { useState, useEffect } from 'react'
import SearchResult from './SearchResult.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { mainSelectors, mainThunks } from '@/app/store/main'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { getCurrentLanguage } from '@/i18next'

/**
 * Form component.
 *
 * @returns {JSX.Element} The rendered Form component.
 */
const Form = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const regionalPowerContact = useSelector(mainSelectors.regionalPowerContact)
	const [error, setError] = useState(null)
	const [searchForm, setSearchValue] = useState({
		country: 'empty',
	})

	useEffect(() => {
		dispatch(handbookThunks.getDistricts()).catch(setError)
	}, [])

	useEffect(() => {
		if (searchForm.country !== 'empty') {
			dispatch(mainThunks.searchRegionalPower(searchForm)).catch(setError)
		}
	}, [dispatch, searchForm])

	/**
	 * Retrieve the districts from the state using the handbookSelectors.
	 *
	 * @type {Array.<string>}
	 * @example
	 * const districts = useSelector(handbookSelectors.districts);
	 */
	const districts = useSelector(handbookSelectors.districts)

	/**
	 * Extracts the country information from the given districts.
	 *
	 * @param {Array} districts - An array of district objects.
	 * @returns {Array} An array of country objects with 'value' and 'label' properties.
	 *          The 'value' property contains the first two characters of the district code,
	 *          and the 'label' property contains the name of the district in the current language.
	 */
	const getCountryFromDistricts = (districts) => {
		return districts
			.map((item) => ({
				value: item.code.substring(0, 2),
				label: item[getCurrentLanguage()],
			}))
			.sort((a, b) => a.label.localeCompare(b.label))
	}

	/**
	 * Retrieves the country associated with the specified districts.
	 *
	 * @param {Array} districts - An array containing the districts of interest.
	 * @returns {string} The name of the regions associated with the given districts.
	 */
	const country = getCountryFromDistricts(districts)
	const onChangeSearchValue = (field) => (e) => {
		const value = e.value
		setSearchValue((prevState) => ({ ...prevState, [field]: value }))
	}

	/**
	 * Handles the submission of the search form for regional power.
	 *
	 * @param {Event} e - The event object representing the form submission.
	 * @returns {void}
	 */
	const onSubmitSearchRegionalPower = (e) => {
		e.preventDefault()
		dispatch(mainThunks.searchRegionalPower(searchForm)).catch(setError)
	}
	if (error) {
		// ваш код для отображения ошибки, например:
		return <div>Произошла ошибка: {error.message}</div>
	}
	return (
		<div className={regionalPowerContact ? 'form-section active' : 'form-section'}>
			<div className="container-form container-form-mob">
				<form
					className={regionalPowerContact ? 'form-wrapper active' : 'form-wrapper'}
					onSubmit={onSubmitSearchRegionalPower}
				>
					<div className="form-block">
						<p>{t('refer_to_regional_inspection')}</p>
						<div className="flex align-center justify-center scroll">
							<Select
								onChange={onChangeSearchValue('country')}
								placeholder={t('chose_area')}
								className="custom-select"
								options={country}
								styles={{
									menuList: (base) => ({
										...base,
										'::-webkit-scrollbar': {
											width: '3px',
										},
										'::-webkit-scrollbar-track': {
											boxShadow: 'inset 0 0 6px #aab8d1',
										},
										'::-webkit-scrollbar-thumb': {
											backgroundColor: '#5b6880',
										},
									}),
								}}
							/>
						</div>
					</div>
					<SearchResult data={regionalPowerContact} visible={!!regionalPowerContact} />
				</form>
			</div>
		</div>
	)
}

export default Form
