import { countriesIds } from '@app/config'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'checkRemoteWork', function () {
	return this.when('remote_work', {
		is: (remoteWork) => !remoteWork,
		then: Yup.string().required({ key: 'required' }),
	})
})

Yup.addMethod(Yup.string, 'checkCountryWork', function () {
	return this.when('country_work', {
		is: (country) => Number(country) === countriesIds.kz,
		then: Yup.string().required({ key: 'required' }),
	})
})
