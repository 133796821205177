import { formatDate } from '@/utils'

const getColumns = (t, data, langOption) => {
	const { documentType, number, beginDate, endDate, organization } = data || {}

	return [
		{ title: t('personal_affairs.document_name'), data: documentType?.[langOption] },
		{
			title: t('document_number'),
			data: number,
		},
		{
			title: t('issue_date'),
			data: formatDate(beginDate),
		},
		{
			title: t('contracts_tab.expired_date'),
			data: formatDate(endDate),
		},
		{
			title: t('issued_by'),
			data: organization?.[langOption],
		},
	]
}

export const getDocuments = (data, t, optionLang) => {
	return data.data.documents.document.map((document) => getColumns(t, document, optionLang))
}

export const getBirthPlace = (data, optionLang) => {
	const { country, district, region, city } = data.data.birthPlace || {}
	return [country?.[optionLang], district?.[optionLang], region?.[optionLang], city]
		.filter((value) => !!value)
		.join(', ')
}

export const getAddress = (data, optionLang) => {
	const { country, district, region, city, street, building, corpus, flat } = data.data.regAddress || {}
	return [country?.[optionLang], district?.[optionLang], region?.[optionLang], city, street, building, corpus, flat]
		.filter((value) => !!value)
		.join(', ')
}
