import React, { useEffect, useCallback } from 'react'
import { Modal, ModalBackButton } from '@/components/Modal'
import { opvSelectors, opvThunks } from '@/entity/opv'
import { Title, Button } from '@/ui'
import styles from './OpvModal.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from './Table/Table'

export const OpvModal = ({ onClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const opvList = useSelector(opvSelectors.opvList)
	const getData = useCallback(() => dispatch(opvThunks.getOpv()))
	useEffect(() => getData(), [dispatch])

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>{t('digitization.form.choosePeriod')}</Title>
					</div>
					<Table data={opvList.opvRecordList} close={onClose} />
					<div className={styles.footer}>
						<Button onClick={onClose}>{t('close')}</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}
