import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './DetailsOfPartiesInformation.module.scss'

export const EditInformation = ({ contract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const stausTranslate = {
		rus: contract?.dcontractStatus?.nameRu,
		kz: contract?.dcontractStatus?.nameKz,
	}
	const contactInformation = useMemo(
		() => [
			{ title: t('contract_id'), data: contract?.externalId },
			{
				title: t('esutd_reg_date'),
				data: contract?.registerDate,
			},
			{
				title: t('contract_status'),
				data: contract.dcontractStatus ? stausTranslate[language] : '',
			},
		],
		[contract, t]
	)

	return (
		<DataGridTable
			hideTitle
			hideSubTitle
			titleClass={styles.tableTitleCell}
			title={'Информация о договоре'}
			information={contactInformation}
		/>
	)
}
