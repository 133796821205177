import React, { useEffect } from 'react'
import { Sidebar } from '@/widgets'
import { Header } from '@/widgets/header'
import { BasicTemplate } from '@/ui'
import styles from './style.module.scss'
import { useToggle } from '@/shared'

export const ProfileTemplate = ({ title, children }) => {
	const [isOpen, toggleOpen] = useToggle()

	useEffect(() => {
		if (isOpen) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'auto'
	}, [isOpen])

	return (
		<BasicTemplate sidebar={<Sidebar />} header={<Header title={title} toggleOpen={toggleOpen} />} isOpenMenu={isOpen}>
			<div className={styles.container}>{children}</div>
		</BasicTemplate>
	)
}
