import { api, getFromLocalStorage } from '@/shared'
import { actions } from './actions'
import { handbookActions } from '@/entity/handbook'
import { userActions } from '@/entity'
import { message } from 'antd'
import { employerProfileThunks } from '@features/employerProfile/model'
import { companyActions } from '@/entity/company'

export const getUserByIin = (payload) => {
	return async (dispatch) => {
		// const data = await api.profile.getEmployerActivityByBIN({ bin: payload.code })
		dispatch(actions.setUserByBin('Test oked'))
		// try {
		// 	const data = await api.profile.getInformation()
		// 	let avatar
		// 	if (data.photoId) {
		// 		avatar = `${process.env.REACT_APP_API_URL}/file/download/${data.photoId}`
		// 	}
		// 	dispatch(actions.setUser({ ...data, avatar }))
		// } catch (error) {
		//
		// }
	}
}
export const getEmployerActivityByBin = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByBIN({ bin: payload.code })
			dispatch(
				handbookActions.setSingleOptions({
					name: 'oked',
					data: {
						id: data.id,
						code: data.okedCode,
						rus: data.okedRu,
						kz: data.okedKz,
						fullNameKz: data.fullNameKz,
						fullNameRu: data.fullNameRu,
					},
				})
			)
		} catch (error) {}
	}
}

export const getEmployerActivityByIINTestCol = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByIIN({ iin: payload.code })
			dispatch(
				handbookActions.setSingleOptions({
					name: 'oked',
					data: {
						id: data.id,
						code: data.okedCode,
						rus: data.okedRu,
						kz: data.okedKz,
						fullNameKz: data.fullNamePerson,
						fullNameRu: data.fullNamePerson,
					},
				})
			)
		} catch (error) {}
	}
}

export const getEmployerActivityByIIN = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByIIN({ iin: payload.code })
			if (!data.fullNameIpKz && !data.fullNameIpRu) {
				alert('Жұмыс берушінің БСН / ЖСН табылмады' + '\n' + 'БИН/ИИН работодателя не найден')
				return
			}
			dispatch(
				handbookActions.setSingleOptions({
					name: 'oked',
					data: {
						id: data.id,
						code: data.okedCode,
						rus: data.okedRu,
						kz: data.okedKz,
						fullNameKz: data.fullNameIpKz,
						fullNameRu: data.fullNameIpRu,
					},
				})
			)
		} catch (error) {}
	}
}

export const getEmployerActivityByIINAndBin = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByIINAndBin({ bin: payload.code })
			if (!data.fullNameKz && !data.fullNameRu) {
				alert('Жұмыс берушінің БСН / ЖСН табылмады' + '\n' + 'БИН/ИИН работодателя не найден')
				return
			}
			dispatch(
				handbookActions.setSingleOptions({
					name: 'oked',
					data: {
						id: data.id,
						code: data.okedCode,
						rus: data.okedRu,
						kz: data.okedKz,
						fullNameKz: data.fullNameKz,
						fullNameRu: data.fullNameRu,
					},
				})
			)
		} catch (error) {}
	}
}

export const getEmployerActivityUnionByBin = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByBIN({ bin: payload.code })
			dispatch(
				handbookActions.setSingleOptions({
					name: 'okedUnion',
					data: {
						id: data.id,
						code: data.okedCode,
						rus: data.okedRu,
						kz: data.okedKz,
						fullNameKz: data.fullNameKz,
						fullNameRu: data.fullNameRu,
					},
				})
			)
		} catch (error) {}
	}
}

export const getEmployerActivityUnionByIin = (payload) => {
	return async (dispatch) => {
		try {
			const data = await api.profile.getEmployerActivityByIIN({ iin: payload.code })
			dispatch(
				handbookActions.setSingleOptions({
					name: 'okedUnionIin',
					data: {
						fullNamePerson: data.fullNamePerson,
					},
				})
			)
		} catch (error) {}
	}
}
export const createEditUser = (payload) => {
	return async (dispatch) => {
		try {
			const response = await api.profile.createEditProfile(payload)
			dispatch(
				userActions.setUser({ ...response, ...response.personDetails, personDetailsId: response?.personDetails?.id })
			)
			message.success('Операция успешно выполнена')
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		}
	}
}

export const verification = (payload, flEmployer) => {
	//TODO ID и CODE по умолчанию, используется в случае если company.id равен null для ФЛ
	const DEFAULT_ID = 123456789
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.profile.personVerification(payload, flEmployer)
			dispatch(
				userActions.setUser({ ...response, ...response.personDetails, personDetailsId: response?.personDetails?.id })
			)
			const { currentBin, companies, currentFlEmployer } = response || {}
			if (currentBin && currentFlEmployer) {
				const parsedCompanies = companies.map((company) => ({
					id: company.id !== null ? company.id : DEFAULT_ID,
					rus: company.companyName,
					kz: company.companyName,
					code: String(company.id !== null ? company.id : DEFAULT_ID),
				}))
				dispatch(userActions.setEmployerCompanies(parsedCompanies))
				dispatch(
					companyActions.setCompanies(
						companies.map((company) => ({
							...company,
							id: company.id !== null ? company.id : DEFAULT_ID,
							code: String(company.id !== null ? company.id : DEFAULT_ID),
						}))
					)
				)
				const activeCompany = getFromLocalStorage('activeCompany')
				dispatch(employerProfileThunks.getCompany({ id: currentFlEmployer ? DEFAULT_ID : activeCompany?.id || null }))
			}
			message.success('Операция успешно выполнена')
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
export const removeOked = () => {
	return async (dispatch) => {
		dispatch(handbookActions.setSingleOptions({ name: 'oked', data: null }))
	}
}

export const removeOkedUnion = () => {
	return async (dispatch) => {
		dispatch(handbookActions.setSingleOptions({ name: 'okedUnion', data: null }))
	}
}
export const removeOkedUnionIin = () => {
	return async (dispatch) => {
		dispatch(handbookActions.setSingleOptions({ name: 'okedUnionIin', data: null }))
	}
}

export const verificationSms = (payload, user) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.profile.save(payload)
			dispatch(userActions.setUser({ ...user, ...response, personDetailsId: response?.id }))
			message.success('Операция успешно выполнена')
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
