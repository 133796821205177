const crypto = require('crypto')
export function getSha256(file) {
	return new Promise(function (resolve) {
		let reader = new FileReader()
		reader.onload = function () {
			let bytes = Array.from(new Uint8Array(this.result))
			const sha256Hash = crypto.createHash('sha256').update(bytes).digest('hex')
			resolve(sha256Hash)
		}
		reader.onerror = function (error) {
			resolve(null)
		}
		reader.readAsArrayBuffer(file)
	})
}
