import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './TableSign.module.scss'
import { headCells } from './table.headings'
import { Dots, Success } from '@app/icons'

const TableSign = ({ currentEcd }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const statusUnion = {
		dcontractStatus: {
			nameRu: currentEcd.signUnionDate ? 'Подписан' : 'Ожидает подписи',
			nameKz: currentEcd.signUnionDate ? 'Қол қойылған' : 'Қолын күтуде',
		},
	}

	const statusEmployer = {
		dcontractStatus: {
			nameRu: currentEcd.signEmployerDate ? 'Подписан' : 'Ожидает подписи',
			nameKz: currentEcd.signEmployerDate ? 'Қол қойылған' : 'Қолын күтуде',
		},
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<>
			<div className={styles.status__title}>{t('col_contract.status_sign')}</div>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>
					<tr>
						<td>{t('col_contract.trade_union_representative')}</td>
						<td>{currentEcd.unionSignIin || '-'}</td>
						<td>{currentEcd.unionSignFullName || '-'}</td>
						<td>{currentEcd.signUnionDate || '-'}</td>
						<td>
							{currentEcd.signUnionDate ? (
								<Success className={styles.iconSuccess} />
							) : (
								<Dots className={styles.iconClock} />
							)}
							<span className={currentEcd.signUnionDate ? styles.textSuccess : styles.textClock}>
								{lang === 'rus' ? statusUnion.dcontractStatus.nameRu : statusUnion.dcontractStatus.nameKz}
							</span>
						</td>
					</tr>
					<tr>
						<td>{t('col_contract.employer_representative')}</td>
						<td>{currentEcd.employerSignIin || '-'}</td>
						<td>{currentEcd.employerSignFullName || '-'}</td>
						<td>{currentEcd.signEmployerDate || '-'}</td>
						<td>
							{currentEcd.signEmployerDate ? (
								<Success className={styles.iconSuccess} />
							) : (
								<Dots className={styles.iconClock} />
							)}
							<span className={currentEcd.signEmployerDate ? styles.textSuccess : styles.textClock}>
								{lang === 'rus' ? statusEmployer.dcontractStatus.nameRu : statusEmployer.dcontractStatus.nameKz}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default TableSign
