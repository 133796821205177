import { ModalTemplate, Title } from '@/ui'
import styles from './style.module.scss'
import { useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { LoadingBlocker } from '@/components/Loader'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { IndustryAgreementForm } from '@/entity/industryAgreement/ui/form/IndustryAgreementForm'
import { industryAgreementSchema } from '@/entity'

export const IndustryAgreement = ({ initialValues, onCancel, onSubmit, isEdit }) => {
	const { t } = useTranslation()
	const industryAgreement = useSelector(handbookSelectors.industryAgreement)
	const extraGuaranteesCollectiveContract = useSelector(handbookSelectors.extraGuaranteesCollectiveContract)
	const [filesMiddleWare, setFilesMiddleWare] = useState([])
	const { files } = initialValues

	const onSubmitMiddleWare = (data) => {
		let sendFileArray = []
		if (!isEdit) {
			delete data.updatesList
		} else {
			// Удаляем все элементы кроме первого при редактировании отраслевого соглашения
			data.updatesList = [data.updatesList[0]]
			// Удаляю лишние элементы при редактировании отраслевого соглашения перед отправкой на бэк
			delete data.updatesList[0].bin
			delete data.updatesList[0].externalId
			delete data.updatesList[0].files
		}
		// Создаем копию объекта и удаляем все поля, равные ''
		const cleanedData = Object.keys(data).reduce((acc, key) => {
			if (data[key] !== '') {
				acc[key] = data[key]
			}
			return acc
		}, {})

		// Удаление элементов из employerAssociationNames, если выполняются условия
		if (data.employerAssociationNames) {
			data.employerAssociationNames = data.employerAssociationNames.filter(
				el => el.employerAssociationNameKz || el.employerAssociationNameRu
			)
			if (!data.employerAssociationNames.length) {
				delete data.employerAssociationNames
			}
		}

		// Подготовка массива файлов к отправке
		if (filesMiddleWare.length > 0) {
			sendFileArray = filesMiddleWare.map((file) => ({
				id: file.id
			}))
		}

		// Включение массива файлов в объект данных
		let preparedData = {
			...cleanedData,
			files: sendFileArray || [],
			employerAssociationNames: data.employerAssociationNames
		}

		// Вызов onSubmit с подготовленными данными
		onSubmit(preparedData)
	}

	const attachFile = (values) => {
		setFilesMiddleWare(values)
	}

	return (
		<ModalTemplate onClose={onCancel}>
			<div className={`scroll ${styles.wrapper}`}>
				{!!industryAgreement ? (
					<>
						<Title color={'black'}>
							{isEdit ? t('industryAgreement.titleEdit') : t('industryAgreement.titleCreate')}
						</Title>
						<Formik
							validationSchema={industryAgreementSchema}
							initialValues={initialValues}
							onSubmit={onSubmitMiddleWare}
						>
							<IndustryAgreementForm
								industryAgreement={industryAgreement}
								extraGuaranteesCollectiveContract={extraGuaranteesCollectiveContract}
								attachFile={attachFile}
								files={files}
								onCancel={onCancel}
								isEdit={isEdit}
							/>
						</Formik>
					</>
				) : (
					<LoadingBlocker />
				)}
			</div>
		</ModalTemplate>
	)
}