export const headCells = [
	{
		id: 'date',
		numeric: false,
		label: 'transfer_request_date',
	},
	{
		id: 'event',
		numeric: true,
		label: 'payment.event',
	},
	{
		id: 'iin',
		numeric: true,
		label: 'yin',
	},
	{
		id: 'counterparty',
		numeric: false,
		label: 'contracts_tab.contractor',
	},
	{
		id: 'status',
		numeric: false,
		label: 'transfer_status_request',
	},
]
