import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import WorkerWelfareMainTab from '@pages/PersonalAffairs/Tabs/WorkerWelfareMainTab/WorkerWelfareMainTab'

export const EmployerPassport = () => {
	const { t } = useTranslation()
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_workers_portrait')}>
				<WorkerWelfareMainTab />
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
