import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './EmployeeInformation.module.scss'
import { useSelector } from 'react-redux'
import { staffingSelectors } from '@/entity'
import { formatNumber } from '@/utils'

export const EmployeeInformation = ({ position }) => {
	const { t } = useTranslation()

	const wplaceEmployeeList = useSelector(staffingSelectors.wplaceEmployeeList)

	const getSubstitutionNameForId = (substitutionExternalId) => {
		const substitution = wplaceEmployeeList.find((item) => item.externalId === substitutionExternalId)
		return {
			substitutionName: substitution ? substitution.fullName : '',
		}
	}

	const employeeInformation = () => {
		return [
			{ title: t('contracts_tab.employee_fio'), data: position.fullName || '' },
			{
				title: t('staff.substitute_fio'),
				data: getSubstitutionNameForId(position.substitutionExternalId).substitutionName || '',
			},
			{ title: t('positions_combination'), data: position.isCombining ? t('yes') : t('no') || '' },
			{ title: t('workplace.tariff_rate'), data: position.tariffRate || '' },
			{ title: t('staffing_table.sum'), data: formatNumber(position.factSalary || '') || '' },
			{
				title: t('staffing_table.supplement_surcharge'),
				data: formatNumber(position.salarySupplement || '') || '',
			},
			{ title: t('contracts_tab.start_contract_date'), data: position.beginDate || '' },
			{ title: t('contracts_tab.end_contract_date'), data: position.endDate || '' },
			{ title: t('staffing_table.cause'), data: position.reasonForAbsence || '' },
		]
	}
	return (
		<DataGridTable
			hideSubTitle
			titleClass={styles.tableTitleCell}
			title={t('workplace.information_prescribing')}
			information={employeeInformation()}
		/>
	)
}
