import React, { useEffect } from 'react'
import { Field, Button } from '@/ui'
import { Form, Field as FormikField, useFormikContext } from 'formik'
import styles from './TransferForm.module.scss'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { branchSelectors, branchThunks } from '@/entity/branch'
import { useDispatch, useSelector } from 'react-redux'
import { ContractFiled } from './fields'
import { getCurrentLanguage } from '@/i18next'
import { removeDuplicates } from './utils/removeDuplicates'

const TransferForm = ({ contractInitial, setName, addNewTransfer, selectContract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const lang = currentLanguage === 'rus' ? 'nameRu' : 'nameKz'
	const dispatch = useDispatch()
	const { values, setFieldValue } = useFormikContext()
	const isSuccess = useSelector(branchSelectors.isSuccess)
	const branchName = useSelector(branchSelectors.branchName)

	useEffect(() => {
		if (isSuccess) {
			setFieldValue('branchNameRu', branchName[lang])
			setFieldValue('branchNameKz', branchName[lang])
			addNewTransfer()
			setName(branchName[lang])
		}
	}, [isSuccess])

	useEffect(() => {
		if (selectContract) {
			let parsedContract = values.contracts.concat(selectContract).filter((item) => item.externalId !== '')
			setFieldValue('contracts', removeDuplicates(parsedContract))
		}
	}, [selectContract])
	const handleSearchBin = () => {
		if (values.bin.length === 12) {
			dispatch(branchThunks.getDataByBin(values?.bin))
		}
	}

	return (
		<Form>
			<label className={styles.field__label}>{t('transfer_request_date')}</label>
			<Grid container spacing={4} alignItems="flex-end" classes={{ root: styles.container }}>
				<Grid item xs={3}>
					<FormikField name="date">
						{({ field }) => <Field disabled placeholder={t('default_date_value')} {...field} />}
					</FormikField>
				</Grid>
			</Grid>

			<Grid container spacing={4} alignItems="flex-end" classes={{ root: styles.container }}>
				<Grid item xs={4}>
					<Box display="flex" alignItems="flex-end" justifyContent="space-between">
						<FormikField name="bin">
							{({ field, meta: { error, touched } }) => (
								<Field
									{...field}
									label={t('transfer_request_bin')}
									placeholder={t('enter_12_digits')}
									error={touched && error}
								/>
							)}
						</FormikField>
						<div className={styles.button}>
							<Button disabled={!(values.bin?.length === 12)} onClick={handleSearchBin}>
								{t('find')}
							</Button>
						</div>
					</Box>
				</Grid>

				<Grid item xs={7}>
					<FormikField name={language === 'rus' ? 'branchNameRu' : 'branchNameKz'}>
						{({ field, meta: { error, touched } }) => (
							<Field
								disabled
								{...field}
								label={t('transfer_branch_name')}
								placeholder={t('auto_filled')}
								error={touched && error}
							/>
						)}
					</FormikField>
				</Grid>
			</Grid>

			<div className={styles.title__info}>{t('transfer_employeers_data')}</div>
			<ContractFiled contractInitial={contractInitial}></ContractFiled>
			<Box className={styles.submit__button} display="flex" justifyContent="flex-end">
				<Button type="submit">{t('send_request')}</Button>
			</Box>
		</Form>
	)
}
export default TransferForm
