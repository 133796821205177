import { format, fromUnixTime } from 'date-fns'
import { formates } from '@app/config'

export const parseECPKeyInformation = (ecp) => {
	function getSubstring(text, string) {
		if (text.indexOf(string) >= 0) {
			const start = text.indexOf(string) + string.length
			let finish = 0
			if (text.indexOf(',', start) < 0) {
				finish = text.length
			} else {
				finish = text.indexOf(',', start)
			}
			return text.substring(start, finish)
		}
		return null
	}
	const subjectDn = ecp['subjectDn']
	const serialNumber = ecp['serialNumber']
	let iin = null
	let fullName

	const middlename = getSubstring(subjectDn, 'G=')
	const bin = getSubstring(subjectDn, 'BIN')
	if (bin) {
		fullName = getSubstring(subjectDn, 'O=')
	} else {
		iin = getSubstring(subjectDn, 'IIN')
		if (middlename === null) {
			fullName = getSubstring(subjectDn, 'CN=')
		} else {
			fullName = getSubstring(subjectDn, 'CN=') + ' ' + middlename
		}
	}

	const surname = getSubstring(subjectDn, 'SURNAME=')
	const subjectCn = ecp['subjectCn']
	let firstname = ''
	if (subjectCn) {
		let cns = subjectCn.split(' ')
		firstname = cns[cns.length - 1]
	}
	const dateFrom = format(fromUnixTime(Number(ecp.certNotBefore) / 1000), formates.date)
	const dateTo = format(fromUnixTime(Number(ecp.certNotAfter) / 1000), formates.date)
	return { iin, bin, serialNumber, dateFrom, dateTo, fullName, surname, middlename, firstname }
}
