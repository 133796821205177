import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './ErrorInfoModal.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const ErrorInfoModal = ({ errors, onClose }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	return (
		<ModalTemplate onClose={onClose} hideBackButton={true}>
			<div className={`${styles.wrapper} scroll`}>
				{errors && errors?.length > 0 ? (
					errors.map((error) => {
						return (
							<div className={styles.content}>
								<Title color={'darkBlue'}>{t('staff.error_number') + error.code}</Title>
								{lang === 'rus' ? error.nameRu : error.nameKz}
							</div>
						)
					})
				) : (
					<div className={styles.content}>
						<Title color={'darkBlue'}>{t('staff.error_status')}</Title>
						{t('staff.error_struct_or_wp')}
					</div>
				)}
				<div className={styles.buttonStyle}>
					<Button type="submit" onClick={onClose}>
						{t('close')}
					</Button>
				</div>
			</div>
		</ModalTemplate>
	)
}
