import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg.attrs({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 16 17",
    fill: "none",
})``;

const Svg = styled(Icon)`
    width: ${props => props.width ? props.width : 16}px;
    height: ${props => props.height ? props.height : 17}px;
`;

const SuccessfulSaved = ({ width, height }) => {
    return (
			<Svg width={width} height={height}>
				<path
					d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 15C4.134 15 1 11.866 1 8C1 4.134 4.134 1 8 1C11.866 1 15 4.134 15 8C15 11.866 11.866 15 8 15Z"
					fill="#AAB8D1"
				/>
				<path
					d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
					fill="#AAB8D1"
				/>
				<path
					d="M11.5 9C12.0523 9 12.5 8.55228 12.5 8C12.5 7.44772 12.0523 7 11.5 7C10.9477 7 10.5 7.44772 10.5 8C10.5 8.55228 10.9477 9 11.5 9Z"
					fill="#AAB8D1"
				/>
				<path
					d="M4.5 9C5.05228 9 5.5 8.55228 5.5 8C5.5 7.44772 5.05228 7 4.5 7C3.94772 7 3.5 7.44772 3.5 8C3.5 8.55228 3.94772 9 4.5 9Z"
					fill="#AAB8D1"
				/>
			</Svg>
		)
};

export default SuccessfulSaved;