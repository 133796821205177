import { getLatestContract } from './getLatestContract'

export const updateAnnuityStatus = (additionalContracts, dworkingConditionsCode, annuityDate, setAnnuity) => {
	if (annuityDate) {
		if (additionalContracts?.length > 0) {
			let arrayAdditionalContracts = additionalContracts.filter(
				({ dcontractState, dworkingConditionsCode }) => dcontractState.code === '1' && dworkingConditionsCode !== null
			)
			if (arrayAdditionalContracts.length > 0) {
				let latestContract = getLatestContract(arrayAdditionalContracts)
				if (isHarmfulWorkingConditions(latestContract.dworkingConditionsCode)) {
					setAnnuity(true)
				}
			} else if (isHarmfulWorkingConditions(dworkingConditionsCode)) {
				setAnnuity(true)
			}
		} else if (isHarmfulWorkingConditions(dworkingConditionsCode)) {
			setAnnuity(true)
		}
	}
}

export const isHarmfulWorkingConditions = (dworkingConditionsCode) => {
	return ['1', '2'].includes(dworkingConditionsCode)
}
