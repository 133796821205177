import styles from './AddMembersForm.module.scss'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Field, Button } from '@/ui'
import React from 'react'
import { api } from '@/shared'
import { useTranslation } from 'react-i18next'
import { Add, Trash } from '@app/icons'
import { message } from 'antd'

export const AddMembersForm = ({ onCancel }) => {
	const { t } = useTranslation()
	const { values, setFieldValue, isValid } = useFormikContext()

	const handleSearchMember = async (iin, memberIndex) => {
		if (iin.length < 12) {
			return
		}
		try {
			const { fullNamePerson } = await api.profile.getEmployerActivityByIIN({ iin: iin })
			setFieldValue(`members[${memberIndex}].fio`, fullNamePerson)
		} catch (error) {
			if (error.response && error.response.status === 500) {
				message.error('Произошла серверная ошибка при поиске Ф.И.О. Повторите попытку позже.')
			} else {
				message.error('Ошибка добавления: ' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			}
		}
	}
	const addStructMembers = () => {
		setFieldValue('members', [
			...values.members,
			{
				dateIn: '',
				fio: '',
				iin: '',
			},
		])
	}
	const removeMembers = (index) => {
		const updatedUnits = [...values.members]
		updatedUnits.splice(index, 1)
		setFieldValue('members', updatedUnits)
	}

	return (
		<FormikForm className={styles.form}>
			<div>
				{values.members.map((member, memberIndex) => (
					<div className={styles.fields__wrapper} key={memberIndex}>
						<div className={styles.fieldAndButton}>
							<FormikField name={`members.${memberIndex}.iin`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										placeholder={t('enter_count_digitals', { count: 12 })}
										error={touched && (error && error?.key) ? t(error.key) : ''}
										wrapperClassName={styles.field__input}
										label={t('iin')}
										isRequired
										maxLength={12}
										pattern="[0-9]{12}"
										onInput={(e) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, '')
										}}
									/>
								)}
							</FormikField>
							<Button onClick={() => handleSearchMember(member.iin, memberIndex)}>{t('find')}</Button>
							<FormikField name={`members.${memberIndex}.fio`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										disabled
										wrapperClassName={styles.field__input}
										label={t('employee_initials')}
										isRequired
										placeholder={t('filled_automate')}
										{...field}
										error={touched && (error && error?.key) ? t(error.key) : ''}
									/>
								)}
							</FormikField>
							<div className={styles.calendarButton}>
								<FormikField name={`members.${memberIndex}.dateIn`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType="datePicker"
											label={t('profUnions.date_in')}
											placeholder={t('default_date_value')}
											minDate={new Date(1900, 0, 1)}
											isRequired
											{...field}
											error={touched && (error && error?.key) ? t(error.key) : ''}
										/>
									)}
								</FormikField>
							</div>
						</div>
						<div className={styles.removeButtonWrap} >
							<Button
								transparent
								onClick={() => removeMembers(memberIndex)}
								buttonClassName={styles.buttonGrey}
								disabled={false}
							>
								<Trash className={styles.iconGrey} />
								{t('remove')}
							</Button>
						</div>
					</div>
				))}
				<Button transparent onClick={addStructMembers} buttonClassName={styles.button}>
					<Add className={styles.iconGreen} />
					{t('profUnions.add_prof_union_member')}
				</Button>
			</div>
			<div className={styles.actions}>
				<Button type="bare" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<Button disabled={!isValid} type="submit">
					{t('add')}
				</Button>
			</div>
		</FormikForm>
	)
}
