export const isLoading = (state) => state.verification.isLoading
export const isSuccess = (state) => state.verification.isSuccess
export const phone = (state) => state.verification.phone
export const qr = (state) => state.verification.qr
export const qr_key = (state) => state.verification.qr_key

export const qr_status = (state) => state.verification.qr_status
export const isError = (state) => state.verification.error
export const isSuccessSend = (state) => state.verification.isSuccessSend
export const isErrorSend = (state) => state.verification.errorSend
