import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingBlocker } from '@/components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form as FormikForm, Field as FormikField, useFormikContext } from 'formik'
import { SMSCodeValidationScheme } from '@/shared'
import styles from '../VerificationSms.module.scss'
import { Field, Button } from '@/ui'
import { userSelectors, userThunks } from '@/entity'

export const CodeVerification = ({ iin }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isLoading = useSelector(userSelectors.isLoading)
	const user = useSelector(userSelectors.user)
	const save = (code) => {
		dispatch(userThunks.verificationSms({ confirmCode: code, iin: iin }, user))
	}
	return (
		<div>
			{isLoading ? <LoadingBlocker /> : null}
			<Formik initialValues={{ code: '' }} validationSchema={SMSCodeValidationScheme}>
				{({ values, isValid }) => {
					return (
						<FormikForm>
							<div className={'flex'}>
								<div className={styles.fields}>
									<FormikField name={'code'}>
										{({ field, meta: { touched, error } }) => (
											<Field
												hideNumberArrows
												label={t('code_bmg')}
												placeholder={t('code_bmg_placeholder', { count: 6 })}
												error={touched && error}
												{...field}
												maxLength={6}
												pattern="[0-9]{6}"
											/>
										)}
									</FormikField>
								</div>
								<div className={styles.fieldAndButton}>
									<Button disabled={!isValid} type="button" onClick={() => save(values.code)}>
										{t('send')}
									</Button>
								</div>
							</div>
						</FormikForm>
					)
				}}
			</Formik>{' '}
		</div>
	)
}
