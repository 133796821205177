import React from 'react'
import styles from './infoWarning.module.scss'
import { Info } from '@app/icons'
import { useTranslation } from 'react-i18next'

export const InfoWarning = () => {
	const { t } = useTranslation()
	return (
		<div className={styles.information}>
			<Info className={styles.hintIcon} />
			<div className={styles.tableText}>{t('staff.status_get_new_version_error')}</div>
		</div>
	)
}
