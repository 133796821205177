export const defaultPassport = {
	addressNameKz: '',
	addressNameRu: '',
	averageRating: 0,
	bin: '',
	citizenCount: 0,
	email: '',
	employeeCount: 0,
	foreignEmployeeCount: 0,
	inEnterpriseSMZ: 0,
	leaderName: '',
	medianSalaryLast12Month: 0,
	medianSalaryLast24Month: 0,
	nameKz: '',
	nameRu: '',
	organizationFormKz: '',
	organizationFormRu: '',
	organizationSize: '',
	ownershipNameKz: '',
	ownershipNameRu: '',
	phone: '',
	regActionDate: '',
	regionKz: '',
	regionRu: '',
	riskType: '',
	salaryByRegionAndOked: 0,
	salaryLast12Month: 0,
	salaryLast24Month: 0,
	smzInForeignCitizens: 0,
	smzInRKCitizens: 0,
}

export const defaultFullInfo = {
	activeContractCount: 0,
	administrativeMatter: 0,
	asp: 0,
	bin: 'string',
	categoryAcks: 0,
	categoryBcks: 0,
	categoryCcks: 0,
	categoryDcks: 0,
	categoryEcks: 0,
	checkTypeOtbScheduled: 0,
	checkTypeOtbUnScheduled: 0,
	cntMnogodet: 0,
	contactInfo: 'string',
	countActive: 0,
	countDied: 0,
	countHeavy: 0,
	countLight: 0,
	countNotActive: 0,
	duchet: 0,
	employeesIncomeBelowMonthlyMinimum: 0,
	employeesIncomeBelowPoverty: 0,
	esutdPrinyato: 0,
	esutdSeason: 0,
	esutdUvol: 0,
	esutdUvolBad: 0,
	finalTip: 'string',
	indexKeitca: 0,
	insuredEmployeeCount: 0,
	kol3Mes: 0,
	kolLsi: 0,
	leaderName: 'string',
	med12Mes: 0,
	med24Mes: 0,
	mestnyi: 0,
	nameOked1: 'string',
	nameOked2: 'string',
	notInsuredEmployeeCount: 0,
	notMestnyi: 0,
	oppv: 0,
	orgName: 'string',
	percentageCategoryAcks: 0,
	percentageCategoryBcks: 0,
	percentageCategoryCcks: 0,
	percentageCategoryDcks: 0,
	percentageCategoryEcks: 0,
	prescription: 0,
	quitedContractCount: 0,
	riskCntRuk: 'string',
	riskDe: 'string',
	riskErsop: 'string',
	riskFinalTipFinance: 'string',
	riskFinalTipSoc: 'string',
	riskFinalTipTrud: 'string',
	riskGradoob: 'string',
	riskKratnostSmz: 'string',
	riskKredit90: 'string',
	riskMnogodet: 'string',
	riskMolodezh: 'string',
	riskNotMestnyi: 'string',
	riskOppv: 'string',
	riskSmz: 'string',
	riskSmzRuk: 'string',
	riskStat: 'string',
	riskStatInostr: 'string',
	riskTek: 'string',
	riskTempRost: 'string',
	riskTravma: 'string',
	riskUvolOtr: 'string',
	smz12Mes: 0,
	smz24Mes: 0,
	violations: [
		{
			descriptionKz: 'string',
			descriptionRu: 'string',
			externalId: 0,
			violationTypeKz: 'string',
			violationTypeRu: 'string',
			workersCount: 0,
		},
	],
	workedToday: 0,
	workersWithLoan: 0,
	workersWithLoansOverdue90Days: 0,
}

export const defaultObjects = [
	{
		addressKz: '',
		addressRu: '',
		bin: '',
		nameKz: '',
		nameRu: '',
		objectId: 0,
		status: '',
	},
]

export const defaultInsurance = {
	activeContractCount: 0,
	insuredEmployeeCount: 0,
	notInsuredEmployeeCount: 0,
	osrnsContracts: [],
	quitedContractCount: 0,
	totalEmployeesCount: 0,
	totalInsuranceContractCount: 0,
}

export const violationsDefault = {
	allowedIrs: 0,
	divergenceOPVandTd: 0,
	divergenceTdAndOPV: 0,
	laborMigrants: 0,
	totalEmploymentContacts: 0,
	totalViolations: 0,
	totalOPV: 0,
	violations: [
		{
			dateOfDetection: '2024-09-01',
			descriptionKz: 'string',
			descriptionRu: 'string',
			sourceKz: 'string',
			sourceRu: 'string',
			violationId: 0,
			violationTypeKz: 'string',
			violationTypeRu: 'string',
			workersCount: 0,
		},
	],
}

const mockedInsurenceContract = {
	branchBinList: 'string',
	contractBeginDate: '2024-08-15',
	contractDate: '2024-08-15',
	contractEndDate: '2024-08-15',
	contractNumber: '235',
	contractVersionDTOList: [
		{
			calculatedAmount: 0,
			countEmployees: 0,
			insuranceVersionBeginDate: '2024-08-15',
			insuredEmployers: 0,
			premium: 0,
			versionNumber: 0,
		},
	],
	insuredEmployees: 0,
	statusKz: 'string',
	statusRu: 'действующий',
	systemDelimiterBin: 'string',
	systemDelimiterKz: 'string',
	systemDelimiterRu: 'Наименование страховой компании',
}

export const mockedInsurance = {
	activeContractCount: 12,
	insuredEmployeeCount: 12,
	notInsuredEmployeeCount: 12,
	osrnsContracts: [
		{ ...mockedInsurenceContract, statusRu: 'не действующий' },
		...new Array(38).fill(mockedInsurenceContract),
	],
	quitedContractCount: 12,
	totalEmployeesCount: 12,
	totalInsuranceContractCount: 39,
}
