import { Sign as SignIcon, Exclude as ExcludeIcon, Decline as DeclineIcon } from '@app/images'

export default function onActionMenuClickMembers(row, e, setActionMenuItems, t, isApproved) {
	const status = row.status
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = []
	if (status === 'APPROVED' && isApproved) {
		actions = [...actions, { icon: ExcludeIcon, title: t('actions.exclude'), action: 'exclude' }]
	}
	if (status === 'DRAFT' && isApproved && row.initPerson) {
		actions = [
			...actions,
			{ icon: SignIcon, title: t('actions.approve'), action: 'approve' },
			{ icon: DeclineIcon, title: t('actions.decline'), action: 'decline' },
		]
	}
	setActionMenuItems(actions)
}
