import React from 'react'
import { Title } from '@/ui'
import styles from './InformationSection.module.scss'

export const InformationSection = ({ title, items, lang, itemKey }) => (
	<section>
		<Title>{title}</Title>
		<div>
			{items.map((item, index) => (
				<div key={index} className={styles.association}>
					{index + 1 + ') '}
					{lang === 'rus' ? item[itemKey + 'Ru'] : item[itemKey + 'Kz']}
				</div>
			))}
		</div>
	</section>
)
