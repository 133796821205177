import { request } from '@/shared/api/request'

export const getAll = (params) => {
	return request({ url: 'safety/accident/all', method: 'GET', params: params, withCredentials: true })
}

export const saveReport = (data) => {
	return request({ url: 'safety/accident/xml', method: 'POST', data: data, withCredentials: true })
}

export const sendReport = (data) => {
	return request({ url: 'safety/accident/send', method: 'POST', data: data, withCredentials: true })
}
