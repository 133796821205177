import { getLatestContract } from '@/utils/getLatestContract'

export const getConsentStatus = (
	additionalContracts,
	dworkingConditionsCode,
	employeeRequest,
	annuityDate,
	setAnnuity
) => {
	if (annuityDate) {
		if (additionalContracts?.length > 0) {
			let arrayAdditionalContracts = additionalContracts.filter(
				({ dcontractState, dworkingConditionsCode, employeeRequest }) =>
					dcontractState.code === '1' && dworkingConditionsCode !== null && employeeRequest
			)
			if (arrayAdditionalContracts.length > 0) {
				let latestContract = getLatestContract(arrayAdditionalContracts)
				if (latestContract.employeeRequest) {
					return setAnnuity(true)
				}
			} else if (employeeRequest) {
				return setAnnuity(true)
			}
		} else if (employeeRequest) {
			return setAnnuity(true)
		}
	}
	return setAnnuity(false)
}
