import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

export const Header = ({ tabs, activeTab, onChange }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.tabs} style={{ gridTemplateColumns: `repeat(${tabs.length}, max-content)` }}>
			{tabs.map((tab) => {
				return typeof tab !== 'object' ? (
					<div
						key={tab}
						className={`${styles.tab} ${activeTab === tab ? styles.active : ''}`}
						onClick={() => onChange(tab)}
					>
						{t(tab)}
					</div>
				) : (
					<a key={tab.label} href={tab?.url} className={styles.tab} onClick={() => onChange(tab.label)}>
						{t(tab.label)}
					</a>
				)
			})}
		</div>
	)
}
