import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import User from '@/assets/home/User.svg'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

export const HeaderButton = ({ lang }) => {
	const { t } = useTranslation()
	const user = useSelector(userSelectors.user)
	const [urlAuth, setUrlAuth] = useState()

	useMemo(
		() =>
			lang === 'rus'
				? setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE)
				: setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE),
		[lang]
	)

	return (
		<div className="greenBtn mob">
			{!user ? (
				<a href={urlAuth}>
					{t('entrance')}/{t('registration')}
				</a>
			) : (
				<Link to={'/role'}>{t('entrance')}</Link>
			)}{' '}
			{!user ? (
				<a href={urlAuth}>
					<img src={User} className="user" />
				</a>
			) : (
				<Link to={'/role'}>
					<img src={User} className="user" />
				</Link>
			)}
		</div>
	)
}

export default HeaderButton
