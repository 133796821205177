import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	oppv: (payload) => ({
		type: types.OPPV,
		payload,
	}),
	error: (payload) => ({
		type: types.ERROR,
		payload,
	}),
	reset: () => ({
		type: types.RESET,
	}),
}
