import styles from './Documents.module.scss'
import { Title, Button, SelectSearch } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field as FormikField } from 'formik'
import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'
import { useSelector } from 'react-redux'
import { pensionSelectors } from '@/entity'
import { ConfirmDocument } from '@/components/ConfirmDocument/ConfirmDocument'
import { ModalWS, ModalWSBackButton } from '@/components/ModalWS'

export const Documents = ({ addItem, docList, onClose }) => {
	const fileList = docList.map((a) => a.file)
	const { t } = useTranslation()
	const [documentList, setDocList] = useState([])
	const pensionDocType = useSelector(pensionSelectors.pensionDocType)
	const getDocument = (docList) => {
		setDocList(docList)
	}

	return (
		<ModalWS show={true} windowClassName={styles.modal}>
			<ModalWSBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<div className={styles.header}>
					<Title className={styles.title}>{t('pension.payments.form.doc_form.add_document')}</Title>
				</div>
				<Formik
					initialValues={{ type: '' }}
					enableReinitialize
					onSubmit={(data) => {
						const files = documentList.map((document) => {
							const docType = pensionDocType.find((a) => a.code === data.type)
							return {
								deleted: false,
								docId: document.id,
								docType: { code: docType.code, nameRu: docType.rus, nameKz: docType.kz },
								id: '',
								sent: false,
								file: document,
								docTypeCode: data.type,
							}
						})
						addItem(files)
					}}
				>
					{({ values }) => (
						<Form>
							<FormikField name="type">
								{({ field, meta: { touched, error } }) => (
									<Box className={styles.mb20}>
										<Tooltip
											placement="right"
											color="white"
											overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
											title={t('pension.payments.hints.document_type')}
										>
											<label>
												{t('pension.payments.form.doc_form.document_type')}
												<Info className={styles.hintIcon} />
											</label>
										</Tooltip>
										<SelectSearch
											options={pensionDocType}
											fieldtype="select"
											{...field}
											placeholder={t('pension.payments.form.doc_form.choose_type_experience')}
											error={touched && error ? t(error) : ''}
										/>
									</Box>
								)}
							</FormikField>
							<ConfirmDocument getDocument={getDocument} fileList={fileList}></ConfirmDocument>
							<div className={styles.footer}>
								<Button buttonClassName={styles.button} type="bare" onClick={onClose}>
									{t('cancel')}
								</Button>
								<Button type={'submit'} disabled={!(documentList.length > 0 && values.type)}>
									{t('save')}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</ModalWS>
	)
}
