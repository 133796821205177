export const headCells = [
	{
		id: 'date',
		numeric: true,
		disablePadding: false,
		label: 'digitization.table.headers.date',
	},
	{
		id: 'moving',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.moving',
	},
	{
		id: 'bin',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.bin',
	},
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.name',
	},
	{
		id: 'position',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.position',
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: 'digitization.table.headers.actions',
	}
]