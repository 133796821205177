import styles from './Table.module.scss'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { headCells } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { opvSelectors } from '@/entity/opv'
import { getCurrentLanguage } from '@/i18next'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { opvThunks } from '@/entity/opv'
export const Table = ({ data, close }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isLoading = useSelector(opvSelectors.isLoading)
	const isSuccess = useSelector(opvSelectors.isSuccess)
	const selectRow = (row) => {
		dispatch(opvThunks.setSelectedOpv(row))
		close()
	}

	const lang = getCurrentLanguage()

	const getTableRow = useCallback(
		(row, index) => {
			return (
				<tr key={index + '-table-row'} onClick={() => selectRow(row)}>
					<td>{row.beginDate + ' - ' + row.endDate || '-'}</td>
					<td>{row.bin || '-'}</td>
					<td>{lang === 'rus' ? row.orgNameRu : row.orgNameKz}</td>
				</tr>
			)
		},
		[t]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'} className={styles.thText}>
						{t(head.label)}
					</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{!isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data.length && (
						<div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>
					)}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{data.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}
