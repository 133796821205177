export const removeDuplicates = (arr) => {
	const map = new Map()
	return arr.reduce((result, obj) => {
		if (!map.has(obj.externalId)) {
			map.set(obj.externalId, true)
			result.push(obj)
		}
		return result
	}, [])
}
