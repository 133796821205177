import { useTranslation } from 'react-i18next'
import { Button, SelectSearch } from '@/ui'
import styles from './AdditonalReasonSelect.module.scss'
import { Trash } from '@app/icons'
import { useCallback } from 'react'

export const AdditonalReasonSelect = ({
	setAdditionalReasons,
	index,
	value,
	terminationReasons,
	removeReason,
	stateTerminationReasonsIs = false,
}) => {
	const { t } = useTranslation()

	const handleReasonSelect = useCallback(
		(data) => {
			setAdditionalReasons((value) => {
				const newVal = [...value]
				newVal[index] = data
				return newVal
			})
		},
		[index, setAdditionalReasons]
	)

	const handleReasonRemove = useCallback(() => {
		removeReason(index)
	}, [removeReason, index])

	return (
		<>
			<SelectSearch
				value={value}
				onChange={handleReasonSelect}
				className="flex-1"
				options={terminationReasons}
				disabled={stateTerminationReasonsIs}
			/>
			<Button
				transparent
				onClick={handleReasonRemove}
				buttonClassName={styles.buttonRed}
				disabled={stateTerminationReasonsIs}
			>
				<Trash className={styles.iconRed} />
				{t('remove')}
			</Button>
		</>
	)
}
