import { actions } from './actions'
import { api } from '@/shared'
export const getOpv = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const response = await api.handbook.getOpv()
			dispatch(actions.opvList(response))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const setSelectedOpv = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.selectedOpv(payload))
		} catch (error) {
			dispatch(actions.selectedOpv({}))
		}
	}
}
