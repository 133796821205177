import React from 'react'
import { ModalTemplate } from '@/ui'
import styles from '../structureForm/StaffingFirstStructure.module.scss'
import { StaffingFirstStructure } from '../structureForm/StaffingFirstStructure'

export const Form = ({ staffProject, actions, onClose, setRowSelectMenu, isNewStaffing }) => {
	let initialValues = actions.edit
		? {
				note: staffProject?.note,
				orderDate: staffProject?.orderDate,
				orderNumber: staffProject?.orderNumber,
				topDeptCode: staffProject?.childs[0].dept.code,
				nameRu: staffProject?.childs[0].dept.nameRu,
				nameKz: staffProject?.childs[0].dept.nameKz,
		  }
		: {
				blank: isNewStaffing,
				note: '',
				orderDate: '',
				orderNumber: '',
		  }

	return (
		<div>
			<ModalTemplate onClose={onClose} priority={actions.edit ? 2 : 1}>
				<div className={`scroll ${actions.edit ? styles.wrapper_edit : styles.wrapper_first}`}>
					<StaffingFirstStructure
						initialValues={initialValues}
						actions={actions}
						onClose={onClose}
						staffProject={staffProject}
						setRowSelectMenu={setRowSelectMenu}
					></StaffingFirstStructure>
				</div>
			</ModalTemplate>
		</div>
	)
}
