import { Edit as EditIcon, View as ViewIcon, Decline as DeclineIcon, Sign as SignIcon, } from '@app/images'
import { isNull } from 'lodash'

export default function onActionMenuClickMyUnion(setActionMenuItems, t, row) {
	let actions = []

	if (isNull(row.approvedPerson) && isNull(row.initPerson)) {
		actions = [
			{ icon: EditIcon, title: t('actions.join'), action: 'join' },
		]
	} 
	if (!isNull(row.approvedPerson) && !isNull(row.initPerson) && !row.approvedPerson && !row.initPerson) {
		actions = [
			{ icon: SignIcon, title: t('actions.approve'), action: 'approve' },
			{ icon: DeclineIcon, title: t('actions.reject'), action: 'reject' },
		]
	}
	setActionMenuItems([{ icon: ViewIcon, title: t('actions.view'), action: 'view' }, ...actions])
}
