import { composeAddress } from '@/shared'

const dpositionCodeMessages = {
	rus: ' Указанные данные не найдены в справочнике',
	kz: ' Көрсетілген деректер анықтамалықта табылған жоқ',
}

export const parseAdditionalContractData = (
	t,
	additionalContract,
	positionsByKnz,
	lang,
	positions,
	remoteWorkTypes,
	jobTypes,
	workingTimeModes,
	workCondition,
	workActivityTypes
) => {
	const workType = jobTypes.find((item) => item.code == additionalContract?.dpartTimeCode)?.[lang] || '-'
	const dworkingHours =
		workingTimeModes.find((item) => item.code == additionalContract?.dworkingHoursCode)?.[lang] || '-'
	const getDangerListData = (index) => ({
		title: t(`contract.info.conditions${index}`),
		data: additionalContract?.dharmfulConditionsTypeCode?.startsWith(`L${index}`)
			? workActivityTypes.find((item) => item.code === additionalContract?.dharmfulConditionsTypeCode)?.[lang]
			: '-',
	})

	return [
		{ title: t('add_contract_num'), data: additionalContract?.contractNum || '-' },
		{ title: t('reg_date_additional_contract'), data: additionalContract?.registerDate || '-' },
		{ title: t('contract.info.start_date'), data: additionalContract?.dateFrom || '-' },
		{ title: t('contract.info.expiration_date'), data: additionalContract?.dateTo || '-' },
		{ title: t('contract.info.contract_term'), data: additionalContract?.dcontractDateCode || '-' },

		...(additionalContract?.employeeRequest
			? [{ title: t('contract.info.note'), data: t('contract.info.note_body_additional') }]
			: []),
		{ title: t('contract.info.work_type'), data: workType },
		{
			title: 'contract.info.nkz_position',
			data: additionalContract?.dpositionCode
				? (positionsByKnz.find(({ code }) => code === additionalContract?.dpositionCode) || {})[lang] ||
				  dpositionCodeMessages[lang]
				: '-',
		},
		{
			title: t('contract.info.position'),
			data:
				additionalContract?.establishedPost ||
				(positions.find(({ code }) => code === additionalContract?.destablishedPostCode) || {})[lang] ||
				'-',
		},
		{
			title: t('contract.info.working_condition'),
			data: additionalContract?.dworkingConditionsCode
				? workCondition.find((it) => it.code === additionalContract?.dworkingConditionsCode)[lang]
				: '-',
		},
		getDangerListData(1),
		getDangerListData(2),
		getDangerListData(3),

		{
			title: t('work_address'),
			data:
				composeAddress(
					{
						country: additionalContract?.country,
						region: additionalContract?.region,
						district: additionalContract?.district,
						locality: additionalContract?.locality,
					},
					additionalContract?.workingPlace
				) || '-',
		},
		{ title: t('contract.info.time_mode'), data: dworkingHours },
		{ title: t('contract.info.tariff_rate'), data: additionalContract?.tariffRate || '-' },
		{
			title: t('contract.info.remote_job'),
			data: (remoteWorkTypes.find(({ code }) => code === additionalContract?.dremoteWorkCode) || {})[lang] || '-',
		},
		{ title: t('change_contract_other'), data: additionalContract?.otherConditions || '-' },
	]
}
