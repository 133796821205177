import styles from './Form.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import React from 'react'
import { ModalTemplate } from '@/ui'
import { ECollectiveContractForm } from '@/entity/eCollectiveContracts/ui/form'

export const Form = ({ initialValues, options = {}, onCancel, onSubmit }) => {
	return (
		<div>
			<ModalTemplate onClose={onCancel}>
				<div className={`scroll ${styles.wrapper}`}>
					{options?.workTimeCollectiveContract?.length > 0 && options?.relaxTimeCollectiveContract?.length > 0 ? (
						<ECollectiveContractForm
							isTitle
							initialValues={initialValues}
							options={options}
							onCancel={onCancel}
							onSubmit={onSubmit}
						></ECollectiveContractForm>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
			</ModalTemplate>
		</div>
	)
}
