import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField } from 'formik'
import { Field } from '@/ui'
import { formFieldNames } from '../../../lib/constants/formFieldNames'
import styles from '../IndustryAgreement.module.scss'

export const Coefficients = connect(() => {
	const { t } = useTranslation()
	return (
		<div className={styles.fields}>
			<FormikField name={formFieldNames.industrialCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						isRequired
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.industrialCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.baseSalary}>
				{({ field, meta: { touched, error } }) => (
					<Field
						isRequired
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.baseSalary')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.oneToTwoGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.oneToTwoGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.twoToThreeGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.twoToThreeGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.threeToFourGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.threeToFourGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.fourToFiveGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.fourToFiveGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.fiveToSixGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.fiveToSixGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.sixToSevenGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.sixToSevenGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
			<FormikField name={formFieldNames.sevenToEightGradeCoef}>
				{({ field, meta: { touched, error } }) => (
					<Field
						type="number"
						min={0}
						hideNumberArrows
						enablecheckfloat
						label={t('industryAgreement.sevenToEightGradeCoef')}
						error={touched && error}
						placeholder={t('col_contract.placeholder_rate')}
						{...field}
					/>
				)}
			</FormikField>
		</div>
	)
})
