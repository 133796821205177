import React from 'react'
import styles from './Theme.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const Theme = ({ themes, activeTheme, onChange }) => {
	const lang = getCurrentLanguage()
	return (
		<>
			{themes.map((theme) => {
				return (
					<div
						key={theme.code}
						className={`${styles.themeStyle} ${activeTheme === theme.code ? styles.active : ''}`}
						onClick={() => onChange(theme)}
					>
						{theme?.[lang === 'rus' ? 'nameRu' : 'nameKz'] || '-'}
					</div>
				)
			})}
		</>
	)
}
