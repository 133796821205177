import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import styles from './Checks.module.scss'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { Indicator } from '@pages/CompanyPassport/CompanyInsurance'
import { Table, TableColumn } from '@/components/Table'
import { HrPagination } from '@/components/Pagination'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { defaultInsurance } from '@pages/CompanyPassport/dfault'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import { LoadingBlocker } from '@/components/Loader'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { Title } from '@/ui'
import { Info } from '@app/icons'
import { Tooltip } from 'antd'

const NoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
			<rect width="40" height="40" fill="#F0F9F4" rx="20" />
			<g stroke="#17B67C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)">
				<path d="M28.2 20.6a2 2 0 1 1 2.9 2.8l-6.8 6.8c-.2.2-.5.3-.8.3h-2.3v-2.4c0-.3 0-.6.3-.8l6.7-6.7v0Zm-14-4.1h9.3m-9.3 4.7H20m-5.8 4.6h3.5m11.9-.8-2.9-3m1.5-5.5V13c0-1.3-1-2.3-2.4-2.3h-14c-1.3 0-2.3 1-2.3 2.3v15.2c0 1.3 1 2.3 2.3 2.3h4.7m7-21v2.3m-4.7-2.3v2.3m-4.6-2.3v2.3" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const OrangeNoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
			<rect width="40" height="40" fill="#FFF1DC" rx="20" />
			<g clipPath="url(#a)">
				<g stroke="#F3A126" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#b)">
					<path d="m15.3 10.7 1.6-2c.2-.2.5-.4.9-.4h4.4c.4 0 .7.2 1 .5l1.5 1.9v1.1c0 .7-.6 1.2-1.2 1.2h-7c-.6 0-1.2-.5-1.2-1.2v-1.1 0Z" />
					<path d="M15.3 10.7H13c-1.3 0-2.3 1-2.3 2.3v15.2c0 1.3 1 2.3 2.3 2.3h14c1.3 0 2.3-1 2.3-2.3V13c0-1.3-1-2.3-2.3-2.3h-2.3m-9.4 8.1h9.4m-9.4 5.9h9.4" />
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
				<clipPath id="b">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const RedNoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none">
			<rect width="40" height="40" x=".5" fill="#FBEFEF" rx="20" />
			<g clipPath="url(#a)">
				<g stroke="#F04E47" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#b)">
					<path d="M27.5 15.3v-3.5c0-1.3-1-2.3-2.3-2.3H12.3c-1.3 0-2.3 1-2.3 2.3v16.4c0 1.3 1 2.3 2.3 2.3h3.5" />
					<path d="M15.3 25.8a1.8 1.8 0 1 1 0-3.5 1.8 1.8 0 0 1 0 3.5ZM31 28.9v-1c0-.6-.3-1-.8-1.2l-2.7-.9v-2.3l.8-.5a1.6 1.6 0 0 0-.9-3h-3.3a1.6 1.6 0 0 0-1 3l.9.5v2.3l-2.7 1c-.5 0-.8.5-.8 1v1c0 .4.1.7.3.9l.5.5c.2.2.5.3.8.3h7.3c.3 0 .6-.1.8-.3l.5-.5c.2-.2.3-.5.3-.8ZM13.5 14.2h9.3m-9.3 4.1h4.7" />
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6.5 6h28v28h-28z" />
				</clipPath>
				<clipPath id="b">
					<path fill="#fff" d="M6.5 6h28v28h-28z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const RestrictIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#eb5757"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm4.3-2.2c.2-.2.5-.2.7 0L7 6.3l1.5-1.5a.5.5 0 0 1 .7.7L7.7 7l1.5 1.5a.5.5 0 0 1-.7.7L7 7.7 5.5 9.2a.5.5 0 0 1-.7-.7L6.3 7 4.8 5.5a.5.5 0 0 1 0-.7Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const WaitIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#5792EB"
				fillRule="evenodd"
				d="M6.8 3.8c.3 0 .5.2.5.5v2.8l2.2 1.4a.5.5 0 1 1-.5.8L6.6 8a.5.5 0 0 1-.3-.5v-3c0-.4.2-.6.5-.6Z"
				clipRule="evenodd"
			/>
			<path
				fill="#5792EB"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const WaitIconOrange = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M6.8 3.8c.3 0 .5.2.5.5v2.8l2.2 1.4a.5.5 0 1 1-.5.8L6.6 8a.5.5 0 0 1-.3-.5v-3c0-.4.2-.6.5-.6Z"
				clipRule="evenodd"
			/>
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const WaitIconGreen = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M6.8 3.8c.3 0 .5.2.5.5v2.8l2.2 1.4a.5.5 0 1 1-.5.8L6.6 8a.5.5 0 0 1-.3-.5v-3c0-.4.2-.6.5-.6Z"
				clipRule="evenodd"
			/>
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const DoneIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM11 6.3c.2.2.2.5 0 .7l-3.3 3.4H7l-2-2a.5.5 0 1 1 .7-.8l1.6 1.7 3-3c.2-.2.5-.2.7 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const PauseIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm5.2-2.5c.2 0 .5.2.5.5v4a.5.5 0 0 1-1 0V6c0-.3.2-.5.5-.5Zm2.6 0c.3 0 .5.2.5.5v4a.5.5 0 0 1-1 0V6c0-.3.3-.5.5-.5Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

//Восклицательный знак grey-500
const InfoIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 1.5C3.96214 1.5 1.5 3.96214 1.5 7C1.5 10.0379 3.96214 12.5 7 12.5C10.0379 12.5 12.5 10.0379 12.5 7C12.5 3.96214 10.0379 1.5 7 1.5ZM0.5 7C0.5 3.40986 3.40986 0.5 7 0.5C10.5901 0.5 13.5 3.40986 13.5 7C13.5 10.5901 10.5901 13.5 7 13.5C3.40986 13.5 0.5 10.5901 0.5 7Z"
				fill="#6B7280"
			/>
			<path
				d="M6.53385 3.41667C6.53385 3.18655 6.7204 3 6.95052 3C7.18064 3 7.36719 3.18655 7.36719 3.41667V7.89858C7.36719 8.1287 7.18064 8.31524 6.95052 8.31524C6.7204 8.31524 6.53385 8.1287 6.53385 7.89858V3.41667Z"
				fill="#6B7280"
			/>
			<path
				d="M6.33333 9.939C6.33333 9.59803 6.60974 9.32162 6.95072 9.32162C7.29169 9.32162 7.5681 9.59803 7.5681 9.939C7.5681 10.28 7.29169 10.5564 6.95072 10.5564C6.60974 10.5564 6.33333 10.28 6.33333 9.939Z"
				fill="#6B7280"
			/>
			<path
				d="M1.6 7C1.6 4.01737 4.01737 1.6 7 1.6V1.4C3.90691 1.4 1.4 3.90691 1.4 7H1.6ZM7 12.4C4.01737 12.4 1.6 9.98263 1.6 7H1.4C1.4 10.0931 3.90691 12.6 7 12.6V12.4ZM12.4 7C12.4 9.98263 9.98263 12.4 7 12.4V12.6C10.0931 12.6 12.6 10.0931 12.6 7H12.4ZM7 1.6C9.98263 1.6 12.4 4.01737 12.4 7H12.6C12.6 3.90691 10.0931 1.4 7 1.4V1.6ZM7 0.4C3.35463 0.4 0.4 3.35463 0.4 7H0.6C0.6 3.46509 3.46509 0.6 7 0.6V0.4ZM13.6 7C13.6 3.35463 10.6454 0.4 7 0.4V0.6C10.5349 0.6 13.4 3.46509 13.4 7H13.6ZM7 13.6C10.6454 13.6 13.6 10.6454 13.6 7H13.4C13.4 10.5349 10.5349 13.4 7 13.4V13.6ZM0.4 7C0.4 10.6454 3.35463 13.6 7 13.6V13.4C3.46509 13.4 0.6 10.5349 0.6 7H0.4ZM7.26719 3.41667V7.89858H7.46719V3.41667H7.26719ZM6.63385 7.89858V3.41667H6.43385V7.89858H6.63385ZM6.95052 8.21524C6.77563 8.21524 6.63385 8.07347 6.63385 7.89858H6.43385C6.43385 8.18392 6.66517 8.41524 6.95052 8.41524V8.21524ZM7.26719 7.89858C7.26719 8.07347 7.12541 8.21524 6.95052 8.21524V8.41524C7.23587 8.41524 7.46719 8.18392 7.46719 7.89858H7.26719ZM6.95052 3.1C7.12541 3.1 7.26719 3.24178 7.26719 3.41667H7.46719C7.46719 3.13132 7.23587 2.9 6.95052 2.9V3.1ZM6.95052 2.9C6.66517 2.9 6.43385 3.13132 6.43385 3.41667H6.63385C6.63385 3.24178 6.77563 3.1 6.95052 3.1V2.9ZM6.95072 10.4564C6.66497 10.4564 6.43333 10.2247 6.43333 9.939H6.23333C6.23333 10.3352 6.55452 10.6564 6.95072 10.6564V10.4564ZM7.4681 9.939C7.4681 10.2247 7.23646 10.4564 6.95072 10.4564V10.6564C7.34691 10.6564 7.6681 10.3352 7.6681 9.939H7.4681ZM6.95072 9.42161C7.23646 9.42161 7.4681 9.65325 7.4681 9.939H7.6681C7.6681 9.5428 7.34691 9.22161 6.95072 9.22161V9.42161ZM6.95072 9.22161C6.55452 9.22161 6.23333 9.5428 6.23333 9.939H6.43333C6.43333 9.65325 6.66497 9.42161 6.95072 9.42161V9.22161Z"
				fill="#6B7280"
			/>
		</svg>
	)
}

//Восклицательный знак blue
const InfoBlueIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 1.5C3.96214 1.5 1.5 3.96214 1.5 7C1.5 10.0379 3.96214 12.5 7 12.5C10.0379 12.5 12.5 10.0379 12.5 7C12.5 3.96214 10.0379 1.5 7 1.5ZM0.5 7C0.5 3.40986 3.40986 0.5 7 0.5C10.5901 0.5 13.5 3.40986 13.5 7C13.5 10.5901 10.5901 13.5 7 13.5C3.40986 13.5 0.5 10.5901 0.5 7Z"
				fill="#5792EB"
			/>
			<path
				d="M6.53385 3.41667C6.53385 3.18655 6.7204 3 6.95052 3C7.18064 3 7.36719 3.18655 7.36719 3.41667V7.89858C7.36719 8.1287 7.18064 8.31524 6.95052 8.31524C6.7204 8.31524 6.53385 8.1287 6.53385 7.89858V3.41667Z"
				fill="#5792EB"
			/>
			<path
				d="M6.33333 9.939C6.33333 9.59803 6.60974 9.32162 6.95072 9.32162C7.29169 9.32162 7.5681 9.59803 7.5681 9.939C7.5681 10.28 7.29169 10.5564 6.95072 10.5564C6.60974 10.5564 6.33333 10.28 6.33333 9.939Z"
				fill="#5792EB"
			/>
			<path
				d="M1.6 7C1.6 4.01737 4.01737 1.6 7 1.6V1.4C3.90691 1.4 1.4 3.90691 1.4 7H1.6ZM7 12.4C4.01737 12.4 1.6 9.98263 1.6 7H1.4C1.4 10.0931 3.90691 12.6 7 12.6V12.4ZM12.4 7C12.4 9.98263 9.98263 12.4 7 12.4V12.6C10.0931 12.6 12.6 10.0931 12.6 7H12.4ZM7 1.6C9.98263 1.6 12.4 4.01737 12.4 7H12.6C12.6 3.90691 10.0931 1.4 7 1.4V1.6ZM7 0.4C3.35463 0.4 0.4 3.35463 0.4 7H0.6C0.6 3.46509 3.46509 0.6 7 0.6V0.4ZM13.6 7C13.6 3.35463 10.6454 0.4 7 0.4V0.6C10.5349 0.6 13.4 3.46509 13.4 7H13.6ZM7 13.6C10.6454 13.6 13.6 10.6454 13.6 7H13.4C13.4 10.5349 10.5349 13.4 7 13.4V13.6ZM0.4 7C0.4 10.6454 3.35463 13.6 7 13.6V13.4C3.46509 13.4 0.6 10.5349 0.6 7H0.4ZM7.26719 3.41667V7.89858H7.46719V3.41667H7.26719ZM6.63385 7.89858V3.41667H6.43385V7.89858H6.63385ZM6.95052 8.21524C6.77563 8.21524 6.63385 8.07347 6.63385 7.89858H6.43385C6.43385 8.18392 6.66517 8.41524 6.95052 8.41524V8.21524ZM7.26719 7.89858C7.26719 8.07347 7.12541 8.21524 6.95052 8.21524V8.41524C7.23587 8.41524 7.46719 8.18392 7.46719 7.89858H7.26719ZM6.95052 3.1C7.12541 3.1 7.26719 3.24178 7.26719 3.41667H7.46719C7.46719 3.13132 7.23587 2.9 6.95052 2.9V3.1ZM6.95052 2.9C6.66517 2.9 6.43385 3.13132 6.43385 3.41667H6.63385C6.63385 3.24178 6.77563 3.1 6.95052 3.1V2.9ZM6.95072 10.4564C6.66497 10.4564 6.43333 10.2247 6.43333 9.939H6.23333C6.23333 10.3352 6.55452 10.6564 6.95072 10.6564V10.4564ZM7.4681 9.939C7.4681 10.2247 7.23646 10.4564 6.95072 10.4564V10.6564C7.34691 10.6564 7.6681 10.3352 7.6681 9.939H7.4681ZM6.95072 9.42161C7.23646 9.42161 7.4681 9.65325 7.4681 9.939H7.6681C7.6681 9.5428 7.34691 9.22161 6.95072 9.22161V9.42161ZM6.95072 9.22161C6.55452 9.22161 6.23333 9.5428 6.23333 9.939H6.43333C6.43333 9.65325 6.66497 9.42161 6.95072 9.42161V9.22161Z"
				fill="#5792EB"
			/>
		</svg>
	)
}

const mapIconToStatusRu = {
	ПРОЕКТ: <InfoIcon />,
	'На согласовании у Руководителя отдела': <WaitIcon />,
	'На доработке от Руководителя отдела': <WaitIconOrange />,
	'На подписании у ГГИТ региона': <WaitIcon />,
	'Отказана в регистрации проверки ГГИТ': <RestrictIcon />,
	'На регистрации в ЕРСОП': <WaitIcon />,
	'Отказана в регистрации ЕРСОП': <RestrictIcon />,
	'Возвращена на доработку в ГО': <WaitIconOrange />,
	'Зарегистрирована в ЕРСОП': <DoneIcon />,
	Приостановлена: <PauseIcon />,
	Возобновлена: <WaitIconGreen />,
	'Отменена проверка': <RestrictIcon />,
	Завершена: <DoneIcon />,
	Удалена: <RestrictIcon />,
	'На доработке от ГГИТ региона': <WaitIconOrange />,
	'Запрос на приостановку': <InfoBlueIcon />,
	'Возврат на доработку в ПРОЕКТ': <InfoIcon />,
	'Запрос на изменение сроков проверки': <InfoBlueIcon />,
	'Запрос на изменение проверяемого периода': <InfoBlueIcon />,
	'Запрос на изменение состава проверяющих': <InfoBlueIcon />,
	'Запрос на отмену': <InfoBlueIcon />,
	'Запрос на возобновление': <InfoBlueIcon />,
}

const mapColorsToStatusRu = {
	ПРОЕКТ: '#6b7280',
	'На согласовании у Руководителя отдела': '#5792EB',
	'На доработке от Руководителя отдела': '#F19204',
	'На подписании у ГГИТ региона': '#5792EB',
	'Отказана в регистрации проверки ГГИТ': '#eb5757',
	'На регистрации в ЕРСОП': '#5792EB',
	'Отказана в регистрации ЕРСОП': '#eb5757',
	'Возвращена на доработку в ГО': '#F19204',
	'Зарегистрирована в ЕРСОП': '#17B67C',
	Приостановлена: '#F19204',
	Возобновлена: '#17B67C',
	'Отменена проверка': '#eb5757',
	Завершена: '#17B67C',
	Удалена: '#eb5757',
	'На доработке от ГГИТ региона': '#F19204',
	'Запрос на приостановку': '#5792EB',
	'Возврат на доработку в ПРОЕКТ': '#6b7280',
	'Запрос на изменение сроков проверки': '#5792EB',
	'Запрос на изменение проверяемого периода': '#5792EB',
	'Запрос на изменение состава проверяющих': '#5792EB',
	'Запрос на отмену': '#5792EB',
	'Запрос на возобновление': '#5792EB',
}

const mapIconToStatusKz = {
	ЖОБА: <InfoIcon />,
	'Бөлім басшысының келісуінде': <WaitIcon />,
	'Бөлім басшысынан пысықтауда': <WaitIconOrange />,
	'Өңірдің БМЕИ-дің қол қоюында': <WaitIcon />,
	'БМЕИ тексеруді тіркеуден бас тартты': <RestrictIcon />,
	'ТСОБТ-та тіркелуде': <WaitIcon />,
	'ТСОБТ тіркеуден бас тартылды': <RestrictIcon />,
	'МО-ға пысықтауға қайтарылды': <WaitIconOrange />,
	'ТСОБТ-та тіркелді': <DoneIcon />,
	Тоқтатылды: <PauseIcon />,
	Жаңартылды: <WaitIconGreen />,
	'Тексерудің күші жойылды': <RestrictIcon />,
	Аяқталды: <DoneIcon />,
	Жойылды: <RestrictIcon />,
	'Өңірдің БМЕИ-ден пысықтауда': <WaitIconOrange />,
	'Тоқтата тұруға сұраныста': <InfoBlueIcon />,
	'ЖОБАҒА қайта өңдеуге қайтарылды': <InfoIcon />,
	'Тексеру мерзімдерін өзгертуге сұраныста': <InfoBlueIcon />,
	'Тексеру кезеңін өзгертуге сұраныста': <InfoBlueIcon />,
	'Тексерушілер құрамын өзгертуге сұраныста': <InfoBlueIcon />,
	'Күшін жоюға сұраныста': <InfoBlueIcon />,
	'Жаңартылуға сұраныста': <InfoBlueIcon />,
}

const mapColorsToStatusKz = {
	ЖОБА: '#6b7280',
	'Бөлім басшысының келісуінде': '#5792EB',
	'Бөлім басшысынан пысықтауда': '#F19204',
	'Өңірдің БМЕИ-дің қол қоюында': '#5792EB',
	'БМЕИ тексеруді тіркеуден бас тартты': '#eb5757',
	'ТСОБТ-та тіркелуде': '#5792EB',
	'ТСОБТ тіркеуден бас тартылды': '#eb5757',
	'МО-ға пысықтауға қайтарылды': '#F19204',
	'ТСОБТ-та тіркелді': '#17B67C',
	Тоқтатылды: '#F19204',
	Жаңартылды: '#17B67C',
	'Тексерудің күші жойылды': '#eb5757',
	Аяқталды: '#17B67C',
	Жойылды: '#eb5757',
	'Өңірдің БМЕИ-ден пысықтауда': '#F19204',
	'Тоқтата тұруға сұраныста': '#5792EB',
	'ЖОБАҒА қайта өңдеуге қайтарылды': '#6b7280',
	'Тексеру мерзімдерін өзгертуге сұраныста': '#5792EB',
	'Тексеру кезеңін өзгертуге сұраныста': '#5792EB',
	'Тексерушілер құрамын өзгертуге сұраныста': '#5792EB',
	'Күшін жоюға сұраныста': '#5792EB',
	'Жаңартылуға сұраныста': '#5792EB',
}

export const CheckStatusColumn = ({ status }) => {
	const lang = getCurrentLanguage()
	const mapColorsToStatus = lang === 'rus' ? mapColorsToStatusRu : mapColorsToStatusKz
	const mapIconToStatus = lang === 'rus' ? mapIconToStatusRu : mapIconToStatusKz

	return (
		<div className={styles.statusColumn} style={{ color: mapColorsToStatus[status?.trim()] }}>
			{mapIconToStatus[status?.trim()]}
			<Typography variant={'text_inter_14'}>{status}</Typography>
		</div>
	)
}

export const Checks = () => {
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'
	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [totalPages, setTotalPages] = useState(0)
	const abbreviations = t('ckp_abbreviations').split('\n');
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const fetchFunction = useCallback(
		async (reqestedBin) => {
			return safetyService.getChecks(currentPage, rowsPerPage, reqestedBin)
		},
		[currentPage, rowsPerPage]
	)

	const { data, isEmpty, loading } = useFetchData({
		bin: activeCompany.bin,
		fetchFunction,
		defaultValue: defaultInsurance,
		defaultChecks: true,
	})

	const dataTable = data?.check?.map((item) => {
		return {
			...item,
			duration: `${item.checkBeginDate} - ${item.checkEndDate}`,
			inspector: `${item.userLastName ?? ''} ${item.userFirstName ?? ''}`,
		}
	})

	useEffect(() => {
		if (data?.total) {
			setTotalPages(Math.ceil(data.total / rowsPerPage))
		}
	}, [data])

	if (loading) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_checks')}>
					<LoadingBlocker />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	if (isEmpty || !data.total) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_checks')}>
					<InfoDevelopment title={t('ckp_no_info_on_your_enterprise')} small={false} />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_checks')}>
				<div className={styles.checksContainer}>
					<div className={styles.statisticContainer}>
						<Typography variant={'title_sm_inter'}>{t('ckp_statistics')}</Typography>
						<div className={styles.statisticGrid}>
							<div className={styles.statisticHeader}>
								<Typography variant={'subtitle_inter'}>{t('ckp_checks')}</Typography>
								<Typography variant={'subtitle_inter'}>{t('ckp_measures_taken')}</Typography>
							</div>
							<Indicator
								title={t('ckp_total_professional_control')}
								icon={<NoteIcon />}
								value={data?.total}
								subValue={`(${data?.checkTypeOtbScheduled}/ ${data?.checkTypeOtbUnScheduled} / ${data?.checkTypeOtbAdministrative})`}
							/>
							<Indicator
								title={t('ckp_active_completed')}
								icon={<NoteIcon />}
								value={`${data?.countActive} / ${data?.countNotActive}`}
							/>
							<Indicator title={t('ckp_instructions_issued')} icon={<OrangeNoteIcon />} value={data?.prescription} />
							<Indicator
								title={t('ckp_administrative_cases_initiated')}
								icon={<RedNoteIcon />}
								value={data?.administrativeMatter}
							/>
						</div>
					</div>
					<div>
						<div className={styles.tooltip}>
						<Title>{t('ckp_check_title')}</Title>
						<Tooltip
							placement="rightTop"
							color="white"
							overlayInnerStyle={{ color: '#5B6880', width: '500px' }}
							title={(
								<div>
									{abbreviations.map((item, index) => (
										<div key={index}>{item}</div>
									))}
								</div>
							)}
						>
							<span>
								<Info className={styles.hintIcon} />
							</span>
						</Tooltip>
						</div>
						<Table tableName="social-leaves" data={dataTable} emptyPlaceholder="-">
							<TableColumn dataKey="registrationNumberKpsisu" heading={t('ckp_KPSU_number')} />
							<TableColumn dataKey={`checkTypeName${langPrefix}`} heading={t('ckp_inspection_type')} />
							<TableColumn
								dataKey={`statusName${langPrefix}`}
								renderCell={(item) => {
									return <CheckStatusColumn status={item} />
								}}
								heading={t('ckp_status')}
							/>
							<TableColumn dataKey="duration" heading={t('ckp_execution_period')} />
							<TableColumn dataKey="inspector" heading={t('ckp_inspector')} />
						</Table>
					<HrPagination
						id="hr-pagination"
						rowsPerPageitems={[
							{ size: 10, label: '10' },
							{ size: 50, label: '50' },
							{ size: 100, label: '100' },
						]}
						rowsPerPage={rowsPerPage}
						numberOfElements={data?.check?.length || 0}
						totalElements={data?.total || 0}
						totalPages={totalPages}
						handleChangePage={(_, page) => {
							setCurrentPage(page - 1)
						}}
						handleChangeRowsPerPage={(rows) => {
							setRowsPerPage(rows)
							setCurrentPage(0)
						}}
						page={currentPage}
					/>
					</div>
				</div>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
