import * as types from './types'
export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	setUser: (payload) => ({
		type: types.SET_USER,
		payload,
	}),
	setActiveRole: (payload) => ({
		type: types.SET_ACTIVE_ROLE,
		payload,
	}),
	setUserByBin: (payload) => ({
		type: types.SET_USER_BY_IIN,
		payload,
	}),
	setEmployerCompanies: (payload) => ({
		type: types.SET_EMPLOYER_COMPANIES,
		payload,
	}),
	clearErrorCompanies: () => ({
		type: types.CLEAR_ERROR_COMPANIES,
	}),
}
