import { formFieldNames } from '@/entity'
import { Field } from '@/ui'
import { checkIsFieldRequired } from '@/shared'
import React, { useEffect } from 'react'
import { connect, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'

export const TariffRateField = connect(({ disabled, formik: { setFieldValue, values, errors }, isFormDirty }) => {
	const { dworkingHoursCode } = values
	const { t } = useTranslation()

	useEffect(() => dworkingHoursCode !== '3' && setFieldValue(formFieldNames.tarrifRate, null), [dworkingHoursCode])
	return (
		<FormikField name={formFieldNames.tarrifRate}>
			{({ field, meta: { touched, error } }) => (
				<Field
					type="number"
					max={1}
					min={0}
					hideNumberArrows
					isRequired={checkIsFieldRequired(errors, field.name, false)}
					label={t('contracts_tab.tarrif_rate')}
					placeholder={t('value_from_to', { from: 0, to: 1 })}
					error={(touched || isFormDirty) && error}
					hint={t('hint_tarrif')}
					disabled={values[formFieldNames.workingTimeMode] !== '3' || disabled} // Не активно, если режим рабочего времени нормальное/сокращенное
					{...field}
					enablecheckfloat
				/>
			)}
		</FormikField>
	)
})
