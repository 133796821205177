import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import phoneCallIcon from '../../../assets/home/phone-call.svg'
import mailIcon from '../../../assets/home/mail.svg'
import classNames from 'classnames'

const iconTypes = {
	phone: phoneCallIcon,
	email: mailIcon,
}

const SearchResult = ({ data, visible }) => {
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	return (
		<div className={classNames('form-right-card', { active: visible })}>
			<p>{data?.name?.[currentLanguage]}</p>
			<div className="card-wrapper">
				<div className="card-wrapper-left">
					<p className={'p2'}>{t('address') + ':'}</p>
					<span>{data?.address?.[currentLanguage]}</span>
					<div className="contacts">
						{['phone', 'mail'].map((type) => (
							<p key={type}>
								<img src={iconTypes[type]} />
								<span>{data?.[type]?.[currentLanguage]}</span>
							</p>
						))}
					</div>
				</div>
				<div className="card-wrapper-right">
					<p className={'p2'}>{t('inspector') + ':'}</p>
					{data?.inspectors[0].map((arr) => {
						return (
							<p key={arr.id}>
								<span>{currentLanguage === 'rus' ? arr.nameRu : arr.nameKz}</span>
							</p>
						)
					})}
					<p className={'pEnd'}>{t('director') + ':'}</p>
					<span>{data?.director?.[currentLanguage]}</span>
				</div>
			</div>
		</div>
	)
}

export default SearchResult
