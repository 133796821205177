import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const CloseButton = ({ onClose }) => {
	return (
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={{
				position: 'absolute',
				right: 8,
				top: 8,
				color: '#aab8d1',
				'&:hover': {
					backgroundColor: 'initial',
				},
			}}
		>
			<CloseIcon
				sx={{
					fontSize: '34px',
				}}
			>
				{' '}
			</CloseIcon>
		</IconButton>
	)
}

export default CloseButton
