export const Signed = ({ className = '' }) => (
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<g clip-path="url(#clip0_6565_31785)">
			<path
				d="M12.8945 0C6.27733 0 0.894531 5.3828 0.894531 12C0.894531 18.6172 6.27733 24 12.8945 24C19.5117 24 24.8945 18.6163 24.8945 12C24.8945 5.38373 19.5117 0 12.8945 0ZM12.8945 22.141C7.30352 22.141 2.75355 17.592 2.75355 12C2.75355 6.40805 7.30352 1.85902 12.8945 1.85902C18.4865 1.85902 23.0355 6.40805 23.0355 12C23.0355 17.592 18.4855 22.141 12.8945 22.141Z"
				fill="#17B67C"
			/>
			<path
				d="M18.4003 7.82595C18.0219 7.48203 17.4336 7.50898 17.0878 7.88914L11.4178 14.1327L8.67847 11.3479C8.31687 10.9817 7.72943 10.9761 7.36414 11.3367C6.9979 11.6965 6.99232 12.2848 7.35298 12.6511L10.7819 16.1367C10.9576 16.3152 11.1956 16.4147 11.4447 16.4147C11.4502 16.4147 11.4568 16.4147 11.4623 16.4156C11.7189 16.41 11.9606 16.3003 12.1325 16.1107L18.4634 9.13939C18.8083 8.75824 18.7804 8.17081 18.4003 7.82595Z"
				fill="#17B67C"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6565_31785">
				<rect width="24" height="24" fill="white" transform="translate(0.894531)" />
			</clipPath>
		</defs>
	</svg>
)
