import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import styles from '@features/contracts/transferRequests/components/form/TransferForm.module.scss'
import { Box } from '@mui/system'
import { Field as FormikField } from 'formik'
import { Field, Button } from '@/ui'
import { Add, Delete } from '@app/icons'
import { connect, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { transferActions, transferSelectors, transferThunks } from '@/entity'
import { removeDuplicates } from '@features/contracts/transferRequests/components/form/utils'

export const ContractFiled = connect(({ contractInitial, formik: { setFieldValue, values, errors } }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isSuccess = useSelector(transferSelectors.isSuccess)
	const contracts = useSelector(transferSelectors.contracts)

	useEffect(() => {
		if (isSuccess && contracts?.length === 1) {
			let parsedContract = values.contracts.concat(contracts).filter((item) => item.externalId !== '')
			setFieldValue('contracts', removeDuplicates(parsedContract))
		}
	}, [contracts?.length, isSuccess])
	const handleSearchIin = (index) => {
		if (values.bin?.length === 12 && values.contracts[index]?.iin?.length === 12)
			dispatch(transferThunks.getDataByIin(values.contracts[index]?.iin, values.bin))
	}
	return (
		<FieldArray name="contracts">
			{({ remove, push }) => (
				<>
					{values.contracts.map((contract, index) => (
						<Grid
							key={`contracts.${index}.element`}
							container
							spacing={4}
							alignItems="flex-end"
							classes={{ root: styles.container }}
						>
							<Grid item xs={4}>
								<Box display="flex" alignItems="flex-end" justifyContent="space-between">
									<FormikField name={`contracts.${index}.iin`}>
										{({ field, meta: { error, touched } }) => (
											<Field
												{...field}
												label={t('worker_iin')}
												placeholder={t('enter_12_digits')}
												error={touched && error}
											/>
										)}
									</FormikField>
									<div className={styles.button}>
										<Button
											disabled={!(values.contracts[index]?.iin?.length === 12)}
											onClick={() => handleSearchIin(index)}
										>
											{t('find')}
										</Button>
									</div>
								</Box>
							</Grid>
							<Grid item xs={3.5}>
								<FormikField name={`contracts.${index}.fullName`}>
									{({ field }) => (
										<Field disabled {...field} label={t('employee_initials')} placeholder={t('auto_filled')} />
									)}
								</FormikField>
							</Grid>
							<Grid item xs={3.5}>
								<FormikField name={`contracts.${index}.contractNumber`}>
									{({ field }) => (
										<Field disabled {...field} label={t('contract.info.td_number')} placeholder={t('auto_filled')} />
									)}
								</FormikField>
							</Grid>
							<Grid item xs={1}>
								{values.contracts.length > 1 && (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										onClick={remove.bind(null, index)}
										className={styles.delete__button}
									>
										<Delete />
										<span>{t('delete_action')}</span>
									</Box>
								)}
							</Grid>
						</Grid>
					))}
					<Button iconSize="smallSize" icon={<Add fill="white" />} onClick={push.bind(null, contractInitial)}>
						{t('add_id')}
					</Button>
				</>
			)}
		</FieldArray>
	)
})
