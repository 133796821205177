import styles from './Table.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useSelector } from 'react-redux'
import { affairsSelectors } from '@/entity/personalAffairs'
import { headCells } from './utils'
export const Table = ({ data }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)
	const [openModal, setOpenModal] = useState(false)
	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index} onClick={() => setOpenModal(!openModal)}>
				<td>{row.iin || '-'}</td>
				<td>{row.fio || '-'}</td>
				<td>{(language === 'rus' ? row.category : row.categoryKz) || '-'}</td>
			</tr>
		),
		[t]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('personal_affairs.no_data')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
			</table>
		</>
	)
}
