import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Arrow } from '@app/icons'
import { useClickOutside, useToggle, useDebounce, convertDataToSelectData } from '@/shared'
import { Error, Options } from '@/ui/atoms'
import { InputWithIcon } from '@/ui'
import styles from './style.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Close8 } from '@app/images'

//Here will be API call for dynamic getting options from server using searchValue
export const SearchSelect = ({
	placeholder,
	onChange,
	value,
	onBlur,
	apiHandler,
	error,
	spareApiHandler,
	setTouched,
	name,
	...props
}) => {
	const [options, setOptions] = useState([])
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const [searchValue, setSearchValue] = useState(value || '')
	const debouncedValue = useDebounce(searchValue, 500)
	const selectRef = useRef()
	const currentLanguage = getCurrentLanguage()

	useEffect(() => {
		if (isOpenedOptions && setTouched) {
			setTouched({ [name]: true }, false)
		}
	}, [isOpenedOptions])

	const searchValueHandler = useCallback(async () => {
		if (apiHandler) {
			const result = await apiHandler(debouncedValue)
			// костыль для совместимости со справочником неактуальных должностей по НКЗ
			if (spareApiHandler && result.content.length === 0) {
				const result2 = await spareApiHandler({ code: value })
				return setOptions(convertDataToSelectData([result2.filter(({ isLast }) => !!isLast)]))
			}
			setOptions(convertDataToSelectData(result.content))
		}
	}, [apiHandler, debouncedValue])

	useEffect(() => {
		onChange(debouncedValue)
		searchValueHandler()
	}, [debouncedValue])

	const onChangeSearchValue = (value) => setSearchValue(value)

	const onSelect = (code) => {
		onChange(code)
		toggleOpenedOptions()
	}

	const onClickOutside = () => toggleOpenedOptions()

	const filteredOptions = useMemo(
		() => options.filter((option) => option[currentLanguage]?.toLowerCase().includes(debouncedValue.toLowerCase())),
		[debouncedValue, options]
	)

	const selectedOption = useMemo(() => options.find(({ code }) => value === code), [value, options])

	const onClear = () => {
		onChange('')
		setSearchValue('')
	}

	useClickOutside(selectRef, isOpenedOptions, onClickOutside)

	return (
		<div className={styles.select} ref={selectRef}>
			<InputWithIcon
				placeholder={placeholder}
				onChange={onChangeSearchValue}
				onClick={toggleOpenedOptions}
				value={selectedOption?.[currentLanguage] || searchValue}
				iconSide="right"
				icon={
					!selectedOption ? (
						<Arrow
							onClick={toggleOpenedOptions}
							styles={{ transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})` }}
							className={styles.arrowIcon}
						/>
					) : (
						<img onClick={onClear} src={Close8} className={styles.closeIcon} alt="close_icon" />
					)
				}
				{...props}
			/>
			{isOpenedOptions && <Options options={filteredOptions} onSelect={onSelect} onBlur={onBlur} />}
			<span className={styles.error}>
				<Error>{error}</Error>
			</span>
		</div>
	)
}
