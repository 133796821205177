import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './ModalGetNewVersion.module.scss'

const ModalGetNewVersion = ({ onSubmit, onClose }) => {
	const { t } = useTranslation()
	return (
		<ModalTemplate onClose={onClose} hideBackButton={true}>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{t('staff.question')}</Title>
				<div className={styles.buttonStyle}>
					<Button type="submit" onClick={onSubmit}>
						{t('staff.sign_and_request')}
					</Button>
					<Button type="bare" onClick={onClose}>
						{t('no_cancel')}
					</Button>
				</div>
			</div>
		</ModalTemplate>
	)
}

export default ModalGetNewVersion
