import * as types from './types'

export const initialStaffingPosition = {
	code: '',
	establishedPost: {
		code: '',
		nameKz: '',
		nameRu: '',
	},
	externalId: '',
	factSalary: '',
	freelancer: false,
	grossSalary: '',
	positionCode: '',
	salarySupplement: '',
	staffFreeCount: '',
	staffUnitsCount: '',
}

export const initialStaff = {
	actual: false,
	beginDate: '',
	bin: '',
	childs: [
		{
			binBranch: '',
			childs: [{}],
			dept: {
				code: '',
				nameKz: '',
				nameRu: '',
			},
			deptTypeCode: '',
			externalId: '',
			parentExternalId: '',
			workPlaces: [initialStaffingPosition],
		},
	],
	currVersion: 0,
	externalId: '',
	note: '',
	orderDate: '',
	orderNumber: '',
	parentBin: '',
	esutdInfo: {},
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	staffing: {
		data: initialStaff,
		isLoading: false,
		isSuccess: false,
	},
	openedChildsIds: [],
	staffXML: null,
	wplaceEmployeeList: [],
	employeeListXML: null,
	employeeeDelXML: null,
	employeeEditXML: null,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_STAFFING:
			return {
				...state,
				staffing: {
					...state.staffing, // сохраняем все остальные свойства staff без изменений
					data: payload, // обновляем data новыми данными
				},
			}
		case types.ADD_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: [...state.openedChildsIds, payload],
			}
		case types.REMOVE_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: state.openedChildsIds.filter((id) => id !== payload),
			}
		case types.SET_STAFF_XML:
			return { ...state, staffXML: payload }
		case types.SET_WPLACE_EMPLOYEE:
			return { ...state, wplaceEmployeeList: payload }
		case types.UPDATE_WPLACE_EMPLOYEE:
			const existingEmployeeIndex = state.wplaceEmployeeList.findIndex(
				(employee) => employee.externalId === payload.externalId
			)
			if (existingEmployeeIndex !== -1) {
				const updatedList = [...state.wplaceEmployeeList]
				updatedList[existingEmployeeIndex] = payload
				return { ...state, wplaceEmployeeList: updatedList }
			} else {
				return { ...state, wplaceEmployeeList: [...state.wplaceEmployeeList, payload] }
			}
		case types.DELETE_WPLACE_EMPLOYEE:
			return {
				...state,
				wplaceEmployeeList: state.wplaceEmployeeList.filter((employee) => employee.externalId !== payload),
			}
		case types.RESET_WPLACE_EMPLOYEE:
			return {
				...state,
				wplaceEmployeeList: [],
			}
		case types.SET_EMPLOYEE_XML:
			return { ...state, employeeListXML: payload }
		case types.SET_DEL_EMPLOYEE_XML:
			return { ...state, employeeeDelXML: payload }
		case types.SET_EDIT_EMPLOYEE_XML:
			return { ...state, employeeEditXML: payload }
		default:
			return state
	}
}
