import React, { useCallback, useMemo } from 'react'
import styles from './DigitizationTable.module.scss'
import { useTranslation } from 'react-i18next'
import { headCells } from './utils'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { Edit, Remove } from '@app/images'
import { getCurrentLanguage } from '@/i18next'

const DigitizationTable = ({ items = [], getAction }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const actions = [
		{ icon: Edit, title: t('actions.edit'), action: 'edit' },
		{ icon: Remove, title: t('actions.remove'), action: 'remove' },
	]
	const edit = [{ icon: Edit, title: t('actions.edit'), action: 'edit' }]

	const onActionMenuChoose = (mode, row, index) => {
		getAction(mode, row, index)
	}

	const getTableRow = useCallback(
		(row, index) => {
			return (
				<tr key={index + '-table-row'}>
					<td>{row.eventDate}</td>
					<td>{lang === 'rus' ? row.laborType.nameRu : row.laborType.nameKz}</td>
					<td>{row.bin}</td>
					<td>{row.orgName}</td>
					<td>{row.establishedPost}</td>
					<td>
						<DropdownMenu
							title={t('select_pls')}
							items={row.laborType.code === '1' ? edit : actions}
							onItemClick={(mode) => onActionMenuChoose(mode, row, index)}
						/>
					</td>
				</tr>
			)
		},
		[lang, onActionMenuChoose, t]
	)
	const convert = (date) => {
		const prev = date.split('.')
		return new Date(prev[2], prev[1] - 1, prev[0])
	}
	const tableBody = useMemo(
		() =>
			items
				.sort((a, b) => convert(b.eventDate) - convert(a.eventDate) || b.laborTypeCode - a.laborTypeCode)
				.map((row, index) => getTableRow(row, index)),
		[items, getTableRow]
	)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'} className={styles.thText}>
						{t(head.label)}
					</th>
				))}
			</>
		),
		[t]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>
				{items.length ? (
					tableBody
				) : (
					<tr>
						<td colSpan="6" className={styles.message}>
							{t('digitization.table.text')}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default DigitizationTable
