import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Edit, Delete, Save } from '@app/icons'
import { DialogTitle, DialogContent, Dialog, Button, Box } from '@mui/material'
import { Button as FormButton } from '@/ui'
import styles from './ContractCard.module.scss'
import cn from 'classnames'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useTranslation } from 'react-i18next'
import ModalConfirm from './ModalConfirm'
import { useDispatch, useSelector } from 'react-redux'
import { contractsThunks, userSelectors } from '@/entity'
import '@/assets/bootstrap/bootstrap-datepicker.css'
import '@/assets/bootstrap/bootstrap.min.css'
import { datePickerlocaleRu, datePickerlocaleKz } from '@/i18next'
import {
	DeclineModal,
	RevertModal,
	SignModal,
	EmployeeRevision,
	EmployeeDecline,
	EmployeeSignModal,
} from './components'
import TableSign from './components/TableSign/TableSign'
import CloseButton from '@/components/CloseButton/CloseButton'
import PdfViewer from '@/entity/contracts/ui/form/employmentСontractFile/PdfViewer/PdfViewer'
import { notypeFileByUid } from '@/shared/api/rest/pension/notypeFileByUid'
import { ConfirmDocument } from '@/entity/contracts/ui/form/Documents/ConfirmDocument/ConfirmDocument'
import { LoadingBlocker } from '@/components/Loader'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
import { DocumentState, SignStatus } from '@features/contracts/badges'
import { request } from '@/shared/api/request'

export const ContractProject = ({
	isOpen,
	onClose,
	contract,
	currentEtd,
	handleSave,
	handleSendToEmployee,
	handleEditEtd,
	currentEtdEdit,
	decline,
	sendForImprovement,
	updateContract,
	declineEmployer,
	revertEmployer,
	deleteEtd,
}) => {
	const dispatch = useDispatch()
	// EMPLOYER
	const [isOpenDecline, setIsOpenDecline] = useState(false)
	const [isOpenRevert, setIsOpenRevert] = useState(false)
	const [isOpenSign, setIsOpenSign] = useState(false)

	// EMPLOYEE
	const [isEmployeeRevisionOpen, setIsEmployeeRevisionOpen] = useState(false)
	const [isEmployeeDeclineOpen, setIsEmployeeDeclineOpen] = useState(false)
	const [isEmployeeOpenSign, setIsEmployeeOpenSign] = useState(false)

	const [isPdfFile, setPdfFile] = useState(null)

	const activeRole = useSelector(userSelectors.activeRole)
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const langsVal = {
		rus: 'valueRu',
		kz: 'valueKz',
	}
	const refContainer = useRef(null)
	const refSubContainer = useRef(null)

	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'

	const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
	const [modalConfirmMessage, setModalConfirmMessage] = useState('')
	const [disableDownload, setDisableDownload] = useState(false)
	const [sendToEmployeeActive, setSendToEmployeeActive] = useState(false)
	const stateCode = contract.dcontractState?.code
	const sendToEmployee = () => dispatch(contractsThunks.sendToEmployee({ externalId: contract.externalId }))
	const updateData = () => dispatch(contractsThunks.getContract(contract.externalId, contract.isLocalContract))
	const handleSaveTemplate = async (isSendToEmployee, isEdit = false) => {
		if (currentEtd.fromFile) {
			await sendToEmployee()
			await updateData()
			return
		}
		var el = document.createElement('html')
		el.innerHTML = refSubContainer.current.innerHTML

		const elementsCollection = el.getElementsByClassName('field')
		const editableblocks = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName != 'INPUT')
		const dateInputs = Array.prototype.filter.call(elementsCollection, (element) => element.nodeName == 'INPUT')

		const parsedData = []
		const parsedDataNumbers = []
		const datatoValidate = {}

		if (editableblocks && editableblocks?.length) {
			for (let i = 0; i < editableblocks.length; i++) {
				const element = editableblocks[i]
				if (element.classList.contains('number')) {
					parsedDataNumbers.push({
						code: element.getAttribute('data-name'),
						value: element.innerText,
					})
				}
				const findElement = parsedData.find((el) => el.code == element.getAttribute('data-name'))
				if (!findElement) {
					if (!(['1.5.4rus', '1.5.4kaz'].includes(element.getAttribute('data-name')) && element.innerText === ''))
						parsedData.push({
							code: element.getAttribute('data-name'),
							value: element.innerText,
							required: Boolean(element.getAttribute('required')),
						})
				}
				datatoValidate[element.getAttribute('data-name')] = element.innerText
			}
		}

		if (dateInputs && dateInputs?.length) {
			for (let i = 0; i < dateInputs.length; i++) {
				const element = dateInputs[i]
				parsedData.push({
					code: element.getAttribute('data-name'),
					value: element.value,
				})
				datatoValidate[element.getAttribute('data-name')] = element.value
			}
		}

		parsedDataNumbers.forEach((item) => {
			if (/\D/g.test(item.value)) {
				setModalConfirmOpen(true)
				setModalConfirmMessage('Значения полей должны быть числовыми')
				return null
			}
		})

		if (isEdit) {
			handleSave(parsedData, isSendToEmployee, contract.externalId)
		} else {
			handleSendToEmployee(parsedData, isSendToEmployee, isEdit)
		}
	}

	$.fn.bootstrapDP.defaults.language = language
	$.fn.bootstrapDP.defaults.defaultViewDate = new Date()
	$.fn.bootstrapDP.defaultViewDate = new Date()
	$.fn.bootstrapDP.dates['rus'] = datePickerlocaleRu
	$.fn.bootstrapDP.dates['kz'] = datePickerlocaleKz

	const downloadContractPDF = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDisableDownload(true)
		setTimeout(() => {
			setDisableDownload(false)
		}, 5000)
		const response = await downloadFileByUid(currentEtd.downloadFile.id)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = currentEtd.downloadFile.fileName
		document.body.appendChild(a)
		a.click()
	}

	useEffect(async () => {
		if (currentEtd.fromFile) {
			const response = await notypeFileByUid(currentEtd.file.id)
			setPdfFile(response)
		}
	}, [])

	const getDocument = useCallback(async (doc) => {
		if (doc) {
			const response = await notypeFileByUid(doc.id)
			setPdfFile(response)
			currentEtd.file.id = doc.id
			setSendToEmployeeActive(true)
		}
	}, [])

	const saveFromFile = useCallback(async () => {
		const result = await request({
			url: '/contract/econtract/save',
			method: 'POST',
			data: {
				clang: 'ru',
				contractDTO: contract,
				externalId: contract?.externalId,
				fileId: currentEtd.file.id,
				templateId: 0,
				valueList: [],
			},
			params: {
				isSend: false,
			},
		})
		if (result.success) {
			setSendToEmployeeActive(false)
			alert(t('success_save'))
		}
	}, [])

	return (
		<>
			<Dialog scroll={'paper'} classes={{ paper: styles.dialog__template }} onClose={onClose} open={isOpen}>
				{currentEtd.fromFile && !isPdfFile && <LoadingBlocker />}
				<DialogTitle>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Button
							startIcon={<ArrowBackIosIcon />}
							classes={{ root: cn(styles.button_etd, styles.back__button) }}
							variant="text"
							onClick={onClose}
						>
							{t('back')}
						</Button>
						<Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
					</Box>
				</DialogTitle>

				<DialogContent>
					<Box className={styles.header} display="flex" alignItems="center" justifyContent="space-between">
						<Box>
							<div className={styles.etd__title}>{t('electronic_contract_title')}</div>
							<div className={styles.id__wrapper}>ID {contract.externalId}</div>
							{contract.dcontractStatus.code === '6' && (
								<div className={styles.date__wrapper}>
									{t('date_created')}: {currentEtd.createDate}
								</div>
							)}
						</Box>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<DocumentState item={contract.dcontractStatus ? contract.dcontractStatus : { code: '1', nameRu: '' }} />
							<SignStatus item={contract.dcontractState ? contract.dcontractState : { code: '1', nameRu: '' }} />
						</Box>
					</Box>

					{contract.dcontractStatus.code === '1' || contract.dcontractStatus.code === '2' ? (
						<div className={styles.actions}>
							<FormButton onClick={(e) => downloadContractPDF(e)} disabled={disableDownload}>
								{t('download_pdf_eec')}
							</FormButton>
						</div>
					) : null}
					{activeRole === 'ROLE_EMPLOYER' && currentEtd.fromFile && ['3', '5', '6', '8', '9'].includes(stateCode) ? (
						<div className={'mt-3 mb-3'}>
							<ConfirmDocument getDocument={getDocument} buttonText={t('replace_eds_project_file')}></ConfirmDocument>
						</div>
					) : null}
					{currentEtd.fromFile ? (
						<PdfViewer isPdfFile={isPdfFile} />
					) : (
						<div className={styles.editor__wrapper} ref={refContainer}>
							<div
								dangerouslySetInnerHTML={{
									__html: currentEtdEdit ? currentEtdEdit?.[langsVal[language]] : currentEtd?.textHtml,
								}}
								ref={refSubContainer}
								className={styles.editor__subwrapper}
							></div>
						</div>
					)}

					{['5', '6'].includes(stateCode) && (
						<div className={styles.employee__comment__wrapper}>
							<div className={styles.etd__title}>{t('employee_comment')}</div>
							<div className={styles.employee__comment}>{currentEtd.note}</div>
						</div>
					)}

					{!isEmployee && stateCode !== '1' && (
						<div className={styles.buttons__wrapper}>
							{!currentEtdEdit && (
								<>
									{currentEtd.fromFile ? (
										stateCode === '4' ? null : (
											<Button
												type="button"
												disabled={!['3', '5', '6', '8', '9'].includes(stateCode)}
												startIcon={<Save />}
												classes={{ root: cn(styles.button, styles.button__save) }}
												sx={{ paddingLeft: '20px !important' }}
												onClick={saveFromFile}
											>
												{t('save')}
											</Button>
										)
									) : (
										<>
											<Button
												type="button"
												disabled={!['3', '5', '6', '8', '9'].includes(stateCode)}
												variant="text"
												classes={{
													root: cn(styles.button_etd, styles.reset__button),
													disabled: styles.button__disabled,
												}}
												startIcon={<Delete fill={'#5B6880'} className={styles.edit__icon} />}
												onClick={deleteEtd}
											>
												{t('remove')}
											</Button>
											<Button
												type="button"
												disabled={!['3', '5', '6', '8', '9'].includes(stateCode)}
												startIcon={<Edit fill={'#17B67C'} className={styles.edit__icon} />}
												classes={{
													root: cn(styles.button_etd, styles.button__save),
													disabled: styles.button__disabled,
												}}
												sx={{ paddingLeft: '20px !important' }}
												onClick={handleEditEtd}
											>
												{t('edit_button')}
											</Button>
										</>
									)}
									<FormButton
										disabled={sendToEmployeeActive || !['3', '5', '6', '8', '9'].includes(stateCode)}
										type="submit"
										onClick={handleSaveTemplate.bind(null, true, false)}
									>
										{t('send_to_sign_worker')}
									</FormButton>
								</>
							)}
							{currentEtdEdit && (
								<>
									<Button
										type="button"
										disabled={stateCode === '4'}
										variant="text"
										classes={{ root: cn(styles.button_etd, styles.reset__button), disabled: styles.button__disabled }}
										startIcon={<Delete fill={'#5B6880'} className={styles.edit__icon} />}
									>
										{t('remove')}
									</Button>
									<Button
										type="button"
										startIcon={<Save />}
										classes={{ root: cn(styles.button, styles.button__save) }}
										sx={{ paddingLeft: '20px !important' }}
										onClick={handleSaveTemplate.bind(null, false, true)}
									>
										{t('save')}
									</Button>
									<FormButton
										disabled={stateCode === '4'}
										type="submit"
										onClick={handleSaveTemplate.bind(null, true, true)}
									>
										{t('send_to_sign_worker')}
									</FormButton>
								</>
							)}
						</div>
					)}
					<div className={styles.prolonagation__wrapper}></div>
					<div className={styles.status__wrapper}>
						{['1', '4', '7'].includes(stateCode) ? <TableSign currentEtd={currentEtd} /> : null}
						{stateCode !== '1' && (
							<Box display="flex" alignItems="center" justifyContent="flex-end" className={styles.buttons__wrapper}>
								{activeRole === 'ROLE_EMPLOYEE' && (
									<FormButton
										buttonClassName={styles.decline__button}
										onClick={setIsEmployeeRevisionOpen.bind(null, true)}
										disabled={stateCode !== '4'}
									>
										{t('send_to_rework')}
									</FormButton>
								)}
								{activeRole === 'ROLE_EMPLOYEE' && (
									<FormButton
										buttonClassName={styles.decline__button}
										onClick={setIsEmployeeDeclineOpen.bind(null, true)}
										disabled={stateCode !== '4'}
									>
										{t('decline')}
									</FormButton>
								)}
								{activeRole === 'ROLE_EMPLOYEE' && (
									<FormButton disabled={stateCode !== '4'} onClick={setIsEmployeeOpenSign.bind(null, true)}>
										{t('sign_button')}
									</FormButton>
								)}
								{activeRole === 'ROLE_EMPLOYER' && (
									<FormButton
										disabled={stateCode !== '4'}
										buttonClassName={styles.decline__button}
										onClick={setIsOpenRevert.bind(null, true)}
									>
										{t('actions.revoke')}
									</FormButton>
								)}
								{activeRole === 'ROLE_EMPLOYER' && (
									<FormButton
										disabled={stateCode !== '7'}
										buttonClassName={styles.decline__button}
										onClick={setIsOpenDecline.bind(null, true)}
									>
										{t('decline')}
									</FormButton>
								)}
								{activeRole === 'ROLE_EMPLOYER' && (
									<FormButton disabled={stateCode !== '7'} onClick={setIsOpenSign.bind(null, true)}>
										{t('sign_button')}
									</FormButton>
								)}
							</Box>
						)}
					</div>
				</DialogContent>
			</Dialog>
			{/* EMPLOYER */}
			<DeclineModal onClose={setIsOpenDecline.bind(null, false)} onDecline={declineEmployer} isOpen={isOpenDecline} />
			<RevertModal onClose={setIsOpenRevert.bind(null, false)} onRevert={revertEmployer} isOpen={isOpenRevert} />
			{isOpenSign ? (
				<SignModal onClose={setIsOpenSign.bind(null, false)} contract={contract} updateContract={updateContract} />
			) : null}
			{/* EMPLOYEE */}
			<EmployeeRevision
				onClose={setIsEmployeeRevisionOpen.bind(null, false)}
				sendForImprovement={sendForImprovement}
				isOpen={isEmployeeRevisionOpen}
			/>
			<EmployeeDecline
				onClose={setIsEmployeeDeclineOpen.bind(null, false)}
				onDecline={decline}
				isOpen={isEmployeeDeclineOpen}
			/>
			<EmployeeSignModal
				onClose={setIsEmployeeOpenSign.bind(null, false)}
				updateContract={updateContract}
				isOpen={isEmployeeOpenSign}
				contract={contract}
			/>
			<ModalConfirm
				onClose={() => setModalConfirmOpen(false)}
				isOpen={modalConfirmOpen}
				message={modalConfirmMessage}
			/>
		</>
	)
}
