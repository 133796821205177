import styles from './Loading.module.scss'
import React, { useMemo } from 'react'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'

export const Loading = ({ isLoading, isSuccess, request }) => {
	const { t } = useTranslation()
	return useMemo(
		() => (
			<div className={styles.loading}>
				{isLoading ? (
					<LoadingScreen>
						<Title>
							{t('wait')} <br />
							{t('labor_records.loading')}
						</Title>
					</LoadingScreen>
				) : isSuccess && !request?.length ? (
					t('labor_records.no_data')
				) : (
					t('history_check.has_error')
				)}
			</div>
		),
		[isLoading, isSuccess, request?.length, t]
	)
}
