export function getBase64(files) {
	return Promise.all(
		files.map(function (file) {
			return new Promise(function (resolve) {
				let reader = new FileReader()
				reader.onload = function () {
					//This will result in an array that will be recognized by C#.NET WebApi as a byte[]
					let bytes = Array.from(new Uint8Array(this.result))
					//if you want the base64encoded file you would use the below line:
					let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(''))
					//Resolve the promise with your custom file structure
					resolve({
						base64StringFile,
						fileName: file.name,
					})
				}
				reader.onerror = function (error) {
					resolve(null)
				}
				reader.readAsArrayBuffer(file)
			})
		})
	)
}
