import { Clock, CrossRounded, Dots, Success } from '@app/icons'
import styles from './contractState.module.scss'
import React from 'react'

export const ContractState = ({ state, lang }) => {
	const icons = {
		1: <Success className={styles.iconSuccess} />,
		2: <Clock className={styles.iconClock} />,
		3: <Dots className={styles.iconClock} />,
		4: <Dots className={styles.iconClock} />,
		5: <Dots className={styles.iconClock} />,
		6: <CrossRounded className={styles.iconClock} />,
		7: <Dots className={styles.iconClock} />,
		8: <CrossRounded className={styles.iconClock} />,
		9: <Clock className={styles.iconClock} />,
	}

	const classes = {
		1: styles.textSuccess,
		2: styles.textClock,
		3: styles.textClock,
		4: styles.textClock,
		5: styles.textClock,
		6: styles.textRed,
		7: styles.textClock,
		8: styles.textRed,
		9: styles.textClock,
	}

	return (
		<div className={styles.contractState}>
			<div>{icons[state.code || 1] || <Dots className={styles.iconClock} />}</div>
			{
				<span className={classes[state.code || 1] || styles.textClock}>
					{state[lang === 'rus' ? 'nameRu' : 'nameKz'] || 'Подписан'}
				</span>
			}
		</div>
	)
}
