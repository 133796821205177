import React from 'react'

export const Prohibit = ({ className = '' }) => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g clipPath="url(#clip0_412:5593)">
				<path d="M6 12C9.30823 12 12 9.30847 12 6C12 4.396 11.3762 2.88904 10.2435 1.75647C9.11072 0.623779 7.604 0 6 0C2.69153 0 0 2.69153 0 6C0 7.604 0.623779 9.11096 1.75647 10.2435C2.88904 11.3762 4.396 12 6 12ZM6 11C4.83154 11 3.72498 10.6028 2.83447 9.87256L9.87256 2.83447C10.6028 3.72498 11 4.83154 11 6C11 8.75696 8.75696 11 6 11ZM6 1C7.16821 1 8.27502 1.39722 9.16528 2.12744L2.12744 9.16553C1.39722 8.27502 1 7.16846 1 6C1 3.24304 3.24304 1 6 1Z" />
			</g>
			<defs>
				<clipPath id="clip0_412:5593">
					<rect width="12" height="12" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
