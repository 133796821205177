import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import { composeAddress } from '@/shared'
import styles from './DetailsOfPartiesInformation.module.scss'
import { useSelector } from 'react-redux'
import { contractsSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'

export const ContractInformation = ({ contract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const optionLang = useMemo(() => (language === 'rus' ? 'nameRu' : 'nameKz'), [language])
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const { workCondition = [] } = useSelector(contractActionsSelectors.options)

	const getDangerListData = (index) => ({
		title: t(`contract.info.conditions${index}`),
		data: contract?.dharmfulConditionsTypeCode?.startsWith(`L${index}`) ? contract?.harmfulConditions : '-',
	})

	const contactInformation = () => {
		const data = [
			{ title: t('contract.info.number'), data: contract?.contractNumber || '-' },
			{ title: t('contract.info.type'), data: contract.isElectronic ? 'электронный' : 'бумажный' },
			{ title: t('contract.info.registration_date'), data: contract?.registerDate || '-' },
			{ title: t('contract.info.start_date'), data: contract?.dateFrom || '-' },
			{ title: t('contract.info.expiration_date'), data: contract?.dateTo || '-' },
			{ title: t('contract.info.contract_term'), data: contract?.dcontractDateCode || '-' },
			{ title: t('contract.info.termination_date'), data: contract?.terminationDate || '-' },
			...(contract.employeeRequest ? [{ title: t('contract.info.note'), data: t('contract.info.note_body') }] : []),
			{ title: t('contract.info.work_type'), data: contract?.dpartTimeCode || '-' },
			{ title: t('contract.info.nkz_position'), data: contract?.position || '-' },
			{
				title: t('contract.info.established_position'),
				data: (contract.destablishedPost && contract.destablishedPost[optionLang]) || contract.establishedPost || '-',
			},
			{
				title: t('contract.info.working_condition'),
				data: contract?.dworkingConditionsCode ? workCondition.find(it => it.code === contract.dworkingConditionsCode)?.[language] : '-',
			},
			getDangerListData(1),
			getDangerListData(2),
			getDangerListData(3),
			{
				title: t('contract.info.job_address'),
				data:
					composeAddress(
						{
							country: contract?.country,
							region: contract?.region,
							district: contract?.district,
							locality: contract?.locality,
						},
						contract?.workingPlace
					) || '-',
				language,
			},
			{ title: t('contract.info.time_mode'), data: contract?.dworkingHoursCode || '-' },
			{ title: t('contract.info.tariff_rate'), data: contract?.tariffRate || '-' },
			{ title: t('contract.info.remote_job'), data: contract?.dremoteWorkCode || '-' },
		]

		if (contract?.dcontractStatus?.id === 2) {
			data.splice(7, 0, {
				title: t('contracts_tab.termination_reason'),
				data:
					terminationReasons
						?.filter(({ code }) => contract?.termReasonsList?.includes(code))
						.map((reason) => reason[optionLang])
						.join(',') || '-',
			})
		}

		return data
	}

	return (
		<DataGridTable
			hideSubTitle
			titleClass={styles.tableTitleCell}
			title={t('contract_info')}
			information={contactInformation()}
		/>
	)
}
