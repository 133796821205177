import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import paper from '@/assets/home/PaperClip.svg'
import doc from '@/assets/home/doc.svg'
import styles from './ConfirmDocument.module.scss'
import { useError } from '@/shared'
import { getSha256 } from '@/utils'
import { uploadFileEc } from '@/shared/api/rest/pension/uploadFileEc'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'

export const ConfirmDocument = ({
	getDocument,
	buttonText = null,
	buttonReplace = null,
	maxSize = 5242880,
	bottomText = null,
}) => {
	const { t } = useTranslation()
	const { getInputProps, acceptedFiles, fileRejections } = useDropzone({
		noClick: true,
		maxSize: maxSize,
		accept: ['.doc', '.docx', '.pdf'],
	})
	const [uploadedFile, setUploadedFile] = useState(null)
	const { errors, setError } = useError()
	const onUploadFile = useCallback(
		async (file) => {
			try {
				const formData = new FormData()
				getSha256(file).then(async (arr) => {
					formData.set('file', file)
					const data = await uploadFileEc(formData)
					setUploadedFile(data)
				})
			} catch (error) {
				console.error('[error uploading file] ', error)
			}
		},
		[uploadedFile]
	)
	useEffect(() => {
		if (acceptedFiles.length > 0) onUploadFile(acceptedFiles[0])
		if (fileRejections?.length > 0) {
			const error = fileRejections[0]?.errors?.filter(({ code }) => code === 'file-invalid-type').length
				? t('wrong_type_photo_error')
				: t('pension.payments.form.doc_form.max_file_size_error')
			setError('file', error)
		}
	}, [acceptedFiles, fileRejections])

	useEffect(() => {
		getDocument(uploadedFile)
	}, [uploadedFile, getDocument])
	return (
		<div>
			{!bottomText && (
				<div className={styles.size}>
					{' '}
					<Tooltip
						placement="right"
						color="white"
						overlayInnerStyle={{color: '#5B6880', width: '329px'}}
						title={t('labor_records.max_size_by_var', {maxSize: maxSize / 1048576})}
					>
						{t('labor_records.full_size') + ' (' + maxSize / 1048576 + ' МБ) '}
						<Info className={styles.hintIcon}/>
					</Tooltip>
				</div>
			)}
			<label className={styles.button}>
				<input
					type="file"
					{...getInputProps()}
					accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
				/>
				<img src={paper} alt={'img'} className={styles.svgWhite}/>
				<span>
					{buttonText
						? uploadedFile
							? buttonReplace ?? buttonText
							: buttonText
						: uploadedFile
						? t('replace_eds_project_file')
						: t('include_file_etd')}
				</span>
			</label>
			{uploadedFile && (
				<div className={styles.fileBlock}>
					<img src={doc} alt={'img'}/>
					<span>
						{uploadedFile.fileName} ({(uploadedFile.fileSize / 1048576).toFixed(2)} МБ)
					</span>
				</div>
			)}
			{(errors.file || bottomText) && (
				<div className={styles.fileInfo}>
					<span>{t('file_info_size')}</span>
				</div>
			)}
		</div>
	)
}
