export const TOGGLE_LOADING = 'forms/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'forms/TOGGLE_SUCCESS'
export const SET_CHECKS = 'forms/SET_CHECKS'
export const THEMES = 'forms/THEMES'
export const QUESTIONS = 'forms/QUESTIONS'
export const THEME = 'forms/THEME'
export const THEMELABEL = 'forms/THEMELABEL'
export const STATUSCHECK = 'forms/STATUSCHECK'
export const STATUSSELECT = 'forms/STATUSSELECT'
export const TESTRESULT = 'forms/TESTRESULT'
export const PDF = 'forms/PDF'
export const SET_SELECTED_CHECK = 'forms/SET_SELECTED_CHECK'
export const CHECKS_ALL = 'forms/CHECKS_ALL'
export const RESULT_TYPES = 'forms/RESULT_TYPES'