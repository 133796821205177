import { actions } from './actions'
import { api } from '@/shared'
import { message } from 'antd'
import { laborRecordsActions } from '@/entity'
import { getCurrentLanguage } from '@/i18next'

export const getLaborRecords = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const result = await api.laborRecords.getLaborRecords(params)
			if (result.laborDigitalizationList) {
				result.laborDigitalizationList.map((localDigit) => {
					localDigit.laborDigitalizationRecordList.map((record) => {
						if (record.terminationReasons)
							record.termReasonCodes = record.terminationReasons.map((reason) => reason.code)
					})
				})
			}
			dispatch(actions.setLaborRecords(result))
			dispatch(actions.toggleSuccess(true))
		} catch (error) {
			dispatch(actions.toggleSuccess(false))
			dispatch(actions.toggleError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
export const getRequestsAdjustment = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			const response = await api.laborRecords.getRequestsAdjustment()
			dispatch(actions.setRequestsAdjustment(response))
			dispatch(actions.toggleAdjustmentSuccess(true))
			dispatch(actions.toggleAdjustmentRefresh(false))
		} catch (error) {
			dispatch(actions.toggleAdjustmentSuccess(false))
			dispatch(actions.toggleAdjustmentError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
		}
	}
}
export const getRequestsAdjustmentDetail = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleRequestLoading(true))
			const response = await api.laborRecords.getRequestAdjustmentDetail(id)
			dispatch(actions.setRequestDetail(response))
			dispatch(actions.toggleRequestSuccess(true))
		} catch (error) {
			dispatch(actions.toggleRequestSuccess(false))
			dispatch(actions.toggleRequestError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleRequestLoading(false))
		}
	}
}
export const getShortContractData = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleRequestLoading(true))
			const response = await api.laborRecords.getShortContractData(id)
			dispatch(actions.setShortContractData(response))
			dispatch(actions.toggleRequestSuccess(true))
		} catch (error) {
			dispatch(actions.toggleRequestSuccess(false))
			dispatch(actions.toggleRequestError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleRequestLoading(false))
		}
	}
}
export const detContractAdjustmentXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.contractAdjustmentXml({ ...data })
			dispatch(actions.setAdjustmentXml(response))
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
export const sendContractAdjustment = ({ signedXml }) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
			const response = await api.laborRecords.contractAdjustment({
				entityId: 0,
				externalId: '',
				paramMap: {},
				signedXml: signedXml,
			})
			if (response.code === '1') {
				message.success('Данные отправлены успешно')
				dispatch(actions.toggleAdjustmentXmlSuccess(true))
			}
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error('Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}

export const sendContractDigit = ({ signedXml, isRefreshContracts, onClose }) => {
	const lang = getCurrentLanguage()
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
			const response = await api.laborRecords.contractDigit({
				entityId: 0,
				externalId: '',
				paramMap: {},
				signedXml: signedXml,
			})
			if (response.code === 'S001') {
				message.success(lang === 'rus' ? response.nameRu : response.nameKz)
				dispatch(actions.isRefreshContracts(!isRefreshContracts))
				onClose()
			}
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error('Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
export const getCancelRequestAdjustmentXml = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(true))
			const response = await api.laborRecords.cancelAdjustmentXml(id)
			dispatch(actions.setCancelAdjustmentXml(response))
		} catch (error) {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
		}
	}
}
export const sendCancelRequestAdjustment = ({ signedXml }) => {
	const lang = getCurrentLanguage()
	return async (dispatch) => {
		try {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(true))
			const response = await api.laborRecords.cancelAdjustment({
				entityId: 0,
				externalId: '',
				paramMap: {},
				signedXml: signedXml,
			})
			if (response?.code === 'S001') {
				message.success(lang === 'rus' ? response.nameRu : response.nameKz)
				dispatch(actions.toggleCancelAdjustmentXmlSuccess(true))
			}
		} catch (error) {
			dispatch(actions.setCancelAdjustmentXml(null))
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			dispatch(actions.toggleCancelAdjustmentXmlSuccess(false))
		} finally {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			dispatch(actions.toggleAdjustmentRefresh(true))
		}
	}
}
export const getRequestsAdjustmentJur = () => {
	return async (dispatch) => {
		try {
			dispatch(laborRecordsActions.toggleAdjustmentLoading(true))
			const response = await api.laborRecords.jur.getRequestsAdjustment()
			dispatch(laborRecordsActions.setRequestsAdjustment(response))
			dispatch(laborRecordsActions.toggleAdjustmentSuccess(true))
			dispatch(laborRecordsActions.toggleAdjustmentRefresh(false))
		} catch (error) {
			dispatch(laborRecordsActions.toggleAdjustmentSuccess(false))
			dispatch(laborRecordsActions.toggleAdjustmentError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(laborRecordsActions.toggleAdjustmentLoading(false))
		}
	}
}

export const getRequestsAdjustmentDetailJur = (id) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleRequestLoading(true))
			const response = await api.laborRecords.jur.getRequestAdjustmentDetail(id)
			dispatch(actions.setRequestDetail(response))
			dispatch(actions.toggleRequestSuccess(true))
		} catch (error) {
			dispatch(actions.toggleRequestSuccess(false))
			dispatch(actions.toggleRequestError(true))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.toggleRequestLoading(false))
		}
	}
}
export const getConfirmRequestAdjustmentXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(true))
			const response = await api.laborRecords.jur.adjustmentConfirmXml({ ...data })
			dispatch(actions.setCancelAdjustmentXml(response))
		} catch (error) {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
		}
	}
}

export const sendConfirmRequestAdjustment = ({ signedXml }) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(true))
			const response = await api.laborRecords.jur.adjustmentConfirm({
				entityId: 0,
				externalId: '',
				paramMap: {},
				signedXml: signedXml,
			})
			if (response === '') {
				message.success('Данные отправлены успешно')
				dispatch(actions.toggleCancelAdjustmentXmlSuccess(true))
			}
		} catch (error) {
			dispatch(actions.setCancelAdjustmentXml(null))
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			dispatch(actions.toggleCancelAdjustmentXmlSuccess(false))
		} finally {
			dispatch(actions.toggleCancelAdjustmentXmlLoading(false))
			dispatch(actions.toggleAdjustmentRefresh(true))
		}
	}
}
export const getContractDigitXml = (data) => {
	return async (dispatch) => {
		try {
			data.laborDigitalizationRecordList.map((record) => {
				if (record.terminationReasons)
					record.termReasonCodes = record.terminationReasons.map((reason) => reason.code ?? reason)
			})
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.contractDigitXml({ ...data })
			dispatch(actions.setAdjustmentXml(response.data))
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
export const saveContractLocalDigit = (data, isRefreshContracts, onClose) => {
	return async (dispatch) => {
		try {
			data.laborDigitalizationRecordList.map((record) => {
				if (record.terminationReasons)
					record.termReasonCodes = record.terminationReasons.map((reason) => reason.code ?? reason)
			})
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.contractLocalDigit({ ...data })
			dispatch(actions.setAdjustmentXml(response.data))
			dispatch(actions.isLocalDigitContractAdded(true))
			dispatch(actions.isRefreshContracts(!isRefreshContracts))
			onClose()
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
export const getContractLocalDigit = () => {
	return async (dispatch) => {
		try {
			let response = await api.laborRecords.getContractLocalDigit()
			if (response) {
				response.map((localDigit) => {
					localDigit.laborDigitalizationRecordList.map((record) => {
						if (record.terminationReasons)
							record.termReasonCodes = record.terminationReasons.map((reason) => reason.code)
					})
				})
				dispatch(actions.setDigitContractLocal(response))
			} else {
				dispatch(actions.setDigitContractLocal([]))
			}
		} catch (error) {
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		}
	}
}

export const deleteContractLocalDigit = (data, isRefreshContracts) => {
	const lang = getCurrentLanguage()
	return async (dispatch) => {
		try {
			let response = await api.laborRecords.deleteContractLocalDigit(data)
			dispatch(actions.isRefreshContracts(!isRefreshContracts))
		} catch (error) {
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		}
	}
}
export const isRefreshContracts = (value) => {
	return async (dispatch) => {
		dispatch(actions.isRefreshContracts(value))
	}
}

export const getContractLocalDigitXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.contractLocalDigitXml(data)
			dispatch(actions.setAdjustmentXml(response.data))
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}

export const deleteContractDigitXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.deleteContractDigit(data)
			dispatch(actions.setAdjustmentXml(response.data))
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
export const getContractDigitXmlWithOutID = (data) => {
	return async (dispatch) => {
		try {
			data.laborDigitalizationRecordList.map((record) => {
				if (record.terminationReasons)
					record.termReasonCodes = record.terminationReasons.map((reason) => reason.code ?? reason)
			})
			dispatch(actions.toggleAdjustmentLoading(true))
			dispatch(actions.toggleAdjustmentXmlLoading(true))
			const response = await api.laborRecords.contractDigitXmlWithOutID({ ...data })
			dispatch(actions.setAdjustmentXml(response.data))
		} catch (error) {
			dispatch(actions.resetAdjustmentXml())
			dispatch(actions.toggleAdjustmentLoading(false))
			message.error(
				'Ошибка получения данных для подписи' + error.response ? error.response.data.message : 'Неизвестная ошибка'
			)
		} finally {
			dispatch(actions.toggleAdjustmentLoading(false))
			dispatch(actions.toggleAdjustmentXmlLoading(false))
		}
	}
}
