import { actions } from './actions'
import { api } from '@/shared/api'
import { message } from 'antd'

export const getDashboardInformation = () => {
	return (dispatch) => {
		dispatch(actions.setDashBoardInformation({ workers: '4,15 млн.', employers: '174 500', contracts: '4,9 млн' }))
	}
}

export const contactUs = () => {
	return async (dispatch) => {
		try {
			message.success('Вопрос успешно отправлен')
		} catch (error) {
			dispatch(actions.toggleSuccessSend(false))
			message.error('Что-то пошло нет так, возможно вопрос не был отправлен')
		}
	}
}

export const searchRegionalPower = (payload) => {
	return async (dispatch) => {
		try {
			const response = await api.contacts.getContacts(payload)
			const email = response.filter((item) => item.code === 'mail')
			let inspectors = response.filter((item) => item.code === 'inspec')
			const director = response.filter((item) => item.code === 'head')
			const name = response.filter((item) => item.code === 'name')
			const phone = response.filter((item) => item.code === 'phone')
			const address = response.filter((item) => item.code === 'addr')

			const data = {
				email: email[0] && email[0].rows[0] ? { rus: email[0].rows[0].nameRu, kz: email[0].rows[0].nameKz } : null,
				inspectors: inspectors[0] && inspectors[0].rows ? [inspectors[0].rows] : [],
				director:
					director[0] && director[0].rows[0]
						? { rus: director[0].rows[0].nameRu, kz: director[0].rows[0].nameKz }
						: null,
				name: name[0] && name[0].rows[0] ? { rus: name[0].rows[0].nameRu, kz: name[0].rows[0].nameKz } : null,
				phone: phone[0] && phone[0].rows[0] ? { rus: phone[0].rows[0].nameRu, kz: phone[0].rows[0].nameKz } : null,
				address:
					address[0] && address[0].rows[0] ? { rus: address[0].rows[0].nameRu, kz: address[0].rows[0].nameKz } : null,
			}
			dispatch(actions.setRegionalPower(data))
		} catch (error) {}
	}
}

export const setFeedback = (form, captcha) => {
	return (dispatch) => {
		try {
			api.feedback.sendFeedbackToEmail(form, captcha)
			dispatch(actions.toggleSuccessSend(true))
		} catch (error) {
			dispatch(actions.toggleSuccessSend(false))
			message.error('Что-то пошло нет так, возможно вопрос не был отправлен')
		}
	}
}
