import * as types from './types'

export const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

export const initialIndustryAgreement = {
	authStateNameKz: '',
	authStateNameRu: '',
	baseSalary: '',
	createDate: '',
	dateFrom: '',
	dateTo: '',
	industryCodes: [],
	employerAssociationNames: [
		{
			employerAssociationNameKz: '',
			employerAssociationNameRu: '',
		},
	],
	externalId: '',
	files: [],
	fiveToSixGradeCoef: '',
	fourToFiveGradeCoef: '',
	industrialCoef: '',
	industryAgreementNameKz: '',
	industryAgreementNameRu: '',
	oneToTwoGradeCoef: '',
	sixToSevenGradeCoef: '',
	threeToFourGradeCoef: '',
	tradeUnionNames: [
		{
			tradeUnionNameKz: '',
			tradeUnionNameRu: '',
			tradeUnionBin: '',
		},
	],
	twoToThreeGradeCoef: '',
	extraGuarantee: [
		{
			code: '',
			valueKz: '',
			valueRu: '',
		},
	],
	updatesList: [
		{
			bin: '',
			externalId: null,
			files: [],
			updateDateTime: '',
			updateTextKz: '',
			updateTextRu: '',
		},
	],
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	industryAgreement: {
		data: initialIndustryAgreement,
		isLoading: false,
		isSuccess: false,
		error: false,
		industryAgreementXml: null,
	},
	industryAgreements: [],
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_AGREEMENT_XML:
			return { ...state, industryAgreement: { ...state.industryAgreement, industryAgreementXml: payload } }
		case types.RESET_AGREEMENT_XML:
			return { ...state, industryAgreement: { ...state.industryAgreement, industryAgreementXml: null } }
		case types.SET_AGREEMENT_ERROR:
			return { ...state, industryAgreement: { ...state.industryAgreement, error: payload } }
		case types.SET_AGREEMENT:
			return { ...state, industryAgreement: { ...state.industryAgreement, data: payload } }
		case types.SET_AGREEMENT_LOADING:
			return { ...state, industryAgreement: { ...state.industryAgreement, isLoading: payload } }
		case types.SET_AGREEMENT_SUCCESS:
			return { ...state, industryAgreement: { ...state.industryAgreement, isSuccess: payload } }
		case types.SET_AGREEMENTS:
			return {
				...state,
				industryAgreements: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		default:
			return state
	}
}
