import { contractsSelectors, contractsThunks, eContractsThunks, userSelectors } from '@/entity'
import { contractsActions } from '@/entity/contracts/model/index.js'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDigitalSign } from '@/shared'
import { inProcess } from '@features/contracts/table/actionMenu/config'
import { message } from 'antd'

export function useTermination(contract = null, onToggleAction = () => {}) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { xml: xmlData, loading, error, result } = useSelector(contractsSelectors.packageLoading)
	const terminationReasons = useSelector(contractsSelectors.terminationReasons)
	const isEmployer = useSelector(userSelectors.isEmployer)
	const selectorContract = useSelector(contractsSelectors.contract)
	const { stateCode } = contract ?? selectorContract
	const initialSign = useMemo(() => inProcess(stateCode?.code), [stateCode])

	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState({})

	const { successPayload: signedXml, onClearPayload } = useDigitalSign()

	const handle = (callback) => {
		try {
			setIsLoading(true)
			callback()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setIsLoading(false)
		}
	}

	const handleSign = (submitData) => {
		setData(submitData)
		if (isEmployer) handle(() => dispatch(eContractsThunks.initTermEmployer(submitData)))
		else handle(() => dispatch(eContractsThunks.initTermEmployee(submitData)))
	}

	useEffect(() => {
		if (!signedXml) return

		if (initialSign && !isEmployer) handle(() => dispatch(eContractsThunks.signEmployeeTermination(data, signedXml)))
		else handle(() => dispatch(eContractsThunks.saveSign(signedXml)))
	}, [data, dispatch, initialSign, isEmployer, signedXml])

	useEffect(() => {
		if (!terminationReasons) dispatch(contractsThunks.getTerminationReasons())
	}, [terminationReasons, dispatch])
	useEffect(() => {
		if (!result) return
		message.success(t('success_alert'))
		dispatch(contractsActions.resetPackage())
		onToggleAction('signPackage', false)
	}, [dispatch, onToggleAction, result, t])
	useEffect(() => {
		if (!error) return
		onClearPayload()
		onToggleAction('signPackage', false)
	}, [error, onClearPayload, onToggleAction])
	return {
		isLoading,
		terminationReasons,
		handleSign,
	}
}
