import * as types from './types'

export const initialPension = {
	state: '',
	status: '',
}

const initialParams = {
	bin: '',
	birthDate: '',
	externalId: '',
	iin: '',
	lastSendDate: '',
	middleName: '',
	name: '',
	sendDate: '',
	state: '',
	status: '',
	surname: '',
	srcText: '',
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	pension: {
		data: initialPension,
		isLoading: false,
		isSuccess: false,
	},
	persons: [],
	pensions: [],
	empty: false,
	first: false,
	last: false,
	number: 0,
	numberOfElements: 0,
	pageable: null,
	size: 0,
	sort: null,
	totalElements: 0,
	totalPages: 0,
	params: initialParams,
	pensionStatuses: [],
	pensionStates: [],
	pensionPrefExp: [],
	pensionExp: [],
	pensionDocType: [],
	consentAndPhoneData: {
		consentData: {},
		phoneData: {},
	},
	pensionXml: null,
	isSuccessSave: false,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.SET_PERSONS:
			return { ...state, persons: payload }
		case types.SET_PENSION:
			return { ...state, pension: { ...state.pension, data: payload } }
		case types.SET_CONSENT_STATUS_PENSION:
			return { ...state, pension: { ...state.pension, data: { ...state.pension.data, ...payload } } }
		case types.SET_PARAMS:
			return {
				...state,
				params: payload,
			}
		case types.SET_PENSIONS:
			return {
				...state,
				pensions: payload.content,
				empty: payload.empty,
				first: payload.first,
				last: payload.last,
				number: payload.number,
				numberOfElements: payload.numberOfElements,
				pageable: payload.pageable,
				size: payload.size,
				sort: payload.sort,
				totalElements: payload.totalElements,
				totalPages: payload.totalPages,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				[payload.name]: payload.data,
			}
		case types.SET_SINGLE_OPTIONS:
			return {
				...state,
				singleOptions: { ...state.singleOptions, [payload.name]: payload.data },
			}
		case types.SET_CONSENT_AND_PHONE_DATA:
			return {
				...state,
				consentAndPhoneData: payload,
			}
		case types.SET_PENSION_XML:
			return {
				...state,
				pensionXml: payload,
			}
		case types.CLEAR_XML:
			return {
				...state,
				pensionXml: null,
			}
		case types.TOGGLE_SUCCESS_SAVE:
			return { ...state, isSuccessSave: payload }
		default:
			return state
	}
}
